import { Button, Card, CardContent, Grid, Stack, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { StandardPopup } from 'libs/base/web/src/components/common';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AppealRequest = ({ active, setActive, appeal, loading = false }) => {
  const { t } = useTranslation();

  return (
    <StandardPopup visible={active} onBackdropClick={() => (loading ? null : setActive(false))}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3">{t('reject-registration')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField id="rejection-input" label={t('cause-of-rejection')} multiline rows={6} variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button variant="outlined" color="error" onClick={() => setActive(false)} disabled={loading}>
                  {t('cancel')}
                </Button>
                <LoadingButton variant="contained" color="primary" onClick={() => appeal((document.getElementById('rejection-input') as HTMLInputElement).value)} loading={loading}>
                  {t('send-rejection')}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </StandardPopup>
  );
};
