import {faSignature} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {StandardPopup} from "@base/web";
import {BiathlonYearPicker} from "../settings/BiathlonYearPicker";
import {useDebouncedState} from "../../users";
import {getCurrentBiathlonYear} from "../getCurrentBiathlonYear";

interface BiathlonYearPickerPopup {
  open: boolean;
  onClose: () => void;
  onSubmit: (year: string) => void;
}

export function BiathlonYearPickerPopup({open, onClose, onSubmit}: BiathlonYearPickerPopup) {
  const {t} = useTranslation();
  const theme = useTheme();
  const [biathlonYear, setBiathlonYear, fastBiathlonYear] = useDebouncedState<string>(getCurrentBiathlonYear());

  return (
    <StandardPopup visible={open} onBackdropClick={onClose} width={450}>
      <Card sx={{}}>
        <CardContent>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 3}}>
            <Box
              sx={{
                borderRadius: '50%',
                width: 42,
                height: 42,
                backgroundColor: theme.palette.primary.main,
                marginRight: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon style={{height: 22, width: 22, color: 'white'}} icon={faSignature}/>
            </Box>
          </Box>
        </CardContent>
        <CardContent sx={{py: 2}}>
          <Typography variant="h2" fontWeight="500">
            Select Biathlon Year
          </Typography>
        </CardContent>
        <CardContent sx={{pt: 2}}>
          <Stack spacing={1}>
            <BiathlonYearPicker
              value={fastBiathlonYear}
              size="small"
              onChange={(e) => {
                setBiathlonYear(e.target.value as string);
              }}
            >
            </BiathlonYearPicker>
          </Stack>
        </CardContent>
        <CardActions sx={{paddingX: 3, paddingBottom: 3, justifyContent: 'flex-end'}}>
          <Button variant="outlined" color="error" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={() => onSubmit(biathlonYear)}>
            {t('copy')}
          </Button>
        </CardActions>
      </Card>
    </StandardPopup>
  );
}
