import { API, Campaign, FormSubmission, SigningParticipantType, VirtualFileCreationBase } from '@base/core';
import { Form } from '@editors/form-editor';
import { FileUpdate } from 'libs/base/core/src/redux/files';
import { useMutation, useQueryClient } from 'react-query';
import { EmailTemplates } from '../../../../../firebase/src';
import cuid from 'cuid';

export function useToggleFavoriteMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (fileId: string) => {
      return await API.campaigns.toggleFavorite(fileId);
    },
    {
      onSuccess: (file, fileId, context) => {
        queryClient.setQueryData(
          ['campaigns', file.parent, 'children'],
          (old: { children: Core.VirtualFile[]; fileCount: number; folderCount: number }) =>
            old && {
              ...old,
              children: old.children.map((f) => (f.id == file.id ? file : f)),
            },
        );
        queryClient.setQueryData(['campaigns', file.id], (old) => file);
      },
    },
  );
}

export function useRenameFileMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ fileId, name }: { fileId: string; name: string }) => {
      return await API.campaigns.updateFile(fileId, { name });
    },
    {
      onSuccess: (data, { fileId }, context) => {
        void queryClient.invalidateQueries(['campaigns', fileId]);
        void queryClient.invalidateQueries(['campaigns', data.parent, 'children']);
      },
    },
  );
}

export function useUpdateFileMutation<T = Core.VirtualFile>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ fileId, update }: { fileId: string; update: FileUpdate<T> }) => {
      return await API.campaigns.updateFile(fileId, update);
    },
    {
      onSuccess: (data, { fileId }, context) => {
        queryClient.setQueryData(['campaigns', fileId], (old: Core.NormalFile) => {
          if (!old) return old;
          return data;
        });
        queryClient.setQueryData(['campaigns', data.parent, 'children'], (old: { children: Core.NormalFile[] }) => {
          console.log({ old });
          if (!old) return old;
          return { ...old, children: old.children.map((file) => (file.id === fileId ? data : file)) };
        });
      },
    },
  );
}

export function useUpdatePhysicalFileMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ fileId, data }: { fileId: string; data: Blob | Uint8Array | ArrayBuffer }) => {
      return await API.campaigns.updateNormalFile(fileId, data);
    },
    {
      onSuccess: (data, { fileId, data: fileData }, context) => {
        const url = URL.createObjectURL(fileData);

        queryClient.setQueryData(['campaigns', fileId], (old: Core.NormalFile) => {
          if (!old) return old;
          old.downloadUrl = Promise.resolve(url);
          return { ...old };
        });
        queryClient.setQueryData(['campaigns', data.parent, 'children'], (old: { children: Core.NormalFile[] }) => {
          if (!old) return old;
          return {
            ...old,
            children: old.children.map((file) => (file.id === fileId ? { ...file, downloadUrl: url } : file)),
          };
        });
      },
    },
  );
}

export function useDeleteFileMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (file: Core.VirtualFile) => {
      return await API.campaigns.deleteFile(file.id);
    },
    {
      onSuccess: async (data, file, context) => {
        await queryClient.invalidateQueries(['campaigns', file.id]);
        queryClient.setQueryData(['campaigns', file.parent, 'children'], (old: { children: Core.VirtualFile[]; fileCount: number; folderCount: number }) => {
          if (!old) return old;
          const newData = { ...old };
          if (file.type === 'folder') newData.folderCount--;
          else newData.fileCount--;
          newData.children = old.children.filter((f) => f.id != file.id);
          return newData;
        });
      },
    },
  );
}

export function useCreateCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ parent, ...other }: { parent: string } & VirtualFileCreationBase) => {
      const file = await API.campaigns.createCampaign(parent, other);
      return file;
    },
    {
      onSuccess: (file, _, context) => {
        queryClient.setQueryData(
          ['campaigns', file.parent, 'children'],
          (old: { children: Core.VirtualFile[]; fileCount: number; folderCount: number }) =>
            old && {
              ...old,
              children: [...old.children, file],
              fileCount: old.fileCount + 1,
            },
        );
      },
    },
  );
}

export function useCreateFolderMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ parent, ...other }: { parent: string } & VirtualFileCreationBase) => {
      return await API.campaigns.createFolder(parent, other);
    },
    {
      onSuccess: (file, _, context) => {
        // queryClient.invalidateQueries(['campaigns', file.parent, "children"])
        queryClient.setQueryData(
          ['campaigns', file.parent, 'children'],
          (old: { children: Core.VirtualFile[]; fileCount: number; folderCount: number }) =>
            old && {
              ...old,
              children: [...old.children, file],
              folderCount: old.folderCount + 1,
            },
        );
      },
    },
  );
}

export function useMovecampaignsMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updates: { fileId: string; parent: string; oldParent: string }[]) => {
      await API.campaigns.batchUpdateFiles(updates.map((u) => ({ fileId: u.fileId, update: { parent: u.parent } })));
    },
    {
      onSuccess: async (data, updates, context) => {
        for (const { parent, fileId, oldParent } of updates) {
          await queryClient.invalidateQueries(['campaigns', fileId]);
          await queryClient.invalidateQueries(['campaigns', oldParent, 'children']);
          await queryClient.invalidateQueries(['campaigns', parent, 'children']);
        }
      },
    },
  );
}

export function useCreatePhysicalFileMutation<T extends Partial<Omit<Core.VirtualFile, 'id' | 'trashed'>>>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ data, type, parent, permissions, ...other }: { data: Blob | Uint8Array | ArrayBuffer } & Partial<T>) => {
      return await API.campaigns.createNormalFile(parent, data, permissions, type, other);
    },
    {
      onSuccess: (data, { data: fileData }, context) => {
        queryClient.setQueryData(['campaigns', data.id], (old: Core.NormalFile) => {
          return data;
        });
        if (!data.parent) {
          void queryClient.invalidateQueries(['campaigns', null, 'children']);
        } else {
          queryClient.setQueryData(['campaigns', data.parent, 'children'], (old: { children: Core.NormalFile[]; fileCount: number; folderCount: number }) => {
            if (!old) return old;
            if (data.type === 'folder') old.folderCount++;
            else old.fileCount++;
            old.children = [...old.children, data];
            return { ...old };
          });
        }
      },
    },
  );
}

export function useDeleteFormContentMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ campaignId, formId, storageId }: { campaignId: string; formId: string; storageId: string }) => {
      await API.campaigns.deleteFormViewerContent(campaignId, formId, storageId);
    },
    {
      onSuccess: async (data, params, context) => {
        await queryClient.invalidateQueries(['campaigns', params.campaignId, 'formssubmissions', params.formId, params.storageId]);
        await queryClient.invalidateQueries(['campaigns', params.campaignId, 'formssubmissions', params.formId]);
        await queryClient.invalidateQueries(['campaigns', params.campaignId, 'submissions']);
      },
    },
  );
}

export function useDeleteFormMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ campaignId, formId }: { campaignId: string; formId: string }) => {
      return await API.campaigns.deleteForm(campaignId, formId);
    },
    {
      onSuccess: (data, params, context) => {
        void queryClient.invalidateQueries(['campaigns', params.campaignId, 'forms', params.formId]);
        queryClient.setQueryData(['campaigns', params.campaignId, 'forms'], (old: Form[]) => {
          if (!old) return old;
          return old.filter((f) => f.id != params.formId);
        });
      },
    },
  );
}

export function useUpdateFormMutation<T extends Form>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ campaignId, formId, update }: { formId: string; update: Partial<T>; campaignId: string }) => {
      return await API.campaigns.updateForm(campaignId, formId, update);
    },
    {
      onSuccess: (data, { campaignId, formId, update }, context) => {
        queryClient.setQueryData(['campaigns', campaignId, 'forms', formId], data);
        queryClient.setQueryData(['campaigns', campaignId, 'forms'], (old: T[]) => {
          if (!old) return old;
          return old.map((form) => (form.id === formId ? data : form));
        });
      },
    },
  );
}

export function useUpdateFormsMutation<T extends Form>() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updates: { formId: string; update: Partial<T>; campaignId: string }[]) => {
      return await Promise.all(updates.map(({ campaignId, formId, update }) => API.campaigns.updateForm(campaignId, formId, update)));
    },
    {
      onSuccess: (data, updates, context) => {
        let index = 0;
        for (const { campaignId, formId } of updates) {
          queryClient.setQueryData(['campaigns', campaignId, 'forms', formId], data[index]);
          // eslint-disable-next-line no-loop-func
          queryClient.setQueryData(['campaigns', campaignId, 'forms'], (old: T[]) => {
            if (!old) return old;
            return old.map((form) => (form.id === formId ? data[index] : form));
          });
          index++;
        }
      },
    },
  );
}

export function useCreateFormMutation<T extends Form>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ form, campaignId }: { form: T; campaignId: string }) => {
      return await API.campaigns.createForm(campaignId, form);
    },
    {
      onSuccess: (data, { campaignId, form }, context) => {
        queryClient.setQueryData(['campaigns', campaignId, 'forms', form.id], () => {
          return data;
        });
        queryClient.setQueryData(['campaigns', campaignId, 'forms'], (old: T[]) => {
          if (!old) return [data];
          return [...old, data];
        });
      },
    },
  );
}

export function useDuplicateFormMutation<T extends Form>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ form, campaignId }: { form: T; campaignId: string }) => {
      const forms = await API.campaigns.getForms(campaignId);
      console.log(forms, form);
      const newForm = { ...form, id: cuid(), name: form.name + ' (copy)' };
      return Promise.all([
        await API.campaigns.createForm(campaignId, newForm),
        ...forms
          .filter((f) => f.parent === form.id)
          .map((f) =>
            API.campaigns.createForm(campaignId, {
              ...f,
              parent: newForm.id,
              id: cuid(),
            }),
          ),
      ]);
    },
    {
      onSuccess: (data, { campaignId, form }, context) => {
        queryClient.setQueryData(['campaigns', campaignId, 'forms'], (old: T[]) => {
          if (!old) return data;
          return [...old, ...data];
        });
      },
    },
  );
}

export function usePublishCampaignMutation() {
  const emailTemplates = new EmailTemplates();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ campaignId }: { campaignId: string }) => {
      const campaign = (await API.campaigns.getFile(campaignId)) as Campaign;
      return await API.campaigns.publishCampaign(campaign, 'New development project available', emailTemplates.getNewCampaignAvailableContent(campaign));
    },
    {
      onSuccess: (campaign, { campaignId }, context) => {
        queryClient.setQueryData(['campaigns', campaignId], (old: Campaign) => {
          if (!old) return old;
          return campaign;
        });
        void queryClient.invalidateQueries(['campaigns', campaign.parent, 'children']);
      },
    },
  );
}

export function useDeepCopyCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ campaign, newParent }: { campaign: Campaign; newParent?: string }): Promise<Campaign> => {
      return await API.campaigns.deepDuplicateCampaign(campaign, newParent);
    },
    {
      onSuccess: (campaign, _, context: Campaign) => {
        void queryClient.setQueryData(['campaigns', campaign.id], (old: Campaign) => {
          return context;
        });
        void queryClient.invalidateQueries(['campaigns']);
        void queryClient.invalidateQueries(['campaigns', context?.parent, 'children']);
      },
      onError: (error, _, context) => {
        console.error(error);
        console.error(context);
      },
    },
  );
}

export function useSendESignAgreementMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      userId,
      selectedFile,
      recipients,
      deadline,
      agreementName,
      formSubmission,
      sendDraft,
    }: {
      userId: string;
      selectedFile: File;
      recipients: SigningParticipantType[];
      deadline: Date;
      agreementName: string;
      formSubmission: FormSubmission;
      sendDraft: boolean;
    }) => {
      return await API.adobeSign.createAndSendAgreement(
        userId,
        selectedFile,
        recipients,
        deadline,
        agreementName,
        formSubmission.campaignId,
        formSubmission.formId,
        formSubmission.entityId,
        sendDraft,
      );
    },
    {
      onSuccess: async (campaign, args, context) => {
        await queryClient.invalidateQueries(['campaigns', args.formSubmission.campaignId, 'formssubmissions', args.formSubmission.formId, args.formSubmission.entityId]);
        await queryClient.invalidateQueries(['campaigns', args.formSubmission.campaignId, 'formssubmissions', args.formSubmission.formId]);
        await queryClient.invalidateQueries(['campaigns', args.formSubmission.campaignId, 'submissions']);
      },
    },
  );
}

export function useDeleteESignAgreementMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ agreementId, formSubmission }: { agreementId: string; formSubmission: FormSubmission }) => {
      return await API.adobeSign.abortAgreement(agreementId, formSubmission);
    },
    {
      onSuccess: async (campaign, args, context) => {
        await queryClient.invalidateQueries(['campaigns', args.formSubmission.campaignId, 'submissions', args.formSubmission.formId + ':' + args.formSubmission.entityId]);
        await queryClient.invalidateQueries(['campaigns', args.formSubmission.campaignId, 'formssubmissions', args.formSubmission.formId]);
        await queryClient.invalidateQueries(['campaigns', args.formSubmission.campaignId, 'submissions']);
      },
    },
  );
}
