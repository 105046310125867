import { Campaign } from '@base/core';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Stack, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, XGrid, GridFilterModel } from '@material-ui/x-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../assets/universal/new-empty-state.svg';
import SearchIcon from '../../assets/universal/search-icon.svg';
import { ContentHeader, InfoPopup } from '../../components/common';
import { SearchInput } from '../../components/inputs';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { useDebouncedState } from '../users';

export interface CampaignManagerProps {
  campaigns: Campaign[];
  parents: Core.VirtualFile[];
  baseUrl: string;
  onDuplicateCampaign(campaign: Campaign, newParent?: string): void | Promise<void>;
  loading?: boolean;
  onCampaignDoubleClick(campaign: Campaign): void;
}

const useStyles = makeStyles({
  root: (theme: any) => ({
    flex: 1,
    height: 'auto',
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
});

export function ApplicationsExternal({ campaigns, loading, onCampaignDoubleClick, parents = [], baseUrl }: CampaignManagerProps) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [filterModel, setFilterModel, fastFilterModel] = useDebouncedState<GridFilterModel>();

  const columns: (lastCellClassName: string) => GridColDef[] = (lastCellClassName: string) => [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography style={{ marginLeft: 8 }}>{value}</Typography>;
      },
    },
    {
      flex: 0.5,
      align: 'right',
      renderCell: ({ value, row }) => {
        return (
          <Stack direction={'row'} spacing={1}>
            <InfoPopup description={row.description} />
            <Button
              /* ToDo: Implement Logic: View or View Reason of denial */
              onClick={() => {
                onCampaignDoubleClick(row as Campaign);
              }}
              sx={{ bgcolor: 'info.lighter', color: 'info.main', borderRadius: 50 }}
              style={{ marginRight: 6 }}
              startIcon={<FontAwesomeIcon style={{ marginLeft: 8 }} fixedWidth fontSize={20} width={20} height={20} size="sm" icon={faEdit} />}
            >
              {t('View')}
            </Button>
          </Stack>
        );
      },
      field: '1',
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <ContentContainer>
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.campaignExternalOverview, ...parents.map((p) => ({ path: baseUrl + p.id, name: p.name }))]}></NavbarSmall>
      <ContentView noPadding>
        <ContentHeader title="Application Projects" subtitle={campaigns.length + ' Campaigns'}>
          <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
            <Box flex={1}>
              <SearchInput
                size="small"
                InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
                placeholder={t('search')}
                fullWidth
                value={fastFilterModel?.items?.find((i) => i.columnField == 'name')?.value ?? ''}
                onChange={(e) => {
                  try {
                    new RegExp(e.target.value);
                    setFilterModel({ items: [{ columnField: 'name', operatorValue: 'contains', value: e.target.value }] });
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
            </Box>
          </Stack>
        </ContentHeader>
        <XGrid
          className={classes.root}
          headerHeight={40}
          disableColumnSelector
          sortModel={[{ field: 'name', sort: 'asc' }]}
          disableColumnMenu
          onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
          filterModel={filterModel}
          columns={columns(classes.lastCell).map((c) => ({
            ...c,
            renderHeader: (params) => {
              return (
                <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                  {params.colDef.headerName}
                </Typography>
              );
            },
          }))}
          components={{
            NoRowsOverlay: () => {
              return (
                <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                  <Box sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <img src={EmptyState} style={{ width: 175 }} />
                    <Typography variant="h2" style={{ marginTop: 20 }}>
                      Currently no projects here.
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
                      Currently there are no application projects assigned to your federation. <br /> We are going to notify you via mail if you are assigned to a new application!
                    </Typography>
                  </Box>
                </div>
              );
            },
            NoResultsOverlay: () => {
              return (
                <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                  <Box sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <img src={SearchIcon} style={{ width: 200 }} />
                    <Typography variant="h2" style={{ marginTop: 20 }}>
                      No results found!
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
                      We couldn't find anything related to your search or filter. <br />
                      Please try a different search term or filter setting.
                    </Typography>
                  </Box>
                </div>
              );
            },
          }}
          rows={campaigns}
          loading={loading}
          onRowDoubleClick={(params) => {
            onCampaignDoubleClick(params.row as Campaign);
          }}
        />
      </ContentView>
    </ContentContainer>
  );
}
