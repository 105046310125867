import firebaseType from 'firebase/app';
import { setFirebase } from './config';

export var region = '';

export function initializeDatabase(options: Object | undefined, functionsRegion: string, fb: typeof firebaseType) {
  if (options) {
    fb.initializeApp(options);
  }
  fb.firestore().settings({
    ignoreUndefinedProperties: true,
  });
  setFirebase(fb);
  region = functionsRegion;
  //@ts-ignore
  window.firebase = fb;
}
