import '../../css/components/common/layout/inputSection.scss';

import React from 'react';
import type { ReactChildrenProp } from '../../lib';

interface InputSectionProps {
  title: string;
  rightParentMg?: number;
  children: ReactChildrenProp;
}

export const InputSection = ({ title, rightParentMg = 16, children }: InputSectionProps) => (
  <div className="input-section">
    <div className="settings-title">{title}</div>
    <div className="input-area" style={{ marginRight: -rightParentMg, width: '100%' }}>
      {children}
    </div>
  </div>
);
