import { Campaign, getKeysWithTruthyValues, Group, GroupTag } from '@base/core';
import { Box, Button, Grid, Stack, Tab, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@material-ui/core';
import { LoadingButton, TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopup } from '../../../components/common';
import { LoadingPromiseToggleButton } from '../../../components/LoadingPromiseButton';
import { BiathlonYearPicker } from './BiathlonYearPicker';
import { GroupsPanelContent } from './GroupsPanelContent';

export interface CampaignSetttingsProps {
  campaign: Campaign;
  onUpdateCampaign(campaign: Campaign): Promise<void> | void;
  groups: Group[];
  groupTags: GroupTag[];
  setGroups(update: { added: string[]; removed: string[]; value: string[] }): void | Promise<void>;
  publishCampaign(id: string): void | Promise<void>;
}

export function generateBiathlonYears() {
  const startingYear = 2021;

  const endYear = new Date().getFullYear() + 3;

  const biathlonYears = [];
  for (let year = startingYear; year < endYear; year++) {
    biathlonYears.push(year + `/${year + 1 - 2000}`);
  }
  return biathlonYears;
}

export function Settings({ campaign, onUpdateCampaign, groups, setGroups, publishCampaign, groupTags }: CampaignSetttingsProps) {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState('settings');
  const { t } = useTranslation();
  const notify = usePopup();
  const status = campaign.publishedState ?? 'unpublished';
  return (
    <TabContext value={tabIndex}>
      <Box paddingX={4} paddingTop={1} paddingBottom={2} bgcolor={theme.palette.background.grayDark}>
        <TabList style={{ height: 40, minHeight: 40 }} onChange={(_, v) => setTabIndex(v)} TabIndicatorProps={{ style: { background: theme.palette.primary.main } }} textColor="#000000">
          <Tab label="General Settings" value="settings" />
          <Tab label="Entitled Groups" value="groups" />
        </TabList>
      </Box>
      <TabPanel value="settings">
        <Formik
          initialValues={campaign}
          onSubmit={async (values, {}) => {
            try {
              await onUpdateCampaign(values);
              notify({
                title: t('saved'),
                type: 'success',
              });
            } catch (error) {
              notify({
                title: t('error'),
                type: 'error',
                text: error.message,
              });
            }
          }}
        >
          {({ values, setFieldValue, isSubmitting, handleReset, handleSubmit }) => (
            <Grid container margin={0} spacing={4}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Field as={TextField} label={t('name')} name="name" variant="outlined" required />
                  <Field as={TextField} label={t('description')} name="description" variant="outlined" multiline rows={5} />
                  <Field as={BiathlonYearPicker} name="biathlonYear" />
                  <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button variant="outlined" color="error" onClick={handleReset}>
                      {t('cancel')}
                    </Button>
                    <LoadingButton variant="contained" color="primary" loading={isSubmitting} onClick={handleSubmit as any}>
                      {t('save-changes')}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack spacing={2}>
                  <Typography variant="h2">{t('status')}</Typography>
                  <ToggleButtonGroup value={status} exclusive>
                    <ToggleButton
                      value="unpublished"
                      //@ts-ignore
                      color="info"
                      onClick={async () => {
                        if (status != 'unpublished') await onUpdateCampaign({ ...campaign, publishedState: 'unpublished' });
                      }}
                    >
                      {status == 'unpublished' ? t('unpublished') : t('unpublish')}
                    </ToggleButton>
                    <LoadingPromiseToggleButton
                      variant="contained"
                      // loadingPosition="start"
                      // loading
                      value="published"
                      //@ts-ignore
                      color="success"
                      onClick={async () => {
                        if (status != 'published') await publishCampaign(campaign.id);
                      }}
                    >
                      {status == 'published' ? t('published') : t('publish')}
                    </LoadingPromiseToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
            </Grid>
          )}
        </Formik>
      </TabPanel>
      <TabPanel value="groups" sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <GroupsPanelContent groupTags={groupTags} groups={groups} setGroups={setGroups} selectedGroups={getKeysWithTruthyValues(campaign.selectedGroups ?? {})} />
      </TabPanel>
    </TabContext>
  );
}
