import '../../../css/components/common/buttons/switch.scss';

import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

type tooltipProps = {
    check: boolean;
};

function cssClasses(conditionalNames: { [className: string]: boolean }, ...otherClassnames: string[]) {
    const classNames = [
        ...otherClassnames,
        ...Object.entries(conditionalNames)
            .filter(([className, isClassNameActive]) => isClassNameActive)
            .map(([className, isClassNameActive]) => className),
    ];
    return classNames.join(' ');
}

export const Switch = ({ check }: tooltipProps) => {
    const [checkAnimation, setCheckAnimation] = useState<boolean>(false);

    return (
        <div>
            <div
                onClick={() => {
                    setCheckAnimation(!checkAnimation), console.log(checkAnimation);
                }}
                className="switch-container"
                style={checkAnimation ? { backgroundColor: 'rgba(63, 223, 149, .2)' } : { backgroundColor: 'rgba(213, 85, 85, .2)' }}
            >
                <div
                    className={cssClasses(
                        {
                            checked: checkAnimation,
                        },
                        'switch-icon-circle'
                    )}
                >
                    <FontAwesomeIcon color="#ffffff" className="switch-icon fa-check" icon={faCheck} />
                    <FontAwesomeIcon color="#ffffff" className="switch-icon fa-times" icon={faTimes} />
                </div>
            </div>
        </div>
    );
};
