import '../../../css/components/common/popups/LoadingPopup.scss';

import {} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';

export function LoadingPopup() {
  return (
    <div className="Loading-popup-provider">
      <div className="Loading-popup-container">
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
        <div className="text">Lädt Inhalte...</div>
      </div>
    </div>
  );
}
