import * as React from 'react';

function SvgFileWordLight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.802 62.414" ref={svgRef} {...props}>
      <path d="M45.083 11.944L34.858 1.718A5.85 5.85 0 0030.726 0H5.85A5.87 5.87 0 000 5.862v50.7a5.852 5.852 0 005.85 5.85h35.1a5.852 5.852 0 005.85-5.85V16.088a5.879 5.879 0 00-1.719-4.144zm-2.754 2.767a1.917 1.917 0 01.512.9H31.2V3.973a1.917 1.917 0 01.9.512zm-1.377 43.8H5.85a1.956 1.956 0 01-1.95-1.95V5.862a1.956 1.956 0 011.95-1.95H27.3v12.676a2.918 2.918 0 002.925 2.925H42.9v37.051a1.956 1.956 0 01-1.949 1.95zm-6.533-30.04c-.829 4-3.961 17.026-4.071 18.318-.707-3.547-.085.2-5.095-18.391a1.474 1.474 0 00-1.414-1.085h-.78a1.46 1.46 0 00-1.413 1.087c-4.668 17.343-4.558 17.136-4.8 18.855-.5-2.913.256-.353-4.193-18.818a1.462 1.462 0 00-1.426-1.121h-.878a1.457 1.457 0 00-1.414 1.816c1.158 4.631 4.2 16.746 5.143 20.476a1.46 1.46 0 001.414 1.109h2.072a1.474 1.474 0 001.414-1.085c4.168-15.564 4.083-15.04 4.473-17.416.792 3.79.024.853 4.473 17.416a1.474 1.474 0 001.414 1.085h1.706c.67 0 1.67-.451 1.84-1.1L38.1 29.141a1.467 1.467 0 00-1.414-1.828h-.829a1.474 1.474 0 00-1.438 1.158z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileWordLight);
export default ForwardRef;
