import * as React from 'react';

function SvgCheckCircleRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.75 20.75" ref={svgRef} {...props}>
      <path d="M10.375 0A10.375 10.375 0 1020.75 10.375 10.375 10.375 0 0010.375 0zm0 2.008a8.367 8.367 0 11-8.367 8.367 8.362 8.362 0 018.367-8.367m5.865 5.45l-.943-.95a.5.5 0 00-.71 0L8.674 12.37l-2.5-2.522a.5.5 0 00-.71 0l-.95.943a.5.5 0 000 .71l3.8 3.829a.5.5 0 00.71 0l7.22-7.162a.5.5 0 00-.004-.71z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCheckCircleRegular);
export default ForwardRef;
