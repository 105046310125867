import { Query } from '../../entities/Query';
import * as ACTION_TYPES from './types';

export const queryUsersRequestAction = () => {
  return {
    type: ACTION_TYPES.QUERY_USERS_REQUEST as typeof ACTION_TYPES.QUERY_USERS_REQUEST,
  };
};

export const queryUsersSuccessAction = (users: Core.User[], query: Query<Core.User>) => {
  return {
    type: ACTION_TYPES.QUERY_USERS_SUCCESS as typeof ACTION_TYPES.QUERY_USERS_SUCCESS,
    users,
    query,
  };
};

export const queryUsersFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.QUERY_USERS_FAILURE as typeof ACTION_TYPES.QUERY_USERS_FAILURE,
    error,
  };
};

export const fetchNextPageRequestAction = () => {
  return {
    type: ACTION_TYPES.FETCH_NEXT_PAGE_REQUEST as typeof ACTION_TYPES.FETCH_NEXT_PAGE_REQUEST,
  };
};

export const fetchNextPageSuccessAction = (users: Core.User[]) => {
  return {
    type: ACTION_TYPES.FETCH_NEXT_PAGE_SUCCESS as typeof ACTION_TYPES.FETCH_NEXT_PAGE_SUCCESS,
    users,
  };
};

export const fetchNextPageFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.FETCH_NEXT_PAGE_FAILURE as typeof ACTION_TYPES.FETCH_NEXT_PAGE_FAILURE,
    error,
  };
};

export const createUserRequestAction = () => {
  return {
    type: ACTION_TYPES.CREATE_USER_REQUEST as typeof ACTION_TYPES.CREATE_USER_REQUEST,
  };
};

export const createUserSuccessAction = (user: Core.User) => {
  return {
    type: ACTION_TYPES.CREATE_USER_SUCCESS as typeof ACTION_TYPES.CREATE_USER_SUCCESS,
    user,
  };
};

export const createUserFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.CREATE_USER_FAILURE as typeof ACTION_TYPES.CREATE_USER_FAILURE,
    error,
  };
};

export const deleteUserRequestAction = () => {
  return {
    type: ACTION_TYPES.DELETE_USER_REQUEST as typeof ACTION_TYPES.DELETE_USER_REQUEST,
  };
};

export const deleteUserSuccessAction = (uid: string) => {
  return {
    type: ACTION_TYPES.DELETE_USER_SUCCESS as typeof ACTION_TYPES.DELETE_USER_SUCCESS,
    uid,
  };
};

export const deleteUserFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.DELETE_USER_FAILURE as typeof ACTION_TYPES.DELETE_USER_FAILURE,
    error,
  };
};

export const fetchSingleUserRequestAction = () => {
  return {
    type: ACTION_TYPES.FETCH_SINGLE_USER_REQUEST as typeof ACTION_TYPES.FETCH_SINGLE_USER_REQUEST,
  };
};

export const fetchSingleUserSuccessAction = (user: Core.User) => {
  return {
    type: ACTION_TYPES.FETCH_SINGLE_USER_SUCCESS as typeof ACTION_TYPES.FETCH_SINGLE_USER_SUCCESS,
    user,
  };
};

export const fetchSingleUserFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.FETCH_SINGLE_USER_FAILURE as typeof ACTION_TYPES.FETCH_SINGLE_USER_FAILURE,
    error,
  };
};

export const updateUserRequestAction = () => {
  return {
    type: ACTION_TYPES.UPDATE_USER_REQUEST as typeof ACTION_TYPES.UPDATE_USER_REQUEST,
  };
};

export const updateUserSuccessAction = (uid: string, userData: Partial<Core.User>) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_SUCCESS as typeof ACTION_TYPES.UPDATE_USER_SUCCESS,
    userData,
    uid,
  };
};

export const updateUserFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_FAILURE as typeof ACTION_TYPES.UPDATE_USER_FAILURE,
    error,
  };
};

export const setUserRightsRequestAction = () => {
  return {
    type: ACTION_TYPES.SET_USER_RIGHTS_REQUEST as typeof ACTION_TYPES.SET_USER_RIGHTS_REQUEST,
  };
};

export const setUserRightsSuccessAction = (uid: string, rights: Core.Rights) => {
  return {
    type: ACTION_TYPES.SET_USER_RIGHTS_SUCCESS as typeof ACTION_TYPES.SET_USER_RIGHTS_SUCCESS,
    rights,
    uid,
  };
};

export const setUserRightsFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.SET_USER_RIGHTS_FAILURE as typeof ACTION_TYPES.SET_USER_RIGHTS_FAILURE,
    error,
  };
};

export const setUserRolesRequestAction = () => {
  return {
    type: ACTION_TYPES.SET_USER_ROLES_REQUEST as typeof ACTION_TYPES.SET_USER_ROLES_REQUEST,
  };
};

export const setUserRolesSuccessAction = () => {
  return {
    type: ACTION_TYPES.SET_USER_ROLES_SUCCESS as typeof ACTION_TYPES.SET_USER_ROLES_SUCCESS,
  };
};

export const setUserRolesFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.SET_USER_ROLES_FAILURE as typeof ACTION_TYPES.SET_USER_ROLES_FAILURE,
    error,
  };
};

export const updateUserGroupsRequestAction = () => {
  return {
    type: ACTION_TYPES.UPDATE_USER_GROUPS_REQUEST as typeof ACTION_TYPES.UPDATE_USER_GROUPS_REQUEST,
  };
};

export const updateUserGroupsSuccessAction = (uid: string, groups: { add: string[]; remove: string[] }) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_GROUPS_SUCCESS as typeof ACTION_TYPES.UPDATE_USER_GROUPS_SUCCESS,
    uid,
    groups,
  };
};

export const updateUserGroupsFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_GROUPS_FAILURE as typeof ACTION_TYPES.UPDATE_USER_GROUPS_FAILURE,
    error,
  };
};

export const updateUserCreateDefaultStateAction = () => {
  return {
    type: ACTION_TYPES.UPDATE_USER_CREATE_DEFAULT_STATE as typeof ACTION_TYPES.UPDATE_USER_CREATE_DEFAULT_STATE,
  };
};
