import { FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import React from 'react';
import {} from 'react-dom';
import * as yup from 'yup';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { SHORT_ANSWER } from './types';

export const LinearScaleRenderer: Renderer<LinearScaleValue, number> = {
  type: SHORT_ANSWER,
  contentType: 'linearscale',
  defaultEditorValue: { min: 0, max: 5 },
  defaultReaderValue: null,
  viewer,
  viewerMin,
  editor,
  getValidationSchema,
  getTextRepresentation,
};

function viewerMin({ onChange, value, schema, error, touched }: RendererViewerProps<LinearScaleValue, number>): JSX.Element {
  return (
    <Grid item xs={12}>
      <Typography style={{ wordBreak: 'break-all' }}>
        {value} of {schema.max}
      </Typography>
    </Grid>
  );
}

async function getTextRepresentation({ onChange, value, schema, error, touched }: RendererViewerProps<LinearScaleValue, number>) {
  return `${value} of ${schema.max}`;
}

function viewer({ onChange, value, schema, error, touched }: RendererViewerProps<LinearScaleValue, number>): JSX.Element {
  console.log('🚀 ~ file: LinearScaleRenderer.tsx ~ line 35 ~ viewer ~ schema', schema);
  console.log('🚀 ~ file: LinearScaleRenderer.tsx ~ line 18 ~ viewer ~ value', value);
  const max = schema.max > 10 ? 10 : schema.max;
  const range = max + 1 - schema.min;
  const items = new Array(range > 0 ? range : 0).fill(0).map((_, i) => i + schema.min);

  return (
    <Grid item xs={12}>
      {/* <TextField multiline fullWidth label="Answer" value={value} onChange={(e) => onChange?.(e.target.value)} /> */}
      <Grid container display="flex" alignItems="center" justifyContent="center">
        {schema.minLabel && (
          <Grid item xs>
            <Typography style={{ wordBreak: 'break-all' }}>{schema.minLabel}</Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={8} md={7}>
          <FormControl error={touched && Boolean(error)} fullWidth>
            <RadioGroup
              row
              value={value ?? null}
              onChange={(e) => {
                onChange(parseInt(e.target.value));
              }}
              style={{ display: 'flex' }}
            >
              {items.map((v) => (
                <FormControlLabel style={{ margin: 0, flex: 1 }} key={v} value={v} control={<Radio />} label={v} labelPlacement="top" />
              ))}
            </RadioGroup>
            {touched && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        </Grid>
        {schema.maxLabel && (
          <Grid item xs>
            <Typography style={{ wordBreak: 'break-all' }}>{schema.maxLabel}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

function editor({ onChange, value }: RendererEditorProps<LinearScaleValue, number>): JSX.Element {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            variant="outlined"
            label="Minimum"
            value={value.min}
            onChange={(e) => onChange({ ...value, min: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) })}
            type="number"
            inputProps={{ min: '0', max: '10' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            variant="outlined"
            label="Maximum"
            value={value.max}
            onChange={(e) => onChange({ ...value, max: parseInt(e.target.value) > 10 ? 10 : parseInt(e.target.value) })}
            type="number"
            inputProps={{ min: '0', max: '10' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" label="Minimum Label (optional)" value={value.minLabel ?? ''} onChange={(e) => onChange({ ...value, minLabel: e.target.value.slice(0, 20) })} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" label="Maximum Label (optional)" value={value.maxLabel ?? ''} onChange={(e) => onChange({ ...value, maxLabel: e.target.value.slice(0, 20) })} fullWidth />
        </Grid>
      </Grid>
    </Grid>
  );
}

function getValidationSchema(schema, t) {
  let validator = yup.number();
  if (schema.required) {
    validator = validator.required(t('something-is-required', { something: t('question') }));
  } else {
    validator = validator.nullable();
  }
  return validator.typeError(t('something-is-required', { something: t('question') }));
}
type LinearScaleValue = {
  min: number;
  minLabel?: string;
  max: number;
  maxLabel?: string;
};
