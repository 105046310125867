import '../../../css/components/common/buttons/button.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '../Tooltip';
import React from 'react';
import { cx } from '../../HelperFunctions';

type EditButtonProps = {
    tooltipText?: string;
    icon: any;
    iconSize?: number;
    style?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    disabled?: boolean;
    active?: boolean;
    onClick: () => void;
    color?: string;
};

/**
 *
 * @param tooltipText if no value is given, tooltip is disabled
 */
export const EditButton = ({ onClick, color, tooltipText, icon, iconSize = 18, style, iconStyle, disabled = false, active = false }: EditButtonProps) => (
    <Tooltip text={tooltipText} hidden={tooltipText ? false : true}>
        <button
            className={cx('edit-button', active && 'active')}
            disabled={disabled}
            style={{ ...style }}
            onMouseDown={(e) => {
                e.preventDefault();
                onClick();
            }}
        >
            <FontAwesomeIcon color={(active && '#fff') || color} icon={icon} style={{ fontSize: iconSize, ...iconStyle }} />
        </button>
    </Tooltip>
);
