export const USER_COLLECTION = 'users';
export const GROUP_COLLECTION = 'groups';
export const GROUP_TAG_COLLECTION = 'grouptags';
import type firebaseType from 'firebase/app';

export var firebase: typeof firebaseType;

export function setFirebase(fb: typeof firebaseType) {
  firebase = fb;
}

//#region Access Request
export const ACCESS_REQUEST_COLLECTION = 'access_request';
export const ACCESS_REQUEST_STORAGE = 'access_request_files';

//#region Handbook
export const HANDBOOK_FILES_COLLECTION = 'handbook_files';
export const HANDBOOK_FILES_STORAGE = 'handbook_files';

//#endregion

//#region Learning
export const USER_QUESTION_DATA_COLLECTION = 'question_data';
export const MODULE_COLLECTION = 'modules';

export function getModuleCollection() {
  return firebase.firestore().collection(MODULE_COLLECTION);
}

export function getModuleUserDataCollection(moduleId: string) {
  return firebase.firestore().collection(MODULE_COLLECTION).doc(moduleId).collection('user_data');
}
export function getModuleUserExamDataCollection(moduleId: string, uid: string) {
  return firebase.firestore().collection(MODULE_COLLECTION).doc(moduleId).collection('user_data').doc(uid).collection('exams');
}

export function getModuleQuestionsCollection(moduleId: string) {
  return firebase.firestore().collection(MODULE_COLLECTION).doc(moduleId).collection('questions');
}

//#endregion
