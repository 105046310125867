import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '@base/core';
import * as mime from 'mime-types';

/**
 *
 * @param currentParent
 * @deprecated
 */
export function useParentFolderBreadCrumbs(currentParent: string) {
  const { filesMap } = useSelector((state: Core.StateType) => state.files);
  const dispatch = useDispatch();
  const getParentRecurse = (currentParent: string): Core.VirtualFile[] => {
    if (filesMap[currentParent]) {
      const current = filesMap[currentParent];
      if (current.parent) {
        return [...getParentRecurse(current.parent), filesMap[currentParent]];
      } else {
        return [filesMap[currentParent]];
      }
    } else {
      dispatch(Actions().files.fetchFile(currentParent));
      return [];
    }
  };

  return useMemo(() => getParentRecurse(currentParent).map((p) => ({ name: p.name, path: '/files/' + p.id, id: p.id })), [currentParent, filesMap]);
}
