import { API } from '@base/core';
import { useQuery } from 'react-query';

export function useGetChildren<T extends Core.VirtualFile[]>(parentId: string, equalQueries?: { field: string; value: any }[]) {
  return useQuery(['campaigns', parentId, 'children'], async () => {
    const children = await API.campaigns.getChildren(parentId, equalQueries);
    const fileCount = children.filter((f) => f.type != 'folder').length;
    const folderCount = children.length - fileCount;
    return {
      children: children as T,
      fileCount,
      folderCount,
    };
  });
}
