import { Group, GroupTag } from '@base/core';
import { faSave, faUsers, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Grid, ListItemIcon, ListItemText, MenuItem, Tab, Tabs, TextField, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { GridColDef, XGrid } from '@material-ui/x-grid';
import { diffArrays } from 'diff';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StandardPopup, usePopup } from '../../../components/common';
import { useTheme as useSettings } from '../../../theme/ThemeProvider';

interface AddGroupsPopupProps {
  open?: boolean;
  onClose(): void;
  selectedGroups: string[];
  groups: Group[];
  groupTags: GroupTag[];
  onSave(added: string[], removed: string[], nextValue: string[]): Promise<any> | void;
}

function useSplitIntoGroupTypes(groups: Group[], groupTags: GroupTag[]) {
  const settings = useSettings();
  const map: { [key: string]: { external: boolean; icon: IconDefinition; name: string; groups: Group[]; groupTags: GroupTag[] } } = {};
  for (const g of settings.namedGroups) {
    map[g.name] = { ...g, groupTags: [], groups: [] };
  }
  for (const group of groups) {
    if (map[group.type]) {
      map[group.type].groups.push(group);
    }
  }
  for (const groupTag of groupTags) {
    if (map[groupTag.type]) {
      map[groupTag.type].groupTags.push(groupTag);
    }
  }

  return map;
}

function unique(value: string[]) {
  const s = value.reduce((map, v) => ({ ...map, [v]: true }), {});
  return Object.keys(s);
}

export function SelectGroupsPopupAdvances({ onClose, open = false, onSave, groups, groupTags, selectedGroups }: AddGroupsPopupProps) {
  const theme = useTheme();
  const notify = usePopup();
  const { t, i18n } = useTranslation();
  const settings = useSettings();
  const iconMap = settings.namedGroups.reduce((map, v) => ({ ...map, [v.name]: v.icon }), {});
  const mappedGroups = useSplitIntoGroupTypes(groups, groupTags);
  const [tabIndex, setTabIndex] = useState(Object.keys(mappedGroups)[1] + 'tags');

  const groupMap = groups.reduce((map, group) => ({ ...map, [group.id]: group }), {});

  useMemo(() => ({ groups: selectedGroups.map((id) => groups.find((group) => group.id == id)).filter((g) => g) }), [selectedGroups, groups]);
  return (
    <StandardPopup visible={open} onBackdropClick={onClose} width={750}>
      <Formik
        initialValues={{ groups: selectedGroups }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const diff = diffArrays(selectedGroups, values.groups);
            const added = diff.filter((d) => d.added).flatMap((d) => d.value);
            const removed = diff.filter((d) => d.removed).flatMap((d) => d.value);
            await onSave(added, removed, values.groups);
            onClose();
          } catch (error) {
            notify({
              title: t('error'),
              type: 'error',
              text: error.message,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, setFieldValue, values, submitForm }) => {
          const columnsGroups: GridColDef[] = [
            {
              field: 'checked',
              renderCell: ({ row }) => {
                const groups = mappedGroups[tabIndex.slice(0, tabIndex.length - 4)].groups.filter((g) => g.groupTag == row.id);
                return (
                  <Checkbox
                    disabled={groups.length == 0}
                    checked={groups.length && groups.every((g) => values.groups.includes(g.id))}
                    indeterminate={groups.some((g) => values.groups.includes(g.id)) && groups.some((g) => !values.groups.includes(g.id))}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFieldValue('groups', unique([...values.groups, ...groups.map((g) => g.id)]));
                      } else {
                        setFieldValue(
                          'groups',
                          values.groups.filter((id) => !groups.some((g) => g.id === id)),
                        );
                      }
                    }}
                  />
                );
              },
              renderHeader: ({}) => <></>, //<Checkbox checked={mappedGroups[tabIndex.slice(0, tabIndex.length - 4)].groupTags.every(g => values.groups.includes(g.id))} indeterminate />,
            },
            {
              field: 'name',
              headerName: t('name'),
              flex: 1,
            },
          ];

          const columns: GridColDef[] = [
            {
              field: 'checked',
              filterable: false,
              sortable: false,
              disableColumnMenu: true,
              renderCell: ({ value, row }) => (
                <Checkbox
                  checked={value as boolean}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('groups', [...values.groups, row.id]);
                    } else {
                      setFieldValue(
                        'groups',
                        values.groups.filter((id) => id != row.id),
                      );
                    }
                  }}
                />
              ),
              renderHeader: ({}) => (
                <Checkbox
                  checked={mappedGroups[tabIndex].groups.every((g) => values.groups.includes(g.id))}
                  indeterminate={mappedGroups[tabIndex].groups.some((g) => values.groups.includes(g.id)) && mappedGroups[tabIndex].groups.some((g) => !values.groups.includes(g.id))}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('groups', unique([...values.groups, ...mappedGroups[tabIndex].groups.map((g) => g.id)]));
                    } else {
                      setFieldValue(
                        'groups',
                        values.groups.filter((id) => !mappedGroups[tabIndex].groups.some((g) => g.id === id)),
                      );
                    }
                  }}
                />
              ),
            },
            {
              field: 'name',
              headerName: t('name'),
              flex: 1,
            },
          ];
          console.log(values.groups);
          return (
            <Card>
              <CardHeader
                title={t('add-groups')}
                titleTypographyProps={{ variant: 'h2' }}
                style={{ background: theme.palette.background.gray, paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid xs={12} item>
                    <Autocomplete
                      options={groups}
                      autoSelect
                      value={values.groups.map((id) => groupMap[id]).filter((g) => g)}
                      onChange={(_, v) =>
                        setFieldValue(
                          'groups',
                          v.map((g) => g.id),
                        )
                      }
                      multiple
                      renderInput={(params) => <TextField {...params} variant="outlined" label="Select groups" placeholder="add group" />}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <MenuItem {...props}>
                          <ListItemIcon>
                            <FontAwesomeIcon icon={iconMap[option.type] ?? faUsers} />
                          </ListItemIcon>
                          <ListItemText>{option.name}</ListItemText>
                        </MenuItem>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)}>
                      {Object.keys(mappedGroups)
                        .reverse()
                        .map((key) => (
                          <Tab label={t(key) + ' Groups'} value={key + 'tags'} key={key} />
                        ))}
                    </Tabs>
                    <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)}>
                      {Object.keys(mappedGroups)
                        .reverse()
                        .map((key) => (
                          <Tab label={t(key)} value={key} key={key} />
                        ))}
                    </Tabs>

                    {tabIndex.endsWith('tags') ? (
                      <XGrid autoHeight columns={columnsGroups} rows={mappedGroups[tabIndex.slice(0, tabIndex.length - 4)].groupTags} />
                    ) : (
                      <XGrid autoHeight columns={columns} rows={mappedGroups[tabIndex].groups.map((g) => ({ ...g, checked: values.groups.includes(g.id) }))} />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions sx={{ paddingX: 3, paddingBottom: 3, justifyContent: 'flex-end' }}>
                <Button variant="outlined" color="error" onClick={onClose}>
                  {t('cancel')}
                </Button>
                <LoadingButton startIcon={<FontAwesomeIcon icon={faSave} />} variant="contained" onClick={submitForm} loading={isSubmitting}>
                  {t('save')}
                </LoadingButton>
              </CardActions>
            </Card>
          );
        }}
      </Formik>
    </StandardPopup>
  );
}
