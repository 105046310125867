import { Button, Card, CardActions, CardContent, CardHeader, Paper, Typography, useTheme, Grid, Stack, Switch } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StandardPopup, usePopup } from '../../components/common';
import '../../css/components/common/layout/InputGrid.scss';
import '../../css/components/common/layout/usergrid.scss';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { useUpdateFileMutation } from '../campaign/hooks';
import { FileModule } from './CampaignOverview';

export interface NotificationsPopupProps {
  currentModule?: CampaignModule;
  visible: boolean;
  onClose: () => void;
}

export interface CampaignModule extends FileModule {
  notifications?: {
    chat: { [userid: string]: boolean };
    submissions: { [userid: string]: boolean };
  };
}

export const CampaignNotificationsPopup = ({ currentModule, visible, onClose }: NotificationsPopupProps) => {
  const me = useSelector((state: Core.StateType) => state.auth.user);
  const notification = usePopup();

  const updateMutation = useUpdateFileMutation<CampaignModule>();

  const { t } = useTranslation();
  const theme = useTheme();

  const [allNotifications, setAllNotifications] = useState(false);

  const chat = currentModule?.notifications?.chat?.[me.id];
  const submissions = currentModule?.notifications?.submissions?.[me.id];

  return (
    <StandardPopup onBackdropClick={onClose} visible={visible} width={500}>
      <Card>
        <CardHeader
          title={t('mail-notifications')}
          titleTypographyProps={{ variant: 'h3' }}
          style={{ background: theme.palette.background.gray, paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Paper sx={{ backgroundColor: theme.palette.background.gray, mb: 1, boxShadow: 'none' }}>
            <Grid container spacing={2} paddingX={2} paddingY={1}>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">{t('enable-all-notifications')}</Typography>
                  <Switch
                    checked={chat && submissions}
                    onChange={async (e) => {
                      try {
                        await updateMutation.mutateAsync({
                          fileId: currentModule.id,
                          update: { [`notifications.chat.${me.id}`]: e.target.checked, [`notifications.submissions.${me.id}`]: e.target.checked },
                        });
                        notification({
                          title: t('saved'),
                          type: 'success',
                          time: 1500,
                        });
                      } catch (error) {
                        notification({
                          title: 'Error',
                          type: 'error',
                          text: error.message,
                        });
                      }
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" sx={{ mb: 1 }}>
            <Grid container spacing={2} paddingX={2} paddingY={1}>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">{t('new-submissions')}</Typography>
                  <Switch
                    checked={submissions}
                    onChange={async (e) => {
                      try {
                        await updateMutation.mutateAsync({ fileId: currentModule.id, update: { [`notifications.submissions.${me.id}`]: e.target.checked } });
                        notification({
                          title: t('saved'),
                          type: 'success',
                          time: 1500,
                        });
                      } catch (error) {
                        notification({
                          title: 'Error',
                          type: 'error',
                          text: error.message,
                        });
                      }
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined">
            <Grid container spacing={2} paddingX={2} paddingY={1}>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">{t('new-messages')}</Typography>
                  <Switch
                    checked={chat}
                    onChange={async (e) => {
                      try {
                        await updateMutation.mutateAsync({ fileId: currentModule.id, update: { [`notifications.chat.${me.id}`]: e.target.checked } });
                        notification({
                          title: t('saved'),
                          type: 'success',
                          time: 1500,
                        });
                      } catch (error) {
                        notification({
                          title: 'Error',
                          type: 'error',
                          text: error.message,
                        });
                      }
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </CardContent>
        <CardActions sx={{ paddingX: 3, paddingBottom: 3, justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={onClose}>
            {t('done')}
          </Button>
        </CardActions>
      </Card>
    </StandardPopup>
  );
};
