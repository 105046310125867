import { useMemo } from 'react';
import usePromise from 'react-promise';

function isPromise<T>(val: T | Promise<T>): val is Promise<T> {
  return Boolean((val as Promise<T>)?.then);
}

export function usePromiseResolve<T>(val: T | Promise<T>): T {
  const v: Promise<T> = useMemo(() => (isPromise(val) ? val : Promise.resolve(val)), [val]);

  const { value } = usePromise(v);
  return value;
}
