import * as React from 'react';

function SvgAlignJustifyRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.514 20.746" ref={svgRef} {...props}>
      <path d="M20.746 18.44H.768a.768.768 0 00-.768.769v.768a.768.768 0 00.768.768h19.978a.768.768 0 00.768-.768v-.768a.768.768 0 00-.768-.769zm0-6.147H.768a.768.768 0 00-.768.769v.768a.768.768 0 00.768.768h19.978a.768.768 0 00.768-.768v-.768a.768.768 0 00-.768-.768zm0-6.147H.768A.768.768 0 000 6.915v.768a.768.768 0 00.768.768h19.978a.768.768 0 00.768-.768v-.768a.768.768 0 00-.768-.768zm0-6.147H.768A.768.768 0 000 .768v.768a.768.768 0 00.768.768h19.978a.768.768 0 00.768-.768V.768A.768.768 0 0020.746 0z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAlignJustifyRegular);
export default ForwardRef;
