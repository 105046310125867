import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faUser, faUserCrown, faUserEdit } from '@fortawesome/pro-regular-svg-icons';

export const UserGroupRights: { name: string; icon: IconDefinition; i18nDescription: string; rights: { [key in Core.GroupRightsKeys]: boolean } }[] = [
  {
    name: 'Viewer',
    icon: faUser,
    i18nDescription: 'groupuser-viewer-description',
    rights: {
      can_submit_applications: false,
      group_admin: false,
      user_create: false,
      user_delete: false,
      user_edit: false,
      user_right_edit: false,
    },
  },
  {
    name: 'Editor',
    icon: faUserEdit,
    i18nDescription: 'groupuser-editor-description',
    rights: {
      can_submit_applications: false,
      group_admin: false,
      user_create: true,
      user_delete: false,
      user_edit: true,
      user_right_edit: false,
    },
  },
  {
    name: 'Admin',
    icon: faUserCrown,
    i18nDescription: 'groupuser-admin-description',
    rights: {
      can_submit_applications: true,
      group_admin: true,
      user_create: true,
      user_delete: true,
      user_edit: true,
      user_right_edit: true,
    },
  },
];

export function getRoleFromGroupRights(rights: Core.GroupRights = {} as any, groupId: string) {
  outer: for (const role of UserGroupRights) {
    for (const right of Object.keys(role.rights)) {
      if ((rights[right] ?? []).includes(groupId) !== role.rights[right]) continue outer;
    }
    return role.name;
  }
  return undefined;
}

export function getRightsFromRole(role: string, groupId: string): Core.GroupRights {
  const currentRole = UserGroupRights.find((r) => r.name === role);
  if (!currentRole) return {} as Core.GroupRights;

  const newRights = {} as Core.GroupRights;

  for (const [right, include] of Object.entries(currentRole.rights)) {
    newRights[right] = include ? [groupId] : [];
  }
  return newRights;
}
