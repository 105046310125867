import { faSortAlphaDown, faSortAlphaDownAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import '../../../css/components/common/content/search/search.scss';

interface SortProps {
  onChange: (filter: 'a-z' | 'z-a') => void;
}

export const SortDropdown = ({ onChange }: SortProps) => {
  const [selectedFilter, setfilterSelected] = useState<'a-z' | 'z-a'>('a-z');
  const theme = useTheme();

  const handleChange = (filter: 'a-z' | 'z-a') => () => {
    setfilterSelected(filter);
    onChange?.(filter);
  };

  return (
    <IconButton
      sx={{ color: 'secondary.main', bgcolor: 'background.paper', ':hover': { bgcolor: 'action.hover' }, borderRadius: 999, zIndex: 2, ml: 1, width: 48 }}
      onClick={handleChange(selectedFilter === 'a-z' ? 'z-a' : 'a-z')}
    >
      <FontAwesomeIcon icon={selectedFilter === 'a-z' ? faSortAlphaDown : faSortAlphaDownAlt} style={{ color: theme.palette.primary.main }} />
      {/* <div
        className={cssClasses(
          {
            visible: popupVisible,
          },
          'filter-dropdown-vertical',
        )}
      >
        {selectedFilter === 'z-a' && (
          <FontAwesomeIcon icon={faSortAlphaDown} color={theme.colors['dark-gray']} className="dropdown-filter-image" style={{ marginBottom: 0 }} onClick={handleChange('a-z')} />
        )}
        {selectedFilter === 'a-z' && (
          <FontAwesomeIcon color={theme.colors['dark-gray']} icon={faSortAlphaDownAlt} className="dropdown-filter-image" style={{ marginBottom: 0 }} onClick={handleChange('z-a')} />
        )}
      </div> */}
    </IconButton>
  );
};
