import {useCallback} from "react";
import { Button } from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from "@fortawesome/pro-regular-svg-icons";
import xlsx from "xlsx";

interface DownloadXLSXButtonProps {
  buttonText: string,
  rows: any,
  columns: any,
  filename: string,
  sheetname: string
}

export function DownloadXLSXButton({buttonText, rows, columns, filename, sheetname}:DownloadXLSXButtonProps) {

  const downloadXLSX = useCallback(() => {
    const workbook = xlsx.utils.book_new();
    const colIDs = [];
    const colNames = [];
    const worksheetData = [];

    columns.forEach(col => {
      colIDs.push(col.field);
      colNames.push(col.headerName ? col.headerName : col.field);
    });

    worksheetData.push(colNames)

    // <app-name>_<nf-name>_<date>

    rows.forEach(row => {
      const worksheetRow = []
      colIDs.forEach(colID => {
        worksheetRow.push(row[colID] ? row[colID] : '')
      })
      worksheetData.push(worksheetRow)
    });

    const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);

    xlsx.utils.book_append_sheet(workbook, worksheet, sheetname);
    xlsx.writeFile(workbook, filename);
  }, [rows, columns, filename]);

  return (
    <Button
      variant="outlined"
      size={"small"}
      sx={{alignSelf: "flex-start"}}
      onClick={downloadXLSX}
      endIcon={<FontAwesomeIcon icon={faDownload} style={{height: 16, width: 16}} />}
    >
      {buttonText}
    </Button>
  )
};
