import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import React from 'react';

export interface FileUploadProps {
  size: number;
  uploadedBytes: number;
  finished?: boolean;
  name: string;
  mimeType: string;
  id: string;
  onCancel: () => void;
  onDelete: () => void;
}

export function FileUpload({ id, mimeType, name, size, uploadedBytes, finished, onCancel, onDelete }: FileUploadProps) {
  const theme = useTheme();
  return (
    <Box>
      <Paper variant="outlined" sx={{ borderWidth: 2 }}>
        <Box padding={1} display="flex" alignItems="center">
          <Box flex={3}>
            <Typography sx={{ wordBreak: 'break-all', mr: 2 }} fontWeight="500">
              {name}
            </Typography>
          </Box>
          {finished && (
            <Button
              onClick={onDelete}
              endIcon={<FontAwesomeIcon icon={faTrash} fixedWidth style={{ fontSize: 16 }} color={theme.palette.error.contrastText} />}
              //@ts-ignore
              color="error"
              size="small"
              variant="contained"
            >
              Delete File
            </Button>
          )}
          {finished || (
            <Grid container spacing={2} flex={1} justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Button
                  onClick={onCancel}
                  endIcon={<FontAwesomeIcon icon={faTimes} size="xs" color={theme.palette.error.contrastText} />}
                  //@ts-ignore
                  color="error"
                  variant="contained"
                >
                  Cancel Upload
                </Button>
              </Grid>
              <Grid item>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={(uploadedBytes / size) * 100} />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round((uploadedBytes / size) * 100)}%`}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
