import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton } from '@material-ui/core';
import cuid from 'cuid';
import React from 'react';
import { SortableQuestionItem } from './SortableQuestionItem';
import { SetAction } from '../components/getValue';
import { MCItem } from './MultipleChoiceQuestionRenderer';

export function SelectableEditor({ onChange, value, renderItem }: { value: MCItem[]; onChange: (val: SetAction<MCItem[]>) => void; renderItem: (v: MCItem, i: number, val: MCItem[]) => React.ReactNode; }) {
  const val = Array.isArray(value) ? value : [];

  const addItem = (afterItem?: MCItem) => {
    if (afterItem) {
      let newItems = [...val];
      newItems.splice(val.indexOf(afterItem) + 1, 0, { id: cuid(), title: 'Option ' + (val.length + 1) });
      onChange(newItems);
    } else
      onChange([...val, { id: cuid(), title: 'Option ' + (val.length + 1) }]);
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const ids = val.map((i) => i.id);
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);

      onChange(arrayMove(val, oldIndex, newIndex));
    }
  };

  return (
    <Grid item xs={12}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={val.map((i) => i.id)} strategy={verticalListSortingStrategy}>
          {val.map((v, i) => (
            <SortableQuestionItem
              key={v.id}
              id={v.id}
              title={v.title}
              add={() => addItem(v)}
              onChange={(item) => onChange?.(val.map((question) => (question.id == item.id ? item : question)))}
              remove={() => onChange?.(val.filter((question) => question.id != v.id))}
            >
              {renderItem(v, i, val)}
            </SortableQuestionItem>
          ))}
        </SortableContext>
      </DndContext>
      <IconButton>
        <FontAwesomeIcon icon={faPlusCircle} size="sm" onClick={() => addItem()} />
      </IconButton>
    </Grid>
  );
}
