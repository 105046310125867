import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { ChangeProfilePicturePopup } from '../../../components/common/popups/ChangeProfilePicturePopup';
import { Grid, Stack, Typography } from '@material-ui/core';
import { UserImageHandler } from '@base/web';

export function UserImageEditControl({
  currentUser,
  onImage,
  userName,
  userPhotoUrl,
}: {
  currentUser: Core.User & { newPassword?: string; currentModules?: string[] };
  onImage: (image) => void;
  userName: string;
  userPhotoUrl: Promise<string>;
}) {
  const { t } = useTranslation();
  const [imagePopupActive, setImagePopupActive] = useState(false);

  return (
    <>
      <ChangeProfilePicturePopup currentUser={currentUser} active={imagePopupActive} setActive={setImagePopupActive} setImage={onImage} />
      <Grid item xs={12}>
        <Stack spacing={2} direction="row" alignItems="center">
          <UserImageHandler userName={userName} userPhotoUrl={userPhotoUrl} onClick={() => setImagePopupActive(!imagePopupActive)} />
          <Typography variant="h1">{userName ? userName : t('creating-new-users')}</Typography>
        </Stack>
      </Grid>
    </>
  );
}
