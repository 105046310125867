import { UserCreationData } from '@base/core';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTheme } from '../../../theme/ThemeProvider';
import { useUserCreate } from './helpers';

export function UserUploadMutaion({ userData, onSuccess }: { userData: UserCreationData; onSuccess: () => void }) {
  const mutation = useUserCreate();

  const uploadUser = async () => {
    await mutation.mutateAsync(userData);
    onSuccess();
  };

  useEffect(() => {
    uploadUser();
  }, []);

  const theme = useTheme();
  if (mutation.error) {
    //@ts-ignore
    if (mutation.error.message)
      //@ts-ignore
      var errorMessage = mutation.error.message;
    //@ts-ignore
    else if (mutation.error.error)
      //@ts-ignore
      var errorMessage = mutation.error.error.message;
  }
  return (
    <TableRow>
      <TableCell>
        {mutation.isLoading && <CircularProgress />}
        {mutation.isError && (
          <Button
            variant="contained"
            //@ts-ignore
            color="error"
            onClick={uploadUser}
          >
            Try Again
          </Button>
        )}
        {mutation.isSuccess && <FontAwesomeIcon icon={faCheckCircle} fontSize={30} color={theme.colors.success} />}
      </TableCell>
      <TableCell>{userData.displayName}</TableCell>
      <TableCell>{mutation.isError && errorMessage}</TableCell>
    </TableRow>
  );
}
