import * as React from 'react';

function SvgTimesRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.749 20.75" ref={svgRef} {...props}>
      <path d="M13.46 10.375l6.985-6.985a1.036 1.036 0 000-1.464L18.822.303a1.036 1.036 0 00-1.464 0l-6.984 6.986L3.392.303a1.036 1.036 0 00-1.464 0L.302 1.926a1.036 1.036 0 000 1.464l6.99 6.985-6.99 6.985a1.036 1.036 0 000 1.464l1.623 1.623a1.036 1.036 0 001.464 0l6.984-6.986 6.985 6.985a1.036 1.036 0 001.464 0l1.623-1.623a1.036 1.036 0 000-1.464z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTimesRegular);
export default ForwardRef;
