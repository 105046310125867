import * as React from 'react';

function SvgExternalLinkRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ref={svgRef} {...props}>
      <path d="M21.381 0l-7.012.007a.619.619 0 00-.619.619v1.431a.619.619 0 00.631.619l3.164-.117.089.089L5.651 14.63a.516.516 0 000 .73l.988.988a.516.516 0 00.73 0L19.352 4.366l.089.089-.117 3.164a.619.619 0 00.619.631h1.431a.619.619 0 00.619-.619L22 .619A.619.619 0 0021.381 0zm-2.818 12.375h-.687a.687.687 0 00-.687.688v6.617a.258.258 0 01-.258.258H2.32a.258.258 0 01-.258-.258V5.07a.258.258 0 01.258-.258h6.618a.687.687 0 00.688-.687v-.687a.688.688 0 00-.687-.687H2.063A2.063 2.063 0 000 4.813v15.125A2.062 2.062 0 002.063 22h15.125a2.062 2.062 0 002.063-2.062v-6.875a.687.687 0 00-.688-.688z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgExternalLinkRegular);
export default ForwardRef;
