import { faLink, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { faUserLock, faUserUnlock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import type { ReactChildrenProp } from '../../lib/ReactProps';
import { useTheme } from '../../theme/ThemeProvider';
import { ConditionalRender, DefaultDropDown } from '../common';
import { useTranslation } from 'react-i18next';

type userSmallGridProps = {
  children: ReactChildrenProp;
  title?: string;
  addUserClick?: () => void;
  changeLinkClick?: () => void;
  onVisibilityChange: (visibility: 'public' | 'private') => void;
  visibility: 'public' | 'private';
};

export const UserSmallGrid = ({ children, title = 'Berechtigte Gruppen und Nutzer', addUserClick, changeLinkClick, onVisibilityChange, visibility }: userSmallGridProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div className="grid-con users" style={{ overflow: 'visible' }}>
      <div className="inner-grid-con" style={{ overflow: 'visible', paddingBottom: 12 }}>
        <div className="flexbox-row" style={{ padding: 0 }}>
          <ConditionalRender render={!!addUserClick || !!changeLinkClick}>
            <div className="separation-line-con">
              <ConditionalRender render={false && !!changeLinkClick}>
                <div
                  className="configurable-user button"
                  style={{ boxShadow: theme.styles.shadow.shadowXS, justifyContent: 'center', backgroundColor: theme.colors['main-color'], marginRight: 16, width: 150 }}
                  onClick={changeLinkClick}
                >
                  <FontAwesomeIcon color={theme.colors['main-accent-color']} icon={faLink} className="configurable-user-image" />
                  <div className="configurable-user-name" style={{ marginTop: 12, color: theme.colors['main-accent-color'] }}>
                    Link Freigabe verwalten
                  </div>
                </div>
              </ConditionalRender>
              <div
                className="configurable-user button"
                style={{ boxShadow: theme.styles.shadow.shadowXS, justifyContent: 'center', backgroundColor: theme.colors['main-color'], marginRight: 16, width: 150 }}
                onClick={changeLinkClick}
              >
                <FontAwesomeIcon color={theme.colors['main-accent-color']} icon={visibility != 'private' ? faUserUnlock : faUserLock} className="configurable-user-image" />
                {/* <div className="configurable-user-name" style={{ marginTop: 12, color: theme.colors['main-accent-color'] }}>
                  Visibility: Public
                </div> */}
                <InputLabel id="visibility-label" style={{ marginTop: 15 }}>
                  Visibility
                </InputLabel>
                <Select
                  labelId="visibility-label"
                  // id="demo-customized-select"
                  value={visibility}
                  onChange={({ target: { value, name } }) => {
                    onVisibilityChange(value as 'public' | 'private');
                  }}
                  input={<DefaultDropDown />}
                  variant="filled"
                  style={{ alignSelf: 'stretch', marginTop: 15 }}
                  // defaultValue="public"
                  placeholder="Visibility"
                >
                  <MenuItem value="public" selected={visibility == 'public'}>
                    Public
                  </MenuItem>
                  <MenuItem value="private" selected={visibility == 'private'}>
                    Private
                  </MenuItem>
                </Select>
              </div>
              <ConditionalRender render={!!addUserClick}>
                <div className="configurable-user button" style={{ boxShadow: theme.styles.shadow.shadowXS, justifyContent: 'center', width: 150 }} onClick={addUserClick}>
                  <FontAwesomeIcon color={theme.colors['main-color']} icon={faPlusCircle} className="configurable-user-image" />
                  <div className="configurable-user-name" style={{ marginTop: 12, color: theme.colors['main-color'] }}>
                    {t('add-users-and-groups')}
                  </div>
                </div>
              </ConditionalRender>
              <div className="flex-separation-line" />
            </div>
          </ConditionalRender>
          <div className="w-layout-grid user-grid-small" style={{ width: '100%', marginTop: 0, marginBottom: 0 }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
