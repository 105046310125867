import React from 'react';
import { LoginHomeBg } from '../../assets/images';
import { ReactChildrenProp } from '../../lib/ReactProps';

type authConProps = {
  children?: ReactChildrenProp;
  loading?: boolean;
};

export const AuthContainer = ({ children, loading }: authConProps) => {
  return (
    <div
      className="auth-con"
      style={{
        backgroundImage: `url('${LoginHomeBg}')`,
      }}
    >
      <div id="auth-container">{loading ? <div>{children}</div> : <div className="auth-content-con">{children}</div>}</div>
    </div>
  );
};
