import { AccessRequestFileProps, Group, GroupTag, IAccessRequestService, IGroupsService } from '../entities';

export class GroupsInteractor {
  constructor(private service: IGroupsService, private accessRequestService: IAccessRequestService) {}

  async deleteGroup(groupID: string): Promise<void> {
    return this.service.deleteGroup(groupID);
  }

  async updateUsersForGroup(groupId: string, update: { add: string[]; remove: string[] }): Promise<void> {
    return this.service.updateUsersForGroup(groupId, update);
  }

  async updateGroup<T extends Partial<Omit<Group, 'id'>>>(groupId: string, update: T): Promise<void> {
    return this.service.updateGroup(groupId, update);
  }

  async createGroup<T extends Omit<Group, 'id'>>(g: T): Promise<T & { id: string }> {
    return this.service.createGroup(g);
  }

  async getGroups(): Promise<Group[]> {
    return this.service.getGroups();
  }

  async getGroupTags(): Promise<GroupTag[]> {
    return this.service.getGroupTags();
  }

  async deleteGroupTag(groupTagID: string): Promise<void> {
    return this.service.deleteGroupTag(groupTagID);
  }

  async updateGroupsForGroupTag(groupTagId: string, update: { add: string[]; remove: string[] }): Promise<void> {
    const addPromises = update.add.map((gid) => this.updateGroup(gid, { groupTag: groupTagId }));
    const removePromises = update.remove.map((gid) => this.updateGroup(gid, { groupTag: null }));
    await Promise.allSettled([...addPromises, ...removePromises]);
    await Promise.all([...addPromises, ...removePromises]);
  }

  async updateGroupTag<T extends Omit<GroupTag, 'id'>>(groupTagId: string, update: T): Promise<void> {
    return this.service.updateGroupTag(groupTagId, update);
  }

  async createGroupTag<T extends Omit<GroupTag, 'id'>>(g: T): Promise<T & { id: string }> {
    return this.service.createGroupTag(g);
  }

  async getGroup(groupId: string): Promise<Group> {
    return this.service.getGroup(groupId);
  }

  /**
   * Deletes the old file, uploads the new file and updates the requestData doc with the new file
   * @param accessRequestDoc the access request doc to apply the changes to
   * @param oldFilePath the firebase.storage.Reference to the old file to delete it
   * @param file the new file as Blob object
   * @returns
   */
  async swapGroupAccessRequestFile(group: Group, oldFilePath: string, file: File): Promise<void> {
    const newFile = await this.accessRequestService.uploadAccessRequestFile(file);
    group.requestData.files = group.requestData.files.filter((file) => file.firebasePath != oldFilePath);
    group.requestData.files = [...(group.requestData.files ?? []), newFile];
    await this.accessRequestService.deleteAccessRequestFile(oldFilePath);
    return await this.updateGroup(group.id, group);
  }

  /**
   * Uploads the new file and updates the access request document
   * @param accessRequestDoc the access request doc to apply the changes to
   * @param file the new file as Blob object
   * @returns
   */
  async addGroupAccessRequestFile(group: Group, file: File): Promise<void> {
    const newFile = await this.accessRequestService.uploadAccessRequestFile(file);
    group.requestData.files = [...(group.requestData.files ?? []), newFile];
    return await this.updateGroup(group.id, group);
  }
}
