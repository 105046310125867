import { Breadcrumbs } from '@material-ui/core';
import React from 'react';
import '../../css/Views/contentView.scss';
import type { ReactChildrenProp } from '../../lib/ReactProps';
import { BreadcrumbsType } from '../../router/BreadcrumbsType';
import { LogoutButton } from '../common/buttons/LogoutButton';
import { CurrentUserAvatar } from '../common/UserImageHandler';
import { Breadcrumb } from './Breadcrump';

interface NavbarSmallProps {
  children?: ReactChildrenProp;
  title?: string;
  breadcrumbs: BreadcrumbsType[];
}

export const NavbarSmall = ({ children, title, breadcrumbs = [] }: NavbarSmallProps) => {
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
      <div className="main-navbar">
        <Breadcrumbs separator={null} itemsAfterCollapse={2} itemsBeforeCollapse={2}>
          {breadcrumbs.map((el) => {
            return <Breadcrumb name={el.name} path={el.path} exact={!el.notExact} key={el.path} />;
          })}
        </Breadcrumbs>
        <div className="content-navbar-right-con">
          <LogoutButton style={{ marginRight: 15, height: 45 }} fontSize={18} />
          <CurrentUserAvatar style={{ height: 56, width: 56 }} fontSize={28} />
        </div>
      </div>
      <div className="main-navbar" style={{ height: title ? 60 : 0, paddingBottom: title ? 30 : 0 }}>
        <h1>{title}</h1>
        {children}
      </div>
    </div>
  );
};
