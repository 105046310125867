import { Grid, TextField, Typography, useTheme as useMaterialTheme } from '@material-ui/core';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import PhoneNumberInput from 'react-phone-input-material-ui';
import { AutocompleteFieldCountries } from '../../components/AutocompleteFieldCountries';
import { FileHandler } from '../../components/FileHandler';
import { ShowFile } from '../../components/ShowFile';
import { TextFieldWithLabel } from '../../components/TextFieldWithLabel';
import { RequestFormContentProps } from '../RequestFormContentProps';
export function NFRequestFormContent({ formik: { isSubmitting, errors, touched, values, setFieldValue }, readOnly = false, fileSlots, files, setFileSlots, deleteFile }: RequestFormContentProps) {
  const { t } = useTranslation();
  const muiTheme = useMaterialTheme();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="overline">{t('basic-information')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder="Name of University/Academy/Sports institution"
          required
          name="organizationName"
          disabled={isSubmitting}
          autoComplete="off"
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.organizationName && Boolean(errors.organizationName)}
          helperText={touched.organizationName && errors.organizationName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder={t('address')}
          required
          name="address"
          disabled={isSubmitting}
          autoComplete="off"
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.address && Boolean(errors.address)}
          helperText={touched.address && errors.address}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder={t('zip-code')}
          required
          name="zipCode"
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.zipCode && Boolean(errors.zipCode)}
          helperText={touched.zipCode && errors.zipCode}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {readOnly ? (
          <Field
            as={TextFieldWithLabel}
            placeholder={t('country')}
            required
            name="country"
            InputProps={{
              readOnly: readOnly,
            }}
            error={touched.zipCode && Boolean(errors.zipCode)}
            helperText={touched.zipCode && errors.zipCode}
          />
        ) : (
          <Field
            as={AutocompleteFieldCountries}
            placeholder={t('country')}
            required
            name="country"
            value={{ title: values.country }}
            disabled={isSubmitting}
            InputProps={{
              readOnly: readOnly,
            }}
            onChange={(e, v) => setFieldValue('country', v.title)}
            error={touched.country && Boolean(errors.country)}
            helperText={touched.country && errors.country}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder="Website"
          required
          name="website"
          disabled={isSubmitting}
          autoComplete="off"
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.website && Boolean(errors.website)}
          helperText={touched.website && errors.website}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="overline" sx={{ marginBottom: 0 }}>
          {t('contact-information')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder="Scientific Degree/Title"
          name="degree"
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.position && Boolean(errors.position)}
          helperText={touched.position && errors.position}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ paddingTop: muiTheme.spacing(1) }}>
        <Field
          as={TextFieldWithLabel}
          placeholder={t('name')}
          required
          name="displayName"
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.displayName && Boolean(errors.displayName)}
          helperText={touched.displayName && errors.displayName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder="Scientific Area"
          name="scientificArea"
          required
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.position && Boolean(errors.position)}
          helperText={touched.position && errors.position}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder={t('email')}
          required
          name="email"
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneNumberInput
          inputProps={{
            variant: 'outlined',
            label: t('phone-number'),
            autoComplete: 'off',
            disabled: isSubmitting,
            required: true,
            error: touched.phoneNumber && Boolean(errors.phoneNumber),
            helperText: touched.phoneNumber && errors.phoneNumber,
          }}
          specialLabel={false}
          component={TextField}
          value={values.phoneNumber}
          onChange={(v) => setFieldValue('phoneNumber', v)}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Typography variant="overline">{t('bank-details')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder={t('name-of-account')}
          required
          name="bankAccountName"
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.bankAccountName && Boolean(errors.bankAccountName)}
          helperText={touched.bankAccountName && errors.bankAccountName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder={t('bank-name')}
          required
          name="bankName"
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.bankName && Boolean(errors.bankName)}
          helperText={touched.bankName && errors.bankName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder="IBAN"
          required
          name="iban"
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.iban && Boolean(errors.iban)}
          helperText={touched.iban && errors.iban}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder="BIC"
          required
          name="bic"
          autoComplete="off"
          disabled={isSubmitting}
          InputProps={{
            readOnly: readOnly,
          }}
          error={touched.bic && Boolean(errors.bic)}
          helperText={touched.bic && errors.bic}
        />
      </Grid> */}
    </>
  );
}
