import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export function ModuleSelectDropdown(props) {
  return (
    <FormControl fullWidth>
      <Select {...props} variant="outlined" labelId="select-input-label" multiple label="Module">
        <MenuItem value="VA">Versicherungsagent</MenuItem>
        <MenuItem value="VB">Vermögensberater</MenuItem>
      </Select>
      <InputLabel id="select-input-label">Module</InputLabel>
    </FormControl>
  );
}
