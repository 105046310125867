import {EmailIdentitys, EmailTemplateNames, EmailTemplateTypes, IEmailService} from '../entities/IEmailService';
import {AuthInteractor} from './AuthInteractor';

export class EmailInteractor {
  constructor(private emailService: IEmailService, private auth: AuthInteractor) {
  }

  sendNotificationEmailToCurrentUser(subject: string, templateContent: EmailTemplateTypes, templateName?: EmailTemplateNames) {
    const currentUser = this.auth.getCurrentUser();
    return this.emailService.sendNotification({emails: currentUser.email}, subject, templateContent, templateName);
  }

  sendNotificationEmailToUsers(uids: string[], subject: string, templateContent: EmailTemplateTypes, templateName?: EmailTemplateNames) {
    return this.emailService.sendNotification({uids: uids}, subject, templateContent, templateName);
  }

  sendNotificationEmail(to: EmailIdentitys, subject: string, templateContent: EmailTemplateTypes, templateName?: EmailTemplateNames) {
    return this.emailService.sendNotification(to, subject, templateContent, templateName);
  }

  sendChatNotificationEmail(to: EmailIdentitys, message: string, questionTitle: string, openUrl: string, formName: string): Promise<void> {
    return this.emailService.sendChatNotification(to, message, questionTitle, openUrl,formName);
  }

  sendEmail(to: EmailIdentitys, subject: string, htmlContent: string, replyTo?: EmailIdentitys, cc?: EmailIdentitys) {
    return this.emailService.sendEmail({}, to, subject, htmlContent, replyTo, cc);
  }

  getArchivedEmails(autosend: boolean) {
    return this.emailService.getArchivedEmails(autosend);
  }

  deleteArchivedEmail(id: string) {
    return this.emailService.deleteArchivedEmail(id);
  }
}
