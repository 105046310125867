import { Autocomplete, TextField } from '@material-ui/core';
import { IconMap } from '@modules/country-icons';
import * as countries from 'i18n-iso-countries';
// const countries = require('i18n-iso-countries');
//Note: normal import is not workin import * as englishCountries from 'i18n-iso-countries/langs/translation.json';
import i18n_iso_countries from 'i18n-iso-countries/langs/en.json';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

countries.registerLocale(i18n_iso_countries);

type countryElement = {
  title: string;
  key: string;
};

export const AutocompleteFieldCountries = ({ name, ...props }: any) => {
  const { t } = useTranslation();
  const countryArray = useMemo(() => {
    const countryArray = [];
    for (const [key, value] of Object.entries(countries.getNames('en', { select: 'official' }))) {
      countryArray.push({ title: value, key: key });
    }
    return countryArray;
  }, []);

  function countryToFlag(countryKey: string) {
    return IconMap[countryKey] ? (
      <img src={IconMap[countryKey]} alt="flag" style={{ borderRadius: '50%', height: 18, marginRight: 10 }} />
    ) : (
      <div style={{ borderRadius: '50%', height: 18, width: 18, backgroundColor: '#fff', marginRight: 10 }} />
    );
  }

  return (
    <Autocomplete
      {...props}
      options={countryArray}
      autoSelect
      getOptionLabel={(countryArray: countryElement) => countryArray.title}
      renderInput={(params, countryArray: countryElement) => {
        return (
          <TextField
            {...params}
            label={t('country')}
            inputProps={{ ...params.inputProps, autoComplete: 'chrome-off' }}
            variant="outlined"
            required={props.required}
            error={props.error}
            helperText={props.helperText}
          />
        );
      }}
      renderOption={(props, countryArray: countryElement) => {
        return (
          <li {...props}>
            {countryToFlag(countryArray.key)}
            {countryArray.title}
          </li>
        );
      }}
    />
  );
};
