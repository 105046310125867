import { Box, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingPromiseButton } from '../..';
import { Actions } from '../../../../../core/src';
import TwoFactorIcon from '../../../assets/universal/fingerprint_green.svg';
import { StandardPopup } from '../../../components/common';

export function TwoFactorSuccessPopup() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { twoFA } = useSelector((state: Core.StateType) => state.auth);

  return (
    <StandardPopup visible={twoFA?.status?.finished2FACreation} onBackdropClick={() => null} width={425} hideClose>
      <Card>
        <CardContent>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={TwoFactorIcon} style={{ width: 72, height: 72, alignSelf: 'center' }} />
          </Box>
          <Typography fontWeight="500" variant="h2" sx={{ marginTop: 3, marginBottom: 2, textAlign: 'center' }}>
            {t('registration-successful')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ alignItems: 'center', justifyContent: 'center', marginBottom: 1 }}>
              <Typography textAlign="center" fontWeight="500">
                {t('please-login-again')}
              </Typography>
              <Typography textAlign="center" fontWeight="500" variant="subtitle2">
                {t('two-factor-success-description')}
              </Typography>
            </Box>
            <LoadingPromiseButton
              style={{ marginTop: 16 }}
              variant="contained"
              color="success"
              onClick={async () => {
                await dispatch(Actions().auth.close2FACreationNotification());
              }}
            >
              {t('got-it')}
            </LoadingPromiseButton>
          </Box>
        </CardContent>
      </Card>
    </StandardPopup>
  );
}
