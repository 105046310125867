import { FormStatus, FormSubmission, FormWithReports } from '@base/core';
import { faFileSignature } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Stack, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, XGrid } from '@material-ui/x-grid';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../assets/universal/new-empty-state.svg';
import SearchIcon from '../../assets/universal/search-icon.svg';
import { ApplicationDeadlineDateChip, ApplicationStatusChip, ApplicationSubmissionDateChip } from '../../components/campaign';
import { ContentHeader, InfoPopup } from '../../components/common';
import { SearchInput } from '../../components/inputs';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { ApplicationCenterPopup } from '../campaign';
import { useDebouncedState } from '../users';

interface ExternalFormProps {
  forms: FormWithReports[];
  parents: Core.VirtualFile[];
  baseUrl: string;
  loading?: boolean;
  onFormDoubleClick(formId: string, campaignId?: string): void;
}

const useStyles = makeStyles({
  root: (theme: any) => ({
    flex: 1,
    height: 'auto',
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
  lastCell: {
    minWidth: '300px !important',
    position: 'absolute',
    left: 0,
    zIndex: 10,
    transform: 'translateX(calc(100vw - 100% - 24px))',
    justifyContent: 'flex-end',
  },
});

export function FormsExternal({ forms, loading, onFormDoubleClick, parents = [], baseUrl }: ExternalFormProps) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [applicationCenterData, setApplicationCenterData] = useState<null | FormWithReports>(null);
  const [filterModel, setFilterModel, fastFilterModel] = useDebouncedState<GridFilterModel>();

  const columns: (lastCellClassName: string) => GridColDef[] = (lastCellClassName: string) => [
    {
      field: 'name',
      // width: 525,
      // width: 500,
      maxWidth: 900,
      minWidth: 300,
      flex: 1,
      headerName: t('name'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography style={{ marginLeft: 8 }}>{value}</Typography>;
      },
    },
    {
      field: 'info',
      headerName: t('info'),
      filterable: false,
      width: 70,
      renderCell: ({ value, row }) => {
        return <InfoPopup description={row.description} />;
      },
    },
    {
      field: 'status',
      width: 150,
      headerName: t('status'),
      type: 'date',
      renderCell: ({ value }) => <ApplicationStatusChip status={value as FormStatus} />,
    },
    {
      field: 'deadline',
      headerName: t('deadline'),
      type: 'date',
      width: 120,
      renderCell: ({ formattedValue, value, row }) => <ApplicationDeadlineDateChip deadline={value as Date} />,
    },
    {
      field: 'submissionDate' as keyof FormSubmission,
      headerName: t('submission-date'),
      type: 'date',
      width: 160,
      renderCell: ({ formattedValue, value, row }) => {
        return <ApplicationSubmissionDateChip submission={row.submission as FormSubmission} dateValue={value as Date} deadline={row.deadline} />;
      },
    },
    {
      width: 150,
      align: 'right',
      cellClassName: lastCellClassName,
      renderCell: ({ value, row, api }) => {
        return (
          <Button color={'primary'} variant={'contained'} size="small" onClick={() => setApplicationCenterData(row as FormWithReports)} startIcon={<FontAwesomeIcon icon={faFileSignature} />}>
            {t('open')}
          </Button>
        );
      },
      field: '1',
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const memoizedColumns = useMemo(() => {
    return columns(classes.lastCell).map((c) => ({
      ...c,
      renderHeader: (params) => {
        return (
          <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
            {params.colDef.headerName}
          </Typography>
        );
      },
    }));
  }, [forms]);

  return (
    <ContentContainer>
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.campaignExternalOverview, ...parents.map((p) => ({ path: '/campaigns-external/' + p.id, name: p.name }))]}></NavbarSmall>
      <ContentView noPadding>
        <ContentHeader title="Applications" subtitle={forms.length + ' ' + t('form_plural')}>
          <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
            <Box flex={1}>
              <SearchInput
                size="small"
                InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
                placeholder={t('search')}
                fullWidth
                value={fastFilterModel?.items?.find((i) => i.columnField === 'name')?.value ?? ''}
                onChange={(e) => {
                  try {
                    new RegExp(e.target.value);
                    setFilterModel({ items: [{ columnField: 'name', operatorValue: 'contains', value: e.target.value }] });
                  } catch {}
                }}
              />
            </Box>
          </Stack>
        </ContentHeader>
        <XGrid
          className={classes.root}
          headerHeight={40}
          disableColumnSelector
          disableColumnMenu
          sortModel={[{ field: 'name', sort: 'asc' }]}
          onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
          filterModel={filterModel}
          columns={memoizedColumns}
          components={{
            NoRowsOverlay: () => {
              return (
                <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                  <Box sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <img src={EmptyState} style={{ width: 175 }} alt={'Empty State'} />
                    <Typography variant="h2" style={{ marginTop: 20 }}>
                      Currently no projects here.
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
                      Currently there are no application projects assigned to your federation. <br /> We are going to notify you via mail if you are assigned to a new application!
                    </Typography>
                  </Box>
                </div>
              );
            },
            NoResultsOverlay: () => {
              return (
                <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                  <Box sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <img src={SearchIcon} style={{ width: 200 }} alt={'No Results State'} />
                    <Typography variant="h2" style={{ marginTop: 20 }}>
                      No results found!
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
                      We couldn't find anything related to your search or filter. <br />
                      Please try a different search term or filter setting.
                    </Typography>
                  </Box>
                </div>
              );
            },
          }}
          rows={forms}
          loading={loading}
          onRowDoubleClick={(params) => {
            setApplicationCenterData(params.row as FormWithReports);
          }}
        />
        <ApplicationCenterPopup open={Boolean(applicationCenterData)} onClose={() => setApplicationCenterData(null)} application={applicationCenterData} onOpenApplicationForm={onFormDoubleClick} />
      </ContentView>
    </ContentContainer>
  );
}
