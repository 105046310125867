import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function RedirectRoute({ children, component, ...rest }: RouteProps) {
  const { user } = useSelector((state: Core.StateType) => state.auth);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        console.log(location);
        if (!user) {
          return children || <Route component={component} />;
        }
        //@ts-ignore
        const redirect = (location.state || {}).from || { pathname: '/' };
        return <Redirect to={redirect} />;
      }}
    />
  );
}
