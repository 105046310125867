import * as React from 'react';

function SvgFolderLight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.968 35.976" ref={svgRef} {...props}>
      <path
        d="M18.245 3l5.118 5.118a3 3 0 002.12.878h17.988a1.5 1.5 0 011.5 1.5v20.983a1.5 1.5 0 01-1.5 1.5H4.5a1.5 1.5 0 01-1.5-1.5V4.5A1.5 1.5 0 014.5 3h13.745M4.5 0A4.5 4.5 0 000 4.5v26.979a4.5 4.5 0 004.5 4.5h38.971a4.5 4.5 0 004.5-4.5V10.493a4.5 4.5 0 00-4.5-4.5H25.483L20.365.875A3 3 0 0018.245 0z"
        fill="#fff"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFolderLight);
export default ForwardRef;
