import { exception } from 'console';
import { IFileService } from '../entities';
import { FileRepo } from './FileRepo'
export * from './FileRepo'
export * from './AuthRepo'
export * from './UsersRepo'


let fileRepo: FileRepo = null;

export function FileRepoInstance() {
  if (fileRepo) return fileRepo
  else throw new Error("You must call initialize before using Core!!!")
}

export function setFileRepo(service: IFileService) {
  fileRepo = new FileRepo(service);
}