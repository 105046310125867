import { faArrowToBottom, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Grid, useTheme } from '@material-ui/core';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FileDownload } from '../components/FileDownload';
import { FileUpload } from '../components/FileUpload';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { useProvider } from '../provider/ProviderContext';
import { SHORT_ANSWER } from './types';

export const ProvideFilesRenderer: Renderer<FilePropsEditor[], undefined> = {
  type: SHORT_ANSWER,
  contentType: 'files-provider',
  defaultEditorValue: [],
  viewer,
  editor,
  viewerMin,
  getValidationSchema: () => undefined,
  getTextRepresentation,
};

function viewer({ onChange, value, schema }: RendererViewerProps<FilePropsEditor[], undefined>): JSX.Element {
  return (
    <Grid item container xs={12} spacing={2}>
      {schema?.map((file) => (
        <Grid key={file.id} item xs={12}>
          <FileDownload {...file} />
        </Grid>
      ))}
    </Grid>
  );
}

function viewerMin({}: RendererViewerProps<FilePropsEditor[], undefined>): JSX.Element {
  return null;
}
async function getTextRepresentation({}: RendererViewerProps<FilePropsEditor[], undefined>) {
  return '';
}

function editor({ onChange, value }: RendererEditorProps<FilePropsEditor[], undefined>): JSX.Element {
  const theme = useTheme();
  const { fileProvider } = useProvider();

  const uploadFile = async (file: File) => {
    try {
      const id = await fileProvider.createFileId();
      const task = fileProvider.uploadFile(id, file);
      onChange((val) => [
        ...val,
        ({
          id,
          name: file.name,
          mimeType: file.type,
          size: file.size,
          uploadedBytes: 0,
          finished: false,
          cancel: task.cancel,
        } as any) as FilePropsEditor,
      ]);

      task.on({
        change: (uploadedBytes) => {
          console.log('change', uploadedBytes);
          onChange((val) => val.map((v) => (v.id == id ? { ...v, uploadedBytes } : v)));
        },
        success: () => {
          console.log('Success');
          onChange((val) =>
            val.map((v) => {
              if (v.id == id) {
                const { cancel, ...nv } = v;
                return { ...(nv as any), finished: true };
              }
              return v;
            })
          );
        },
        error: (error) => {
          console.log('Error', error);
          onChange((val) => val.filter((v) => v.id != id));
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    for (const file of acceptedFiles) {
      uploadFile(file);
    }
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });
  const { onClick, ...rootProps } = getRootProps();
  return (
    // <div {...getRootProps()}>
    <Grid item container xs={12} {...rootProps} spacing={2}>
      {value.map((file) => (
        <Grid key={file.id} item xs={12}>
          <FileUpload
            {...file}
            onCancel={file.cancel}
            onDelete={async () => {
              fileProvider.deleteFile(file.id);
              onChange((v) => v.filter((x) => x.id != file.id));
            }}
          />
        </Grid>
      ))}

      <Grid item xs={12}>
        <input {...getInputProps()} />
        {isDragActive || (
          <Button
            onClick={onClick}
            startIcon={<FontAwesomeIcon icon={faPlusCircle} size="sm" />}
            variant="contained"
            fullWidth
            //@ts-ignore
            color="default"
          >
            Upload
          </Button>
        )}
        {isDragActive && (
          <Box padding={5} bgcolor={alpha(theme.palette.text.primary, 0.1)} borderRadius={theme.shape.borderRadius} alignItems="center" justifyContent="center" display="flex">
            <FontAwesomeIcon icon={faArrowToBottom} size="3x" />
          </Box>
        )}
      </Grid>
    </Grid>
    // </div>
  );
}

export interface FilePropsEditor {
  size: number;
  uploadedBytes: number;
  finished?: boolean;
  name: string;
  mimeType: string;
  id: string;
  cancel(): void;
  getDownloadUrl(): Promise<string>;
}
