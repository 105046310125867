import * as React from 'react';

function SvgFileImportRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ref={svgRef} {...props}>
      <path d="M21.391 4.21l-3.6-3.6A2.062 2.062 0 0016.33 0H7.562A2.069 2.069 0 005.5 2.066v9.28H.516a.515.515 0 00-.516.516v1.031a.515.515 0 00.516.516h11.859v2.1a.516.516 0 00.882.363l3.084-3.107a.55.55 0 000-.775l-3.084-3.106a.516.516 0 00-.882.363v2.1H7.562V2.066h6.874v4.468a1.028 1.028 0 001.031 1.031h4.47v12.373H7.562v-4.466H5.5v4.466A2.063 2.063 0 007.562 22h12.372A2.066 2.066 0 0022 19.938V5.671a2.075 2.075 0 00-.609-1.461zM16.5 5.5V2.234L19.767 5.5z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileImportRegular);
export default ForwardRef;
