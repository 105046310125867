import React from 'react';
import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactChildrenProp } from '../../../../lib';

type navButtonProps = {
  icon?: IconDefinition;
  bgColor?: string;
  children?: ReactChildrenProp;
  color: string;
  hoverAnimation?: string;
  fontAwesomeIconStyle?: React.CSSProperties;
  onClick: () => void;
};

export const NavButton = ({ icon: Icon, onClick, color, hoverAnimation, children, bgColor, fontAwesomeIconStyle }: navButtonProps) => (
  <div onClick={onClick} className="icon-button" style={{ backgroundColor: bgColor }}>
    {Icon ? <FontAwesomeIcon color={color} className={'icon-button-icon ' + hoverAnimation} icon={Icon} style={fontAwesomeIconStyle} /> : children}
  </div>
);
