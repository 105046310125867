import { getKeysWithTruthyValues } from '@base/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReactProps } from '../lib/ReactProps';
import { ThemeType } from '../theme/Theme';
import { useSettings } from '../theme/ThemeProvider';

export function ConditionalRenderWithRights({ children, invert = false, enableOwnAccountWithUid, ...rights }: ReactProps<Core.Rights & { invert?: boolean; enableOwnAccountWithUid?: string }>) {
  const { user } = useSelector((state: Core.StateType) => state.auth);

  if (user?.roles?.superAdmin && !invert) return <>{children}</>;
  if (user?.roles?.superAdmin && invert) return null;

  if (user && !invert && user.id == enableOwnAccountWithUid) return <>{children}</>;
  if (user && invert && user.id != enableOwnAccountWithUid) return <>{children}</>;

  if (user && !invert && UserHasRight(user, rights)) {
    return <>{children}</>;
  }
  if (user && invert && !UserHasRight(user, rights)) {
    return <>{children}</>;
  }
  return null;
}

export function ConditionalRenderWithRightsForFiles({
  children,
  parent,
  or,
  read,
  write,
  move,
  changePermissions,
  root,
}: ReactProps<{ parent: Core.VirtualFile; root?: Core.VirtualFile; read?: boolean; write?: boolean; move?: boolean; changePermissions?: boolean; or?: boolean }>) {
  const rights = useGetCombinedRightsForFile(parent, root);
  if ((rights.changePermissions && changePermissions) || (rights.move && move) || (rights.write && write) || (read && rights.read) || or) return <>{children}</>;
  return null;
}

export function CombinedConditionalRender({
  children,
  ...rest
}: ReactProps<
  Core.Rights & { invert?: boolean; enableOwnAccountWithUid?: string; parent: Core.VirtualFile; root?: Core.VirtualFile; read?: boolean; write?: boolean; move?: boolean; changePermissions?: boolean }
>) {
  if (ConditionalRenderWithRights({ children: true, ...rest }) || ConditionalRenderWithRightsForFiles({ children: true, ...rest })) {
    return <>{children}</>;
  }
  return null;
}

export function useGetRightsForFile(file: Core.VirtualFile): { read?: boolean; write?: boolean; move?: boolean; changePermissions?: boolean } {
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const rights = { read: false, write: false, move: false, changePermissions: false };
  if (!file) return rights;
  const { permissions } = file;

  if (!user) return rights;

  if (!permissions) return rights;
  const { groups, users, visibility } = permissions;

  const hasFileAdminAccess = visibility === 'public' && user.rights.file_admin;

  if (user.roles?.superAdmin) return { read: true, write: true, move: true, changePermissions: true };

  if (users?.read.some((uid) => uid == user?.id) || groups?.read?.some((gid) => user?.groups[gid]) || visibility === 'public') {
    rights.read = true;
  }
  if (users?.write.some((uid) => uid == user?.id) || groups?.write?.some((gid) => user?.groups[gid]) || hasFileAdminAccess) {
    rights.write = true;
  }
  if (users?.move.some((uid) => uid == user?.id) || groups?.move?.some((gid) => user?.groups[gid]) || hasFileAdminAccess) {
    rights.move = true;
  }
  if (users?.permission.some((uid) => uid == user?.id) || groups?.permission?.some((gid) => user?.groups[gid]) || hasFileAdminAccess) {
    rights.changePermissions = true;
  }
  return rights;
}

export function useGetCombinedRightsForFile(file: Core.VirtualFile, root: Core.VirtualFile): { read?: boolean; write?: boolean; move?: boolean; changePermissions?: boolean } {
  const a = useGetRightsForFile(file);
  const b = useGetRightsForFile(root);
  // console.log({
  //   changePermissions: a.changePermissions || b.changePermissions,
  //   move: a.move || b.move,
  //   write: a.write || b.write,
  //   read: a.read || b.read,
  // })
  return {
    changePermissions: a.changePermissions || b.changePermissions,
    move: a.move || b.move,
    write: a.write || b.write,
    read: a.read || b.read,
  };
}

export function useWithWriteRightForFiles(file: Core.VirtualFile): boolean {
  const { user } = useSelector((state: Core.StateType) => state.auth);

  if (user?.roles?.superAdmin) return true;
  if (!file) return false;
  const { permissions } = file;
  if (!user) return false;
  if (!permissions) return false;
  const { groups, users } = permissions;
  if (!users.permission || !groups.permission) return false;

  if (users.permission.some((uid) => uid === user?.id) || groups.permission.some((gid) => user?.groups[gid])) return true;

  return false;
}

export function ConditionalRenderIfOwn({ children, invert = false, enableOwnAccountWithUid }: ReactProps<{ invert?: boolean; enableOwnAccountWithUid?: string }>) {
  const { user } = useSelector((state: Core.StateType) => state.auth);

  if (user && !invert && user.id == enableOwnAccountWithUid) return <>{children}</>;
  if (user && invert && user.id != enableOwnAccountWithUid) return <>{children}</>;
  return null;
}

export function UserHasRight(user: Core.User, rights: Core.Rights) {
  for (const right of Object.keys(rights || {}) as (keyof Core.Rights)[]) {
    if ((user.rights || {})[right] === true && rights[right]) return true;
  }
  return false;
}

export function useWithRights(...rights: (keyof Core.Rights | 'superadmin')[]) {
  const { user } = useSelector((state: Core.StateType) => state.auth);
  if (!user) return false;
  for (const right of rights) {
    if (user.rights?.[right] === true) return true;
    if (right == 'superadmin' && user.roles?.superAdmin == true) return true;
  }
  return false;
}

export function ConditionalRenderWithProductSettings({ children, ...props }: ReactProps<{ [key in keyof ThemeType['productSettings']]?: boolean }>) {
  const settings = useSettings().productSettings;
  for (const setting of getKeysWithTruthyValues(props)) {
    if (settings[setting]) return <>{children}</>;
  }
  return null;
}
