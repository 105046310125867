import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Actions } from '@base/core';
import { Tooltip } from '../Tooltip';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

type LogoutButtonProps = {
  fontSize?: number;
  style?: React.CSSProperties;
  size?: number;
};

export const LogoutButton = ({ fontSize = 24, style, size = 45 }: LogoutButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const logoutUser = async () => {
    await dispatch(Actions().auth.logout());
    queryClient.clear();
    history.replace('/login');
    window.location.reload();
  };
  return (
    <Tooltip text={t('logout')} tooltipStyle={{ top: '110%', right: 0, bottom: 'unset' }}>
      <div
        onClick={logoutUser}
        style={{
          backgroundColor: '#ffffff30',
          borderStyle: 'none',
          height: size,
          width: size,
          boxSizing: 'border-box',
          color: 'white',
          margin: 0,
          borderRadius: '50%',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...style,
        }}
      >
        <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: fontSize, marginRight: 0 }} />
      </div>
    </Tooltip>
  );
};
