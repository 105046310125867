import { InviteUserPopup, RequestPopup } from '@access-request';
import { AccessRequestDoc, API, Group } from '@base/core';
import { faEye, faInfoCircle, faMailBulk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, XGrid } from '@material-ui/x-grid';
import * as countries from 'i18n-iso-countries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppealRequest } from '../../../../../access-request/src/lib/components/AppealRequest';
import { VerifyRequest } from '../../../../../access-request/src/lib/components/VerifyRequest';
import { IconMap } from '../../../../../modules/country-icons/src/lib/country-icons';
import EmptyState from '../../assets/universal/new-empty-state.svg';
import { ContentHeader, LoadingPopup, MainMenu, usePopup } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { ConditionalRenderWithRights } from '../../helpers/ConditionalRenderWithRights';
import { useSettings } from '../../theme/ThemeProvider';
import { useGetAllRejectedAccessRequests, useRejectAccessRequestMutation, useVerifyAccessRequestMutation } from './hooks/useAccessRequest';
import { GridNoResultsOverlay, GridNoRowsOverlay } from '../../components/content/GridContent';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const useStyles = makeStyles({
  root: (theme: any) => ({
    flex: 1,
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
});

export const RejectedAccessRequests = () => {
  const [invitationEmails, setInvitationEmails] = useState(false);
  const [selection, setSelection] = useState([]);
  const [multiAppealOpen, setMultiAppealOpen] = useState(false);
  const [multiVerifyOpen, setMultiVerifyOpen] = useState(false);

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const notify = usePopup();

  const { isLoading: requestsLoading, data: accessRequests } = useGetAllRejectedAccessRequests();
  const appealAccessRequestMutation = useRejectAccessRequestMutation();
  const verifyAccessRequest = useVerifyAccessRequestMutation();
  const settings = useSettings();

  const columns: () => GridColDef[] = () => [
    {
      field: 'country',
      width: 75,
      renderCell: ({ formattedValue, value, row }) => {
        const key = countries.getAlpha2Code(value as string, 'en');
        return value ? <img src={IconMap[key]} alt="flag" style={{ borderRadius: '50%', height: 28, marginLeft: 25 }} /> : <div style={{ borderRadius: '50%', height: 28, backgroundColor: 'grey' }} />;
      },
    },
    {
      field: 'organizationName',
      width: 250,
      headerName: 'Organization Name',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'displayName',
      width: 225,
      headerName: 'Name',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'type',
      width: 70,
      headerName: 'Type',
      renderCell: ({ formattedValue, value = 'federation', row }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return <Typography variant="subtitle1">{t((value as string) + '-short')}</Typography>;
      },
    },
    {
      field: 'phoneNumber',
      width: 175,
      headerName: 'Phone Number',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{'+' + value}</Typography>;
      },
    },
    {
      field: 'email',
      width: 200,
      headerName: 'E-Mail',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      flex: 1,
      align: 'right',
      renderCell: ({ formattedValue, value, row }) => {
        const [requestPopupOpen, setRequestPopupOpen] = useState(false);

        const group = settings.namedGroups.find((g) => g.name === ((row as Group).type ?? 'federation'));
        const [infoVisible, setInfoVisible] = useState(false);
        if (!group?.accessRequest) return null;

        const menuActions = [
          {
            title: t('cause-of-rejection'),
            icon: faInfoCircle,
            action: async (e) => {
              setInfoVisible(true);
            },
          },
          {
            title: t('view-data'),
            icon: faEye,
            action: async (e) => {
              setRequestPopupOpen(true);
            },
          },
        ];

        return (
          <>
            <MainMenu
              id={row.id}
              menuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              }}
              actions={menuActions}
            />
            <MainDialog
              hideCancel
              width={600}
              buttonText="Close"
              open={infoVisible}
              modalTitle={t('information')}
              onCloseClick={() => setInfoVisible(false)}
              onSaveClick={() => setInfoVisible(false)}
              description={(row as AccessRequestDoc).rejectMessage.length < 1 ? t('no-cause-of-rejection-given') : (row as AccessRequestDoc).rejectMessage}
            />
            <RequestPopup
              active={requestPopupOpen}
              setActive={setRequestPopupOpen}
              config={{ readOnly: true }}
              loading={appealAccessRequestMutation.isLoading || verifyAccessRequest.isLoading}
              topic={t('verification')}
              name={row.organizationName}
              description={`${t('requested-on')} ${new Date(row.timeStamp?.seconds * 1000).toDateString()}`}
              appeal={async (message) => {
                await appealAccessRequestMutation
                  .mutateAsync({ ...(row as AccessRequestDoc), message })
                  .then(() => notify({ type: 'success', title: t('request-rejected') }))
                  .catch((error) => notify({ type: 'error', title: t('error'), text: error }));
              }}
              verify={async () => {
                await verifyAccessRequest
                  .mutateAsync(row as AccessRequestDoc)
                  .then(() => notify({ type: 'success', title: t('request-verified'), text: t('a-new-federation-with-a-user-was-created') }))
                  .catch((error) => notify({ type: 'error', title: t('error'), text: error }));
              }}
              formData={row as AccessRequestDoc}
              FormContent={group.accessRequest.FormContent}
              getInitialValues={group.accessRequest.getInitialValues}
              getSchema={group.accessRequest.getSchema}
            />
          </>
        );
      },
      field: '1',
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Box flex={1}>
      <ContentHeader noBack title={t('rejected-access-request')} subtitle={t('access-requests-rejected-counted', { count: accessRequests?.length })}>
        <AppealRequest
          active={multiAppealOpen}
          setActive={setMultiAppealOpen}
          loading={appealAccessRequestMutation.isLoading}
          appeal={async (message) => {
            const selectedRequests: AccessRequestDoc[] = [];
            for (const request of accessRequests) {
              if (selection.includes(request.id)) {
                selectedRequests.push(request);
              }
            }
            const promises = selectedRequests.map(async (request) => {
              return appealAccessRequestMutation.mutateAsync({ ...(request as AccessRequestDoc), message });
            });
            Promise.all(promises)
              .then(() => {
                notify({ type: 'success', title: t('all-requests-rejected') });
                setSelection([]);
                setMultiAppealOpen(false);
              })
              .catch((error) => {
                notify({ type: 'error', title: t('error'), text: error });
                setMultiAppealOpen(false);
              });
          }}
        />
        <VerifyRequest
          type={undefined}
          active={multiVerifyOpen}
          setActive={setMultiVerifyOpen}
          loading={verifyAccessRequest.isLoading}
          verify={async () => {
            const selectedRequests: AccessRequestDoc[] = [];
            for (const request of accessRequests) {
              if (selection.includes(request.id)) {
                selectedRequests.push(request);
              }
            }
            const promises = selectedRequests.map(async (request) => {
              return verifyAccessRequest.mutateAsync({ ...(request as AccessRequestDoc) });
            });
            Promise.all(promises)
              .then(() => {
                notify({ type: 'success', title: t('all-requests-verified') });
                setSelection([]);
                setMultiVerifyOpen(false);
              })
              .catch((error) => {
                notify({ type: 'error', title: t('error'), text: error });
                setMultiVerifyOpen(false);
              });
          }}
        />
        <InviteUserPopup
          active={invitationEmails}
          setActive={setInvitationEmails}
          sendEmail={async (to: string[], subject: string, message: string) => {
            try {
              await API.email.sendNotificationEmail({ emails: to }, subject, { emailTitle: subject, emailText: message, buttonLink: 'https://ibu-scope.com/', autosend: false });
              setInvitationEmails(false);
              notify({ title: t('successfully-sent'), type: 'success', text: t('invitation-emails-send-successfully') });
            } catch (error) {
              notify({ title: t('error'), type: 'error', text: error });
            }
          }}
        />
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1}></Box>
          <Button variant="contained" sx={{ borderRadius: 999 }} color="primary" endIcon={<FontAwesomeIcon icon={faMailBulk} />} onClick={() => setInvitationEmails(true)}>
            {t('send-invitation-emails')}
          </Button>
        </Stack>
      </ContentHeader>
      {requestsLoading ? (
        <LoadingPopup />
      ) : (
        <>
          <ConditionalRenderWithRights>
            <XGrid
              className={classes.root}
              headerHeight={40}
              disableColumnSelector
              disableSelectionOnClick
              sortModel={[{ field: 'organizationName', sort: 'asc' }]}
              components={{
                NoRowsOverlay: GridNoRowsOverlay,
                NoResultsOverlay: GridNoResultsOverlay,
              }}
              disableColumnMenu
              columns={columns().map((c) => ({
                ...c,
                renderHeader: (params) => {
                  return (
                    <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                      {params.colDef?.headerName}
                    </Typography>
                  );
                },
              }))}
              rows={accessRequests ?? []}
              onRowDoubleClick={(params) => console.log(params)}
            />
          </ConditionalRenderWithRights>
          <ConditionalRenderWithRights invert>
            <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <img src={EmptyState} style={{ width: 175 }} />
                <Typography variant="h2" style={{ marginTop: 20 }}>
                  Currently no projects here.
                </Typography>
                <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
                  Currently there are no application projects assigned to your federation. <br /> We are going to notify you via mail if you are assigned to a new application!
                </Typography>
              </Box>
            </div>
          </ConditionalRenderWithRights>
        </>
      )}
    </Box>
  );
};
