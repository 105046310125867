import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@material-ui/core';
import { LoadingPromiseButton } from '@base/web';
import { StandardPopup } from 'libs/base/web/src/components/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'libs/base/web/src/theme/ThemeProvider';

export const VerifyRequest = ({ active, setActive, verify, loading = false, type }) => {
  const { t } = useTranslation();

  const settings = useSettings();

  const group = settings.namedGroups.find((g) => g.name === type);

  return (
    <StandardPopup visible={active} onBackdropClick={() => (loading ? null : setActive(false))}>
      <Card>
        <CardContent style={{ maxWidth: 800 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3">
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10 }} />
                {t('are-you-sure-to-add-this-federation', { entity: t(type) })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{t('the-federation-will-be-able-to-sign-and-upload-applications-and-add-users-to-their-federation', { entity: t(type) })}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button variant="outlined" color="error" onClick={() => setActive(false)} disabled={loading}>
                  {t('cancel')}
                </Button>
                <LoadingPromiseButton variant="contained" color="primary" onClick={verify}>
                  add {type}
                </LoadingPromiseButton>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </StandardPopup>
  );
};
