import * as React from 'react';

function SvgFirstSlide(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={139.614} height={305.633} {...props}>
      <path d="M101.826 147.884s6.048 7.5 4.156 8.526-6.884 2.935-8.064 1.73-1.559-10.049-1.559-10.049z" fill="#ffb27d" />
      <path d="M101.581 148.035a8.861 8.861 0 013.445 2.146c.972 1.288 3.117 3.247 2.6 3.788s-3.637-1.855-3.637-1.855z" fill="#ffb27d" />
      <path
        d="M78.959 47.694s6.817 63.477 7.425 65.285c.13.39.665 2.4 1.419 5.29a3681.73 3681.73 0 014.032 15.707c2.167 8.521 4.156 16.388 4.156 16.388l5.923-1.953s.307-30.847-1.216-37.929-6.4-50.747-8.833-56.634-12.906-6.154-12.906-6.154z"
        fill="#aec3ff"
      />
      <path
        d="M85.694 195.57l38.447.21a9.124 9.124 0 009.146-9.05l.091-16.654a9.12 9.12 0 00-9.05-9.146l-38.447-.21a9.125 9.125 0 00-9.151 9.05l-.087 16.654a9.125 9.125 0 009.05 9.147z"
        fill="#233862"
      />
      <path
        d="M128.31 170.03l-.09 16.853a9.145 9.145 0 01-4.37 7.734 8.837 8.837 0 01-2.836 1.144 9.023 9.023 0 01-1.977.229l-38.378-.208a9.161 9.161 0 01-9.084-9.183l.095-16.86a9.161 9.161 0 019.183-9.082l13.215.073.022-3.593a3.013 3.013 0 013.026-2.997l1.448.006 8.056.05h2.002a3 3 0 012.121.897 2.966 2.966 0 01.87 2.132l-.016 3.595 7.651.04a9.4 9.4 0 01.895.051 9.15 9.15 0 016.993 4.597 9.037 9.037 0 011.175 4.522zm-32.359-9.31l13.736.073.018-3.595a1.128 1.128 0 00-1.119-1.128l-3.448-.02-6.328-.035-1.707-.012a1.208 1.208 0 00-.337.05 1.138 1.138 0 00-.823 1.065z"
        fill="#2a4e96"
      />
      <path
        d="M98.293 158.306s-1.652-2.78-1.039-3.751 2.847 2.884 2.847 2.884-1.523-3.31-.956-3.855 2.925 3.284 2.925 3.284-1.45-3.216-.94-3.325 2.24 2.707 2.24 2.707-.9-2.411-.078-2.291 2.6 2.307 2.6 2.307z"
        fill="#ffb27d"
      />
      <path d="M87.802 118.27a3501.78 3501.78 0 014.032 15.707 61.152 61.152 0 002.847-12.47c1.372-10.5-4.423-38.034-4.423-38.034z" fill="#9db0f4" />
      <path
        d="M8.43 145.994s-2.472 8.128-2.212 9.97 2.025 8.123 3.237 9.725 1.634-6.61 2.541-7.865.253 3.086.913 3.471 1.97-3.21 2.53-4.49-1.55-5.885-1.615-9.132-5.395-1.68-5.395-1.68z"
        fill="#ffb27d"
      />
      <path
        d="M96.307 305.633H63.438c-2.354-1.6.634-17.208.634-17.208s8.973-3.408 10.521 3.518 11.431 9.113 16.652 9.96c3.55.581 4.709 2.524 5.062 3.73zM47.561 305.633H14.687c-2.354-1.6.639-17.208.639-17.208s8.968-3.408 10.517 3.518 11.431 9.113 16.626 9.96c3.605.581 4.733 2.524 5.092 3.73z"
        fill="#233862"
      />
      <path
        d="M87.465 146.335s-1.476 18.435-3.211 40.595c-2.359 30.1-8.729 104.726-8.729 104.726s-4.156 3.559-13.416 1.611a292.3 292.3 0 01-10.22-55.127l-.109-1.455c-2.193-29.912-5.6-92.9-5.6-92.9z"
        fill="#2b478b"
      />
      <path d="M87.969 139.965s-1.98 24.8-3.715 46.965l-32.365 51.21-.109-1.455c-2.193-29.912-5.6-92.9-5.6-92.9z" fill="#233862" />
      <path
        d="M78.538 139.965s-23.474 58.432-30.977 80.582c-5.346 15.774-20.419 71.12-20.419 71.12s-6.557 2-12.065-1.86c0 0-5.611-56.546 5.716-72.492 6.006-8.459 1.491-87.928 25.859-88.609s31.886 11.259 31.886 11.259z"
        fill="#2b478b"
      />
      <path
        d="M33.807 145.269c9.784 5.523 51.719 5.4 53.657 1.039 1.3-10.645 1.469-58.033 3.383-73.941 0 0-1.04-23.183-3.383-22.16-15.489-7.118-40.558-.655-40.558-.655-6.755 1.99-6.178 17.437-8.064 39.129q-.364 4.193-.81 8.718c-1.336 13.395-3.279 28.78-6.235 45.011-.188 1.098.555 2.044 2.01 2.859z"
        fill="#aec3ff"
      />
      <path
        d="M58.492 46.972c.26 4.157 5.669 3.678 12.169 2.193s4.156-5.315 4.156-5.315-.093-2.946-.519-6.479a59.272 59.272 0 00-.587-3.975 33.043 33.043 0 00-.385-1.9c-.852-3.637-12.99-.13-12.906.852.244 2.647-1.673 11.366-1.928 14.624z"
        fill="#ffb27d"
      />
      <path d="M72.428 54.209l.514-5.731-7.2 1.361 2.769 5.113z" fill="#8a9100" />
      <path
        d="M74.656 41.151l4.3 6.541a9.514 9.514 0 01-3.735 6.942l-5.887-5.477s3.811-1.449 5.322-8.006zM69.336 49.159s-1.216 4.375-3.574 6.38c0 0-9.181-2.468-11.368-7.176l4.78-6.173s.238 4.672 10.162 6.969z"
        fill="#9db0f4"
      />
      <path d="M58.949 34.613c3.598 3.291 10.4 5.177 15.257 5.647.03-.031-.38-2.543-.344-3.89a21.797 21.797 0 00-4.376-1.194z" fill="#e2915a" />
      <path
        d="M56.241 29.526c1.16 8.282 11.776 8.49 16.378 8.178 1.298-.085 2.117-.213 2.117-.213 1.471-.324 2.38-2.58 2.893-5.562 1.016-5.903.46-14.657-.441-16.931-1.36-3.423-4.04-7.175-14.863-5.041s-7.57 8.955-6.084 19.569z"
        fill="#ffb27d"
      />
      <path
        d="M49.447 23.935c2.58 3.206 8.285 9.347 8.285 9.347a12.456 12.456 0 01-.929-3.776 3.358 3.358 0 01.124-1.184 6.556 6.556 0 00-.163-3.753c-.265-.753-.267-1.47.35-1.848 1.02-.623 2.312.422 3.123 1.277a1.964 1.964 0 002.076.5c3.019-1.106.702-4.674 3.387-6.657s12.146-.322 12.096-1.932c3.585-1.128 2.989-8.234 3.003-8.603a35.405 35.405 0 01-10.12-3.214c-4.411-2.16-10.185-.26-12.071.62a2.7 2.7 0 01.501-1.084 2.483 2.483 0 00-1.015 1.378c-.087.056-.124.101-.11.12a3.118 3.118 0 00-1.54.028s.423-.043.563.236a.516.516 0 00.068.078c-7.155 3.074-9.773 15.817-7.628 18.467z"
        fill="#233862"
      />
      <path d="M71.225 31.545s.301 1.079 3.013.816c0 0 2.263-.225 2.15-1.417a7.31 7.31 0 00-5.163.6z" fill="#fff" />
      <path d="M72.427 54.208s5.747 72.575 5.664 78.623-6.365 12.439-6.365 12.439-5-5.481-5.98-11.264 2.769-79.054 2.769-79.054z" fill="#d5e000" />
      <path d="M46.906 49.578s-9.27 2.219-16.6 16.3-13.119 42.481-16.626 49.973-5.9 31.1-5.9 31.1l5.544 2.717s13.374-24.192 15.354-32.126c1.07-4.292 17.427-38.651 17.427-38.651z" fill="#aec3ff" />
      <path d="M33.808 145.27c17.458-2.509 12.47-60.271 11.088-60.516s-6.391 10.329-6.391 10.329l-.478 2.343c-1.335 13.395-3.279 28.78-6.235 45.011-.182 1.071.561 2.017 2.016 2.833z" fill="#9db0f4" />
    </svg>
  );
}

export default SvgFirstSlide;
