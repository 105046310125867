import { Typography } from '@material-ui/core';
import React from 'react';
import { FormContent } from './entities/FormContent';
import { ChatItem, FormSubmission } from '@base/core';
import { diffJson } from 'diff';

export function ConditionalSum({ item, values }: { item: FormContent; values: number[]; }) {
  if (item.type == 'number') {
    const sum = values.reduce((sum, cv) => sum + (cv ?? 0), 0);
    const avg = sum / values.length;
    return (
      <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>
        Sum: {sum}, Avg: {avg.toFixed(2)}
      </Typography>
    );
  }
  return null;
}
function hasChanged<T extends any>(old: T, next: T) {
  if (typeof next === 'object' && next && old) {
    return diffJson(old as any, next as any).length > 1;
  }
  return old !== next;
}
export function getChangeSets(history: (FormSubmission & { timestamp: Date; })[], key: string) {
  const sorted = history.sort((a, b) => a.timestamp.valueOf() - b.timestamp.valueOf());
  const changes = sorted
    .map((item, index) => {
      if (index == 0)
        return;
      const lastItem = sorted[index - 1];
      if (hasChanged(lastItem.data[key], item.data[key]))
        return {
          from: lastItem.data[key],
          to: item.data[key],
          dateSent: item.timestamp,
          id: item.timestamp.toUTCString(),
          type: 'changeset',
        };
    })
    .filter((d) => d);
  return changes;
}
export function getFirstChatToShowIndex(chats: ChatItem[], uid: string) {
  const firstUnreadItem = chats.find((c) => !(c as any).type && !c.read[uid]);
  if (!firstUnreadItem)
    return chats.length - 1;
  return chats.indexOf(firstUnreadItem);
}
