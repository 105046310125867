import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import PhoneNumberInput from 'react-phone-input-material-ui';
import * as yup from 'yup';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { PHONE_NUMBER } from './types';

export const PhoneNumberRenderer: Renderer<undefined, string> = {
  type: PHONE_NUMBER,
  viewer,
  editor,
  viewerMin,
  contentType: 'string',
  defaultEditorValue: '' as any,
  defaultReaderValue: '',
  getValidationSchema,
  getTextRepresentation,
};

function viewer({ onChange, schema, value, required, touched, error }: RendererViewerProps<undefined, string>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={8}>
      <PhoneNumberInput
        inputProps={{
          variant: 'outlined',
          label: t('phone-number'),
          autoComplete: 'off',
          required,
          error: touched && Boolean(error),
          helperText: touched && error,
        }}
        specialLabel={false}
        component={TextField}
        value={value}
        onChange={(v) => onChange?.(v)}
        // name="phoneNumber"
      />
    </Grid>
  );
}

function viewerMin({ onChange, value = '', touched, required, error }: RendererViewerProps<undefined, string>): JSX.Element {
  return <div>{value}</div>;
}

async function getTextRepresentation({ onChange, value = '', touched, required, error }: RendererViewerProps<undefined, string>) {
  return `<div>${value}</div>`;
}

function editor({ onChange, value }: RendererEditorProps<undefined, string>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} md={8}>
      <PhoneNumberInput
        inputProps={{
          variant: 'outlined',
          label: t('phone-number'),
          autoComplete: 'off',
          required: true,
          disabled: true,
        }}
        specialLabel={false}
        component={TextField}
        // name="phoneNumber"
      />
    </Grid>
  );
}

function getValidationSchema(schema, t) {
  let validator = yup.string();
  if (schema.content == 'email') {
    validator = validator.email(t('enter-a-valid-email'));
    if (schema.required) {
      validator = validator.required(t('something-is-required', { something: t('email') }));
    }
  } else if (schema.required) {
    validator = validator.required(t('something-is-required', { something: t('question') }));
  }
  return validator;
}
