import React from 'react';
import { Typography, List, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Theme } from '@material-ui/core/styles';
import { withStyles, makeStyles, WithStyles } from '@material-ui/styles';

import { createStyles } from '@material-ui/styles';

type sidebarListProps = {
  title: string;
  theme: any;
  children: any;
  noDivider?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      paddingTop: 0,
      '& .MuiListItem-root': {
        paddingTop: 12,
        paddingBottom: 12,
        borderBottom: `1.5px solid ${theme.palette.background.gray}`,
      },
      '& .MuiListItemText-root': {
        fontSize: 15, //doesnt work
      },
    },
  }),
);

export const SidebarList = ({ title, theme, children, noDivider }: sidebarListProps) => {
  //   const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles(theme);

  return (
    <>
      <Typography variant="subtitle2" fontWeight="600" style={{ paddingLeft: theme.spacing(2), marginTop: theme.spacing(2), marginBottom: theme.spacing(1) }}>
        {title.toUpperCase()}
      </Typography>
      {!noDivider && <Divider sx={{ height: '1.5px' }} />}
      <List className={classes.list}>{children}</List>
    </>
  );
};
