import * as React from 'react';

function SvgListUlRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.533 20.746" ref={svgRef} {...props}>
      <path d="M2.394 15.958a2.394 2.394 0 102.394 2.394 2.394 2.394 0 00-2.394-2.394zm0-7.979a2.394 2.394 0 102.394 2.394 2.394 2.394 0 00-2.394-2.394zm0-7.979a2.394 2.394 0 102.394 2.394A2.394 2.394 0 002.394 0zm22.341 1.2H8.777a.8.8 0 00-.8.8v.8a.8.8 0 00.8.8h15.958a.8.8 0 00.8-.8V2a.8.8 0 00-.8-.8zm0 7.979H8.777a.8.8 0 00-.8.8v.8a.8.8 0 00.8.8h15.958a.8.8 0 00.8-.8v-.8a.8.8 0 00-.8-.803zm0 7.979H8.777a.8.8 0 00-.8.8v.8a.8.8 0 00.8.8h15.958a.8.8 0 00.8-.8v-.8a.8.8 0 00-.8-.803z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgListUlRegular);
export default ForwardRef;
