import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, ListItemIcon, ListItemText, MenuItem, Select, Stack, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRightsFromRole, getRoleFromGroupRights, UserGroupRights } from './UserGroupRightsData';

export function UserGroupRightsSelect(props) {
  const { t } = useTranslation();
  const role = getRoleFromGroupRights(props.value, props.groupId);
  const selectedRole = UserGroupRights.find((r) => r.name == role);
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} md={6} mt={2}>
        <Stack spacing={2}>
          <Typography variant="overline">{t('rights')}</Typography>
          <Select
            {...props}
            value={role}
            fullWidth
            onChange={(e) => {
              props.setFieldValue(props.name, getRightsFromRole(e.target.value as string, props.groupId));
            }}
            variant="outlined"
            renderValue={(role) => {
              const selectedRole = UserGroupRights.find((r) => r.name == role);
              if (selectedRole)
                return (
                  <Stack display="flex" alignItems="center" direction="row" spacing={2}>
                    <FontAwesomeIcon icon={selectedRole.icon} fixedWidth />
                    <Typography>{selectedRole.name}</Typography>
                  </Stack>
                );
              return <Typography>t('select-role')</Typography>;
            }}
          >
            {UserGroupRights.map((role, i) => {
              return (
                <MenuItem key={i} value={role.name}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={role.icon} fixedWidth />
                  </ListItemIcon>
                  <ListItemText>{role.name}</ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
      </Grid>
      <Grid item xs={12} md={12} mt={2}>
        <Stack spacing={2}>
          <Box sx={{ backgroundColor: theme.palette.info.lighter, color: theme.palette.info.main, borderRadius: 3, padding: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FontAwesomeIcon style={{ height: 28, width: 28 }} icon={selectedRole.icon} />
            <Typography style={{ marginLeft: 12 }} variant="subtitle1">
              {t(selectedRole.i18nDescription)}
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </>
  );
}
