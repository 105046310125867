import { Group } from '@base/core';
import { faPlusCircle, faSave, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Grid, ListItem, ListItemIcon, ListItemText, MenuItem, TextField, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTheme as useSettings } from '../../../theme/ThemeProvider';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { StandardPopup, usePopup } from '../../../components/common';
import { diffArrays } from 'diff';

interface AddGroupsPopupProps {
  open?: boolean;
  onClose(): void;
  selectedGroups: string[];
  groups: Group[];
  onSave(added: string[], removed: string[], nextValue: string[]): Promise<any>|void;
}



export function AddGroupsPopup({ onClose, open = false, onSave, groups, selectedGroups }: AddGroupsPopupProps) {
  const theme = useTheme();
  const notify = usePopup();
  const { t, i18n } = useTranslation();
  const settings = useSettings();
  const iconMap = settings.namedGroups.reduce((map, v) => ({ ...map, [v.name]: v.icon }), {});
  return (
    <StandardPopup visible={open} onBackdropClick={onClose} width={750}>
      <Formik
        initialValues={useMemo(()=>({ groups: selectedGroups.map((id) => groups.find((group) => group.id == id)) }), [selectedGroups, groups])}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const diff = diffArrays(
              selectedGroups,
              values.groups.map((group) => group.id),
            );
            const added = diff.filter((d) => d.added).flatMap((d) => d.value);
            const removed = diff.filter((d) => d.removed).flatMap((d) => d.value);
            await onSave(added, removed, values.groups.map((group) => group.id));
            onClose();
          } catch (error) {
            notify({
              title: t('error'),
              type: 'error',
              text: error.message,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, setFieldValue, values,submitForm }) => (
          <Card>
            <CardHeader
              title={t('add-groups')}
              titleTypographyProps={{ variant: 'h2' }}
              style={{ background: theme.palette.background.gray, paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Autocomplete
                    options={groups}
                    autoSelect
                    value={values.groups}
                    onChange={(_, v) => setFieldValue('groups', v)}
                    multiple
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Select groups" placeholder="add group" />}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <MenuItem {...props}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={iconMap[option.type] ?? faUsers} />
                        </ListItemIcon>
                        <ListItemText>{option.name}</ListItemText>
                      </MenuItem>
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ paddingX: 3, paddingBottom: 3, justifyContent: 'flex-end' }}>
              <Button variant="outlined" color="error" onClick={onClose}>
                {t('cancel')}
              </Button>
              <LoadingButton startIcon={<FontAwesomeIcon icon={faSave} />} variant="contained" onClick={submitForm} loading={isSubmitting}>
                {t('save')}
              </LoadingButton>
            </CardActions>
          </Card>
        )}
      </Formik>
    </StandardPopup>
  );
}
