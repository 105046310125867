import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { API, archivedEmail } from '@base/core';

export function useGetArchivedEmails(autosend = false, queryOptions?: UseQueryOptions<archivedEmail[], unknown, archivedEmail[]>) {
  return useQuery([autosend ? 'archivedEmailNotifications' : 'archivedCustomEmails'], async () => {
    return await API.email.getArchivedEmails(autosend);
  });
}

export function useDeleteArchiveEmailMutation(autosend = false) {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      return await API.email.deleteArchivedEmail(id);
    },
    {
      onSuccess: async (data, id, context) => {
        await queryClient.invalidateQueries([autosend ? 'archivedEmailNotifications' : 'archivedCustomEmails', id]);
        queryClient.setQueryData([autosend ? 'archivedEmailNotifications' : 'archivedCustomEmails'], (old: archivedEmail[]) => {
          if (!old) return old;
          let newData = { ...old };
          newData = old.filter((mail) => mail.id != id);
          return newData;
        });
      },
    },
  );
}
