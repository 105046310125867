import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTheme } from '../../theme/ThemeProvider';

export const TextField = ({ text, describtion = undefined, placeholder = '' }) => {
  return (
    <div className="text-field">
      {describtion && <div className="text-field-describtion">{describtion}</div>}
      <div className="text-field-text">{text ? text : placeholder}</div>
    </div>
  );
};

export const CheckField = ({ describtion, checked = false }) => {
  const theme = useTheme();
  return (
    <div className="check-field">
      <div className="check-field-describtion">{describtion}</div>
      <FontAwesomeIcon className="check-field-icon" icon={checked ? faCheckCircle : faTimesCircle} color={checked ? theme.colors.right : theme.colors.error} />
    </div>
  );
};
