import { AccessRequestDoc, AccessRequestFileProps, AccessRequestFormData, IAccessRequestService } from '@base/core';
import cuid from 'cuid';
import { firebase } from '../config';
import { FirebaseQuery } from './FirebaseQuery';

export class AccessRequestService implements IAccessRequestService {
  constructor(private collectionRoot: string, private accessRequestStorageBucket: string) {}

  /**
   * uploads files to firebase storage and creates a firestore document with references to the file and the form data
   * @param accessFormData The data from the access form.
   */
  async sendAccessRequest(accessFormData: AccessRequestFormData) {
    // upload access form data + upload access document
    // send request notification to admins
    let finishedFormFiles: AccessRequestFileProps[] = [];

    if (accessFormData.files && accessFormData.files.length > 0) {
      const formFiles = accessFormData.files.map(async (file) => {
        return this.uploadAccessRequestFile(file);
      });
      finishedFormFiles = await Promise.all(formFiles);
    }
    //accessFormData should not be uploaded to the DB because it contains a physical File array
    await this.geAccessRequestCollection().add({ ...accessFormData, files: finishedFormFiles, timeStamp: firebase.firestore.FieldValue.serverTimestamp(), rejected: false });
  }

  /**
   * Uploads the new file and updates the access request document without the id
   * @param accessRequestDoc the updated access request document to upload with the same id
   * @returns
   */
  async updateAccessRequest(accessRequestDoc: AccessRequestDoc): Promise<AccessRequestDoc> {
    const { id, ...saveData } = accessRequestDoc as any;
    await this.geAccessRequestCollection().doc(accessRequestDoc.id).update(saveData);
    return await this.getAccessRequest(accessRequestDoc.id);
  }

  /**
   * Get a single access request by its id
   * @param requestId
   * @returns
   */
  async getAccessRequest(requestId: string): Promise<AccessRequestDoc> {
    const sn = await this.geAccessRequestCollection().doc(requestId).get();
    const data = sn.data() as AccessRequestDoc;
    if (data === undefined) throw new Error('Could not get access request with id: ' + requestId);
    return { ...data, id: sn.id };
  }

  /**
   * Loads all pending access request documents from the firebase
   * @returns Promise with an Array of Access request documents
   */
  async getAllPendingAccessRequests(): Promise<AccessRequestDoc[]> {
    // const q = this.geAccessRequestCollection();
    // return new FirebaseQuery<AccessRequestDoc>(q, async (d) => await this.transformSnToDocument(d)).getNextPage(100);
    const snapshot = await this.geAccessRequestCollection().where('rejected', '==', false).get();
    const rejectedRequests = snapshot.docs.map((documentSnapshot) => {
      return this.transformSnToDocument(documentSnapshot);
    }) as Promise<AccessRequestDoc>[];
    return await Promise.all(rejectedRequests);
  }

  /**
   * Loads all rejected Access Requests from the firebase
   * @returns Promise with an Array of rejected Access request documents
   */
  async getAllRejectedAccessRequests(): Promise<AccessRequestDoc[]> {
    const snapshot = await this.geAccessRequestCollection().where('rejected', '==', true).get();
    const rejectedRequests = snapshot.docs.map((documentSnapshot) => {
      return this.transformSnToDocument(documentSnapshot);
    }) as Promise<AccessRequestDoc>[];
    return await Promise.all(rejectedRequests);
  }

  /**
   * Deletes the request doc und all of its storage files
   * @param request
   */
  async deleteAccessRequest(request: AccessRequestDoc): Promise<void> {
    try {
      if (request.files && request.files.length > 0) {
        request.files.forEach(async (file) => {
          await this.deleteAccessRequestFile(file.firebasePath);
        });
      }
      await this.geAccessRequestCollection().doc(request.id).delete();
    } catch (error) {
      throw new Error('Could not delete files from access request. Access data: ' + request + 'error: ' + typeof error == 'object' ? JSON.stringify(error) : error);
    }
  }

  /**
   * Deletes the request doc und all of its storage files
   * @param filePath the firebase.storage.Reference to the old file to delete it
   */
  async deleteAccessRequestFile(filePath: string): Promise<void> {
    await firebase.storage().ref(filePath).delete();
  }

  /**
   * Deletes only the access document without the files in the storage
   * @param request
   */
  async deleteAccessRequestWithoutFile(request: AccessRequestDoc): Promise<void> {
    try {
      await this.geAccessRequestCollection().doc(request.id).delete();
    } catch (error) {
      throw new Error('Could not delete firestore doc of access request. Access data: ' + request + 'error: ' + typeof error == 'object' ? JSON.stringify(error) : error);
    }
  }

  /**
   * Merges the access request documents with its document id and adds the download urls to the file array
   * @param rawDocument the snapshot of the firebase access request doc
   * @returns
   */
  private async transformSnToDocument(rawDocument): Promise<AccessRequestDoc> {
    const request = {
      ...(rawDocument.data() as AccessRequestDoc),
      id: rawDocument.id,
    };
    if (request.files) {
      request.files = await Promise.all(
        request.files.map(async (file) => {
          file.downloadUrl = await firebase.storage().ref(file.firebasePath).getDownloadURL();
          return file;
        })
      );
    } else {
      request.files = [];
    }
    return request;
  }
  /**
   * Upload Access request file
   * @param file the file as Blob object
   * @returns filename and the persistent path on the storage bucket
   */
  async uploadAccessRequestFile(file: File): Promise<AccessRequestFileProps> {
    try {
      const task = this.getAccessRequestStorageRef().child(cuid()).put(file);
      const sn = await task;
      return { name: file.name, firebasePath: (await sn.ref).fullPath };
    } catch (error) {
      throw new Error('Could not upload file: ' + typeof error == 'object' ? JSON.stringify(error) : error);
    }
  }

  private getAccessRequestStorageRef() {
    return firebase.storage().ref(this.accessRequestStorageBucket);
  }

  private geAccessRequestCollection() {
    return firebase.firestore().collection(this.collectionRoot);
  }
}
