import * as React from 'react';

function SvgEyeStrike(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.294 25.036" ref={svgRef} {...props}>
      <path d="M15.647 19.559A7.015 7.015 0 018.66 13.05L3.531 9.086a16.3 16.3 0 00-1.8 2.718 1.582 1.582 0 000 1.427 15.683 15.683 0 0013.916 8.672 15.188 15.188 0 003.809-.511l-2.537-1.961a7.048 7.048 0 01-1.271.128zm15.345 2.844l-5.406-4.178a16.2 16.2 0 003.973-4.991 1.582 1.582 0 000-1.427 15.683 15.683 0 00-13.912-8.678 15.068 15.068 0 00-7.2 1.843L2.223.165a.782.782 0 00-1.098.138l-.96 1.235a.782.782 0 00.134 1.097L29.072 24.87a.782.782 0 001.1-.137l.96-1.236a.782.782 0 00-.14-1.094zm-8.983-6.947l-1.922-1.485a4.633 4.633 0 00-5.677-5.962 2.33 2.33 0 01.455 1.379 2.281 2.281 0 01-.075.489l-3.6-2.782a6.959 6.959 0 014.457-1.619 7.037 7.037 0 017.041 7.041 6.874 6.874 0 01-.68 2.939z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEyeStrike);
export default ForwardRef;
