import { faAlignLeft, faCaretDown, faCheckSquare, faFile, faGripLines, faSquare, faCalendarDay, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faDotCircle, faTable } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, ListItemIcon, ListItemText, ListSubheader, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import {
  CHECKBOXES,
  DATE,
  DROP_DOWN_MENU,
  FILE_UPLOAD,
  PROVIDE_FILES,
  LINEAR_SCALE,
  LONG_PARAGRAPH,
  MULTIPLE_CHOICE_QUESIONS,
  SHORT_ANSWER,
  NUMBER,
  DATE_RANGE,
  PHONE_NUMBER,
  TABLE,
} from '../content-renderer/types';
import * as types from '../content-renderer/types';

export function QuestionSelectDropDown({ onChange, value }: { value: string; onChange: (value: string) => void }) {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel id="question-type-label">Inputtype</InputLabel>
      <Select
        labelId="question-type-label"
        label="Inputtype"
        fullWidth
        variant="outlined"
        defaultValue={SHORT_ANSWER}
        SelectDisplayProps={{
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        }}
        value={value}
        onChange={(e) => {
          if (e.target.value) {
            onChange(e.target.value);
          }
        }}
      >
        <ListSubheader>{t('text-only')}</ListSubheader>
        <MenuItem value={SHORT_ANSWER}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faGripLines} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(SHORT_ANSWER)} />
        </MenuItem>
        <MenuItem value={LONG_PARAGRAPH}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faAlignLeft} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(LONG_PARAGRAPH)} />
        </MenuItem>
        <MenuItem value={NUMBER}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faAlignLeft} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(NUMBER)} />
        </MenuItem>
        <MenuItem value={PHONE_NUMBER}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faAlignLeft} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(PHONE_NUMBER)} />
        </MenuItem>
        <ListSubheader>{t('multi-select')}</ListSubheader>
        <MenuItem value={MULTIPLE_CHOICE_QUESIONS}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faDotCircle} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(MULTIPLE_CHOICE_QUESIONS)} />
        </MenuItem>
        <MenuItem value={CHECKBOXES}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCheckSquare} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(CHECKBOXES)} />
        </MenuItem>
        <MenuItem value={DROP_DOWN_MENU}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCaretDown} fontSize="1x" mask={faSquare} fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(DROP_DOWN_MENU)} />
        </MenuItem>
        <ListSubheader>{t('special')}</ListSubheader>
        <MenuItem value="linear-scale">
          <ListItemIcon>
            <FontAwesomeIcon icon={faEllipsisH} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(LINEAR_SCALE)} />
        </MenuItem>
        <MenuItem value={DATE}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCalendarDay} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(DATE)} />
        </MenuItem>
        <MenuItem value={DATE_RANGE}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCalendarDay} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(DATE_RANGE)} />
        </MenuItem>
        <MenuItem value={FILE_UPLOAD}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faFile} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(FILE_UPLOAD)} />
        </MenuItem>
        <MenuItem value={PROVIDE_FILES}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faFile} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(PROVIDE_FILES)} />
        </MenuItem>
        <MenuItem value={TABLE}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faTable} fontSize="1x" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t(TABLE)} />
        </MenuItem>
        {/* {inputtypes.map((group) => (
           <React.Fragment key={group.group}>
             <ListSubheader>{t(group.group)}</ListSubheader>
             {group.elements.map((element) => (
               <MenuItem key={element.type} value={element.type} name={t(element.type)} label="select asldkjasdlkj">
                 <ListItemIcon>{element.icon}</ListItemIcon>
                 <ListItemText primary={t(element.type)} />
               </MenuItem>
             ))}
           </React.Fragment>
          ))} */}
      </Select>
    </FormControl>
  );
}
