import '../../css/components/user/SelectableUser.scss';

import React, { useState } from 'react';
import { ConditionalRender, UserImageHandler } from '../common';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { joinCssClasses } from '@base/web';
import { useTranslation } from 'react-i18next';

interface SelectableUserProps {
  userName: string;
  userArea: string;
  userImage?: any;
  selected: boolean;
  onReSelectClick: (selected: boolean) => void;
}
export const SelectableUser = ({ userName, userArea, userImage, selected, onReSelectClick }: SelectableUserProps) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="selectable-user" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => onReSelectClick(selected ? false : true)}>
      <div style={{ justifyContent: 'flex-start', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <UserImageHandler userName={userName} userPhotoUrl={userImage} style={{ height: 64, width: 64, marginRight: 20, borderStyle: 'solid', borderColor: '#efefef' }} fontSize={28} />
        <div className="column">
          <div>
            <div className="name">{userName}</div>
            <ConditionalRender render={!(hover && selected)}>
              <div className="subtitle">{userArea}</div>
            </ConditionalRender>
          </div>
        </div>
      </div>
      <div className={joinCssClasses({ 'select-indicator': true, unselect: hover && selected, selected: !hover && selected })}>
        <ConditionalRender render={selected}>
          <FontAwesomeIcon icon={hover ? faTimes : faCheck} style={{ fontSize: 16, color: '#fff' }} />
        </ConditionalRender>
        <ConditionalRender render={hover && selected}>
          <div className="indicator-text">{t('remove')}</div>
        </ConditionalRender>
      </div>
    </div>
  );
};
