import { InviteUserPopup, RequestPopup } from '@access-request';
import { AccessRequestDoc, API, Group } from '@base/core';
import { faEdit, faMailBulk, faShare, faUserPlus, faUserTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Link, Stack, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, XGrid } from '@material-ui/x-grid';
import * as countries from 'i18n-iso-countries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppealRequest } from '../../../../../access-request/src/lib/components/AppealRequest';
import { VerifyRequest } from '../../../../../access-request/src/lib/components/VerifyRequest';
import { IconMap } from '../../../../../modules/country-icons/src/lib/country-icons';
import SearchIcon from '../../assets/universal/search-icon.svg';
import EmptyState from '../../assets/universal/new-empty-state.svg';
import { ConditionalRender, ContentHeader, LoadingPopup, usePopup } from '../../components/common';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { ConditionalRenderWithRights } from '../../helpers/ConditionalRenderWithRights';
import { useRejectAccessRequestMutation, useGetAllPendingAccessRequests, useVerifyAccessRequestMutation } from './hooks/useAccessRequest';
import { useSettings } from '../../theme/ThemeProvider';
import { GridNoResultsOverlay } from '../../components/content/GridContent';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const useStyles = makeStyles({
  root: (theme: any) => ({
    flex: 1,
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
  lastCell: {
    minWidth: '300px !important',
    position: 'absolute',
    right: 14,
    zIndex: 10,
  },
});

export const AccessRequest = () => {
  const theme = useTheme();
  const notification = usePopup();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const notify = usePopup();
  const [invitationEmails, setInvitationEmails] = useState(false);
  const [selection, setSelection] = useState([]);
  const [multiAppealOpen, setMultiAppealOpen] = useState(false);
  const [multiVerifyOpen, setMultiVerifyOpen] = useState(false);
  const { isLoading: requestsLoading, data: accessRequests } = useGetAllPendingAccessRequests();
  const appealAccessRequestMutation = useRejectAccessRequestMutation();
  const verifyAccessRequest = useVerifyAccessRequestMutation();

  const columns: (lastCellClassName: string) => GridColDef[] = (lastCellClassName: string) => [
    {
      field: 'country',
      width: 55,
      renderCell: ({ formattedValue, value, row }) => {
        const key = countries.getAlpha2Code(value as string, 'en');
        return value ? <img src={IconMap[key]} alt="flag" style={{ borderRadius: '50%', height: 28 }} /> : <div style={{ borderRadius: '50%', height: 28, backgroundColor: 'grey' }} />;
      },
    },
    {
      field: 'organizationName',
      width: 250,
      headerName: 'Organization Name',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'displayName',
      width: 225,
      headerName: 'Name',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'type',
      width: 70,
      headerName: 'Type',
      renderCell: ({ formattedValue, value = 'federation', row }) => {
        return <Typography variant="subtitle1">{t((value as string) + '-short')}</Typography>;
      },
    },
    {
      field: 'phoneNumber',
      width: 170,
      headerName: 'Phone Number',
      renderCell: ({ formattedValue, value, row }) => {
        return (
          <Link href={'tel:' + value} onClick={(e) => e.stopPropagation()} variant="subtitle1">
            {'+' + value}
          </Link>
        );
      },
    },
    {
      field: 'email',
      width: 300,
      headerName: 'E-Mail',
      renderCell: ({ formattedValue, value, row }) => {
        return (
          <Link href={'mailto:' + value} onClick={(e) => e.stopPropagation()} variant="subtitle1">
            {value}
          </Link>
        );
      },
    },
    {
      flex: 1,
      align: 'right',
      cellClassName: lastCellClassName,
      renderCell: ({ formattedValue, value, row }) => {
        const [requestPopupOpen, setRequestPopupOpen] = useState(undefined);

        const appealAccessRequestMutation = useRejectAccessRequestMutation();
        const verifyAccessRequest = useVerifyAccessRequestMutation();
        const settings = useSettings();

        const group = settings.namedGroups.find((g) => g.name === ((row as AccessRequestDoc).type ?? 'federation'));
        console.log('🚀 ~ file: AccessRequest.tsx ~ line 179 ~ AccessRequest ~ row', row);

        if (!group || !group.accessRequest) return null;
        return (
          <>
            <Button
              sx={{ borderRadius: 999, color: 'info.main', backgroundColor: 'info.lighter', ':hover': { bgcolor: 'info.main', color: 'white' } }}
              onClick={() => setRequestPopupOpen(row as AccessRequestDoc)}
              variant="contained"
              endIcon={<FontAwesomeIcon icon={faEdit} />}
            >
              {t('verify-request')}
            </Button>
            <RequestPopup
              active={requestPopupOpen}
              setActive={setRequestPopupOpen}
              config={{ rejectAcceptMode: true, readOnly: true }}
              loading={appealAccessRequestMutation.isLoading || verifyAccessRequest.isLoading}
              topic={t('verification')}
              name={row?.organizationName}
              description={`${t('requested-on')} ${new Date(row?.timeStamp?.seconds * 1000).toDateString()}`}
              appeal={async (message) => {
                await appealAccessRequestMutation
                  .mutateAsync({ ...(row as AccessRequestDoc), message })
                  .then(() => notify({ type: 'success', title: t('request-rejected') }))
                  .catch((error) => notify({ type: 'error', title: t('error'), text: error }));
              }}
              verify={async () => {
                await verifyAccessRequest
                  .mutateAsync(row as AccessRequestDoc)
                  .then(() => notify({ type: 'success', title: t('request-verified'), text: t('a-new-federation-with-a-user-was-created') }))
                  .catch((error) => notify({ type: 'error', title: t('error'), text: error }));
              }}
              formData={requestPopupOpen}
              FormContent={group.accessRequest.FormContent}
              getInitialValues={group.accessRequest.getInitialValues}
              getSchema={group.accessRequest.getSchema}
            />
          </>
        );
      },
      field: '1',
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Box flex={1}>
      <ContentHeader noBack title={t('pending-access-requests')} subtitle={t('access-requests-counted', { count: accessRequests?.length })}>
        <AppealRequest
          active={multiAppealOpen}
          setActive={setMultiAppealOpen}
          loading={appealAccessRequestMutation.isLoading}
          appeal={async (message) => {
            const selectedRequests: AccessRequestDoc[] = [];
            for (const request of accessRequests) {
              if (selection.includes(request.id)) {
                selectedRequests.push(request);
              }
            }
            const promises = selectedRequests.map(async (request) => {
              return appealAccessRequestMutation.mutateAsync({ ...(request as AccessRequestDoc), message });
            });
            Promise.all(promises)
              .then(() => {
                notification({ type: 'success', title: t('all-requests-rejected') });
                setSelection([]);
                setMultiAppealOpen(false);
              })
              .catch((error) => {
                notification({ type: 'error', title: t('error'), text: error });
                setMultiAppealOpen(false);
              });
          }}
        />
        <VerifyRequest
          type={undefined}
          active={multiVerifyOpen}
          setActive={setMultiVerifyOpen}
          loading={verifyAccessRequest.isLoading}
          verify={async () => {
            const selectedRequests: AccessRequestDoc[] = [];
            for (const request of accessRequests) {
              if (selection.includes(request.id)) {
                selectedRequests.push(request);
              }
            }
            const promises = selectedRequests.map(async (request) => {
              return verifyAccessRequest.mutateAsync({ ...(request as AccessRequestDoc) });
            });
            Promise.all(promises)
              .then(() => {
                notification({ type: 'success', title: t('all-requests-verified') });
                setSelection([]);
                setMultiVerifyOpen(false);
              })
              .catch((error) => {
                notification({ type: 'error', title: t('error'), text: error });
                setMultiVerifyOpen(false);
              });
          }}
        />
        <InviteUserPopup
          active={invitationEmails}
          setActive={setInvitationEmails}
          sendEmail={async (to: string[], subject: string, message: string) => {
            try {
              await API.email.sendNotificationEmail({ emails: to }, subject, { emailTitle: subject, emailText: message, buttonLink: 'https://ibu-scope.com/', autosend: false });
              setInvitationEmails(false);
              notify({ title: t('successfully-sent'), type: 'success', text: t('invitation-emails-send-successfully') });
            } catch (error) {
              notify({ title: t('error'), type: 'error', text: error });
            }
          }}
        />
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1}></Box>
          <Button variant="contained" sx={{ borderRadius: 999 }} color="primary" endIcon={<FontAwesomeIcon icon={faMailBulk} />} onClick={() => setInvitationEmails(true)}>
            {t('send-invitation-emails')}
          </Button>
          <ConditionalRender render={selection.length > 0}>
            <Button
              sx={{ borderRadius: 999 }}
              onClick={() => {
                setMultiAppealOpen(true);
              }}
              variant="contained"
              color="error"
              endIcon={<FontAwesomeIcon icon={faUserTimes} />}
            >
              {t('reject-selected')}
            </Button>
            <Button
              sx={{ borderRadius: 999 }}
              onClick={() => {
                setMultiVerifyOpen(true);
              }}
              variant="contained"
              color="primary"
              endIcon={<FontAwesomeIcon icon={faUserPlus} />}
            >
              {t('verify-selected')}
            </Button>
          </ConditionalRender>
        </Stack>
      </ContentHeader>
      {requestsLoading ? (
        <LoadingPopup />
      ) : (
        <>
          <ConditionalRenderWithRights>
            <XGrid
              className={classes.root}
              headerHeight={40}
              disableColumnSelector
              disableSelectionOnClick
              sortModel={[{ field: 'organizationName', sort: 'asc' }]}
              components={{
                NoRowsOverlay: () => {
                  return (
                    <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                      <Box
                        sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                      >
                        <img src={EmptyState} style={{ width: 175 }} />
                        <Typography variant="h2" style={{ marginTop: 20 }}>
                          Currently no access requests here.
                        </Typography>
                        <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
                          Currently there are no federation requesting for access.
                        </Typography>
                      </Box>
                    </div>
                  );
                },
                NoResultsOverlay: GridNoResultsOverlay,
              }}
              disableColumnMenu
              checkboxSelection
              onSelectionModelChange={({ selectionModel }) => {
                setSelection(selectionModel);
              }}
              columns={columns(classes.lastCell).map((c) => ({
                ...c,
                renderHeader: (params) => {
                  return (
                    <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                      {params.colDef?.headerName}
                    </Typography>
                  );
                },
              }))}
              rows={accessRequests ?? []}
              // onRowClick={(params) => setRequestPopupOpen(params.row)} // Das geht so nicht....
            />
          </ConditionalRenderWithRights>
          <ConditionalRenderWithRights invert>
            <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <img src={EmptyState} style={{ width: 175 }} />
                <Typography variant="h2" style={{ marginTop: 20 }}>
                  Currently no projects here.
                </Typography>
                <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
                  Currently there are no application projects assigned to your federation. <br /> We are going to notify you via mail if you are assigned to a new application!
                </Typography>
              </Box>
            </div>
          </ConditionalRenderWithRights>
        </>
      )}
    </Box>
  );
};
