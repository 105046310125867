import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { InfoPopup } from '../..';
import '../../css/Views/ModulView.scss';

type contentHeaderProps = {
  title: string;
  subtitle: string;
  href?: string;
  children?: any;
  dark?: boolean;
  noBack?: boolean;
  infoDescription?: string;
};

export const ContentHeader = ({ title, subtitle, children, href, dark, noBack, infoDescription }: contentHeaderProps) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: dark ? theme.palette.background.grayDark : theme.palette.background.gray,
        height: 50,
        justifyContent: 'space-between',
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!noBack && (
          <IconButton onClick={href ? () => history.push(href) : () => history.goBack()} LinkComponent={Link} size="medium" sx={{ marginRight: 1 }}>
            <FontAwesomeIcon color="#000000" icon={faChevronLeft} fixedWidth />
          </IconButton>
        )}
        <div>
          <Typography variant="h5" fontWeight="600">
            {title}
          </Typography>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </div>
        {infoDescription && <InfoPopup description={infoDescription} buttonStyle={{ marginLeft: 24 }} />}
      </div>
      {children}
    </div>
  );
};
