import { Editor as CFEditor } from '@atlaskit/editor-core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faCopy, faGripHorizontal, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Card, CardActions, CardContent, Divider, Grid, IconButton, TextField, Typography, useTheme } from '@material-ui/core';
import { withStyles, makeStyles, WithStyles } from '@material-ui/styles';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContent } from '../entities/FormContent';
import { cx } from '../helpers';
import {ConfluenceEditor} from "./ConfluenceEditor";

const useStyles = makeStyles({
  editorWrapper: (theme: any) => ({
    color: theme.palette.primary.main,
    '& p,h1,h2,h3,h4,h5,h6': {
      color: theme.palette.primary.main,
    },
  }),
});

export function Information({
  value,
  onChange,
  changeType,
  onRemove,
  focused,
  duplicate,
  setFocus,
}: {
  value: FormContent;
  onChange: (val: FormContent) => void;
  changeType: (newType: string) => void;
  onRemove: () => void;
  focused: boolean;
  duplicate: () => void;
  setFocus: (ref: HTMLDivElement) => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const rootref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focused) {
      setFocus(rootref.current);
    }
  }, [focused]);

  const classes = useStyles(theme);

  return (
    <Grid item xs={12} ref={rootref}>
      <div
        style={style}
        className={cx('question__wrapper', focused && 'focused')}
        onFocus={() => {
          console.log(value.id, ':focus');
          setFocus(rootref.current);
        }}
        // onBlur={() => !draggingRef.current && setFocused(false)}
        tabIndex={0}
        ref={setNodeRef}
        {...attributes}
      >
        <Card style={{ border: '2px solid ' + theme.palette.primary.main }}>
          <div className="drag-handle__wrapper" onMouseDown={(e) => e.preventDefault()} onMouseUp={(e) => e.preventDefault()} onClick={() => setFocus(rootref.current)} {...listeners}>
            <Box padding={2} flex={1} justifyContent="center" alignItems="center" flexDirection="column" display="flex">
              <FontAwesomeIcon icon={faGripHorizontal} color={alpha(theme.palette.text.primary, 0.2)} size="1x" />
            </Box>
          </div>
          <CardContent style={{ paddingTop: focused ? undefined : 0, marginTop: focused ? undefined : -8 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {focused && (
                  <TextField
                    fullWidth
                    autoFocus
                    onFocus={(e) => e.target.select()}
                    label="Title"
                    onChange={(e) => {
                      const val = e.target.value;
                      onChange({ ...value, title: val });
                    }}
                    value={value.title}
                  />
                )}
                {!focused && (
                  <Box display="flex" alignItems="center">
                    <FontAwesomeIcon icon={faInfoCircle} size="2x" style={{ marginRight: 16 }} color={theme.palette.primary.main} />
                    <Typography
                      variant="h4"
                      //@ts-ignore
                      color="primary"
                    >
                      {value.title}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} className={classes.editorWrapper}>
                <ConfluenceEditor
                  value={value}
                  onChange={onChange}
                  focused={focused}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions style={{ flexDirection: 'row-reverse' }} className="card-action__wrapper">
            <IconButton onClick={onRemove}>
              <FontAwesomeIcon icon={faTrash} color={theme.palette.error.main} size="xs" />
            </IconButton>
            <IconButton onClick={duplicate}>
              <FontAwesomeIcon icon={faCopy} size="xs" />
            </IconButton>
          </CardActions>
        </Card>
      </div>
    </Grid>
  );
}
