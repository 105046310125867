import { AccessRequestFormData } from '@base/core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { RequestFormContentProps } from '../form-contents/RequestFormContentProps';
import '../styles/components/RequestForm.scss';
import { FileSlot } from './FileSlot';

type RegisterRequestFormProps = {
  onSubmit: (data: AccessRequestFormData) => Promise<void>;
  getSchema: (t: TFunction<'translation'>) => any;
  getInitialValues: (formData?: any) => any;
  FormContent: (props: RequestFormContentProps) => JSX.Element;
};

const MAX_FILE_AMOUNT = 2;
const MAX_FILE_SIZE_IN_BYTE = 10000000;

export const RegisterRequestForm = ({ onSubmit, getInitialValues, getSchema, FormContent }: RegisterRequestFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const schema = getSchema(t);
  const [fileSlots, _setFileSlots] = useState<FileSlot[]>([{}, {}]);

  async function setFileSlots(files: React.SetStateAction<FileSlot[]>) {
    _setFileSlots(files);
    fillFileslotsWithPlaceholder();
  }

  function getRawFiles(): File[] {
    return fileSlots.filter((fileSlot) => !!fileSlot.rawFileData).map((fileSlot) => fileSlot.rawFileData);
  }

  function fillFileslotsWithPlaceholder() {
    _setFileSlots((oldState) => {
      const filledFileSlotArray = oldState.filter((fs) => !!fs.existingData || !!fs.rawFileData);
      if (oldState.length >= MAX_FILE_AMOUNT && filledFileSlotArray.length >= MAX_FILE_AMOUNT) {
        return [...filledFileSlotArray];
      } else {
        return [...filledFileSlotArray, {}];
      }
    });
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ ...values, files: getRawFiles() });
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {(formik) => (
        <Form className="access-request-form-con">
          <Grid container spacing={2}>
            <FormContent formik={formik} setFileSlots={setFileSlots} files={[]} fileSlots={fileSlots} />
            <Grid item xs={12}>
              <div className="bottom-con">
                <div style={{ display: 'flex', alignItems: 'center', color: theme.palette.info.main }}>
                  <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10 }} />
                  <Typography variant="subtitle1">{t('ibu-verifies-new-accounts-in-roughly-in-24-hours')}</Typography>
                </div>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (getRawFiles().some((file) => file.size > MAX_FILE_SIZE_IN_BYTE)) {
                      alert(t('file-size-over-mb_size', { size: MAX_FILE_SIZE_IN_BYTE / 1000000 }));
                    } else {
                      formik.handleSubmit();
                    }
                  }}
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                >
                  {t('apply-for-registration')}
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
