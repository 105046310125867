import { FormControl, FormHelperText, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import cuid from 'cuid';
import React from 'react';
import * as yup from 'yup';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { SelectableEditor } from './SelectableEditor';
import { SHORT_ANSWER } from './types';

export const DropdownQuestionRenderer: Renderer<MCItem[], string> = {
  type: SHORT_ANSWER,
  contentType: 'selectable-item',
  defaultEditorValue: [{ id: cuid(), title: 'Option 1' }],
  viewer,
  viewerMin,
  editor,
  getValidationSchema,
  getTextRepresentation,
};

type MCItem = {
  id: string;
  title: string;
  checked?: boolean;
};

function viewer({ onChange, value, required, schema, error, touched }: RendererViewerProps<MCItem[], string>): JSX.Element {
  return (
    <Grid item xs={12} sm={8}>
      <FormControl error={touched && Boolean(error)} fullWidth>
        <Select fullWidth variant="outlined" required={required} defaultValue="#placeholder#" value={value} onChange={(e) => onChange(e.target.value)}>
          <MenuItem disabled value="#placeholder#">
            <em>Choose Answer</em>
          </MenuItem>
          {schema?.map((v) => (
            <MenuItem key={v.id} value={v.id}>
              {v.title}
            </MenuItem>
          ))}
        </Select>
        {touched && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

function viewerMin({ onChange, value = '', schema, touched, required, error }: RendererViewerProps<MCItem[], string>): JSX.Element {
  return <div>{schema.find((v) => v.id == value)?.title ?? 'None Selected'}</div>;
}

async function getTextRepresentation({ onChange, value, schema }: RendererViewerProps<MCItem[], string>) {
  return schema.find((item) => item.id == value)?.title || 'None selected';
}

function editor({ onChange, value }: RendererEditorProps<MCItem[], string>): JSX.Element {
  return <SelectableEditor value={value} onChange={onChange} renderItem={(v, index) => <Typography style={{ paddingLeft: 16, paddingRight: 16 }}>{index + 1}.</Typography>} />;
}

function getValidationSchema(schema, t) {
  let validator = yup.string();
  if (schema.required) {
    validator = validator.nullable(false).required(t('something-is-required', { something: t('question') }));
  } else {
    validator = validator.nullable();
  }
  return validator.typeError(t('something-is-required', { something: t('question') }));
}
