import { getKeysWithTruthyValues } from '@base/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Container, Grid, Tooltip, useTheme } from '@material-ui/core';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { LoadingPromiseButton } from '../../../../base/web/src';
import { createEmptyForm, Section } from './components';
import { getValue } from './components/getValue';
import { InformationReader } from './components/InformationReader';
import { ReaderItem } from './components/ReaderItem';
import { Renderers } from './content-renderer';
import './EditorsFormEditor.module.scss';
import { Form } from './entities/Form';
import { FormContent } from './entities/FormContent';
import { FileProvider } from './provider/FileProvider';
import { MockFileProvider } from './provider/mocks/MockFileProvider';
import { ProvidersProvider } from './provider/ProviderContext';

export const VOID = () => {};

export function useInitialValues(content: FormContent[], t: TFunction<any>) {
  return useMemo(() => {
    const initialValues = {};
    const schemaObject = {};
    for (const item of content) {
      if (item.type != 'section' && item.type != 'info') {
        const renderer = Renderers[item.type];
        initialValues[item.id] = renderer.defaultReaderValue ?? null;
        schemaObject[item.id] = renderer.getValidationSchema(item, t);
      }
    }
    return { initialValues, validationSchema: yup.object(schemaObject) };
  }, [document]);
}

export interface FormReaderProps {
  document: Form;
  fileProvider?: FileProvider;
  uploadFileProvider?: FileProvider;
  value?: any;
  onSubmit(content: any): Promise<void> | PromiseLike<void>;
  onSave(content: any): Promise<void> | PromiseLike<void>;
  valueRef?: React.MutableRefObject<any>;
  disableSubmit?: boolean;
  onMissingFieldsOnPage?: () => void;
}

export function FormViewer({
  document = createEmptyForm(),
  fileProvider = new MockFileProvider(),
  uploadFileProvider = new MockFileProvider(),
  onSubmit,
  disableSubmit,
  value,
  valueRef,
  onSave,
  onMissingFieldsOnPage,
}: FormReaderProps) {
  const items = document.content;
  const [focusedId, setFocusedId] = useState<string>(null);
  const theme = useTheme();

  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const prev = () => {
    setPage((p) => p - 1);
    window.scrollTo(0, 0);
  };

  const { initialValues: iv, validationSchema } = useInitialValues(document.content, t);
  const initialValues = useMemo(() => ({ ...iv, ...(value ?? {}) }), [value, document]);
  const { pages, sections } = usePagination(document, items);
  const sectionCount = sections.length;

  const popupAnchorRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focusedId == null && items.length > 0) {
      setFocusedId(items[0].id);
    }
  }, [focusedId, items]);

  return (
    <ProvidersProvider value={{ fileProvider: fileProvider, uploadFileProvider }}>
      <Box bgcolor={theme.palette.grey[200]} paddingTop={10} paddingBottom={10} flex={1}>
        <Container>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              await onSubmit(values);
              setSubmitting(false);
            }}
            validationSchema={validationSchema}
          >
            {({ submitForm, setValues: _setValues, values, errors, touched, setTouched }) => {
              const next = () => {
                onSave(values);
                window.scrollTo(0, 0);

                setTouched({ ...touched, ...pages[page].reduce((map, item) => ({ ...map, [item.id]: true }), {}) });
                if (!pages[page].some((v) => errors[v.id])) {
                  setPage((p) => p + 1);
                } else {
                  onMissingFieldsOnPage?.();
                }
              };
              // this is a hack that errors are computed.
              useEffect(() => {
                setTouched({ ...touched });
              }, [document, value]);

              const setValues = (v: any) => {
                _setValues(v);
              };
              if (valueRef) valueRef.current = values;
              return (
                <Box ref={boxRef}>
                  <Grid spacing={3} container>
                    <Section
                      id={sections[page].id}
                      title={sections[page].title}
                      description={sections[page].description}
                      disableCount={sectionCount == 0}
                      sectionNum={page + 1}
                      numOfSections={sectionCount}
                      setFocus={VOID}
                      onChange={VOID}
                      onRemove={VOID}
                    />
                    {pages[page].map((i, idx) => {
                      switch (i.type) {
                        case 'info':
                          return <InformationReader key={i.id} value={i} focused={false} setFocus={VOID} />;
                        default:
                          return (
                            <ReaderItem
                              key={i.id}
                              schemaValue={i}
                              value={values[i.id]}
                              error={errors[i.id]}
                              setTouched={(t) => setTouched({ ...touched, [i.id]: t })}
                              touched={touched[i.id] as any}
                              onChange={(val) => setValues((d) => ({ ...d, [i.id]: getValue(val, d[i.id]) }))}
                              focused={focusedId == i.id}
                              setFocus={(ref) => {
                                setTouched({ ...touched, [focusedId]: true });
                                setFocusedId(i.id);
                                popupAnchorRef.current = ref;
                              }}
                            />
                          );
                      }
                    })}
                    <Grid item xs={6}>
                      <Button variant="contained" onClick={prev} disabled={page <= 0} startIcon={<FontAwesomeIcon icon={faChevronLeft} />}>
                        {t('previous-page')}
                      </Button>
                    </Grid>
                    <Grid item xs={6} justifyContent="flex-end" display="flex">
                      {page < sectionCount - 1 && (
                        <Button variant="contained" onClick={next} endIcon={<FontAwesomeIcon icon={faChevronRight} />}>
                          {t('next-page')}
                        </Button>
                      )}
                      {page >= sectionCount - 1 && (
                        <Tooltip title={disableSubmit ? 'You do not have the rights to submit. Please call your admin.' : 'Submit application to IBU'}>
                          <div>
                            <LoadingPromiseButton
                              variant="contained"
                              onClick={async () => {
                                await submitForm();
                                if (getKeysWithTruthyValues(errors).length > 0) onMissingFieldsOnPage();
                              }}
                              disabled={disableSubmit}
                              endIcon={<FontAwesomeIcon icon={faChevronRight} />}
                            >
                              {t('submit')}
                            </LoadingPromiseButton>
                          </div>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
          </Formik>
        </Container>
      </Box>
    </ProvidersProvider>
  );
}

export default FormViewer;

export function usePagination(document: Form, items: FormContent<any>[]): { pages: FormContent<any>[][]; sections: { title: string; description: string; id: string }[] } {
  return useMemo(() => {
    const pages: FormContent<any>[][] = [[]];
    const sections = [{ title: document.name, description: document.description ?? '', id: 'first-secrion' }];
    for (const item of items) {
      if (item.type != 'section') pages[pages.length - 1].push(item);
      else {
        sections.push({ title: item.title, description: item.content, id: item.id });
        pages.push([]);
      }
    }
    return { pages, sections };
  }, [document]);
}
