import React, { useEffect, useMemo, useState } from 'react';
import type { ReactChildrenProp } from '../../lib';

export type GridProps = {
  style: {
    xs?: React.CSSProperties;
    s?: React.CSSProperties;
    m?: React.CSSProperties;
    default: React.CSSProperties;
    l?: React.CSSProperties;
    xl?: React.CSSProperties;
    xxl?: React.CSSProperties;
  };
  children: ReactChildrenProp;
};

const widthDefinitions = {
  xs: 478,
  s: 767,
  m: 991,
  l: 1279,
  xl: 1440,
  xxl: 1920,
};

export const GridLayout = ({ style, children }: GridProps) => {
  const [width, setWidth] = useState(document.documentElement.clientWidth);

  useEffect(() => {
    const callback = () => {
      setWidth(document.documentElement.clientWidth);
    };
    window.addEventListener('resize', callback);
    return () => {
      window.removeEventListener('resize', callback);
    };
  }, []);

  const usedStyle = useMemo(() => {
    if (width <= widthDefinitions.xs && style.xs) return { ...style.default, ...style.xs };
    if (width <= widthDefinitions.s && style.s) return { ...style.default, ...style.s };
    if (width <= widthDefinitions.m && style.m) return { ...style.default, ...style.m };
    if (width >= widthDefinitions.l && style.l) return { ...style.default, ...style.l };
    if (width >= widthDefinitions.xl && style.xl) return { ...style.default, ...style.xl };
    if (width >= widthDefinitions.xxl && style.xxl) return { ...style.default, ...style.xxl };
    return style.default;
  }, [width, style]);

  return <div style={{ display: 'grid', width: '100%', ...usedStyle }}>{children}</div>;
};
