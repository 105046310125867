import { faBusinessTime, faInboxOut, faTh, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { Box, Stack, Theme, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Route, Switch } from 'react-router-dom';
import { NavLinkListItem, SidebarHeader, SidebarList } from '../../../components/common';
import { ContentContainer, ContentView, NavbarSmall } from '../../../components/layouts';
import { ConditionalRenderWithProductSettings } from '../../../helpers/ConditionalRenderWithRights';
import { BREADCRUMBS } from '../../../router/BreadcrumbsType';
import { useTheme as useSettings } from '../../../theme/ThemeProvider';
import { ExternalUsers } from '../ExternalUsers';
import { GroupView } from '../GroupView';
import { useGetAllPendingAccessRequests } from '../hooks/useAccessRequest';
import { Users } from '../Users';
import { AccessRequestWrapper } from './AccessRequestWrapper';
import { EmailArchiveWrapper } from './EmailArchiveWrapper';
import { GroupsWrapper } from './GroupsWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      paddingTop: 0,
      '& .MuiListItem-root': {
        paddingTop: 12,
        paddingBottom: 12,
        borderBottom: `1.5px solid ${theme.palette.background.gray}`,
      },
      '& .MuiListItemText-root': {
        fontSize: 15, //doesnt work
      },
    },
  }),
);

const drawerWidth = 300;

export const UserAdministrationRouter = ({ match }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const settings = useSettings();
  const baseUrl = match.url;
  const { data: accessRequests } = useGetAllPendingAccessRequests();

  const routes = {
    internalUsers: baseUrl, // + '/users/internal',
    externalUsers: baseUrl + '/external',
    accessRequests: baseUrl + '/access-requests',
    emailArchive: baseUrl + '/email-archive',
    otherGroups: baseUrl + '/groups/others',
    groupsByType: baseUrl + '/groups/:groupType',
    group: baseUrl + '/group/:groupId',
  };

  const drawer = (
    <div>
      <SidebarHeader title="Users & Groups" subtitle={t('product-name')} theme={theme}></SidebarHeader>
      <SidebarList title={t('user')} theme={theme}>
        <NavLinkListItem to={routes.internalUsers} icon={faBusinessTime} title={t('internal-users')} exact />
        <NavLinkListItem to={routes.externalUsers} icon={faUsers} title={t('external-users')} exact />
      </SidebarList>
      <SidebarList title={t('overview')} theme={theme}>
        <NavLinkListItem to={routes.accessRequests} icon={faTh} title={t('access-request')} count={accessRequests?.length} exact />
        <NavLinkListItem to={routes.emailArchive} icon={faInboxOut} title={t('email-archive')} exact />
      </SidebarList>
      <SidebarList title={t('groups')} theme={theme}>
        {settings.namedGroups.map((g) => (
          <NavLinkListItem key={g.name} to={generatePath(routes.groupsByType, { groupType: g.name })} icon={g.icon} title={t(g.name, { count: 10 })} exact />
        ))}
        <ConditionalRenderWithProductSettings furtherGroups>
          <NavLinkListItem to={routes.otherGroups} icon={faUsers} title={t('further-groups')} exact />
        </ConditionalRenderWithProductSettings>
      </SidebarList>
    </div>
  );

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <ContentContainer flexDirection="row-reverse">
      <main className={classes.content}>
        <NavbarSmall breadcrumbs={[BREADCRUMBS.home, { ...BREADCRUMBS.users, notExact: true }]}></NavbarSmall>
        <ContentView noPadding ref={containerRef}>
          <Stack direction="row" style={{ flex: 1 }}>
            <Box className={classes.drawer} sx={{ boxShadow: 6, height: '100%', zIndex: 1 }}>
              {drawer}
            </Box>
            <Box flex={1} display="flex" flexDirection="column">
              <Switch>
                <Route path={routes.internalUsers} exact component={Users} />
                <Route path={routes.externalUsers} exact component={ExternalUsers} />
                <Route path={routes.accessRequests} exact component={AccessRequestWrapper} />
                <Route path={routes.emailArchive} exact component={EmailArchiveWrapper} />
                {settings.productSettings.furtherGroups && <Route path={routes.otherGroups} exact component={GroupsWrapper} />}
                <Route path={routes.groupsByType} exact component={GroupsWrapper} />
                <Route path={routes.group} exact component={GroupView} />
              </Switch>
            </Box>
          </Stack>
        </ContentView>
      </main>
    </ContentContainer>
  );
};
