import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckField } from "./TextField";

export function UserRightsCheckFieldArray({ currentUser }: { currentUser: Core.User; }) {
  const { t } = useTranslation();

  return (
    <div className="input-grid" style={{ marginTop: 20, marginBottom: 60, gridTemplateRows: 'auto' }}>
      <CheckField describtion={t('can-create-users')} checked={currentUser.rights?.user_create} />
      <CheckField describtion={t('can-edit-users')} checked={currentUser.rights?.user_edit} />
      <CheckField describtion={t('can-delete-users')} checked={currentUser.rights?.user_delete} />
      <CheckField describtion={t('file-admin-handbook-files')} checked={currentUser.rights?.file_admin} />
      <CheckField describtion={t('can-edit-user-rights')} checked={currentUser.rights?.user_rights_edit} />
      <CheckField describtion={t('can-create-user-groups')} checked={currentUser.rights?.user_groups_create} />
      <CheckField describtion={t('can-edit-user-groups')} checked={currentUser.rights?.user_groups_edit} />
      <CheckField describtion={t('can-delete-user-groups')} checked={currentUser.rights?.user_groups_delete} />
      <CheckField describtion={t('super-admin')} checked={currentUser.roles?.superAdmin} />
    </div>
  );
}
