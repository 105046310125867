import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import type { ReactChildrenProp } from '../../lib/ReactProps';
import { ConditionalRender } from '../common';

interface ContentContainerProps {
  title?: string;
  search?: boolean;
  children: ReactChildrenProp;
}

export const ContentSearchArea = ({ title, children, search = true }: ContentContainerProps) => {
  return (
    <div className="content-search-area">
      <ConditionalRender render={search}>
        <div className="content-search-bar">
          <FontAwesomeIcon icon={faSearch} style={{ fontSize: 28, marginRight: 12 }} />
          <div>Search</div>
        </div>
      </ConditionalRender>
      {title && <p>{title}</p>}
      <div className="search-area-row">{children}</div>
    </div>
  );
};
