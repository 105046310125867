import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type sidebarHeaderProps = {
  title: string;
  subtitle: string;
  theme: any;
};

export const SidebarHeader = ({ title, subtitle, theme }: sidebarHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ backgroundColor: theme.palette.background.gray, padding: theme.spacing(2), paddingTop: theme.spacing(3) }}>
      <Typography fontWeight="600">{title}</Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </div>
  );
};
