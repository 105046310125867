import { FileUpdate } from 'libs/base/core/src/redux/files';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getPlainPermissionsFromPermissionUpdate } from '../../../helpers/getPlainPermissionsFromPermissionUpdate';
import { CampaignOverview, FileModule } from '../CampaignOverview';
import { useCreatePhysicalFileMutation, useDeleteFileMutation, useUpdateFileMutation, useUpdatePhysicalFileMutation } from '../hooks/mutations';
import { useGetChildren } from '../hooks/useFolderChildren';
import { usePopup } from '@base/web';
import { useTranslation } from 'react-i18next';

export function CampaignOverviewWrapper({ match }) {
  const notify = usePopup();
  const { t } = useTranslation();
  const { data, isLoading } = useGetChildren(null);
  console.log('match', { match });

  const history = useHistory();

  const createMutation = useCreatePhysicalFileMutation();
  const updateImageMutation = useUpdatePhysicalFileMutation();
  const updateFolderMutation = useUpdateFileMutation();
  const deleteFolderMutation = useDeleteFileMutation();

  const onUpdate = async (id: string, value: FileUpdate<FileModule> & { imageFile?: File }) => {
    const { imageFile, ...update } = value;
    if (imageFile) {
      await updateImageMutation.mutateAsync({ data: imageFile, fileId: id });
    }
    await updateFolderMutation.mutateAsync({ fileId: id, update });
  };
  const onCreate = async (value: FileUpdate<FileModule> & { imageFile?: File }) => {
    await createMutation.mutateAsync({
      data: value.imageFile,
      description: value.description,
      name: value.name,
      //@ts-ignore
      icon: value.icon,
      parent: null,
      type: 'folder',
      permissions: getPlainPermissionsFromPermissionUpdate(value),
    });
  };
  const onDelete = async (file: FileModule) => {
    try {
      await deleteFolderMutation.mutateAsync(file);
      notify({
        type: 'success',
        title: t('deleted-successful'),
      });
    } catch (e) {
      notify({
        type: 'error',
        title: t('error'),
        text: e.message ?? e,
      });
    }
  };

  return (
    <CampaignOverview
      folders={(data?.children as any) ?? []}
      isLoading={isLoading}
      onCreate={onCreate}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onOpenModule={(file) => history.push(match.path + file.id)}
    />
  );
}
