// ----------------------------------------------------------------------

import { createTheme } from "@material-ui/core";

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

const theme = createTheme()
export function responsiveFontSizes({ sm, md, lg }) {
  return {
    [theme.breakpoints.up('sm')]: { fontSize: pxToRem(sm) },
    [theme.breakpoints.up('md')]: { fontSize: pxToRem(md) },
    [theme.breakpoints.up('lg')]: { fontSize: pxToRem(lg) },
  };
}
