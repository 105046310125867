import '../../../css/components/common/content/search/search.scss';

import { faFilter, faStar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FileEditLight, FileExcelLight, FilePdfLight, FilePowerpointLight, FileWordLight } from '../../../assets/file-icons';
import { TimesRegular } from '../../../assets/toolbar-icons';
import { FileFilterElement } from './FileFilterElement';

interface FilterProps {
  popupVisible: boolean;
  onCloseClick: () => void;
  onButtonClick: () => void;
  bgColor?: string;
  iconColor?: string;
}

function cssClasses(conditionalNames: { [className: string]: boolean }, ...otherClassnames: string[]) {
  const classNames = [
    ...otherClassnames,
    ...Object.entries(conditionalNames)
      .filter(([className, isClassNameActive]) => isClassNameActive)
      .map(([className, isClassNameActive]) => className),
  ];
  return classNames.join(' ');
}

export const Filter = ({ popupVisible, onCloseClick, onButtonClick, bgColor = '#ffffff30', iconColor = '#ffffff' }: FilterProps) => {
  const [wordIsPressed, setWordIsPressed] = useState(false);

  return (
    <div className="filter-btn-con">
      <div onClick={onButtonClick} className="search-filter-btn shadow nomargin" style={{ backgroundColor: bgColor }}>
        <FontAwesomeIcon icon={faFilter} className="search-filter-img" style={{ fontSize: 32, color: iconColor }} />
      </div>
      <div
        className={cssClasses(
          {
            visible: popupVisible,
          },
          'filter-popup',
        )}
      >
        <div className="filter-row">
          <div className="filter-title">Filter</div>
          <div style={{ cursor: 'pointer' }} onClick={onCloseClick} className="close-icon">
            <TimesRegular className="times-icon" style={{ fill: 'white', width: '39%' }} />
          </div>
        </div>
        <FileFilterElement faIcon={faStar} check={wordIsPressed} text="Favoriten" color="#E1D533" onClick={() => setWordIsPressed(!wordIsPressed)} />
        <div className="popup-subtitle" style={{ textAlign: 'left' }}>
          Dateien
        </div>
        <FileFilterElement icon={FileWordLight} check={wordIsPressed} text="Word" color="#3455bf" onClick={() => setWordIsPressed(!wordIsPressed)} />
        <FileFilterElement icon={FilePowerpointLight} check={wordIsPressed} text="PowerPoint" color="#c85e21" onClick={() => setWordIsPressed(!wordIsPressed)} />
        <FileFilterElement icon={FileExcelLight} check={wordIsPressed} text="Excel" color="#4da533" onClick={() => setWordIsPressed(!wordIsPressed)} />
        <FileFilterElement icon={FilePdfLight} check={wordIsPressed} text="PDF" color="#c92c2c" onClick={() => setWordIsPressed(!wordIsPressed)} />
        <FileFilterElement icon={FileEditLight} check={wordIsPressed} text="Interaktiv" color="#006e9b" onClick={() => setWordIsPressed(!wordIsPressed)} />
      </div>
    </div>
  );
};
