import { SentDocumentStatusType } from '@base/core';
import { faAlarmExclamation, faCheck, faEngineWarning, faPaperPlane, faTimes, faFileEdit } from '@fortawesome/pro-solid-svg-icons';

export function getColorFromAdobeStatus(status: SentDocumentStatusType) {
  switch (status) {
    case 'CANCELLED':
      return 'error';
    case 'EXPIRED':
      return 'error';
    case 'SIGNED':
      return 'success';
    case 'OUT_FOR_SIGNATURE':
      return 'info';
    case 'DRAFT':
      return 'info';
    case 'AUTHORING':
      return 'info';
    default:
      return 'warning';
  }
}

export function getIconFromAdobeStatus(status: SentDocumentStatusType) {
  switch (status) {
    case 'CANCELLED':
      return faTimes;
    case 'EXPIRED':
      return faAlarmExclamation;
    case 'SIGNED':
      return faCheck;
    case 'OUT_FOR_SIGNATURE':
      return faPaperPlane;
    case 'DRAFT':
      return faFileEdit;
    case 'AUTHORING':
      return faFileEdit;
    default:
      return faEngineWarning;
  }
}
