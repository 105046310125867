import { API, debounce } from '@base/core';
import { faCloudUpload, faEdit, faLock, faTrash, faUserPlus, faUsers, faUserShield } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Chip, Link, Stack, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, GridLinkOperator, XGrid } from '@material-ui/x-grid';
import React, { SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConditionalRender, ContentHeader, MainMenu, useNotificationEffect, UserImageHandler } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import { SearchInput } from '../../components/inputs';
import { ImportExcelUserPopup } from '../../components/popups/excel-import/ImportExcelUserPopup';
import '../../css/Views/admin/users/UsersOverride.scss';
import { ConditionalRenderWithRights } from '../../helpers/ConditionalRenderWithRights';
import { useDeleteUsersMutation, useUpdateGroupsMutation } from './hooks/mutations';
import { useGetGroups } from './hooks/useGetGroups';
import { useGetUsers } from './hooks/useGetUsers';
import { ResetPasswordPopup } from './popups';
import { AddGroupsPopup } from './popups/AddUserToGroupsPopup';
import { UserEdit } from './UserEdit';
import { GridNoResultsOverlay, GridNoRowsOverlay } from '../../components/content/GridContent';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    lastCell: {
      minWidth: '700px !important',
      position: 'absolute',
      left: 0,
      zIndex: 10,
      opacity: 0,
      transform: 'translateX(calc(100vw - 100% - 325px))',
    },
    root: {
      flex: 1,
      height: 'auto',
      '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
      '& .MuiDataGrid-row': {
        position: 'relative',
        minWidth: '100%',
        cursor: 'pointer',
        '&:hover': {
          '& $lastCell': {
            opacity: 1,
          },
        },
      },
      '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
    },
  }),
);

function getGroupsFromUser(user: Core.User) {
  return Object.entries(user.groups ?? {})
    .filter(([_, v]) => v)
    .map(([group]) => group);
}

export const Users = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [resetPasswordOpen, setresetPasswordOpen] = useState(undefined);
  const [editUserPopupOpen, setEditUserPopupOpen] = useState(undefined);
  const [editGroupsOpen, setEditGroupsOpen] = useState(undefined);
  const [createUserPopupOpen, setCreateUserPopupOpen] = useState(false);
  const [importUserPopupOpen, setImportUserPopupOpen] = useState(false);
  const [selection, setSelection] = useState([]);

  const classes = useStyles(theme);
  const updateGroupsMutation = useUpdateGroupsMutation();
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const { data: userData = [], isLoading } = useGetUsers();
  const { data: groupsList = [] } = useGetGroups();

  const usersList = userData.filter((u) => !u.rights?.is_group_only_user);

  const columns: { [key: string]: Omit<GridColDef, 'field'> } = {
    photoURL: {
      width: 65,
      renderCell: ({ formattedValue, value, row }) => {
        return <UserImageHandler userName={(row as Core.User).displayName} userPhotoUrl={value as any} style={{ height: 42, width: 42, marginRight: 16, borderStyle: 'none' }} fontSize={16} />;
      },
    },
    displayName: {
      width: 250,
      headerName: t('name'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    abteilung: {
      width: 250,
      headerName: t('position'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Chip label={value || '-'} />;
      },
    },
    email: {
      width: 350,
      headerName: 'E-Mail',
      renderCell: ({ formattedValue, value, row }) => {
        return (
          <Link variant="subtitle1" href={'mailto:' + value} onClick={(e) => e.stopPropagation()}>
            {value}
          </Link>
        );
      },
    },
    jobTitle: { headerName: t('job-description'), flex: 2, hide: true },
    phoneNumber: { headerName: t('phone-number'), flex: 2, hide: true },
    mobile: { headerName: t('mobile-number'), flex: 2, hide: true },
    address: { headerName: t('address'), flex: 2, hide: true },
    language: { headerName: t('language'), flex: 1, hide: true },
    birthDate: { headerName: t('birthdate'), type: 'date', flex: 1, hide: true },
    notes: { headerName: t('notes'), flex: 2, hide: true },
    emailVerified: { headerName: t('email-verified'), hide: true },
    disabled: { headerName: t('user-disabled'), hide: true },
    lastCell: {
      flex: 1,
      align: 'right',
      renderCell: ({ value, row }) => {
        const menuActions = [];

        if (user.roles.superAdmin) {
          menuActions.push({
            title: 'Impersonate',
            icon: faUserShield,
            action: async (e) => {
              await API.users.impersonateUser(row.id);
            },
          });
        }
        if (user.roles.superAdmin || user.rights.user_groups_edit) {
          menuActions.push({
            title: t('add-to-group'),
            icon: faUsers,
            action: (e) => {
              setEditGroupsOpen(row);
            },
          });
        }

        if (user.roles.superAdmin || user.rights.user_edit || user.rights.user_create || user.rights.user_delete) {
          menuActions.push({
            title: t('change-login-data'),
            icon: faLock,
            action: (e) => {
              setresetPasswordOpen(row);
            },
          });
        }

        if (user.roles.superAdmin || user.rights.user_edit) {
          menuActions.push({
            title: t('can-edit-users'),
            icon: faEdit,
            action: (e) => {
              setEditUserPopupOpen(row);
            },
          });
        }
        return (
          <MainMenu
            id={row.id}
            menuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
            actions={menuActions}
          />
        );
      },
      filterable: false,
      disableColumnMenu: true,
    },
  };

  const deleteUsersMutation = useDeleteUsersMutation();
  useNotificationEffect({ title: t('error'), type: 'error', text: deleteUsersMutation.error?.message || deleteUsersMutation.error?.message }, deleteUsersMutation.isError, [deleteUsersMutation.error]);
  const [filterModel, setFilterModel, fastFilterModel] = useDebouncedState<GridFilterModel>();
  const [openDeletePopup, setOpenDeletePopup] = useState(undefined);

  return (
    <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <ResetPasswordPopup user={usersList?.find((u) => u.id == (resetPasswordOpen as Core.User)?.id)} onClose={() => setresetPasswordOpen(undefined)} open={Boolean(resetPasswordOpen)} />
      <UserEdit open={Boolean(editUserPopupOpen)} onClose={() => setEditUserPopupOpen(undefined)} currentUser={editUserPopupOpen as Core.User} />
      <AddGroupsPopup
        open={editGroupsOpen}
        onClose={() => setEditGroupsOpen(false)}
        selectedGroups={getGroupsFromUser((editGroupsOpen ?? {}) as Core.User)}
        groups={groupsList}
        onSave={(added, removed) => updateGroupsMutation.mutateAsync({ uid: (editGroupsOpen as Core.User)?.id, added, removed })}
      />
      <ContentHeader noBack title={t('current-users')} subtitle={usersList?.length + ' ' + t('user')}>
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1}>
            <SearchInput
              size="small"
              InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
              placeholder={t('search')}
              fullWidth
              value={fastFilterModel?.items?.find((i) => i.columnField === 'displayName')?.value ?? ''}
              onChange={(e) => {
                try {
                  new RegExp(e.target.value);
                  setFilterModel({
                    items: [
                      { columnField: 'displayName', operatorValue: 'contains', value: e.target.value },
                      { columnField: 'abteilung', operatorValue: 'contains', value: e.target.value },
                    ],
                    linkOperator: GridLinkOperator.Or,
                  });
                } catch {}
              }}
            />
          </Box>
          <ConditionalRenderWithRights user_delete>
            <ConditionalRender render={selection.length > 0}>
              <LoadingButton
                sx={{ borderRadius: 999 }}
                onClick={() => {
                  setOpenDeletePopup(true);
                }}
                loading={deleteUsersMutation.isLoading}
                variant="contained"
                color="error"
                endIcon={<FontAwesomeIcon icon={faTrash} />}
              >
                {t('delete-users')}
              </LoadingButton>
              <MainDialog
                open={openDeletePopup}
                onCloseClick={() => setOpenDeletePopup(false)}
                onSaveClick={async () => {
                  await deleteUsersMutation.mutateAsync(selection);
                  setSelection([]);
                  setOpenDeletePopup(false);
                }}
                saveButtonColor="error"
                secondButtonColor="primary"
                modalTitle={t('do-you-really-want-to-delete-count-users', { count: selection.length })}
                description={'This action cannot be undone.' /* TODO: Implement i18n key */}
                buttonText={t('delete')}
              />
            </ConditionalRender>
          </ConditionalRenderWithRights>
          <ConditionalRenderWithRights user_create>
            <Button
              sx={{ color: 'primary.main', bgcolor: 'background.paper', ':hover': { bgcolor: 'action.hover' }, borderRadius: 999 }}
              onClick={() => {
                setImportUserPopupOpen(true);
              }}
              variant="contained"
              color="primary"
              endIcon={<FontAwesomeIcon icon={faCloudUpload} />}
            >
              {t('import-users')}
            </Button>
            <Button
              sx={{ borderRadius: 999 }}
              onClick={() => {
                setCreateUserPopupOpen(true);
              }}
              variant="contained"
              color="primary"
              endIcon={<FontAwesomeIcon icon={faUserPlus} />}
            >
              {t('new-user')}
            </Button>
          </ConditionalRenderWithRights>
        </Stack>
      </ContentHeader>
      <XGrid
        rows={usersList ?? []}
        className={classes.root}
        headerHeight={40}
        loading={isLoading}
        sortModel={[{ field: 'displayName', sort: 'asc' }]}
        checkboxSelection={true}
        onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
        filterModel={filterModel}
        disableSelectionOnClick
        onRowClick={(params) => setEditUserPopupOpen(params.row)}
        onSelectionModelChange={({ selectionModel }) => {
          setSelection(selectionModel);
        }}
        columns={useMemo(
          () =>
            Object.entries(columns)
              .map(([key, value]) => ({ field: key, ...value }))
              .map((c) => ({
                ...c,
                renderHeader: (params) => {
                  return (
                    <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                      {params.colDef.headerName}
                    </Typography>
                  );
                },
              })),
          [],
        )}
        components={{
          NoRowsOverlay: GridNoRowsOverlay,
          NoResultsOverlay: GridNoResultsOverlay,
        }}
      />
      <ImportExcelUserPopup open={importUserPopupOpen} setOpen={setImportUserPopupOpen} />
      <UserEdit open={createUserPopupOpen} onClose={() => setCreateUserPopupOpen(false)} />
    </Box>
  );
};

export function useDebouncedState<T>(initialState?: T, timeout?: number): [T, (params: SetStateAction<T>) => void, T] {
  const [state, setState] = useState<T>(initialState);
  const [fastState, setFastState] = useState<T>(initialState);
  const setStateDebounced = useMemo(() => {
    const debouncedSetState = debounce(setState, timeout);
    return (params: SetStateAction<T>) => {
      debouncedSetState(params);
      setFastState(params);
    };
  }, []);
  return [state, setStateDebounced, fastState];
}
