import '../../css/components/common/layout/Breadcrump.scss';

import React, { useMemo } from 'react';
import { NavLink, useRouteMatch, matchPath, useParams } from 'react-router-dom';
import { cx } from '../HelperFunctions';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

interface NavbarSmallProps {
  name: string;
  path: string;
  exact?: boolean;
  id?: string;
}

const matchRe = /\:(\w+)/g;

export const Breadcrumb = ({ name, path, id, exact }: NavbarSmallProps) => {
  const params = useParams();
  const { t } = useTranslation();

  const injectedPath = useMemo(() => {
    let newPath = path;
    for (const match of Array.from(path.matchAll(matchRe))) {
      if (!match) continue;
      //@ts-ignore
      newPath = newPath.replace(new RegExp(`(${match[0]})`), params[match[1]] ?? '');
    }
    return newPath;
  }, [params, path]);

  return (
    <NavLink className={cx('breadcrumb-element')} to={injectedPath} exact={exact}>
      <Typography variant="h4">{id ? name : t(name)}</Typography>
      <div className="breadcrumb-underline" />
    </NavLink>
  );
};
