import { DataBase } from './entities/Database';
import { UsersThunk } from './redux/users/thunk';
import { GroupsThunk } from './redux/groups/thunk';
import { AuthThunk } from './redux/auth';
import { FilesThunk } from './redux/files';
export { configureStore } from './redux/store';
export * from './redux/common';
export * from './entities';
export * from './helpers';
import { AuthInteractor, FilesInteractor, UsersInteractor, EmailInteractor, AccessRequestInteractor, CampaignsInteractor, GroupsInteractor, ChatInteractor } from './useCases';

export type ActionsType = import('./redux/auth').ActionsType | import('./redux/groups').ActionsType | import('./redux/files').ActionsType | import('./redux/users').ActionsType;

type ReturnTypeOf<T extends { [key: string]: any }> = {
  [k in keyof T]: ReturnType<T[k]>;
};

export type ActionsTypeMap = ReturnTypeOf<typeof import('./redux/auth/actions')> &
  ReturnTypeOf<typeof import('./redux/groups/actions')> &
  ReturnTypeOf<typeof import('./redux/files/actions')> &
  ReturnTypeOf<typeof import('./redux/users/actions')>;

import * as AuthTypes from './redux/auth/types';
import * as GroupTypes from './redux/groups/types';
import * as UsersTypes from './redux/users/types';
import * as FilesTypes from './redux/files/types';
import { ISearch } from './entities';
import { AuthRepo, setFileRepo, FileRepo, UsersRepo } from './repos';
import {AdobeSignInteractor} from "./useCases/AdobeSignInteractor";
export const ActionTypes = {
  auth: AuthTypes,
  groups: GroupTypes,
  users: UsersTypes,
  files: FilesTypes,
};

export var searchService!: ISearch;

export const API = {} as {
  auth: AuthInteractor;
  files: FilesInteractor;
  campaigns: CampaignsInteractor;
  users: UsersInteractor;
  email: EmailInteractor;
  accessRequest: AccessRequestInteractor;
  groups: GroupsInteractor;
  chats: ChatInteractor;
  adobeSign: AdobeSignInteractor;
};

export * from './repos';

let usersThunk!: UsersThunk;
let groupsThunk!: GroupsThunk;
let authThunk!: AuthThunk;
let filesThunk!: FilesThunk;

export function initialize(database: DataBase) {
  const authRepo = new AuthRepo(database.authService);
  const userRepo = new UsersRepo(database.userService);
  const fileRepo = new FileRepo(database.fileService);
  const campaignFileRepo = new FileRepo(database.campaignFileService);

  const authInteractor = new AuthInteractor(authRepo, userRepo);
  const filesInteractor = new FilesInteractor(fileRepo, authInteractor);
  const usersInteractor = new UsersInteractor(database.userService);
  const emailInteractor = new EmailInteractor(database.emailService, authInteractor);
  const accessRequestInteractor = new AccessRequestInteractor(database.accessRequestService, usersInteractor, database.groupService, database.emailService);
  const campaignsInteractor = new CampaignsInteractor(campaignFileRepo, authInteractor, database.formService, emailInteractor);
  const chatInteractor = new ChatInteractor(database.chatService);
  const groupsInteractor = new GroupsInteractor(database.groupService, database.accessRequestService);
  const adobeSignInteractor = new AdobeSignInteractor(database.adobeSignService, database.formService);

  usersThunk = new UsersThunk(database.userService);
  groupsThunk = new GroupsThunk(database.groupService);
  authThunk = new AuthThunk(authInteractor);
  filesThunk = new FilesThunk(filesInteractor, fileRepo);
  searchService = database.search;
  setFileRepo(database.fileService);

  API.auth = authInteractor;
  API.files = filesInteractor;
  API.users = usersInteractor;
  API.email = emailInteractor;
  API.accessRequest = accessRequestInteractor;
  API.campaigns = campaignsInteractor;
  API.groups = groupsInteractor;
  API.chats = chatInteractor;
  API.adobeSign = adobeSignInteractor;
}

export const Actions = () => ({
  users: usersThunk,
  groups: groupsThunk,
  auth: authThunk,
  files: filesThunk,
});
