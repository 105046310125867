import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Backdrop, Dialog, DialogProps, Fade } from '@material-ui/core';
import React from 'react';
import '../../../css/components/common/popups/StandardPopup.scss';
import type { ReactChildrenProp } from '../../../lib/ReactProps';
import { FAIconButton } from '../buttons/FAIconButton';

interface standardPopupProps extends Omit<DialogProps, 'open'> {
  children: ReactChildrenProp;
  visible: boolean;
  onBackdropClick: () => void;
  onCloseClick?: () => void;
  width?: number;
  hideClose?: boolean;
}

export const StandardPopup = ({ children, visible, onBackdropClick, width, hideClose, onCloseClick, ...props }: standardPopupProps) => {
  return (
    <Dialog
      {...props}
      open={visible}
      onClose={onBackdropClick}
      closeAfterTransition
      BackdropComponent={Backdrop}
      scroll="paper"
      maxWidth={false}
      BackdropProps={{ timeout: 500 }}
      PaperProps={{ sx: { borderRadius: 4 } }}
      className="modal"
      style={{ display: visible ? 'flex' : 'none' }}
    >
      <Fade in={visible} timeout={500}>
        <div>
          {!hideClose && (
            <FAIconButton iconSize={26} iconColor={'#fff'} bgColor={'#d55555'} icon={faTimes} className="escape-button" onClick={onCloseClick || onBackdropClick} style={{ zIndex: 99999 }} />
          )}
          <div className="modal-popup-container" style={{ zIndex: 9999, width }}>
            {children}
          </div>
        </div>
      </Fade>
    </Dialog>
  );
};
