import * as React from 'react';

function SvgFileExcelLight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.801 62.413" ref={svgRef} {...props}>
      <path d="M45.082 11.944L34.857 1.718A5.85 5.85 0 0030.725 0H5.85A5.87 5.87 0 000 5.862v50.7a5.852 5.852 0 005.85 5.85h35.1a5.852 5.852 0 005.85-5.85V16.088a5.879 5.879 0 00-1.718-4.144zm-2.754 2.767a1.917 1.917 0 01.512.9H31.2V3.973a1.917 1.917 0 01.9.512zm-1.377 43.8H5.85a1.956 1.956 0 01-1.95-1.95V5.862a1.956 1.956 0 011.95-1.95H27.3v12.675a2.918 2.918 0 002.925 2.925H42.9v37.051a1.956 1.956 0 01-1.949 1.95zM25.8 37.55l6.155-9.97a1.463 1.463 0 00-1.255-2.217h-.5a1.469 1.469 0 00-1.231.67c-3.778 5.911-4.436 6.52-5.57 9.08-2.1-3.924-1.024-1.95-5.582-9.08a1.469 1.469 0 00-1.231-.67h-.5a1.456 1.456 0 00-1.243 2.218l6.24 9.97-7.2 10.908a1.465 1.465 0 001.231 2.255h.427a1.469 1.469 0 001.231-.67c4.534-7.069 5.521-7.617 6.63-10.055a72.088 72.088 0 006.63 10.067 1.452 1.452 0 001.219.658h.439a1.456 1.456 0 001.231-2.243z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileExcelLight);
export default ForwardRef;
