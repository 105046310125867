import * as React from 'react';

function SvgInfoCircleRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={svgRef} {...props}>
      <path d="M10 0a10 10 0 1010 10A10 10 0 0010 0zm0 18.065A8.065 8.065 0 1118.065 10 8.06 8.06 0 0110 18.065zm0-13.629A1.694 1.694 0 118.306 6.13 1.694 1.694 0 0110 4.435zm2.258 10.242a.484.484 0 01-.484.484H8.226a.484.484 0 01-.484-.484v-.968a.484.484 0 01.484-.484h.484v-2.581h-.484a.484.484 0 01-.484-.484v-.968a.484.484 0 01.484-.484h2.581a.484.484 0 01.484.484v4.032h.484a.484.484 0 01.484.484z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgInfoCircleRegular);
export default ForwardRef;
