import { faInfoCircle, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, IconButton, Paper, Stack, TextField, Typography, useTheme as useMaterialTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type RequestFormProps = {
  onSubmit: (data: Core.User) => Promise<void>;
  readOnly?: boolean;
  currentUser?: Core.User;
};

const maxFileSizeInByte = 4194304;

export const UserForm = ({ onSubmit, readOnly = false, currentUser }: RequestFormProps) => {
  const { t } = useTranslation();
  const muiTheme = useMaterialTheme();

  const [file, setFile] = useState<File>(undefined);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('enter-a-valid-email'))
      .required(t('something-is-required', { something: t('email') })),
    displayName: yup.string().required(t('something-is-required', { something: t('name') })),
    phoneNumber: yup.string().required(t('something-is-required', { something: t('phone-number') })),
    address: yup.string().required(t('something-is-required', { something: t('address') })),
    zipCode: yup.string().required(t('something-is-required', { something: t('zip-code') })),
    position: yup.string(), //.required(t('something-is-required', { something: t('position') })),
  });

  const initialValues = useMemo(() => {
    const defaultValues = {
      displayName: '',
      phoneNumber: '',
      mobile: '',
      address: '',
      email: '',
      abteilung: '',
    } as Core.User;
    if (currentUser) return { ...defaultValues, ...currentUser };
    return defaultValues;
  }, [currentUser]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ ...values });
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ isSubmitting, errors, touched, handleSubmit }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline">{t('basic-information')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              as={TextFieldWithLabel}
              placeholder={t('name')}
              required
              name="displayName"
              disabled={isSubmitting || readOnly}
              error={touched.displayName && Boolean(errors.displayName)}
              helperText={touched.displayName && errors.displayName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              as={TextFieldWithLabel}
              placeholder={t('address')}
              required
              name="address"
              disabled={isSubmitting || readOnly}
              error={touched.address && Boolean(errors.address)}
              helperText={touched.address && errors.address}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ paddingTop: muiTheme.spacing(1) }}>
            <Field
              as={TextFieldWithLabel}
              placeholder={t('name')}
              required
              name="email"
              disabled={isSubmitting || readOnly}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              as={TextFieldWithLabel}
              placeholder={t('position')}
              name="abteilung"
              disabled={isSubmitting || readOnly}
              error={touched.abteilung && Boolean(errors.abteilung)}
              helperText={touched.abteilung && errors.abteilung}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" sx={{ marginBottom: 0 }}>
              {t('contact-information')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              as={TextFieldWithLabel}
              placeholder={t('phone-number')}
              required
              name="phoneNumber"
              disabled={isSubmitting || readOnly}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              as={TextFieldWithLabel}
              placeholder={t('email')}
              required
              name="mobile"
              disabled={isSubmitting || readOnly}
              error={touched.mobile && Boolean(errors.mobile)}
              helperText={touched.mobile && errors.mobile}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">{t('verification')}</Typography>
          </Grid>
          {readOnly ? (
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <LoadingButton
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    console.log('appeal');
                  }}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {t('apeal-registration')}
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    console.log('verify');
                  }}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {t('verify-registration')}
                </LoadingButton>
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <div className="bottom-con">
                <div style={{ display: 'flex', alignItems: 'center', color: muiTheme.palette.info.main }}>
                  <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10 }} />
                  <Typography variant="subtitle1">{t('ibu-verifies-new-accounts-in-roughly-in-24-hours')}</Typography>
                </div>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    file?.size > maxFileSizeInByte ? alert(t('file-size-over-4mb')) : handleSubmit();
                  }}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {t('apply-for-registration')}
                </LoadingButton>
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </Formik>
  );
};

export function TextFieldWithLabel(props) {
  return <TextField {...props} variant="outlined" label={props.placeholder} autoComplete="off" fullWidth />;
}

function AddFile({ file, setFile, disabled }: { file: File; setFile: React.Dispatch<React.SetStateAction<File>>; disabled: boolean }) {
  const { t } = useTranslation();
  const muiTheme = useMaterialTheme();

  function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>): void {
    const files = Array.from(e.target.files);
    setFile(files[0]);
  }

  return (
    <Paper variant={'outlined'} className="upload-con">
      {file ? (
        file.size > maxFileSizeInByte ? (
          <div>
            <Typography variant="subtitle1" color="error">
              {t('file-size-over-4mb')}
            </Typography>
            <Typography variant="body1" color="error">
              {t('please-choose-smaller-document-or-compress-the-current-on')}
            </Typography>
          </div>
        ) : (
          <Typography variant="body1">{file.name}</Typography>
        )
      ) : (
        <div>
          <Typography variant="subtitle1">{t('verification-document-for-nf-status')}</Typography>
          <Typography variant="body2">{t('upload-the-document-provided-by-ibu-to-verify-your-status')}</Typography>
        </div>
      )}
      <input accept=".pdf" id="contained-button-file" type="file" onChangeCapture={handleFileSelected} hidden />
      <div className="flex-con">
        {file && (
          <IconButton aria-label="delete" onClick={() => setFile(undefined)} color="primary" style={{ marginRight: 20, color: muiTheme.palette.error.main }} size="medium">
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        )}
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="secondary" component="span" disabled={disabled}>
            {t('upload-file')}
          </Button>
        </label>
      </div>
    </Paper>
  );
}

function ShowFile({ file, disabled }: { file: { name: string; downloadUrl: string }; disabled: boolean }) {
  const { t } = useTranslation();
  return (
    <Paper variant={'outlined'} className="upload-con">
      {file ? (
        <Typography variant="body1">{file.name}</Typography>
      ) : (
        <div>
          <Typography variant="subtitle1">{t('verification-document-for-nf-status')}</Typography>
          <Typography variant="body2">{t('no-document-was-uploaded')}</Typography>
        </div>
      )}
      <div className="flex-con">
        <Button variant="contained" color="secondary" disabled={disabled}>
          {t('download')}
        </Button>
      </div>
    </Paper>
  );
}
