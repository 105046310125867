import { faChevronLeft, faChevronRight, faCommentLines } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Box, Button, Card, CardContent, Container, Divider, Drawer, Grid, IconButton, Stack, Typography, useTheme } from '@material-ui/core';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useInitialValues, VOID } from '..';
import { createEmptyForm, Section } from './components';
import { getValue } from './components/getValue';
import { InformationReader } from './components/InformationReader';
import { MinimalReaderItem } from './components/MinimalReaderItem';
import { ReaderItem } from './components/ReaderItem';
import { Renderers } from './content-renderer';
import './EditorsFormEditor.module.scss';
import { Form } from './entities/Form';
import { FormContent } from './entities/FormContent';
import { Comments } from './Comments';
import { FileProvider } from './provider/FileProvider';
import { MockFileProvider } from './provider/mocks/MockFileProvider';
import { ProvidersProvider } from './provider/ProviderContext';
import { QuestionSpecificChatItem } from './QuestionSpecificChatItem';
import { StandardPopup } from '@base/web';

export interface FormReaderResponseProps {
  document: Form;
  fileProvider?: FileProvider;
  uploadFileProvider?: FileProvider;
  value?: any;
  onSubmit(content: any): Promise<void> | PromiseLike<void>;
  onSave(content: any): Promise<void> | PromiseLike<void>;
  valueRef?: React.MutableRefObject<any>;
  onMissingFieldsOnPage?: () => void;
  chats?: QuestionSpecificChatItem[];
  onSendChat?: (message: string, questionId: string) => void;
  readChatItems?: (chatItemIds: string[]) => void;
  review?: { [questionId: string]: { type: 'rejected' | 'accepted' } };
  status: 'success' | string;
  canSubmit: boolean;
}

export function FormViewerResponse({
  document = createEmptyForm(),
  fileProvider = new MockFileProvider(),
  uploadFileProvider = new MockFileProvider(),
  onSubmit,
  value,
  valueRef,
  onSave,
  onMissingFieldsOnPage,
  canSubmit,
  review = {},
  chats = [],
  status,
  onSendChat,
  readChatItems,
}: FormReaderResponseProps) {
  const items = document.content;
  const [focusedId, setFocusedId] = useState<string>(null);
  const theme = useTheme();

  const { t } = useTranslation();

  const { initialValues: iv, validationSchema } = useInitialValues(document.content, t);
  const initialValues = useMemo(() => ({ ...iv, ...(value ?? {}) }), [value, document]);
  const me = useSelector((state: Core.StateType) => state.auth.user);
  const [selectedQuestion, setSelectedQuestion] = useState<string>(null);

  return (
    <ProvidersProvider value={{ fileProvider: fileProvider, uploadFileProvider }}>
      <Box bgcolor={theme.palette.grey[200]} paddingTop={10} paddingBottom={10} flex={1}>
        <Container>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              await onSubmit(values);
              setSubmitting(false);
            }}
            validationSchema={validationSchema}
          >
            {({ submitForm, setValues: _setValues, values, errors, touched, setTouched }) => {
              const setValues = (v: any) => {
                _setValues(v);
              };
              if (valueRef) valueRef.current = values;
              // this is a hack that errors are computed.
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                setTouched({ ...touched });
              }, [document, value]);
              return (
                <Box>
                  <Grid spacing={3} container>
                    <Section id={'root'} title={document.name} description={document.description} disableCount sectionNum={0} numOfSections={0} setFocus={VOID} onChange={VOID} onRemove={VOID} />
                    {items.map((i, idx) => {
                      switch (i.type) {
                        case 'info':
                          return <InformationReader key={i.id} value={i} focused={false} setFocus={VOID} />;
                        case 'provide-file':
                          return null;
                        case 'section':
                          return (
                            <Grid key={i.id} item xs={12}>
                              <Typography variant="h4">{i.title}</Typography>
                              <Divider />
                            </Grid>
                          );
                        default: {
                          const questionChats = chats.filter((c) => c.questionId == i.id);
                          const unreadCount = questionChats.reduce((sum, c) => sum + (c.read[me.id] ? 0 : 1), 0);

                          if ((((!review[i.id] && questionChats.length === 0) || review[i.id]?.type === 'accepted') && !errors[i.id]) || status === 'success') {
                            return (
                              <Grid item xs={12} key={i.id}>
                                <Card variant="outlined">
                                  {/* <CardHeader title={i.title} /> */}
                                  <CardContent sx={{ padding: 2, paddingBottom: theme.spacing(2) + ' !important' }}>
                                    <Stack spacing={2}>
                                      <Box display="flex" justifyContent="space-between">
                                        <Typography style={{ wordBreak: 'break-word' }} variant="h4">
                                          {i.title}
                                        </Typography>
                                        <Badge color="error" badgeContent={unreadCount} sx={{ display: 'block' }}>
                                          <IconButton
                                            onClick={() => setSelectedQuestion(i.id)}
                                            sx={{ color: 'white', bgcolor: 'primary.main', ':hover': { bgcolor: 'primary.light', color: 'white' }, width: 38, height: 38 }}
                                          >
                                            <FontAwesomeIcon fixedWidth icon={faCommentLines} />
                                          </IconButton>
                                        </Badge>
                                      </Box>
                                      <Typography sx={{ wordBreak: 'break-word', marginBottom: 1, display: 'block' }} fontWeight="600">
                                        <MinimalReaderItem schemaValue={i} value={values[i.id]} />
                                      </Typography>
                                    </Stack>
                                  </CardContent>
                                </Card>
                              </Grid>
                            );
                          }
                          return (
                            <Grid item xs={12} key={i.id} display="flex" alignItems="stretch" position="relative" container>
                              <ReaderItem
                                schemaValue={i}
                                value={values[i.id]}
                                error={errors[i.id]}
                                setTouched={(t) => setTouched({ ...touched, [i.id]: t })}
                                touched={touched[i.id] as any}
                                onChange={(val) => setValues((d) => ({ ...d, [i.id]: getValue(val, d[i.id]) }))}
                                focused={focusedId == i.id}
                                setFocus={(ref) => {
                                  setTouched({ ...touched, [focusedId]: true });
                                  setFocusedId(i.id);
                                }}
                              />
                              <Badge color="error" badgeContent={unreadCount} sx={{ display: 'block', position: 'absolute', right: 16, top: 40 }}>
                                <IconButton
                                  onClick={() => setSelectedQuestion(i.id)}
                                  sx={{ color: 'white', bgcolor: 'primary.main', ':hover': { bgcolor: 'primary.light', color: 'white' }, width: 38, height: 38 }}
                                >
                                  <FontAwesomeIcon fixedWidth icon={faCommentLines} />
                                </IconButton>
                              </Badge>
                            </Grid>
                          );
                        }
                      }
                    })}
                    {canSubmit && (
                      <Grid item xs={12} justifyContent="flex-end" display="flex">
                        <Button variant="contained" onClick={submitForm} endIcon={<FontAwesomeIcon icon={faChevronRight} />}>
                          {t('submit')}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <StandardPopup visible={Boolean(selectedQuestion)} onBackdropClick={() => setSelectedQuestion(null)} width={800}>
                    <Card>
                      <CardContent>
                        <Typography variant="h1" sx={{ marginBottom: 3, display: 'block' }} fontWeight="500">
                          Comment Application
                        </Typography>
                        <Typography variant="overline" sx={{ marginBottom: 2, display: 'block' }}>
                          Mentioned answer
                        </Typography>
                        <Card sx={{ borderBottom: '4px solid ' + theme.palette.info.main, borderRadius: 1 }}>
                          <Typography variant="subtitle1" sx={{ padding: 2 }}>
                            Question: {items.find((i) => i.id === selectedQuestion)?.title}
                          </Typography>
                          {/* <Typography>Applicant answer: {values[selectedQuestion]}</Typography> */}
                        </Card>
                        <Comments
                          full
                          containerProps={{
                            // height: '500px',
                            maxHeight: '500px',
                            padding: 1,
                            marginTop: 3,
                            // paddingRight: 0,
                            flex: 1,
                            boxSizing: 'border-box',
                          }}
                          chats={chats.filter((c) => c.questionId === selectedQuestion)}
                          onSend={(m) => onSendChat(m, selectedQuestion)}
                          readChatItems={readChatItems}
                          writeMessageContainerProps={{ sx: { bgcolor: 'grey.300', paddingX: 5, paddingBottom: 4, paddingTop: 3 }, style: { marginLeft: -40, marginRight: -40, marginBottom: -40 } }}
                        />
                      </CardContent>
                    </Card>
                  </StandardPopup>
                </Box>
              );
            }}
          </Formik>
        </Container>
      </Box>
    </ProvidersProvider>
  );
}

export default FormViewerResponse;
