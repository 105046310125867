import React from 'react';

type inputProps = {
  placeholder: string;
  value: string;
  onChange: (text: string) => void;
  paddingVertical?: boolean;
  color?: string;
};

export const StandardInput = ({ value, placeholder, onChange, paddingVertical = true, color = '#6D6D6D' }: inputProps) => (
  <div className="input input-container" style={{ display: 'flex' }}>
    <input
      className="text-input"
      style={{ backgroundColor: 'transparent', color: color, width: '100%', paddingTop: paddingVertical ? 0 : 0, paddingBottom: paddingVertical ? 0 : 0 }}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);
