import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Paper, Typography, useTheme } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingPromiseButton } from '@base/web';
import { useProvider } from '../provider/ProviderContext';
import { RemoteFile } from '../provider/RemoteFile';

export async function downloadFile(file: RemoteFile) {
  const urlPromise = file.getDownloadUrl();

  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = (event) => {
    const blob = xhr.response;
    const blobUrl = URL.createObjectURL(blob);
    const element = document.createElement('a');
    element.setAttribute('href', blobUrl);
    element.setAttribute('download', file.name);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  xhr.open('GET', await urlPromise);
  xhr.send();
}

export function FileDownload(file: RemoteFile) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { fileProvider } = useProvider();

  const onDownloadClick = useCallback(
    async (e) => {
      e.stopPropagation();
      await fileProvider.getRemoteFile(file.id).then((file) => downloadFile(file));
    },
    [file, fileProvider]
  );

  return (
    <Box>
      <Paper variant="outlined">
        <Box padding={1} display="flex" alignItems="center">
          <Box flex={3}>
            <Typography variant="subtitle1">{file.name}</Typography>
            <Typography variant="body1">{file.mimeType}</Typography>
          </Box>
          <LoadingPromiseButton onClick={onDownloadClick} endIcon={<FontAwesomeIcon icon={faDownload} size="xs" color={theme.palette.error.contrastText} />} color="primary" variant="contained">
            {t('downloadFile')}
          </LoadingPromiseButton>
        </Box>
      </Paper>
    </Box>
  );
}

export function FileDownloadReader(file: RemoteFile) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { uploadFileProvider } = useProvider();

  const onDownloadClick = useCallback(
    async (e) => {
      e.stopPropagation();
      await uploadFileProvider.getRemoteFile(file.id).then((file) => downloadFile(file));
    },
    [file, uploadFileProvider]
  );

  return (
    <Box>
      <Paper variant="outlined">
        <Box padding={1} display="flex" alignItems="center">
          <Box flex={3}>
            <Typography variant="subtitle1">{file.name}</Typography>
            <Typography variant="body1">{file.mimeType}</Typography>
          </Box>
          <LoadingPromiseButton onClick={onDownloadClick} endIcon={<FontAwesomeIcon icon={faDownload} size="xs" color={theme.palette.error.contrastText} />} color="primary" variant="contained">
            {t('downloadFile')}
          </LoadingPromiseButton>
        </Box>
      </Paper>
    </Box>
  );
}

export function FileDownloadReaderMinimal(file: RemoteFile) {
  const { uploadFileProvider } = useProvider();
  const { t } = useTranslation();

  const onDownloadClick = useCallback(
    async (e) => {
      e.stopPropagation();
      await uploadFileProvider.getRemoteFile(file.id).then((file) => downloadFile(file));
    },
    [file, uploadFileProvider]
  );

  return (
    <LoadingPromiseButton fullWidth variant="contained" onClick={onDownloadClick} endIcon={<FontAwesomeIcon icon={faDownload} size="xs" />} color="primary">
      {t('downloadFile')}
    </LoadingPromiseButton>
  );
}
