import { API, Campaign, FormSubmission, getKeysWithTruthyValues, UserCreationData } from '@base/core';
import { GetFormVierwerEmailRepresentation } from '@editors/form-editor';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { CampaignModule } from '../../campaign/CampaignNotificationsPopup';
import { useGetFile } from '../../campaign/hooks';

export function useSetFormContentMutation(campaignId: string, formId: string) {
  const queryClient = useQueryClient();
  const user = useSelector((state: Core.StateType) => state.auth.user);

  const { data: campaign } = useGetFile<Campaign>(campaignId);

  return useMutation(
    async (data: any) => {
      const groupid = getKeysWithTruthyValues(user.groups).find((gid) => campaign.selectedGroups?.[gid]);

      const file = await API.campaigns.setFormViewerContent(campaignId, formId, groupid, data);
      return file;
    },
    {
      onSuccess: (file, data, context) => {
        void queryClient.invalidateQueries(['campaigns', campaignId, 'formssubmissions', formId]);
      },
    },
  );
}

async function getUserIdsForNotification(campaignId: string) {
  const campaign = await API.campaigns.getFile(campaignId, true);
  const parent = (await API.campaigns.getFile(campaign.parent, true)) as CampaignModule;

  return {
    chats: getKeysWithTruthyValues(parent.notifications?.chat),
    submissions: getKeysWithTruthyValues(parent.notifications?.submissions),
  };
}

async function sendSubmissionNotification(campaignId: string, formId: string, groupId: string, formSubmission: FormSubmission) {
  const group = await API.groups.getGroup(groupId);

  const parent = (await API.campaigns.getParents(campaignId))[0];

  const title = 'New application submission from ' + group.name;
  const notificationPersons = await getUserIdsForNotification(campaignId);

  await API.email.sendNotificationEmail({ uids: notificationPersons.submissions }, 'New application submission - IBU Scope', {
    autosend: true,
    emailTitle: title,
    emailText: `
    <p>Dear IBU Scope User</p><br/><br/>
    <p>A federation sent a new submission in ${parent.name ?? 'IBU Scope'}.</p><br/>
    <p><b>Federation:</b> ${group.name}</p><br/>
    <p>Click on the button below to view all submissions.</p><br/>
    <br/>
    <br/>
    `,
    buttonLink: `${window.location.origin}/campaigns/${campaignId}`,
    buttonText: 'Go to Campaign',
    belowButtonText: `
    <p>Best regards,</p>
    </br>
    <p>your IBU Scope Team</p>
    `,
  });
}

export async function sendAgreementNotification(campaignId: string, formId: string, groupId: string) {
  const group = await API.groups.getGroup(groupId);

  // const title = 'New application submission from ' + group.name;
  const notificationPersons = await getUserIdsForNotification(campaignId);
  try {
    await API.email.sendNotificationEmail({ uids: notificationPersons.submissions }, 'Agreement has been signed by NF! - IBU Scope', {
      autosend: true,
      emailTitle: 'An agreement has been signed by a National Federation!',
      paragraphTitle: 'National Federation: ' + group.name,
      emailText: `
      <p>Dear IBU Scope User</p></br></br>
      <p>An agreement has been signed by ${group.name}.</p>
      </br></br>
      <p>You can check the agreement in your submission dashboard and with the button below.</p>
      <br>`,
      buttonText: 'Open Application',
      buttonLink: `${window.location.origin}/campaigns/${campaignId}/forms/${formId}`,
      belowButtonText: `
      <p>Best regards,</p>
      </br>
      <p>your IBU Scope Team</p>
      `,
    });
  } catch (error) {
    console.log(error);
  }
}

async function sendSubmissionNotificationToNF(campaignId: string, formId: string, groupId: string, formSubmission: FormSubmission) {
  const form = await API.campaigns.getForm(campaignId, formId);
  const title = 'Your submission has been sent - IBU Scope';
  await API.email.sendNotificationEmail({ groupIds: [groupId] }, title, {
    autosend: true,
    // emailTitle: title,
    emailText:
      `
    <p>Dear National Federation</p></br></br>
    <p>Your submission has been sent to IBU.</p></br>
    <p>Click the button below the details to view it in IBU Scope.</p></br>
    <h2>Submission details:</h2>
    <br/>
    ` +
      (await GetFormVierwerEmailRepresentation(form, formSubmission)) +
      '<br/>',
    buttonLink: `${window.location.origin}/campaigns/${campaignId}/forms/${formId}`,
    belowButtonText: `
    <p>Best regards,</p>
    </br>
    <p>your IBU Scope Team</p>
    `,
  });
}

export async function sendChatNotification(campaign: Campaign, user: Core.User, formName: string, message: string, question: string) {
  const groupId = getKeysWithTruthyValues(user.groups).find((gid) => campaign.selectedGroups?.[gid]);
  const group = await API.groups.getGroup(groupId);

  const title = 'New comment on your application - IBU Scope ';
  const notificationPersons = await getUserIdsForNotification(campaign.id);
  await API.email.sendNotificationEmail({ uids: notificationPersons.chats }, title, {
    autosend: true,
    emailTitle: title,
    emailText: `
    <p>Dear IBU Scope User</p><br/><br/>
    <p>There is a new comment on your application.</p></br>
    <p><b>Applicationname:</b> ${formName}</p>
    <p><b>Federation:</b> ${group.name}</p>
    <br/>
    <br/>
    <p>${user.displayName} commented: <i>"${message}"</i></p><br/>
    <p>on question: <i>"${question}"</i><p/>
    <br/>
    <br/>
    `,
    buttonLink: `${window.location.origin}/campaigns/${campaign.id}`,
    buttonText: 'Go to Campaign',
    belowButtonText: `
    <p>Best regards,</p>
    </br>
    <p>your IBU Scope Team</p>
    `,
  });
}

export function useSubmitFormContentMutation(campaignId: string, formId: string, submitterId: string) {
  const queryClient = useQueryClient();
  const user = useSelector((state: Core.StateType) => state.auth.user);

  const { data: campaign } = useGetFile<Campaign>(campaignId);

  return useMutation(
    async (data: any) => {
      const groupid = getKeysWithTruthyValues(user.groups).find((gid) => campaign.selectedGroups?.[gid]);
      await API.groups.updateGroup(groupid, { [`formSubmissions.${formId}`]: campaign.id });
      const file = await API.campaigns.createFormSubmission(campaignId, formId, groupid, submitterId, data);
      try {
        await sendSubmissionNotification(campaignId, formId, groupid, file);
        await sendSubmissionNotificationToNF(campaignId, formId, groupid, data);
      } catch (error) {
        console.error(error);
      }
      return groupid;
    },
    {
      onSuccess: (groupid, data, context) => {
        void queryClient.invalidateQueries(['campaigns', campaignId, 'formssubmissions', formId]);
        void queryClient.invalidateQueries(['campaigns', campaignId, 'formssubmissions', `${formId}:${groupid}`, 'history']);
      },
    },
  );
}

export function useUpdateFormContentMutation(onlyState?: boolean) {
  const queryClient = useQueryClient();
  const user = useSelector((state: Core.StateType) => state.auth.user);

  return useMutation(
    async ({ data, campaignId, formId, groupId }: { data: Partial<FormSubmission>; campaignId: string; formId: string; groupId: string }) => {
      queryClient.setQueryData(
        ['campaigns', campaignId, 'submissions'],
        (oldData: FormSubmission[]) => oldData && oldData.map((submission) => (submission.id == `${formId}:${groupId}` ? { ...submission, state: data.state } : submission)),
      );
      await API.campaigns.updateFormContent(campaignId, formId, groupId, data);
    },
    {
      onSuccess: (_, { campaignId, formId, data, groupId }, context) => {
        void queryClient.invalidateQueries(['campaigns', campaignId, 'formssubmissions', formId]);
        if (onlyState)
          queryClient.setQueryData(['campaigns', campaignId, 'submissions'], (old: FormSubmission[]) => {
            if (!old) return old;
            return old.map((i) => (i.id == `${formId}:${groupId}` ? { ...i, ...data } : i));
          });
        else void queryClient.invalidateQueries(['campaigns', campaignId, 'submissions']);
      },
    },
  );
}

export function useDeleteUserExternalMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(
    async (userId) => {
      await API.users.deleteExternalUser(userId);
    },
    {
      onSuccess: (group, id, context) => {
        queryClient.setQueryData(['users'], (old: Core.User[]) => (old ?? []).filter((u) => u.id !== id));
      },
    },
  );
}

export function useDeleteUsersExternalMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, any, string[]>(
    async (userIds) => {
      await Promise.all(userIds.map((id) => API.users.deleteExternalUser(id)));
    },
    {
      onSuccess: (group, ids, context) => {
        queryClient.setQueryData(['users'], (old: Core.User[]) => (old ?? []).filter((u) => !ids.some((id) => id == u.id)));
      },
    },
  );
}

export function useUpdateUserExternalMutation<T extends Partial<Omit<Core.User & { password: string }, 'groups' | 'rights' | 'roles'>>>() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, T & { userImage?: Blob }>(
    async ({ id, userImage, photoURL, emailVerified, ...update }) => {
      await API.users.updateExternalUser(id, update);
      if (userImage) {
        await API.users.setProfileImage(id, userImage);
      }
    },
    {
      onSuccess: (group, req, context) => {
        const { id, userImage, ...update } = req;
        queryClient.setQueryData(['users'], (old: Core.User[]) => (old ?? []).map((u) => (u.id == id ? { ...u, ...update } : u)));
      },
    },
  );
}

export function useCreateUserExternalMutation<T extends UserCreationData & Core.User & { group: string }>() {
  const queryClient = useQueryClient();

  return useMutation<Core.User, Error, T & { userImage?: Blob }>(
    async ({ userImage, rights, roles, groups, photoURL, group, ...rest }) => {
      const user = await API.users.createExternalUser(rest, group);
      const promises = [];
      if (userImage) {
        promises.push(API.users.setProfileImage(user.id, userImage));
      }

      if (rights) {
        if (Object.keys(rights).length > 0) promises.push(API.users.setUserRights(user.id, rights));
      }
      await Promise.all(promises);
      await queryClient.invalidateQueries(['users']);
      return { ...user, ...rest } as Core.User;
    },
    {
      onSuccess: (user, req, context) => {
        queryClient.setQueryData(['users'], (old: Core.User[]) => [...(old ?? []), user]);
      },
    },
  );
}

export function useSetUserRightsExternalMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { uid: string; rights: Core.GroupRights }>(
    async ({ uid, rights }) => {
      await API.users.setExternalUserRights(uid, rights);
    },
    {
      onSuccess: (group, req, context) => {
        const { uid, rights } = req;
        queryClient.setQueryData(['users'], (old: Core.User[]) => (old ?? []).map((u) => (u.id == uid ? { ...u, rights: { ...(u.rights ?? {}), groupRights: rights } } : u)));
      },
    },
  );
}
