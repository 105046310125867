import { PermissionsObject } from '@base/core';
import { MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../css/components/user/ConfigurableRoleUser.scss';
import { DefaultTheme } from '../../theme/Theme';
import { useTheme } from '../../theme/ThemeProvider';
import { DefaultDropDown } from '../common/dropdowns/SetPropertyDropdown';
import { UserImageHandler } from '../common/UserImageHandler';

export const Roles = {
  Viewer: 'Betrachter',
  Contributer: 'Beitragender',
  ContentManager: 'Content Manager',
  Admin: 'Administrator',
};

interface ConfigurableUserProps {
  userName: string;
  userImage?: string | Promise<string>;
  role?: string;
  onRoleSelect: (role: string) => void;
}

type RightOwnerPermissionMap = { [uid: string]: { read: boolean; write: boolean; move: boolean; editPermissions: boolean } };

function extractRightOwners<T extends object, R extends string>(lastPermissions: { [id: string]: T }, values: string[] = [], permission: R) {
  const current = { ...lastPermissions } as { [id: string]: T & { R: boolean } };
  for (const key of values) {
    if (current[key]) {
      //@ts-ignore
      current[key][permission] = true;
    } else current[key] = { [permission]: true } as any;
  }
  return current;
}

export function extractUsersWithRolesFromPermissions(permissions: Core.AccessObject) {
  let users: RightOwnerPermissionMap = {};
  if (!permissions) return [];
  users = extractRightOwners(users, permissions.read, 'read');
  users = extractRightOwners(users, permissions.write, 'write');
  users = extractRightOwners(users, permissions.move, 'move');
  users = extractRightOwners(users, permissions.permission, 'editPermissions');

  return Object.entries(users).map(([u, val]) => {
    let role: string = undefined;
    if (val.read) {
      role = Roles.Viewer;
      if (val.write) {
        role = Roles.Contributer;
        if (val.move) {
          role = Roles.ContentManager;
          if (val.editPermissions) {
            role = Roles.Admin;
          }
        }
      }
    }
    return { uid: u, role };
  });
}

export function getRightsObjFromRole(role: string): { read?: boolean; write?: boolean; move?: boolean; editPermissions?: boolean } {
  switch (role) {
    case Roles.Viewer:
      return { read: true };
    case Roles.Contributer:
      return { read: true, write: true };
    case Roles.ContentManager:
      return { read: true, write: true, move: true };
    case Roles.Admin:
      return { read: true, write: true, move: true, editPermissions: true };
  }
}

export function union<T, R>(values: T[], selector: (T) => R) {
  const map = new Map();
  for (const value of values) {
    map.set(selector(value), value);
  }
  return Array.from(map.values());
}

export function calculateNewPermissions(permissions: Core.AccessObject, roles: { uid: string; role: string }[]): { read: string[]; write: string[]; move: string[]; permission: string[] } {
  let users: RightOwnerPermissionMap = {};
  users = extractRightOwners(users, permissions.read, 'read');
  users = extractRightOwners(users, permissions.write, 'write');
  users = extractRightOwners(users, permissions.move, 'move');
  users = extractRightOwners(users, permissions.permission, 'editPermissions');

  const newRoles = union(
    [
      ...Object.entries(users).map(([u, val]) => {
        let role: string = undefined;
        if (val.read) {
          role = Roles.Viewer;
          if (val.write) {
            role = Roles.Contributer;
            if (val.move) {
              role = Roles.ContentManager;
              if (val.editPermissions) {
                role = Roles.Admin;
              }
            }
          }
        }
        return { uid: u, role };
      }),
      ...roles,
    ],
    ({ uid }) => uid,
  );

  return extractPermissionsFromRoles(newRoles);
}

const ErrorMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.dark + ' !important',
    color: 'white !important',
    fontWeight: 600,
  },
}))(MenuItem);

export const ConfigurableUser = ({ userName = 'Unnamed', userImage, role = Roles.Viewer, onRoleSelect }: ConfigurableUserProps) => {
  const theme = useTheme();
  const regex = /^([A-Z]).*?(\s([A-Z])[\w\-]*)?$/;
  const { t } = useTranslation();
  const [_, firstChar, __, secondChar] = regex.exec(userName) ?? [];

  return (
    <div className="configurable-user" style={{ boxShadow: DefaultTheme.styles.shadow.shadowXS }}>
      <UserImageHandler userName={userName} userPhotoUrl={userImage} style={{ height: 64, width: 64, borderStyle: 'solid', borderColor: '#efefef' }} fontSize={28} />
      <div className="configurable-user-name">{userName}</div>
      {/* <FormControl> */}
      {/* <InputLabel id="demo-customized-select-label">Age</InputLabel> */}
      <Select
        // labelId="demo-customized-select-label"
        // id="demo-customized-select"
        value={role}
        onChange={({ target: { value, name } }) => {
          onRoleSelect(value as string);
        }}
        input={<DefaultDropDown />}
        variant="filled"
        style={{ alignSelf: 'stretch' }}
        defaultValue={Roles.Viewer}
      >
        <MenuItem value={Roles.Viewer}>{t('file-roles.viewer')}</MenuItem>
        <MenuItem value={Roles.Contributer}>{t('file-roles.contributer')}</MenuItem>
        <MenuItem value={Roles.ContentManager}>{t('file-roles.content-manager')}</MenuItem>
        <MenuItem value={Roles.Admin}>{t('roles.admin')}</MenuItem>
        <ErrorMenuItem value={'remove'}>{t('remove')}</ErrorMenuItem>
      </Select>
      {/* </FormControl> */}
    </div>
  );
};
export function extractPermissionsFromRoles(newRoles: any[]) {
  const rights = { read: [], write: [], move: [], permission: [] };

  for (const { uid, role } of newRoles) {
    const right = getRightsObjFromRole(role);
    if (right.editPermissions) rights.permission.push(uid);
    if (right.move) rights.move.push(uid);
    if (right.write) rights.write.push(uid);
    if (right.read) rights.read.push(uid);
  }
  return rights;
}
