import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const RequestPopupLoginCall = ({ setRequestPopupState }) => {
  const { t } = useTranslation();
  return (
    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', marginTop: 40, width: '100%' }}>
      <div className="row" style={{ marginBottom: 15 }}>
        <FontAwesomeIcon icon={faUserPlus} fixedWidth />
        <Typography variant="h4" sx={{ ml: 1 }}>
          {t('registration')}
        </Typography>
      </div>
      <Button variant="outlined" onClick={() => setRequestPopupState(true)} fullWidth>
        National Federation
      </Button>
      <Button sx={{ mt: 1 }} variant="outlined" onClick={() => setRequestPopupState(true)} fullWidth>
        Research Grant Programme
      </Button>
    </div>
  );
};
