import {ApplicationForm, Campaign, FormSubmission} from '@base/core';
import {faClock, faFileImport, faLock, faLockOpen, faSpinnerThird, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faCommentLines, faLayerPlus} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Dialog, Menu, MenuItem, Stack, TextField} from '@material-ui/core';
import {LoadingButton} from '@material-ui/lab';
import StaticDatePicker from '@material-ui/lab/StaticDatePicker';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useObservable} from 'rxjs-hooks';
import {map} from 'rxjs/operators';
import {NavLinkListItem, usePopup} from '../../components/common';
import {MainDialog} from '../../components/common/popups/MainDialog';
import {getNotificationCountFromFormSubmissions} from '../../lib/notificationCountHelpers';
import {CampaignFolderSelectPopup} from '../fileManager/FolderSelectPopup';
import {
  useCreateFormMutation,
  useDeleteFormMutation,
  useDuplicateFormMutation,
  useUpdateFormMutation
} from './hooks/mutations';
import {useGetFormSubmissions} from './hooks/useGetFormSubmissions';

export function FormSidebarItem({
  form,
  onEditForm,
  campaign,
  baseUrl,
  allForms,
}: {
  form: ApplicationForm;
  allForms: ApplicationForm[];
  campaign: Campaign;
  onEditForm(formId: string): void;
  baseUrl: string;
}) {
  console.log('form', form);
  const { t } = useTranslation();
  const { data: submissions = [] } = useGetFormSubmissions(campaign?.id);
  const childFormIds = allForms.filter((f) => f.parent == form.id).map((f) => f.id);
  const submissionCount = submissions.filter((s) => s && (s.formId === form.id || childFormIds.includes(s.formId)) && s.state === 'submitted').length;
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const [selectOpen, setSelectOpen] = useState(false);
  const [deadlineOpen, setDeadlineOpen] = useState(false);
  const [deadlineDate, setDeadlineDate] = useState(form.deadline);
  const createFormMutation = useCreateFormMutation<ApplicationForm>();
  const duplicateFormMutation = useDuplicateFormMutation<ApplicationForm>();
  const updateFormMutation = useUpdateFormMutation<ApplicationForm>();
  const deleteFormMutation = useDeleteFormMutation();
  const notify = usePopup();
  const isPublic = form.published !== false;

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const me = useSelector((state: Core.StateType) => state.auth.user);
  const filteredSubmissions = useMemo(() => submissions.filter((s) => s && s.formId === form.id), [submissions, form.id]);
  const unreadCount = useObservable<number, [FormSubmission[]]>(
    (state$, input$) =>
      input$.pipe(
        map(([submissions]) => submissions),
        getNotificationCountFromFormSubmissions(me.id),
      ),
    0,
    [filteredSubmissions],
  );
  const query = window.location.search;

  return (
    <div onContextMenu={handleContextMenu} key={form.id} style={{ cursor: 'context-menu' }}>
      <NavLinkListItem
        to={(baseUrl + '/forms/' + form.id + query.replace(/&?form\-id=\w+/, "")).replace(/\/\//g, '/')}
        icon={isPublic ? faLockOpen : faLock}
        count={[
          { value: unreadCount, color: 'error', label: 'Chats', countIcon: faCommentLines },
          { value: submissionCount, color: 'secondary', label: 'Submissions', countIcon: faLayerPlus },
        ]}
        title={form.name}
        onDoubleClick={() => onEditForm(form.id)}
        inactive={!isPublic}
        style={{ cursor: 'context-menu' }}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem
          onClick={async () => {
            try {
              await updateFormMutation.mutateAsync({ formId: form.id, update: { published: !isPublic }, campaignId: campaign.id });
              notify({
                title: t('saved'),
                type: 'success',
              });
            } catch (error) {
              notify({
                title: t('error'),
                type: 'error',
                text: error.message,
              });
            }
            handleClose();
          }}
        >
          {updateFormMutation.isLoading ? (
            <FontAwesomeIcon style={{ marginRight: 12 }} icon={faSpinnerThird} spin fixedWidth />
          ) : (
            <FontAwesomeIcon style={{ marginRight: 12 }} icon={isPublic ? faLock : faLockOpen} fixedWidth />
          )}
          {isPublic ? t('unpublish') : t('publish')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectOpen(true);
            handleClose();
          }}
        >
          <FontAwesomeIcon style={{ marginRight: 12 }} icon={faFileImport} fixedWidth />
          {t('copy-form')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeadlineOpen(true);
            handleClose();
          }}
        >
          <FontAwesomeIcon style={{ marginRight: 12 }} icon={faClock} fixedWidth />
          {t('deadline')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeletePopup(true);
            handleClose();
          }}
        >
          <FontAwesomeIcon style={{ marginRight: 12 }} icon={faTrash} fixedWidth />
          {t('delete')}
        </MenuItem>
      </Menu>
      <MainDialog
        open={openDeletePopup}
        onCloseClick={() => setOpenDeletePopup(false)}
        onSaveClick={async () => {
          await deleteFormMutation.mutate({ campaignId: campaign.id, formId: form.id });
        }}
        modalTitle={t('delete-form-title')}
        description={t('sure-to-delete-form')}
        buttonText={t('delete')}
      />
      <CampaignFolderSelectPopup
        onFolderSelected={async (id) => {
          try {
            await duplicateFormMutation.mutateAsync({ form, campaignId: id });
            notify({
              title: t('saved'),
              type: 'success',
            });
          } catch (error) {
            notify({
              title: t('error'),
              type: 'error',
              text: error.message,
            });
          }
          setSelectOpen(false);
        }}
        setVisible={setSelectOpen}
        visible={selectOpen}
        initialParent={campaign?.parent}
        mimeTypes={['campaign']}
      />
      <Dialog
        open={deadlineOpen}
        onClose={() => {
          setDeadlineOpen(false);
        }}
        PaperComponent={Box}
        PaperProps={{ sx: { borderRadius: 1 } }}
      >
        <Stack spacing={1}>
          <StaticDatePicker
            value={(form.deadline as number) ?? null}
            mask={t('full-date-mask')}
            open={deadlineOpen}
            onChange={(newValue: Date) => {
              setDeadlineDate(newValue.valueOf());
            }}
            renderInput={(params) => {
              return <TextField {...params} />;
            }}
          />
          <LoadingButton
            loading={updateFormMutation.isLoading}
            variant="contained"
            color="primary"
            onClick={() =>
              updateFormMutation
                .mutateAsync({ campaignId: campaign.id, formId: form.id, update: { deadline: deadlineDate as any } })
                .then(() => {
                  setDeadlineOpen(false);
                  notify({ type: 'success', title: t('deadline-updated') });
                })
                .catch((error) => notify({ type: 'error', title: t('error'), text: JSON.stringify(error) }))
            }
          >
            {t('save')}
          </LoadingButton>
          <Button variant="contained" color="error" onClick={() => setDeadlineOpen(false)}>
            {t('cancel')}
          </Button>
        </Stack>
      </Dialog>
    </div>
  );
}
