import * as React from 'react';

function SvgArrowToRightRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.208 20.75" ref={svgRef} {...props}>
      <path d="M10.813 1.918l8.019 8a.649.649 0 010 .919l-8.019 8a.649.649 0 01-.919 0l-1.059-1.064a.641.641 0 01.011-.924l5.247-5.063H.648A.65.65 0 010 11.137V9.624a.65.65 0 01.648-.648h13.445L8.846 3.912a.651.651 0 01-.011-.924l1.059-1.059a.64.64 0 01.919-.011zM21.4.648V20.1a.65.65 0 00.648.648h1.512a.65.65 0 00.648-.648V.648A.65.65 0 0023.56 0h-1.513a.65.65 0 00-.647.648z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowToRightRegular);
export default ForwardRef;
