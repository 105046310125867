import { AccessObjectUpdate } from '@base/core';
import { FileModule } from '../views/campaign/CampaignOverview';

export function getPlainPermissionsFromPermissionUpdate(
  value: Partial<Omit<FileModule, 'id' | 'permissions' | 'lastUpdateTime' | 'createTime' | 'favoriteOf'>> & {
    permissions?: { users: AccessObjectUpdate; groups: AccessObjectUpdate; visibility?: 'public' | 'private'; };
    setFavorite?: { uid: string; isFavorite: boolean; };
  } & { imageFile?: File; }): { users: Core.AccessObject; groups: Core.AccessObject; visibility: 'public' | 'private'; } {
  return {
    visibility: value.permissions?.visibility ?? 'public',
    groups: {
      read: value.permissions?.groups?.read?.set ?? [],
      write: value.permissions?.groups?.write?.set ?? [],
      move: value.permissions?.groups?.move?.set ?? [],
      permission: value.permissions?.groups?.permission?.set ?? [],
    },
    users: {
      read: value.permissions?.users?.read?.set ?? [],
      write: value.permissions?.users?.write?.set ?? [],
      move: value.permissions?.users?.move?.set ?? [],
      permission: value.permissions?.users?.permission?.set ?? [],
    },
  };
}
