import React from 'react';
import { Card, CardContent, Grid, Stack, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StandardPopup } from '../../../components/common';

export type RequestPopupProps = {
  active: boolean;
  setActive;
  readOnly: boolean;
  topic: string;
  name: string;
  description: string;
};

export const RequestPopup = ({ active, setActive, readOnly, topic, name, description }: RequestPopupProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //   const submitFormData = async (data: AccessRequestFormData) => {
  //     try {
  //       await API.accessRequest.sendAccessRequest(data);
  //       notification({ title: t('successfully-sent'), text: t('you-have-successfully-applied-for-registration'), type: 'success' });
  //     } catch (error) {
  //       notification({ title: t('error'), text: error, type: 'error' });
  //     }
  //     setActive(false);
  //   };

  return (
    <StandardPopup onBackdropClick={() => setActive(false)} visible={active}>
      <Card css={{ maxWidth: 900 }}>
        <CardContent style={{ maxWidth: 900 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <Typography variant="h3" fontWeight={500} fontSize="2rem">
                  {topic}
                </Typography>
                <Typography variant="h3" fontWeight={400} fontSize="2rem">
                  {name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{description}</Typography>
            </Grid>
          </Grid>
          {/* <UserForm onSubmit={async (data) => submitFormData(data)} readOnly={readOnly} /> */}
        </CardContent>
      </Card>
    </StandardPopup>
  );
};
