import * as React from 'react';

function SvgFilePowerpointLight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.801 62.413" ref={svgRef} {...props}>
      <path d="M45.082 11.944L34.857 1.718A5.85 5.85 0 0030.725 0H5.85A5.87 5.87 0 000 5.862v50.7a5.852 5.852 0 005.85 5.85h35.1a5.852 5.852 0 005.85-5.85V16.088a5.879 5.879 0 00-1.718-4.144zm-2.754 2.767a1.917 1.917 0 01.512.9H31.2V3.973a1.917 1.917 0 01.9.512zm-1.377 43.8H5.85a1.956 1.956 0 01-1.95-1.95V5.862a1.956 1.956 0 011.95-1.95H27.3v12.675a2.918 2.918 0 002.925 2.925H42.9v37.051a1.956 1.956 0 01-1.949 1.95zM24.9 25.363h-7.837a1.467 1.467 0 00-1.463 1.462V49.25a1.467 1.467 0 001.463 1.463h.975A1.467 1.467 0 0019.5 49.25v-7.361h5.4a7.977 7.977 0 008.251-8.41c0-4.704-2.901-8.116-8.251-8.116zm3.168 11.907a5.061 5.061 0 01-3.778 1.389H19.5v-10.25h4.839a5.078 5.078 0 013.729 1.353 5.78 5.78 0 010 7.508z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFilePowerpointLight);
export default ForwardRef;
