import * as React from 'react';

function SvgStrikethrough(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.709 22.599" ref={svgRef} {...props}>
      <path
        d="M12.04 22.099a10.807 10.807 0 01-4.277-.78 6.579 6.579 0 01-2.761-2.055 4.9 4.9 0 01-1-2.744.578.578 0 01.179-.42.613.613 0 01.45-.182h1.47a.772.772 0 01.752.6 3.607 3.607 0 001.545 2.173 6.211 6.211 0 003.646.917 6.611 6.611 0 003.81-.9 2.913 2.913 0 001.259-2.492 2.372 2.372 0 00-.614-1.678 3.472 3.472 0 00-.411-.372h3.587a5.6 5.6 0 01.317 1.96 5.161 5.161 0 01-2.144 4.395 9.636 9.636 0 01-5.808 1.578zM9.601 8.942h-4.3l-.007-.012-.01-.015a5.17 5.17 0 01-.689-2.775 5.044 5.044 0 01.87-2.9 5.873 5.873 0 012.519-2.012 9.612 9.612 0 013.9-.732 8.977 8.977 0 014 .824 6.568 6.568 0 012.536 2.069 4.786 4.786 0 01.931 2.5.6.6 0 01-.182.45.606.606 0 01-.449.182h-1.5a.754.754 0 01-.45-.15.829.829 0 01-.3-.449 3.025 3.025 0 00-1.438-2.13 5.579 5.579 0 00-3.15-.842 5.826 5.826 0 00-3.242.8 2.654 2.654 0 00-1.2 2.354 2.573 2.573 0 00.553 1.709 4.579 4.579 0 001.6 1.122z"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit={10}
      />
      <path d="M22.968 10.432H.741a.741.741 0 00-.741.741v.741a.741.741 0 00.741.741h22.227a.741.741 0 00.741-.741v-.741a.741.741 0 00-.741-.741z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStrikethrough);
export default ForwardRef;
