import { faSave, faTrash } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { StandardButton } from '../../components/common';
import { GridInputElement, GridMultilineInput } from '../../components/content/GridContent';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/components/common/layout/InputGrid.scss';
import '../../css/components/common/layout/usergrid.scss';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { useTheme } from '../../theme/ThemeProvider';
import { useParentFolderBreadCrumbs } from './FileManagerHelper';
import { useAutoUpdateState } from './useAutoUpdateState';
import { useDeleteFileMutation, useGetFile, useUpdateFileMutation } from './hooks';

export const FolderSettings = () => {
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();

  const { fileId: folderId } = useParams<{ fileId: string }>();
  const { data: currentFolder, isLoading, isError } = useGetFile(folderId);

  const [name, setName] = useAutoUpdateState(currentFolder?.name);
  const [description, setDescription] = useAutoUpdateState(currentFolder?.description);
  const { user } = useSelector((state: Core.StateType) => state.auth);

  const deleteMutation = useDeleteFileMutation();
  const updateMutation = useUpdateFileMutation();

  const deleteFolder = async () => {
    await deleteMutation.mutateAsync(currentFolder);
    history.push(`/files/${currentFolder.parent}`);
  };

  const updateFolder = async () => {
    const update: { name?: string; description?: string } = {};
    if (currentFolder.name != name) update.name = name;
    if (currentFolder.description != description) update.description = description;

    await updateMutation.mutateAsync({
      fileId: folderId,
      update: {
        description,
        name,
      },
    });
    history.goBack();
  };

  const additionalBreadcrumbs = useParentFolderBreadCrumbs(folderId);
  if (!user) return null;

  return (
    <ContentContainer>
      <NavbarSmall title={t('folder-settings')} breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.moduleOverview, ...additionalBreadcrumbs, BREADCRUMBS.folderSettings]}>
        <div style={{ display: 'flex' }}>
          <StandardButton contentColor="#fff" onClick={() => history.goBack()} text={t('abort')} bgColor="#ffffff30" />
          <StandardButton contentColor="#fff" onClick={deleteFolder} text={t('delete-folder')} bgColor={theme.colors.error} faIcon={faTrash} />
          <StandardButton contentColor="white" onClick={updateFolder} text={t('save')} bgColor="#ffffff30" faIcon={faSave} />
        </div>
      </NavbarSmall>
      <ContentView loading={isLoading}>
        <div className="content-title">{t('folder-info')}</div>
        <div className="input-grid">
          <GridInputElement paddingVertical={false} value={name} placeholder={t('folder-title')} onText={setName} />
          {/* <PictogramChooser /> */}
          <GridMultilineInput value={description ?? ''} placeholder={t('folder-description')} onText={setDescription} charMax={300} />
        </div>
        {/* <div className="content-title mg-top">Ordnerfreigabe</div>
        <UserSmallGrid addUserClick={() => {}} changeLinkClick={() => {}}>
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
          <ConfigurableUser name="Sarah Selani Bonabarney" userImage={Placeholder} onRoleSelect={(role) => console.log('Changed Role to: ', role)} />
        </UserSmallGrid> */}
      </ContentView>
    </ContentContainer>
  );
};
