export { default as AlignCenterRegular } from './AlignCenterRegular'
export { default as AlignJustifyRegular } from './AlignJustifyRegular'
export { default as AlignLeftRegular } from './AlignLeftRegular'
export { default as AlignRightRegular } from './AlignRightRegular'
export { default as ArrowToBottomRegular } from './ArrowToBottomRegular'
export { default as ArrowToLeftRegular } from './ArrowToLeftRegular'
export { default as ArrowToRightRegular } from './ArrowToRightRegular'
export { default as ArrowToTopRegular } from './ArrowToTopRegular'
export { default as Bold } from './Bold'
export { default as CheckCircleRegular } from './CheckCircleRegular'
export { default as Comment } from './Comment'
export { default as ExclamationCircleRegular } from './ExclamationCircleRegular'
export { default as ExclamationTriangleRegular } from './ExclamationTriangleRegular'
export { default as ExternalLinkRegular } from './ExternalLinkRegular'
export { default as EyeRegular } from './EyeRegular'
export { default as EyeStrike } from './EyeStrike'
export { default as FileImportRegular } from './FileImportRegular'
export { default as H1 } from './H1'
export { default as H2 } from './H2'
export { default as H3 } from './H3'
export { default as H4 } from './H4'
export { default as InfoCircleLight } from './InfoCircleLight'
export { default as InfoCircleRegular } from './InfoCircleRegular'
export { default as InfoSquareRegular } from './InfoSquareRegular'
export { default as Italic } from './Italic'
export { default as Layout } from './Layout'
export { default as LinkRegular } from './LinkRegular'
export { default as ListOlRegular } from './ListOlRegular'
export { default as ListUlRegular } from './ListUlRegular'
export { default as PaintRollerRegular } from './PaintRollerRegular'
export { default as PenRegular } from './PenRegular'
export { default as QuoteLeftRegular } from './QuoteLeftRegular'
export { default as QuoteLeftSolid } from './QuoteLeftSolid'
export { default as QuoteRightRegular } from './QuoteRightRegular'
export { default as QuoteRightSolid } from './QuoteRightSolid'
export { default as RedoRegular } from './RedoRegular'
export { default as RemoveFormatRegular } from './RemoveFormatRegular'
export { default as Strikethrough } from './Strikethrough'
export { default as TableRegular } from './TableRegular'
export { default as Text } from './Text'
export { default as TimesCircleRegular } from './TimesCircleRegular'
export { default as TimesRegular } from './TimesRegular'
export { default as Underline } from './Underline'
export { default as UndoRegular } from './UndoRegular'
export { default as UnlinkRegular } from './UnlinkRegular'