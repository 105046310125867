import { TextField } from '@material-ui/core';
import { Field, Formik } from 'formik';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import '../../../css/components/common/popups/RenamePopup.scss';
import { useRenameFileMutation } from '../../../views/fileManager/hooks/mutations';
import { ActionDialog } from './ActionDialog';
import { usePopup } from './TimedPopup';

interface RenamePopup {
  onClose: () => void;
  open: boolean;
  fileId?: string;
  fileName?: string;
}

export function RenamePopup({ onClose, open, fileId, fileName }: RenamePopup) {
  const { t } = useTranslation();
  const schema = yup.object({
    name: yup
      .string()
      .required(t('something-is-required', { something: t('name') }))
      .max(75, t('notifications.name-is-too-long')),
  });
  const notification = usePopup();
  const renameFileMutation = useRenameFileMutation();

  return (
    <Formik
      validationSchema={schema}
      initialValues={{ name: fileName }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await renameFileMutation.mutateAsync({ fileId, name: values.name });
          onClose();
          resetForm();
        } catch (error) {
          notification({ title: 'Rename failed...', type: 'error', text: error.message || error });
        }
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, values, setValues, errors, touched, ...formik }) => (
        <ActionDialog saveOnEnter modalTitle={t('rename')} open={open} onCloseClick={() => onClose()} onSaveClick={submitForm} savePending={isSubmitting}>
          <Field as={TextField} variant="outlined" label={t('name')} autoFocus name="name" required error={touched.name && Boolean(errors.name)} helperText={touched.name && errors.name} fullWidth />
        </ActionDialog>
      )}
    </Formik>
  );
}
