import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cx } from '../../HelperFunctions';
interface pictogramChooserProps {
  gridColumnSpan?: number;
  gridRowSpan?: number;
  onSelect: (icon: string) => void;
  icon?: string;
}

const Icons = [
  'address-book',
  'address-card',
  'archive',
  'balance-scale',
  'book',
  'briefcase',
  'shield-check',
  'skiing-nordic',
  'skiing',
  'building',
  'bullhorn',
  'business-time',
  'calendar',
  'calendar-alt',
  'calculator',
  'cabinet-filing',
  'chart-line',
  'chart-network',
  'chart-pie',
  'compass',
  'edit',
  'envelope',
  'file-chart-line',
  'file-chart-pie',
  'file-spreadsheet',
  'file-user',
  'globe',
  'industry',
  'folders',
  'landmark',
  'keynote',
  'marker',
  'mind-share',
  'phone',
  'users',
  'passport',
  'phone-office',
  'presentation',
  'print',
  'projector',
  'sticky-note',
  'tasks',
  'user-chart',
  'chart-bar',
  'analytics',
  'database',
  'desktop',
  'download',
  'laptop',
  'phone-laptop',
  'film',
  'image',
];

export const PictogramChooser = ({ gridColumnSpan = 1, gridRowSpan = 4, onSelect, icon }: pictogramChooserProps) => {
  const [selectedIndex, setSelectedIndex] = useState(Icons.findIndex((i) => i === icon));
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    setSelectedIndex(Icons.findIndex((i) => i === icon));
  }, [icon]);

  const select = (index: number) => {
    setSelectedIndex(index);
    onSelect(Icons[index]);
  };

  return (
    <Box sx={{ borderRadius: 2, border: `2px solid ${theme.palette.background.gray}`, padding: 1, marginBottom: 2 }}>
      <Typography variant="subtitle2" fontWeight="500" sx={{ marginBottom: 1, marginLeft: 1 }}>
        Choose Pictogramm
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', overflowX: 'auto', maxHeight: 200, paddingBottom: 1 }}>
        {Icons.map((icon, i) => (
          <Box
            key={icon}
            onClick={() => select(i)}
            sx={{
              cursor: 'pointer',
              borderRadius: 100,
              backgroundColor: selectedIndex == i ? theme.palette.primary.main : theme.palette.background.gray,
              color: selectedIndex == i ? '#ffffff' : theme.palette.grey[700],
              width: 52,
              height: 52,
              margin: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontAwesomeIcon style={{ height: 26, width: 26 }} icon={['fal', icon as any]} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
