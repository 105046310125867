export type BreadcrumbsType = { name: string; path: string; notExact?: boolean };
const bc = {
  login: { name: 'login', path: '/login' },
  resetPassword: { name: 'reset-password', path: '/reset-password' },
  home: { name: 'home', path: '/' },
  moduleOverview: { name: 'areas', path: '/files' },
  campaignOverview: { name: 'campaigns', path: '/campaigns' },
  campaignExternalOverview: { name: 'campaigns-external', path: '/campaigns-external' },
  folderSettings: { name: 'folder-settings', path: '/files/:fileId/settings' },
  users: { name: 'user', path: '/admin' },
  userEdit: { name: 'edit', path: '/users/:uid' },
  userCreation: { name: 'create', path: '/users/create' },
  moduleCreation: { name: 'area-create', path: '/files/create-module' },
  moduleEdit: { name: 'edit-area', path: '/files/:fileId/module-settings' },
  questions: { name: 'questions', path: '/question' },
  usergroupCreation: { name: 'user-group', path: '/usergroups/create' },
  usergroupExternalOverview: { name: 'user-group', path: '/usergroups/create' /* TODO: Add the right path */ },
  userAdd: { name: 'add-user', path: '/user-creation' },
  userSettings: { name: 'settings', path: '/settings' },
  organigram: { name: 'org-chart', path: '/organigram' },
  favorites: { name: 'favorites', path: '/favorites' },
  search: { name: 'search', path: '/search' },
  trash: { name: 'trash', path: '/trash' },
  usersExternal: { name: 'external-users', path: '/user-external/:groupId' },
  nfActivity: { name: 'nf-activity', path: '/nf-activity' },
};

export const BREADCRUMBS: {
  [key in keyof typeof bc]: BreadcrumbsType;
} = bc;
