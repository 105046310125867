import { faSave, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faCog, IconName } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Box, Button, Card, CardActions, CardContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Field, Formik, FormikHelpers } from 'formik';
import { FileUpdate } from 'libs/base/core/src/redux/files';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { StandardPopup, usePopup } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import { PictogramChooser, UploadImage } from '../../components/content/GridContent';
import { extractPermissionsFromRoles, extractUsersWithRolesFromPermissions } from '../../components/users';
import '../../css/components/common/layout/InputGrid.scss';
import '../../css/components/common/layout/usergrid.scss';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { CombinedConditionalRender } from '../../helpers/ConditionalRenderWithRights';
import { NamedGroup } from '../../theme/Theme';
import { useSettings } from '../../theme/ThemeProvider';
import { LabeledSwitchBoxRounded } from '../users/popups/LabeledSwitchBoxRounded';
import { FileModule } from './CampaignOverview';

export interface ModuleSettingsPopupProps {
  currentModule?: FileModule;
  onDelete?: () => Promise<void>;
  onUpdate: (value: FileUpdate<FileModule> & { imageFile?: File }) => Promise<void>;
  visible: boolean;
  onClose: () => void;
  create?: boolean;
  possibleParentModules?: FileModule[];
  isCampaign?: boolean;
}

export const ModuleSettingsPopup = ({ currentModule, onDelete, onUpdate, visible, onClose, create = false, possibleParentModules = [], isCampaign = false }: ModuleSettingsPopupProps) => {
  const notification = usePopup();
  const settings = useSettings();

  const [deletePending, setDeletePending] = useState(false);
  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    const v = { ...((currentModule ?? { permissions: { visibility: 'public' }, name: '', description: '', icon: 'address-book' }) as FileModule & { accessUsers: { uid: string; role: string }[] }) };
    const uids = currentModule?.permissions?.users ? extractUsersWithRolesFromPermissions(currentModule.permissions.users) : [];
    v.accessUsers = uids;
    v.groupTypeAccess = (currentModule?.groupTypeAccess?.map((groupName) => settings.namedGroups.find((g) => g.name === groupName)).filter(Boolean) || []) as any;
    return v;
  }, [currentModule, settings.namedGroups]);

  const saveModule = async (values: FileModule & { accessUsers: { uid: string; role: string }[]; imageFile?: File }, formikHelpers: FormikHelpers<FileModule>) => {
    try {
      const { name, icon, description, isParentModule, parentModule, groupTypeAccess, ...rest } = values;
      const change: FileUpdate & Partial<FileModule> & { imageFile?: File } = { name, icon, description, isParentModule: isParentModule ?? false, parentModule: parentModule ?? null };
      if (groupTypeAccess) change.groupTypeAccess = (groupTypeAccess as any[]).map((v: NamedGroup) => v.name);
      if (values.accessUsers != initialValues.accessUsers) {
        const newRights = extractPermissionsFromRoles(values.accessUsers);
        change.permissions = {
          users: {
            read: { set: newRights.read } as any,
            write: { set: newRights.write } as any,
            move: { set: newRights.move } as any,
            permission: { set: newRights.permission } as any,
          },
          groups: {},
        } as any;
      }

      if (values.imageFile) {
        change.imageFile = values.imageFile;
      }

      await onUpdate(change);
      onClose();
      formikHelpers.resetForm();
    } catch (error) {
      notification({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const schema = yup.object({
    name: yup.string().required(),
    icon: yup.string().required(t('something-is-required', { something: t('piktogram') })),
  } as { [key in keyof FileModule]: any });

  const theme = useTheme();
  const [openDeletePopup, setOpenDeletePopup] = useState(undefined);
  return (
    <StandardPopup onBackdropClick={onClose} visible={visible} width={750}>
      <MainDialog
        open={openDeletePopup}
        onCloseClick={() => setOpenDeletePopup(false)}
        onSaveClick={async () => {
          await onDelete();
          setOpenDeletePopup(false);
        }}
        saveButtonColor="error"
        secondButtonColor="primary"
        modalTitle={t('confirm-delete')}
        description={t('confirm-delete-module-text')}
        buttonText={t('delete')}
      />
      <Formik initialValues={initialValues} onSubmit={saveModule} validationSchema={schema}>
        {({ values, setFieldValue, handleSubmit, isSubmitting, touched, errors }) => {
          return (
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 3 }}>
                  <Box
                    sx={{ borderRadius: '50%', width: 42, height: 42, backgroundColor: theme.palette.primary.main, marginRight: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <FontAwesomeIcon style={{ height: 22, width: 22, color: 'white' }} icon={faCog} />
                  </Box>
                  <Typography variant="h2" fontWeight="500">
                    {t('proiect-application-information')}
                  </Typography>
                </Box>
                <Typography variant="overline" sx={{ color: theme.palette.grey[800] }}>
                  {t('basic-information')}
                </Typography>
                <Field
                  as={TextField}
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 2, marginTop: 2 }}
                  label={t('module-title')}
                  name="name"
                  required
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
                <Field
                  as={TextField}
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  inputProps={{ style: { minHeight: 80, maxHeight: 250, overflow: 'auto' } }}
                  label={t('description-of-module')}
                  name="description"
                  multiline
                />
                {isCampaign && (
                  <Autocomplete
                    sx={{ marginBottom: 3 }}
                    options={settings.namedGroups}
                    multiple
                    getOptionLabel={(o: NamedGroup) => t(o.name)}
                    value={values.groupTypeAccess ?? []}
                    onChange={(e, value: string[]) => setFieldValue('groupTypeAccess', value)}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autocomplete: 'chrome-off',
                          }}
                          variant="outlined"
                          placeholder="Select Groups"
                        />
                      );
                    }}
                  />
                )}
                {create && (
                  <LabeledSwitchBoxRounded
                    sx={{ mb: 3, mt: -1, borderWidth: 2 }}
                    checked={values.isParentModule}
                    infoText="By activating this function the module contains other modules instead of regular folders. This enables you to create a deeper layer of folders with dedicated user rights and permissions."
                    onChange={(checked) => setFieldValue('isParentModule', checked)}
                    label="Module contains other Modules"
                  />
                )}
                {!create && !isCampaign && (
                  <FormControl fullWidth>
                    <Select
                      labelId="select-input-label"
                      label="Parent Module"
                      sx={{ mb: 3, mt: -1 }}
                      fullWidth
                      value={values.parentModule ?? null}
                      variant="outlined"
                      onChange={(e) => setFieldValue('parentModule', e.target.value)}
                    >
                      {possibleParentModules
                        .filter((f) => f.id !== currentModule.id)
                        .map((m) => (
                          <MenuItem key={m.id} value={m.id}>
                            <Typography variant="subtitle1">
                              <FontAwesomeIcon style={{ marginRight: 6 }} icon={['fal', m.icon as IconName]} fixedWidth />
                              {m.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      <MenuItem value={null}>
                        <Typography variant="subtitle1">
                          <FontAwesomeIcon style={{ marginRight: 6 }} icon={faTrash} fixedWidth />
                          No Parent
                        </Typography>
                      </MenuItem>
                    </Select>
                    <InputLabel sx={{ mt: -1 }} id="select-input-label">
                      Parent Module
                    </InputLabel>
                  </FormControl>
                )}
                <Typography variant="overline" sx={{ color: theme.palette.grey[800] }}>
                  {t('visual-presentation')}
                </Typography>
                <UploadImage
                  image={values.downloadUrl}
                  setImage={(url, file) => {
                    setFieldValue('downloadUrl', url);
                    setFieldValue('imageFile', file);
                  }}
                  title={t('upload-thumbnail')}
                  subTitle={t('pic-for-easy-view-of-topic')}
                />
                <PictogramChooser icon={values.icon} onSelect={(icon) => setFieldValue('icon', icon)} gridColumnSpan={2} />
                <CardActions style={{ justifyContent: 'flex-end' }}>
                  <Button onClick={onClose} color="primary" variant="outlined">
                    {t('abort')}
                  </Button>
                  <CombinedConditionalRender campaign_admin parent={currentModule} move>
                    <LoadingButton onClick={() => setOpenDeletePopup(true)} color="error" variant="contained" loading={deletePending} endIcon={<FontAwesomeIcon icon={faTrash} />}>
                      {t('delete-module')}
                    </LoadingButton>
                  </CombinedConditionalRender>
                  <LoadingButton color="primary" onClick={() => handleSubmit()} endIcon={<FontAwesomeIcon icon={faSave} />} loading={isSubmitting} variant="contained">
                    {t(create ? 'create' : 'save')}
                  </LoadingButton>
                </CardActions>
              </CardContent>
            </Card>
          );
        }}
      </Formik>
    </StandardPopup>
  );
};
