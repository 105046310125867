import * as React from 'react';

function SvgH2(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.546 20" ref={svgRef} {...props}>
      <path d="M.619 20a.583.583 0 01-.45-.183.658.658 0 01-.169-.464V.985A.7.7 0 01.169.493.583.583 0 01.619.31h1.35a.665.665 0 01.492.183.665.665 0 01.183.492v7.82h9.789V.985a.665.665 0 01.183-.492.629.629 0 01.464-.183h1.35a.665.665 0 01.492.183.665.665 0 01.183.492v18.368a.606.606 0 01-.2.464.677.677 0 01-.478.183H13.08a.629.629 0 01-.464-.183.629.629 0 01-.183-.464v-8.1H2.644v8.1a.606.606 0 01-.2.464.677.677 0 01-.478.183zm18.959 0a.629.629 0 01-.464-.183.629.629 0 01-.183-.464v-.844a1.649 1.649 0 01.7-1.238l6.132-6.1a17.466 17.466 0 002.869-2.9 4.1 4.1 0 00.788-2.391 3.547 3.547 0 00-.928-2.6 3.625 3.625 0 00-2.7-.942 3.611 3.611 0 00-2.672.985 4.462 4.462 0 00-1.21 2.644.736.736 0 01-.281.478.748.748 0 01-.45.169h-1.35a.572.572 0 01-.422-.169.54.54 0 01-.169-.394 6.571 6.571 0 01.8-2.883A5.909 5.909 0 0122.246.89 6.718 6.718 0 0125.79.004a6.3 6.3 0 014.74 1.646 5.768 5.768 0 011.589 4.177 6.054 6.054 0 01-.858 3.193 13.035 13.035 0 01-2.686 3l-5.537 5.617h8.861a.647.647 0 01.478.169.647.647 0 01.169.478v1.069a.629.629 0 01-.186.464.629.629 0 01-.464.183z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgH2);
export default ForwardRef;
