import { API } from '@base/core';
import { useQuery } from 'react-query';

export function useGetChildren<T extends Core.VirtualFile[]>(parentId: string) {
  return useQuery(["files", parentId, "children"], async () => {
    const children = await API.files.getChildren(parentId);
    const fileCount = children.filter((f) => f.type != 'folder').length;
    const folderCount = children.length - fileCount;
    return {
      children: children as T, fileCount, folderCount
    };
  }, {
    refetchOnMount: false
  });
}
