import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { AccessRequestDoc, API } from '@base/core';

export function useGetAllPendingAccessRequests(queryOptions?: UseQueryOptions<AccessRequestDoc[], unknown, AccessRequestDoc[]>) {
  return useQuery(['pendingAccessRequests'], async () => {
    return await API.accessRequest.getAllPendingAccessRequests();
  });
}

export function useGetAllRejectedAccessRequests(queryOptions?: UseQueryOptions<AccessRequestDoc[], unknown, AccessRequestDoc[]>) {
  return useQuery(['rejectedAccessRequests'], async () => {
    return await API.accessRequest.getAllRejectedAccessRequests();
  });
}

export function useRejectAccessRequestMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (requestDoc: AccessRequestDoc & { message?: string }) => {
      return await API.accessRequest.denyAccessRequest(requestDoc, requestDoc.message);
    },
    {
      onSuccess: async (data, requestDoc, context) => {
        await queryClient.invalidateQueries(['pendingAccessRequests', requestDoc.id]);
        await queryClient.invalidateQueries(['rejectedAccessRequests', requestDoc.id]);
        queryClient.setQueryData(['pendingAccessRequests'], (old: AccessRequestDoc[]) => {
          if (!old) return old;
          let newData = { ...old };
          newData = old.filter((request) => request.id != requestDoc.id);
          return newData;
        });
      },
    },
  );
}

export function useVerifyAccessRequestMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (requestDoc: AccessRequestDoc) => {
      return await API.accessRequest.allowAccessRequest(requestDoc);
    },
    {
      onSuccess: async (data, requestDoc, context) => {
        await queryClient.invalidateQueries(['pendingAccessRequests', requestDoc.id]);
        await queryClient.invalidateQueries(['groups']);
        queryClient.setQueryData(['pendingAccessRequests'], (old: AccessRequestDoc[]) => {
          if (!old) return old;
          let newData = { ...old };
          newData = old.filter((request) => request.id != requestDoc.id);
          return newData;
        });
      },
    },
  );
}

//    allow read: if isSignedIn() && hasOneOfRights(["user_create"]) && hasOneOfRights(["user_delete"]) && hasOneOfRights(["group_create"]);
