import * as React from 'react';

function SvgTableRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.714 20.75" ref={svgRef} {...props}>
      <path
        d="M21.491 0H2.223A2.223 2.223 0 000 2.223v16.3a2.223 2.223 0 002.223 2.227h19.268a2.223 2.223 0 002.223-2.223v-16.3A2.223 2.223 0 0021.491 0zM10.746 18.527H2.5a.278.278 0 01-.278-.278v-6.021h8.522zm0-8.522H2.223v-6.3h8.522zm10.468 8.522h-8.245v-6.3h8.522v6.021a.278.278 0 01-.278.279zM21.491 10h-8.522V3.7h8.522z"
        fill="#6d6d6d"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTableRegular);
export default ForwardRef;
