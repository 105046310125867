import * as React from 'react';

function SvgH1(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.771 20" ref={svgRef} {...props}>
      <path d="M.629 20a.592.592 0 01-.457-.186.668.668 0 01-.171-.471V.686a.708.708 0 01.171-.5A.592.592 0 01.629 0H2a.676.676 0 01.5.186.676.676 0 01.186.5v7.943h9.943V.686a.676.676 0 01.186-.5.639.639 0 01.471-.186h1.374a.676.676 0 01.5.186.676.676 0 01.186.5v18.657a.615.615 0 01-.2.471.688.688 0 01-.486.186h-1.374a.639.639 0 01-.471-.186.639.639 0 01-.186-.471v-8.229H2.686v8.229a.615.615 0 01-.2.471A.688.688 0 012 20zm25.085 0a.592.592 0 01-.457-.186.668.668 0 01-.171-.471V3.229l-4.8 3.686a.648.648 0 01-.429.171.635.635 0 01-.486-.286l-.686-.886a.685.685 0 01-.143-.4.6.6 0 01.287-.514l6.2-4.8a1.354 1.354 0 01.743-.2h1.343a.639.639 0 01.471.186.639.639 0 01.186.471v18.686a.639.639 0 01-.186.471.639.639 0 01-.472.186z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgH1);
export default ForwardRef;
