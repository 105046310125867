import { API } from '@base/core';
import { faEdit, faEye, faLock, faLockOpen, faLowVision, faTrash, faUserCrown, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Grid,
  Grow,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Tab,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';
import { StandardPopup, usePopup, UserAvatarHandler } from 'libs/base/web/src/components/common';
import { useAutoUpdateState } from 'libs/editors/form-editor/src/lib/useAutoUpdateState';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { copyTextToClipboard } from '../../../helpers';
import { useSettings } from '../../../theme/ThemeProvider';
import { useUpdateFileMutation } from '../../../views/fileManager/hooks';
import { useGetGroups } from '../../../views/users/hooks/useGetGroups';
import { useGetUsers } from '../../../views/users/hooks/useGetUsers';
import { LoadingPromiseButton } from '../../LoadingPromiseButton';
import { calculateNewPermissions, extractUsersWithRolesFromPermissions, getRightsObjFromRole, Roles } from '../../users/ConfigurableRoleUser';

export type AccessManagementPopupProps = {
  open: boolean;
  setActive: (active: boolean) => void;
  subject?: string;
  message?: string;
  file: Core.VirtualFile;
  options?: Core.User[];
  openLinkFirst?: boolean;
  relativeLinkUrl?: string;
};

function unique(value: string[]) {
  const s = value.reduce((map, v) => ({ ...map, [v]: true }), {});
  return Object.keys(s);
}

const validator = string().email();
export const AccessManagementPopup = ({ open, setActive, message: m, subject: s, options = [], relativeLinkUrl, openLinkFirst, file }: AccessManagementPopupProps) => {
  const { t } = useTranslation();
  const notification = usePopup();
  const theme = useTheme();

  const [editMailState, setEditMailState] = useAutoUpdateState<boolean>(openLinkFirst);
  const [recipients, setRecipients] = useState([]);
  const [inviteMessage, setInviteMessage] = useState('');
  const [groupRecipients, setGroupRecipients] = useState([]);
  const { data: userData = [], isLoading } = useGetUsers();
  const { data: groupsData = [] } = useGetGroups();

  const updateMutation = useUpdateFileMutation();
  const settings = useSettings();
  const pushNofification = usePopup();

  const externalGroupTypes = settings.namedGroups.filter((g) => g.external).map((g) => g.name);
  const usersList = userData.filter((u) => !u.rights?.is_group_only_user);
  const groupsList = groupsData.filter((g) => !externalGroupTypes.includes(g.type));
  const [tabIndex, setTabIndex] = useState('users');

  const [messageRequired, setMessageRequired] = useState(true);

  const [userRight, setUserRight] = useState(Roles.Viewer);

  const accessType = file?.permissions?.visibility ?? 'private';

  const handleAccessTypeChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    try {
      await updateMutation.mutateAsync({
        fileId: file.id,
        update: {
          permissions: {
            groups: {},
            users: {},
            visibility: event.target.value == 'private' ? 'private' : 'public',
          },
        },
      });
      notification({
        title: t('saved'),
        type: 'success',
      });
    } catch (error) {
      notification({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    }
  };

  const handleRightChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserRight(event.target.value as string);
  };

  const link = window.location.origin + '/' + relativeLinkUrl.replace(/^\//, '');

  const copyLinkToClipboard = () => {
    void copyTextToClipboard(link);
    pushNofification({
      title: t('link-copied-to-clipboard'),
      type: 'info',
      blocking: false,
      time: 2000,
      id: new Date().getUTCMilliseconds(),
    });
  };
  const [currentUsers, setCurrentUsers] = useAutoUpdateState(file?.permissions?.users ? extractUsersWithRolesFromPermissions(file.permissions.users) : [], [file]);
  const [currentGroups, setCurrentGroups] = useAutoUpdateState(file?.permissions?.groups ? extractUsersWithRolesFromPermissions(file.permissions.groups) : [], [file]);

  const usersWithAccess = useMemo(
    () =>
      currentUsers.map(({ uid, role }) => {
        const user = usersList.find((u) => u.id == uid);
        if (user)
          return {
            user,
            uid,
            role,
          };
        return { uid, role };
      }),
    [usersList, currentUsers],
  );
  const groupsWithAccess = useMemo(
    () =>
      currentGroups.map(({ uid, role }) => {
        const group = groupsList.find((g) => g.id == uid);
        if (group)
          return {
            group,
            uid,
            role,
          };
        return { uid, role };
      }),
    [groupsList, currentGroups],
  );

  const handleUserRoleChanged = async (id: string, previouseRole: string, currentRole: string, useGroup = false) => {
    const prev = getRightsObjFromRole(previouseRole) ?? {};
    const curr = getRightsObjFromRole(currentRole) ?? {};

    const newPermissions = {
      read: {} as { remove: string[]; add: string[] },
      write: {} as { remove: string[]; add: string[] },
      move: {} as { remove: string[]; add: string[] },
      permission: {} as { remove: string[]; add: string[] },
    };
    prev.editPermissions && (newPermissions.permission.remove = [id]);
    prev.move && (newPermissions.move.remove = [id]);
    prev.write && (newPermissions.write.remove = [id]);
    prev.read && (newPermissions.read.remove = [id]);
    curr.editPermissions && (newPermissions.permission.add = [id]);
    curr.move && (newPermissions.move.add = [id]);
    curr.write && (newPermissions.write.add = [id]);
    curr.read && (newPermissions.read.add = [id]);

    try {
      await updateMutation.mutateAsync({
        fileId: file.id,
        update: {
          permissions: {
            users: useGroup ? {} : newPermissions,
            groups: useGroup ? newPermissions : {},
          },
        },
      });
    } catch (error) {
      notification({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    }
  };

  const handleUpdateUserRoles = async (entitiesToAdd: { uid: string; role: string }[], useGroup = false) => {
    const newUserRights = calculateNewPermissions(file.permissions.users, useGroup ? [] : entitiesToAdd);
    const newGroupRights = calculateNewPermissions(file.permissions.groups, useGroup ? entitiesToAdd : []);
    try {
      const newUserPermissions = {
        read: { set: newUserRights.read },
        write: { set: newUserRights.write },
        move: { set: newUserRights.move },
        permission: { set: newUserRights.permission },
      };
      const newGroupPermissions = {
        read: { set: newGroupRights.read },
        write: { set: newGroupRights.write },
        move: { set: newGroupRights.move },
        permission: { set: newGroupRights.permission },
      };
      await updateMutation.mutateAsync({
        fileId: file.id,
        update: {
          permissions: {
            users: newUserPermissions,
            groups: newGroupPermissions,
          },
        },
      });
      setRecipients([]);
      setGroupRecipients([]);

      if (messageRequired) {
        const emailEntities: any = {};
        if (useGroup) {
          emailEntities.groupIds = entitiesToAdd.map((e) => e.uid);
        } else {
          emailEntities.uids = entitiesToAdd.map((e) => e.uid);
        }
        const inviteText = inviteMessage.length > 0 ? `<p><b>Message:</b> ${inviteMessage}</p>` : '';
        try {
          await API.email.sendNotificationEmail(emailEntities, 'IBU-Scope: Access to File-Module Granted', {
            autosend: true,
            buttonLink: window.location.origin + '/files/' + file.id,
            buttonText: file.type === 'folder' ? 'Go to directory' : 'Open file',
            emailTitle: `${file.name} Access granted`,
            emailText: `
            <p>Dear IBU Scope User,</p></br>
            <p>You got acccess to ${file.type === 'folder' ? 'the directory' : 'the file'} ${file.name}.</p>
            ${inviteText}
            </br>
            <p>Open the link <a target="_blank" href="${window.location.origin + '/files/' + file.id}">ibu-scope.com</a> or click the button below.</p></br>
            </br>
            `,
            belowButtonText: `
            <p>Best regards,</p>
            </br>
            <p>your IBU Scope Team</p>
            `,
          });
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      notification({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    }
  };

  function onClose() {
    setActive(false);
    setInviteMessage('');
  }

  return (
    <StandardPopup PaperComponent={Box} visible={open} onBackdropClick={onClose} width={700}>
      <Card>
        <Accordion sx={{ padding: 1 }} expanded={!editMailState} onChange={() => setEditMailState(false)}>
          <AccordionSummary>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography variant="h3" color="primary">
                <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: 10 }} />
                {t('entitled-users-groups')}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: 0 }}>
            <TabContext value={tabIndex}>
              <Box paddingX={0} paddingBottom={0} paddingTop={0} sx={{ marginBottom: 2, borderBottom: `2px solid ${theme.palette.background.gray}` }}>
                <TabList style={{ height: 40, minHeight: 40 }} onChange={(_, v) => setTabIndex(v)} TabIndicatorProps={{ style: { background: theme.palette.primary.main } }} textColor="#000000">
                  <Tab sx={{ paddingX: 2 }} label="Users" value="users" />
                  <Tab sx={{ paddingX: 2 }} label="Departments" value="groups" />
                </TabList>
              </Box>
              {tabIndex === 'users' && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex' }}>
                      <Autocomplete
                        multiple
                        options={usersList}
                        value={recipients}
                        fullWidth
                        getOptionLabel={(option) => option.displayName}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ fontSize: 15, '& > span': { mr: '10px', fontSize: 18 } }} {...props}>
                            <UserAvatarHandler sx={{ height: 36, width: 36, marginRight: 1 }} disableTooltip key={option.id} userName={option.displayName} userPhotoUrl={option.photoURL} />
                            <Box>
                              <Typography variant="subtitle2" fontWeight="600">
                                {option.displayName}
                              </Typography>
                              <Typography variant="subtitle2" style={{ marginTop: 0 }}>
                                {option.email}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        onChange={(e, v, d) => {
                          setRecipients(v);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            return (
                              <Tooltip placement="top" title={option.email ?? option}>
                                <Chip variant="outlined" label={option.displayName} {...getTagProps({ index })} />
                              </Tooltip>
                            );
                          })
                        }
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autocomplete: 'chrome-off',
                              }}
                              variant="outlined"
                              placeholder={t('add-user')}
                            />
                          );
                        }}
                      />
                      {recipients.length !== 0 && (
                        <Select sx={{ marginLeft: 1, minWidth: 140, height: 56 }} variant="outlined" value={userRight} onChange={handleRightChange} autoWidth>
                          <MenuItem value={Roles.Viewer}>
                            <Typography variant="subtitle1">
                              <FontAwesomeIcon style={{ marginRight: 6 }} icon={faEye} fixedWidth />
                              Viewer
                            </Typography>
                          </MenuItem>
                          <MenuItem value={Roles.ContentManager}>
                            <Typography variant="subtitle1">
                              <FontAwesomeIcon style={{ marginRight: 6 }} icon={faEdit} fixedWidth />
                              Editor
                            </Typography>
                          </MenuItem>
                          <MenuItem value={Roles.Admin}>
                            <Typography variant="subtitle1">
                              <FontAwesomeIcon style={{ marginRight: 6 }} icon={faUserCrown} fixedWidth />
                              Admin
                            </Typography>
                          </MenuItem>
                        </Select>
                      )}
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Collapse in={recipients.length === 0}>
                        {usersWithAccess
                          .sort((a, b) => a.uid.localeCompare(b.uid))
                          .map((userWithRoles, i) => (
                            <Stack flexDirection="row" justifyContent="space-between" key={i}>
                              <Stack flexDirection="row" alignItems="center" marginBottom={1}>
                                <UserAvatarHandler
                                  sx={{ height: 40, width: 40, marginRight: 1 }}
                                  disableTooltip
                                  userName={userWithRoles.user?.displayName}
                                  userPhotoUrl={userWithRoles.user?.photoURL}
                                />
                                <Box>
                                  <Typography variant="subtitle1" fontWeight="600">
                                    {userWithRoles.user?.displayName}
                                  </Typography>
                                  <Typography variant="subtitle1" style={{ marginTop: 0 }}>
                                    {userWithRoles.user?.email}
                                  </Typography>
                                </Box>
                              </Stack>
                              <Select
                                sx={{ marginLeft: 1, minWidth: 120 }}
                                value={userWithRoles.role}
                                onChange={(e) => {
                                  setCurrentUsers((c) =>
                                    c.map((user) => {
                                      if (user.uid === userWithRoles.uid) {
                                        void handleUserRoleChanged(userWithRoles.uid, userWithRoles.role, e.target.value);
                                        return { ...userWithRoles, role: e.target.value };
                                      }
                                      return user;
                                    }),
                                  );
                                }}
                                autoWidth
                              >
                                <MenuItem value={Roles.Viewer}>
                                  <Typography variant="subtitle1">
                                    <FontAwesomeIcon style={{ marginRight: 6 }} icon={faEye} fixedWidth />
                                    Viewer
                                  </Typography>
                                </MenuItem>
                                <MenuItem value={Roles.ContentManager}>
                                  <Typography variant="subtitle1">
                                    <FontAwesomeIcon style={{ marginRight: 6 }} icon={faEdit} fixedWidth />
                                    Editor
                                  </Typography>
                                </MenuItem>
                                <MenuItem value={Roles.Admin}>
                                  <Typography variant="subtitle1">
                                    <FontAwesomeIcon style={{ marginRight: 6 }} icon={faUserCrown} fixedWidth />
                                    Admin
                                  </Typography>
                                </MenuItem>
                                <MenuItem value={'remove'}>
                                  <Typography variant="subtitle1">
                                    <FontAwesomeIcon style={{ marginRight: 6 }} icon={faTrash} fixedWidth />
                                    Remove
                                  </Typography>
                                </MenuItem>
                              </Select>
                            </Stack>
                          ))}
                      </Collapse>
                      <Collapse in={recipients.length !== 0 || groupRecipients.length !== 0}>
                        <Box>
                          <Paper variant="outlined">
                            <Stack sx={{ paddingLeft: 2, paddingRight: 1 }} direction="row" alignItems="center" justifyContent="space-between">
                              <Typography variant="subtitle1" fontWeight="600">
                                {t('notify-people-message')}
                              </Typography>
                              <Switch name="budgetRequired" checked={messageRequired} onChange={(e) => setMessageRequired(e.target.checked)} />
                            </Stack>
                          </Paper>
                          <Collapse in={messageRequired}>
                            <TextField sx={{ mt: 2 }} rows={5} label={t('message')} variant="outlined" multiline fullWidth value={inviteMessage} onChange={(e) => setInviteMessage(e.target.value)} />
                          </Collapse>
                        </Box>
                      </Collapse>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {tabIndex === 'groups' && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex' }}>
                      <Autocomplete
                        multiple
                        options={groupsList}
                        value={groupRecipients}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        onChange={(e, v, d) => {
                          setGroupRecipients(v);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            return <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />;
                          })
                        }
                        renderInput={(params) => {
                          return <TextField {...params} autoComplete="chrome-off" inputProps={{ ...params.inputProps, autoComplete: 'chrome-off' }} variant="outlined" placeholder={t('add-group')} />;
                        }}
                      />
                      {groupRecipients.length !== 0 && (
                        <Select sx={{ marginLeft: 1, minWidth: 140, height: 56 }} variant="outlined" value={userRight} onChange={handleRightChange} autoWidth>
                          <MenuItem value={Roles.Viewer}>
                            <Typography variant="subtitle1">
                              <FontAwesomeIcon style={{ marginRight: 6 }} icon={faEye} fixedWidth />
                              Viewer
                            </Typography>
                          </MenuItem>
                          <MenuItem value={Roles.ContentManager}>
                            <Typography variant="subtitle1">
                              <FontAwesomeIcon style={{ marginRight: 6 }} icon={faEdit} fixedWidth />
                              Editor
                            </Typography>
                          </MenuItem>
                          <MenuItem value={Roles.Admin}>
                            <Typography variant="subtitle1">
                              <FontAwesomeIcon style={{ marginRight: 6 }} icon={faUserCrown} fixedWidth />
                              Admin
                            </Typography>
                          </MenuItem>
                        </Select>
                      )}
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Collapse in={recipients.length === 0}>
                        {groupsWithAccess
                          .sort((a, b) => a.uid.localeCompare(b.uid))
                          .map((groupWithRoles, i) => (
                            <Stack flexDirection="row" justifyContent="space-between" key={i}>
                              <Stack flexDirection="row" alignItems="center" marginBottom={1}>
                                <UserAvatarHandler sx={{ height: 40, width: 40, marginRight: 1 }} disableTooltip userName={groupWithRoles.group?.name} />
                                <Box>
                                  <Typography variant="subtitle1" fontWeight="600">
                                    {groupWithRoles.group?.name}
                                  </Typography>
                                </Box>
                              </Stack>
                              <Select
                                sx={{ marginLeft: 1, minWidth: 120 }}
                                value={groupWithRoles.role}
                                onChange={(e) => {
                                  setCurrentGroups((c) =>
                                    c.map((group) => {
                                      if (group.uid === groupWithRoles.uid) {
                                        void handleUserRoleChanged(groupWithRoles.uid, groupWithRoles.role, e.target.value, true);
                                        return { ...groupWithRoles, role: e.target.value };
                                      }
                                      return group;
                                    }),
                                  );
                                }}
                                autoWidth
                              >
                                <MenuItem value={Roles.Viewer}>
                                  <Typography variant="subtitle1">
                                    <FontAwesomeIcon style={{ marginRight: 6 }} icon={faEye} fixedWidth />
                                    Viewer
                                  </Typography>
                                </MenuItem>
                                <MenuItem value={Roles.ContentManager}>
                                  <Typography variant="subtitle1">
                                    <FontAwesomeIcon style={{ marginRight: 6 }} icon={faEdit} fixedWidth />
                                    Editor
                                  </Typography>
                                </MenuItem>
                                <MenuItem value={Roles.Admin}>
                                  <Typography variant="subtitle1">
                                    <FontAwesomeIcon style={{ marginRight: 6 }} icon={faUserCrown} fixedWidth />
                                    Admin
                                  </Typography>
                                </MenuItem>
                                <MenuItem value={'remove'}>
                                  <Typography variant="subtitle1">
                                    <FontAwesomeIcon style={{ marginRight: 6 }} icon={faTrash} fixedWidth />
                                    Remove
                                  </Typography>
                                </MenuItem>
                              </Select>
                            </Stack>
                          ))}
                      </Collapse>
                      <Collapse in={recipients.length !== 0 || groupRecipients.length !== 0}>
                        <Box>
                          <Paper variant="outlined">
                            <Stack sx={{ paddingLeft: 2, paddingRight: 1 }} direction="row" alignItems="center" justifyContent="space-between">
                              <Typography variant="subtitle1" fontWeight="600">
                                {t('notify-people-message')}
                              </Typography>
                              <Switch name="budgetRequired" checked={messageRequired} onChange={(e) => setMessageRequired(e.target.checked)} />
                            </Stack>
                          </Paper>
                          <Collapse in={messageRequired}>
                            <TextField sx={{ mt: 2 }} rows={5} label={t('message')} variant="outlined" multiline fullWidth value={inviteMessage} onChange={(e) => setInviteMessage(e.target.value)} />
                          </Collapse>
                        </Box>
                      </Collapse>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack style={{ marginTop: 20 }} spacing={1} direction="row" justifyContent="flex-end">
                  {recipients.length === 0 && groupRecipients.length === 0 && (
                    <Button variant="contained" color="primary" onClick={onClose}>
                      {t('done')}
                    </Button>
                  )}
                  {recipients.length !== 0 && (
                    <LoadingPromiseButton variant="contained" color="primary" onClick={() => handleUpdateUserRoles(recipients.map((r) => ({ uid: r.id, role: userRight })))}>
                      {t('add-users')}
                    </LoadingPromiseButton>
                  )}
                  {groupRecipients.length !== 0 && (
                    <LoadingPromiseButton
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleUpdateUserRoles(
                          groupRecipients.map((r) => ({ uid: r.id, role: userRight })),
                          true,
                        )
                      }
                    >
                      {t('add-groups')}
                    </LoadingPromiseButton>
                  )}
                </Stack>
              </Grid>
            </TabContext>
          </AccordionDetails>
        </Accordion>
      </Card>
      <Grow in={recipients.length === 0 && groupRecipients.length === 0}>
        <Card sx={{ marginTop: 2 }}>
          <Accordion sx={{ padding: 1 }} expanded={editMailState} onChange={() => setEditMailState(true)}>
            <AccordionSummary>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Typography variant="h3" color="primary">
                  <FontAwesomeIcon icon={faLowVision} style={{ marginRight: 10 }} />
                  {t('link-and-visibility')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0 }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 2 }}>
                <Typography sx={{ border: `2px solid ${theme.palette.background.gray}`, paddingX: 2, paddingY: 1, borderRadius: 1, flex: 1, marginRight: 1 }} variant="subtitle1">
                  {link}
                </Typography>
                <Button variant="outlined" onClick={() => copyLinkToClipboard()} color="primary" sx={{ whiteSpace: 'nowrap' }}>
                  {t('copy-link')}
                </Button>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 2, padding: 1, borderRadius: 3, border: `2px solid ${theme.palette.background.gray}`, alignItems: 'center' }}>
                <Box
                  sx={{
                    borderRadius: 100,
                    backgroundColor: theme.palette.info.lighter,
                    color: theme.palette.info.main,
                    padding: 1,
                    width: 24,
                    height: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon style={{ width: 20, height: 20 }} icon={accessType === 'private' ? faLock : faLockOpen} />
                </Box>
                <Box sx={{ marginLeft: 2 }}>
                  <Select value={accessType} onChange={handleAccessTypeChange} autoWidth inputProps={{ sx: { fontSize: 14 } }}>
                    <MenuItem value={'private'}>
                      <Typography variant="subtitle1">{t('private')}</Typography>
                    </MenuItem>
                    <MenuItem value={'public'}>
                      <Typography variant="subtitle1">{t('public')}</Typography>
                    </MenuItem>
                    {/* <MenuItem value={'untitled'}>
                      <Typography variant="subtitle1">{t('untitled')}</Typography>
                    </MenuItem> */}
                  </Select>
                  <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 1 }}>
                    {accessType === 'private' ? t('access-private-describition') : /* accessType === 'untitled' ? t('access-untitled-decribtion') :  */ t('access-public-describtion')}
                  </Typography>
                </Box>
              </Box>
              <Grid item xs={12}>
                <Stack style={{ marginTop: 20 }} spacing={1} direction="row" justifyContent="flex-end">
                  <Button variant="contained" color="primary" onClick={onClose}>
                    {t('done')}
                  </Button>
                </Stack>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Grow>
    </StandardPopup>
  );
};
