import * as React from 'react';

function SvgFilePdfLight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.801 62.413" ref={svgRef} {...props}>
      <path d="M45.082 11.944L34.857 1.718A5.85 5.85 0 0030.725 0H5.85A5.87 5.87 0 000 5.862v50.7a5.852 5.852 0 005.85 5.85h35.1a5.852 5.852 0 005.85-5.85V16.088a5.879 5.879 0 00-1.718-4.144zm-2.754 2.767a1.917 1.917 0 01.512.9H31.2V3.973a1.917 1.917 0 01.9.512zm-1.377 43.8H5.85a1.956 1.956 0 01-1.95-1.95V5.862a1.956 1.956 0 011.95-1.95H27.3v12.675a2.918 2.918 0 002.925 2.925H42.9v37.051a1.956 1.956 0 01-1.949 1.95zm-2.682-20.863c-1.645-1.621-6.7-1.121-8.982-.817a12.757 12.757 0 01-5.5-6.9c.524-2.194 1.463-5.753.78-7.91-.536-3.425-4.839-3.01-5.436-.829-.609 2.23-.037 5.411 1.024 9.482a117.335 117.335 0 01-5.131 10.8c-2.535 1.3-6.594 3.571-7.166 6.386-.427 2.048 2.791 4.8 6.472.78a32.611 32.611 0 003.817-5.54 70.851 70.851 0 019.994-2.925 18.594 18.594 0 007.874 2.425c3.376.012 3.522-3.681 2.255-4.948zM10.335 48.495c.719-1.938 3.486-4.193 4.327-4.973-2.694 4.302-4.327 5.058-4.327 4.973zM21.938 21.4c1.06 0 .951 4.57.256 5.8-.634-1.984-.61-5.8-.256-5.8zm-3.461 19.417a57.167 57.167 0 003.51-7.764 15.877 15.877 0 004.278 5.058 44.551 44.551 0 00-7.788 2.706zm18.695-.717s-.707.853-5.3-1.109c4.996-.368 5.812.777 5.3 1.109z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFilePdfLight);
export default ForwardRef;
