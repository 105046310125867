import { IUserService, Observer, Query, UserCreationData } from '@base/core';
import { GROUP_COLLECTION, USER_COLLECTION } from '../config';

import { firebase } from '../config';
import type * as admin from 'firebase-admin';
import { FirebaseQuery } from './FirebaseQuery';
import { createCallableApi } from './createCallableApi';
import { FileService } from './FileService';

export class UserService implements IUserService {
  async impersonateUser(uid: string): Promise<void> {
    const {
      data: { token },
    } = await createCallableApi<{ token: string }>(`/user/${uid}/impersonate`)();

    await firebase.auth().signInWithCustomToken(token);
  }

  private getUserStorageCollection(child: string) {
    return firebase.storage().ref('users/profile_images/' + child);
  }

  async getUserById(uid: string, observer?: Observer<Core.User>): Promise<Core.User & admin.auth.UserRecord> {
    const response = await createCallableApi<{ user: admin.auth.UserRecord }>(`/user/${uid}`)();
    const userRecordData = response.data.user;
    const userSN = await firebase.firestore().collection(USER_COLLECTION).doc(uid).get();
    if (observer) {
      firebase
        .firestore()
        .collection(USER_COLLECTION)
        .doc(uid)
        .onSnapshot({
          next:
            observer.next &&
            (async (snapshot) => {
              const user = (await this.transformItem(snapshot)) ?? undefined;
              observer.next(user);
            }),
          error: observer.error,
          complete: observer.complete,
        });
    }
    const user = await this.transformItem(userSN);
    const u = {
      ...user,
      groups: userRecordData.customClaims?.groups?.reduce((map, g) => ({ ...map, [g]: true }), {}) ?? {},
      rights: userRecordData.customClaims?.rights ?? {},
      roles: userRecordData.customClaims?.roles ?? {},
      ...userRecordData,
    } as Core.User & admin.auth.UserRecord;
    console.log('user record data');
    console.log(u);
    return u;
  }

  async deleteUser(uid: string): Promise<void> {
    await createCallableApi(`/user/${uid}`, 'DELETE')();
    await firebase.firestore().doc(`${USER_COLLECTION}/${uid}`).delete();
  }

  async createUser(userData: UserCreationData): Promise<Core.User> {
    const { phoneNumber, ...saveFBData } = userData;
    const res = await createCallableApi<{ user: admin.auth.UserRecord }>('/user', 'POST')(saveFBData);
    const { password, emailVerified, disabled, email, ...saveData } = userData;
    await firebase.firestore().collection(USER_COLLECTION).doc(res.data.user.uid).update(saveData);
    return ({
      ...res.data,
      id: res.data.user.uid,
      groups: {},
      rights: {},
      roles: {},
    } as any) as Core.User; //FIXME
  }

  async createUserQuery(): Promise<Query<Core.User>> {
    const q = firebase.firestore().collection(USER_COLLECTION);
    return new FirebaseQuery<Core.User>(q, async (d) => await this.transformItem(d));
  }

  private async transformItem(d): Promise<Core.User> {
    const user = {
      ...(d.data() as Core.User),
      id: d.id,
    };
    const url = d.data().imageUrl;
    if (url) {
      user.photoURL = firebase.storage().ref(url).getDownloadURL();
    }
    return user;
  }

  async updateUserGroups(uid: string, groups: { add: string[]; remove: string[] }): Promise<void> {
    await createCallableApi<admin.auth.UserRecord>(`/user/${uid}/groups`, 'PATCH')(groups);
  }

  async setUserRoles(uid: string, roles: Core.Roles): Promise<void> {
    await createCallableApi<admin.auth.UserRecord>(`/user/${uid}/roles`, 'POST')({ roles });
    await this.updateUser(uid, { roles } as any);
  }

  async setUserRights(uid: string, rights: Core.Rights): Promise<void> {
    await createCallableApi<admin.auth.UserRecord>(`/user/${uid}/rights`, 'POST')({ rights });
  }

  async updateUser(uid: string, userData: Partial<Omit<Core.User, 'groups' | 'rights' | 'roles' | 'id' | 'photoUrl'> & { password: string }>): Promise<void> {
    const { phoneNumber, ...saveFBData } = userData;

    const { password, newPassword, emailVerified, disabled, email, ...saveData } = userData as any;
    await firebase.firestore().collection(USER_COLLECTION).doc(uid).update(saveData);
    await createCallableApi<admin.auth.UserRecord>(`/user/${uid}`, 'PATCH')(saveFBData);
  }

  async setProfileImage(uid: string, data: Uint8Array | Blob | ArrayBuffer) {
    const fileRef = this.getUserStorageCollection(uid);
    await this.updateUser(uid, {
      //@ts-ignore
      imageUrl: fileRef.fullPath,
    });
    await fileRef.put(data);
    return await fileRef.getDownloadURL();
  }

  async setExternalUserRights(uid: string, rights: Core.GroupRights): Promise<void> {
    await createCallableApi<admin.auth.UserRecord>(`/user/external-user/${uid}/rights`, 'POST')({ rights });
  }

  async updateExternalUser(uid: string, userData: Partial<Omit<Core.User, 'groups' | 'rights' | 'roles' | 'id' | 'photoUrl'> & { password: string }>): Promise<void> {
    const { phoneNumber, ...saveFBData } = userData;

    await createCallableApi<admin.auth.UserRecord>(`/user/external-user/${uid}`, 'PATCH')(saveFBData);
    const { password, newPassword, emailVerified, disabled, email, ...saveData } = userData as any;
    await firebase.firestore().collection(USER_COLLECTION).doc(uid).update(saveData);
  }

  async deleteExternalUser(uid: string): Promise<void> {
    await createCallableApi(`/user/external-user/${uid}`, 'DELETE')();
    await firebase.firestore().doc(`${USER_COLLECTION}/${uid}`).delete();
  }

  async createExternalUser(userData: UserCreationData, group: string): Promise<Core.User> {
    const { phoneNumber, ...saveFBData } = userData;
    const res = await createCallableApi<{ user: admin.auth.UserRecord }>('/user/external-user/', 'POST')({ ...saveFBData, group });
    const { password, emailVerified, disabled, email, ...saveData } = userData;
    await firebase.firestore().collection(USER_COLLECTION).doc(res.data.user.uid).update(saveData);
    return ({
      ...res.data,
      id: res.data.user.uid,
      groups: {},
      rights: {},
      roles: {},
    } as any) as Core.User;
  }
}
