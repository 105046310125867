import { Group, GroupTag } from '@base/core';
import { faLayerPlus, faTrash, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvatarGroup, Box, Button, Stack, Typography, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { XGrid } from '@material-ui/x-grid';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalRender, ContentHeader, UserAvatarHandler } from '../../../components/common';
import { LoadingPromiseButton } from '../../../components/LoadingPromiseButton';
import { useSettings } from '../../../theme/ThemeProvider';
import { SelectGroupsPopupAdvances } from '../../users/popups/SelectGroupsPopupAdvances';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    lastCell: {
      minWidth: '700px !important',
      position: 'absolute',
      left: 0,
      zIndex: 10,
      opacity: 0,
      // right: 20,
      transform: 'translateX(calc(100vw - 100% - 310px))',
    },
    root: {
      borderRadius: 0,
      flex: 1,
      height: 'auto',
      '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
      '& .MuiDataGrid-row': {
        position: 'relative',
        minWidth: '100%',
        '&:hover': {
          '& $lastCell': {
            opacity: 1,
          },
        },
      },
      '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
    },
  }),
);

interface GroupsPanelContentProps {
  groups: Group[];
  groupTags: GroupTag[];
  selectedGroups: string[];
  setGroups(update: { added: string[]; removed: string[]; value: string[] }): void | Promise<void>;
}

export function GroupsPanelContent({ groups, selectedGroups = [], setGroups, groupTags }: GroupsPanelContentProps) {
  const theme = useTheme();
  const settings = useSettings();
  const classes = useStyles(theme);
  const iconMap = settings.namedGroups.reduce((map, v) => ({ ...map, [v.name]: v.icon }), {});
  const [selection, setSelection] = useState([]);
  const { t } = useTranslation();
  const [editGroupsOpen, setEditGroupsOpen] = useState(false);
  const columns = [
    {
      field: 'id',
      width: 50,
      renderCell: ({ formattedValue, value, row }) => {
        return <FontAwesomeIcon icon={iconMap[row.type] ?? faUsers} />;
      },
    },
    {
      field: 'name',
      width: 275,
      headerName: 'Groupname',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'users',
      width: 300,
      headerName: 'Involved users',
      type: 'date',
      disableColumnMenu: true,
      renderCell: ({ formattedValue, value }) => (
        <AvatarGroup max={8} spacing="medium">
          {((value as any) ?? []).map((user: Core.User) => (
            <UserAvatarHandler key={user.id} userName={user.displayName} userPhotoUrl={user.photoURL} />
          ))}
        </AvatarGroup>
      ),
    },
  ];

  const groupsMap: { [key: string]: Group } = useMemo(() => groups.reduce((map, g) => ({ ...map, [g.id]: g }), {}), [groups]);
  return (
    <>
      <SelectGroupsPopupAdvances
        open={editGroupsOpen}
        groupTags={groupTags}
        onClose={() => setEditGroupsOpen(false)}
        selectedGroups={selectedGroups}
        groups={groups}
        onSave={(added, removed, value) => setGroups({ added, removed, value })}
      />
      <ContentHeader title="Assigned Groups" subtitle={selectedGroups.length + ' ' + 'Groups'}>
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1} />
          <ConditionalRender render={selection.length > 0}>
            <LoadingPromiseButton
              sx={{ borderRadius: 999 }}
              onClick={async () => {
                await setGroups({ added: [], removed: selection, value: selectedGroups.filter((gid) => !selection.some((id) => id === gid)) });
                setSelection([]);
              }}
              variant="contained"
              color="error"
              endIcon={<FontAwesomeIcon icon={faTrash} />}
            >
              {t('remove-groups')}
            </LoadingPromiseButton>
          </ConditionalRender>
          <Button
            sx={{ color: 'primary.main', bgcolor: 'background.paper', ':hover': { bgcolor: 'action.hover' }, borderRadius: 999 }}
            onClick={() => {
              setEditGroupsOpen(true);
            }}
            variant="contained"
            color="primary"
            endIcon={<FontAwesomeIcon icon={faLayerPlus} />}
          >
            {t('add-groups')}
          </Button>
        </Stack>
      </ContentHeader>
      <XGrid
        className={classes.root}
        headerHeight={40}
        // loading={isLoading}
        // disableColumnSelector
        sortModel={[{ field: 'name', sort: 'asc' }]}
        selectionModel={selection}
        // autoHeight
        checkboxSelection
        onSelectionModelChange={({ selectionModel }) => {
          setSelection(selectionModel);
        }}
        // disableColumnMenu
        columns={Object.entries(columns)
          .map(([key, value]) => ({ field: key, ...value }))
          .map((c) => ({
            ...c,
            renderHeader: (params) => {
              return (
                <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                  {params.colDef.headerName}
                </Typography>
              );
            },
          }))}
        rows={selectedGroups.map((id) => groupsMap[id]).filter((d) => d)}
        onRowDoubleClick={(params) => {}}
      />
    </>
  );
}
