import { faInfo } from '@fortawesome/free-solid-svg-icons';
import type { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type smallInfoCircleProps = {
  onClick: () => void;
  bgColor?: string;
  iconColor?: string;
  icon?: IconDefinition;
  left?: boolean;
  margin?: number;
  iconSize?: number;
};

export const SmallInfoCircle = ({ icon = faInfo, iconColor = 'white', bgColor = '#8d8d8d', onClick, left, margin = 10, iconSize = 14 }: smallInfoCircleProps) => (
  <div
    onClick={onClick}
    className="info-button"
    style={{ backgroundColor: bgColor, left: left ? margin : 'auto', right: left ? 'auto' : margin, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    <FontAwesomeIcon icon={icon} style={{ color: iconColor, fontSize: iconSize }} />
  </div>
);
