import { Actions } from '@base/core';
import { faSignIn } from '@fortawesome/pro-light-svg-icons';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { AuthContainer, AuthSlide, AuthSlider, LeftContainer, RightContainer } from '../../components/auth';
import { usePopup } from '../../components/common';
import { TextInputIcon } from '../../components/inputs';
import '../../css/Views/auth/login.scss';
import { useTheme } from '../../theme/ThemeProvider';

export const ResetPassword = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const notification = usePopup();
  const history = useHistory();

  const { success, error } = useSelector((state: Core.StateType) => state.auth.fetching.resetPassword);
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();

  const resetPassword = () => dispatch(Actions().auth.resetPassword(email));

  useEffect(() => {
    if (error) {
      notification({ type: 'error', title: t('email-not-found') });
    } else if (success) {
      notification({ type: 'success', title: t('password-reset-sucess') });
      history.push('/login');
    }
  }, [error, success]);

  return (
    <div className="login">
      <AuthContainer>
        <LeftContainer title={t('reset-password')} icon={faSignIn} customerLogo={theme.images.logo} productName={t('product-name')}>
          <div className="form-block w-form">
            <form
              onSubmit={(e) => {
                resetPassword();
                e.preventDefault();
              }}
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="login-form"
            >
              <TextInputIcon placeholder={t('email')} value={email} onChange={setEmail} icon={faEnvelope} />
              <Typography textAlign="center" variant="subtitle2" sx={{ mt: -0.5, mb: 2 }}>
                {t('reset-password-text')}
              </Typography>
              <Button fullWidth variant="contained" type="submit" value={t('reset') as string} data-wait={t('please-wait')}>
                {t('reset')}
              </Button>
            </form>
            <Button sx={{ mt: 1 }} fullWidth variant="outlined" href="/">
              {t('cancel')}
            </Button>
          </div>
        </LeftContainer>
        <RightContainer>
          <AuthSlider>
            {theme.slides.loginSlides.map((slide) => (
              <AuthSlide title={slide.title} description={slide.description} illustration={slide.illustration} />
            ))}
          </AuthSlider>
        </RightContainer>
      </AuthContainer>
    </div>
  );
};
