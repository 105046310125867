import type { DocumentData } from '@google-cloud/firestore';
import { Query } from "@base/core";
import {firebase} from '../config';

export class FirebaseQuery<T> implements Query<T> {
  constructor(
    private query: firebase.default.firestore.Query | firebase.default.firestore.CollectionReference,
    private documentTransformer:
      (value: firebase.default.firestore.QueryDocumentSnapshot<DocumentData>, index: number, array: firebase.default.firestore.QueryDocumentSnapshot<DocumentData>[]) => T|Promise<T>
  ) { }

  lastDocument: firebase.default.firestore.DocumentSnapshot<any> | undefined;

  end: boolean = false;
  async getNextPage(limit: number): Promise<T[]> {
    let q = this.query;
    if (this.lastDocument) q = q.startAfter(this.lastDocument);

    const res = await q.limit(limit).get();

    if (res.empty) this.end = true;

    this.lastDocument = res.docs[res.docs.length - 1];
    return Promise.all(res.docs.map(this.documentTransformer));
  }
}