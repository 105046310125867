import { ISearch, QueryType, SearchResult } from "@base/core"
import { createCallableApi } from "./createCallableApi"


export class SearchService implements ISearch {
  async search({ query,limit,mimetype,offset, parent}: QueryType): Promise<SearchResult> {
    let searchParts = [];

    if(query != undefined) searchParts.push(`q=${query}`)
    if(mimetype != undefined) searchParts.push(`mimetype=${mimetype}`)
    if(limit != undefined) searchParts.push(`limit=${limit}`)
    if(offset != undefined) searchParts.push(`offset=${offset}`)
    if(parent != undefined) searchParts.push(`parent=${parent}`)

    let res = await createCallableApi<{ result: SearchResult }>("/search?" + searchParts.join("&"))();

    if (res.data.status == 200)
      return res.data.result;
    throw new Error(res.error.message);
  }
  
}