import '../../css/components/user/User.scss';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { UserImageHandler } from '../common';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '../../theme/ThemeProvider';

interface UserProps {
  userName: string;
  userImage?: Promise<string>;
  userArea: string;
  userEMail: string;
  onClick: () => void;
  disabled?: boolean;
}

export const User = ({ userName, userArea, userImage, onClick, userEMail, disabled = false }: UserProps) => {
  const theme = useTheme();

  return (
    <div onClick={onClick} className="user">
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', paddingRight: 5, opacity: disabled ? 0.65 : 1 }}>
        <UserImageHandler userName={userName} userPhotoUrl={userImage} style={{ height: 64, width: 64, marginRight: 20, borderStyle: 'solid', borderColor: '#efefef' }} fontSize={28} />
        <div className="user-text-con">
          <div className="user-name" style={{ fontSize: 16 }}>
            {userName}
          </div>
          {userArea && (
            <div className="user-subtitle" style={{ fontSize: 15 }}>
              {userArea}
            </div>
          )}
          {/* <ConditionalRenderWithRights user_create user_edit> */}
          <a
            className="user-subtitle hover__text-underline text__ellipsis"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: 15, width: 'fit-content' }}
            href={'mailto:' + userEMail}
            onClick={(e) => e.stopPropagation()}
          >
            {userEMail}
          </a>
          {/* </ConditionalRenderWithRights> */}
        </div>
      </div>
      {disabled ? (
        <FontAwesomeIcon icon={faBan} style={{ fontSize: 18, color: 'white', background: theme.colors.error, borderRadius: '50%', padding: 5 }} />
      ) : (
        <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 18, color: '#858585' }} />
      )}
    </div>
  );
};
