import AF from "./122-afghanistan.svg";
import AL from "./117-albania.svg";
import DZ from "./126-algeria.svg";
import AS from "./085-american-samoa.svg";
import AD from "./102-andorra.svg";
import AO from "./124-angola.svg";
import AI from "./087-anguilla.svg";
import AG from "./104-antigua-and-barbuda.svg";
import AR from "./064-argentina.svg";
import AM from "./121-armenia.svg";
import AW from "./099-aruba.svg";
import AU from "./130-australia.svg";
import AT from "./189-austria.svg";
import AZ from "./125-azerbaijan.svg";
import BS from "./194-bahamas.svg";
import BH from "./116-bahrain.svg";
import BD from "./128-bangladesh.svg";
import BB from "./111-barbados.svg";
import BY from "./110-belarus.svg";
import BE from "./054-belgium.svg";
import BZ from "./115-belize.svg";
import BJ from "./098-benin.svg";
import BM from "./114-bermuda.svg";
import BT from "./094-bhutan.svg";
import BO from "./133-bolivia.svg";
import BA from "./113-bosnia-and-herzegovina.svg";
import BW from "./127-botswana.svg";
import BR from "./250-brazil.svg";
import IO from "./093-british-indian-ocean-territory.svg";
import BN from "./107-brunei.svg";
import BG from "./135-bulgaria.svg";
import BF from "./118-burkina-faso.svg";
import BI from "./103-burundi.svg";
import KH from "./132-cambodia.svg";
import CM from "./106-cameroon.svg";
import CA from "./206-canada.svg";
import CV from "./096-cape-verde.svg";
import KY from "./092-cayman-islands.svg";
import CF from "./091-central-african-republic.svg";
import TD from "./095-chad.svg";
import CL from "./051-chile.svg";
import CN from "./261-china.svg";
import CX from "./083-christmas-island.svg";
import CC from "./082-cocos-island.svg";
import CO from "./062-colombia.svg";
import KM from "./090-comoros.svg";
import CG from "./240-republic-of-the-congo.svg";
import CD from "./258-democratic-republic-of-congo.svg";
import CK from "./081-cook-islands.svg";
import CR from "./131-costa-rica.svg";
import CI from "./245-ivory-coast.svg";
import HR from "./134-croatia.svg";
import CU from "./129-cuba.svg";
import CY from "./030-cyprus.svg";
import CZ from "./061-czech-republic.svg";
import DK from "./072-denmark.svg";
import DJ from "./211-djibouti.svg";
import DM from "./147-dominica.svg";
import DO from "./018-dominican-republic.svg";
import EC from "./049-ecuador.svg";
import EG from "./079-egypt.svg";
import SV from "./015-el-salvador.svg";
import GQ from "./241-equatorial-guinea.svg";
import ER from "./210-eritrea.svg";
import EE from "./257-estonia.svg";
import ET from "./001-ethiopia.svg";
import FK from "./187-falkland-islands.svg";
import FO from "./146-faroe-islands.svg";
import FJ from "./145-fiji.svg";
import FI from "./052-finland.svg";
import FR from "./077-france.svg";
import PF from "./234-french-polynesia.svg";
import GA from "./186-gabon.svg";
import GM from "./159-gambia.svg";
import GE from "./256-georgia.svg";
import DE from "./066-germany.svg";
import GH from "./255-ghana.svg";
import GI from "./185-gibraltar.svg";
import GR from "./071-greece.svg";
import GL from "./183-greenland.svg";
import GD from "./182-grenada.svg";
import GU from "./181-guam.svg";
import GT from "./040-guatemala.svg";
import GN from "./154-guinea.svg";
import GW from "./209-guinea-bissau.svg";
import HT from "./247-haiti.svg";
import VA from "./190-vatican-city.svg";
import HN from "./027-honduras.svg";
import HK from "./067-hong-kong.svg";
import HU from "./053-hungary.svg";
import IS from "./041-iceland.svg";
import IN from "./217-india.svg";
import ID from "./078-indonesia.svg";
import IR from "./063-iran.svg";
import IQ from "./007-iraq.svg";
import IE from "./070-ireland.svg";
import IL from "./060-israel.svg";
import IT from "./011-italy.svg";
import JM from "./026-jamaica.svg";
import JP from "./033-japan.svg";
import JO from "./042-jordan.svg";
import KZ from "./034-kazakhstan.svg";
import KE from "./029-kenya.svg";
import KP from "./037-north-korea.svg";
import KR from "./055-south-korea.svg";
import KW from "./178-kuwait.svg";
import KG from "./208-kyrgyzstan.svg";
import LA from "./069-laos.svg";
import LV from "./032-latvia.svg";
import LB from "./008-lebanon.svg";
import LS from "./244-lesotho.svg";
import LR from "./218-liberia.svg";
import LY from "./225-libya.svg";
import LI from "./216-liechtenstein.svg";
import LT from "./025-lithuania.svg";
import LU from "./023-luxembourg.svg";
import MO from "./144-macao.svg";
import MG from "./253-madagascar.svg";
import MW from "./252-malawi.svg";
import MY from "./056-malaysia.svg";
import MV from "./232-maldives.svg";
import ML from "./207-mali.svg";
import MT from "./012-malta.svg";
import MQ from "./137-martinique.svg";
import MR from "./177-mauritania.svg";
import MU from "./021-mauritius.svg";
import MX from "./239-mexico.svg";
import FM from "./204-micronesia.svg";
import MD from "./231-moldova.svg";
import MC from "./043-monaco.svg";
import MN from "./251-mongolia.svg";
import MS from "./175-montserrat.svg";
import MA from "./086-morocco.svg";
import MZ from "./006-mozambique.svg";
import MM from "./028-myanmar.svg";
import NA from "./020-namibia.svg";
import NR from "./173-nauru.svg";
import NP from "./249-nepal.svg";
import NL from "./195-netherlands.svg";
import NZ from "./048-new-zealand.svg";
import NI from "./014-nicaragua.svg";
import NE from "./203-niger.svg";
import NG from "./010-nigeria.svg";
import NU from "./143-niue.svg";
import NF from "./202-norfolk-island.svg";
import MK from "./223-republic-of-macedonia.svg";
import MP from "./172-northern-marianas-islands.svg";
import NO from "./058-norway.svg";
import OM from "./002-oman.svg";
import PK from "./031-pakistan.svg";
import PW from "./142-palau.svg";
import PS from "./170-palestine.svg";
import PA from "./047-panama.svg";
import PG from "./229-papua-new-guinea.svg";
import PY from "./260-paraguay.svg";
import PE from "./074-peru.svg";
import PH from "./076-philippines.svg";
import PN from "./169-pitcairn-islands.svg";
import PL from "./108-poland.svg";
import PT from "./174-portugal.svg";
import PR from "./005-puerto-rico.svg";
import QA from "./019-qatar.svg";
import RO from "./050-romania.svg";
import RU from "./228-russia.svg";
import RW from "./243-rwanda.svg";
import KN from "./167-saint-kitts-and-nevis.svg";
import LC from "./139-st-lucia.svg";
import VC from "./160-st-vincent-and-the-grenadines.svg";
import WS from "./248-samoa.svg";
import SM from "./214-san-marino.svg";
import ST from "./201-sao-tome-and-principe.svg";
import SA from "./059-saudi-arabia.svg";
import SN from "./259-senegal.svg";
import SC from "./150-seychelles.svg";
import SL from "./149-sierra-leone.svg";
import SG from "./141-singapore.svg";
import SK from "./045-slovakia.svg";
import SI from "./004-slovenia.svg";
import SB from "./163-solomon-islands.svg";
import SO from "./198-somalia.svg";
import ZA from "./075-south-africa.svg";
import ES from "./044-spain.svg";
import LK from "./238-sri-lanka.svg";
import SD from "./221-sudan.svg";
import SR from "./197-suriname.svg";
import SE from "./073-sweden.svg";
import CH from "./097-switzerland.svg";
import SY from "./039-syria.svg";
import TW from "./080-taiwan.svg";
import TJ from "./236-tajikistan.svg";
import TZ from "./003-tanzania.svg";
import TH from "./184-thailand.svg";
import TL from "./226-east-timor.svg";
import TG from "./193-togo.svg";
import TK from "./158-tokelau.svg";
import TO from "./138-tonga.svg";
import TT from "./235-trinidad-and-tobago.svg";
import TN from "./013-tunisia.svg";
import TR from "./119-turkey.svg";
import TM from "./192-turkmenistan.svg";
import TC from "./156-turks-and-caicos.svg";
import TV from "./191-tubalu.svg";
import UG from "./009-uganda.svg";
import UA from "./065-ukraine.svg";
import AE from "./068-united-arab-emirates.svg";
import GB from "./262-united-kingdom.svg";
import US from "./153-united-states-of-america.svg";
import UY from "./038-uruguay.svg";
import UZ from "./220-uzbekistn.svg";
import VU from "./148-vanuatu.svg";
import VE from "./057-venezuela.svg";
import VN from "./164-vietnam.svg";
import VG from "./112-british-virgin-islands.svg";
import VI from "./188-virgin-islands.svg";
import YE from "./219-yemen.svg";
import ZM from "./016-zambia.svg";
import ZW from "./242-zimbabwe.svg";
import AX from "./105-aland-islands.svg";
import BQ from "./166-sint-eustatius.svg";
import CW from "./212-curacao.svg";
import GG from "./180-guernsey.svg";
import IM from "./246-isle-of-man.svg";
import JE from "./254-jersey.svg";
import ME from "./230-montenegro.svg";
import BL from "./213-st-barts.svg";
import RS from "./035-serbia.svg";
import SX from "./165-sint-maarten.svg";
import SS from "./161-south-sudan.svg";
import XK from "./151-kosovo.svg";

export const IconMap = {
  AF,AL,DZ,AS,AD,AO,AI,AG,AR,AM,AW,AU,AT,AZ,BS,
  BH,BD,BB,BY,BE,BZ,BJ,BM,BT,BO,BA,BW,BR,IO,BN,
  BG,BF,BI,KH,CM,CA,CV,KY,CF,TD,CL,CN,CX,CC,CO,
  KM,CG,CD,CK,CR,CI,HR,CU,CY,CZ,DK,DJ,DM,DO,EC,
  EG,SV,GQ,ER,EE,ET,FK,FO,FJ,FI,FR,PF,GA,GM,GE,
  DE,GH,GI,GR,GL,GD,GU,GT,GN,GW,HT,VA,HN,HK,HU,
  IS,IN,ID,IR,IQ,IE,IL,IT,JM,JP,JO,KZ,KE,KP,KR,
  KW,KG,LA,LV,LB,LS,LR,LY,LI,LT,LU,MO,MG,MW,MY,
  MV,ML,MT,MQ,MR,MU,MX,FM,MD,MC,MN,MS,MA,MZ,MM,
  NA,NR,NP,NL,NZ,NI,NE,NG,NU,NF,MK,MP,NO,OM,PK,
  PW,PS,PA,PG,PY,PE,PH,PN,PL,PT,PR,QA,RO,RU,RW,
  KN,LC,VC,WS,SM,ST,SA,SN,SC,SL,SG,SK,SI,SB,SO,
  ZA,ES,LK,SD,SR,SE,CH,SY,TW,TJ,TZ,TH,TL,TG,TK,
  TO,TT,TN,TR,TM,TC,TV,UG,UA,AE,GB,US,UY,UZ,VU,
  VE,VN,VG,VI,YE,ZM,ZW,AX,BQ,CW,GG,IM,JE,ME,BL,
  RS,SX,SS,XK,};