import React, { useEffect, useState } from 'react';


export function useAutoUpdateState<T>(initialState: T | (() => T), updateOn?: any[]): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(initialState);
  useEffect(
    () => {
      setState(initialState);
    },
    updateOn != undefined ? updateOn : [initialState]
  );
  return [state as T, setState];
}


