import { Form } from '@editors/form-editor';
import cuid from 'cuid';
import { ApplicationForm, Campaign, FormSubmission, IFormService, PermissionsObject, VirtualFileCreationBase } from '../entities';
import { getKeysWithTruthyValues } from '../helpers';
import { FileUpdate } from '../redux/files';
import { FileRepo } from '../repos';
import { AuthInteractor } from './AuthInteractor';
import { EmailInteractor } from './EmailInteractor';

export class CampaignsInteractor {
  constructor(private files: FileRepo, private auth: AuthInteractor, private formService: IFormService, private emails: EmailInteractor) {}

  /**
   *
   * @param campaignId
   * @param formId
   * @param storageid id of storable item (uid, groupId)
   * @returns
   */
  async getFormViewerContent(campaignId: string, formId: string, storageid: string): Promise<FormSubmission> {
    try {
      return await this.formService.getFormContent(campaignId, formId, storageid);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getFormViewerContents(campaignId: string): Promise<FormSubmission[]> {
    return await this.formService.getFormContents(campaignId);
  }

  async setFormViewerContent(campaignId: string, formId: string, storageid: string, content: FormSubmission): Promise<FormSubmission> {
    return await this.formService.setFormContent(campaignId, formId, storageid, content);
  }

  async deleteFormViewerContent(campaignId: string, formId: string, storageid: string): Promise<void> {
    return await this.formService.deleteFormContent(campaignId, formId, storageid);
  }

  async updateFormContent(campaignId: string, formId: string, storageId: string, content: Partial<FormSubmission>): Promise<void> {
    await this.formService.updateFormContent(campaignId, formId, storageId, content);
  }

  createFormSubmission(campaignId: string, formId: string, storageId: string, submitterId: string, content: FormSubmission): Promise<FormSubmission> {
    return this.formService.createFormSubmission(campaignId, formId, storageId, submitterId, content);
  }

  getFormSubmissionHistory(campaignId: string, formSubmissionId: string): Promise<(FormSubmission & { timestamp: Date })[]> {
    return this.formService.getFormSubmissionHistory(campaignId, formSubmissionId);
  }

  private getOptionalRightsAndGroupsFromUser(): [string | undefined, string[] | undefined] {
    const user = this.auth.getCurrentUser();
    if (user.roles?.superAdmin) return [undefined, undefined];
    const { id, groups } = user;
    return [id, Object.keys(groups || {})];
  }

  async getRootFiles(): Promise<Core.VirtualFile[]> {
    return this.files.getRootFiles(...this.getOptionalRightsAndGroupsFromUser());
  }

  async getParents(fileId: string): Promise<Core.VirtualFile[]> {
    return this.files.getParents(fileId);
  }

  async getFavoriteFiles(): Promise<Core.VirtualFile[]> {
    const { id, groups } = this.auth.getCurrentUser();
    return this.files.getFavoriteFiles(id, Object.keys(groups || {}));
  }

  async getChildren<T extends Core.VirtualFile>(parent: string, equalQueries?: { field: string; value: any }[]): Promise<T[]> {
    return this.files.getChildren(parent, ...this.getOptionalRightsAndGroupsFromUser(), equalQueries) as Promise<T[]>;
  }

  async getFile(fileId: string, cacheFirst?: boolean) {
    return this.files.getFile(fileId, cacheFirst);
  }

  async deleteFile(fileId: string): Promise<void> {
    return this.files.deleteFile(fileId);
  }

  async updateFile<T extends FileUpdate>(fileId: string, update: T) {
    return this.files.updateFile(fileId, update);
  }

  async batchUpdateFiles<T extends FileUpdate>(updates: { fileId: string; update: T }[]): Promise<void> {
    return await this.files.batchUpdateFiles(updates);
  }

  async toggleFavorite(fileId: string) {
    const file = await this.files.getFile(fileId, true);
    const user = this.auth.getCurrentUser();

    return this.files.updateFile(fileId, {
      setFavorite: {
        isFavorite: !file.favoriteOf.some((uid) => uid == user.id),
        uid: user.id,
      },
    });
  }

  async publishCampaign(campaign: Campaign, subject: string, htmlTemplate: string): Promise<Campaign> {
    const newCampaign = await this.updateFile(campaign.id, {
      publishedState: 'published',
      releaseDate: new Date(),
    } as any);
    const ok = confirm('Do you want to send an invitation to all entitled groups?');
    if (ok)
      await this.emails.sendNotificationEmail({ groupIds: getKeysWithTruthyValues(campaign.selectedGroups ?? {}) }, subject, {
        emailText: htmlTemplate,
        autosend: true,
        buttonLink: 'https://ibu-scope.com/campaigns-external/' + campaign.id,
      });
    return newCampaign as Campaign;
  }

  async createForm(campaignId: string, form: Form) {
    return await this.formService.createForm(campaignId, form);
  }

  async updateForm(campaignId: string, formId: string, form: Partial<Form>): Promise<Form> {
    return await this.formService.updateForm(campaignId, formId, form);
  }

  async deleteForm(campaignId: string, formId: string): Promise<void> {
    return await this.formService.deleteForm(campaignId, formId);
  }

  async getForms(campaignId: string): Promise<ApplicationForm[]> {
    return await this.formService.getForms(campaignId);
  }

  async getForm(campaignId: string, formId: string): Promise<Form> {
    return await this.formService.getForm(campaignId, formId);
  }

  async deepDuplicateCampaign(campaign: Campaign, newParent: string) {
    const forms = await this.getForms(campaign.id);
    const id = newParent ?? campaign.parent;
    const { id: _, parent, name, ...creatable } = campaign;
    const newCampaign = await this.createCampaign(id, { ...creatable, name: name + ' - Copy' });
    // create unique ids and adapt the references of report to forms
    forms.forEach((f_primary, index) => {
      const newId = cuid();
      forms.forEach((f_secondary) => {
        if (f_secondary?.parent === f_primary.id) {
          // is a report of the primary form
          f_secondary.parent = newId;
        }
      });
      f_primary.id = newId;
    });

    const promises = forms.map(async (f) => {
      await this.createForm(newCampaign.id, f);
    });

    await Promise.all(promises);
    return newCampaign;
  }

  updateNormalFile(fileId: string, data: Blob | Uint8Array | ArrayBuffer) {
    return this.files.updateNormalFile(fileId, data);
  }

  createNormalFile<T extends Partial<Omit<Core.VirtualFile, 'id' | 'trashed' | 'type' | 'permissons' | 'parent'>>>(
    parent: string | null,
    data: Blob | Uint8Array | ArrayBuffer,
    accessProperties: PermissionsObject,
    mimeType: Core.FileType,
    other: T
  ) {
    return this.files.createNormalFile(parent, data, accessProperties, mimeType, other);
  }

  async createCampaign<T extends VirtualFileCreationBase>(parent: string | null, other: T): Promise<Campaign> {
    const user = this.auth.getCurrentUser();
    return (await this.createVirtualFile(
      parent,
      {
        users: {
          move: [user.id],
          write: [user.id],
          read: [user.id],
          permission: [user.id],
        },
        groups: {},
        visibility: 'public',
      },
      'campaign' as any,
      other
    )) as Campaign;
  }

  async createFolder<T extends VirtualFileCreationBase>(parent: string | null, other: T): Promise<Core.VirtualFile> {
    const user = this.auth.getCurrentUser();
    return await this.createVirtualFile(
      parent,
      {
        users: {
          move: [user.id],
          write: [user.id],
          read: [user.id],
          permission: [user.id],
        },
        groups: {},
        visibility: 'public',
      },
      'folder',
      other
    );
  }

  async createVirtualFile<T extends VirtualFileCreationBase>(parent: string | null, accessProperties: PermissionsObject, mimeType: Core.FileType, other: T): Promise<Core.VirtualFile> {
    return await this.files.createVirtualFile(parent, accessProperties, mimeType, other);
  }
}
