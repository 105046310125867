import { ApplicationForm, FormSubmission, FormWithReports } from '@base/core';
import { Form } from '@editors/form-editor';
import { faEdit, faEye } from '@fortawesome/pro-solid-svg-icons';
import { alpha, Box, Button, Chip, Drawer, Stack, Tooltip, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, XGrid } from '@material-ui/x-grid';
import format from 'date-fns/format';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ContentHeader, MainMenu } from '../../components/common';
import { SearchInput } from '../../components/inputs';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { getColorFromDate } from '../externalUsers/getColorFromDate';
import { useGetMultipleFormSubmissions } from '../externalUsers/hooks/useGetMultipleFormSubmissions';
import { getDeadlineDateFromForm } from '../externalUsers/router/getDeadlineDateFromForm';
import { useDebouncedState } from '../users';
import { useGetGroup } from '../users/hooks/useGetGroup';
import { ComparePopup } from './ComparePopup';
import { FormSubmissionDrawer } from './FormSubmissionDrawer';
import { useGetMultipleForms } from './hooks/useGetMultipleForms';
import { GridNoResultsOverlay, GridNoRowsOverlay } from '../../components/content/GridContent';

interface ExternalFormProps {
  forms: FormWithReports[];
  parents: Core.VirtualFile[];
  baseUrl: string;
  loading?: boolean;
  onFormDoubleClick(forms: Form): void;
}

const useStyles = makeStyles({
  root: (theme: any) => ({
    flex: 1,
    height: 'auto',
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
  lastCell: {
    minWidth: '300px !important',
    position: 'absolute',
    left: 0,
    zIndex: 10,
    transform: 'translateX(calc(100vw - 100% - 24px))',
    justifyContent: 'flex-end',
  },
});

function getColor(status: FormSubmission['state'] | 'not-started') {
  switch (status) {
    case 'draft':
      return 'warningAction';
    case 'rejected':
      return 'errorAction';
    case 'submitted':
      return 'infoAction';
    case 'success':
      return 'successAction';
    case 'review':
      return 'warningAction';
    default:
      return 'default';
  }
}

function getName(status: FormSubmission['state'] | 'not-started') {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'rejected':
      return 'Rejected';
    case 'submitted':
      return 'Submitted';
    case 'success':
      return 'Accepted';
    case 'review':
      return 'In Review';
    default:
      return 'default';
  }
}
function columns(t: (...params: any) => any, openGroup: (campaignId: string, formId: string, submissionId: string) => void, preview: (submissionId: string) => void): GridColDef[] {
  return [
    {
      field: 'name',
      width: 275,
      headerName: 'Name of Application',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'status',
      width: 150,
      headerName: t('status'),
      type: 'date',
      renderCell: ({ value }) => <Chip color={getColor(value as any) as any} label={getName(value as any)} />,
    },
    {
      field: 'deadline',
      headerName: t('deadline'),
      type: 'date',
      width: 120,
      renderCell: ({ formattedValue, value }) => <Chip color={getColorFromDate(value as Date) as any} label={formattedValue ?? '-'} />,
    },
    {
      field: 'submissionDate' as keyof FormSubmission,
      headerName: t('submission-date'),
      type: 'date',
      width: 180,
      renderCell: ({ formattedValue, value, row }) => (
        <Chip
          color={getColorFromDate(row.submission?.submissionDate as Date) as any}
          label={row.submission?.submissionDate ? format(new Date(row.submission?.submissionDate), t('full-date-mask')) : '-'}
        />
      ),
    },
    {
      field: 'budgetOverride' as keyof FormSubmission,
      headerName: t('budget'),
      type: 'number',
      width: 160,
      renderCell: ({ formattedValue, value, row }) => {
        row as FormSubmission;
        const submission = row?.submission as FormSubmission;
        const requestedBudgetFieldId = row?.budgetField;
        const requestedBudget = submission?.data?.[requestedBudgetFieldId];

        let budgetString = 'No Budget';
        let budgetColor = 'default';
        let tooltipText = 'No Budget';

        if (submission?.budgetOverride) {
          budgetString = `${submission?.budgetOverride} €`;
          budgetColor = 'successAction';
          tooltipText = 'Approved';
        } else if (requestedBudget) {
          budgetString = `${requestedBudget} €`;
          budgetColor = 'errorAction';
          tooltipText = 'Not approved';
        }

        return (
          <Tooltip title={tooltipText}>
            <Chip color={budgetColor as any} label={budgetString} />
          </Tooltip>
        );
      },
    },
    {
      field: 'edit',
      flex: 1,
      align: 'right',
      type: 'date',
      disableColumnMenu: true,
      renderCell: ({ formattedValue, value, row }) => {
        const submission: FormSubmission = row.submission;
        const menuActions = [
          {
            title: 'Open',
            icon: faEdit,
            action: () => {
              preview(submission.id);
              //openGroup(submission.campaignId, submission.formId, submission.id);
            },
          },
          {
            title: 'Open in Project Application',
            icon: faEdit,
            action: () => {
              openGroup(submission.campaignId, submission.formId, submission.id);
            },
          }
          /*{
            title: 'Open in Project',
            icon: faEdit,
            action: () => {
              openGroup(submission.campaignId, submission.formId, submission.id);
            },
          },
          {
            title: t('preview'),
            icon: faEye,
            action: () => {
              preview(submission.id);
            },
          },*/
        ];
        return (
          <MainMenu
            id={row.id}
            menuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
            actions={menuActions}
          />
        );
      },
    },
  ].map(
    (def) =>
      ({
        ...def,
        renderHeader: (params) => {
          return (
            <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
              {params.colDef.headerName}
            </Typography>
          );
        },
      } as any),
  );
}

export function CampaignsByFederation() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<string>(null);

  const { groupId } = useParams<{ groupId: string }>();
  const { data: group, isLoading: loading } = useGetGroup(groupId);
  const requiredForms = Object.entries(group?.formSubmissions ?? {}).map(([formId, campaignId]: [string, string]) => ({ campaignId, formId }));

  const forms = useGetMultipleForms(requiredForms);
  const submissionData = useGetMultipleFormSubmissions(requiredForms, groupId).filter(Boolean);

  const finalForms = useMemo(() => {
    const filteredForms: (ApplicationForm & { submission?: FormSubmission })[] = forms
      .filter((d) => d.data)
      .map((d) => d.data)
      .map((f: ApplicationForm) => {
        const queryData = submissionData.find((q) => q.data?.formId == f.id);
        return { ...f, status: queryData?.data?.state ?? 'not-started', deadline: getDeadlineDateFromForm(f.deadline), submission: queryData?.data } as any;
      })
      .filter((f) => f.published !== false);

    return filteredForms;
  }, [forms, submissionData]);

  const history = useHistory();

  const openGroupSubmission = useCallback((campaignId: string, formId: string, submissionId: string) => history.push(`/campaigns/${campaignId}/forms/${formId}`), [history]);
  const previewSubmission = (submissionId: string) => setSelectedSubmissionId(submissionId);

  const cols = useMemo(() => columns(t, openGroupSubmission, previewSubmission), [openGroupSubmission]);

  const [filterModel, setFilterModel, fastFilterModel] = useDebouncedState<GridFilterModel>();
  const [selectedSubmissions, setSelectedSubmissions] = useState<string[]>([]);
  const selectedForm = selectedSubmissionId && finalForms.find((f) => f.submission?.id === selectedSubmissionId);
  const [comparePopupOpen, setComparePopupOpen] = useState(false);

  return (
    <ContentContainer>
      <NavbarSmall
        breadcrumbs={[
          BREADCRUMBS.home,
          BREADCRUMBS.nfActivity,
          {
            name: group?.name,
            path: BREADCRUMBS.nfActivity.path + '/' + group?.id,
          },
        ]}
      />
      <ContentView noPadding>
        <ContentHeader noBack title={t('groups')} subtitle={t('application-counted', { count: Object.keys(group?.formSubmissions ?? {}).length })}>
          <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
            <Box flex={1}>
              <SearchInput
                variant="outlined"
                size="small"
                InputProps={{
                  sx: {
                    background: theme.palette.background.default,
                    ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) },
                  },
                }}
                placeholder={t('search-applications')}
                fullWidth
                value={fastFilterModel?.items?.find((i) => i.columnField == 'name')?.value ?? ''}
                onChange={(e) => {
                  try {
                    new RegExp(e.target.value);
                    setFilterModel({ items: [{ columnField: 'name', operatorValue: 'contains', value: e.target.value }] });
                  } catch {}
                }}
              />
            </Box>
            <Button variant="contained" disabled={selectedSubmissions.length == 0} onClick={() => setComparePopupOpen(true)}>
              Compare
            </Button>
          </Stack>
        </ContentHeader>
        <XGrid
          sortModel={[{ field: 'name', sort: 'asc' }]}
          className={classes.root}
          headerHeight={40}
          filterModel={filterModel}
          onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
          onSelectionModelChange={({ selectionModel }) => setSelectedSubmissions(selectionModel as string[])}
          checkboxSelection
          disableSelectionOnClick
          onRowClick={(params) => {
            const submission: FormSubmission = params.row.submission;
            previewSubmission(submission?.id);
          }}
          columns={cols}
          rows={finalForms}
          loading={loading}
          components={{
            NoRowsOverlay: GridNoRowsOverlay,
            NoResultsOverlay: GridNoResultsOverlay,
          }}
        />
        <Drawer PaperProps={{ sx: { width: 550 } }} anchor="right" open={Boolean(selectedSubmissionId)} onClose={() => setSelectedSubmissionId(null)}>
          {selectedForm && (
            <FormSubmissionDrawer groups={[group]} content={submissionData.find((s) => s.data?.id === selectedSubmissionId).data} form={selectedForm} onClose={() => setSelectedSubmissionId(null)} />
          )}
          {!selectedForm && (
            <Typography sx={{ m: 3 }} color="error">
              Form has been deleted
            </Typography>
          )}
        </Drawer>
        <ComparePopup
          open={comparePopupOpen}
          onClose={() => setComparePopupOpen(false)}
          selectedSubmissions={selectedSubmissions}
          submissionData={submissionData.map((s) => s.data)}
          group={group}
          finalForms={finalForms}
        />
      </ContentView>
    </ContentContainer>
  );
}

function getCurrentReportFromForm(form: FormWithReports): FormWithReports | undefined {
  for (const r of form.reports ?? []) {
    const report = r as FormWithReports;
    if (report.status !== 'rejected' && report.status !== 'success') return report;
    if (report.status === 'rejected') return undefined;
  }
  return undefined;
}
