import LoginBg from './login_bg.jpg';
import Placeholder from './placeholder.png';
import DefaultImagePlaceholder from './imagePlaceholder.png';
import LoginHomeBg from './login_home_bg.svg';
import PdfHeader from './ibu_letter_header.png';
import PdfFooter from './ibu_letter_footer.jpg';

export { default as FirstSlide } from './FirstSlide';
export { default as IbuLogo } from './IbuLogo';
export { default as People } from './People';
export { default as Search } from './Search';
export { default as Woman } from './Woman';
export { default as NothingHere } from './NothingHere';
export { default as KlickNote } from './KlickNote';
// export { default as LoginHomeBg } from './login_home_bg';

export { LoginBg, Placeholder, DefaultImagePlaceholder, LoginHomeBg, PdfHeader, PdfFooter };
