import React from 'react';
import '../../css/components/common/popups/popup.scss';
import { Switch } from './buttons/Switch';

type settingSwitchProps = {
    check: boolean;
    title: string;
    onChange: (change: boolean) => void;
};

export const SettingsSwitchContainer = ({ check, title, onChange }: settingSwitchProps) => {
    return (
        <div className="no-user-select" onClick={() => onChange(!check)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: 12 }}>
            <p className="popup-text" style={{ marginRight: 40 }}>
                {title}
            </p>
            <Switch check={check} />
        </div>
    );
};
