import * as React from 'react';

function SvgRemoveFormatRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.931 20.745" ref={svgRef} {...props}>
      <path d="M25.688 19.083L1.459.143a.648.648 0 00-.912.1L.142.75a.648.648 0 00.1.912l24.23 18.941a.648.648 0 00.912-.1l.405-.506a.648.648 0 00-.101-.914zM14.262 3.89l-1 3.009 2.055 1.61 1.54-4.619h5.834v1.3a.648.648 0 00.648.648h.648a.648.648 0 00.648-.648V1.945a.648.648 0 00-.648-.648H7.131a.643.643 0 00-.575.362L9.41 3.89zm-.645 13.613h-1.3l1.04-3.12-2.057-1.608-1.576 4.728h-1.3a.648.648 0 00-.648.648v.648a.648.648 0 00.648.648h5.193a.648.648 0 00.648-.648v-.648a.648.648 0 00-.648-.648z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRemoveFormatRegular);
export default ForwardRef;
