import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StandardButton } from '../../components/common';
import { GridInputElement, GridMultilineInput, PictogramChooser } from '../../components/content/GridContent';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/components/common/layout/InputGrid.scss';
import '../../css/components/common/layout/usergrid.scss';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';

export const UserGroupCreation = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const { t } = useTranslation();

  const [userName, setUserName] = useState(user?.displayName);
  const regex = /^([A-Z]).*?(\s([A-Z])[\w\-]*)?$/;
  const [_, firstChar, __, secondChar] = regex.exec(userName ? userName : '') ?? [];

  return (
    <ContentContainer>
      <NavbarSmall title="Neue Zugangskategorie" breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.users, BREADCRUMBS.usergroupCreation]}>
        <div style={{ display: 'flex' }}>
          <StandardButton contentColor="#fff" onClick={() => {}} text="Abbrechen" bgColor="#015B80" />
          <StandardButton contentColor="white" onClick={() => {}} text="Gruppe anlegen" bgColor="#ffffff30" faIcon={faPlusCircle} />
        </div>
      </NavbarSmall>
      <ContentView>
        <div className="content-title">{t('settings')}</div>
        <div className="input-grid">
          <GridInputElement paddingVertical={false} value={inputValue} placeholder="Name der Zugangskategorie" onText={setInputValue} />
          <PictogramChooser
            onSelect={
              (icon) => null //TODO: IMPLEMENT
            }
          />
          <GridMultilineInput value={inputValue} placeholder="Beschreibung der Zugangskategorie." onText={setInputValue} charMax={300} />
        </div>
        <div className="content-title mg-top">Mitglieder der Zugangskategorie</div>
      </ContentView>
    </ContentContainer>
  );
};
