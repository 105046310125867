import { Box, Card, CardActions, CardContent, CardHeader, TextField, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { StandardPopup } from '../../../components/common';
import { useUpdateUserMutation } from '../../../views/users/hooks/mutations';

interface SetPasswordPopupProps {}

export function SetPasswordPopup({}: SetPasswordPopupProps) {
  const user = useSelector((state: Core.StateType) => state.auth.user);
  const theme = useTheme();
  const { t } = useTranslation();

  const mutation = useUpdateUserMutation();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required('No password provided.')
      .min(12, 'Password is too short - should be 12 chars minimum.')
      .matches(/^.*[a-z].*$/, 'Password must contain at least one lower case letter (a-z)')
      .matches(/^.*[A-Z].*$/, 'Password must contain at least one upper case letter (A-Z)')
      .matches(/^.*[0-9].*$/, 'Password must contain at least on number (0-9)')
      .matches(/^.*[^\w\s].*$/, 'Password must contain at least one special character.'),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  return (
    <StandardPopup visible={user?.passwordChangeRequired} onBackdropClick={() => {}} width={500} hideClose>
      <Formik
        initialValues={{ password: '', passwordConfirmation: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          //   await onSubmit({ ...values });
          await mutation.mutateAsync({ passwordChangeRequired: false, password: values.password, id: user.id });
          setSubmitting(false);
          location.reload();
        }}
        enableReinitialize
      >
        {({ isSubmitting, errors, touched, handleSubmit, submitForm }) => (
          <Card>
            <CardHeader
              title={t('set-new-password')}
              titleTypographyProps={{ variant: 'h2' }}
              style={{ background: theme.palette.background.gray, paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}
            />
            <CardContent>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1">{t('change-password-explanation')}</Typography>
                <Field
                  style={{ marginTop: 20 }}
                  as={TextField}
                  label={'New Password'}
                  required
                  type="password"
                  autoComplete="new-password"
                  variant="outlined"
                  id="new-password"
                  name="password"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
                <Field
                  style={{ marginTop: 14 }}
                  as={TextField}
                  label={t('repeat-password')}
                  required
                  type="password"
                  variant="outlined"
                  name="passwordConfirmation"
                  error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
                  helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                />
              </Box>
            </CardContent>
            <CardActions sx={{ paddingX: 3, paddingBottom: 3, justifyContent: 'flex-end' }}>
              <LoadingButton
                variant="outlined"
                color="primary"
                // TODO: loading={resetPasswordMutation.isLoading}
                onClick={submitForm}
              >
                {t('confirm')}
              </LoadingButton>
            </CardActions>
          </Card>
        )}
      </Formik>
    </StandardPopup>
  );
}
