import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faArrowToBottom, faBook, faLink, faStar as faStarRegular, faTimes } from '@fortawesome/pro-regular-svg-icons';
import 'firebase/database';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PdfHighlighter, PdfLoader, setPdfWorker } from 'react-pdf-highlighter';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
/* eslint import/no-webpack-loader-syntax: 0 */
//@ts-ignore
import PDFWorker from 'worker-loader!pdfjs-dist/lib/pdf.worker';
import { Actions } from '../../..';
import { LoadingPopup, Tooltip, usePopup } from '../../../components/common';
import { downloadFile } from '../../../components/content/ContentElementHelper';
import { useTheme } from '../../../theme/ThemeProvider';
import { FileModule } from '../../../views/campaign/CampaignOverview';
import { useToggleFavoriteMutation } from '../../../views/fileManager/hooks/mutations';
import { useGetFile } from '../../../views/fileManager/hooks/useGetFile';
import { usePromiseResolve } from '../../../views/fileManager/usePromiseResolve';
import { NavButton } from '../components/editor-nav';
import { FloatingNavbar } from '../components/Reader';
import '../styles/views/Editor.scss';
import '../styles/views/Reader.scss';
setPdfWorker(PDFWorker);

const isFavorite = (file: Core.VirtualFile, uid: string) => {
  return file.favoriteOf?.some((u) => u === uid);
};

export function useRootFolder(file: Core.VirtualFile): FileModule | undefined {
  const { filesMap, fetching } = useSelector((state: Core.StateType) => state.files);
  const dispatch = useDispatch();

  useEffect(() => {
    if (file?.path?.[0] && !filesMap[file.path[0]] && !fetching.fetchFile.fetching) dispatch(Actions().files.fetchFile(file.path[0]));
  }, [file]);

  return filesMap[file?.path?.[0] ?? ''] as FileModule | undefined;
}

export const PdfReader = () => {
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const history = useHistory();
  const editorRef = useRef<HTMLDivElement>(null);
  const { fileId } = useParams<{ fileId: string }>();
  console.log('fileID: ' + fileId);
  const { t } = useTranslation();
  const theme = useTheme();
  const notification = usePopup();
  const { data: file } = useGetFile<Core.NormalFile>(fileId);

  const pdfUrl = usePromiseResolve(file?.downloadUrl);
  const mutateFavorite = useToggleFavoriteMutation();
  const rootFolder = useRootFolder(file);
  let hash: string;

  const setFileFavorite = (file: Core.VirtualFile) => {
    if (isFavorite(file, user.id)) {
      file.favoriteOf = file.favoriteOf.filter((u) => u !== user.id);
      mutateFavorite.mutate(file.id);
      return;
    }
    file.favoriteOf === undefined ? (file.favoriteOf = [user.id]) : file.favoriteOf.push(user.id);
    mutateFavorite.mutate(file.id);
  };

  const copyLinkToClipboard = () => {
    void navigator.clipboard.writeText(window.location.href).then(() =>
      notification({
        title: t('link-copied-to-clipboard'),
        type: 'info',
        blocking: false,
        time: 2000,
        id: new Date().getUTCMilliseconds(),
      }),
    );
  };

  const btnColor = '#6D6D6D';
  const btnBg = '#F0F0F0';
  if (file) {
    return (
      <div className="content-container" id="scrollContainer" style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <FloatingNavbar
          title={file.name}
          subTitle={rootFolder?.name}
          icon={rootFolder?.icon ? ['fal', rootFolder?.icon as string] : faBook}
          onParentClick={() => history.push('/files/' + file.parent)}
        >
          <Tooltip text={t('favorite')} tooltipStyle={{ right: 0, transform: 'translateY(85px)' }}>
            <NavButton
              hoverAnimation="favorite-animation"
              bgColor={btnBg}
              color={isFavorite(file, user.id) ? theme.colors.favorite : btnColor}
              onClick={() => setFileFavorite(file)}
              icon={isFavorite(file, user.id) ? faStarSolid : faStarRegular}
            />
          </Tooltip>
          <Tooltip text={t('download')} tooltipStyle={{ right: 0, transform: 'translateY(85px)' }}>
            <NavButton bgColor={btnBg} color={btnColor} onClick={() => downloadFile(file)} icon={faArrowToBottom} hoverAnimation="scale-animation" fontAwesomeIconStyle={{ fontSize: 30 }} />
          </Tooltip>
          <Tooltip text={t('close-reader')} tooltipStyle={{ right: 0, transform: 'translateY(85px)' }}>
            <NavButton bgColor={theme.colors.error} color={'#fff'} onClick={() => history.go(hash ? -2 : -1)} icon={faTimes} hoverAnimation="scale-animation" fontAwesomeIconStyle={{ fontSize: 30 }} />
          </Tooltip>
        </FloatingNavbar>
        <div className="pdf-loader-container" style={{ paddingTop: 0 }} ref={editorRef}>
          {pdfUrl !== undefined ? (
            <PdfLoader url={pdfUrl} beforeLoad={<LoadingPopup />}>
              {(pdfDocument) => {
                return <PdfHighlighter pdfDocument={pdfDocument} highlights={[]} />;
              }}
            </PdfLoader>
          ) : (
            <LoadingPopup />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="content-container" id="scrollContainer">
        <FloatingNavbar title={'No File'} subTitle={'Error when loading file'} icon={['fal', faExclamationTriangle]}>
          <Tooltip text={t('copy-link')} tooltipStyle={{ right: 0, transform: 'translateY(85px)' }}>
            <NavButton hoverAnimation="link-animation" bgColor={btnBg} color={btnColor} onClick={copyLinkToClipboard} icon={faLink} />
          </Tooltip>
          <Tooltip text={t('close-reader')} tooltipStyle={{ right: 0, transform: 'translateY(85px)' }}>
            <NavButton bgColor={theme.colors.error} color={'#fff'} onClick={() => history.go(hash ? -2 : -1)} icon={faTimes} hoverAnimation="scale-animation" fontAwesomeIconStyle={{ fontSize: 30 }} />
          </Tooltip>
        </FloatingNavbar>
        <LoadingPopup />
      </div>
    );
  }
};
