import { ApplicationForm, Campaign, Group, GroupTag } from '@base/core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItem, ListItemIcon, ListItemText, Stack, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { NavLinkListItem, SidebarHeader, SidebarList } from '../../components/common';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { FormSettings } from './forms/FormSettings';
import { FormSidebarItem } from './FormSidebarItem';
import { Settings } from './settings';

export interface CampaignEditorProps {
  onCreateForm(): void | Promise<void>;
  onEditForm(formId: string): void;
  loading?: boolean;
  campaign: Campaign;
  publishCampaign(id: string): void | Promise<void>;
  forms: ApplicationForm[];
  groups: Group[];
  parent: Core.VirtualFile;
  groupTags: GroupTag[];
  baseUrl: string;
  onUpdateCampaign(campaign: Partial<Campaign>): Promise<void> | void;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      paddingTop: 0,
      '& .MuiListItem-root': {
        paddingTop: 12,
        paddingBottom: 12,
        borderBottom: `1.5px solid ${theme.palette.background.gray}`,
      },
      '& .MuiListItemText-root': {
        fontSize: 15, //doesnt work
      },
    },
  }),
);

const drawerWidth = 350;

export function CampaignEditor({ onCreateForm, loading, campaign, forms, onEditForm, baseUrl, onUpdateCampaign, groups, groupTags, publishCampaign, parent }: CampaignEditorProps) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [campaignCreationOpen, setCampaignCreationOpen] = useState(false);
  const { t } = useTranslation();

  const drawer = (
    <div>
      <SidebarHeader title={campaign.name} subtitle={forms.length + ' ' + t('form-count')} theme={theme}></SidebarHeader>
      <SidebarList title={t('campaigns')} theme={theme}>
        <NavLinkListItem to={baseUrl + '/'} icon={faCog} title={t('settings')} exact />
      </SidebarList>
      <SidebarList title={t('form_plural')} theme={theme}>
        {forms
          .filter((f) => !f.parent)
          .sort((formA: ApplicationForm, formB: ApplicationForm) => {
            if (formA.name.toLowerCase() < formB.name.toLowerCase()){
              return -1;
            }
            if (formA.name.toLowerCase() > formB.name.toLowerCase()){
              return 1;
            }
            return 0;
          })
          .map((form) => (
            <FormSidebarItem key={form.id} form={form} allForms={forms} onEditForm={onEditForm} campaign={campaign} baseUrl={baseUrl} />
          ))}
        <ListItem
          button
          sx={{ color: theme.palette.primary.main, backgroundColor: theme.palette.background.gray, ':hover': { bgcolor: theme.palette.grey[800], color: 'white' } }}
          onClick={onCreateForm}
        >
          <ListItemIcon>
            <FontAwesomeIcon size="lg" fixedWidth icon={faPlusCircle} />
          </ListItemIcon>
          <ListItemText>{t('add-new-application')}</ListItemText>
        </ListItem>
      </SidebarList>
    </div>
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const [tabIndex, setTabIndex] = useState('subByGroup');

  return (
    <ContentContainer flexDirection="row-reverse">
      <main className={classes.content} style={{ maxWidth: '100vw' }}>
        <NavbarSmall
          breadcrumbs={[
            BREADCRUMBS.home,
            BREADCRUMBS.campaignOverview,
            ...(parent ? [{ name: parent.name, path: '/campaigns/' + parent.id }] : []),
            { name: campaign.name, path: baseUrl, notExact: true },
          ]}
        />
        <ContentView noPadding ref={containerRef}>
          <Stack direction="row" style={{ flex: 1 }} alignItems="stretch">
            <Box className={classes.drawer} sx={{ boxShadow: 6, height: '100%' }}>
              {drawer}
            </Box>
            <Box flex={1} display="flex" flexDirection="column" maxWidth="calc(100% - 350px)">
              <Switch>
                <Route path={baseUrl + '/'} exact>
                  <Settings
                    campaign={campaign}
                    onUpdateCampaign={onUpdateCampaign}
                    groups={groups}
                    groupTags={groupTags}
                    publishCampaign={publishCampaign}
                    setGroups={({ value }) => onUpdateCampaign({ id: campaign.id, selectedGroups: value.reduce((m, cv) => ({ ...m, [cv]: true }), {}) })}
                  />
                </Route>
                <Route path={baseUrl + '/forms/:formId'} exact>
                  <FormSettings
                    forms={forms}
                    campaign={campaign}
                    groups={groups}
                    groupTags={groupTags}
                    setGroups={({ value }) => onUpdateCampaign({ id: campaign.id, selectedGroups: value.reduce((m, cv) => ({ ...m, [cv]: true }), {}) })}
                  />
                </Route>
              </Switch>
            </Box>
          </Stack>
        </ContentView>
      </main>
    </ContentContainer>
  );
}
