import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Placeholder } from '../../assets/images';
import '../../css/Views/contentView.scss';
import type { ReactChildrenProp } from '../../lib/ReactProps';
import { ConditionalRender, CurrentUserAvatar } from '../common';
import { LogoutButton } from '../common/buttons/LogoutButton';
import { Breadcrumb } from './Breadcrump';

export const compareSearch = (searchInput: string, elementName: string) => {
  try {
    const searchRe = new RegExp(searchInput, 'gi');
    return searchRe.test(elementName);
  } catch (error) {
    return true;
  }
};

interface NavbarSmallProps {
  children?: ReactChildrenProp;
  searchInputValue: string;
  setSearchInputValue: (val: string) => void;
  title?: string;
  breadcrumbs: { name: string; path: string; id?: string; notExact?: boolean }[];
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

export const NavbarSmallSearch = ({ children, title, searchInputValue, setSearchInputValue, breadcrumbs = [], inputProps }: NavbarSmallProps) => {
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const { t } = useTranslation();
  if (!user) {
    return null;
  }
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundImage: Placeholder }}>
      <div className="main-navbar">
        {/* <div className="content-nav-left-con"> */}
        <Breadcrumbs separator={null} itemsAfterCollapse={2} itemsBeforeCollapse={2}>
          {breadcrumbs.map((el, i) => {
            return <Breadcrumb key={el.path + i} name={el.name} path={el.path} exact={!el.notExact} />;
          })}
        </Breadcrumbs>
        {/* </div> */}
        <div className="content-navbar-right-con" style={{ zIndex: 2 }}>
          <LogoutButton style={{ marginRight: 15, height: 45 }} fontSize={20} />
          <CurrentUserAvatar style={{ height: 64, width: 64 }} fontSize={28} />
          {/* <Link to="/settings">
            <div className="content-nav-button settings">
              <FontAwesomeIcon icon={faCog} className="nav-content-button-icon" color="white" />
            </div>
          </Link> */}
        </div>
      </div>
      <div className="main-navbar" style={{ height: 60, paddingBottom: 30 }}>
        <div className="content-search-bar-container">
          <div className="content-search-bar" style={{ color: 'white' }}>
            <FontAwesomeIcon icon={faSearch} style={{ fontSize: 28, marginRight: 12 }} />
            <input {...inputProps} type="Text" value={searchInputValue} onChange={(e) => setSearchInputValue(e.target.value)} placeholder={t('search')} className="content-search-bar-input" />
          </div>
          <ConditionalRender render={searchInputValue !== ''}>
            <button className="content-search-bar-delete" onClick={() => setSearchInputValue('')}>
              <FontAwesomeIcon icon={faTimes} style={{ color: 'white', fontSize: 16 }} />
            </button>
          </ConditionalRender>
        </div>
        <ConditionalRender render={!!title}>
          <p>{title}</p>
        </ConditionalRender>
        {children}
      </div>
    </div>
  );
};
