import '../../../css/components/common/content/search/search.scss';

import type { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { SVGImage } from '../../../lib';

interface fileFilterElementProps {
  check: boolean;
  onClick: () => void;
  icon?: SVGImage;
  faIcon?: IconDefinition;
  color?: string;
  text?: string;
}

export const FileFilterElement = ({ check, icon: Icon, onClick, color = 'red', text, faIcon }: fileFilterElementProps) => {
  return (
    <div className="filter-popup-element" onClick={onClick} style={{ color: check ? '#ffffff' : '#6D6D6D', transition: '300ms ease', backgroundColor: check ? color : '#EFEFEF' }}>
      {Icon && <Icon className="popup-element-icon" style={{ fill: check ? '#ffffff' : color }} />}
      {faIcon && <FontAwesomeIcon icon={faIcon} className="popup-element-icon" style={{ color: check ? '#ffffff' : color, fontSize: 20 }} />}
      <div className="popup-element-text">{text}</div>
    </div>
  );
};
