import * as ACTION_TYPES from './types';
import type * as ACTIONS from './actions';
import { FetchingType, createDefault, createFetching, createSuccess, createError } from '../common';
import { UploadTaskSnapshot } from '../../entities';

type ValueOf<T> = T[keyof T];
export type ActionsType = ValueOf<{ [k in keyof typeof ACTIONS]: ReturnType<typeof ACTIONS[k]> }>;

export type initialStateType = {
  fetching: {
    fetchRootFiles: FetchingType;
    fetchChildren: FetchingType;
    updateFile: FetchingType;
    createFile: FetchingType;
    createFolder: FetchingType;
    updateFolder: FetchingType;
    fetchFile: FetchingType;
    createPhysicalFile: FetchingType;
    updatePhysicalFile: FetchingType;
    deleteFile: FetchingType;
    fetchFavorites: FetchingType;
    uploadFilesWithinFolders: FetchingType;
  };
  filesMap: { [fileId: string]: Core.VirtualFile };
  uploadTasks: { [fileId: string]: UploadTaskSnapshot };
  currentFiles: { [parentId: string]: Core.VirtualFile[] };
  rootFiles: Core.VirtualFile[];
  favoriteFiles: Core.VirtualFile[];
};

const initialState: initialStateType = {
  fetching: {
    fetchRootFiles: createDefault(),
    fetchChildren: createDefault(),
    updateFile: createDefault(),
    createFile: createDefault(),
    createFolder: createDefault(),
    updateFolder: createDefault(),
    fetchFile: createDefault(),
    createPhysicalFile: createDefault(),
    updatePhysicalFile: createDefault(),
    deleteFile: createDefault(),
    fetchFavorites: createDefault(),
    uploadFilesWithinFolders: createDefault(),
  },
  currentFiles: {},
  rootFiles: [],
  filesMap: {},
  uploadTasks: {},
  favoriteFiles: [],
};

function applyDeleteFile(state: initialStateType, fileId: string) {
  let { currentFiles, filesMap, rootFiles, favoriteFiles } = state;
  const file = filesMap[fileId];
  if (!file) return {};
  if (file.parent) {
    if (currentFiles[file.parent]) {
      currentFiles = { ...currentFiles, [file.parent]: currentFiles[file.parent].filter((f) => f.id != file.id) };
      favoriteFiles = favoriteFiles.filter((f) => f.id != file.id);
    }
  } else {
    rootFiles = rootFiles.filter((f) => f.id != file.id);
  }
  return { currentFiles, rootFiles, favoriteFiles };
}

function removeSuccededTasks(tasks: initialStateType['uploadTasks']): initialStateType['uploadTasks'] {
  const currentTasks: initialStateType['uploadTasks'] = {};
  for (const [id, task] of Object.entries(tasks)) {
    if (task.state !== 'cancelled' && task.state !== 'error' && task.state !== 'success') {
      currentTasks[id] = task;
    }
  }
  return currentTasks;
}
function removeCanceledTask(tasks: initialStateType['uploadTasks'], taskToRemove: UploadTaskSnapshot): initialStateType['uploadTasks'] {
  const currentTasks: initialStateType['uploadTasks'] = {};
  for (const [id, task] of Object.entries(tasks)) {
    if (task.state !== 'cancelled' && task.fileId !== taskToRemove.fileId) {
      currentTasks[id] = task;
    }
  }
  return currentTasks;
}

function applyUpdateFile(state: initialStateType, file: Core.VirtualFile, uid?: string) {
  let { currentFiles, rootFiles, favoriteFiles } = state;
  if (!file) return {};
  if (file.parent) {
    if (currentFiles[file.parent]) {
      currentFiles = { ...currentFiles, [file.parent]: [...currentFiles[file.parent].filter((f) => f.id != file.id), file] };
    } else {
      currentFiles = { ...currentFiles, [file.parent]: [file] };
    }
  } else {
    rootFiles = [...rootFiles.filter((f) => f.id != file.id), file];
  }
  if (uid) {
    favoriteFiles = [...favoriteFiles.filter((f) => f.id != file.id), file].filter((file) => file.favoriteOf.includes(uid));
  }
  return { currentFiles, rootFiles, favoriteFiles };
}

export const filesReducer = (state: initialStateType = initialState, action: ActionsType): initialStateType => {
  switch (action.type) {
    case ACTION_TYPES.DELETE_CANCLED_TASK:
      return {
        ...state,
        uploadTasks: removeCanceledTask(state.uploadTasks, action.task),
      };
    case ACTION_TYPES.CLEAR_COMPLETED_UPLOAD_TASKS:
      return { ...state, uploadTasks: removeSuccededTasks(state.uploadTasks) };
    case ACTION_TYPES.FETCH_FAVORITES_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchFavorites: createFetching(),
        },
      };
    case ACTION_TYPES.FETCH_FAVORITES_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchFavorites: createSuccess(),
        },
        favoriteFiles: action.files,
      };
    case ACTION_TYPES.FETCH_FAVORITES_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchFavorites: createError(action.error),
        },
      };
    case ACTION_TYPES.DELETE_FILE_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          deleteFile: createFetching(),
        },
      };
    case ACTION_TYPES.DELETE_FILE_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          deleteFile: createSuccess(),
        },
        ...applyDeleteFile(state, action.fileId),
      };
    case ACTION_TYPES.DELETE_FILE_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          deleteFile: createError(action.error),
        },
      };
    case ACTION_TYPES.UPLOAD_TASK_CHANGED:
      return {
        ...state,
        uploadTasks: {
          ...state.uploadTasks,
          [action.update.fileId]: { ...state.uploadTasks[action.update.fileId], ...action.update },
        },
      };
    case ACTION_TYPES.UPDATE_PHYSICAL_FILE_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatePhysicalFile: createFetching(),
        },
      };
    case ACTION_TYPES.UPDATE_PHYSICAL_FILE_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatePhysicalFile: createSuccess(),
        },
        ...applyUpdateFile(state, action.file),
      };
    case ACTION_TYPES.UPDATE_PHYSICAL_FILE_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatePhysicalFile: createError(action.error),
        },
      };
    case ACTION_TYPES.CREATE_PHYSICAL_FILE_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createPhysicalFile: createFetching(),
        },
      };
    case ACTION_TYPES.CREATE_PHYSICAL_FILE_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createPhysicalFile: createSuccess(),
        },
        ...applyUpdateFile(state, action.file),
      };
    case ACTION_TYPES.CREATE_PHYSICAL_FILE_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createPhysicalFile: createError(action.error),
        },
      };
    case ACTION_TYPES.FETCH_FILE_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchFile: createFetching(),
        },
      };
    case ACTION_TYPES.FETCH_FILE_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchFile: createSuccess(),
        },
        ...applyUpdateFile(state, action.file),
      };
    case ACTION_TYPES.FETCH_FILE_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchFile: createError(action.error),
        },
      };
    case ACTION_TYPES.UPDATE_FILE_MAP:
      return { ...state, filesMap: action.fileMap };
    case ACTION_TYPES.FETCH_ROOT_FILES_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchRootFiles: createFetching(),
        },
      };
    case ACTION_TYPES.FETCH_ROOT_FILES_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchRootFiles: createSuccess(),
        },
        rootFiles: action.files,
      };
    case ACTION_TYPES.FETCH_ROOT_FILES_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchRootFiles: createError(action.error),
        },
      };
    case ACTION_TYPES.FETCH_CHILDREN_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchChildren: createFetching(),
        },
      };
    case ACTION_TYPES.FETCH_CHILDREN_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchChildren: createSuccess(),
        },
        currentFiles: { ...state.currentFiles, [action.parentId]: action.files },
      };
    case ACTION_TYPES.FETCH_CHILDREN_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchChildren: createError(action.error),
        },
      };
    case ACTION_TYPES.UPDATE_FILE_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateFile: createFetching(),
        },
      };
    case ACTION_TYPES.UPDATE_FILE_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateFile: createSuccess(),
        },
        ...applyUpdateFile(state, action.file, action.uid),
      };
    case ACTION_TYPES.UPDATE_FILE_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateFile: createError(action.error),
        },
      };
    case ACTION_TYPES.CREATE_FILE_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createFile: createFetching(),
        },
      };
    case ACTION_TYPES.CREATE_FILE_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createFile: createSuccess(),
        },
        ...applyUpdateFile(state, action.file),
      };
    case ACTION_TYPES.CREATE_FILE_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createFile: createError(action.error),
        },
      };
    case ACTION_TYPES.CREATE_FOLDER_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createFolder: createFetching(),
        },
      };
    case ACTION_TYPES.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createFolder: createSuccess(),
        },
        ...applyUpdateFile(state, action.folder),
      };
    case ACTION_TYPES.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createFolder: createError(action.error),
        },
      };
    case ACTION_TYPES.UPDATE_FOLDER_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateFolder: createFetching(),
        },
      };
    case ACTION_TYPES.UPDATE_FOLDER_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateFolder: createSuccess(),
        },
        ...applyUpdateFile(state, action.folder),
      };
    case ACTION_TYPES.UPDATE_FOLDER_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateFolder: createError(action.error),
        },
      };
    case ACTION_TYPES.UPLOAD_FILES_WITHIN_FOLDERS_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          uploadFilesWithinFolders: createFetching(),
        },
      };
    case ACTION_TYPES.UPLOAD_FILES_WITHIN_FOLDERS_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          uploadFilesWithinFolders: createSuccess(),
        },
      };
    case ACTION_TYPES.UPLOAD_FILES_WITHIN_FOLDERS_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          uploadFilesWithinFolders: createError(action.error),
        },
      };
    default:
      return state;
  }
};
