import * as React from 'react';

function SvgRedoRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.08 20.746" ref={svgRef} {...props}>
      <path d="M20.578 0h-1.159a.5.5 0 00-.5.514l.1 4.123a10.373 10.373 0 10-1.692 13.436.5.5 0 00.02-.729l-.826-.826a.5.5 0 00-.69-.023A8.2 8.2 0 1117.846 7l-5.286-.126a.5.5 0 00-.514.5v1.159a.5.5 0 00.5.5h8.031a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRedoRegular);
export default ForwardRef;
