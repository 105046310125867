export function getKeysWithTruthyValues(object: object) {
  return Object.entries(object ?? {})
    .filter(([_, v]) => v)
    .map(([key]) => key);
}

export function debounce(func: (...params: any[]) => any, timeout = 300) {
  let timer: NodeJS.Timeout;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
