import { QueryObserverResult, useQueries, useQuery, UseQueryOptions } from 'react-query';
import { API } from '@base/core';
import { useGetUsers } from './useGetUsers';
import { useMemo } from 'react';

export function useGetGroup(groupId: string) {
  const { data: users = [] } = useGetUsers();

  const res = useQuery(['groups', groupId], async () => {
    const group = await API.groups.getGroup(groupId);

    return group;
  });

  const finalGroup = useMemo(() => res.data && { ...res.data, users: users.filter((u) => u.groups[res.data.id]) }, [res, users]);

  return { ...res, data: finalGroup };
}
