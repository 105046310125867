import { Actions } from '@base/core';
import { OrgChart, TreeItem } from '@ctrl/react-orgchart';
import { faCompress, faExpand } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Placeholder } from '../../assets/images';
import { StandardButton } from '../../components/common';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/Views/content/Organigram.scss';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { useTheme } from '../../theme/ThemeProvider';

function generateUserTreeFromUsers(users: Core.User[]): [TreeItem, { [key: string]: TreeItem[] }] {
  const TreeItems = users.map<TreeItem & { parent?: string | null }>((u) => ({
    hasChild: false,
    hasImage: false,
    parent: u.supervisor,
    children: [],
    hasParent: !!u.supervisor,
    id: u.id,
    entity: {
      ...u,
      hasImage: true,
      name: u.displayName,
      title: u.jobTitle ?? '',
      totalReports: 0,
      avatar: ((u.photoURL as any) as string) ?? Placeholder,
      subTitle: u.abteilung ?? '',
      link: '/users/' + u.id,
    },
  }));
  const userMap: { [key: string]: TreeItem[] } = {};
  const rootUsers: TreeItem[] = [];
  for (const user of TreeItems) {
    const p = user.parent;
    if (p) {
      const arr = userMap[p];
      if (arr) {
        arr.push(user);
      } else {
        userMap[p] = [user];
      }
    } else {
      rootUsers.push(user);
    }
  }

  for (const user of TreeItems) {
    if (userMap[user.id]) {
      user.hasChild = true;
      user.children = userMap[user.id];
      user.entity.totalReports = userMap[user.id].length;
    }
  }
  return [rootUsers.sort((a, b) => (b.children?.length ?? 0) - (a.children?.length ?? 0))[0], userMap];
}

export const UserOrganigram = () => {
  const theme = useTheme();
  const [config, setConfig] = useState({});
  const [downloadingChart, setDownloadingChart] = useState(false);

  const svgRef = useRef<OrgChart>(null);
  const dispatch = useDispatch();
  // const downloadImageId = 'download-image';
  // const downloadPdfId = 'download-pdf';
  const history = useHistory();
  const handle = useFullScreenHandle();
  const toggleFullscreen = handle.active ? handle.exit : handle.enter;
  const {
    usersList,
    fetching: { queryUsers },
  } = useSelector((state: Core.StateType) => state.users);
  // const { groups } = useSelector((state: Core.StateType) => state.groups);

  useEffect(() => {
    if (!queryUsers.fetching) dispatch(Actions().users.queryUsers());
  }, []);

  useEffect(() => {
    if (svgRef.current) {
      console.log(svgRef.current);
    }
  }, [svgRef.current]);

  const [userTree, map] = useMemo(() => (usersList && generateUserTreeFromUsers(usersList)) ?? [], [usersList]);

  // const handleDownload = () => {
  //   setDownloadingChart(false);
  // };

  // const handleLoadConfig = (d: any) => {
  //   return config;
  // };

  return (
    <ContentContainer>
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.users, BREADCRUMBS.organigram]} title="Organigram">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StandardButton id={'fullscreen'} text="FullScreen" bgColor="#ffffff30" contentColor="#fff" onClick={toggleFullscreen} faIcon={faExpand} />
          {/* <StandardButton id={'zoom-in'} bgColor="#ffffff30" contentColor="#fff" faIcon={faSearchPlus} onClick={()=>console.log(svgRef.current)} /> */}
          {/* <StandardButton id={'zoom-out'} bgColor="#ffffff30" contentColor="#fff" faIcon={faSearchMinus} /> */}
          {/* <StandardButton id={'download-image'} text="Download IMG" bgColor="#ffffff30" contentColor="#fff" onClick={() => {}} />
          <StandardButton id={'download-pdf'} text="Download PDF" bgColor="#ffffff30" contentColor="#fff" onClick={() => {}} /> */}
        </div>
      </NavbarSmall>
      <ContentView noPadding>
        <FullScreen handle={handle}>
          {downloadingChart && <div>Downloading chart</div>}
          {!queryUsers.fetching && userTree && (
            <OrgChart
              tree={userTree}
              onNameClick={(personData) => history.push(`/users/${personData.data.id}`)}
              onEntityLinkClick={(personData, e) => {
                e.preventDefault();
                history.push(`/users/${personData.data.id}`);
              }}
              reportsColor="#4B4B4B88"
              countFontSize={16}
              nameColor="#4B4B4B"
              titleColor="#4B4B4B88"
              nameFontSize={20}
              countYTopDistance={70}
              titleYTopDistance={40}
              nodeWidth={180}
              avatarWidth={10}
              lineDepthY={5}
              nodeHeight={150}
              ref={svgRef}
              titleFontSize={15}
              nodeSpacing={50}
              nodeBorderRadius={15}
              getCount={(data) => {
                const count = data.data.entity.totalReports;
                return count > 0 ? `${count} supervisee${count > 1 ? 's' : ''}` : '';
              }}
              // downloadImageId={downloadImageId}
              // downloadPdfId={downloadPdfId}
              // onConfigChange={setConfig}
              // loadConfig={(d) => config}
              // nodeHeight={150}
              // downlowdedOrgChart={(d) => {
              //   handleDownload();
              // }}
              // loadImage={(d) => {
              //   return Promise.resolve(Placeholder);
              // }}
              // loadChildren={async (personData: TreeItem) => {
              //   console.log(personData);
              //   return map?.[personData.id];
              // }}
              // loadParent={async (personData: TreeItem) => {
              //   console.log(personData);
              //   return map?.[personData.id][0];
              // }}
            />
          )}

          {handle.active && (
            <div style={{ position: 'absolute', right: 50, top: 50 }}>
              <StandardButton id={'fullscreen'} text="Exit Fullscreen" bgColor={theme.colors['main-color']} contentColor="#fff" onClick={toggleFullscreen} faIcon={faCompress} />
            </div>
          )}
        </FullScreen>
      </ContentView>
    </ContentContainer>
  );
};
