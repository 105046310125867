import { faSave } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StandardButton, usePopup } from '../../components/common';
import { GridInputElement, GridMultilineInput, PictogramChooser, UploadImage } from '../../components/content/GridContent';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/components/common/layout/InputGrid.scss';
import '../../css/components/common/layout/usergrid.scss';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { FileModule } from '../campaign/CampaignOverview';
import { useCreatePhysicalFileMutation } from './hooks';

export const ModuleCreation = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pictogram, setPictogram] = useState('');
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState<File>(null);

  const { user } = useSelector((state: Core.StateType) => state.auth);

  const createModuleMutation = useCreatePhysicalFileMutation<FileModule>();
  const notify = usePopup();
  const createModule = async () => {
    if (!imageFile || !user) {
      alert('Please select image!');
      return;
    }
    const uid = user.id;
    try {
      await createModuleMutation.mutateAsync({
        parent: null,
        permissions: {
          users: {
            read: [uid],
            write: [uid],
            move: [uid],
            permission: [uid],
          },
          groups: {
            read: [],
            write: [],
            move: [],
            permission: [],
          },
          visibility: 'public',
        },
        data: imageFile,
        name,
        icon: pictogram,
        description,
        type: 'folder',
      });
      history.goBack();
    } catch (error) {
      notify({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    }
  };

  return (
    <ContentContainer>
      <NavbarSmall title={t('create-new-module')} breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.moduleOverview, BREADCRUMBS.moduleCreation]}>
        <div style={{ display: 'flex' }}>
          <StandardButton
            contentColor="#fff"
            onClick={() => {
              history.goBack();
            }}
            text={t('cancel')}
            bgColor="#ffffff30"
          />
          <StandardButton contentColor="white" onClick={createModule} text={t('save-module')} bgColor="#ffffff30" faIcon={faSave} loading={createModuleMutation.isLoading} />
        </div>
      </NavbarSmall>
      <ContentView>
        <div className="content-title">{t('module-information')}</div>
        <div className="input-grid">
          <GridInputElement paddingVertical={false} value={name} placeholder={t('module-title')} onText={setName} />
          <UploadImage
            image={image}
            setImage={(url, file) => {
              setImage(url);
              setImageFile(file);
            }}
            title={t('upload-thumbnail')}
            subTitle={t('upload-thumbnail-text')}
          />
          <GridMultilineInput value={description} placeholder={t('module-description')} onText={setDescription} charMax={300} />
          <PictogramChooser onSelect={(icon) => setPictogram(icon)} />
        </div>
      </ContentView>
    </ContentContainer>
  );
};
