import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps, Grid, IconButton, useTheme } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainDialog } from '../../common/popups/MainDialog';

type gridMultilineProps = {
  label?: string;
  description?: string;
} & FormControlLabelProps &
  CheckboxProps & { inputType: any };

export const GridCheckboxElement = ({ label, description, indeterminate, inputType, ...props }: gridMultilineProps) => {
  const [infoVisible, setInfoVisible] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item xs={12} md={6}>
      <Box sx={{ borderRadius: 2, background: theme.palette.background.gray, paddingX: 1, paddingY: 0.4, paddingLeft: 2, display: 'flex', justifyContent: 'space-between' }}>
        <FormControlLabel label={label} {...props} control={<Checkbox ref={ref} indeterminate={indeterminate} color="primary" />} />
        {description && (
          <IconButton onClick={() => setInfoVisible(true)}>
            <FontAwesomeIcon icon={faInfoCircle} color={theme.palette.primary.main} />
          </IconButton>
        )}
      </Box>
      <MainDialog
        hideCancel
        buttonText="Close"
        open={infoVisible}
        modalTitle={t('information')}
        onCloseClick={() => setInfoVisible(false)}
        onSaveClick={() => setInfoVisible(false)}
        description={description}
      />
    </Grid>
  );
};
