import { firebase } from '../config';
import { region } from '../init';

export function createCallableApi<T>(apiPath: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD' = 'GET', additionalHeaders?: any) {
  const url = `https://${region}-${(firebase.app().options as { projectId: string }).projectId}.cloudfunctions.net/api${apiPath}`;

  // const url = `http://localhost:5001/seekinnovation-learningsuite/europe-west3/api${apiPath}`;
  // const url = `http://localhost:5001/ibu-knowledgebase/europe-west3/api${apiPath}`;

  // console.log('firebase.functions().', firebase.functions());
  // console.log('firebase.app().options.projectId', firebase.app().options);
  // console.log('url', url);
  return async (data?: any) => {
    const payload = {
      headers: {
        authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken(true)}`,
        'Content-Type': 'application/json',
        ...additionalHeaders,
      },
      method,
    };
    if (method !== 'GET' && method !== 'HEAD') {
      payload['body'] = data && JSON.stringify(data);
    }
    const res = await fetch(url, payload);
    if (!res.ok) throw (await res.json()).error;
    return (await res.json()) as FunctionsResult<T>;
  };
}

export type FunctionsResult<T> = { data: { status?: number } & T; error?: { message: string; details: Error }; code: number };
