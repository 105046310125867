import * as React from 'react';

function SvgSearch(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.825 292.582" ref={svgRef} {...props}>
      <path d="M133.845 28.684s-4.44-7.89 0-9.5 6.447-.124 6.658 1.671-1.7 8.881-1.7 8.881z" fill="#ffb27d" />
      <path
        d="M137.401 79.157a143.45 143.45 0 01-22.256 2.286 123.906 123.906 0 01-17.106-.77 110.31 110.31 0 01-3.887-.515c-13.118-1.987-20.344-5.512-20.344-5.512s11.976-25.069 21.651-24.107c11.361 1.122 30.588 11.308 32.495 8.456 2.152-3.219 3.955-30.819 3.955-30.819 5.031-3.445 8.036.187 8.036.187s9.835 48.552-2.544 50.794z"
        fill="#aec3ff"
      />
      <path d="M137.401 79.157a143.448 143.448 0 01-22.256 2.286 123.907 123.907 0 01-17.106-.77c-3.02-9.6-5.191-19.412.882-13.5 10.712 10.422 38.48 11.984 38.48 11.984z" fill="#9db0f4" />
      <path d="M72.906 281.55s-.86 9.96 1.246 11l26.964-.053s0-3.112-4.331-3.8-11.937-3.226-13.224-8.871-10.655 1.724-10.655 1.724z" fill="#233862" />
      <path
        d="M92.7 160.938c-.134 6.515-.447 13.237-1.059 19.278-2.915 28.664-3.935 63.3-4.353 69.977-.729 11.59-3.015 30.107-3.015 30.107s-4.542 4.312-13.394 2.254c0 0-7.336-26.67-8.988-54.753l-.073-1.387c-.189-3.714-.388-7.958-.593-12.537-1.348-30.663-2.813-76.213-2.813-76.213l33.829-7.943s.791 14.971.459 31.217z"
        fill="#2a4e96"
      />
      <path d="M92.7 160.938c-8.573 14.791-24.916 42.044-31.463 52.939-1.348-30.663-2.813-76.213-2.813-76.213l33.829-7.943s.779 14.971.447 31.217z" fill="#233862" />
      <path d="M7.038 132.283s-5.5 6.474-6 8.259-1.377 8.259-.9 10.2 4.13-5.42 5.463-6.2-1 2.915-.544 3.547 3.083-2.167 4.108-3.112.926-6.02 2.155-9.022-4.282-3.672-4.282-3.672z" fill="#ffb27d" />
      <path
        d="M5.75 270.225s-4.8 4.373-3.862 7.489 5.266 5.186 5.852 8.988 2.915 5.879 5.031 5.879h10.045s1.55-2.672-2.186-3.483-5.157-5.276-4.921-9.423a72.763 72.763 0 00-.352-9.68l-7.958-1.844z"
        fill="#233862"
      />
      <path
        d="M86.923 138.359s-31.669 67.759-48.523 97.48-22.105 38.026-22.105 38.026-9.656-3.8-10.533-6.4c0 0 6.445-57.4 17.733-71.388 6.376-7.9 13.868-31.63 14.218-39.928s7.749-28.1 7.749-28.1z"
        fill="#2a4e96"
      />
      <path
        d="M96.181 141.552a54.877 54.877 0 01-30.418 7.637c-7.822-.34-23.337-2.558-25.841-8.779-.588-1.458-.158-3.316.729-6.16 2.291-7.394 7.625-21.476 6.015-52.7a51.364 51.364 0 01.209-8.194c1.678-15.379 10.491-24.486 19.011-27.45 20.774-7.261 27.693 2.8 27.693 2.8s9.182 7.275 10.567 22.458c2.767 30.36-7.965 70.388-7.965 70.388z"
        fill="#aec3ff"
      />
      <path
        d="M71.966 44.154s9.532 5.8 19.871 4.156c0 0 2.045-.52 1.04-2.915a31.057 31.057 0 00.918-3.2 48.5 48.5 0 001.183-5.223l-5.866-6.168-5.558-5.893c-1.536 14.081-11.588 19.243-11.588 19.243z"
        fill="#ffb27d"
      />
      <path d="M82.664 30.251s3.192 8.534 11.13 11.942c.906-3.313 1.5-6.777 1.5-6.777l-6.18-4.615z" fill="#ed985f" />
      <path d="M101.561 36.671s-18.195 2.274-19.472-8.908-4.487-18.418 7.076-20.342 14.313 2.094 15.661 5.726 1.436 22.644-3.265 23.524z" fill="#ffb27d" />
      <path
        d="M76.344 5.604c-.158-.194-.53-.362-1.37-.44a3.418 3.418 0 011.591-.384c.221-1.166.921-3.216 2.959-3.644a2.527 2.527 0 00-1.295 1.778c2.429-2.31 7.5-3.867 21.313-2.249 18.219 2.133 11.75 13.6 9.333 14.9s-8.225-2.743-12.632-2.56-5.5 6.517-7.025 7.336-.882-.707-2.869-1.7-2.308 2.351-1.8 4.552-2.385 4.97-2.385 4.97l-2.694-3.129c-2.694-3.131-4.858-16.033-3.476-18.722z"
        fill="#2c3a64"
      />
      <path d="M131.914 23.448l-30.889.275-.211-4.645 30.792-2.388z" fill="#2a4e96" />
      <path d="M155.231 24.226l-33.632.486-.384-8.449 33.535-2.6z" fill="#2a4e96" />
      <path d="M177.907 24.89l-28.361.034-.517-11.405 28.24-2.57z" fill="#233862" />
      <path d="M178.313 26.813a1.581 1.581 0 001.511-1.654l-.666-14.653a1.586 1.586 0 00-1.654-1.513 1.589 1.589 0 00-1.513 1.657l.666 14.653a1.586 1.586 0 001.656 1.51z" fill="#233862" />
      <path d="M100.927 24.717a1.411 1.411 0 01-1.472-1.343l-.165-3.8a1.411 1.411 0 011.343-1.472 1.409 1.409 0 011.472 1.346l.175 3.8a1.414 1.414 0 01-1.353 1.469z" fill="#2a4e96" />
      <path d="M40.645 134.251c2.291-7.394 7.625-21.476 6.015-52.7a51.364 51.364 0 01.209-8.194c6.836-.588 17.288 6.8 17.288 6.8s-6.435 39.247-23.512 54.094z" fill="#9db0f4" />
      <path
        d="M71.967 44.155s-17.531 3.012-26.675 14.174-23.852 26.9-27.328 32.412-13.132 43.021-13.132 43.021.639 1.215 3.3 1.907a6.269 6.269 0 004.4-.369s12.79-21.892 17.6-32.823c2-4.545 36.588-22.538 49.592-20.672 5.108.731-7.757-37.65-7.757-37.65z"
        fill="#aec3ff"
      />
      <path d="M131.729 15.359s3.77 2.308 6.027 1.331 2.055-1.868 2.055-1.868-6.814-1.892-8.082.537z" fill="#ffb27d" />
      <path d="M88.978 56.381s-7.693 48.583-62.279 62.716l-5.947 11.73 10.445 1.768s62.145-18.005 61.307-77.073z" fill="#d5e000" />
      <path d="M87.785 48.629l-2.519.248v3.646l3.712 3.86 3.53-.86v-5.062l-2.538-1.832z" fill="#d5e000" />
      <path
        d="M77.898 38.971s-2.429.549-8.016 5.184c0 0 5.546 11.769 17.247 13.178a39.169 39.169 0 01.658-8.7s-9.274-3.324-9.889-9.662zM87.785 48.629s5.709 3.032 6.131 7.751c0 0 2.186-3.457 1.55-5.849a19.68 19.68 0 00-2.59-5.14s.195 2.886-5.091 3.238z"
        fill="#9db0f4"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSearch);
export default ForwardRef;
