export const FETCH_GROUPS_REQUEST = "groups/fetch_groups_request"
export const FETCH_GROUPS_SUCCESS = "groups/fetch_groups_success"
export const FETCH_GROUPS_FAILURE = "groups/fetch_groups_failure"
export const CREATE_GROUP_REQUEST = "groups/create_group_request"
export const CREATE_GROUP_SUCCESS = "groups/create_group_success"
export const CREATE_GROUP_FAILURE = "groups/create_group_failure"
export const DELETE_GROUP_REQUEST = "groups/delete_group_request"
export const DELETE_GROUP_SUCCESS = "groups/delete_group_success"
export const DELETE_GROUP_FAILURE = "groups/delete_group_failure"
export const UPDATE_GROUP_REQUEST = "groups/update_group_request"
export const UPDATE_GROUP_SUCCESS = "groups/update_group_success"
export const UPDATE_GROUP_FAILURE = "groups/update_group_failure"
export const UPDATE_USERS_REQUEST = "groups/update_users_request"
export const UPDATE_USERS_SUCCESS = "groups/update_users_success"
export const UPDATE_USERS_FAILURE = "groups/update_users_failure"