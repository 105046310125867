import { useTranslation } from 'react-i18next';
import { Autocomplete, Checkbox, Chip, CircularProgress, ListItem, Stack, TextField, Tooltip, Typography } from '@material-ui/core';
import { DefaultImagePlaceholder } from '../../assets/images';
import React, { useEffect } from 'react';
import { Group } from 'libs/base/core/src/entities/Group';
import { remove, includes, orderBy } from 'lodash';

export type TSelectableUser = { icon?: string, groupName: string } & Core.User;
export type TSelectableGroup =  { displayName: string } & Group;
export type TSelectableElem = { elemType: string, displayName: string, elem: (TSelectableUser | TSelectableGroup) };

export interface DisplayUser {
  displayName: string,
  email: string
}

interface UserSelectInputInterface {
  setSelectedUsers: (user: DisplayUser[]) => void;
  groups: TSelectableGroup[];
  users: TSelectableUser[];
  selectedUsers: DisplayUser[];
  preselectedUsers: DisplayUser[]
  loading: boolean;
}

export function UserSelectInput({ setSelectedUsers, groups, users, selectedUsers, preselectedUsers, loading }: UserSelectInputInterface) {
  const { t } = useTranslation();

  useEffect(()=>{
    setSelectedUsers(preselectedUsers);
  }, []);

  if (loading) {
    return (
      <Stack sx={{ alignItems: 'center', border: `1px solid grey`, borderRadius: 1.25 }}>
        <CircularProgress size={40} style={{ margin: 8 }} />
      </Stack>
    );
  }

  let allElems: TSelectableElem[] = []
  const selectedElems: TSelectableElem[] = []

  groups.forEach((group: TSelectableGroup) => {
    allElems.push({
      elemType: 'group',
      displayName: "NF " + group.displayName,
      elem: group
    });
  });

  users.forEach((user: TSelectableUser) => {
    allElems.push({elemType: 'user', displayName: user.displayName, elem: user} as TSelectableElem);
    allElems = orderBy(allElems, ['elemType', 'displayName']);
  });

  selectedUsers.forEach((user: DisplayUser) => {
    selectedElems.push({elemType: 'user', displayName: user.displayName, elem: user} as TSelectableElem);
  });

  return (
    <Autocomplete
      multiple
      id="email-list-input1"
      options={allElems}
      value={selectedElems}
      getOptionLabel={(option) => (option?.displayName ?? 'No display name?!?')}
      freeSolo
      disableCloseOnSelect
      onChange={(e, v, d) => {
        let displayUsers: DisplayUser[] = [];
        if (v.length > 0 && Array.isArray(v)) {

          const isUserAlreadySelected = (displayUser: DisplayUser): boolean => {
            return includes(displayUsers, displayUser)
          }

          const isGroupChecked = (users: DisplayUser[]): boolean => {
            let retVal = true
            users.forEach((u) => {
              if (!isUserAlreadySelected(u)) {
                retVal = false;
              }
            });
            return retVal;
          }

          v.forEach((listElem: TSelectableElem) => {
            // group of mails
            if (listElem.elemType === 'group') {
              const group: TSelectableGroup = listElem.elem as TSelectableGroup

              if (isGroupChecked(group.users)) {
                group.users.forEach((u) => {
                  displayUsers = remove(displayUsers, (du) => {
                    return du.email !== u.email
                  });
                });
              } else {
                group.users.forEach((u) => {
                  if (!isUserAlreadySelected(u)) {
                    displayUsers.push(u)
                  }
                });
              }
            }
            // single user
            else {
              const user: TSelectableUser = listElem.elem as TSelectableUser
              if (user.email) {
                if (isUserAlreadySelected(user)) {
                  displayUsers = remove(displayUsers, (du) => {
                    return du.email !== user.email;
                  });
                } else {
                  displayUsers.push(user);
                }
              }
            }
          });
        }

        setSelectedUsers(displayUsers);
      }}
      renderOption={(props, option: TSelectableElem, state) => {
        const user: TSelectableUser = option.elemType === 'user' ? option.elem as TSelectableUser : null;
        const group: TSelectableGroup = option.elemType === 'group' ? option.elem as TSelectableGroup : null;

        if (user) {
          props['key'] = user.id;
        } else if (group) {
          props['key'] = group.id;
        }

        const isSelected = (users: any[], displayUser?): boolean => {
          let retVal = true;
          if (users?.length > 0 && !displayUser) {
            users.forEach((user) => {
              if (!includes(selectedUsers, user)) {
                retVal = false;
              }
            })
            return retVal;
          } else if (displayUser) {
            return includes(selectedUsers, displayUser);
          } else {
            return false;
          }
        }

        return (
          <ListItem {...props}>
            <Checkbox sx={{ mr: 2 }} checked={ isSelected(group?.users, user) } />
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <img src={user?.icon ?? group?.icon ?? DefaultImagePlaceholder} alt="flag" style={{ borderRadius: '50%', height: 28, width: 28, objectFit: 'cover' }} />
              <Stack>
                {
                  option.elemType === 'user'
                  ? <>
                      <Typography sx={{ flexShrink: 0 }} variant={'subtitle1'} fontWeight={500}>
                        {user?.displayName ?? user?.email}
                      </Typography>
                      <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: 330 }} variant={'subtitle2'}>
                        {user?.groupName ?? t('No group')}
                      </Typography>
                    </>
                  : <>
                      <Typography sx={{ flexShrink: 0 }} variant={'subtitle1'} fontWeight={500}>
                        {option.displayName}
                      </Typography>
                    </>
                }
              </Stack>
            </Stack>
          </ListItem>
        );
      }}
      renderTags={(value, getTagProps) => {
        return value.map((options: any, index) => {
          const user = options?.elem
          return (
            <Tooltip placement="top" title={user.displayName + ", " + user.email } key={user.email}>
              <Chip
                variant="outlined"
                color={'primary'}
                label={index + 1 + '. ' + user.email}
                {...getTagProps({index})} />
            </Tooltip>
          );
        });
      }}
      renderInput={(params) => {
        return <TextField {...params} inputProps={{ ...params.inputProps }} variant="outlined" label="Signers" placeholder="Signers" />;
      }}
    />
  );
}
