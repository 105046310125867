import { useTheme } from '@material-ui/core/styles';
import { FileUpdate } from 'libs/base/core/src/redux/files';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ContentHeader } from '../../components/common/ContentHeader';
import { Module } from '../../components/content';
import { compareSearch, ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/Views/ModulView.scss';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { FileModule } from '../campaign/CampaignOverview';
import { useGetGroup } from '../users/hooks/useGetGroup';

interface CampaignOverviewExternalProps {
  folders: FileModule[];
  isLoading?: boolean;
  onUpdate: (id: string, value: FileUpdate<FileModule> & { imageFile?: File }) => Promise<void>;
  onCreate: (value: FileUpdate<FileModule> & { imageFile?: File }) => Promise<void>;
  onDelete: (file: FileModule) => Promise<void>;
  onOpenModule(m: FileModule): void;
}

function getFirstGroup(groups: Core.Groups) {
  return Object.keys(groups)?.[0];
}

export const CampaignOverviewExternal = ({ isLoading = false, folders, onCreate, onDelete, onUpdate, onOpenModule }: CampaignOverviewExternalProps) => {
  const [searchValue, setSearchValue] = useState('');
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const groupID = getFirstGroup(user.groups);
  const { data: group } = useGetGroup(groupID);

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ContentContainer>
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.campaignExternalOverview]} />
      <ContentView loading={isLoading} noPadding>
        <ContentHeader title={t('form-folders')} subtitle={t('folder-counted', { count: folders.length })} href="/"></ContentHeader>
        <div style={{ padding: theme.spacing(4) }}>
          <div className="module-grid-module-view">
            {folders
              .filter((f) => {
                if (f.groupTypeAccess && f.groupTypeAccess.length > 0) {
                  if (!group) return false;
                  return f.groupTypeAccess.includes(group.type);
                }
                return true;
              })
              .map((file: FileModule, idx) => {
                return (
                  compareSearch(searchValue, file.name) && (
                    <div key={file.id}>
                      <Module external currentModule={file} key={file.id} onClick={() => onOpenModule(file)} count={idx + 1} />
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </ContentView>
    </ContentContainer>
  );
};
