import { API, VirtualFileCreationBase } from '@base/core';
import { FileUpdate } from 'libs/base/core/src/redux/files';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export function useToggleFavoriteMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (fileId: string) => {
      return await API.files.toggleFavorite(fileId);
    },
    {
      onSuccess: (file, fileId, context) => {
        queryClient.setQueryData(
          ['files', file.parent, 'children'],
          (old: { children: Core.VirtualFile[]; fileCount: number; folderCount: number }) => old && { ...old, children: old.children.map((f) => (f.id == file.id ? file : f)) },
        );
        queryClient.setQueryData(['files', file.id], (old) => file);
      },
    },
  );
}

export function useRenameFileMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ fileId, name }: { fileId: string; name: string }) => {
      return await API.files.updateFile(fileId, { name });
    },
    {
      onSuccess: async (data, { fileId }, context) => {
        await queryClient.invalidateQueries(['files', fileId]);
        await queryClient.invalidateQueries(['files', data.parent, 'children']);
      },
    },
  );
}

export function useUpdateFileMutation<T = Core.VirtualFile>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ fileId, update }: { fileId: string; update: FileUpdate<T> }) => {
      return await API.files.updateFile(fileId, update);
    },
    {
      onSuccess: (data, { fileId }, context) => {
        queryClient.setQueryData(['files', fileId], (old: Core.NormalFile) => {
          if (!old) return old;
          return data;
        });
        queryClient.setQueryData(['files', data.parent, 'children'], (old: { children: Core.NormalFile[] }) => {
          if (!old) return old;
          return { ...old, children: old.children.map((file) => (file.id === fileId ? data : file)) };
        });
      },
    },
  );
}

export function useUpdatePhysicalFileMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ fileId, data }: { fileId: string; data: Blob | Uint8Array | ArrayBuffer }) => {
      return await API.files.updateNormalFile(fileId, data);
    },
    {
      onSuccess: (data, { fileId, data: fileData }, context) => {
        const url = URL.createObjectURL(fileData);

        queryClient.setQueryData(['files', fileId], (old: Core.NormalFile) => {
          if (!old) return old;
          old.downloadUrl = Promise.resolve(url);
          return { ...old };
        });
        queryClient.setQueryData(['files', data.parent, 'children'], (old: { children: Core.NormalFile[] }) => {
          if (!old) return old;

          return { ...old, children: old.children.map((file) => (file.id === fileId ? { ...file, downloadUrl: url } : file)) };
        });
      },
    },
  );
}

export function useDeleteFileMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (file: Core.VirtualFile) => {
      return await API.files.deleteFile(file.id);
    },
    {
      onSuccess: async (data, file, context) => {
        await queryClient.invalidateQueries(['files', file.id]);
        queryClient.setQueryData(['files', file.parent, 'children'], (old: { children: Core.VirtualFile[]; fileCount: number; folderCount: number }) => {
          if (!old) return old;
          const newData = { ...old };
          if (file.type === 'folder') newData.folderCount--;
          else newData.fileCount--;
          newData.children = old.children.filter((f) => f.id != file.id);
          return newData;
        });
      },
    },
  );
}

export function useCreateSIDocumentMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ parent, ...other }: { parent: string } & VirtualFileCreationBase) => {
      const file = await API.files.createSIDocument(parent, other);
      return file;
    },
    {
      onSuccess: (file, _, context) => {
        queryClient.setQueryData(
          ['files', file.parent, 'children'],
          (old: { children: Core.VirtualFile[]; fileCount: number; folderCount: number }) => old && { ...old, children: [...old.children, file], fileCount: old.fileCount + 1 },
        );
      },
    },
  );
}

export function useCreateFolderMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ parent, ...other }: { parent: string } & VirtualFileCreationBase) => {
      return await API.files.createFolder(parent, other);
    },
    {
      onSuccess: (file, _, context) => {
        // queryClient.invalidateQueries(['files', file.parent, "children"])
        queryClient.setQueryData(
          ['files', file.parent, 'children'],
          (old: { children: Core.VirtualFile[]; fileCount: number; folderCount: number }) => old && { ...old, children: [...old.children, file], folderCount: old.folderCount + 1 },
        );
      },
    },
  );
}

export function useMoveFilesMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updates: { fileId: string; parent: string; oldParent: string }[]) => {
      await API.files.batchUpdateFiles(updates.map((u) => ({ fileId: u.fileId, update: { parent: u.parent } })));
    },
    {
      onSuccess: async (data, updates, context) => {
        for (const { parent, fileId, oldParent } of updates) {
          await queryClient.invalidateQueries(['files', fileId]);
          await queryClient.invalidateQueries(['files', oldParent, 'children']);
          await queryClient.invalidateQueries(['files', parent, 'children']);
        }
      },
    },
  );
}

export function useCreatePhysicalFileMutation<T extends Partial<Omit<Core.VirtualFile, 'id' | 'trashed'>>>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ data, type, parent, permissions, ...other }: { data: Blob | Uint8Array | ArrayBuffer } & Partial<T>) => {
      return await API.files.createNormalFile(parent, data, permissions, type, other);
    },
    {
      onSuccess: async (data, { data: fileData }, context) => {
        queryClient.setQueryData(['files', data.id], (old: Core.NormalFile) => {
          return data;
        });
        if (!data.parent) {
          await queryClient.invalidateQueries(['files', null, 'children']);
        } else {
          queryClient.setQueryData(['files', data.parent, 'children'], (old: { children: Core.NormalFile[]; fileCount: number; folderCount: number }) => {
            if (!old) return old;
            if (data.type === 'folder') old.folderCount++;
            else old.fileCount++;
            old.children = [...old.children, data];
            return { ...old };
          });
        }
      },
    },
  );
}
