import { Grid, MenuItem, Select, Stack, Typography } from '@material-ui/core';
import { useAutoUpdateState } from 'libs/editors/form-editor/src/lib/useAutoUpdateState';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetGroups } from '../hooks/useGetGroups';
import { UserGroupRightsSelect } from './UserGroupRightsSelect';
// import { containsRight } from './containsRight';

function allEqual<T>(...params: T[]) {
  for (let i = 1; i < params.length; i++) {
    if (params[i - 1] !== params[i]) return false;
  }
  return true;
}

export function GroupRightsEditControl({ setRights, rights, groups }: { setRights: (rights: Partial<Core.Rights>) => void; rights: Core.Rights; groups: string[] }) {
  const { t } = useTranslation();
  const [advancedMode, setAdvancedMode] = useState(false);

  const { data: groupList = [] } = useGetGroups();

  const selectableGroups = groups.map((groupId) => groupList.find((g) => g.id == groupId)).filter((g) => g);
  const [selectedGroup, setSelectedGroup] = useAutoUpdateState(selectableGroups[0]?.id ?? null, [groupList, groups]);

  const changeRight = (right: Core.GroupRightsKeys, add: boolean) => {
    let newGroupRights = rights.groupRights?.[right] ?? [];
    newGroupRights = newGroupRights.filter((gid) => gid != selectedGroup);
    if (add) {
      newGroupRights = [...newGroupRights, selectedGroup];
    }
    setRights({ ...rights, groupRights: { ...(rights.groupRights ?? {}), [right]: newGroupRights } as Core.GroupRights });
  };

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12} md={6} marginTop={2}>
        <Stack spacing={2}>
          <Typography variant="overline">{t('group-name')}</Typography>
          <Select fullWidth variant="outlined" value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)}>
            {selectableGroups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Grid>
      <UserGroupRightsSelect value={rights.groupRights} groupId={selectedGroup} setFieldValue={(_, gr) => setRights({ ...rights, groupRights: gr })} />
      {/* <Field
        as={GridCheckboxElement}
        checked={containsRight(rights.groupRights, selectedGroup, 'user_create')}
        onChange={(e) => changeRight('user_create', e.target.checked)}
        type="checkbox"
        label={t('can-create-users')}
      />
      <Field
        as={GridCheckboxElement}
        checked={containsRight(rights.groupRights, selectedGroup, 'user_edit')}
        onChange={(e) => changeRight('user_edit', e.target.checked)}
        type="checkbox"
        label={t('can-edit-users')}
      />
      <Field
        as={GridCheckboxElement}
        checked={containsRight(rights.groupRights, selectedGroup, 'user_delete')}
        onChange={(e) => changeRight('user_delete', e.target.checked)}
        type="checkbox"
        label={t('can-delete-users')}
      />
      <Field
        as={GridCheckboxElement}
        checked={containsRight(rights.groupRights, selectedGroup, 'user_right_edit')}
        onChange={(e) => changeRight('user_right_edit', e.target.checked)}
        type="checkbox"
        label={t('can-edit-user-rights')}
      />
      <Field
        as={GridCheckboxElement}
        checked={containsRight(rights.groupRights, selectedGroup, 'group_admin')}
        onChange={(e) => changeRight('group_admin', e.target.checked)}
        type="checkbox"
        label="Can submit applications"
      />
      <Field
        as={GridCheckboxElement}
        checked={containsRight(rights.groupRights, selectedGroup, 'can_submit_applications')}
        onChange={(e) => changeRight('can_submit_applications', e.target.checked)}
        type="checkbox"
        label="Group Admin"
      /> */}
    </Grid>
  );
}
