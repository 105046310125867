import { Campaign } from '@base/core';
import { SimpleFileProvider } from '@base/firebase';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useGetFile } from '../../campaign/hooks/useGetFile';
import { useGetForm } from '../../campaign/hooks/useGetForm';
import { FormSubmissionViewer } from '../FormSubmissionViewer';
import { useSetFormContentMutation, useSubmitFormContentMutation } from '../hooks/mutations';
import { useGetFormContent } from '../hooks/useGetFormContent';

export function CampaignFormEditorWrapper({ match }) {
  const readFileProvider = useMemo(() => new SimpleFileProvider('/campaigns/forms/provided_files'), []);
  const writeFileProvider = useMemo(() => new SimpleFileProvider('/campaigns/forms/response_files'), []);
  const { user } = useSelector((state: Core.StateType) => state.auth);

  const { parentId, formId } = useParams<{ parentId: string; formId: string }>();

  const { data: campaign } = useGetFile(parentId);
  const { data: form } = useGetForm(parentId, formId);
  const { data: formContent, isLoading: isFormContentLoading } = useGetFormContent(parentId, formId, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 3600 * 3 * 1000,
    staleTime: 3600 * 3 * 1000,
  });

  const history = useHistory();

  const mutation = useSetFormContentMutation(parentId, formId);
  const submitMutation = useSubmitFormContentMutation(parentId, formId, user.id);

  return (
    <FormSubmissionViewer
      fileProvider={readFileProvider}
      campaign={campaign as Campaign}
      fileUploadProvider={writeFileProvider}
      isLoading={!form || isFormContentLoading}
      campaignName={campaign?.name}
      value={useMemo(() => formContent, [formContent != undefined, formId])}
      onClose={() => {
        history.goBack();
      }}
      onSave={(data) => mutation.mutateAsync(data)}
      onSubmit={(data, initialSubmit) => submitMutation.mutateAsync({ ...data, initialSubmit })}
      onDelete={async (formId: string) => {
        history.goBack();
      }}
      form={form}
    />
  );
}
