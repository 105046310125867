import '../../../css/components/common/content/search/search.scss';

import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface TimeSettingsProps {
  popupVisible: boolean;
  onButtonClick: () => void;
}

function cssClasses(conditionalNames: { [className: string]: boolean }, ...otherClassnames: string[]) {
  const classNames = [
    ...otherClassnames,
    ...Object.entries(conditionalNames)
      .filter(([className, isClassNameActive]) => isClassNameActive)
      .map(([className, isClassNameActive]) => className),
  ];
  return classNames.join(' ');
}

export const TimeSettings = ({ popupVisible, onButtonClick }: TimeSettingsProps) => {
  return (
    <div onClick={onButtonClick} className="filter-btn-con shadow time">
      <div
        className={cssClasses(
          {
            visible: popupVisible,
          },
          'time-settings-con',
        )}
      >
        <div className="time-text bg">10.02.2020</div>
        <div className="time-text">bis</div>
        <div className="time-text bg">Heute</div>
      </div>
      <div className="clock-con">
        <FontAwesomeIcon className="search-filter-img time" icon={faClock} style={{ color: '#fff', width: 60 }} />
      </div>
    </div>
  );
};
