import { TextField } from '@material-ui/core';
import { FieldProps } from 'formik';
import React from 'react';

type gridInputElementProps = {
  placeholder: string;
  value: string;
  onText?: (text: string) => void;
  onChange?: (event: any) => void;
  /**
   * @deprecated This will soon be removed
   */
  paddingVertical?: boolean;
  color?: string;
  inputType?: 'tel' | 'text' | 'url' | 'password' | 'email' | 'date' | 'number' | 'color';
} & Partial<FieldProps>;

export const GridInputElement = ({ value, placeholder, onText, onChange, color = '#6D6D6D', inputType, ...props }: gridInputElementProps) => (
  // <div className="grid-con">
  //   <div className="input input-container" style={{ display: 'flex' }}>
      <TextField
        type={inputType ? inputType : 'text'}
        variant="filled"
        placeholder={placeholder}
        value={value}
        onChange={(e) => { onText?.(e.target.value); onChange?.(e)}}
        autoComplete="off"
        {...props}
      />
  //   </div>
  // </div>
);
