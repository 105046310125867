export function containsRight(rights: Core.GroupRights, groupId: string, ...keys: Core.GroupRightsKeys[]) {
  if (rights == undefined) {
    if (process.env.NODE_ENV === "development") console.trace("Rights is undefined");
    return false;
  }
  for (const key of keys) {
    if (rights[key] && rights[key].includes(groupId))
      return true;
  }
  return false;
}
