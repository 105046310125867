import { Box, Button, Checkbox, Dialog, FormControl, FormHelperText, FormLabel, IconButton, Stack, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import React, { MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faFilePlus } from '@fortawesome/pro-regular-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf, faSignIn, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FormSubmission, Group } from '@base/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoadingPromiseButton, usePopup, useSettings } from '@base/web';
import { useGetUsers } from '../../users/hooks/useGetUsers';
import { format } from 'date-fns';
import { StaticDatePicker } from '@material-ui/lab';
import { useSendESignAgreementMutation } from '../hooks';
import { useGetGroups } from '../../users/hooks/useGetGroups';
import { EmailAutocompleteInput, TSelectableUser, UserSelectInput } from '../../../components/users';

const MAXIMUM_FILE_SIZE = 10 * 1000 * 1000; //10MB

function AgreementFile({ setSelectedFile, selectedFile }: { setSelectedFile: (file: File) => void; selectedFile: File }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [fileError, setFileError] = useState<string | undefined>(undefined);

  const onDrop = (acceptedFiles: File[]) => {
    console.log(acceptedFiles);
    if (acceptedFiles[0].type != 'application/pdf') {
      setFileError('Only PDF files allowed' ?? t('onlyPDFAllowed'));
      return;
    } else if (acceptedFiles[0].size > MAXIMUM_FILE_SIZE) {
      setFileError('File exceeds 10MB!' ?? t('fileToBigMax10MB'));
      return;
    }
    setFileError(undefined);
    setSelectedFile(acceptedFiles[0]);
  };
  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });
  const { onClick, ...DnDRootProps } = getRootProps();

  const uploadFileFromLokalFilespace = () => {
    const files: (File & { path: string })[] = [];
    const fileList = fileInputRef.current.files as FileList;
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      file['path'] = file.name;
      files.push(file as File & { path: string });
    }
    onDrop(files);
  };

  return (
    <>
      <input style={{ display: 'none' }} ref={fileInputRef} type="file" name="filePicker" id="filepicker" accept="application/pdf" onChange={uploadFileFromLokalFilespace} />
      <div
        style={{
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: isDragActive ? 99 : 0,
          backgroundColor: 'transparent',
        }}
        {...DnDRootProps}
      ></div>
      <input {...getInputProps()} />
      {isDragActive && (
        <div
          style={{
            backgroundColor: '#000a',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
            zIndex: 999,
            color: 'white',
            borderRadius: 10,
          }}
        >
          <FontAwesomeIcon icon={faFilePlus} style={{ fontSize: 50, color: '#fff' }} />
          <h1 style={{ color: '#fff', fontSize: 30 }}>{t('drop-here')}</h1>
        </div>
      )}
      <Stack spacing={1}>
        <Stack sx={{ backgroundColor: theme.palette.grey.A200, p: 1.5, borderRadius: 1, border: `2px dashed ${theme.palette.grey.A400}` }}>
          <Stack direction={'row'} spacing={2}>
            <Stack alignItems={'center'} justifyContent={'center'} style={{ width: 142, borderRadius: 8, backgroundColor: theme.palette.grey.A700 }}>
              <div style={{ width: 64, height: 64, borderRadius: 999, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faFilePdf} style={{ width: 28, height: 28 }} color={theme.palette.error.main} />
              </div>
            </Stack>
            <Stack spacing={1} alignItems={'flex-start'} py={0.5}>
              <Stack spacing={0.25}>
                <FormLabel component="legend" sx={{ color: theme.palette.text.primary }}>
                  Agreement Document
                </FormLabel>
                <FormHelperText hidden={Boolean(selectedFile)}>Choose a file from explorer or drag and drop</FormHelperText>
              </Stack>
              <Button
                onClick={() => fileInputRef.current.click()}
                startIcon={<FontAwesomeIcon icon={faFilePlus} fixedWidth style={{ height: 20 }} />}
                variant={selectedFile ? 'outlined' : 'contained'}
              >
                {selectedFile ? 'selectNew' : 'selectDoc'}
              </Button>
            </Stack>
          </Stack>
          <FormHelperText hidden={Boolean(!fileError)} error>
            {fileError}
          </FormHelperText>
          {selectedFile && (
            <Stack
              sx={{
                bgcolor: theme.palette.background.grayDark,
                color: theme.palette.grey[800],
                justifyContent: 'space-between',
                overflowWrap: 'anywhere',
                borderRadius: 1,
                p: 1,
                alignItems: 'center',
                flexDirection: 'row',
                mt: 1,
              }}
            >
              <Stack
                sx={{
                  overflowWrap: 'anywhere',
                  textAlign: 'left',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FontAwesomeIcon style={{ fontSize: 15, marginRight: 8 }} icon={faFile} />
                <Typography variant={'body1'}>{selectedFile.name}</Typography>
              </Stack>
              <Tooltip title={t('remove')}>
                <IconButton sx={{ alignItems: 'center', justifyContent: 'center', mt: 0 }} size={'small'} onClick={() => setSelectedFile(null)}>
                  <FontAwesomeIcon style={{ fontSize: 15, color: theme.palette.error.main }} icon={faTrash} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}

function DeadlineSelector({ deadline, setDeadline }: { deadline: number; setDeadline: (deadline: number) => void }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
        <Button
          variant="outlined"
          size="medium"
          startIcon={<FontAwesomeIcon icon={faClock} fixedWidth style={{ height: 24 }} />}
          sx={{ px: 2, height: 46, width: '100%' }}
          onClick={() => {
            setDatePickerOpen(true);
          }}
        >
          {deadline ? format(new Date(deadline as number), t('full-date-mask')) : 'select deadline'}
        </Button>
        {deadline && (
          <Tooltip title={t('remove')}>
            <IconButton onClick={() => setDeadline(undefined)} sx={{ alignSelf: 'flex-end' }}>
              <FontAwesomeIcon icon={faTrash} fixedWidth style={{ height: 24 }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <FormHelperText hidden={Boolean(deadline)}>Select a deadline to limit the signing access and send a reminder 7 days before</FormHelperText>
      <Dialog
        open={datePickerOpen}
        onClose={() => {
          setDatePickerOpen(false);
        }}
        PaperComponent={Box}
        PaperProps={{ sx: { borderRadius: 1 } }}
      >
        <StaticDatePicker
          value={deadline}
          mask={t('full-date-mask')}
          disablePast
          open={datePickerOpen}
          onChange={(newValue: Date) => {
            setDeadline(newValue.valueOf());
            setDatePickerOpen(false);
          }}
          renderInput={(params) => {
            return <TextField {...params} />;
          }}
        />
        <Button variant="contained" color="error" onClick={() => setDatePickerOpen(false)}>
          {t('close')}
        </Button>
      </Dialog>
    </>
  );
}

interface AgreementUploadProps {
  uid: string;
  formSubmission: FormSubmission;
  onSubmitted: () => void;
}

export function AgreementUpload({ uid, formSubmission, onSubmitted }: AgreementUploadProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.auth);
  const [recipients, setRecipients] = useState([]);
  const [externalEmails, setExternalEmails] = useState<string[]>([]);
  const [deadline, setDeadline] = useState(undefined);
  const [agreementName, setAgreementName] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const sendAgreementMutation = useSendESignAgreementMutation();
  const notify = usePopup();

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  const { data: userData = [], isLoading: usersLoading } = useGetUsers();
  const { data: groupsList = [], isLoading: groupsLoading } = useGetGroups();

  const settings = useSettings();

  const taggedUsers = useMemo(() => {
    const listableUsers = userData.map((user) => {
      const selectableUser = user as TSelectableUser;
      const group = groupsList.find((g) => user.groups[g.id]);
      selectableUser.groupName = group?.name;
      const namedGroup = settings.namedGroups.find((namedGroup) => namedGroup?.name === group?.type);
      selectableUser.icon = namedGroup?.getIcon && namedGroup.getIcon(group as Group);
      return selectableUser;
    });
    return listableUsers;
  }, [groupsList, settings.namedGroups, userData]);

  const sortedUsers = useMemo(() => {
    // sort users from the nf first then campaing users
    const assignedGroup = groupsList.find((g) => g.id === formSubmission?.entityId);
    const filteredUsers = taggedUsers.filter((u) => u.groups[assignedGroup.id]);
    // merge filtered users with the rest of the users with the filtered users first
    const users = [...filteredUsers, ...taggedUsers.filter((u) => !filteredUsers.find((f) => f.email === u.email))];
    // Every user should have a ununique id, sort out duplicates
    const uniqueUsers = users.reduce((acc, user) => {
      if (!acc.find((u) => u.id === user.id)) {
        acc.push(user);
      }
      return acc;
    }, [] as TSelectableUser[]);
    return uniqueUsers;
  }, [groupsList, taggedUsers, formSubmission?.entityId]);

  const startAgreement = useCallback(async () => {
    const mergedRecipients = [...recipients, ...externalEmails.map((email) => ({ email }))];
    try {
      await sendAgreementMutation.mutateAsync({
        userId: user.id,
        selectedFile: selectedFile,
        agreementName: agreementName,
        formSubmission: formSubmission,
        deadline: deadline,
        recipients: mergedRecipients,
        sendDraft: isDraft,
      });
      notify({
        type: 'success',
        title: 'Agreement sent' ?? t('agreement-sent'),
        text: 'Agreement sucessfully sent' ?? t('agreement-sent-text'),
      });
      onSubmitted();
    } catch (error) {
      notify({
        type: 'error',
        title: 'Agreement send out failed' ?? t('agreement-sent-failed'),
        text: error?.message ?? error,
      });
    }
  }, [sendAgreementMutation, user.id, selectedFile, agreementName, formSubmission, deadline, recipients, externalEmails, isDraft, onSubmitted]);

  // The agreement starting process
  return (
    <FormControl fullWidth>
      <AgreementFile selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
      <Stack spacing={1.5} mt={1.5}>
        <Stack spacing={0.75}>
          <Typography variant={'subtitle1'} fontWeight={500}>
            {'Agreement name' ?? t('agreementName(Seen by NF)')}
          </Typography>
          <TextField
            required
            id="outlined-required"
            placeholder={'Enter name here'}
            defaultValue="Contract"
            variant={'outlined'}
            value={agreementName}
            onChange={(e) => setAgreementName(e.target.value)}
          />
        </Stack>
        <Stack spacing={0.75}>
          <Typography variant={'subtitle1'} fontWeight={500}>
            {'Send to' ?? t('sendTo')}
          </Typography>
          <FormHelperText>Assign users within IBU Scope:</FormHelperText>
          <UserSelectInput setSelectedUsers={setRecipients} groups={[]} users={sortedUsers} selectedUsers={recipients} preselectedUsers={[]} loading={groupsLoading || usersLoading} />
          <FormHelperText>Assign external people via their email:</FormHelperText>
          <EmailAutocompleteInput setEmails={setExternalEmails} emails={externalEmails} />
        </Stack>
        <Stack spacing={0.75}>
          <Typography variant={'subtitle1'} fontWeight={500}>
            {t('deadline')}
          </Typography>
          <DeadlineSelector deadline={deadline} setDeadline={setDeadline} />
        </Stack>
        <Stack spacing={0.75}>
          <Typography variant={'subtitle1'} fontWeight={500}>
            Send as Draft
          </Typography>
          <Stack direction={'row'}>
            <Checkbox checked={isDraft} onChange={(e) => setIsDraft(e.target.checked)} sx={{ width: 'fit-content' }} />
            <FormHelperText>Agreements in Draft are not send out and can be edited in the Adobe Console before sending out</FormHelperText>
          </Stack>
        </Stack>
        <LoadingPromiseButton
          onClick={startAgreement}
          color={'success'}
          variant={'contained'}
          disabled={(recipients.length === 0 && externalEmails.length === 0) || !selectedFile || agreementName.length === 0}
          startIcon={<FontAwesomeIcon icon={faSignIn} />}
        >
          {'Start signing' ?? t('startSigning')}
        </LoadingPromiseButton>
        <FormHelperText hidden={Boolean((recipients.length > 0 || externalEmails.length > 0) && !!selectedFile && agreementName.length > 0)} error>
          {'Missing fields' ?? t('recipientsOrDocumentMissing!')}
        </FormHelperText>
      </Stack>
    </FormControl>
  );
}
