import * as React from 'react';

function SvgAlignLeftRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.514 20.746" ref={svgRef} {...props}>
      <path d="M.616 14.6h12.6a.616.616 0 00.616-.616V12.91a.616.616 0 00-.616-.616H.616A.616.616 0 000 12.91v1.073a.616.616 0 00.616.616zm0-12.294h12.6a.616.616 0 00.616-.616V.616A.616.616 0 0013.214 0H.616A.616.616 0 000 .616v1.073a.616.616 0 00.616.616zm20.129 3.842H.768A.768.768 0 000 6.915v.768a.768.768 0 00.768.768h19.978a.768.768 0 00.768-.768v-.768a.768.768 0 00-.768-.768zm0 12.294H.768a.768.768 0 00-.768.767v.768a.768.768 0 00.768.768h19.978a.768.768 0 00.768-.768v-.768a.768.768 0 00-.768-.769z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAlignLeftRegular);
export default ForwardRef;
