import { ESignAgreement, FormSubmission, IFormService } from '@base/core';
import { Form } from '@editors/form-editor';
import type firebasetype from 'firebase/app';
import { firebase } from '../config';

export class FormService implements IFormService {
  private getFormCollection(campaignId: string) {
    return firebase.firestore().collection(`campaign_files/${campaignId}/forms`);
  }
  private getFormContentCollection(campaignId: string) {
    return firebase.firestore().collection(`campaign_files/${campaignId}/form_submissions`);
  }
  private getFormContentSubmissionHistoryCollection(campaignId: string, submissionId: string) {
    return firebase.firestore().collection(`campaign_files/${campaignId}/form_submissions/${submissionId}/history`);
  }

  async createForm(campaignId: string, form: Form): Promise<Form> {
    await this.getFormCollection(campaignId).doc(form.id).set(form);
    return form;
  }

  async updateForm(campaignId: string, formId: string, form: Partial<Form>): Promise<Form> {
    await this.getFormCollection(campaignId).doc(formId).update(form);
    return await this.getForm(campaignId, formId);
  }

  async deleteForm(campaignId: string, formId: string): Promise<void> {
    await this.getFormCollection(campaignId).doc(formId).delete();
  }

  async getForms(campaignId: string): Promise<Form[]> {
    const sn = await this.getFormCollection(campaignId).get();
    return sn.docs.map(this.getFormFromSn) as Form[];
  }

  async getForm(campaignId: string, formId: string): Promise<Form> {
    const sn = await this.getFormCollection(campaignId).doc(formId).get();
    if (!sn.exists) throw new Error(`Form with id: ${formId} in campaign ${campaignId} does not exist!`);

    const reportDocs = await this.getFormCollection(campaignId).where('parent', '==', formId).where('published', '==', true).orderBy('position').get();
    const reports = reportDocs.docs.map((d) => this.getFormFromSn(d));
    return { ...this.getFormFromSn(sn), reports } as any;
  }

  private getFormFromSn(sn: firebasetype.firestore.DocumentSnapshot): Form {
    return { ...(sn.data() as any), id: sn.id };
  }

  async getFormContent(campaignId: string, formId: string, storageid: string): Promise<FormSubmission> {
    const sn = await this.getFormContentCollection(campaignId).doc(`${formId}:${storageid}`).get();
    if (!sn.exists) throw new Error(`Formcontent with id: ${formId} in campaign ${campaignId} does not exist!`);
    const submission = sn.data() as FormSubmission;
    if (submission?.timestamp && ((submission.timestamp as any) as firebasetype.firestore.Timestamp).toDate) {
      submission.submissionDate = ((submission.timestamp as any) as firebasetype.firestore.Timestamp).toDate().valueOf();
    } else if (submission?.submissionDate && ((submission.submissionDate as any) as firebasetype.firestore.Timestamp).toDate) {
      submission.submissionDate = ((submission.submissionDate as any) as firebasetype.firestore.Timestamp).toDate().valueOf();
    }

    const agreementIds = Object.keys(submission?.eSignAgreements ?? {});
    agreementIds.map(async (agreementId) => {
      if (submission?.eSignAgreements?.[agreementId]?.storagePath) {
        submission.eSignAgreements[agreementId].downloadUrl = await firebase.storage().ref(submission.eSignAgreements[agreementId].storagePath).getDownloadURL();
      }
    });

    return { ...submission, id: sn.id };
  }

  async getFormContents(campaignId: string): Promise<FormSubmission[]> {
    const sn = await this.getFormContentCollection(campaignId).get();
    const submissions = sn.docs.map((d) => {
      const submission = d.data() as FormSubmission;
      if (submission.timestamp && ((submission.timestamp as any) as firebasetype.firestore.Timestamp).toDate) {
        submission.submissionDate = ((submission.timestamp as any) as firebasetype.firestore.Timestamp).toDate().valueOf();
      } else if (submission?.submissionDate && ((submission.submissionDate as any) as firebasetype.firestore.Timestamp).toDate) {
        submission.submissionDate = ((submission.submissionDate as any) as firebasetype.firestore.Timestamp).toDate().valueOf();
      }

      const agreementIds = Object.keys(submission?.eSignAgreements ?? {});
      agreementIds.map(async (agreementId) => {
        if (submission?.eSignAgreements?.[agreementId]?.storagePath) {
          submission.eSignAgreements[agreementId].downloadUrl = await firebase.storage().ref(submission.eSignAgreements[agreementId].storagePath).getDownloadURL();
        }
      });

      return { ...submission, id: d.id };
    });
    return submissions;
  }

  async setFormContent(campaignId: string, formId: string, storageId: string, content: FormSubmission): Promise<FormSubmission> {
    await this.getFormContentCollection(campaignId)
      .doc(`${formId}:${storageId}`)
      .set({ ...content, campaignId, formId, entityId: storageId } as FormSubmission);
    return { ...content, campaignId, formId, entityId: storageId } as FormSubmission;
  }

  async deleteFormContent(campaignId: string, formId: string, storageId: string): Promise<void> {
    await this.getFormContentCollection(campaignId).doc(`${formId}:${storageId}`).delete();
  }

  async updateFormContent(campaignId: string, formId: string, storageId: string, content: Partial<FormSubmission>): Promise<void> {
    const submissionId = storageId ? `${formId}:${storageId}` : formId;
    await this.getFormContentCollection(campaignId)
      .doc(submissionId)
      .update({ ...content, campaignId, formId, entityId: storageId } as FormSubmission);
  }

  async setAgreement(campaignId: string, formId: string, storageId: string, agreementId: string, content: Partial<ESignAgreement>): Promise<void> {
    const submissionId = storageId ? `${formId}:${storageId}` : formId;
    await this.getFormContentCollection(campaignId)
      .doc(submissionId)
      .update({ [`eSignAgreements.${agreementId}`]: { ...content } });
  }

  async deleteAgreement(campaignId: string, formId: string, storageId: string, agreementId: string): Promise<void> {
    const submissionId = storageId ? `${formId}:${storageId}` : formId;
    await this.getFormContentCollection(campaignId)
      .doc(submissionId)
      .update({ [`eSignAgreements.${agreementId}`]: firebase.firestore.FieldValue.delete() });
  }

  async createFormSubmission(campaignId: string, formId: string, storageId: string, submitterId: string, content: FormSubmission): Promise<FormSubmission> {
    const submissionId = storageId ? `${formId}:${storageId}` : formId;
    try {
      let update = {};
      if (content.initialSubmit) {
        update = { ...content, campaignId, formId, entityId: storageId, submitterId: submitterId, timestamp: firebase.firestore.FieldValue.serverTimestamp() } as FormSubmission;
      } else {
        update = { ...content, campaignId, formId, entityId: storageId, lastUpdatedSubmitterId: submitterId, lastUpdatedTimestamp: firebase.firestore.FieldValue.serverTimestamp() } as FormSubmission;
      }
      await this.getFormContentCollection(campaignId).doc(submissionId).update(update);
    } catch (error) {
      await this.getFormContentCollection(campaignId)
        .doc(submissionId)
        .set({ ...content, campaignId, formId, entityId: storageId, submitterId, timestamp: firebase.firestore.FieldValue.serverTimestamp() } as FormSubmission);
    }

    await this.getFormContentSubmissionHistoryCollection(campaignId, `${formId}:${storageId}`).add({
      ...content,
      campaignId,
      formId,
      submitterId,
      entityId: storageId,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    } as FormSubmission);
    return { ...content, campaignId, formId, entityId: storageId, submitterId } as FormSubmission;
  }

  async getFormSubmissionHistory(campaignId: string, formSubmissionId: string): Promise<(FormSubmission & { timestamp: Date })[]> {
    const sn = await this.getFormContentSubmissionHistoryCollection(campaignId, formSubmissionId).get();
    const submissions = sn.docs.map((d) => {
      const s = d.data() as FormSubmission & { timestamp: Date };
      if (s?.submissionDate && ((s.submissionDate as any) as firebasetype.firestore.Timestamp).toDate) {
        s.submissionDate = ((s.submissionDate as any) as firebasetype.firestore.Timestamp).toDate().valueOf();
      }
      s.timestamp = (s.timestamp as any).toDate();
      return { ...s, id: d.id };
    });
    return submissions;
  }
}
