import * as React from 'react';

function SvgKlickNote(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="216" height="100" viewBox="0 0 377.276 174.19">
      <g id="Gruppe_29466" data-name="Gruppe 29466" transform="translate(-1452 -274)">
        <rect id="Rechteck_1904" data-name="Rechteck 1904" width="341" height="131" rx="16" transform="translate(1452 274)" fill="#efefef" />
        <path
          id="file-edit-light"
          d="M53.109,13.971,41.063,1.924A6.892,6.892,0,0,0,36.2-.1H6.892A6.915,6.915,0,0,0,0,6.806V66.534a6.893,6.893,0,0,0,6.892,6.892h41.35a6.893,6.893,0,0,0,6.892-6.892V18.852A6.925,6.925,0,0,0,53.109,13.971ZM49.864,17.23a2.259,2.259,0,0,1,.6,1.062H36.756V4.581a2.259,2.259,0,0,1,1.062.6Zm-1.622,51.6H6.892a2.3,2.3,0,0,1-2.3-2.3V6.806a2.3,2.3,0,0,1,2.3-2.3h25.27V19.441a3.438,3.438,0,0,0,3.446,3.446H50.539V66.534A2.3,2.3,0,0,1,48.242,68.832ZM31.472,35.407l4.25,4.25a.641.641,0,0,1,0,.919L19.584,56.714l-4.322.617A1.3,1.3,0,0,1,13.8,55.866l.617-4.322L30.553,35.407a.641.641,0,0,1,.919,0Zm9.246.172-2.355,2.355a.641.641,0,0,1-.919,0l-4.25-4.25a.641.641,0,0,1,0-.919L35.55,30.41a2.141,2.141,0,0,1,3.044,0l2.125,2.125A2.141,2.141,0,0,1,40.719,35.579Z"
          transform="translate(1479 302.836)"
          fill="#d5d5d5"
        />
        <rect id="Rechteck_1905" data-name="Rechteck 1905" width="130" height="14" rx="7" transform="translate(1558 311)" fill="#6d6d6d" opacity="0.2" />
        <rect id="Rechteck_1906" data-name="Rechteck 1906" width="210" height="14" rx="7" transform="translate(1558 336)" fill="#6d6d6d" opacity="0.2" />
        <g id="Gruppe_29043" data-name="Gruppe 29043" transform="translate(1756.724 361.81)">
          <path
            id="Pfad_29518"
            data-name="Pfad 29518"
            d="M-5.373,0,43.252,21.988l5.373,9.058L36.159,36.39,47.006,48.37l-5.5,6.476-6.638,2.914L23.045,45.78,14.787,57.761,6.163,51.033Z"
            transform="translate(11.344 15.552)"
            fill="#fff"
          />
          <path
            id="mouse-pointer-regular"
            d="M50.433,36.966,15.609,2.7A9.151,9.151,0,0,0,0,9.093V59.588a9.148,9.148,0,0,0,15.536,6.469L19,62.665l2.536,5.882.018.041.018.04A9.168,9.168,0,0,0,33.452,73.35l6.061-2.516.037-.015.037-.016a9.1,9.1,0,0,0,4.9-5.026,8.979,8.979,0,0,0-.1-6.933L41.68,52.564h2.39c8.078,0,12.318-9.905,6.363-15.6Zm-6.346,8.655H31.129L38.015,61.6a2.144,2.144,0,0,1-1.165,2.82l-6.064,2.518A2.221,2.221,0,0,1,27.909,65.8L21.365,50.624,10.677,61.1a2.2,2.2,0,0,1-3.734-1.511V9.093a2.2,2.2,0,0,1,3.734-1.511L45.629,41.962A2.14,2.14,0,0,1,44.087,45.621Z"
            transform="translate(0 15.562) rotate(-17)"
            fill="#6d6d6d"
          />
        </g>
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgKlickNote);
export default ForwardRef;
