import '../../../css/components/common/dropdowns/SetPropertyDropdown.scss';

import React from 'react';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles, makeStyles, WithStyles } from '@material-ui/styles';

import type { ReactChildrenProp } from '../../../lib/ReactProps';

import InputBase from '@material-ui/core/InputBase';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel } from '@material-ui/core';
import { DefaultTheme } from '../../../theme/Theme';
import { inherits } from 'util';

interface SetPropertyDropdownProps extends SelectProps {
  value?: React.ComponentState;
  children?: ReactChildrenProp;
  onChange: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => void;
}

export const DefaultDropDown = withStyles((theme) => ({
  root: {
    backgroundColor: '#EFEFEF',
    borderRadius: 20,
  },
  input: {
    padding: '5px 10px !important',
  },
}))(InputBase);

export const DefaultInputBase = withStyles((theme) => ({
  root: {
    backgroundColor: '#EFEFEF',
    borderRadius: 10,
    height: '100%',
  },
  input: {
    padding: '5px 16px !important',
  },
}))(InputBase);

export const DefaultTextInputBase = withStyles((theme) => ({
  root: {
    backgroundColor: '#EFEFEF',
    borderRadius: 10,
    height: '100%',
  },
  input: {
    // padding: '5px 16px !important',
    padding: '25px 12px 8px !important',
  },
}))(InputBase);
