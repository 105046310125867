import { Group } from '@base/core';
import { faEdit, faEye, faLayerPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, AvatarGroup, Box, Button, Stack, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, XGrid } from '@material-ui/x-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalRender, UserAvatarHandler } from '../../components/common';
import { ContentHeader } from '../../components/common/ContentHeader';
import { SearchInput } from '../../components/inputs';
import { LoadingPromiseButton } from '../../components/LoadingPromiseButton';
import { GroupPopup } from './popups';
import { GridNoResultsOverlay, GridNoRowsOverlay } from '../../components/content/GridContent';

function columns(userList: Core.User[], onSaveGroup: (group: Group, users: { added: string[]; removed: string[] }) => Promise<void> | void): GridColDef[] {
  const { t } = useTranslation();
  return [
    {
      field: 'name',
      width: 275,
      headerName: t('group-name'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'users',
      width: 300,
      headerName: t('involved-groups'),
      type: 'date',
      disableColumnMenu: true,
      renderCell: ({ formattedValue, value }) => (
        <AvatarGroup max={8} spacing="medium">
          {((value as any) ?? []).map((user: Core.User) => (
            <UserAvatarHandler key={user.id} userName={user.displayName} userPhotoUrl={user.photoURL} />
          ))}
        </AvatarGroup>
      ),
    },
    {
      field: 'edit',
      flex: 1,
      align: 'right',
      type: 'date',
      disableColumnMenu: true,
      renderCell: ({ formattedValue, value, row }) => {
        const [open, setOpen] = useState(false);
        const { t } = useTranslation();
        return (
          <>
            <Button
              sx={{ borderRadius: 999, color: 'info.main', backgroundColor: 'info.lighter', ':hover': { bgcolor: 'info.hover' } }}
              onClick={() => setOpen(true)}
              style={{ marginRight: 14 }}
              variant="contained"
              endIcon={<FontAwesomeIcon fixedWidth icon={faEdit} />}
            >
              {t('Edit group')}
            </Button>
            <Button
              sx={{ borderRadius: 999, color: 'info.main', backgroundColor: 'info.lighter', ':hover': { bgcolor: 'info.hover' } }}
              href={'/group/' + row.id}
              style={{ marginRight: 14 }}
              variant="contained"
              endIcon={<FontAwesomeIcon fixedWidth icon={faEye} />}
            >
              {t('view-group')}
            </Button>
            <GroupPopup
              onClose={() => setOpen(false)}
              onSave={(name, added, removed) => onSaveGroup({ ...(row as Group), name }, { added, removed })}
              group={row as any}
              userList={userList}
              open={open}
            />
          </>
        );
      },
    },
  ];
}

const useStyles = makeStyles({
  root: (theme: any) => ({
    flex: 1,
    height: 'auto',
    borderRadius: 0,
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
  lastCell: {
    minWidth: '300px !important',
    position: 'absolute',
    left: 0,
    zIndex: 10,
    transform: 'translateX(calc(100vw - 100% - 28px))',
  },
});

interface GroupsProps {
  groups: Group[];
  loading?: boolean;
  onDelete: (groupIds: string[]) => Promise<any> | any;
  onCreate: (name: string, users: string[]) => Promise<any> | any;
  onUpdate: (group: Group, users: { added: string[]; removed: string[] }) => Promise<any> | any;
  userList: Core.User[];
}

export function GroupView({ groups, loading, onDelete, onCreate, userList, onUpdate }: GroupsProps) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [gridSelection, setGridSelection] = useState<(string | number)[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [createGroupOpen, setCreateGroupOpen] = useState(false);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <ContentHeader title={t('groups')} subtitle={t('groups-counted', { count: groups?.length })} href="/" dark>
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1}>
            <SearchInput
              InputProps={{ sx: { background: theme.palette.background.gray, ':hover, & :focus': { background: alpha(theme.palette.background.gray, 0.4) } } }}
              placeholder={t('search-groups')}
              fullWidth
              value={filterModel?.items?.find((i) => i.columnField == 'name')?.value ?? ''}
              onChange={(e) => {
                try {
                  new RegExp(e.target.value);
                  setFilterModel({ items: [{ columnField: 'name', operatorValue: 'contains', value: e.target.value }] });
                } catch {}
              }}
            />
          </Box>
          <ConditionalRender render={gridSelection.length > 0}>
            <LoadingPromiseButton
              variant="contained"
              color="error"
              sx={{ borderRadius: 999 }}
              endIcon={<FontAwesomeIcon icon={faTrash} />}
              loadingPosition="end"
              onClick={async () => {
                await onDelete(gridSelection as string[]);
                setGridSelection([]);
              }}
            >
              {t('delete-selected-groups')}
            </LoadingPromiseButton>
          </ConditionalRender>
          <Button
            variant="contained"
            sx={{ color: 'primary.main', bgcolor: 'background.paper', ':hover': { bgcolor: 'action.hover' }, borderRadius: 999 }}
            endIcon={<FontAwesomeIcon icon={faLayerPlus} />}
            onClick={() => setCreateGroupOpen(true)}
          >
            {t('add-group')}
          </Button>
        </Stack>
      </ContentHeader>
      <XGrid
        className={classes.root}
        headerHeight={40}
        onSelectionModelChange={(newSelection) => {
          setGridSelection(newSelection.selectionModel);
        }}
        selectionModel={gridSelection}
        sortModel={[{ field: 'name', sort: 'asc' }]}
        filterModel={filterModel}
        onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
        checkboxSelection
        columns={columns(userList, onUpdate).map((c) => ({
          ...c,
          renderHeader: (params) => {
            return (
              <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                {params.colDef.headerName}
              </Typography>
            );
          },
        }))}
        rows={groups}
        loading={loading}
        components={{
          NoRowsOverlay: GridNoRowsOverlay,
          NoResultsOverlay: GridNoResultsOverlay,
        }}
      />
      <GroupPopup onClose={() => setCreateGroupOpen(false)} onCreate={onCreate} userList={userList} open={createGroupOpen} />
    </Box>
  );
}
