import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';
import { DefaultInputBase } from '../../common';
// import DummyWorkbook from './DummyWorkbook.json';
import { StandardPopup } from '../../common/popups/StandardPopup';
import { ColumnSelector } from './ColumnSelector';
import { MultiUserAdd } from './MultiUserAdd';
import { UploadFileComponent } from './UploadFileComponent';

export function ImportExcelUserPopup({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) {
  const [workbook, setWorkbook] = useState<XLSX.WorkBook>(null);
  const [sheetName, setSheetName] = useState(null);
  const [usersToAdd, setUsersToAdd] = useState<{ headers: string[]; rows: string[][] }>(null);
  const [activeStep, setActiveStep] = useState(0);
  // useEffect(() => {
  //   setInterval(()=> setOpen(v=>!v), 2000)
  // }, [])

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setWorkbook(null);
    setSheetName(null);
    setUsersToAdd(null);
    setActiveStep(0);
  };

  function handleFile(e) {
    const files = e.target.files,
      f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      //@ts-ignore
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      setWorkbook(workbook);
      next();
    };
    reader.readAsArrayBuffer(f);
  }

  const steps = ['Upload Excel File', 'Select Worksheet', 'Select Columns', 'Upload Users'];

  const next = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSheetSelect = (sheetName: string) => {
    setSheetName(sheetName);
    next();
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <UploadFileComponent handleFile={handleFile} />;
      case 1:
        return (
          <>
            {/* <h2>Select Sheet</h2> */}
            <FormControl>
              <Select labelId="select-input-label" style={{height: 60}} onChange={(e) => handleSheetSelect(e.target.value)} defaultValue={workbook.SheetNames[0]} variant="filled" input={<DefaultInputBase />}>
                {workbook.SheetNames.map((sheetName) => (
                  <MenuItem key={sheetName} value={sheetName}>
                    {sheetName}
                  </MenuItem>
                ))}
              </Select>
              <InputLabel style={{ marginLeft: 16 }} id="select-input-label">
                Select Sheet
              </InputLabel>
            </FormControl>
            <Button
              style={{marginTop: 50}}
              variant="contained"
              onClick={() => {
                if (!sheetName) setSheetName(workbook.SheetNames[0]);
                next();
              }}
            >
              {t('continue')}
            </Button>
          </>
        );
      case 2:
        const sheet = workbook.Sheets[sheetName];
        return (
          <ColumnSelector
            sheet={sheet}
            onSelect={(headers, rows) => {
              setUsersToAdd({ headers, rows });
              next();
            }}
          />
        );
      case 3:
        return <MultiUserAdd headers={usersToAdd.headers} rows={usersToAdd.rows} onClose={handleClose} />;
    }
  }

  return (
    <StandardPopup visible={open} onBackdropClick={handleClose}>
      <Grid bgcolor="white" padding={5} style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
            // }
            // if (isStepSkipped(index)) {
            //   stepProps.completed = false;
            // }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div style={{ marginTop: 50, overflow: 'auto', flex: 1, display: 'flex', flexDirection: 'column' }}>{getStepContent(activeStep)}</div>
      </Grid>
    </StandardPopup>
  );
}
