export const QUERY_USERS_REQUEST = "users/query_users_request"
export const QUERY_USERS_SUCCESS = "users/query_users_success"
export const QUERY_USERS_FAILURE = "users/query_users_failure"
export const FETCH_NEXT_PAGE_REQUEST = "users/fetch_next_page_request"
export const FETCH_NEXT_PAGE_SUCCESS = "users/fetch_next_page_success"
export const FETCH_NEXT_PAGE_FAILURE = "users/fetch_next_page_failure"
export const CREATE_USER_REQUEST = "users/create_user_request"
export const CREATE_USER_SUCCESS = "users/create_user_success"
export const CREATE_USER_FAILURE = "users/create_user_failure"
export const DELETE_USER_REQUEST = "users/delete_user_request"
export const DELETE_USER_SUCCESS = "users/delete_user_success"
export const DELETE_USER_FAILURE = "users/delete_user_failure"
export const FETCH_SINGLE_USER_REQUEST = "users/fetch_single_user_request"
export const FETCH_SINGLE_USER_SUCCESS = "users/fetch_single_user_success"
export const FETCH_SINGLE_USER_FAILURE = "users/fetch_single_user_failure"
export const UPDATE_USER_REQUEST = "users/update_user_request"
export const UPDATE_USER_SUCCESS = "users/update_user_success"
export const UPDATE_USER_FAILURE = "users/update_user_failure"
export const SET_USER_RIGHTS_REQUEST = "users/set_user_rights_request"
export const SET_USER_RIGHTS_SUCCESS = "users/set_user_rights_success"
export const SET_USER_RIGHTS_FAILURE = "users/set_user_rights_failure"
export const SET_USER_ROLES_REQUEST = "users/set_user_roles_request"
export const SET_USER_ROLES_SUCCESS = "users/set_user_roles_success"
export const SET_USER_ROLES_FAILURE = "users/set_user_roles_failure"
export const UPDATE_USER_GROUPS_REQUEST = "users/update_user_groups_request"
export const UPDATE_USER_GROUPS_SUCCESS = "users/update_user_groups_success"
export const UPDATE_USER_GROUPS_FAILURE = "users/update_user_groups_failure"
export const UPDATE_USER_CREATE_DEFAULT_STATE = "users/update_user_create_default_state"