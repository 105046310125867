import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationForm } from '../../..';
import { useAutoUpdateState } from '../../fileManager/useAutoUpdateState';
import { useDeleteFormMutation } from '../hooks';
import { SortableTableRow } from './SortableTableRow';

export function ReportsContent({
  reports = [],
  onChange,
  onChangeForm,
  onDelete,
  onCreate,
  onChangePublish,
  onDuplicate,
  campaignId,
}: {
  reports: ApplicationForm[];
  onChange: (sortedReports: ApplicationForm[]) => void;
  onChangeForm: (form: ApplicationForm, formId: string) => void;
  onDelete: (id: string) => void;
  onCreate: () => void;
  onChangePublish: (published: boolean, reportId: string) => Promise<any>;
  onDuplicate: (form: ApplicationForm, destinationCampaign?: string) => Promise<any>;
  campaignId: string;
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [sortedReports, setSortedReports] = useAutoUpdateState(reports);
  const deleteFormMutation = useDeleteFormMutation();

  const theme = useTheme();
  const { t } = useTranslation();

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const ids = reports.map((i) => i.id);
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);

      const newSortedReports = arrayMove(reports, oldIndex, newIndex);
      setSortedReports(newSortedReports);
      onChange(newSortedReports);
    }
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="10px"></TableCell>
            <TableCell>
              <Typography fontWeight="600" variant="subtitle1">
                Report Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight="600" variant="subtitle1">
                Published
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight="600" variant="subtitle1">
                Deadline
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={sortedReports.map((i) => i.id)} strategy={verticalListSortingStrategy}>
            <TableBody>
              {sortedReports.map((r) => (
                <SortableTableRow
                  campaignId={campaignId}
                  r={r}
                  key={r.id}
                  onChange={(f) => onChangeForm(f, r.id)}
                  onDelete={() => onDelete(r.id)}
                  onChangePublish={(p) => onChangePublish(p, r.id)}
                  onDuplicate={(report, id) => onDuplicate(report, id)}
                />
              ))}
            </TableBody>
          </SortableContext>
        </DndContext>
      </Table>
      <Button
        onClick={onCreate}
        sx={{ color: theme.palette.grey[800], backgroundColor: theme.palette.background.gray, ':hover': { bgcolor: theme.palette.grey[800], color: 'white' } }}
        fullWidth
        variant="contained"
        style={{ borderRadius: 0 }}
      >
        <Typography>
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 12 }} />
          {t('add-new-report')}
        </Typography>
      </Button>
    </TableContainer>
  );
}
