import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {MainDialog} from "./MainDialog";

interface InfoPopupProps {
  description: string;
  renderAsButton?: boolean;
  buttonStyle?: React.CSSProperties;
}
export function InfoPopup({ description, renderAsButton = false, buttonStyle }: InfoPopupProps) {
  const { t } = useTranslation();
  const [infoVisible, setInfoVisible] = useState(false);

  return (
    <>
      {renderAsButton ? (
        <Button
          onClick={() => {
            setInfoVisible(true);
          }}
          size="small"
          variant="contained"
          sx={{ fontSize: '14px !important', height: 30 }}
          style={buttonStyle}
        >
          <FontAwesomeIcon icon={faInfoCircle} style={{ width: 16, height: 16, marginRight: 8 }} />
          {t("More Info")}
        </Button>
      ) : (
        <IconButton
          onClick={() => {
            setInfoVisible(true);
          }}
          sx={{ bgcolor: 'info.lighter', color: 'info.main' }}
          style={{ marginRight: 6, height: 38, width: 38, ...buttonStyle }}
        >
          <FontAwesomeIcon fixedWidth fontSize={24} width={24} height={24} size="sm" icon={faInfoCircle} />
        </IconButton>
      )}
      <MainDialog
        hideCancel
        buttonText="Close"
        width={800}
        open={infoVisible}
        modalTitle={t('information')}
        onCloseClick={() => setInfoVisible(false)}
        onSaveClick={() => setInfoVisible(false)}
        description={description}
      />
    </>
  );
}
