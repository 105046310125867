import { faArrowAltCircleLeft, faArrowAltCircleRight, faSave, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../css/components/common/popups/AddUserPopup.scss';
import { useAutoUpdateState } from '../../views/fileManager/useAutoUpdateState';
import { FAIconButton, StandardPopup } from '../common';
import { ConditionalRender } from '../common/ConditionalRender';
import { DefaultDropDown } from '../common/dropdowns/SetPropertyDropdown';
import { ConfigurableUser, Roles } from './ConfigurableRoleUser';
import { SelectableUser } from './SelectableUser';

//@ts-nocheck FIXME: Implement view!!!!!
//@ts-nocheck
//----------------------------------------------------------
interface MarkedUserProps {
  userName: string;
  userImage?: any;
  onReSelectClick: (selected: boolean) => void;
}

export const MarkedUser = ({ userImage, userName, onReSelectClick }: MarkedUserProps) => {
  return (
    <div className="selected-user">
      <img src={userImage} className="selected-user-image" />
      <div className="marked-user-text-block">{userName}</div>
      <FAIconButton iconSize={16} iconColor={'#fff'} bgColor={'#d55555'} icon={faTimes} className="btn-marked-user-delete" onClick={() => onReSelectClick(false)} />
    </div>
  );
};

//----------------------------------------------------------

//----------------------------------------------------------
interface AddUserPopup {
  active: boolean;
  setActive: (active: boolean) => void;
  users: Core.User[];
  onUserSelect: (usersToAdd: { uid: string; role: string }[]) => void;
}

export function AddUserPopup({ active, setActive, users, onUserSelect }: AddUserPopup) {
  console.log({ users });
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchCount, setSearchCount] = useState<number>(0);
  const { t } = useTranslation();

  // const selectionMap = useMemo(() => selectedUsers.reduce((map, u) => ({ ...map, [u.uid]: role }), {}), [selectedUsers])

  const [usersWithSelection, setUsers] = useAutoUpdateState<(Core.User & { selected: boolean; role: string })[]>((users as any) ?? [], [users, active]);
  const [emailActive, setEmailActive] = useState(false);
  const [emailText, setEmailText] = useState('');
  const [selectedMasterRole, setSelectedMasterRole] = useState<string>(t('set-all-users'));
  const [activeMenu, setActiveMenu] = useState('1');

  const changeUserSelect = (user: any, selected: boolean) => {
    setUsers(
      usersWithSelection.map((el) => {
        if (el.id === user.id) {
          el.selected = selected;
        }
        return el;
      }),
    );
  };

  const nameSearch = (userName: string) => {
    try {
      const searchRe = new RegExp(searchInput, 'gi');
      return searchRe.test(userName);
    } catch (error) {
      return true;
    }
  };

  const changeUserRole = (user: any, role: any) => {
    setUsers(
      usersWithSelection.map((el) => {
        if (el.id === user.id) {
          el.role = role;
        }
        return el;
      }),
    );
  };

  const applyChanges = () => {
    setActive(false);

    setActiveMenu('1');
    console.log(emailText);
    onUserSelect?.(usersWithSelection.filter((u) => u.selected).map((u) => ({ role: u.role, uid: u.id })));
  };

  const setMasterRole = (role: any) => {
    setSelectedMasterRole(role);
    setUsers(
      usersWithSelection.map((el) => {
        if (el.selected) {
          el.role = role;
        }
        return el;
      }),
    );
  };

  return (
    <StandardPopup visible={active} onBackdropClick={() => setActive(false)}>
      <div className="add-user-popup-provider" style={{ height: 550 }}>
        <ConditionalRender render={activeMenu == '1'}>
          <div className="add-user-menu">
            <div className="split--container space--between">
              <div className="add-user-input-container">
                <FontAwesomeIcon style={{ fontSize: 24 }} icon={faSearch} className="add-user-input-icon" />
                <input
                  style={{ fontSize: 20 }}
                  type="Text"
                  placeholder={t('search-users-and-groups')}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="add-user-input"
                />
              </div>
              <button
                className="add-user-top-button"
                onClick={() => {
                  setActiveMenu('2');
                  setMasterRole(Roles.Viewer);
                }}
              >
                <div style={{ fontSize: 20 }}>{t('continue')}</div>
                <FontAwesomeIcon style={{ fontSize: 20, marginLeft: 10 }} icon={faArrowAltCircleRight} />
              </button>
            </div>
            <ConditionalRender render={searchInput != ''}>
              <div className="marked-user-side-scroll relative">
                {usersWithSelection.map((user) => {
                  return user.selected ? <MarkedUser key={user.id} userImage={user.photoURL} userName={user.displayName} onReSelectClick={(selected) => changeUserSelect(user, selected)} /> : null;
                })}
              </div>
            </ConditionalRender>
            <ConditionalRender render={searchInput != ''}>
              <div className="in-popup-text-field" style={{ marginTop: 10, position: 'relative' }}>
                {searchCount} {searchCount === 1 ? 'Ergebnis' : 'Ergebnisse'}
              </div>
            </ConditionalRender>
            <div className="user-grid-mod">
              <div className="user-select-grid">
                {usersWithSelection.map((user) => {
                  return nameSearch(user.displayName) && user.selected ? (
                    <SelectableUser
                      key={user.id}
                      selected={true}
                      userName={user.displayName}
                      userArea={user.abteilung}
                      userImage={user.photoURL}
                      onReSelectClick={(selected) => changeUserSelect(user, selected)}
                    />
                  ) : null;
                })}
                {usersWithSelection.map((user) => {
                  return nameSearch(user.displayName) && !user.selected ? (
                    <SelectableUser
                      key={user.id}
                      selected={false}
                      userName={user.displayName}
                      userArea={user.abteilung}
                      userImage={user.photoURL}
                      onReSelectClick={(selected) => changeUserSelect(user, selected)}
                    />
                  ) : null;
                })}
              </div>
            </div>
          </div>
        </ConditionalRender>
        <ConditionalRender render={activeMenu == '2'}>
          <div className="add-user-menu height-mod">
            <div className="split--container space--between confirm-small">
              <button className="add-user-top-button" onClick={() => setActiveMenu('1')}>
                <FontAwesomeIcon style={{ fontSize: 20, marginRight: 10 }} icon={faArrowAltCircleLeft} />
                <div style={{ fontSize: 20 }}>{t('go-back')}</div>
              </button>
              <div style={{ margin: 10, display: 'flex', flexDirection: 'row', alignItems: 'center ' }}>
                <div className="in-popup-text-field" style={{ marginRight: 10 }}>
                  {t('set-all-users')}
                </div>
                <Select
                  value={selectedMasterRole}
                  onChange={({ target: { value, name } }) => {
                    setMasterRole(value as string);
                  }}
                  input={<DefaultDropDown />}
                  defaultValue={Roles.Viewer}
                >
                  <MenuItem value={Roles.Viewer} selected={Roles.Viewer == selectedMasterRole}>
                    {t('file-roles.viewer')}
                  </MenuItem>
                  <MenuItem value={Roles.Contributer} selected={Roles.Contributer == selectedMasterRole}>
                    {t('file-roles.contributer')}
                  </MenuItem>
                  <MenuItem value={Roles.ContentManager} selected={Roles.ContentManager == selectedMasterRole}>
                    {t('file-roles.content-manager')}
                  </MenuItem>
                  <MenuItem value={Roles.Admin} selected={Roles.Admin == selectedMasterRole}>
                    {t('roles.admin')}
                  </MenuItem>
                </Select>
              </div>
              <button className="add-user-top-button" onClick={() => applyChanges()}>
                <FontAwesomeIcon style={{ fontSize: 20, marginRight: 10 }} icon={faSave} />
                <div style={{ fontSize: 20 }}>{t('save')}</div>
              </button>
            </div>
            <div className="user-grid-mod" style={{ marginTop: 10, padding: 5 }}>
              <div className="user-confirm-grid">
                {usersWithSelection
                  .filter((u) => u.selected)
                  .map((user) => {
                    return <ConfigurableUser key={user.id} userName={user.displayName as string} userImage={user.photoURL} role={user.role} onRoleSelect={(role) => changeUserRole(user, role)} />;
                  })}
              </div>
            </div>
          </div>
        </ConditionalRender>
      </div>
    </StandardPopup>
  );
}
