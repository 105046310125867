import { Button, Card, CardActions, CardContent, CardHeader, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StandardPopup } from '../../../components/common';
import { LoadingPromiseButton } from '../../LoadingPromiseButton';

interface mainDialogProps {
  open?: boolean;
  onCloseClick(): void;
  onSaveClick(): void;
  description: string;
  modalTitle: string;
  savePending?: boolean;
  buttonText?: string;
  hideCancel?: boolean;
  saveButtonColor?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'success'
    | 'warning'
    | 'info'
    | 'primaryAction'
    | 'secondaryAction'
    | 'defaultAction'
    | 'errorAction'
    | 'successAction'
    | 'warningAction'
    | 'infoAction';
  secondButtonColor?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'success'
    | 'warning'
    | 'info'
    | 'primaryAction'
    | 'secondaryAction'
    | 'defaultAction'
    | 'errorAction'
    | 'successAction'
    | 'warningAction'
    | 'infoAction';
  width?: number;
  children?: any;
}

export function MainDialog({
  onCloseClick,
  onSaveClick,
  description,
  modalTitle,
  savePending,
  open = false,
  buttonText,
  hideCancel,
  width,
  children,
  saveButtonColor,
  secondButtonColor,
}: mainDialogProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <StandardPopup visible={open} onBackdropClick={onCloseClick} width={width ? width : 550}>
      <Card>
        <CardHeader title={modalTitle} titleTypographyProps={{ variant: 'h2' }} style={{ background: theme.palette.background.gray, paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }} />
        <CardContent>
          {description &&
            description.split('\n').map((part, index) => (
              <Typography key={index} variant="subtitle1">
                {part || <br />}
              </Typography>
            ))}
          {children}
        </CardContent>
        <CardActions sx={{ paddingX: 3, paddingBottom: 3, justifyContent: 'flex-end' }}>
          {!hideCancel && (
            <Button variant="outlined" color={secondButtonColor ? secondButtonColor : 'error'} onClick={onCloseClick}>
              {t('cancel')}
            </Button>
          )}
          <LoadingPromiseButton variant="contained" color={saveButtonColor ? saveButtonColor : 'primary'} loading={savePending} onClick={onSaveClick}>
            {buttonText ? buttonText : t('confirm')}
          </LoadingPromiseButton>
        </CardActions>
      </Card>
    </StandardPopup>
  );
}
