import { Group, GroupTag } from '@base/core';
import { faEdit, faLayerPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Avatar, AvatarGroup, Box, Button, Stack, Tooltip, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, XGrid } from '@material-ui/x-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalRender } from '../../components/common';
import { ContentHeader } from '../../components/common/ContentHeader';
import { MainDialog } from '../../components/common/popups/MainDialog';
import { SearchInput } from '../../components/inputs';
import { LoadingPromiseButton } from '../../components/LoadingPromiseButton';
import { useSettings } from '../../theme/ThemeProvider';
import { GroupTagPopup } from './popups/GroupTagPopup';
import { GridNoResultsOverlay, GridNoRowsOverlay } from '../../components/content/GridContent';

const useStyles = makeStyles({
  root: (theme: any) => ({
    borderRadius: 0,
    height: 'auto',
    flex: 1,
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
  lastCell: {
    minWidth: '300px !important',
    position: 'absolute',
    left: 0,
    zIndex: 10,
    transform: 'translateX(calc(100vw - 100% - 28px))',
  },
  avatarSmall: {
    height: 34,
    width: 34,
  },
});

function getInitialsFromString(val: string) {
  let res = '';
  for (const part of val.split(' ')) {
    res += part[0] ?? '';
  }
  return res.toUpperCase();
}

function columns(groupList: Group[], onSaveGroup: (group: GroupTag, groups: { added: string[]; removed: string[] }) => Promise<void> | void): GridColDef[] {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  return [
    {
      field: 'name',
      width: 275,
      headerName: t('group-name'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'groups',
      flex: 1,
      headerName: t('involved-groups'),
      type: 'date',
      disableColumnMenu: true,
      renderCell: ({ formattedValue, value, row }) => {
        const settings = useSettings();
        const namedGroup = settings.namedGroups.find((namedGroup) => namedGroup.name == row.type);

        return (
          <AvatarGroup max={10} classes={{ avatar: classes.avatarSmall }}>
            {((value as any) ?? []).map((group: Group) => (
              <Tooltip title={group.name} key={group.id}>
                <Avatar src={namedGroup?.getIcon?.(group)}>{getInitialsFromString(group.name)}</Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        );
      },
    },
    {
      field: 'edit',
      width: 250,
      align: 'right',
      type: 'date',
      disableColumnMenu: true,
      renderCell: ({ formattedValue, value, row }) => {
        const [open, setOpen] = useState(false);
        const { t } = useTranslation();
        return (
          <>
            <Button
              sx={{ borderRadius: 999, color: 'info.main', backgroundColor: 'info.lighter', ':hover': { bgcolor: 'info.hover' } }}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
              style={{ marginRight: 14 }}
              variant="contained"
              endIcon={<FontAwesomeIcon fixedWidth icon={faEdit} />}
            >
              {t('Edit group')}
            </Button>
            <GroupTagPopup
              onClose={() => setOpen(false)}
              onSave={(name, added, removed) => onSaveGroup({ ...(row as Group), name }, { added, removed })}
              group={row as any}
              groupList={groupList}
              open={open}
            />
          </>
        );
      },
    },
  ];
}

interface GroupTagsProps {
  groups: GroupTag[];
  loading?: boolean;
  onDelete: (groupIds: string[]) => Promise<any> | any;
  onCreate: (name: string, groups: string[]) => Promise<any> | any;
  onUpdate: (group: GroupTag, groups: { added: string[]; removed: string[] }) => Promise<any> | any;
  groupList: Group[];
}

export function GroupTags({ groups, loading, onDelete, onCreate, groupList, onUpdate }: GroupTagsProps) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const [gridSelection, setGridSelection] = useState<(string | number)[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [open, setOpen] = useState(null);
  const [openDeletePopup, setOpenDeletePopup] = useState(undefined);

  return (
    <>
      <ContentHeader noBack title={t('group-folder')} subtitle={t('groups-counted', { count: groups?.length })}>
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1}>
            <SearchInput
              variant="outlined"
              size="small"
              InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
              placeholder={t('search') + ' ' + t('group-folder')}
              fullWidth
              value={filterModel?.items?.find((i) => i?.columnField == 'name')?.value ?? ''}
              onChange={(e) => {
                try {
                  new RegExp(e.target.value);
                  setFilterModel({ items: [{ columnField: 'name', operatorValue: 'contains', value: e.target.value }] });
                } catch {}
              }}
            />
          </Box>
          <ConditionalRender render={gridSelection.length > 0}>
            <LoadingPromiseButton
              variant="contained"
              color="error"
              sx={{ borderRadius: 999 }}
              endIcon={<FontAwesomeIcon icon={faTrash} />}
              loadingPosition="end"
              onClick={() => {
                setOpenDeletePopup(true);
              }}
            >
              {t('delete-selected-groups')}
            </LoadingPromiseButton>
            <MainDialog
              open={openDeletePopup}
              onCloseClick={() => setOpenDeletePopup(false)}
              onSaveClick={async () => {
                onDelete(gridSelection as string[]);
                setGridSelection([]);
                setOpenDeletePopup(false);
              }}
              saveButtonColor="error"
              secondButtonColor="primary"
              modalTitle={t('do-you-really-want-to-delete-count-groups', { count: gridSelection.length })}
              description={'This action cannot be undone.' /* TODO: Implement i18n key */}
              buttonText={t('delete')}
            />
          </ConditionalRender>
          <Button variant="contained" color="primary" sx={{ borderRadius: 999 }} endIcon={<FontAwesomeIcon icon={faLayerPlus} />} onClick={() => setCreateGroupOpen(true)}>
            {t('add-group-folder')}
          </Button>
        </Stack>
      </ContentHeader>
      <XGrid
        className={classes.root}
        headerHeight={40}
        onSelectionModelChange={(newSelection) => {
          setGridSelection(newSelection.selectionModel);
        }}
        selectionModel={gridSelection}
        filterModel={filterModel}
        sortModel={[{ field: 'name', sort: 'asc' }]}
        onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
        checkboxSelection
        disableSelectionOnClick
        onRowClick={(params) => setOpen(params.row)}
        columns={columns(groupList, onUpdate).map((c) => ({
          ...c,
          renderHeader: (params) => {
            return (
              <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                {params.colDef.headerName}
              </Typography>
            );
          },
        }))}
        rows={groups}
        loading={loading}
        components={{
          NoRowsOverlay: GridNoRowsOverlay,
          NoResultsOverlay: GridNoResultsOverlay,
        }}
      />
      <GroupTagPopup onClose={() => setOpen(false)} onSave={(name, added, removed) => onUpdate({ ...(open as Group), name }, { added, removed })} group={open} groupList={groupList} open={open} />
      <GroupTagPopup onClose={() => setCreateGroupOpen(false)} onCreate={onCreate} groupList={groupList} open={createGroupOpen} />
    </>
  );
}
