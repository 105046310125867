import * as React from 'react';

function SvgItalic(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.5 21.22" ref={svgRef} {...props}>
      <g transform="translate(-1483 -1156)">
        <path d="M1486.363 1177a.515.515 0 01-.435-.189.568.568 0 01-.087-.478l4.059-18.966a.683.683 0 01.261-.479.806.806 0 01.522-.188h1.45a.515.515 0 01.435.188.65.65 0 01.116.479l-4.059 18.966a.831.831 0 01-.275.479.751.751 0 01-.508.188z" />
        <rect width={8.5} height={2.22} rx={0.5} transform="translate(1487 1156)" fill="#858585" />
        <rect width={8.5} height={2.22} rx={0.5} transform="translate(1483 1175)" fill="#858585" />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgItalic);
export default ForwardRef;
