export const LOGIN_REQUEST = 'auth/login_request';
export const LOGIN_SUCCESS = 'auth/login_success';
export const LOGIN_FAILURE = 'auth/login_failure';
export const LOGOUT_REQUEST = 'auth/logout_request';
export const LOGOUT_SUCCESS = 'auth/logout_success';
export const LOGOUT_FAILURE = 'auth/logout_failure';
export const RESET_PASSWORD_REQUEST = 'auth/reset_password_request';
export const RESET_PASSWORD_SUCCESS = 'auth/reset_password_success';
export const RESET_PASSWORD_FAILURE = 'auth/reset_password_failure';
export const RESET = 'reset';
export const UPDATE_DATA_REQUEST = 'auth/update_data_request';
export const UPDATE_DATA_SUCCESS = 'auth/update_data_success';
export const UPDATE_DATA_FAILURE = 'auth/update_data_failure';
export const TRY_AUTO_LOGIN_REQUEST = 'auth/try_auto_login_request';
export const TRY_AUTO_LOGIN_SUCCESS = 'auth/try_auto_login_success';
export const TRY_AUTO_LOGIN_FAILURE = 'auth/try_auto_login_failure';
export const RESET_PASSWORD_RESET = 'auth/reset_password_reset';
export const PROVIDE2FAVERIFICATION_CODE_REQUEST = 'auth/provide2faverification_code_request';
export const PROVIDE2FAVERIFICATION_CODE_SUCCESS = 'auth/provide2faverification_code_success';
export const PROVIDE2FAVERIFICATION_CODE_FAILURE = 'auth/provide2faverification_code_failure';
export const SET2FARESOLVER = 'auth/set2faresolver';
export const PROVIDE2FAPHONE_NUMBER_REQUEST = 'auth/provide2faphone_number_request';
export const PROVIDE2FAPHONE_NUMBER_SUCCESS = 'auth/provide2faphone_number_success';
export const PROVIDE2FAPHONE_NUMBER_FAILURE = 'auth/provide2faphone_number_failure';
export const VERIFY2FAFACTOR_ENROLLMENT_REQUEST = 'auth/verify2fafactor_enrollment_request';
export const VERIFY2FAFACTOR_ENROLLMENT_SUCCESS = 'auth/verify2fafactor_enrollment_success';
export const VERIFY2FAFACTOR_ENROLLMENT_FAILURE = 'auth/verify2fafactor_enrollment_failure';
export const PROVIDE2FAFACTOR_CALL = 'auth/provide2fafactor_call';
export const VERIFY2FAFACTOR_ENROLLMENT_CALL = 'auth/verify2fafactor_enrollment_call';
export const ENFORCE2FACREATION = 'auth/enforce2facreation';
export const CLOSE2FACALL = 'auth/close2facall';
export const NOTIFY2FACREATIONFINISHED = 'auth/notify2facreationfinished';
export const CLOSE2FACREATION_NOTIFICATION = 'auth/close2facreation_notification';
