import type { IconName } from '@fortawesome/pro-light-svg-icons';
import { faBellOn, faBellSlash, faCog, faInfoCircle, faLowVision, faShare, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { faLock, faUnlock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Paper, Stack, Typography, useTheme as useMuiTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../css/components/common/content/Module.scss';
import { CombinedConditionalRender, ConditionalRenderWithRightsForFiles } from '../../helpers/ConditionalRenderWithRights';
import { useTheme } from '../../theme/ThemeProvider';
import { FileModule } from '../../views/campaign/CampaignOverview';
import { ConditionalRender } from '../common';
import AsyncImage from '../common/AsyncImage';
import { MainDialog } from '../common/popups/MainDialog';

interface MainModuleProps {
  currentModule: FileModule;
  count: number;
  notifications?: number;
  navigateTo?: string;
  onLinkClick?: () => void;
  onAccessClick?: () => void;
  onSettingsClick?: () => void;
  onNotificationClick?: () => void;
  onClick?: () => void;
  external?: boolean;
  campaign?: boolean;
}

export const Module = ({
  count,
  notifications = 0,
  navigateTo = '/home',
  onSettingsClick,
  onLinkClick,
  onAccessClick,
  onNotificationClick,
  currentModule,
  onClick,
  external,
  campaign = false,
}: MainModuleProps) => {
  const history = useHistory();
  const theme = useTheme();
  const muiTheme = useMuiTheme();
  const { t } = useTranslation();

  const [infoVisible, setInfoVisible] = useState(false);

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setContextMenu(null);
  };
  const me = useSelector((state: Core.StateType) => state.auth.user);

  const chat = (currentModule as any).notifications?.chat?.[me.id];
  const submissions = (currentModule as any).notifications?.submissions?.[me.id];
  const someNotificationsEnabled = chat || submissions;

  const isPublic = currentModule.permissions.visibility === 'public';
  return (
    <>
      <Paper
        // elevation={5}
        sx={{
          position: 'relative',
          backgroundColor: muiTheme.palette.primary.main,
          cursor: 'pointer',
          transition: '200ms ease',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.12)',
        }}
        className="detailed-module no-user-select"
        onContextMenu={handleContextMenu}
        onClick={onClick ? onClick : () => history.push(navigateTo)}
      >
        <CombinedConditionalRender file_admin={!campaign as any} campaign_admin={campaign as any} parent={currentModule} write>
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onSettingsClick();
                handleClose(e);
              }}
            >
              <FontAwesomeIcon icon={faCog} style={{ marginRight: 8 }} fixedWidth />
              {t('open-settings')}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onAccessClick();
                handleClose(e);
              }}
            >
              <FontAwesomeIcon icon={faLowVision} style={{ marginRight: 8 }} fixedWidth />
              {t('manage-access')}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onLinkClick();
                handleClose(e);
              }}
            >
              <FontAwesomeIcon icon={faShare} style={{ marginRight: 8 }} fixedWidth />
              {t('share-internally')}
            </MenuItem>
          </Menu>
        </CombinedConditionalRender>
        <div id={currentModule.id} className="context-menu-overlay"></div>
        <ConditionalRender render={notifications > 0}>
          <div className="notification">{notifications}</div>
        </ConditionalRender>
        <ConditionalRenderWithRightsForFiles parent={currentModule} write>
          <div className="public-status" style={{ backgroundColor: isPublic ? muiTheme.palette.success.main : muiTheme.palette.error.main }}>
            <FontAwesomeIcon icon={isPublic ? faUnlock : faLock} />
          </div>
        </ConditionalRenderWithRightsForFiles>
        <AsyncImage src={currentModule.downloadUrl} className="image" style={{ objectFit: 'cover' }} />
        <Stack sx={{ display: 'flex', paddingX: 1.5, paddingY: 1, flexDirection: 'row', alignItems: 'center', height: 44, borderTop: '1px solid white' }}>
          <Typography variant="h1" sx={{ color: 'white', mr: 1 }} alignSelf="center">
            <FontAwesomeIcon icon={['fal', currentModule.icon as IconName]} fixedWidth />
          </Typography>
          <Typography fontWeight="500" variant="h6" sx={{ color: 'white', overflow: 'hidden' }} alignSelf="center" lineHeight={1.2} maxHeight={40}>
            {currentModule.name}
          </Typography>
        </Stack>
        <div className="module-btn-con">
          <CombinedConditionalRender file_admin={!campaign as any} campaign_admin={campaign as any} parent={currentModule} write>
            {onSettingsClick && (
              <Paper
                elevation={3}
                className="module-btn"
                style={{ color: theme.colors['main-color'] }}
                onClick={(e) => {
                  e.stopPropagation();
                  onSettingsClick();
                }}
              >
                <FontAwesomeIcon icon={faCog} className="module-btn-icon-cog" style={{ fontSize: 20 }} />
              </Paper>
            )}
          </CombinedConditionalRender>
          <ConditionalRender render={campaign}>
            {onNotificationClick && (
              <Paper
                elevation={3}
                className="module-btn"
                style={{ color: someNotificationsEnabled ? muiTheme.palette.success.main : muiTheme.palette.error.main, marginTop: 8 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onNotificationClick();
                }}
              >
                <FontAwesomeIcon icon={someNotificationsEnabled ? faBellOn : faBellSlash} className="module-btn-icon" style={{ fontSize: 20 }} />
              </Paper>
            )}
          </ConditionalRender>
          <CombinedConditionalRender file_admin={!campaign as any} campaign_admin={campaign as any} parent={currentModule} write>
            {onLinkClick && (
              <div
                className="module-btn"
                style={{ color: theme.colors['main-color'], marginTop: 8 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onLinkClick();
                }}
              >
                <FontAwesomeIcon icon={faUserPlus} fixedWidth className="module-btn-icon" style={{ fontSize: 17 }} />
              </div>
            )}
          </CombinedConditionalRender>
          {external && (
            <div
              className="module-btn"
              style={{ color: theme.colors['main-color'], marginTop: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                setInfoVisible(true);
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="module-btn-icon" style={{ fontSize: 18 }} />
            </div>
          )}
        </div>
      </Paper>
      <MainDialog
        hideCancel
        buttonText="Close"
        width={800}
        open={infoVisible}
        modalTitle={t('information')}
        onCloseClick={() => setInfoVisible(false)}
        onSaveClick={() => setInfoVisible(false)}
        description={currentModule.description}
      />
    </>
  );
};
