import '../../../css/components/common/popups/popup.scss';

import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import type { ReactChildrenProp, ReactProps } from '../../../lib';

type popupProps = {
    title?: string;
    children: ReactChildrenProp;
    titleIcon?: IconDefinition;
    titleColor?: string;
    titleBottomMargin?: number;
    padding?: number | string;
    visible: boolean;
    onBackdropClick: () => void;
    contentFlexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    popupContainerStyle?: React.CSSProperties;
};

export const Popup = ({
    children,
    visible = false,
    onBackdropClick,
    padding = 30,
    contentFlexDirection = 'column',
    titleColor,
    titleIcon,
    title,
    titleBottomMargin = 8,
    popupContainerStyle = {},
}: popupProps) => (
    <div onClick={onBackdropClick} className="popup-fixed-container" style={visible ? { display: 'flex' } : { display: 'none' }}>
        <div onClick={(e) => e.stopPropagation()} className="popup" style={{ padding: padding, ...popupContainerStyle }}>
            <div style={{ display: 'flex', flexDirection: contentFlexDirection, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <div className="popup-title-container" style={{ marginBottom: titleBottomMargin }}>
                    <FontAwesomeIcon className="popup-icon" style={{ color: titleColor }} icon={titleIcon} />
                    <p className="popup-title" style={{ color: titleColor }}>
                        {title}
                    </p>
                </div>
                {children}
            </div>
        </div>
    </div>
);
