import {IAuthService, Observer, TwoFALoginError, TwoFARequireEnrollmentError} from '@base/core';
import {firebase} from '../config';
import {createCallableApi} from './createCallableApi';
import {UserService} from './UserService';
import type * as admin from 'firebase-admin';
import {DataBase} from "../index";

function isReactNative(): boolean {
  return typeof document === "undefined";
}

export class AuthService implements IAuthService {
  onAuthStateChanged(callback: (loggedIn: null | Core.User) => void) {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        callback(await new UserService().getUserById(user.uid));
      } else callback(null);
    });
  }

  //implements IAuthService {

  async resetPassword(email: string): Promise<void> {
    await firebase.auth().sendPasswordResetEmail(email);
  }

  /**
   * removes all second factors from the user
   * is only callable as super admin
   * @param uid
   */
  async clear2Factor(uid: string): Promise<void> {
    await createCallableApi(`/user/${uid}/remove-multifactors`, 'DELETE')();
  }

  /**
   * logout on firebase side. logout in application state is still needed
   */
  async logout(): Promise<void> {
    await firebase.auth().signOut();
  }

  /**
   * executes the invisible google reCaptcha V2 check
   * can in case of uncertainty render a reCaptcha human verifier in the given element, which the user needs to verify.
   * @param triggerElementId the element on which the reCaptcha will be triggered. Needs to be rendered during whole process and re-rendered for each new get
   * @returns the reCaptcha verifier
   */
  getReCaptchaVerifier(triggerElementId = 'auth-container'): any {
    return new firebase.auth.RecaptchaVerifier(triggerElementId, {
      size: 'invisible',
      callback: function (response) {
        console.log('recaptcha response');
      },
    });
  }

  /**
   * Try's to login the user with normal credential.
   * If two factor authentication is enabled for this user, the login will fail with an specific error 'auth/multi-factor-auth-required'
   * @param email
   * @param password
   * @param reCaptchaTriggerElementId the element on which the reCaptcha will be triggered and potentially rendered. Needs to be rendered during whole process and re-rendered for each new get
   * @returns the user object if no 2FA is required
   */
  async login(email: string, password: string, reCaptchaTriggerElementId: string, observer?: Observer<Core.User>): Promise<Core.User> {
    if (isReactNative()) {
      return this.loginWithoutCaptcha(email, password, observer);
    }
    const recaptchaVerifier = this.getReCaptchaVerifier(reCaptchaTriggerElementId);
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      if (!userCredential.user) throw new Error('User is undefined!!!');
      const user = await new UserService().getUserById(userCredential.user.uid, observer);
      if (user.isTwoFAEnforced) {
        throw new TwoFARequireEnrollmentError('Enrollment of second factor is required');
      } else {
        // User is not enrolled with a second factor and does not need to enroll one
        return { ...user, is2FAEnrolled: false };
      }
    } catch (error) {
      if (error.code == 'auth/multi-factor-auth-required') {
        const resolver = error.resolver;
        if (resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          };
          const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
          resolver.verificationId = verificationId;
          throw new TwoFALoginError(error.message, error.resolver.hints[0].phoneNumber, resolver);
        } else {
          throw new Error('Your second factor is not supported');
        }
      } else {
        throw new Error(error);
      }
    }
  }

  async loginWithoutCaptcha(email: string, password: string, observer?: Observer<Core.User>): Promise<Core.User> {
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      if (!userCredential.user) throw new Error('User is undefined!!!');
      const user = await new UserService().getUserById(userCredential.user.uid, observer);
      return {...user, is2FAEnrolled: false};
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * Verify's the Code send to the users phone.
   * @param resolver the 2fa resolver provided by the error from the login method
   * @param verificationCode the code send as sms
   * @returns the user object when successfull
   */
  async verify2FACode(resolver: any, verificationCode: string, observer?: Observer<Core.User>): Promise<Core.User> {
    // Ask user for the SMS verification code.
    const cred = firebase.auth.PhoneAuthProvider.credential(resolver.verificationId, verificationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    return resolver.resolveSignIn(multiFactorAssertion).then(async function (userCredential) {
      // User successfully signed in with the second factor phone number.
      return {...(await new UserService().getUserById(userCredential.user.uid, observer)), is2FAEnrolled: true};
    });
  }

  //depreciated
  // async delete2FAFactor(resolver: any, verificationCode: string): Promise<Core.User> {
  //   // Ask user for the SMS verification code.
  //   const cred = firebase.auth.PhoneAuthProvider.credential(resolver.verificationId, verificationCode);
  //   const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
  //   // Complete sign-in.
  //   return resolver.resolveSignIn(multiFactorAssertion).then(async function (userCredential) {
  //     // User successfully signed in with the second factor phone number.
  //     return await userCredential.user.multiFactor.unenroll(userCredential.user.multiFactor.enrolledFactors[0]);
  //   });
  // }

  /**
   * 1/2 Steps of setting up 2FA authentication
   * executes reCaptcha and sends a sms with a verification code to the users phone
   * @param phoneNumber the new phone number to set as second factor
   * @param reCaptchaTriggerElementId the element on which the reCaptcha will be triggered and potentially rendered. Needs to be rendered during whole process and re-rendered for each new get
   * @returns the verificationID to identify the verification in step 2
   */
  async provide2faFactor(phoneNumber: string, reCaptchaTriggerElementId?: string): Promise<string> {
    const user = firebase.auth().currentUser;
    const recaptchaVerifier = this.getReCaptchaVerifier(reCaptchaTriggerElementId);
    const multiFactorSession = await user.multiFactor.getSession();
    // Specify the phone number and pass the MFA session.
    const phoneInfoOptions = {
      phoneNumber: phoneNumber,
      session: multiFactorSession,
    };
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    // Send SMS verification code.
    return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
  }

  /**
   * 2/2 Steps of setting up 2FA authentication
   * Enrolls the 2. factor for the user account
   * On successful enrollment, existing Firebase sessions (refresh tokens) are revoked. When a new factor is enrolled, an email notification is sent to the user’s email.
   * After enrollment the user will always need to login with 2FA
   * @param verificationId the verification id provided by the verifyPhoneNumber function in set2faFactor
   * @param verificationCode the verification Code which is given by the user
   * @returns an empty promise
   */
  async verify2faFactor(verificationId: string, verificationCode: string): Promise<void> {
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    return await user.multiFactor.enroll(multiFactorAssertion);
  }

  async tryAutoLogin(observer?: Observer<Core.User>): Promise<Core.User> {
    const user = await new Promise<firebase.default.User>((resolve, reject) => {
      firebase.auth().onAuthStateChanged({
        next: () => {
          const user = firebase.auth().currentUser;
          if (!user) reject('User is unauthenticated');
          else resolve(user);
        },
        complete: () => null,
        error: (error) => reject(error),
      });
    });
    return await new UserService().getUserById(user?.uid, observer);
  }
}
