import { API } from '@base/core';

export async function sendAcceptedNotification(entityId: string, status: 'accept' | 'reject', newBudget: string | number, campaignId: string, formId: string, applicationName: string) {
  const title = 'Your application has been ' + (status === 'reject' ? 'rejected' : 'accepted') + '! - IBU Scope';
  let emailText = '';

  if (status === 'accept') {
    emailText = `
    <p>You will be notified when the agreement is ready to be signed.</p></br>
    <p>In case of a lower budget than requested, the IBU Development Team contacts you directly.</p>
    <br/>
    `;
  }
  const ok = confirm(`Send "Application ${status === 'reject' ? 'rejected' : 'accepted'}" email?`);
  if (ok)
    await API.email.sendNotificationEmail({ groupIds: [entityId] }, title, {
      autosend: true,
      emailTitle: `Application ${status === 'reject' ? 'rejected' : 'accepted'}!`,
      // <p>Form Name: ${formName}</p></br>
      emailText: `
    <p>Dear National Federation</p></br></br>
    <p>Your application has been ${status === 'reject' ? 'rejected' : newBudget ? 'accepted with a budget of: ' + newBudget : 'accepted'} by IBU!</p></br>
    <p>Application Name: ${applicationName}</p></br>
    ${emailText}
    </br><p>You can click the button below to go to your application:</p>
    `,
      buttonLink: `${window.location.origin}/campaigns-external/${campaignId}/forms/${formId}`,
      belowButtonText: `
    <p>Best regards,</p>
    </br>
    <p>your IBU Scope Team</p>
    `,
    });
}
