import * as React from 'react';

function SvgQuoteRightRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.679 20.75" ref={svgRef} {...props}>
      <path
        d="M9.263 0H3.335A3.339 3.339 0 000 3.335v5.188a3.339 3.339 0 003.335 3.335h2.594v.371a1.852 1.852 0 01-1.853 1.851h-.371a2.224 2.224 0 00-2.223 2.22v2.223a2.224 2.224 0 002.223 2.227h.371a8.529 8.529 0 008.524-8.522V3.335A3.339 3.339 0 009.263 0zm1.112 12.228a6.306 6.306 0 01-6.3 6.3h-.37V16.3h.371a4.082 4.082 0 004.076-4.076v-2.59H3.335a1.115 1.115 0 01-1.112-1.112V3.335a1.115 1.115 0 011.112-1.112h5.928a1.115 1.115 0 011.112 1.112zM23.344 0h-5.929a3.339 3.339 0 00-3.335 3.335v5.188a3.339 3.339 0 003.335 3.335h2.594v.371a1.852 1.852 0 01-1.853 1.853h-.371a2.224 2.224 0 00-2.223 2.218v2.223a2.224 2.224 0 002.223 2.223h.371a8.529 8.529 0 008.522-8.522V3.335A3.339 3.339 0 0023.344 0zm1.112 12.228a6.306 6.306 0 01-6.3 6.3h-.371V16.3h.371a4.082 4.082 0 004.076-4.076v-2.59h-4.817A1.115 1.115 0 0116.3 8.522V3.335a1.115 1.115 0 011.112-1.112h5.929a1.115 1.115 0 011.112 1.112z"
        fill="#6d6d6d"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgQuoteRightRegular);
export default ForwardRef;
