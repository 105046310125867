import type { ThunkAction } from 'redux-thunk';
import { AccessObjectUpdate, PermissionsObject, UploadTaskSnapshot, VirtualFileCreationBase } from '../../entities';
import { FileRepo } from '../../repos';
import { FilesInteractor } from '../../useCases';
import type { StateType } from '../store';
import * as ACTIONS from './actions';
import { ActionsType } from './reducer';
// import { white } from 'chalk';

export type FileUpdate<T = Core.VirtualFile> = Partial<Omit<T, 'id' | 'permissions' | 'createTime' | 'favoriteOf' | 'lastUpdateTime'>> & {
  permissions?: { users: AccessObjectUpdate; groups: AccessObjectUpdate; visibility?: 'public' | 'private' };
  setFavorite?: { uid: string; isFavorite: boolean };
};

function folderExists(currentPath: string, fileTree: any) {
  return currentPath in fileTree;
}

function isFile(i: number, pathParts: string[]) {
  return i === pathParts.length - 1;
}

export class FilesThunk {
  constructor(private filesInteractor: FilesInteractor, private filesRepo: FileRepo) {}

  private getOptionalRightsAndGroupsFromUser(state: StateType): [string | undefined, string[] | undefined] {
    if (!state.auth.user) throw new Error('User is not logged in!');
    if (state.auth.user.roles?.superAdmin) return [undefined, undefined];
    const { id, groups } = state.auth.user;
    return [id, Object.keys(groups || {})];
  }

  fetchRootFiles = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.fetchRootFilesRequestAction());

      const files = await this.filesInteractor.getRootFiles();
      dispatch(ACTIONS.fetchRootFilesSuccessAction(files));
      dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
    } catch (error) {
      dispatch(ACTIONS.fetchRootFilesFailureAction(error));
    }
  };

  fetchChildren = (fileId: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.fetchChildrenRequestAction());
      const files = await this.filesInteractor.getChildren(fileId);
      dispatch(ACTIONS.fetchChildrenSuccessAction(files, fileId));
      dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
    } catch (error) {
      dispatch(ACTIONS.fetchChildrenFailureAction(error));
    }
  };

  updateFile = (fileId: string, update: FileUpdate): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.updateFileRequestAction());
      const user = getState().auth.user;
      if (!user) throw new Error('User is not logged in!');
      const { id } = user;
      const file = await this.filesInteractor.updateFile(fileId, update);
      dispatch(ACTIONS.updateFileSuccessAction(file, id));
      dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
    } catch (error) {
      dispatch(ACTIONS.updateFileFailureAction(error));
    }
  };

  createFile = <T extends VirtualFileCreationBase>(
    parent: string | null,
    accessProperties: PermissionsObject,
    mimeType: Core.FileType,
    other: T
  ): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.createFileRequestAction());
      const file = await this.filesInteractor.createVirtualFile(parent, accessProperties, mimeType, other);
      dispatch(ACTIONS.createFileSuccessAction(file));
      dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
    } catch (error) {
      dispatch(ACTIONS.createFileFailureAction(error));
    }
  };

  createFolder = <T extends VirtualFileCreationBase>(parent: string | null, accessProperties: PermissionsObject, other: T): ThunkAction<void, StateType, never, ActionsType> => async (
    dispatch,
    getState
  ) => {
    try {
      dispatch(ACTIONS.createFolderRequestAction());
      const folder = await this.filesInteractor.createVirtualFile(parent, accessProperties, 'folder', other);
      dispatch(ACTIONS.createFolderSuccessAction(folder));
      dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
      return folder;
    } catch (error) {
      dispatch(ACTIONS.createFolderFailureAction(error));
    }
  };

  updateFolder = (folderId: string, update: FileUpdate): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.updateFolderRequestAction());
      const file = await this.filesInteractor.updateFile(folderId, update);
      dispatch(ACTIONS.updateFolderSuccessAction(file));
      dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
    } catch (error) {
      dispatch(ACTIONS.updateFolderFailureAction(error));
    }
  };

  fetchFile = (fileId: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.fetchFileRequestAction());
      const file = this.filesInteractor.getFile(fileId);
      dispatch(ACTIONS.fetchFileSuccessAction(await file));
      dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
    } catch (error) {
      dispatch(ACTIONS.fetchFileFailureAction(error));
    }
  };

  updatePhysicalFile = (fileId: string, fileName: string, mimeType: Core.FileType, data: Blob | Uint8Array | ArrayBuffer): ThunkAction<void, StateType, never, ActionsType> => async (
    dispatch,
    getState
  ) => {
    try {
      dispatch(ACTIONS.updatePhysicalFileRequestAction());
      const task = this.filesInteractor.updateNormalFile(fileId, data);

      task.on(
        (sn) => {
          dispatch(ACTIONS.uploadTaskChangedAction({ ...sn, name: fileName, mimeType }));
        },
        (error) => {
          dispatch(
            ACTIONS.uploadTaskChangedAction({
              bytesTransferred: 0,
              state: 'error',
              fileId,
              name: fileName,
              mimeType,
            })
          );
        },
        (file) => {
          dispatch(
            ACTIONS.uploadTaskChangedAction({
              state: 'success',
              fileId,
              name: fileName,
              mimeType,
            })
          );
        }
      );

      dispatch(ACTIONS.updatePhysicalFileSuccessAction(await task));
      dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
    } catch (error) {
      dispatch(ACTIONS.updatePhysicalFileFailureAction(error));
    }
  };

  uploadFilesWithinFolders = (files: (File & { path: string })[], currentFolderId: string, accessProperties): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.uploadFilesWithinFoldersRequestAction());
      const fileTree = {};
      for (const file of files) {
        let pathParts: string[] = file.path.split('/');
        pathParts = pathParts.filter((part) => !(part === '' || part === ' ' || part === '.'));

        for (let i = 0; i < pathParts.length; i++) {
          const currentPath = pathParts.slice(0, i + 1).join('/');
          const parentPath = pathParts.slice(0, i).join('/');
          const pathPart = pathParts[i];

          if (isFile(i, pathParts)) {
            const parentFolderId = parentPath ? fileTree[parentPath].id : currentFolderId;
            // is file
            // const newFile = await this.filesRepo.createNormalFile(parentFolderId, file, accessProperties, file.type as any, {
            //   name: file.name,
            // });
            const newFile = await ((dispatch(this.createPhysicalFile(parentFolderId, file, accessProperties, file.type as any, file.name)) as any) as Promise<Core.VirtualFile | undefined>);
            if (!newFile) throw new Error('File creation failed: ' + file.name + ' within path: ' + currentPath);

            fileTree[currentPath] = {
              name: file.name,
              type: file.type,
              id: newFile.id,
              parentId: parentFolderId,
            };
            break;
          }
          // create folder if not already created
          if (!folderExists(currentPath, fileTree)) {
            const parentFolderId = parentPath ? fileTree[parentPath].id : currentFolderId;
            //upload Folder
            // const newFolder = await this.filesInteractor.createVirtualFile(parentFolderId, accessProperties, 'folder', { name: pathPart });
            const newFolder = await ((dispatch(this.createFolder(parentFolderId, accessProperties, { name: pathPart })) as any) as Promise<Core.VirtualFile | undefined>);
            if (!newFolder) throw new Error('Folder creation failed');

            fileTree[currentPath] = {
              name: pathPart,
              type: 'folder',
              id: newFolder.id,
              parentId: parentFolderId,
            };
          }
        }
      }
      console.log('Uploaded FileTree: ', JSON.stringify(fileTree, null, 2));
      dispatch(ACTIONS.uploadFilesWithinFoldersSuccessAction(fileTree));
    } catch (error) {
      dispatch(ACTIONS.uploadFilesWithinFoldersFailureAction(error));
    }
  };

  createPhysicalFile = <T extends Partial<Omit<Core.VirtualFile, 'id' | 'trashed' | 'type' | 'permissons' | 'parent'>>>(
    parent: string | null,
    data: Blob | Uint8Array | ArrayBuffer,
    accessProperties: PermissionsObject,
    mimeType: Core.FileType,
    name
  ): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      const task = this.filesInteractor.createNormalFile(parent, data, accessProperties, mimeType, { name: name });

      task.on(
        (sn) => {
          dispatch(ACTIONS.uploadTaskChangedAction({ ...sn, name, mimeType, task }));
        },
        (error, id) => {
          dispatch(
            ACTIONS.uploadTaskChangedAction({
              bytesTransferred: 0,
              state: 'error',
              fileId: id,
              name: name,
              mimeType: mimeType,
              task,
            })
          );
        },
        (file) => {
          dispatch(
            ACTIONS.uploadTaskChangedAction({
              state: 'success',
              fileId: file.id,
              name: file.name,
              mimeType: file.type,
              task,
            })
          );
          dispatch(ACTIONS.createPhysicalFileRequestAction());
          void this.filesRepo.cacheFile(file);
          dispatch(ACTIONS.createPhysicalFileSuccessAction(file as Core.NormalFile));
          dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
        }
      );
      return task;
    } catch (error) {
      dispatch(ACTIONS.createPhysicalFileFailureAction(error));
    }
  };

  deleteFile = (fileId: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.deleteFileRequestAction());

      await this.filesInteractor.deleteFile(fileId);

      dispatch(ACTIONS.deleteFileSuccessAction(fileId));
      dispatch(ACTIONS.updateFileMapAction(this.filesRepo.getFileMap()));
    } catch (error) {
      dispatch(ACTIONS.deleteFileFailureAction(error));
    }
  };

  fetchFavorites = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.fetchFavoritesRequestAction());
      const files = await this.filesInteractor.getFavoriteFiles();
      dispatch(ACTIONS.fetchFavoritesSuccessAction(files));
    } catch (error) {
      dispatch(ACTIONS.fetchFavoritesFailureAction(error));
    }
  };

  clearCompletedTasks = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.clearCompletedUploadTasksAction());
    } catch (error) {
      // dispatch(ACTIONS.fetchFavoritesFailureAction(error));
    }
  };
  deleteCanceledTask = (task: UploadTaskSnapshot): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.deleteCancledTaskAction(task));
    } catch (error) {
      // dispatch(ACTIONS.fetchFavoritesFailureAction(error));
    }
  };
}
