import { AccessObjectUpdate, Actions } from '@base/core';
import { faSave, faTrash } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { StandardButton, usePopup } from '../../components/common';
import { GridInputElement, GridMultilineInput, PictogramChooser, UploadImage } from '../../components/content/GridContent';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import { AddUserPopup, calculateNewPermissions, ConfigurableUser, extractUsersWithRolesFromPermissions, getRightsObjFromRole } from '../../components/users';
import { UserSmallGrid } from '../../components/users/UserSmallGrid';
import '../../css/components/common/layout/InputGrid.scss';
import '../../css/components/common/layout/usergrid.scss';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { ConditionalRenderWithRightsForFiles } from '../../helpers/ConditionalRenderWithRights';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { useTheme } from '../../theme/ThemeProvider';
import { FileModule } from '../campaign/CampaignOverview';
import { useParentFolderBreadCrumbs } from './FileManagerHelper';
import { useDeleteFileMutation, useGetFile, useUpdateFileMutation, useUpdatePhysicalFileMutation } from './hooks';
import { useAutoUpdateState } from './useAutoUpdateState';
import { usePromiseResolve } from './usePromiseResolve';

export interface FileUpdate extends Partial<Omit<Core.VirtualFile, 'id' | 'permissions' | 'createTime' | 'lastUpdateTime'>> {
  permissions?: { users: AccessObjectUpdate; groups: AccessObjectUpdate; visibility?: 'public' | 'private' };
}

export const ModuleSettings = () => {
  const theme = useTheme();
  const history = useHistory();
  const [addUserPopupVisible, setAddUserPopupVisible] = useState(false);
  const dispatch = useDispatch();
  const notification = usePopup();

  const { fileId } = useParams<{ fileId: string }>();

  const { data: currentModule, isLoading, isError } = useGetFile<FileModule>(fileId);
  const {
    usersList = [],
    fetching: { queryUsers },
  } = useSelector((state: Core.StateType) => state.users);

  useEffect(() => {
    if (!queryUsers.fetching && usersList.length == 0) dispatch(Actions().users.queryUsers());
  }, []);

  const [cu, setCurrentUsers] = useAutoUpdateState(currentModule?.permissions?.users ? extractUsersWithRolesFromPermissions(currentModule.permissions.users) : [], [currentModule]);

  const currentUsers = useMemo(
    () =>
      cu.map(({ uid, role }) => {
        const user = usersList.find((u) => u.id == uid);
        if (user)
          return {
            user,
            uid,
            role,
          };
        return { uid, role };
      }),
    [usersList, cu],
  );

  const [pictogram, setPictogram] = useState(currentModule?.icon);

  const [name, setName] = useAutoUpdateState(currentModule?.name);
  const [description, setDescription] = useAutoUpdateState(currentModule?.description);
  const [image, setImage] = useAutoUpdateState(usePromiseResolve(currentModule?.downloadUrl));
  const [imageFile, setImageFile] = useState<File>(null);

  const deleteFileMutation = useDeleteFileMutation();
  const additionalBreadcrumbs = useParentFolderBreadCrumbs(fileId);

  const deleteElement = async () => {
    await deleteFileMutation.mutateAsync({ id: fileId, parent: null, type: 'folder' } as any);
    history.push('/files/');
  };
  const { t } = useTranslation();

  const updateMutation = useUpdateFileMutation();
  const updatePhysicalMutation = useUpdatePhysicalFileMutation();

  if (!currentModule && !isLoading) {
    return <ModuleWasDeleted />;
  }
  const handleUpdateUserRoles = async (usersToAdd: { uid: string; role: string }[]) => {
    const newRights = calculateNewPermissions(currentModule.permissions.users, usersToAdd);
    try {
      await updateMutation.mutateAsync({
        fileId,
        update: {
          permissions: {
            users: {
              read: { set: newRights.read },
              write: { set: newRights.write },
              move: { set: newRights.move },
              permission: { set: newRights.permission },
            },
            groups: {},
          },
        },
      });
    } catch (error) {
      notification({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    }
  };

  const handleUserRoleChanged = async (uid: string, previouseRole: string, currentRole: string) => {
    const prev = getRightsObjFromRole(previouseRole) ?? {};
    const curr = getRightsObjFromRole(currentRole) ?? {};

    const userPermissions = {
      read: {} as { remove: string[]; add: string[] },
      write: {} as { remove: string[]; add: string[] },
      move: {} as { remove: string[]; add: string[] },
      permission: {} as { remove: string[]; add: string[] },
    };
    prev.editPermissions && (userPermissions.permission.remove = [uid]);
    prev.move && (userPermissions.move.remove = [uid]);
    prev.write && (userPermissions.write.remove = [uid]);
    prev.read && (userPermissions.read.remove = [uid]);
    curr.editPermissions && (userPermissions.permission.add = [uid]);
    curr.move && (userPermissions.move.add = [uid]);
    curr.write && (userPermissions.write.add = [uid]);
    curr.read && (userPermissions.read.add = [uid]);

    try {
      await updateMutation.mutateAsync({
        fileId,
        update: {
          permissions: {
            users: userPermissions,
            groups: {},
          },
        },
      });
    } catch (error) {
      notification({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    }
  };

  const saveModule = async () => {
    try {
      if (imageFile) {
        await updatePhysicalMutation.mutateAsync({ fileId, data: imageFile });
      }
      const change: FileUpdate & Partial<FileModule> = {};
      if (pictogram != currentModule.icon && pictogram) {
        change.icon = pictogram;
      }

      if (name != currentModule.name) {
        change.name = name;
      }

      if (description != currentModule.description) {
        change.description = description;
      }

      if (Object.keys(change).length > 0) {
        await updateMutation.mutateAsync({ fileId, update: change });
      }
      history.push('/files/');
    } catch (error) {
      notification({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    }
  };

  return (
    <ContentContainer>
      <AddUserPopup active={addUserPopupVisible} setActive={setAddUserPopupVisible} users={usersList} onUserSelect={handleUpdateUserRoles} />
      <NavbarSmall title={t('module-settings')} breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.moduleOverview, ...additionalBreadcrumbs, BREADCRUMBS.moduleEdit]}>
        <div style={{ display: 'flex' }}>
          <StandardButton contentColor="#fff" onClick={() => history.goBack()} text={t('abort')} bgColor="#ffffff30" />
          <ConditionalRenderWithRightsForFiles parent={currentModule} move>
            <StandardButton
              contentColor="#fff"
              onClick={() => {
                notification({
                  title: t('confirm-delete'),
                  text: t('confirm-delete-module-text'),
                  type: 'error',
                  blocking: true,
                  actions: [
                    { text: t('delete'), action: () => deleteElement(), type: 'error' },
                    { text: t('cancel'), action: () => null, type: 'info' },
                  ],
                  time: 0,
                });
              }}
              text={t('delete-module')}
              bgColor={theme.colors.error}
              faIcon={faTrash}
            />
          </ConditionalRenderWithRightsForFiles>
          <StandardButton contentColor="white" onClick={saveModule} text={t('save')} bgColor="#ffffff30" faIcon={faSave} loading={updatePhysicalMutation.isLoading} />
        </div>
      </NavbarSmall>
      <ContentView loading={isLoading}>
        <div className="content-title">{t('module-informations')}</div>
        <div className="input-grid">
          <GridInputElement paddingVertical={false} value={name ?? ''} placeholder={t('module-title')} onText={setName} />
          <UploadImage
            image={image}
            setImage={(url, file) => {
              setImage(url);
              setImageFile(file);
            }}
            title={t('upload-thumbnail')}
            subTitle={t('pic-for-easy-view-of-topic')}
          />
          <PictogramChooser icon={pictogram} onSelect={(icon) => setPictogram(icon)} />
          {/*TODO: To be added in the future <ColorChooser /> */}
          {/* <ColorChooser /> */}
          <GridMultilineInput value={description ?? ''} placeholder={t('description-of-module')} onText={setDescription} charMax={300} />
        </div>
        <ConditionalRenderWithRightsForFiles parent={currentModule} changePermissions>
          <div className="content-title mg-top">{t('module-release')}</div>
          <UserSmallGrid
            addUserClick={() => setAddUserPopupVisible(true)}
            changeLinkClick={() => null}
            visibility={currentModule?.permissions?.visibility}
            onVisibilityChange={async (visibility) => {
              try {
                await updateMutation.mutateAsync({
                  fileId,
                  update: {
                    permissions: {
                      users: {},
                      groups: {},
                      visibility,
                    },
                  },
                });
              } catch (error) {
                notification({
                  title: t('error'),
                  type: 'error',
                  text: error.message,
                });
              }
            }}
          >
            {currentUsers
              .sort((a, b) => a.uid.localeCompare(b.uid))
              .map((u) => (
                <ConfigurableUser
                  key={u.uid}
                  userName={u.user?.displayName ?? u.uid}
                  userImage={u.user?.photoURL}
                  role={u.role}
                  onRoleSelect={(role) =>
                    setCurrentUsers((c) =>
                      c.map((user) => {
                        if (user.uid === u.uid) {
                          void handleUserRoleChanged(u.uid, user.role, role);
                          return { ...u, role };
                        }
                        return user;
                      }),
                    )
                  }
                />
              ))}
          </UserSmallGrid>
        </ConditionalRenderWithRightsForFiles>
      </ContentView>
    </ContentContainer>
  );
};

function ModuleWasDeleted() {
  const { t } = useTranslation();
  return (
    <ContentContainer>
      <NavbarSmall title={t('module-was-deleted')} breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.moduleOverview, BREADCRUMBS.moduleEdit]}></NavbarSmall>
    </ContentContainer>
  );
}
