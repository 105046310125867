import {
  archivedEmail,
  Campaign,
  EmailIdentitys,
  EmailSenderIdentity,
  EmailTemplateNames,
  EmailTemplateTypes,
  IEmailService
} from '@base/core';
import * as Mustache from 'mustache';
import {firebase} from '../config';
import {createCallableApi} from './createCallableApi';

const ARCHIVE_COLLECTION = 'email_archive';

export class EmailService implements IEmailService {
  async sendEmail(from: EmailSenderIdentity, to: EmailIdentitys, subject: string, htmlContent?: string, replyTo?: EmailIdentitys, cc?: EmailIdentitys): Promise<void> {
    await createCallableApi(
      '/email/',
      'POST'
    )({
      from,
      to,
      subject,
      htmlContent,
      replyTo,
      cc,
    });
  }

  async sendNotification(to: EmailIdentitys, subject: string, templateContent?: EmailTemplateTypes, templateName?: EmailTemplateNames): Promise<void> {
    await createCallableApi(
      '/email/notification',
      'POST'
    )({
      to,
      subject,
      templateContent,
      templateName,
    });
  }

  async sendChatNotification(to: EmailIdentitys,
                             message: string,
                             questionTitle: string,
                             openUrl: string,
                             formName: string): Promise<void> {
    await createCallableApi(
      '/email/chat-notification',
      'POST'
    )({
      to,
      message,
      questionTitle,
      openUrl,
      formName
    });
  }

  async getArchivedEmails(autosend: boolean): Promise<archivedEmail[]> {
    const querySnapShot = await firebase.firestore().collection(ARCHIVE_COLLECTION).where('template.autosend', '==', autosend).get();
    return querySnapShot.docs.map((doc) => {
      return {id: doc.id, ...doc.data()} as archivedEmail;
    });
  }

  async deleteArchivedEmail(id: string) {
    await firebase.firestore().collection(ARCHIVE_COLLECTION).doc(id).delete();
  }
}

export class EmailTemplates {
  public ibuAccessRequestAllowedSubject = 'Access Request accepted! - IBU Scope';
  public ibuAccessRequestDeniedSubject = 'Access Request Rejected- IBU Scope';

  //as example
  // getNewAccessRequestContent(requesterName: string, requesterOrganisation: string) {
  //   const newAccessRequestTemplate = `
  //   <p>Es gibt eine neue Zugriffsanfrage von {{requesterName}} der Nation {{requesterOrganisation}}.</p>
  //   </br>
  //   <p>Gehen sie in die LearningSuite um den Request zu bearbeiten</p>
  //   `;
  //   return Mustache.render(newAccessRequestTemplate, { requesterName, requesterOrganisation });
  // }

  // getNewAccessRequestUserContent(requesterName: string, requesterOrganisation: string) {
  //   const newAccessRequestTemplate = `
  //   <h2>Welcome to IBU Scope!</h2>
  //   <p>Your request was verified successfull. A user account for {{requesterName}} for the federation {{requesterOrganisation}} was created.</br>
  //   You can now log in, add more user to your federation and apply for applications.
  //   </br></br>
  //   We hope you have an efficient and easy experience on our plattform.
  //   </br>
  //   </br>
  //   </br>
  //   IBU Scope - powered by SeekInnovation</p>
  //   `;
  //   return Mustache.render(newAccessRequestTemplate, { requesterName, requesterOrganisation });
  // }

  getAccessRequestVerifiedContent(requesterName: string) {
    const newAccessRequestTemplate = `
      <p>
      Dear {{requesterName}},
      </br></br>
      Congratulations! you are now a verified user inside the IBU Scope platform.
      </br></br>
      From now on you can take part in applications for various IBU events and projects.
      </br></br>
      You will receive your login data in a separate email in just a moment.
      </br></br>
      If you think that your request has been mistakenly refused please forward this mail to support@ibu-scope.com
      </br></br>
      Thank you!
      </br></br>
      Best regards,
      </br>
      your IBU Scope Team
      </p>
      `;
    return Mustache.render(newAccessRequestTemplate, {requesterName});
  }

  getAccessRequestDeniedContent(requesterName: string, message: string) {
    const newAccessRequestTemplate = `
      <p>Dear {{requesterName}},
      </br></br>
      unfortunately your request for access to IBU Scope has been denied.
      </br></br>Stated reason of rejection from IBU:</p></br>
      {{#message}}
        {{message}}
      {{/message}}
      {{^message}}
        <p>IBU did not provide a reason for rejection</p>
      {{/message}}
      </br></br>
      <p>To once more request access, just register again on IBU Scope or click the following link: https://ibu-scope.com/
      </br></br>
      If you think that your request has been mistakenly refused please forward this mail to support@ibu-scope.com
      </br></br>
      Thank you!
      </br></br>
      Best regards,
      </br>
      your IBU Scope Team
      </p>
      `;
    return Mustache.render(newAccessRequestTemplate, {requesterName, message});
  }

  getNewCampaignAvailableContent(campaign: Campaign) {
    const newAccessRequestTemplate = `
      <p>
      Dear IBU Scope User</b></br>
      IBU published the new development project: {{campaign.name}} {{#campaign.deadline}} with deadline {{campaign.deadline}}{{/campaign.deadline}}
      </br></br>
      {{#campaign.description}}
      Described as:</br>{{campaign.description}}
      </br></br>
      {{/campaign.description}}
      If you think that there is a problem with this email please forward this mail to support@ibu-scope.com and tell us what went wrong.
      </br></br>
      Thank you!
      </br></br>
      Best regards,
      </br>
      your IBU Scope Team
      </p>
      `;
    return Mustache.render(newAccessRequestTemplate, {campaign});
  }
}
