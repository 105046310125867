import { Group, GroupTag, IGroupsService } from '@base/core';
import { firebase } from '../config';
import cuid from 'cuid';

import { GROUP_COLLECTION, USER_COLLECTION, GROUP_TAG_COLLECTION } from '../config';
import { createCallableApi } from './createCallableApi';

export class GroupService implements IGroupsService {
  public get groupCollection(): firebase.default.firestore.CollectionReference {
    return firebase.firestore().collection(GROUP_COLLECTION);
  }
  public get groupTagCollection(): firebase.default.firestore.CollectionReference {
    return firebase.firestore().collection(GROUP_TAG_COLLECTION);
  }

  public get userCollection(): firebase.default.firestore.CollectionReference {
    return firebase.firestore().collection(USER_COLLECTION);
  }

  async deleteGroup(groupID: string): Promise<void> {
    const usersWithGroupSN = await this.userCollection.where(`groups.${groupID}`, '==', true).get();

    const usersWithGroup = usersWithGroupSN.docs;

    await firebase.firestore().runTransaction(async (t) => {
      t.delete(this.groupCollection.doc(groupID));

      await createCallableApi(`/groups/${groupID}/users`, 'PATCH')({ add: [], remove: usersWithGroup.map((u) => u.id) });

      for (const user of usersWithGroup) {
        t.update(user.ref, {
          [`groups.${groupID}`]: firebase.firestore.FieldValue.delete(),
        });
      }
    });
  }

  async updateUsersForGroup(groupId: string, update: { add: string[]; remove: string[] }): Promise<void> {
    await createCallableApi(`/groups/${groupId}/users`, 'PATCH')(update);
  }

  async updateGroup(groupId: string, update: Partial<Pick<Group, 'name' | 'icon'>>): Promise<void> {
    await this.groupCollection.doc(groupId).update(update);
  }

  async createGroup<T extends Omit<Group, 'id'>>(g: T): Promise<T & { id: string }> {
    const id = cuid();
    const res = await this.groupCollection.doc(id).set(g);

    return { id, ...g };
  }

  async getGroups(): Promise<Group[]> {
    const groupsSN = await this.groupCollection.get();

    return groupsSN.docs.map((d) => ({ ...(d.data() as Group), id: d.id }));
  }

  async getGroup(groupId: string): Promise<Group> {
    const groupsSN = await this.groupCollection.doc(groupId).get();

    return { ...(groupsSN.data() as Group), id: groupsSN.id };
  }

  async createGroupTag<T extends Omit<GroupTag, 'id'>>(g: T): Promise<T & { id: string }> {
    const res = await this.groupTagCollection.add(g);

    return { id: res.id, ...g };
  }

  async updateGroupTag<T extends Omit<GroupTag, 'id'>>(groupTagId: string, update: T): Promise<void> {
    await this.groupTagCollection.doc(groupTagId).update(update);
  }

  async deleteGroupTag(groupTagId: string): Promise<void> {
    await this.groupTagCollection.doc(groupTagId).delete();
  }

  async getGroupTags(): Promise<GroupTag[]> {
    const sn = await this.groupTagCollection.get();
    return sn.docs.map((d) => ({ ...(d.data() as GroupTag), id: d.id }));
  }
}
