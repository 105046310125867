import { Group } from '../../entities/Group'
import * as ACTION_TYPES from './types'

export const fetchGroupsRequestAction = () => {
	return ({
		type: ACTION_TYPES.FETCH_GROUPS_REQUEST as typeof ACTION_TYPES.FETCH_GROUPS_REQUEST,
	})
}

export const fetchGroupsSuccessAction = (groups: Group[]) => {
	return ({
		type: ACTION_TYPES.FETCH_GROUPS_SUCCESS as typeof ACTION_TYPES.FETCH_GROUPS_SUCCESS,
		groups
	})
}

export const fetchGroupsFailureAction = (error: any) => {
	return ({
		type: ACTION_TYPES.FETCH_GROUPS_FAILURE as typeof ACTION_TYPES.FETCH_GROUPS_FAILURE,
		error
	})
}

export const createGroupRequestAction = () => {
	return ({
		type: ACTION_TYPES.CREATE_GROUP_REQUEST as typeof ACTION_TYPES.CREATE_GROUP_REQUEST,
	})
}

export const createGroupSuccessAction = (group: Group) => {
	return ({
		type: ACTION_TYPES.CREATE_GROUP_SUCCESS as typeof ACTION_TYPES.CREATE_GROUP_SUCCESS,
		group
	})
}

export const createGroupFailureAction = (error: any) => {
	return ({
		type: ACTION_TYPES.CREATE_GROUP_FAILURE as typeof ACTION_TYPES.CREATE_GROUP_FAILURE,
		error
	})
}

export const deleteGroupRequestAction = () => {
	return ({
		type: ACTION_TYPES.DELETE_GROUP_REQUEST as typeof ACTION_TYPES.DELETE_GROUP_REQUEST,
	})
}

export const deleteGroupSuccessAction = (groupID: string) => {
	return ({
		type: ACTION_TYPES.DELETE_GROUP_SUCCESS as typeof ACTION_TYPES.DELETE_GROUP_SUCCESS,
		groupID
	})
}

export const deleteGroupFailureAction = (error: any) => {
	return ({
		type: ACTION_TYPES.DELETE_GROUP_FAILURE as typeof ACTION_TYPES.DELETE_GROUP_FAILURE,
		error
	})
}

export const updateGroupRequestAction = () => {
	return ({
		type: ACTION_TYPES.UPDATE_GROUP_REQUEST as typeof ACTION_TYPES.UPDATE_GROUP_REQUEST,
	})
}

export const updateGroupSuccessAction = (groupID: string, update: Partial<Omit<Group, 'id'>>) => {
	return ({
		type: ACTION_TYPES.UPDATE_GROUP_SUCCESS as typeof ACTION_TYPES.UPDATE_GROUP_SUCCESS,
		update, groupID
	})
}

export const updateGroupFailureAction = (error: any) => {
	return ({
		type: ACTION_TYPES.UPDATE_GROUP_FAILURE as typeof ACTION_TYPES.UPDATE_GROUP_FAILURE,
		error
	})
}

export const updateUsersRequestAction = () => {
	return ({
		type: ACTION_TYPES.UPDATE_USERS_REQUEST as typeof ACTION_TYPES.UPDATE_USERS_REQUEST,
	})
}

export const updateUsersSuccessAction = () => {
	return ({
		type: ACTION_TYPES.UPDATE_USERS_SUCCESS as typeof ACTION_TYPES.UPDATE_USERS_SUCCESS
	})
}

export const updateUsersFailureAction = (error: any) => {
	return ({
		type: ACTION_TYPES.UPDATE_USERS_FAILURE as typeof ACTION_TYPES.UPDATE_USERS_FAILURE,
		error
	})
}
