export const FETCH_ROOT_FILES_REQUEST = 'files/fetch_root_files_request';
export const FETCH_ROOT_FILES_SUCCESS = 'files/fetch_root_files_success';
export const FETCH_ROOT_FILES_FAILURE = 'files/fetch_root_files_failure';
export const FETCH_CHILDREN_REQUEST = 'files/fetch_children_request';
export const FETCH_CHILDREN_SUCCESS = 'files/fetch_children_success';
export const FETCH_CHILDREN_FAILURE = 'files/fetch_children_failure';
export const UPDATE_FILE_REQUEST = 'files/update_file_request';
export const UPDATE_FILE_SUCCESS = 'files/update_file_success';
export const UPDATE_FILE_FAILURE = 'files/update_file_failure';
export const CREATE_FILE_REQUEST = 'files/create_file_request';
export const CREATE_FILE_SUCCESS = 'files/create_file_success';
export const CREATE_FILE_FAILURE = 'files/create_file_failure';
export const CREATE_FOLDER_REQUEST = 'files/create_folder_request';
export const CREATE_FOLDER_SUCCESS = 'files/create_folder_success';
export const CREATE_FOLDER_FAILURE = 'files/create_folder_failure';
export const UPDATE_FOLDER_REQUEST = 'files/update_folder_request';
export const UPDATE_FOLDER_SUCCESS = 'files/update_folder_success';
export const UPDATE_FOLDER_FAILURE = 'files/update_folder_failure';
export const UPDATE_FILE_MAP = 'files/update_file_map';
export const FETCH_FILE_REQUEST = 'files/fetch_file_request';
export const FETCH_FILE_SUCCESS = 'files/fetch_file_success';
export const FETCH_FILE_FAILURE = 'files/fetch_file_failure';
export const UPDATE_PHYSICAL_FILE_REQUEST = 'files/update_physical_file_request';
export const UPDATE_PHYSICAL_FILE_SUCCESS = 'files/update_physical_file_success';
export const UPDATE_PHYSICAL_FILE_FAILURE = 'files/update_physical_file_failure';
export const CREATE_PHYSICAL_FILE_REQUEST = 'files/create_physical_file_request';
export const CREATE_PHYSICAL_FILE_SUCCESS = 'files/create_physical_file_success';
export const CREATE_PHYSICAL_FILE_FAILURE = 'files/create_physical_file_failure';
export const UPLOAD_TASK_CHANGED = 'files/upload_task_changed';
export const DELETE_FILE_REQUEST = 'files/delete_file_request';
export const DELETE_FILE_SUCCESS = 'files/delete_file_success';
export const DELETE_FILE_FAILURE = 'files/delete_file_failure';
export const FETCH_FAVORITES_REQUEST = 'files/fetch_favorites_request';
export const FETCH_FAVORITES_SUCCESS = 'files/fetch_favorites_success';
export const FETCH_FAVORITES_FAILURE = 'files/fetch_favorites_failure';
export const CLEAR_COMPLETED_UPLOAD_TASKS = 'files/clear_completed_upload_tasks';
export const DELETE_CANCLED_TASK = 'files/delete_cancled_task';
export const UPLOAD_FILES_WITHIN_FOLDERS_REQUEST = 'files/upload_files_within_folders_request';
export const UPLOAD_FILES_WITHIN_FOLDERS_SUCCESS = 'files/upload_files_within_folders_success';
export const UPLOAD_FILES_WITHIN_FOLDERS_FAILURE = 'files/upload_files_within_folders_failure';
