import * as ACTION_TYPES from "./types";
import type * as ACTIONS from "./actions";
import {
  FetchingType,
  createDefault,
  createFetching,
  createSuccess,
	createError,
	removeKey
} from "../common";
import { Group } from "../../entities/Group";

type ValueOf<T> = T[keyof T];
export type ActionsType = ValueOf<
  { [k in keyof typeof ACTIONS]: ReturnType<typeof ACTIONS[k]> }
>;

export type initialStateType = {
  fetching: {
    fetchGroups: FetchingType;
    createGroup: FetchingType;
    deleteGroup: FetchingType;
    updateGroup: FetchingType;
    updateUsers: FetchingType;
  };
  groups: Group[];
};

const initialState: initialStateType = {
  fetching: {
    fetchGroups: createDefault(),
    createGroup: createDefault(),
    deleteGroup: createDefault(),
    updateGroup: createDefault(),
    updateUsers: createDefault(),
  },
  groups: [],
};

export const groupsReducer = (
  state: initialStateType = initialState,
  action: ActionsType
): initialStateType => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_USERS_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUsers: createFetching(),
        },
      };
    case ACTION_TYPES.UPDATE_USERS_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUsers: createSuccess(),
        },
      };
    case ACTION_TYPES.UPDATE_USERS_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUsers: createError(action.error),
        },
      };
    case ACTION_TYPES.FETCH_GROUPS_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchGroups: createFetching(),
        },
      };
    case ACTION_TYPES.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchGroups: createSuccess(),
        },
        groups: action.groups,
      };
    case ACTION_TYPES.FETCH_GROUPS_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchGroups: createError(action.error),
        },
        groups: [],
      };
    case ACTION_TYPES.CREATE_GROUP_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createGroup: createFetching(),
        },
      };
    case ACTION_TYPES.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createGroup: createSuccess(),
        },
        groups: [...state.groups, action.group],
      };
    case ACTION_TYPES.CREATE_GROUP_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createGroup: createError(action.error),
        },
      };
    case ACTION_TYPES.DELETE_GROUP_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          deleteGroup: createFetching(),
        },
      };
    case ACTION_TYPES.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          deleteGroup: createSuccess(),
				},
				groups: (state.groups??[]).filter(g=> g.id != action.groupID)
      };
    case ACTION_TYPES.DELETE_GROUP_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          deleteGroup: createError(action.error),
        },
      };
    case ACTION_TYPES.UPDATE_GROUP_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateGroup: createFetching(),
        },
      };
    case ACTION_TYPES.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateGroup: createSuccess(),
        },
        groups: state.groups.map((g) =>
          g.id == action.groupID ? { ...g, ...action.update } : g
        ),
      };
    case ACTION_TYPES.UPDATE_GROUP_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateGroup: createError(action.error),
        },
      };
    default:
      return state;
  }
};
