import React from 'react';
import '../../css/components/common/language.scss';
import { useTheme } from '../../theme/ThemeProvider';

type languageProps = {
  image: string | undefined;
  languageName: string;
  selected: boolean;
  bgColor?: string;
  contentColor?: string;
  onClick: () => void;
};

export const Language = ({ image, languageName, selected = false, bgColor = 'white', onClick, contentColor = 'black' }: languageProps) => {
  const theme = useTheme();

  return (
    <div onClick={onClick} className="language-con" style={{ backgroundColor: selected ? theme.colors['main-color'] : bgColor }}>
      <img className="language-img" src={image} alt='language icon'/>
      <p style={{ color: selected ? 'white' : contentColor }} className="language-name">
        {languageName}
      </p>
    </div>
  );
};
