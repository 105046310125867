import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@material-ui/core';
import React from 'react';
import { generateBiathlonYears } from './Settings';

const options = generateBiathlonYears();
export function BiathlonYearPicker({ clearEnabled, ...props }: SelectProps & { clearEnabled?: boolean }) {
  return (
    <FormControl>
      <Select {...props} variant={'outlined'} label="Biathlon Season" labelId="select-label">
        {options.map((o) => (
          <MenuItem key={o} value={o}>
            {o}
          </MenuItem>
        ))}
        {clearEnabled && <MenuItem value={'all'}>All</MenuItem>}
      </Select>
      <InputLabel id="select-label">Biathlon Season</InputLabel>
    </FormControl>
  );
}
