import { Meta, Story } from '@storybook/react/types-6-0';
import React, { ComponentProps } from 'react';
import { AppealRequest } from './AppealRequest';
import { withKnobs, boolean } from '@storybook/addon-knobs';
// Declare which Story you want. With the / syntax grouping is possible

export default {
  title: 'AppealRequest',
  component: AppealRequest,
  decorators: [withKnobs],
} as Meta;

// Generate the Template

const Template: Story<ComponentProps<typeof AppealRequest>> = (args) => <AppealRequest {...args} active={true} loading={boolean('Loading', false)} />;

// Fill the Template

// Fill the Templatem with default values

export const Default = Template.bind({});
