import '../../css/components/common/content/userSmall.scss';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { DropdownElement } from '../common';
import { SVGImage } from '../../lib';
import { useTranslation } from 'react-i18next';

interface UserProps {
  text: string;
  userImage?: string;
  optIcon?: SVGImage;
  infoText?: string;
  bgColor?: string;
  contentColor?: string;
}

function cssClasses(conditionalNames: { [className: string]: boolean }, ...otherClassnames: string[]) {
  const classNames = [
    ...otherClassnames,
    ...Object.entries(conditionalNames)
      .filter(([className, isClassNameActive]) => isClassNameActive)
      .map(([className, isClassNameActive]) => className),
  ];
  return classNames.join(' ');
}

export const UserSmall = ({ text, userImage, optIcon: Icon, infoText, contentColor = '#6D6D6D', bgColor = '#ffffff' }: UserProps) => {
  const [dropDownActive, setDropdownActive] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className="user-element-small" style={{ backgroundColor: bgColor, color: contentColor }}>
      <div className="inner-grid-con user-small">
        <div className="user-element-icon-con">
          {Icon && <Icon className="user-small-image" />}
          {userImage && <img src={userImage} className="user-small-image" />}
          <div className="user-con-small-text">{text}</div>
        </div>
        {infoText && <div className="info-button" />}
        <div
          className="user-dropdown-btn"
          onClick={() => {
            setDropdownActive(!dropDownActive);
            console.log(dropDownActive);
          }}
        >
          <div className="textsmall">{t('file-roles.content-manager')}</div>
          <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: 14, color: '#9e9e9e', marginLeft: 8 }} />
        </div>
      </div>
      <div className={dropDownActive ? 'user-small-dropdown active' : 'user-small-dropdown'} style={{ backgroundColor: '#ffffff', borderRadius: 10 }}>
        <DropdownElement text={t('file-roles.viewer')} selected={false} icon={faCheck} onItemClick={() => {}} />
        <DropdownElement text={t('file-roles.contributer')} selected={false} icon={faCheck} onItemClick={() => {}} />
        <DropdownElement text={t('file-roles.content-manager')} selected={false} icon={faCheck} onItemClick={() => {}} />
        <DropdownElement text={t('roles.admin')} selected={false} icon={faCheck} onItemClick={() => {}} />
      </div>
    </div>
  );
};
