import { ThemeOverrides } from '@base/web';
import { FirstSlide, Search, Woman, IbuLogo } from '../assets';
import logoBG from '../assets/login_bg.jpg';

const overrides: ThemeOverrides = {
  colors: {
    logoLight: 'white',
    logoDark: '#015b80',
  },
  slides: {
    loginSlides: [
      {
        title: 'Searched, found!',
        description: 'The search function of the IBU Knowledge Base calls up the entire database within a few milliseconds.',
        illustration: Search as any,
      },
      {
        title: 'All documents in one place',
        description: 'Get easy access to activated internal documents with the IBU Knowledgebase.',
        illustration: Woman as any,
      },
      {
        title: 'Secure and Safe',
        description: 'Easy and quick signing of documents',
        illustration: FirstSlide as any,
      },
    ],
  },
  images: {
    loginBG: logoBG,
    logo: IbuLogo as any,
  },
  productSettings: {
    organigram: true,
    favourites: true,
    editor: true,
    search: true,
    accessRequest: true,
    furtherGroups: false,
  },
};

export default overrides;
