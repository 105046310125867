import { faPen } from '@fortawesome/free-solid-svg-icons';
import type { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import '../../css/components/common/layout/userGroupGrid.scss';
import { useTheme } from '../../theme/ThemeProvider';
import { SmallInfoCircle } from '../common';

interface UserGroupElementProps {
  name: string;
  icon: string | IconDefinition;
  description?: string;
  infoClick?: () => void;
  editClick?: () => void;
  bgColor?: string;
  style?: CSSProperties;
  contentColor?: string;
  button?: boolean;
}

export const UserGroupElement = ({ name = 'User Group', icon, infoClick, editClick, bgColor = 'white', contentColor, style, button }: UserGroupElementProps) => {
  const theme = useTheme();
 
  return (
    <div className="user-group no-user-select" style={{ ...style, backgroundColor: bgColor }}>
      <div className="inner-grid-con user-small">
        <div className="user-element-icon-con" style={{ color: button ? theme.colors['main-color'] : theme.colors['dark-gray'] }}>
          <FontAwesomeIcon icon={(icon as IconDefinition).icon ? icon as IconDefinition : ["far", icon as any]} style={{ fontSize: 52, marginTop: infoClick || editClick ? 12 : 0 }} />
          <div className="user-con-small-text">{name}</div>
        </div>
        {infoClick && <SmallInfoCircle onClick={() => infoClick} />}
        {editClick && <SmallInfoCircle iconSize={12} icon={faPen} left onClick={() => editClick} />}
      </div>
    </div>
  );
};
