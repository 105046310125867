import * as ACTIONS from './actions'
import * as USER_ACTIONS from '../users/actions'
import type { ThunkAction } from 'redux-thunk';
import type { StateType } from '../store';
import type {ActionsType} from './reducer';
import { IGroupsService } from '../../entities/IGroupsService';
import { Group } from '../../entities/Group';

export class GroupsThunk {
	constructor(private groupsService: IGroupsService) { }
	
	fetchGroups = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
		try {
			dispatch(ACTIONS.fetchGroupsRequestAction());

			let groups = await this.groupsService.getGroups();
			
			dispatch(ACTIONS.fetchGroupsSuccessAction(groups));
		} catch (error) {
			dispatch(ACTIONS.fetchGroupsFailureAction(error));
		}
	}

	createGroup = (name: string, icon: string, description: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
		try {
			dispatch(ACTIONS.createGroupRequestAction());

			const newGroup = await this.groupsService.createGroup({name, icon, description});
			
			dispatch(ACTIONS.createGroupSuccessAction(newGroup));
		} catch (error) {
			dispatch(ACTIONS.createGroupFailureAction(error));
		}
	}

	deleteGroup = (groupID: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
		try {
			dispatch(ACTIONS.deleteGroupRequestAction());
			await this.groupsService.deleteGroup(groupID);
			dispatch(ACTIONS.deleteGroupSuccessAction(groupID));
		} catch (error) {
			dispatch(ACTIONS.deleteGroupFailureAction(error));
		}
	}

	updateGroup = (groupId: string, update: Partial<Omit<Group, 'id'>>): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
		try {
			dispatch(ACTIONS.updateGroupRequestAction());
			await this.groupsService.updateGroup(groupId, update);
			dispatch(ACTIONS.updateGroupSuccessAction(groupId, update));
		} catch (error) {
			dispatch(ACTIONS.updateGroupFailureAction(error));
		}
	}

	updateUsers = (groupId: string, update: {add: string[], remove: string[]}): ThunkAction<void, StateType, never, ActionsType & any> => async (dispatch, getState) => {
		try {
			dispatch(ACTIONS.updateUsersRequestAction());
			await this.groupsService.updateUsersForGroup(groupId, update);
			dispatch(ACTIONS.updateUsersSuccessAction());
			for (const uid of update.add) {
				dispatch(USER_ACTIONS.updateUserGroupsSuccessAction(uid, { add: [groupId], remove: [] }));
			}
			for (const uid of update.remove) {
				dispatch(USER_ACTIONS.updateUserGroupsSuccessAction(uid, { add: [], remove: [groupId] }));
			}
		} catch (error) {
			dispatch(ACTIONS.updateUsersFailureAction(error));
		}
	}

}