import React from 'react';
import { useTranslation } from 'react-i18next';
import { MainModule } from './content/Main';
import { getTitleFromI18NObject, useDashboardConfig } from './DashboardHelpers';
import { useExternalUserFilterForDashboardItems } from './HelperFunctions';

export default function Dashboard() {
  const config = useDashboardConfig();
  const { t, i18n } = useTranslation();
  console.log(config);
  const filter = useExternalUserFilterForDashboardItems();

  return (
    <>
      {config.items && (
        <div className="module-grid">
          {filter(config.items).map((i, index) => (
            <MainModule {...i} key={index} />
          ))}
        </div>
      )}
      {config.groups &&
        config.groups.map((group, index) => (
          <div key={index} style={{ width: '100%' }}>
            <h1 style={{ margin: '30px 0' }}>{getTitleFromI18NObject(group.title, i18n.language)}</h1>
            <div className="module-grid">
              {filter(group.items).map((i, index) => (
                <MainModule {...i} key={index} />
              ))}
            </div>
          </div>
        ))}
    </>
  );
}
