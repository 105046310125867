import {
  faArchive,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileEdit,
  faFileExcel,
  faFileImage,
  faFileMusic,
  faFilePdf,
  faFilePowerpoint,
  faFileUpload,
  faFileVideo,
  faFileWord,
  faFilm,
  faFolder,
  faImage,
  faMusic,
  faUpload,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cssClasses, cx } from '../HelperFunctions';
import { ConditionalRender } from '../common';
import { DefaultTheme, useTheme } from '../../theme/ThemeProvider';
import * as mime from 'mime-types';
import { MimeTypes } from '@base/core';

enum FileTypes {
  document,
  sheet,
  presentation,
  pdf,
  image,
  video,
  audio,
  siDoc,
  siPres,
  text,
  zip,
  folder,
  upload,
  unknown,
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function cutExtensionfromName(fileName: string, extension: string) {
  const regex = new RegExp(escapeRegExp(extension) + '$', 'i');
  return fileName.replace(regex, '');
}

export const getFileExtension = (mimeType: string) => {
  switch (mimeType) {
    case 'si/document':
      return '';
    case 'si/presentation':
      return '';
    case 'upload':
      return '';
    case 'folder':
      return '';
    default: {
      const ext = mime.extension(mimeType);
      return ext ? '.' + ext : '';
    }
  }
};

function mimeToUiType(type: string): FileTypes {
  const regexFileType = /^(\w+)\/?/;
  const regexFileEnding = /^\w+\/([\w, ., -]+)/;
  const fileType = regexFileType.exec(type);
  const fileEnding = regexFileEnding.exec(type);

  if (type === 'folder') {
    return FileTypes.folder;
  }
  if (fileType === null || fileEnding === null) {
    return FileTypes.unknown;
  }

  switch (fileType[1]) {
    case 'application':
      switch (fileEnding[1]) {
        case 'pdf':
          return FileTypes.pdf;
        case 'msword':
          return FileTypes.document;
        case 'msexcel':
          return FileTypes.sheet;
        case 'mspowerpoint':
          return FileTypes.presentation;
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
          return FileTypes.document;
        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return FileTypes.sheet;
        case 'vnd.openxmlformats-officedocument.presentationml.presentation':
          return FileTypes.presentation;
        case 'zip':
          return FileTypes.zip;
        case 'exe':
          return FileTypes.unknown;
        default:
          return FileTypes.unknown;
      }
    case 'folder':
      return FileTypes.folder;
    case 'upload':
      return FileTypes.upload;
    case 'image':
      return FileTypes.image;
    case 'audio':
      return FileTypes.audio;
    case 'video':
      return FileTypes.video;
    case 'text':
      return FileTypes.text;
    case 'si':
      switch (fileEnding[1]) {
        case 'document':
          return FileTypes.siDoc;
        case 'presentation':
          return FileTypes.siPres;
        default:
          return FileTypes.unknown;
      }
    default:
      return FileTypes.unknown;
  }
}

export function useFileIcon(type: string, id: string) {
  const def = getFileIconDefinition(type);
  return () => <FontAwesomeIcon icon={def.icon} className={'file-icon ' + def.classNames} id={id} />;
}

export function getFileIconDefinition(type: string): { icon: IconDefinition; classNames: string; color: string } {
  const fileType = mimeToUiType(type);
  switch (fileType) {
    case FileTypes.pdf:
      return { icon: faFilePdf, classNames: 'pdf', color: DefaultTheme.colors.pdf };
    case FileTypes.document:
      return { icon: faFileWord, classNames: 'word', color: DefaultTheme.colors.word };
    case FileTypes.sheet:
      return { icon: faFileExcel, classNames: 'excel', color: DefaultTheme.colors.excel };
    case FileTypes.presentation:
      return { icon: faFilePowerpoint, classNames: 'powerpoint', color: DefaultTheme.colors.powerpoint };
    case FileTypes.folder:
      return { icon: faFolder, classNames: 'folder', color: DefaultTheme.colors['dark-gray'] };
    case FileTypes.upload:
      return { icon: faFileUpload, classNames: 'mime', color: DefaultTheme.colors['dark-gray'] };
    case FileTypes.zip:
      return { icon: faFileArchive, classNames: 'mime', color: DefaultTheme.colors['dark-gray'] };
    case FileTypes.image:
      return { icon: faFileImage, classNames: 'mime', color: DefaultTheme.colors['dark-gray'] };
    case FileTypes.audio:
      return { icon: faFileMusic, classNames: 'mime', color: DefaultTheme.colors['dark-gray'] };
    case FileTypes.video:
      return { icon: faFileVideo, classNames: 'mime', color: DefaultTheme.colors['dark-gray'] };
    case FileTypes.text:
      return { icon: faFileAlt, classNames: 'mime', color: DefaultTheme.colors['dark-gray'] };
    case FileTypes.siDoc:
      return { icon: faFileEdit, classNames: 'si-doc', color: DefaultTheme.colors['si-file'] };
    case FileTypes.siPres:
      return { icon: faFileEdit, classNames: 'si-pres', color: DefaultTheme.colors.powerpoint };
    default:
      return { icon: faFile, classNames: 'unknown', color: DefaultTheme.colors['dark-gray'] };
  }
}

export function shouldDisplayFileDownloadUrlAsDefault(type: MimeTypes) {
  switch (type) {
    case 'folder':
    case 'si/document':
    case 'si/presentation':
    case 'application/pdf':
      return true;
    default:
      return false;
  }
}

export async function downloadFile(file: Core.NormalFile) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = (event) => {
    const blob = xhr.response;
    const blobUrl = URL.createObjectURL(blob);
    const element = document.createElement('a');
    element.setAttribute('href', blobUrl);
    element.setAttribute('download', `${cutExtensionfromName(file.name, getFileExtension(file.type))}${getFileExtension(file.type)}`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  xhr.open('GET', await file.downloadUrl);
  xhr.send();
}
