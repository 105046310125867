import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { SHORT_ANSWER } from './types';

export const MultilineQuestionRenderer: Renderer<undefined, string> = {
  type: SHORT_ANSWER,
  viewer,
  editor,
  viewerMin,
  contentType: 'string',
  defaultEditorValue: '' as any,
  defaultReaderValue: '',
  getValidationSchema,
  getTextRepresentation,
};

function viewer({ onChange, value, touched, required, error }: RendererViewerProps<undefined, string>): JSX.Element {
  return (
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        multiline
        required={required}
        fullWidth
        label="Answer"
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        error={touched && Boolean(error)}
        helperText={touched && error}
      />
    </Grid>
  );
}

function viewerMin({ onChange, value = '', touched, required, error }: RendererViewerProps<undefined, string>): JSX.Element {
  return (
    <Grid item xs={12}>
      {value.split('\n').map((t, i) => (
        <div key={i}>{t}</div>
      ))}
    </Grid>
  );
}

async function getTextRepresentation({ onChange, value = '', touched, required, error }: RendererViewerProps<undefined, string>) {
  return value
    .split('\n')
    .map((t, i) => `<div>${t}</div>`)
    .join('');
}

function editor({ onChange, value }: RendererEditorProps<undefined, string>): JSX.Element {
  return (
    <Grid item xs={12}>
      <TextField variant="outlined" fullWidth label="Answer" disabled />
    </Grid>
  );
}

function getValidationSchema(schema, t) {
  let validator = yup.string();
  if (schema.required) {
    validator = validator.required(t('something-is-required', { something: t('question') }));
  }
  return validator;
}
