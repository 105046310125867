// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EditorsFormEditor_swap-div__10nKq {\n  margin: 0.5rem;\n  padding: 1rem;\n  background-color: burlywood;\n}\n\ndiv:focus {\n  outline: none;\n}\n\n.EditorsFormEditor_drag-handle__wrapper__15NVC {\n  cursor: move;\n  opacity: 0;\n  transition: opacity 300ms ease;\n}\n.EditorsFormEditor_drag-handle__wrapper__15NVC:hover {\n  opacity: 1;\n}\n\n.EditorsFormEditor_question__wrapper__2jFHW .EditorsFormEditor_card-action__wrapper__1NMgq {\n  display: none;\n}\n.EditorsFormEditor_question__wrapper__2jFHW.EditorsFormEditor_focused__2aZ7Y .EditorsFormEditor_drag-handle__wrapper__15NVC,\n.EditorsFormEditor_question__wrapper__2jFHW :hover .EditorsFormEditor_drag-handle__wrapper__15NVC {\n  opacity: 1;\n}\n.EditorsFormEditor_question__wrapper__2jFHW.EditorsFormEditor_focused__2aZ7Y .EditorsFormEditor_card-action__wrapper__1NMgq {\n  display: flex;\n}\n\n.EditorsFormEditor_section__wrapper__3--43 .EditorsFormEditor_trash-button__1HdtT {\n  opacity: 0;\n  transition: 200ms ease;\n}\n.EditorsFormEditor_section__wrapper__3--43:hover .EditorsFormEditor_trash-button__1HdtT {\n  opacity: 1;\n}", ""]);
// Exports
exports.locals = {
	"swap-div": "EditorsFormEditor_swap-div__10nKq",
	"drag-handle__wrapper": "EditorsFormEditor_drag-handle__wrapper__15NVC",
	"question__wrapper": "EditorsFormEditor_question__wrapper__2jFHW",
	"card-action__wrapper": "EditorsFormEditor_card-action__wrapper__1NMgq",
	"focused": "EditorsFormEditor_focused__2aZ7Y",
	"section__wrapper": "EditorsFormEditor_section__wrapper__3--43",
	"trash-button": "EditorsFormEditor_trash-button__1HdtT"
};
module.exports = exports;
