import { Box, BoxProps, useTheme } from '@material-ui/core';
import React from 'react';
import type { ReactChildrenProp } from '../../lib';

interface ContentContainerProps {
  children: ReactChildrenProp;
}

export const ContentContainer = ({ children, ...props }: BoxProps) => {
  const theme = useTheme();
  return (
    <Box
      flex={1}
      display="flex"
      alignItems="stretch"
      flexDirection="column"
      bgcolor={theme.palette.primary.main}
      {...props}
    >
      {children}
    </Box>
  );
};
