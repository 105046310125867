import { getKeysWithTruthyValues } from '@base/core';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NotificationSelectDropdown } from '.';
import { ConditionalRender } from '../../components/common';
import '../../css/Views/admin/users/UsersOverride.scss';
import { ConditionalRenderWithRights, useWithRights } from '../../helpers/ConditionalRenderWithRights';
import { useTheme } from '../../theme/ThemeProvider';
import { useGetUsers } from './hooks/useGetUsers';
import { ModuleSelectDropdown } from '../../components/users/ModuleSelectDropdown';
import { CheckField } from '../../components/users/TextField';
import { BasicInformationEditControl } from './useredit-admin-content/BasicInformationEditControl';
import { GroupRightsEditControl } from './useredit-admin-content/GroupRightEditControl';
import { LanguageSelect } from './useredit-admin-content/LanguageSelect';
import { RightsEditControl } from './useredit-admin-content/RightsEditControl';
import { UserImageEditControl } from './useredit-admin-content/UserImageEditControl';

interface UserEditAdminContentProps {
  formik: FormikProps<
    Core.User & {
      newPassword?: string;
      currentModules?: string[];
      notificationChannels?: string[];
    }
  >;
  inCreation?: boolean;
}

export function UserEditAdminContent({ formik: { values, setValues, handleChange, setFieldValue, errors, touched }, inCreation }: UserEditAdminContentProps) {
  const theme = useTheme();
  const uid = values.id;

  const { data: _users = [] } = useGetUsers();
  const users = _users.filter((u) => !u.rights?.is_group_only_user);

  const { t } = useTranslation();
  const { user: currentUser } = useSelector((state: Core.StateType) => state.auth);

  return (
    <Grid container spacing={2}>
      <UserImageEditControl
        currentUser={values}
        onImage={(image) => {
          setFieldValue('userImage', image);
          const reader = new FileReader();
          reader.onload = (e) => {
            setFieldValue('photoURL', e.target!.result as string);
          };
          reader.readAsDataURL(image);
        }}
        userName={values.displayName}
        userPhotoUrl={values.photoURL}
      />
      <Grid item xs={12} marginTop={2}>
        <Typography variant="overline">{t('basic-information')}</Typography>
      </Grid>
      <BasicInformationEditControl touched={touched} errors={errors} inCreation={inCreation} isOwn={values.id === currentUser.id} values={values} setFieldValue={setFieldValue} />
      <ConditionalRender render={useWithRights('user_edit', 'superadmin') || (useWithRights('user_create') && inCreation) || values.id === currentUser.id}>
        <Grid item xs={12} md={6} mt={2}>
          <Stack spacing={2}>
            <Typography variant="overline">{t('sprachen-languages')}</Typography>
            <Field as={LanguageSelect} name="language" />
          </Stack>
        </Grid>
      </ConditionalRender>
      <ConditionalRender render={!values.rights.is_group_only_user}>
        <Grid item xs={12} md={6} mt={2}>
          <Stack spacing={2}>
            <Typography variant="overline">{t('supervisor')}</Typography>
            <Autocomplete
              options={users}
              getOptionLabel={(user) => user.displayName}
              value={users.find((user) => user.id === values.supervisor) ?? null}
              onChange={(e, user: Core.User) => setFieldValue('supervisor', user?.id ?? null)}
              renderInput={(params) => <TextField variant="outlined" {...params} label={t('no-supervisor-selected')} />}
              disabled={!useWithRights('user_edit', 'superadmin')}
            />
          </Stack>
        </Grid>
      </ConditionalRender>
      <ConditionalRender render={theme.productSettings.learningmodules}>
        <Grid item xs={12} md={6} mt={2}>
          <Stack spacing={2}>
            <Typography variant="overline">{t('unlocked-modules')}</Typography>
            <Field as={ModuleSelectDropdown} name="currentModules" />
            <ConditionalRenderWithRights user_edit invert>
              <div className="input-grid" style={{ marginTop: 20, gridTemplateRows: 'auto' }}>
                {values.currentModules?.map((module) => {
                  return <CheckField key={module} describtion={module === 'VA' ? 'Versicherungsagent' : 'Vermögensberater'} checked />;
                })}
              </div>
            </ConditionalRenderWithRights>
          </Stack>
        </Grid>
      </ConditionalRender>
      <ConditionalRenderWithRights user_edit>
        <Grid item xs={12} md={6} mt={2}>
          <Stack spacing={2}>
            <Typography variant="overline">{t('notification-channels')}</Typography>
            <Field as={NotificationSelectDropdown} name="notificationChannels" />
          </Stack>
        </Grid>
      </ConditionalRenderWithRights>
      <ConditionalRender render={!values.rights.is_group_only_user && values.id !== currentUser.id}>
        <ConditionalRenderWithRights user_rights_edit>
          <RightsEditControl
            rights={values.rights}
            setRights={(e) =>
              setValues({
                ...values,
                rights: {
                  ...values.rights,
                  ...e,
                },
              })
            }
          />
        </ConditionalRenderWithRights>
      </ConditionalRender>
      <ConditionalRender render={values.rights.is_group_only_user}>
        <ConditionalRenderWithRights user_rights_edit>
          <GroupRightsEditControl
            rights={values.rights}
            groups={getKeysWithTruthyValues(values.groups)}
            setRights={(e) =>
              setValues({
                ...values,
                rights: {
                  ...values.rights,
                  ...e,
                },
              })
            }
          />
        </ConditionalRenderWithRights>
      </ConditionalRender>
      {/* <ConditionalRender render={!values.rights.is_group_only_user}>
        <ConditionalRenderWithRights user_edit>
          <ConditionalRenderIfOwn enableOwnAccountWithUid={uid} invert>
            <Grid item xs={12} marginTop={2}>
              <Typography variant="overline">{t('logbuch-notizen')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Field as={TextField} multiline variant="outlined" rows={5} label={t('document-conversations-and-more')} name="notes" fullWidth />
            </Grid>
          </ConditionalRenderIfOwn>
        </ConditionalRenderWithRights>
      </ConditionalRender> */}
    </Grid>
  );
}
