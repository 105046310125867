import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faInfoCircle, faPageBreak, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Container, Grid, IconButton, Paper, Popper, Skeleton, useTheme } from '@material-ui/core';
import cuid from 'cuid';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { createEmptyForm, Item, Section } from './components';
import { getValue, SetAction } from './components/getValue';
import { Information } from './components/Information';
import { Renderers } from './content-renderer';
import './EditorsFormEditor.module.scss';
import { Form } from './entities/Form';
import { FormContent } from './entities/FormContent';
import { FileProvider } from './provider/FileProvider';
import { MockFileProvider } from './provider/mocks/MockFileProvider';
import { ProvidersProvider } from './provider/ProviderContext';
import { useAutoUpdateState } from './useAutoUpdateState';
import xlsx from 'xlsx'
import { useGetFile } from '../../../../base/web/src/views/campaign/hooks/useGetFile';
import {useParams} from "react-router-dom";

export interface EditorsFormEditorProps {
  document: Form;
  fileProvider?: FileProvider;
  onChange(document: Form): void;
  isLoading?: boolean;
}

export function FormEditor({ document = createEmptyForm(), fileProvider = new MockFileProvider(), onChange = () => {}, isLoading }: EditorsFormEditorProps) {
  const [items, _setItems] = useAutoUpdateState(document.content, [document]);
  const setItems = (val: any) => {
    _setItems((items) => {
      const newItems = getValue(val, items);
      onChange({ ...document, content: newItems });
      return newItems;
    });
  };

  const { parentId, formId } = useParams<{ parentId: string; formId: string }>();
  const { data: campaign } = useGetFile(parentId);

  const [focusedId, setFocusedId] = useState<string>(null);
  const theme = useTheme();
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const sectionCount = items.reduce((sum, item) => (item.type === 'section' ? sum + 1 : sum), 0);

  let sectionCounter = 0;

  const popupAnchorRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focusedId == null && items.length > 0) {
      setFocusedId(items[0].id);
    }
  }, [focusedId, items]);

  const addItem = () => {
    setItems((items) => {
      const newItems = [...items];
      const newItem: FormContent = {
        id: cuid(),
        content: 'text',
        contentType: 'string',
        required: false,
        title: 'Question',
        type: 'short-answer',
      };
      let insertIndex = -1;
      if (focusedId != null) {
        insertIndex = items.findIndex((v) => v.id === focusedId);
      }
      if (insertIndex < 0) {
        newItems.push(newItem);
      } else {
        newItems.splice(insertIndex + 1, 0, newItem);
      }
      return newItems;
    });
  };

  const duplicateItem = (item: FormContent) => {
    setItems((items) => {
      const newItems = [...items];
      const newItem: FormContent = {
        ...item,
        id: cuid(),
      };
      const insertIndex = items.findIndex((v) => v.id === item.id);
      if (insertIndex < 0) {
        newItems.push(newItem);
      } else {
        newItems.splice(insertIndex + 1, 0, newItem);
      }
      return newItems;
    });
  };

  const addInformation = () => {
    setItems((items) => {
      const newItems = [...items];
      const newItem: FormContent = {
        id: cuid(),
        content: {},
        contentType: 'rich-text',
        required: false,
        title: 'Information',
        type: 'info',
      };
      let insertIndex = -1;
      if (focusedId != null) {
        insertIndex = items.findIndex((v) => v.id === focusedId);
      }
      if (insertIndex < 0) {
        newItems.push(newItem);
      } else {
        newItems.splice(insertIndex + 1, 0, newItem);
      }
      return newItems;
    });
  };

  const addSection = () => {
    setItems((items) => {
      const newItems = [...items];
      const newItem: FormContent = {
        id: cuid(),
        content: 'Section description',
        contentType: 'string',
        required: false,
        title: 'Section title',
        type: 'section',
      };
      let insertIndex = -1;
      if (focusedId != null) {
        insertIndex = items.findIndex((v) => v.id === focusedId);
      }
      if (insertIndex < 0) {
        newItems.push(newItem);
      } else {
        newItems.splice(insertIndex + 1, 0, newItem);
      }
      return newItems;
    });
  };

  const updateItem = (item: SetAction<FormContent>, id: string) => {
    setItems((items) => items.map((i) => (i.id === id ? getValue(item, i) : i)));
  };

  const changeType = (item: FormContent, newType: string) => {
    if (item.type === newType) return;
    const updatedItem = { ...item };
    if (Renderers[newType].contentType !== item.contentType) {
      updatedItem.contentType = Renderers[newType].contentType;
      updatedItem.content = Renderers[newType].defaultEditorValue;
    }
    updatedItem.type = newType as any;
    updateItem(updatedItem, item.id);
  };

  return (
    <ProvidersProvider value={{ fileProvider: fileProvider } as any}>
      <Box bgcolor={theme.palette.grey[200]} paddingTop={10} paddingBottom={10} flex={1}>
        <Container>
          <Box width="calc(100% - 60px)" ref={boxRef}>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={items.map((i) => i.id)} strategy={verticalListSortingStrategy}>
                <Grid spacing={3} container flexDirection="column" flexWrap="nowrap">
                  <Section
                    id="section0"
                    title={isLoading ? <Skeleton width={300} /> : document.name}
                    description={isLoading ? <Skeleton width={300} /> : document.description ?? ''}
                    disableCount={sectionCount === 0}
                    sectionNum={++sectionCounter}
                    numOfSections={sectionCount + 1}
                    focused={focusedId === 'section0'}
                    onChange={({ description, title }) => onChange({ ...document, description, name: title })}
                    setFocus={(ref) => {
                      setFocusedId('section0');
                      popupAnchorRef.current = ref;
                    }}
                  />
                  {items.map((i, idx) => {
                    switch (i.type) {
                      case 'section':
                        return (
                          <Section
                            key={i.id}
                            id={i.id}
                            title={i.title}
                            description={i.content ?? ''}
                            disableCount={sectionCount === 0}
                            sectionNum={++sectionCounter}
                            numOfSections={sectionCount + 1}
                            focused={focusedId === i.id}
                            isEmpty={items[idx + 1]?.type === 'section' || items[idx + 1] === undefined}
                            setFocus={(ref) => {
                              setFocusedId(i.id);
                              popupAnchorRef.current = ref;
                            }}
                            onChange={({ description, title }) => updateItem((item) => ({ ...item, content: description, title }), i.id)}
                            onRemove={removeItem(i)}
                            isRemovable
                          />
                        );
                      case 'info':
                        return (
                          <Information
                            key={i.id}
                            value={i}
                            duplicate={() => duplicateItem(i)}
                            changeType={(newType) => changeType(i, newType)}
                            onChange={(action) => updateItem(action, i.id)}
                            onRemove={removeItem(i)}
                            focused={focusedId === i.id}
                            setFocus={(ref) => {
                              setFocusedId(i.id);
                              popupAnchorRef.current = ref;
                            }}
                          />
                        );
                      default:
                        return (
                          <Item
                            key={i.id}
                            value={i}
                            duplicate={() => duplicateItem(i)}
                            changeType={(newType) => changeType(i, newType)}
                            onChange={(change) => updateItem(change, i.id)}
                            onRemove={removeItem(i)}
                            onDownload={downloadItem(i)}
                            budgetField={document.budgetField === i.id}
                            setAsBudgetField={(set: boolean) => onChange({ ...document, budgetField: set ? i.id : null })}
                            projectDurationField={document.projectDurationField === i.id}
                            setAsProjectDurationField={(set: boolean) => onChange({ ...document, projectDurationField: set ? i.id : null })}
                            focused={focusedId === i.id}
                            setFocus={(ref) => {
                              console.log('settingFocus for ref:', i.id);
                              setFocusedId(i.id);
                              popupAnchorRef.current = ref;
                            }}
                          />
                        );
                    }
                  })}
                </Grid>
              </SortableContext>
            </DndContext>
            <Popper
              anchorEl={popupAnchorRef.current || boxRef.current}
              open={Boolean(popupAnchorRef.current || boxRef.current)}
              placement="right-start"
              style={popupAnchorRef.current ? { transition: 'transform 200ms ease-in-out' } : {}}
              modifiers={[
                {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: document,
                    padding: 8,
                  },
                },
                {
                  name: 'computeStyles',
                  options: {
                    adaptive: false, // true by default
                  },
                },
              ]}
            >
              <Box margin={3}>
                <Paper style={{ borderRadius: 9999, display: 'flex', flexDirection: 'column' }}>
                  <IconButton onClick={addItem}>
                    <FontAwesomeIcon icon={faPlusCircle} size="xs" />
                  </IconButton>
                  <IconButton onClick={addInformation}>
                    <FontAwesomeIcon icon={faInfoCircle} size="xs" />
                  </IconButton>
                  <IconButton onClick={addSection}>
                    <FontAwesomeIcon icon={faPageBreak} size="xs" />
                  </IconButton>
                </Paper>
              </Box>
            </Popper>
          </Box>
        </Container>
      </Box>
    </ProvidersProvider>
  );

  function removeItem(i: FormContent): () => void {
    return () => {
      popupAnchorRef.current = null;
      // setFocusedId(null);
      setItems((items) => items.filter((item) => item.id !== i.id));
    };
  }

  function downloadItem(i: FormContent): () => void {

    if (i.type === 'table') {
      return () => {

        const workbook = xlsx.utils.book_new();
        const content = i.content;
        const colIDs = [];
        const colNames = [];
        const worksheetData = [];

        content.columns.forEach(col => {
          colIDs.push(col.field);
          colNames.push(col.headerName ? col.headerName : 'Untitled') // add translation
        });

        worksheetData.push(colNames)

        // <app-name>_<nf-name>_<date>

        content.rows.forEach(row => {
          const worksheetRow = []
          colIDs.forEach(colID => {
            worksheetRow.push(row[colID] ? row[colID] : '')
          })
          worksheetData.push(worksheetRow)
        });

        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);

        xlsx.utils.book_append_sheet(workbook, worksheet, i.title);
        xlsx.writeFile(workbook, `${campaign.name}_${new Date().toLocaleDateString()}.xlsx`);
      };
    } else {
      return undefined
    }
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const ids = items.map((i) => i.id);
        const oldIndex = ids.indexOf(active.id);
        const newIndex = ids.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
}

export default FormEditor;
