import { FormFile } from '@base/core';
import { LoadingPromiseButton, StandardPopup } from '@base/web';
import { FileProvider } from '@editors/form-editor';
import { faArrowToBottom, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Card, CardContent, Grid, Stack, Typography, useTheme } from '@material-ui/core';
import { FileUpload } from 'libs/editors/form-editor/src/lib/components/FileUpload';
import { useAutoUpdateState } from 'libs/editors/form-editor/src/lib/useAutoUpdateState';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { usePopup } from '../common';
import { addFileUploadTask } from './addFileUploadTask';

type FileUploadPopupProps = {
  initialFiles: FormFile[];
  onSave: (files: FormFile[]) => Promise<any>;
  open: boolean;
  onClose: () => void;
  fileProvider: FileProvider;
  title: string;
};

export function FileUploadPopup({ initialFiles, onSave, onClose, open, fileProvider, title }: FileUploadPopupProps) {
  const theme = useTheme();
  const notification = usePopup();
  const { t } = useTranslation();
  const [files, setFiles] = useAutoUpdateState<FormFile[]>(initialFiles ?? [], [initialFiles]);

  const uploadFile = addFileUploadTask(fileProvider, setFiles);

  const onDrop = (acceptedFiles: File[]) => {
    for (const file of acceptedFiles) {
      void uploadFile(file);
    }
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });
  const { onClick, ...rootProps } = getRootProps();

  return (
    <StandardPopup width={650} onBackdropClick={onClose} visible={open}>
      <Card>
        <CardContent>
          <Typography variant="h5" fontWeight="500" mb={2}>
            {title}
          </Typography>
          <Grid item container xs={12} {...rootProps} spacing={1}>
            {files.map((file) => (
              <Grid key={file.id} item xs={12}>
                <FileUpload
                  {...file}
                  onCancel={file.cancel}
                  onDelete={async () => {
                    setFiles((v) => v.filter((x) => x.id !== file.id));
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <input {...getInputProps()} />
              {isDragActive || (
                <Button
                  onClick={onClick}
                  sx={{ color: theme.palette.grey[800], backgroundColor: theme.palette.background.gray, ':hover': { bgcolor: theme.palette.grey[800], color: 'white' } }}
                  startIcon={<FontAwesomeIcon icon={faPlusCircle} size="sm" />}
                  variant="contained"
                  fullWidth
                >
                  {t('addFile')}
                </Button>
              )}
              {isDragActive && (
                <Box padding={5} bgcolor={alpha(theme.palette.text.primary, 0.1)} borderRadius={theme.shape.borderRadius} alignItems="center" justifyContent="center" display="flex">
                  <FontAwesomeIcon icon={faArrowToBottom} size="3x" />
                </Box>
              )}
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} justifyContent="flex-end" mt={2}>
            <Button variant="outlined" color="error" onClick={onClose}>
              {t('cancel')}
            </Button>
            <LoadingPromiseButton
              variant="contained"
              disabled={files.some((f) => f.finished === false)}
              onClick={async () => {
                try {
                  await onSave(files);
                  onClose();
                } catch (error) {
                  notification({
                    title: t('error'),
                    type: 'error',
                    text: error.message,
                  });
                }
              }}
            >
              {t('save')}
            </LoadingPromiseButton>
          </Stack>
        </CardContent>
      </Card>
    </StandardPopup>
  );
}
