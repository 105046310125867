import { AccessRequestDoc, AccessRequestFileProps, AccessRequestFormData } from '@base/core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Stack, TextField, Typography, useTheme as useMaterialTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
//@ts-ignore
import * as yup from 'yup';
import { useTheme } from '../../../../base/web/src/theme/ThemeProvider';
import '../styles/components/RequestForm.scss';
import { NFRequestFormContent as RequestFormContent } from '../form-contents/nf/NFRequestFormContent';
import { RequestFormContentProps } from '../form-contents/RequestFormContentProps';
import { FileSlot } from './FileSlot';

type RequestFormProps = {
  onSubmit: (data: AccessRequestFormData) => Promise<void>;
  formData?: AccessRequestDoc;
  appeal?: () => void;
  verify?: () => void;
  onDeleteFiles?: (files: AccessRequestFileProps[]) => Promise<void>;
  config: {
    readOnly?: boolean;
    changeDataMode?: boolean;
    rejectAcceptMode?: boolean;
    requestMode?: boolean;
  };

  getSchema: (t: TFunction<'translation'>) => any;
  getInitialValues: (formData?: any) => any;
  FormContent: (props: RequestFormContentProps) => JSX.Element;
};

const MAX_FILE_AMOUNT = 2;
export const MAX_FILE_SIZE_IN_BYTE = 10000000;

export const RequestForm = ({
  onSubmit,
  config = { readOnly: false, changeDataMode: false, rejectAcceptMode: false, requestMode: false },
  formData = undefined,
  appeal = () => null,
  verify = () => null,
  onDeleteFiles = (files) => null,
  FormContent,
  getInitialValues,
  getSchema,
}: RequestFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const muiTheme = useMaterialTheme();

  const schema = getSchema(t);
  const [fileSlots, _setFileSlots] = useState<FileSlot[]>([]);
  const [filesToDelete, setfilesToDelete] = useState<AccessRequestFileProps[]>([]);

  useEffect(() => {
    if (config?.requestMode) {
      _setFileSlots([{}, {}]);
    } else {
      (formData?.files ?? []).forEach((file) => {
        if (!fileSlots.some((fileSlot) => fileSlot?.existingData?.firebasePath === file.firebasePath)) {
          _setFileSlots((oldFileSlots) => [...oldFileSlots, { existingData: file }]);
        }
      });
      fillFileslotsWithPlaceholder();
    }
  }, [formData]);

  async function setFileSlots(files: React.SetStateAction<FileSlot[]>) {
    _setFileSlots(files);
    fillFileslotsWithPlaceholder();
  }
  async function deleteFile(file: AccessRequestFileProps) {
    _setFileSlots((oldFileSlots) => oldFileSlots.filter((f) => f.existingData?.firebasePath !== file.firebasePath));
    setfilesToDelete((oldState) => [...oldState, file]);
    fillFileslotsWithPlaceholder();
  }

  async function commitFileDelete() {
    await onDeleteFiles?.(filesToDelete);
  }

  function getRawFiles(): File[] {
    return fileSlots.filter((fileSlot) => !!fileSlot.rawFileData).map((fileSlot) => fileSlot.rawFileData);
  }

  function fillFileslotsWithPlaceholder() {
    _setFileSlots((oldState) => {
      const filledFileSlotArray = oldState.filter((fs) => !!fs.existingData || !!fs.rawFileData);
      if (oldState.length >= MAX_FILE_AMOUNT && filledFileSlotArray.length >= MAX_FILE_AMOUNT) {
        return [...filledFileSlotArray];
      } else {
        return [...filledFileSlotArray, {}];
      }
    });
  }

  return (
    <Formik
      initialValues={getInitialValues(formData)}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('###Access request form data: ', { ...values, files: getRawFiles() });
        if (config.changeDataMode) {
          await commitFileDelete();
        }
        await onSubmit({ ...values, files: getRawFiles() });
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {(formik) => (
        <Form className="access-request-form-con">
          <Grid container spacing={2}>
            <FormContent
              formik={formik}
              deleteFile={deleteFile}
              setFileSlots={setFileSlots}
              files={formData?.files ?? []}
              fileSlots={config.changeDataMode ? fileSlots : undefined}
              readOnly={config.readOnly}
            />
            {config.changeDataMode && (
              <Grid item xs={12}>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <LoadingButton color="primary" variant="contained" onClick={() => formik.handleSubmit()} disabled={formik.isSubmitting} loading={formik.isSubmitting}>
                    {t('save')}
                  </LoadingButton>
                </Stack>
              </Grid>
            )}
            {config.rejectAcceptMode && (
              <Grid item xs={12}>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <LoadingButton color="error" variant="outlined" onClick={appeal} disabled={formik.isSubmitting} loading={formik.isSubmitting}>
                    {t('reject-registration')}
                  </LoadingButton>
                  <LoadingButton color="primary" variant="contained" onClick={verify} disabled={formik.isSubmitting} loading={formik.isSubmitting}>
                    {t('verify-registration')}
                  </LoadingButton>
                </Stack>
              </Grid>
            )}
            {config.requestMode && (
              <Grid item xs={12}>
                <div className="bottom-con">
                  <div style={{ display: 'flex', alignItems: 'center', color: theme.colors.information }}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10 }} />
                    <Typography variant="subtitle1">{t('ibu-verifies-new-accounts-in-roughly-in-24-hours')}</Typography>
                  </div>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      if (getRawFiles().some((file) => file.size > MAX_FILE_SIZE_IN_BYTE)) {
                        alert(t('file-size-over-mb_size', { size: MAX_FILE_SIZE_IN_BYTE / 1000000 }));
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                  >
                    {formData ? t('save') : t('apply-for-registration')}
                  </LoadingButton>
                </div>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
