import { createEmptyForm } from './components';
import { Renderers } from './content-renderer';
import './EditorsFormEditor.module.scss';
import { Form } from './entities/Form';

export async function GetFormVierwerEmailRepresentation<T extends { data: any; id: string }>(document: Form, value: T) {
  const items = document.content;
  const promises = items.map(async (i, idx) => {
    switch (i.type) {
      case 'info':
        return null;
      case 'provide-file':
        return null;
      case 'section':
        return `<h3>${i.title}</h3>`;
      default: {
        return `<h5>${i.title}</h5>
                <div>${await Renderers[i.type].getTextRepresentation({ ...i, schema: i.content, value: value.data[i.id] })}</div>`;
      }
    }
  });
  return (await Promise.all(promises)).filter((x) => x).join('<br/>');
}
