import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateFolderMutation } from './hooks/mutations';
import * as yup from 'yup';
import { Box, Grid, TextField } from '@material-ui/core';
import { ActionDialog } from '../../components/common/popups/ActionDialog';
import { Field, Formik } from 'formik';

export function CreateFolderPopup({ onClose, open, parentId }: { onClose: () => void; open: boolean; parentId: string }) {
  const { t } = useTranslation();

  const schema = yup.object({
    name: yup.string().required(t('something-is-required', { something: t('name') })),
  });
  const createFolderMutation = useCreateFolderMutation();

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
      }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await createFolderMutation.mutateAsync({ parent: parentId, ...values });
          onClose();
          resetForm();
        } catch (error) {}
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm, values, setValues, errors, touched, ...formik }) => (
        <ActionDialog saveOnEnter modalTitle={t('new-folder')} open={open} onCloseClick={() => onClose()} onSaveClick={submitForm} savePending={isSubmitting}>
          <Box display="flex" flexDirection="column" style={{ width: 400, maxWidth: '100%' }}>
            <Box>
              <Field
                autofocus
                as={TextField}
                variant="outlined"
                label={t('name')}
                name="name"
                required
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
              />
            </Box>
            <Box marginTop={2}>
              <Field
                as={TextField}
                label={t('description')}
                variant="outlined"
                name="description"
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
                fullWidth
              />
            </Box>
          </Box>
        </ActionDialog>
      )}
    </Formik>
  );
}
