import { faEdit, faLock, faTrash, faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Chip, Link, Stack, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, XGrid } from '@material-ui/x-grid';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConditionalRender, ContentHeader, MainMenu, useNotificationEffect, usePopup, UserImageHandler } from '../../components/common';
import { SearchInput } from '../../components/inputs';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { useDebouncedState } from '../users';
import { useUpdateGroupsMutation } from '../users/hooks/mutations';
import { useGetGroups } from '../users/hooks/useGetGroups';
import { useGetUsers } from '../users/hooks/useGetUsers';
import { ResetPasswordPopup } from '../users/popups';
import { containsRight } from './containsRight';
import { useDeleteUsersExternalMutation } from './hooks/mutations';
import { UserEditExternal } from './UserEditExternal';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flex: 1,
      height: 'auto',
      '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
      '& .MuiDataGrid-row': {
        position: 'relative',
        minWidth: '100%',
        cursor: 'pointer',
        '&:hover': {
          '& $lastCell': {
            opacity: 1,
          },
        },
      },
      '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
    },
  }),
);

export const ExternalUsers = () => {
  const user = useSelector((state: Core.StateType) => state.auth.user);
  const [importUserPopupOpen, setImportUserPopupOpen] = useState(false);
  const { t } = useTranslation();
  const { data: userData = [], isLoading } = useGetUsers();
  const { data: groupsList = [], isLoading: groupsLoading } = useGetGroups();
  const { groupId } = useParams<{ groupId: string }>();

  const usersList = userData.filter((u) => u.rights?.is_group_only_user).filter((u) => u.groups[groupId]);

  const [selection, setSelection] = useState([]);
  const [editUserPopupOpen, setEditUserPopupOpen] = useState(null);
  const [createUserPopupOpen, setCreateUserPopupOpen] = useState(null);

  const updateGroupsMutation = useUpdateGroupsMutation();

  const theme = useTheme();
  const classes = useStyles(theme);
  const rights = (user.rights?.groupRights ?? {}) as Core.GroupRights;

  const columns: { [key: string]: Omit<GridColDef, 'field'> } = {
    photoURL: {
      width: 65,
      renderCell: ({ formattedValue, value, row }) => {
        return <UserImageHandler userName={(row as Core.User).displayName} userPhotoUrl={value as any} style={{ height: 42, width: 42, marginRight: 16, borderStyle: 'none' }} fontSize={16} />;
      },
    },
    displayName: {
      width: 250,
      headerName: t('name'),
      renderCell: ({ formattedValue, value, row }) => {
        return (
          <Typography style={{ marginLeft: 8 }} variant="subtitle1">
            {value}
          </Typography>
        );
      },
    },
    jobTitle: {
      width: 250,
      headerName: t('position'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Chip label={value || '-'} />;
      },
    },
    email: {
      width: 350,
      headerName: 'E-Mail',
      renderCell: ({ formattedValue, value, row }) => {
        return (
          <Link variant="subtitle1" href={'mailto:' + value}>
            {value}
          </Link>
        );
      },
    },
    phoneNumber: { headerName: t('phone-number'), flex: 2, hide: true },
    mobile: { headerName: t('mobile-number'), flex: 2, hide: true },
    address: { headerName: t('address'), flex: 2, hide: true },
    language: { headerName: t('language'), flex: 1, hide: true },
    birthDate: { headerName: t('birthdate'), type: 'date', flex: 1, hide: true },
    notes: { headerName: t('notes'), flex: 2, hide: true },
    emailVerified: { headerName: t('email-verified'), hide: true },
    disabled: { headerName: t('user-disabled'), hide: true },
    lastCell: {
      flex: 1,
      align: 'right',
      // cellClassName: classes.lastCell,
      renderCell: ({ value, row }) => {
        const [resetPasswordOpen, setresetPasswordOpen] = useState(false);
        const menuActions = [];
        if (containsRight(rights, groupId, 'user_edit', 'user_create', 'user_delete')) {
          menuActions.push({
            title: t('reset-password'),
            icon: faLock,
            action: (e) => {
              setresetPasswordOpen(true);
            },
          });
        }
        if (containsRight(rights, groupId, 'user_edit')) {
          menuActions.push({
            title: t('can-edit-users'),
            icon: faEdit,
            action: (e) => {
              setEditUserPopupOpen(row as Core.User);
            },
          });
        }
        return (
          <>
            <MainMenu
              id={row.id}
              menuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              }}
              actions={menuActions}
            />
            <ResetPasswordPopup externalUser user={row as Core.User} onClose={() => setresetPasswordOpen(false)} open={resetPasswordOpen} />
          </>
        );
      },
      filterable: false,
      disableColumnMenu: true,
    },
  };
  if (process.env.NODE_ENV == 'development') {
    columns.id = { hide: true, flex: 1 };
  }

  const deleteUsersMutation = useDeleteUsersExternalMutation();
  useNotificationEffect({ title: t('error'), type: 'error', text: deleteUsersMutation.error?.message || deleteUsersMutation.error?.message }, deleteUsersMutation.isError, [deleteUsersMutation.error]);
  const notify = usePopup();
  const [filterModel, setFilterModel, fastFilterModel] = useDebouncedState<GridFilterModel>();

  return (
    <ContentContainer>
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, { ...BREADCRUMBS.usersExternal, notExact: true, path: location.pathname }]}></NavbarSmall>
      <ContentView noPadding>
        <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <ContentHeader title={t('current-users')} subtitle={usersList?.length + ' ' + t('user')} href="/">
            <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
              <Box flex={1}>
                <SearchInput
                  size="small"
                  InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
                  placeholder={t('search')}
                  fullWidth
                  value={fastFilterModel?.items?.find((i) => i.columnField == 'displayName')?.value ?? ''}
                  onChange={(e) => {
                    try {
                      new RegExp(e.target.value);
                      setFilterModel({ items: [{ columnField: 'displayName', operatorValue: 'contains', value: e.target.value }] });
                    } catch {}
                  }}
                />
              </Box>
              <ConditionalRender render={containsRight(rights, groupId, 'user_delete')}>
                <ConditionalRender render={selection.length > 0}>
                  <LoadingButton
                    sx={{ borderRadius: 999 }}
                    onClick={() => {
                      notify({
                        title: t('do-you-really-want-to-delete-count-users', { count: selection.length }),
                        type: 'warning',
                        actions: [
                          {
                            action: async () => {
                              await deleteUsersMutation.mutateAsync(selection);
                              setSelection([]);
                            },
                            text: t('cancel'),
                            type: 'primary',
                          },
                          {
                            action: async () => {
                              await deleteUsersMutation.mutateAsync(selection);
                              setSelection([]);
                            },
                            text: t('yes'),
                            type: 'error',
                          },
                        ],
                      });
                    }}
                    loading={deleteUsersMutation.isLoading}
                    variant="contained"
                    color="error"
                    endIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    {t('delete-users')}
                  </LoadingButton>
                </ConditionalRender>
              </ConditionalRender>
              <ConditionalRender render={containsRight(rights, groupId, 'user_create', 'user_edit')}>
                <Button
                  sx={{ borderRadius: 999 }}
                  onClick={() => {
                    setCreateUserPopupOpen(true);
                  }}
                  variant="contained"
                  color="primary"
                  endIcon={<FontAwesomeIcon icon={faUserPlus} />}
                >
                  {t('new-user')}
                </Button>
              </ConditionalRender>
            </Stack>
          </ContentHeader>
          <XGrid
            className={classes.root}
            headerHeight={40}
            loading={isLoading}
            sortModel={[{ field: 'displayName', sort: 'asc' }]}
            checkboxSelection
            onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
            filterModel={filterModel}
            onSelectionModelChange={({ selectionModel }) => {
              setSelection(selectionModel);
            }}
            columns={useMemo(
              () =>
                Object.entries(columns)
                  .map(([key, value]) => ({ field: key, ...value }))
                  .map((c) => ({
                    ...c,
                    renderHeader: (params) => {
                      return (
                        <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                          {params.colDef.headerName}
                        </Typography>
                      );
                    },
                  })),
              [],
            )}
            rows={usersList ?? []}
            disableSelectionOnClick
            onRowClick={(params) => setEditUserPopupOpen(params.row as Core.User)}
          />
          <UserEditExternal open={createUserPopupOpen} onClose={() => setCreateUserPopupOpen(null)} rights={rights} groupId={groupId} />
          <UserEditExternal open={editUserPopupOpen} onClose={() => setEditUserPopupOpen(false)} currentUser={editUserPopupOpen as Core.User} rights={rights} groupId={groupId} />
        </Box>
      </ContentView>
    </ContentContainer>
  );
};
