import * as countries from 'i18n-iso-countries';
// const countries = require('i18n-iso-countries');
//Note: normal import is not workin import * as englishCountries from 'i18n-iso-countries/langs/translation.json';
import i18n_iso_countries from 'i18n-iso-countries/langs/en.json';
import React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IconMap } from '../../../../base/web/src';

countries.registerLocale(i18n_iso_countries);

export const countryToFlag = ({ countryKey }) => {
  return <img src={IconMap[countryKey]} alt="flag" style={{ borderRadius: '50%', height: 18, marginRight: IconMap[countryKey] ? 10 : 0 }} />;
};
