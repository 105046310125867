import { LoadingButton, LoadingButtonProps, ToggleButton, ToggleButtonProps } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopup } from './common';

export type LoadingPromiseButton = Omit<LoadingButtonProps, 'loading' | 'onClick'> & {
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => Promise<void>;
};

export function LoadingPromiseButton({ onClick, loading: originalLoading, ...props }: LoadingButtonProps) {
  const [loading, setLoading] = useState(false);
  const notify = usePopup();
  const { t } = useTranslation();
  return (
    <LoadingButton
      onClick={async (e) => {
        try {
          setLoading(true);
          await onClick(e);
        } catch (error) {
          notify({
            title: t('error'),
            type: 'error',
            text: error?.message ?? error,
          });
        } finally {
          setLoading(false);
        }
      }}
      loading={loading || originalLoading}
      {...props}
    />
  );
}

function LoadingToggleButton({ loading, ...props }: LoadingButtonProps & ToggleButtonProps) {
  if (loading) return <LoadingButton {...props} loading />;
  return <ToggleButton {...props} />;
}

export function LoadingPromiseToggleButton({ onClick, ...props }: LoadingButtonProps & ToggleButtonProps) {
  const [loading, setLoading] = useState(false);
  const notify = usePopup();
  const { t } = useTranslation();
  return (
    <LoadingToggleButton
      onClick={async (e) => {
        try {
          setLoading(true);
          await onClick(e);
        } catch (error) {
          notify({
            title: t('error'),
            type: 'error',
            text: error.message || error,
          });
        } finally {
          setLoading(false);
        }
      }}
      loading={loading}
      {...props}
    />
  );
}
