import { Actions, API, configureStore } from '@base/core';
import { LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import * as Sentry from '@sentry/react';
import { ThemeConfig } from '@theming/theme';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { TwoFALoginCodePopup, TwoFASetPhoneNumberPopup, TwoFAVerifyFactorCodePopup } from '../components/auth/2fa';
import { TimedPopupProvider, usePopup } from '@base/web';
import { SetPasswordPopup } from '../components/common/popups/SetPasswordPopup';
import { downloadFile } from '../components/content';
import { useWithRights } from '../helpers/ConditionalRenderWithRights';
import { PdfReader } from '../modules/pdfReader/views';
import { Login, ResetPassword } from '../views/auth';
import { CampaignsByFederation } from '../views/campaign/CampaignsByFederation';
import { CampaignsByFederationOverview } from '../views/campaign/CampaignsByFederationOverview';
import { CampaignRouter } from '../views/campaign/router';
import { ExternalUsers } from '../views/externalUsers/ExternalUsers';
import { CampaignExternalRouter } from '../views/externalUsers/router/CampaignExternalRouter';
import { FomsubmissionsExternal } from '../views/externalUsers/router/FomsubmissionsExternal';
import { FileManager, FolderSettings, ModuleCreation, ModuleOverview, ModuleSettings } from '../views/fileManager';
import { FavouriteContent } from '../views/fileManager/FavouriteContent';
import { useGetFile } from '../views/fileManager/hooks';
import { Search } from '../views/fileManager/Search';
import { Home } from '../views/Home';
import { UserGroupCreation, UserOrganigram, UsersCreation } from '../views/users';
import { UserAdministrationRouter } from '../views/users/router/UserAdministrationRouter';
import { BREADCRUMBS } from './BreadcrumbsType';
import { PrivateRoute } from './PrivateRoute';
import { RedirectRoute } from './RedirectRoute';
import { ThemingView } from './ThemingView';

Sentry.init({
  dsn: 'https://85694297750c4567ae6b09be1c5203e7@o1008025.ingest.sentry.io/5975387',
});

const store = configureStore();

export { initialize } from '@base/core';
export { App as Router };

// export const ROUTES:{[key: string]: {path: string, component: any}} = {

// }

// function useErrorHandler() {
//   const state = useSelector((state: Core.StateType) => state);

//   for (const { error, success } of Object.values(state.auth.fetching)) {
//     useNotificationEffect({ title: 'Error', type: 'error', text: error }, !success && error, [error, success]);
//   }
//   for (const { error, success } of Object.values(state.files.fetching)) {
//     useNotificationEffect({ title: 'Error', type: 'error', text: error }, !success && error, [error, success]);
//   }
//   for (const { error, success } of Object.values(state.groups.fetching)) {
//     useNotificationEffect({ title: 'Error', type: 'error', text: error }, !success && error, [error, success]);
//   }
//   for (const { error, success } of Object.values(state.users.fetching)) {
//     useNotificationEffect({ title: 'Error', type: 'error', text: error }, !success && error, [error, success]);
//   }
// }

const isDevMode = process.env.NODE_ENV === 'development';

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    staleTime: 15000,
  },
});

const localeMap = {
  en: enLocale,
  de: deLocale,
};

function App({ muiOverrides }) {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorScreen />} showDialog>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeConfig themeOverrides={muiOverrides}>
            <Router />
            {isDevMode && <ReactQueryDevtools />}
          </ThemeConfig>
        </Provider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}

function ErrorScreen() {
  return (
    <div className={'background-container'}>
      <h1 className={'loading-text'} style={{ fontSize: 40 }}>
        An Error has occurred
      </h1>
      <p className={'loading-text'} style={{ marginTop: 30, fontWeight: 700 }}>
        Please reload the platform.
      </p>
      <p className={'loading-text'}>We are trying to fix this as soon as possible.</p>
    </div>
  );
}

function Router() {
  useEffect(() => {
    store.dispatch(Actions().auth.tryAutoLogin());
  }, []);
  const { user } = useSelector((state: Core.StateType) => state.auth);
  // const [searchInput, setSearchInput] = useState('');
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.language) {
      void i18n.changeLanguage(user.language);
    }
    if (user?.isTwoFAEnforced) {
      API.users
        .getUserById(user.id)
        .then((completeUser) => {
          console.log(completeUser);
          if (!(completeUser?.multiFactor?.enrolledFactors?.length > 0) && completeUser.isTwoFAEnforced) {
            dispatch(Actions().auth.create2FAcall());
          }
        })
        .catch((e) => console.log(e));
    }
  }, [user]);
  const isExternal = useWithRights('is_group_only_user');
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[i18n.language]}>
      <TimedPopupProvider>
        <SetPasswordPopup />
        <TwoFALoginCodePopup />
        <TwoFASetPhoneNumberPopup />
        <TwoFAVerifyFactorCodePopup />
        <BrowserRouter>
          <Switch>
            <Route path="/theming">
              <ThemingView />
            </Route>
            {/* Authentication */}
            <RedirectRoute path={BREADCRUMBS.login.path} exact={!BREADCRUMBS.login.notExact}>
              <Login />
            </RedirectRoute>
            <RedirectRoute path={BREADCRUMBS.resetPassword.path} exact={!BREADCRUMBS.resetPassword.notExact}>
              <ResetPassword />
            </RedirectRoute>
            {/* Users */}
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.userCreation.path} exact={!BREADCRUMBS.userCreation.notExact}>
                <UsersCreation />
              </PrivateRoute>
            )}
            {/* <PrivateRoute path={BREADCRUMBS.userEdit.path} exact={!BREADCRUMBS.userEdit.notExact}>
              <UserEdit />
            </PrivateRoute> */}
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.usergroupCreation.path} exact={!BREADCRUMBS.usergroupCreation.notExact}>
                <UserGroupCreation />
              </PrivateRoute>
            )}
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.organigram.path} exact={!BREADCRUMBS.organigram.notExact}>
                <UserOrganigram />
              </PrivateRoute>
            )}
            {!isExternal && <PrivateRoute path={BREADCRUMBS.users.path} component={UserAdministrationRouter} />}
            {/* Filemanagement */}
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.folderSettings.path} exact={!BREADCRUMBS.folderSettings.notExact}>
                <FolderSettings />
              </PrivateRoute>
            )}
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.moduleCreation.path} exact={!BREADCRUMBS.moduleCreation.notExact}>
                <ModuleCreation />
              </PrivateRoute>
            )}
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.moduleEdit.path} exact={!BREADCRUMBS.moduleEdit.notExact}>
                <ModuleSettings />
              </PrivateRoute>
            )}
            <PrivateRoute path="/files/:fileId">
              <FileSwitch />
            </PrivateRoute>
            <PrivateRoute path="/adobe-auth">
              <AdobeAuthParser />
            </PrivateRoute>
            <PrivateRoute path="/file-modules/:parentModuleId" exact={!BREADCRUMBS.moduleOverview.notExact}>
              <ModuleOverview />
            </PrivateRoute>
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.moduleOverview.path} exact={!BREADCRUMBS.moduleOverview.notExact}>
                <ModuleOverview />
              </PrivateRoute>
            )}
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.favorites.path} exact={!BREADCRUMBS.favorites.notExact}>
                <FavouriteContent />
              </PrivateRoute>
            )}

            {!isExternal && <PrivateRoute path="/campaigns" component={CampaignRouter} />}
            <PrivateRoute path="/campaigns-external" component={CampaignExternalRouter} />
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.search.path}>
                <Search />
              </PrivateRoute>
            )}
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.nfActivity.path + '/:groupId'} exact>
                <CampaignsByFederation />
              </PrivateRoute>
            )}
            {!isExternal && (
              <PrivateRoute path={BREADCRUMBS.nfActivity.path}>
                <CampaignsByFederationOverview />
              </PrivateRoute>
            )}
            <PrivateRoute path={BREADCRUMBS.usersExternal.path} component={ExternalUsers} />
            <PrivateRoute path="/submissions-external/:groupId" component={FomsubmissionsExternal} />
            {/* HomeScreen */}
            <PrivateRoute path={BREADCRUMBS.home.path}>
              <Home />
            </PrivateRoute>
          </Switch>
        </BrowserRouter>
      </TimedPopupProvider>
    </LocalizationProvider>
  );
}

function FileSwitch() {
  const { fileId } = useParams<{ fileId: string }>();
  const history = useHistory();
  const { data, error, isLoading, isError } = useGetFile(fileId);

  if (isLoading || !data) {
    return <FileManager />;
  }
  if (isError) {
    history.goBack();
    return null;
  }
  switch (data.type) {
    case 'application/pdf':
      return <PdfReader />;
    case 'folder':
      return <FileManager />;
    default: {
      void downloadFile(data as Core.NormalFile);
      // history.goBack();
      return null;
    }
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AdobeAuthParser() {
  const query = useQuery();
  const code = query.get('code') as string | null;
  const state = query.get('state') as string | null;
  const error = query.get('error_description') as string | null;
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const history = useHistory();
  const notify = usePopup();

  useEffect(() => {
    if (error?.length > 0) {
      notify({
        title: 'Error Authenticating',
        type: 'error',
        text: error,
      });
    }
  }, [error]);

  useEffect(() => {
    const redirectUrl = state.split(' ')?.[1];
    const stateUrl = state.split(' ')?.[0];

    console.log('Redirect url: ', redirectUrl);
    console.log('State url: ', stateUrl);

    if (code && state && user?.id) {
      API.adobeSign
        .processAuthResponse(code, redirectUrl, user.id)
        .then(() => {
          notify({
            title: 'Successfull Authentication',
            type: 'success',
            text: 'You have successfully signed in to Adobe Sign',
          });
        })
        .catch((err) => {
          console.error(err);
          notify({
            title: 'Error Authenticating',
            type: 'error',
            text: err?.message || 'An error occured while authenticating with Adobe Sign',
          });
        });
      // route to the correct page
      // history.push(state.split(window.location.origin).filter(str=>str.length>0)[0]);
      history.push(stateUrl);
    }
  }, [code, history, notify, state, user.id]);

  return <Home />;
}
