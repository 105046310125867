import type firebaseType from 'firebase/app'

import { firebase, getModuleUserDataCollection, MODULE_COLLECTION, USER_COLLECTION } from '../config';
import {
  IModuleService,
  ModuleDescription,
  ModuleFromDB,
  UserModuleDataFromDB,
} from '@learning/core';

export class ModuleService implements IModuleService {
  private get moduleCollection(): firebaseType.firestore.CollectionReference {
    return firebase.firestore().collection(MODULE_COLLECTION);
  }

  async getUserModuleData(
    moduleId: string,
    uid: string
  ): Promise<UserModuleDataFromDB> {

    let sn = await getModuleUserDataCollection(moduleId).doc(uid).get();
    if (sn.exists) {
      return sn.data() as UserModuleDataFromDB
    }
    else {
      return {
        areaStats: {}
      }
    }
  }

  async getModuleDefinitionById(moduleId: string): Promise<ModuleFromDB> {
    let sn = await this.moduleCollection.doc(moduleId).get();
    return sn.data() as ModuleFromDB;
  }
  listModulesForGroups(groups: string[]): Promise<ModuleDescription[]> {
    //TODO: IMPLEMENT
    return this.listModules();
  }
  async listModules(): Promise<ModuleFromDB[]> {
    let sn = await this.moduleCollection.get();
    return sn.docs.map(d => d.data() as ModuleFromDB);
  }
}
