import { Editor as CFEditor } from '@atlaskit/editor-core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faGripHorizontal, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Card, CardActions, CardContent, Divider, Grid, IconButton, TextField, Typography, useTheme } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/styles';

import React, { useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContent } from '../entities/FormContent';
import { cx } from '../helpers';

const useStyles = makeStyles({
  editorWrapper: (theme: any) => ({
    color: theme.palette.primary.main,
    '& p,h1,h2,h3,h4,h5,h6': {
      color: theme.palette.primary.main,
    },
  }),
});

export function InformationReader({ value, focused, setFocus }: { value: FormContent; focused: boolean; setFocus: (ref: HTMLDivElement) => void }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const rootref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focused) {
      setFocus(rootref.current);
    }
  }, [focused]);

  const classes = useStyles(theme);

  const content = useMemo(() => {
    return value.content
  }, [value.content])

  return (
    <Grid item xs={12} ref={rootref}>
      <div
        className={cx('question__wrapper', focused && 'focused')}
        onFocus={() => {
          console.log(value.id, ':focus');
          setFocus(rootref.current);
        }}
        // onBlur={() => !draggingRef.current && setFocused(false)}
        tabIndex={0}
      >
        <Card style={{ border: '2px solid ' + theme.palette.primary.main }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <FontAwesomeIcon icon={faInfoCircle} size="2x" style={{ marginRight: 16 }} color={theme.palette.primary.main} />
                  <Typography
                    variant="h4"
                    //@ts-ignore
                    color="primary"
                  >
                    {value.title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.editorWrapper}>
                { console.log(content) }
                <CFEditor
                  defaultValue={content}
                  key={'EDITORKEY'}
                  appearance={'chromeless'}
                  allowFindReplace
                  allowHelpDialog
                  allowDate
                  disabled
                  allowTextAlignment
                  codeBlock={{ allowCopyToClipboard: true, useLongPressSelection: true }}
                  allowExpand={{ allowInsertion: true, allowInteractiveExpand: true }}
                  allowLayouts={{ UNSAFE_addSidebarLayouts: true, allowBreakout: true, useLongPressSelection: true }}
                  allowPanel
                  allowNewInsertionBehaviour
                  waitForMediaUpload
                  allowNestedTasks
                  allowTables={{ advanced: true, allowColumnSorting: true, stickyHeaders: true }}
                  allowStatus
                  allowTasksAndDecisions
                  quickInsert
                  allowTextColor={{ EXPERIMENTAL_allowMoreTextColors: true }}
                  allowIndentation
                  allowDynamicTextSizing
                  allowKeyboardAccessibleDatepicker
                  allowRule
                  mentionInsertDisplayName
                  shouldFocus
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
}
