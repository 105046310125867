import { faLowVision } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CardContent, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../../css/components/common/popups/AccessLinkPopup.scss';
import { copyTextToClipboard } from '../../../helpers';
import { StandardPopup } from './StandardPopup';
import { usePopup } from './TimedPopup';

interface AddUserPopup {
  relativeLinkUrl: string;
  active: boolean;
  setActive: (active: boolean) => void;
}

export function AccessLinkPopup({ relativeLinkUrl, active, setActive }: AddUserPopup) {
  const { t } = useTranslation();
  const pushNofification = usePopup();
  const theme = useTheme();

  const link = window.location.origin + '/' + relativeLinkUrl.replace(/^\//, '');

  const copyLinkToClipboard = () => {
    copyTextToClipboard(link);
    pushNofification({
      title: t('link-copied-to-clipboard'),
      type: 'info',
      blocking: false,
      time: 2000,
      id: new Date().getUTCMilliseconds(),
    });
  };

  return (
    <StandardPopup visible={active} onBackdropClick={() => setActive(false)}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: 2 }}>
          <Typography variant="h3" color="primary">
            <FontAwesomeIcon icon={faLowVision} style={{ marginRight: 10 }} />
            {t('link-and-visibility')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ border: `2px solid ${theme.palette.background.gray}`, paddingX: 2, paddingY: 1, borderRadius: 1, flex: 1, marginRight: 1 }} variant="subtitle1">
            {link}
          </Typography>
          <Button variant="outlined" onClick={() => copyLinkToClipboard()} color="primary" sx={{ whiteSpace: 'nowrap' }}>
            {t('copy-link')}
          </Button>
        </Box>
      </CardContent>
    </StandardPopup>
  );
}
