import { API, FormSubmission } from '@base/core';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  ButtonBase,
  Card,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { faEmptySet, faFilePlus, faSignIn, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { AgreementUpload } from './AgreementUpload';
import { AgreementStatus } from './AgreementStatus';
import { ESignControls } from './AgreementControlls';
import { LoadingPromiseButton, usePopup, useTheme as useOldTheme } from '@base/web';

export function ESignSignelAgreement({
  formSubmission,
  agreementId,
  onSubmitted,
  ...props
}: { formSubmission: FormSubmission; agreementId: string; onSubmitted: VoidFunction } & Omit<AccordionProps, 'children'>) {
  const theme = useOldTheme();
  const { t } = useTranslation();

  const agreementData = { ...formSubmission.eSignAgreements?.[agreementId], agreementId };

  return (
    <Stack sx={{ justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }} spacing={2}>
      <Stack spacing={1} style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <Typography fontWeight={500}>{agreementData?.agreementName}</Typography>
      </Stack>
      <ESignControls form={formSubmission} agreement={agreementData} />
    </Stack>
  );
}

export function ESignAgreements({ formSubmission, isGroupUser = false, ...props }: { formSubmission: FormSubmission; isGroupUser?: boolean } & Omit<AccordionProps, 'children'>) {
  const theme = useTheme();
  const oldTheme = useOldTheme();

  const { t } = useTranslation();
  const [agreements, setAgreements] = useState<string[]>([]);
  const [addAgreement, setAddAgreement] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const notify = usePopup();

  useEffect(() => {
    if (formSubmission?.eSignAgreements) {
      // TODO add sorting dependend on created at
      const submittedIds = Object.keys(formSubmission.eSignAgreements ?? {});
      setAgreements(submittedIds);
    }
  }, [formSubmission?.eSignAgreements]); // do not watch for agreements, will lead to unesessary side effects

  useEffect(() => {
    if (user?.id && !isGroupUser) {
      setLoading(true);
      void API.adobeSign.tryInitApiSilently(user.id).finally(() => setLoading(false));
    }
  }, [user?.id, isGroupUser]);

  const authenticate = useCallback(async () => {
    const urlParts = window.location.href.split('/');
    const authUrl = urlParts[0] + '//' + urlParts[1] + urlParts[2] + '/adobe-auth/';
    const windowLocation = window.location.pathname;
    try {
      await API.adobeSign.initApiAccess(authUrl, user.id, windowLocation);
      notify({
        type: 'success',
        title: 'Successfully authenticated with adobe sign' ?? t('adobeSign.authSuccessTitle'),
      });
    } catch (e) {
      notify({
        type: 'error',
        title: 'Adobe Sign Authentication failed' ?? t('adobeSign.authErrorTitle'),
        text: e,
      });
    }
  }, [user.id]);

  const isSignedIn = API.adobeSign.isUserAuthenticated();

  if (loading) {
    return (
      <Stack sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }} spacing={1}>
        <CircularProgress size={60} style={{ margin: 8 }} />
      </Stack>
    );
  } else {
    return (
      <>
        <Stack spacing={1}>
          {isSignedIn && (
            <Alert severity={'info'}>
              <AlertTitle>Information</AlertTitle>
              Sending reminders, activity overview and more actions can be done in the adobe console.
            </Alert>
          )}
          {!isSignedIn && !isGroupUser && (
            <Alert severity={'warning'}>
              <Stack spacing={1} alignItems={'flex-start'}>
                <AlertTitle>Not Authenticated in Adobe Sign</AlertTitle>
                To be able to create Agreements you need to sign in in the Adobe Sign Console.
                <LoadingPromiseButton onClick={authenticate} variant={'contained'} color={'warning'} startIcon={<FontAwesomeIcon icon={faSignIn} style={{ width: 18, height: 18 }} />}>
                  Open Adobe Sign Console
                </LoadingPromiseButton>
              </Stack>
            </Alert>
          )}
          {agreements.map((agreementId, index) => (
            <Stack sx={{ border: `2px ${theme.palette.background.grayDark} solid`, borderRadius: 2, alignItems: 'center', justifyContent: 'center', p: 2 }}>
              <Stack sx={{ alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', width: '100%', mb: 1 }}>
                <Typography fontWeight={400} color={oldTheme.colors['dark-gray']}>
                  {'Agreement'}
                </Typography>
                <Stack direction={'row'} spacing={1}>
                  {formSubmission.eSignAgreements[agreementId]?.deadline && (
                    <Chip label={t('deadline') + ': ' + new Date(formSubmission.eSignAgreements[agreementId]?.deadline)?.toLocaleDateString() ?? '-'} />
                  )}
                  <AgreementStatus status={formSubmission.eSignAgreements[agreementId]?.status} />
                </Stack>
              </Stack>
              <ESignSignelAgreement formSubmission={formSubmission} agreementId={agreementId} onSubmitted={() => setAddAgreement(false)} />
            </Stack>
          ))}
          {agreements.length === 0 && (
            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
              <FontAwesomeIcon icon={faEmptySet} style={{ width: 16, height: 16 }} />
              <Typography variant={'subtitle1'}>No Agreements</Typography>
            </Stack>
          )}
          {addAgreement && (
            <Stack
              sx={{
                border: `2px ${theme.palette.background.grayDark} solid`,
                borderRadius: 2,
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
              }}
            >
              <Stack sx={{ alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', width: '100%', mb: 1 }}>
                <Typography variant="h5" color={oldTheme.colors['dark-gray']}>
                  {'New Agreement'}
                </Typography>
                <IconButton sx={{ mr: 1 }} size={'small'} onClick={() => setAddAgreement(false)}>
                  <FontAwesomeIcon icon={faTrash} style={{ width: 18, height: 18, color: theme.palette.error.main }} />
                </IconButton>
              </Stack>
              <AgreementUpload formSubmission={formSubmission} uid={user.id} onSubmitted={() => setAddAgreement(false)} />
            </Stack>
          )}
        </Stack>
        {!addAgreement && isSignedIn && !isGroupUser && (
          <Stack sx={{ px: 2, mt: 2 }}>
            <ButtonBase
              sx={{
                display: 'flex',
                backgroundColor: theme.palette.grey.A200,
                alignItems: 'center',
                justifyContent: 'center',
                border: `2px dashed ${theme.palette.grey.A400}`,
                p: 2,
                flexDirection: 'column',
                borderRadius: 2,
                // width: '100%',
              }}
              onClick={() => setAddAgreement(true)}
            >
              <Box
                sx={{
                  display: 'flex',
                  backgroundColor: theme.palette.grey.A400,
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                  borderRadius: 999,
                  mb: 1,
                }}
              >
                <FontAwesomeIcon icon={faFilePlus} style={{ width: 24, height: 24 }} />
              </Box>
              <Typography>{'Add a new Agreement' ?? t('addAnAgreement')}</Typography>
            </ButtonBase>
          </Stack>
        )}
      </>
    );
  }
}

export function ESignAgreementAccordion({ formSubmission, ...props }: { formSubmission: FormSubmission } & Omit<AccordionProps, 'children'>) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Accordion defaultExpanded disableGutters>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
        <Typography variant="overline">{'Agreements' ?? t('eSignagreements')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        <ESignAgreements formSubmission={formSubmission} />
      </AccordionDetails>
    </Accordion>
  );
}

export function ESignAgreementsElement({ formSubmission, ...props }: { formSubmission: FormSubmission } & Omit<AccordionProps, 'children'>) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card sx={{ boxShadow: 'unset', p: 2, border: `2px solid ${theme.palette.grey[400]}`, borderRadius: 1 }}>
      <Stack spacing={2}>
        <Typography variant="overline">{t('agreements')}</Typography>
        <ESignAgreements formSubmission={formSubmission} isGroupUser />
      </Stack>
    </Card>
  );
}
