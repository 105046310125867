export const SHORT_ANSWER = "short-answer"
export const LONG_PARAGRAPH = "long-paragraph"
export const CHECKBOXES = "checkboxes"
export const DROP_DOWN_MENU = "drop-down-menu"
export const MULTIPLE_CHOICE_QUESIONS = "multiple-choice-quesions"
export const LINEAR_SCALE = "linear-scale"
export const DATE = "date"
export const DATE_RANGE = "date-range"
export const FILE_UPLOAD = "request-file"
export const PROVIDE_FILES = "provide-file"
export const SECTION = "section"
export const INFO = "info"
export const NUMBER = "number"
export const PHONE_NUMBER = "phone-number"
export const TABLE = "table"
