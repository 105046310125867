import React from 'react';
import '../../css/components/common/tooltip.scss';

type tooltipProps = {
  text?: string;
  children: any;
  tooltipStyle?: React.CSSProperties;
  conClassName?: string;
  onConClick?: () => void;
  conStyle?: React.CSSProperties;
  hidden?: boolean;
};

export const Tooltip = ({ text, children, tooltipStyle, hidden = false, conStyle, conClassName, onConClick = () => null }: tooltipProps) => (
  <div style={conStyle} className={'tooltip ' + conClassName} onClick={onConClick}>
    <div className="tooltiptext" style={{ ...tooltipStyle }} hidden={hidden}>
      {text}
    </div>
    {children}
  </div>
);
