import { Actions } from '@base/core';
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons';
import { alpha, Box, Stack, useTheme } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ContentHeader } from '../../components/common';
import { ContextMenu, ContextMenuElementType } from '../../components/common/popups/ContextMenu';
import { ContentElement } from '../../components/content';
import { EmptyContentView, NoSearchResultContentView } from '../../components/content/EmptyContentView';
import { SortDropdown } from '../../components/content/search';
import { SearchInput } from '../../components/inputs/SearchInput';
import { compareSearch, ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/Views/content/FileManager.scss';
import { useSelection } from '../../lib/hooks/useSelection';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';

export interface Folder extends Core.VirtualFile {
  description?: string;
}

export const FavouriteContent = () => {
  const [filter, setFilter] = useState<'a-z' | 'z-a'>('a-z');
  const [noSearchResult, setNoSearchResult] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [contextMenuItems, setContextMenuItems] = useState<ContextMenuElementType[]>([]);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number; mouseY: number }>({ mouseX: null, mouseY: null });

  const { user } = useSelector((state: Core.StateType) => state.auth);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    favoriteFiles,
    fetching: { fetchFavorites },
  } = useSelector((state: Core.StateType) => state.files);

  const actualFavoriteFiles = useMemo(() => favoriteFiles ?? [], [favoriteFiles]);
  const { selectAll, deSelectAll, onContentClick, selectedMap, selectedItemCount, selectedIds } = useSelection(actualFavoriteFiles);
  const fileCount = useMemo(() => actualFavoriteFiles.filter((f) => f.type !== 'folder').reduce((sum, cv) => sum + 1, 0), [actualFavoriteFiles]);
  const folderCount = useMemo(() => actualFavoriteFiles.filter((f) => f.type === 'folder').reduce((sum, cv) => sum + 1, 0), [actualFavoriteFiles]);

  const sortedFavoriteFiles = useMemo(() => {
    const res = (favoriteFiles ?? []).sort((a, b) => (a.name ?? '').localeCompare(b.name));
    if (filter === 'z-a') return res.reverse();
    return res;
  }, [favoriteFiles, filter]);

  useEffect(() => {
    if (!fetchFavorites.fetching) {
      dispatch(Actions().files.fetchFavorites());
    }
  }, []);

  useEffect(() => {
    if (sortedFavoriteFiles?.length > 0) sortedFavoriteFiles.some((file) => compareSearch(searchValue, file.name)) ? setNoSearchResult(false) : setNoSearchResult(true);
  }, [searchValue, sortedFavoriteFiles]);

  if (!user) return null;
  const openFolder = (id: string) => history.push(`/files/${id}`);

  const unsetFileFavorite = (file: Core.VirtualFile) => {
    file.favoriteOf = file.favoriteOf.filter((u) => u !== user.id);
    dispatch(Actions().files.updateFile(file.id, { setFavorite: { uid: user.id, isFavorite: false } }));
  };

  const onContextMenuStart = (): ContextMenuElementType[] => {
    const contextOptions: ContextMenuElementType[] = [];
    contextOptions.push({
      text: t('select-all'),
      icon: faCheckDouble,
      onClick: () => {
        selectAll();
      },
    });
    if (selectedItemCount > 0) {
      contextOptions.push({
        text: t('unset-all-favorite'),
        icon: faCheckDouble,
        onClick: () => {
          selectedIds.forEach((fileId) => {
            const fileToUnset = sortedFavoriteFiles.find((favoriteFile) => favoriteFile.id === fileId);
            fileToUnset && unsetFileFavorite(fileToUnset);
          });
        },
      });
    }
    return contextOptions;
  };

  const handleContextMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    setContextMenuItems(onContextMenuStart());
  };

  return (
    <ContentContainer>
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.favorites]} />
      <ContentView
        onClick={(event) => {
          deSelectAll();
          event.stopPropagation();
        }}
        loading={fetchFavorites.fetching}
        onContextMenu={(event) => handleContextMenuClick(event)}
        noPadding
      >
        <div style={{ zIndex: 3 }}>
          <ContentHeader title={t('your-favorites')} subtitle={t('fileWithCount', { count: fileCount }) + ', ' + t('folderWithCount', { count: folderCount })} href={'/'}>
            <Stack direction="row" spacing={1} flex={1} marginLeft={5} alignItems="center">
              <Box flex={1}>
                <SearchInput
                  size="small"
                  InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
                  placeholder={t('search')}
                  fullWidth
                  value={searchValue}
                  onChange={(e) => {
                    try {
                      new RegExp(e.target.value);
                      setSearchValue(e.target.value);
                    } catch {}
                  }}
                />
              </Box>
              <SortDropdown onChange={setFilter} />
            </Stack>
          </ContentHeader>
        </div>
        <Box sx={{ padding: 4 }}>
          <div className="file-grid">
            {fileCount === 0 && folderCount === 0 && !noSearchResult ? <EmptyContentView /> : null}
            {noSearchResult ? <NoSearchResultContentView /> : null}
            <ContextMenu
              menuItems={contextMenuItems}
              position={contextMenuPosition}
              onClose={() => {
                setContextMenuPosition({ mouseX: null, mouseY: null });
                setContextMenuItems([]);
              }}
            />
            {sortedFavoriteFiles.map((file) => {
              if (compareSearch(searchValue, file.name) || searchValue === '') {
                return (
                  file.type === 'folder' && (
                    <ContentElement
                      key={file.id}
                      file={file}
                      selected={selectedMap[file.id]}
                      favorite={true}
                      fileRights={{}}
                      setFavorite={async () => await unsetFileFavorite(file)}
                      onClick={(e) => {
                        e.stopPropagation();
                        onContentClick(file, e);
                      }}
                      onDoubleClick={() => {
                        openFolder(file.id);
                      }}
                      setContextMenuItems={(fileItems, menuPosition) => {
                        setContextMenuItems(fileItems);
                        setContextMenuPosition(menuPosition);
                      }}
                      // contentCount={0} //TODO: Implement
                    />
                  )
                );
              }
            })}
            {sortedFavoriteFiles.map((file) => {
              if (compareSearch(searchValue, file.name) || searchValue === '') {
                return (
                  file.type !== 'folder' && (
                    <ContentElement
                      key={file.id}
                      file={file}
                      selected={selectedMap[file.id]}
                      favorite={true}
                      fileRights={{}}
                      onFileMove={null}
                      setFavorite={async () => await unsetFileFavorite(file)}
                      onClick={(e) => {
                        e.stopPropagation();
                        onContentClick(file, e);
                      }}
                      onDoubleClick={async () => {
                        if (file.type === 'si/document') {
                          if (user?.roles?.superAdmin || file.permissions.users.read.some((uid) => uid === user.id)) {
                            history.push(`/files/${file.id}/edit`);
                          } else {
                            history.push(`/files/${file.id}/read`);
                          }
                        } else {
                          window.open(await (file as Core.NormalFile).downloadUrl, '_blank');
                        }
                      }}
                      setContextMenuItems={(fileItems, menuPosition) => {
                        setContextMenuItems(fileItems);
                        setContextMenuPosition(menuPosition);
                      }}
                    />
                  )
                );
              }
            })}
          </div>
        </Box>
      </ContentView>
    </ContentContainer>
  );
};
