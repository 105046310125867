import { SimpleFileProvider } from '@base/firebase';
import { Form } from '@editors/form-editor';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationForm } from '../../..';
import { FormEditorView } from '../FormEditorView';
import { useCreateFormMutation, useDeleteFormMutation, useUpdateFormMutation } from '../hooks/mutations';
import { useGetFile } from '../hooks/useGetFile';
import { useGetForm } from '../hooks/useGetForm';

export function CampaignFormEditorWrapper({ match }) {
  const fileProvider = useMemo(() => new SimpleFileProvider('/campaigns/forms/provided_files'), []);

  const { parentId, formId } = useParams<{ parentId: string; formId: string }>();

  const { data: campaign } = useGetFile(parentId);
  const { data: form, isLoading } = useGetForm<ApplicationForm>(parentId, formId, { enabled: formId != 'new' });

  const history = useHistory();

  const createFormMutation = useCreateFormMutation();
  const updateFormMutation = useUpdateFormMutation();
  const deleteFormMutation = useDeleteFormMutation();

  const handleSave = async (form: Form, create?: boolean) => {
    if (formId == 'new' || create) {
      const newForm = await createFormMutation.mutateAsync({ campaignId: parentId, form: form });
      history.replace(newForm.id);
    } else {
      await updateFormMutation.mutateAsync({ campaignId: parentId, formId, update: form });
    }
  };

  return (
    <FormEditorView
      fileProvider={fileProvider}
      isLoading={isLoading}
      campaignName={campaign?.name}
      onClose={() => {
        history.goBack();
      }}
      onSave={handleSave}
      onDelete={async (formId: string) => {
        await deleteFormMutation.mutateAsync({ campaignId: parentId, formId: formId });
        history.goBack();
      }}
      form={form}
    />
  );
}
