import * as React from 'react';

function SvgEditorIc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={52} height={52} {...props}>
      <defs>
        <linearGradient id="editor_ic_svg__a" x1={0.561} y1={1.091} x2={0.07} y2={0.5} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#005fff" />
          <stop offset={1} stopColor="#0090ff" />
        </linearGradient>
        <linearGradient id="editor_ic_svg__b" x1={0.561} y1={1.091} x2={0.07} y2={0.5} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#efefef" />
          <stop offset={1} stopColor="#b8b8b8" />
        </linearGradient>
      </defs>
      <g>
        <rect width={52} height={52} rx={5} fill="url(#editor_ic_svg__a)" />
        <rect width={52} height={52} rx={5} opacity={0.2} fill="url(#editor_ic_svg__b)" />
        <rect width={52} height={37} rx={5} opacity={0.2} fill="url(#editor_ic_svg__b)" />
        <rect width={52} height={20} rx={5} opacity={0.2} fill="url(#editor_ic_svg__b)" />
      </g>
      <path
        d="M32.221 16.587l4.979 4.978a.54.54 0 010 .762L25.145 34.381l-5.122.568a1.073 1.073 0 01-1.187-1.187l.564-5.121 12.059-12.054a.54.54 0 01.762 0zm8.941-1.264l-2.694-2.694a2.159 2.159 0 00-3.047 0l-1.953 1.954a.54.54 0 000 .762l4.978 4.978a.54.54 0 00.762 0l1.954-1.954a2.159 2.159 0 000-3.047zM31.194 31.1v5.619H13.532v-17.66h12.684a.679.679 0 00.469-.193l2.208-2.208a.662.662 0 00-.469-1.131H12.649A2.65 2.65 0 0010 18.176V37.6a2.65 2.65 0 002.649 2.649h19.428a2.65 2.65 0 002.649-2.649v-8.706a.664.664 0 00-1.131-.469l-2.208 2.208a.679.679 0 00-.193.467z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgEditorIc;
