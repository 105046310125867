import * as React from 'react';

function SvgAlignRightRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.514 20.746" ref={svgRef} {...props}>
      <path d="M.768 8.452h19.978a.768.768 0 00.768-.768v-.768a.768.768 0 00-.768-.768H.768A.768.768 0 000 6.915v.768a.768.768 0 00.768.769zm19.978 9.988H.768a.768.768 0 00-.768.769v.768a.768.768 0 00.768.768h19.978a.768.768 0 00.768-.768v-.768a.768.768 0 00-.768-.769zM20.9 0H8.3a.616.616 0 00-.616.616v1.073a.616.616 0 00.616.616h12.6a.616.616 0 00.616-.616V.616A.616.616 0 0020.9 0zm0 12.294H8.3a.616.616 0 00-.616.616v1.073a.616.616 0 00.616.617h12.6a.616.616 0 00.616-.616V12.91a.616.616 0 00-.616-.616z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAlignRightRegular);
export default ForwardRef;
