import { faChevronRight, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, ListItem, ListItemIcon, ListItemProps, ListItemText, Stack, Tooltip, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

type NavListItemProps = {
  icon: IconDefinition;
  title: string;
  inactive?: boolean;
  count?: number | { color: string; value: number; label?: string; countIcon: any }[];
} & ListItemProps;

export function NavListItem({ icon, title, inactive, count, ...props }: NavListItemProps) {
  const theme = useTheme();

  return (
    <ListItem {...(props as any)} button sx={{ color: theme.palette.primary.main, opacity: inactive ? 0.8 : 1 }}>
      <ListItemIcon>
        <FontAwesomeIcon size="lg" fixedWidth icon={icon} />
      </ListItemIcon>
      <ListItemText style={{ wordBreak: 'break-word' }}>{title}</ListItemText>
      {count ? (
        Array.isArray(count) ? (
          <Stack spacing={0.5} marginRight={-1} flexShrink={0}>
            {count
              .filter((countItem) => countItem.value > 0)
              .map((countItem, i) => (
                <Tooltip key={i} title={countItem.label}>
                  <Box
                    sx={{
                      minWidth: 16,
                      height: 16,
                      backgroundColor: theme.palette[countItem.color].main,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 50,
                      padding: 0.4,
                      paddingX: 1,
                      color: 'white',
                      flexShrink: 0,
                      fontSize: 12,
                      fontWeight: '600',
                    }}
                  >
                    <Typography variant="subtitle2">
                      <FontAwesomeIcon style={{ marginRight: 5 }} icon={countItem.countIcon} />
                      {countItem.value}
                    </Typography>
                  </Box>
                </Tooltip>
              ))}
          </Stack>
        ) : (
          <Box
            sx={{
              minWidth: 18,
              height: 18,
              backgroundColor: theme.palette.error.main,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 50,
              padding: 0.25,
              color: 'white',
              fontSize: 12,
              fontWeight: '600',
            }}
          >
            {count}
          </Box>
        )
      ) : (
        <ListItemIcon sx={{ margin: 0 }}>
          <FontAwesomeIcon size="sm" fixedWidth icon={faChevronRight} />
        </ListItemIcon>
      )}
    </ListItem>
  );
}

type NavLinkListItemProps = NavListItemProps & ListItemProps & React.PropsWithoutRef<NavLinkProps> & React.RefAttributes<HTMLAnchorElement>;

export function NavLinkListItem(props: NavLinkListItemProps) {
  const theme = useTheme();
  return <NavListItem {...(props as any)} component={NavLink} activeStyle={{ backgroundColor: theme.palette.primary.main, color: theme.palette.getContrastText(theme.palette.primary.main) }} />;
}
