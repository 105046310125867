import * as React from 'react';

function SvgBold(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.69 21" ref={svgRef} {...props}>
      <path d="M.66 21a.622.622 0 01-.48-.2.7.7 0 01-.18-.5V.72A.744.744 0 01.18.195.622.622 0 01.66 0h8.19a6.749 6.749 0 014.8 1.53 5.237 5.237 0 011.62 3.99 4.807 4.807 0 01-.78 2.805 4.787 4.787 0 01-1.83 1.635 5.431 5.431 0 012.16 1.95 5.38 5.38 0 01.87 3.03 6.2 6.2 0 01-.765 3.06 5.5 5.5 0 01-2.265 2.19 7.41 7.41 0 01-3.57.81zM8.4 8.91a4.408 4.408 0 002.97-.885 3.1 3.1 0 001.02-2.475 2.864 2.864 0 00-1.005-2.37A4.681 4.681 0 008.4 2.37H2.82v6.54zm.3 9.72a4.213 4.213 0 003.015-1.035 3.491 3.491 0 001.1-2.655 3.479 3.479 0 00-1.1-2.67A4.251 4.251 0 008.7 11.25H2.82v7.38z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBold);
export default ForwardRef;
