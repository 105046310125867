import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import '../../../css/components/common/popups/ContextMenu.scss';

export type ContextMenuElementType = {
  text: any;
  icon?: any;
  onClick?: () => void;
};

type ContextMenuProps = {
  menuItems: ContextMenuElementType[];
  position: {
    mouseX: null | number;
    mouseY: null | number;
  };
  onClose: () => void;
};
export const ContextMenu = ({ menuItems, position, onClose }: ContextMenuProps) => {
  return (
    <Menu
      keepMounted
      open={position.mouseY !== null}
      onClose={() => onClose()}
      anchorReference="anchorPosition"
      anchorPosition={position.mouseY !== null && position.mouseX !== null ? { top: position.mouseY, left: position.mouseX } : undefined}
    >
      {menuItems.map((item) => {
        return (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              item.onClick();
              onClose();
            }}
          >
            {item.icon && <FontAwesomeIcon icon={item.icon} className="icon" />} {item.text}
          </MenuItem>
        );
      })}
    </Menu>
  );
};
