import * as React from 'react';

function SvgArrowToBottomRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.786 20.75" ref={svgRef} {...props}>
      <path d="M16.141 9.268l-6.854 6.873a.556.556 0 01-.787 0L1.644 9.268a.556.556 0 010-.787l.908-.908a.55.55 0 01.792.009l4.34 4.5V.556A.557.557 0 018.24 0h1.3a.557.557 0 01.556.556v11.523l4.34-4.5a.558.558 0 01.792-.009l.908.908a.548.548 0 01.005.79zm1.088 9.073H.556A.557.557 0 000 18.9v1.3a.557.557 0 00.556.556H17.23a.557.557 0 00.556-.556v-1.3a.557.557 0 00-.556-.558z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowToBottomRegular);
export default ForwardRef;
