import { faEnvelope, faExclamationCircle, faInfoCircle, faShareAll, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Grid, Stack, TextField, Typography, useTheme } from '@material-ui/core';
import { StandardPopup } from 'libs/base/web/src/components/common';
import { LoadingPromiseButton } from 'libs/base/web/src/components/LoadingPromiseButton';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { UserSelectInput, TSelectableGroup, TSelectableUser, DisplayUser } from 'libs/base/web/src/components/users';
import { useGetUsers } from 'libs/base/web/src/views/users/hooks/useGetUsers';
import { useGetGroups } from 'libs/base/web/src/views/users/hooks/useGetGroups';
import {getIconFromAdobeStatus} from "../../views/campaign/eSign/helpers";
import { Group } from 'libs/base/core/src/entities/Group';
import { useSettings } from '@base/web';
import {initialState} from "@atlaskit/editor-core/dist/types/plugins/find-replace/plugin";
import {includes} from "lodash";

export type SendEmailToUsersPopupProps = {
  active: boolean;
  setActive: (active: boolean) => void;
  sendEmail: (to: string[], subject: string, message: string) => Promise<void>;
  subject?: string;
  message?: string;
  selectedUsers: TSelectableUser[];
};

const validator = string().email();

export const SendEmailToUsersPopup = ({ active, setActive, sendEmail, subject: s = '', message: m = '', selectedUsers }: SendEmailToUsersPopupProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [editMailState, setEditMailState] = useState<boolean>(false);
  const [recipients, setRecipients] = useState([]);

  const [subject, setSubject] = useState(s);
  const [message, setMessage] = useState(m);
  const selectedRecipients = recipients.findIndex((recipient) => recipient.displayName?.toLowerCase() === 'all');

  const { data: usersList = []} = useGetUsers();
  const { data: groupsList = []} = useGetGroups();
  const settings = useSettings();

  const users: TSelectableUser[] = useMemo(() => {
    const listableUsers = usersList.map((user) => {
      const selectableUser = user as TSelectableUser;
      const group = groupsList.find((g) => user?.groups?.[g?.id]);
      selectableUser.groupName = group?.name;
      const namedGroup = settings.namedGroups.find((namedGroup) => namedGroup?.name === group?.type);
      selectableUser.icon = namedGroup?.getIcon && namedGroup.getIcon(group as Group);
      return selectableUser;
    });
    return listableUsers;
  }, [groupsList, settings.namedGroups, usersList]);

  const groups: TSelectableGroup[] = useMemo(() => {
    const federations: TSelectableGroup[] = []
    groupsList.forEach((group: any) => {
      if (group?.type === 'federation' && !includes(group?.name.toLowerCase(), 'test')) {
        const federation: TSelectableGroup = group;
        federation.displayName = `${group?.requestData.country} (${group?.users.length} Members)`; // TODO translation
        const namedGroup = settings.namedGroups.find((namedGroup) => namedGroup?.name === group?.type);
        federation.icon = namedGroup?.getIcon && namedGroup.getIcon(group as Group);
        federations.push(federation as TSelectableGroup);
      }
    })
    return federations
  }, [groupsList, settings.namedGroups])

  async function send() {
    let users = recipients;
    if (selectedRecipients > -1) users = selectedUsers;

    await sendEmail(
      users.map((o) => o.email),
      subject,
      message.replace(/\n/g, '<br/>'),
    );
  }

  function validateEmail(email): boolean {
    try {
      if (email === 'all') return true;
      validator.validateSync(email);
      return true;
    } catch {
      return false;
    }
  }

  return (
    <StandardPopup PaperComponent={Box} visible={active} onBackdropClick={() => setActive(false)} width={700}>
      <Card>
        <Accordion sx={{ padding: 1 }} expanded={!editMailState} onChange={() => setEditMailState(false)}>
          <AccordionSummary>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography variant="h3" color="primary">
                <FontAwesomeIcon icon={faUsers} style={{ marginRight: 10 }} />
                {t('recipients')}
              </Typography>
              {recipients.length === 0 && (
                <Typography variant="h3" color={theme.palette.warning.main} style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} paddingTop={0}>
                <Box sx={{ borderRadius: 2, display: 'setSelectedUsersflex', alignItems: 'center', backgroundColor: theme.palette.info.lighter, padding: 2 }}>
                  <FontAwesomeIcon icon={faInfoCircle} fixedWidth style={{ width: 32, height: 32, marginRight: 12 }} color={theme.palette.primary.main} />
                  <Typography variant="subtitle1" color={theme.palette.primary.main}>
                    {t('invitation-mail-description')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <UserSelectInput setSelectedUsers={setRecipients} groups={groups} users={users} selectedUsers={recipients} preselectedUsers={selectedUsers} loading={false} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack style={{ marginTop: 20 }} spacing={1} direction="row" justifyContent="flex-end">
                <Button variant="outlined" color="error" onClick={() => setActive(false)}>
                  {t('cancel')}
                </Button>
                <Button variant="outlined" color="primary" onClick={() => setEditMailState(!editMailState)}>
                  {t('edit-mail')}
                  <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: 10 }} />
                </Button>
                <LoadingPromiseButton disabled={recipients.length === 0 || subject.length === 0 || message.length === 0} variant="contained" color="primary" onClick={send}>
                  {t('send-mails')}
                  <FontAwesomeIcon icon={faShareAll} style={{ marginLeft: 10 }} />
                </LoadingPromiseButton>
              </Stack>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <Accordion sx={{ padding: 1 }} expanded={editMailState} onChange={() => setEditMailState(true)}>
          <AccordionSummary>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography variant="h3" color="primary">
                <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 10 }} />
                {t('email-editor')}
              </Typography>
              {(subject.length === 0 || message.length === 0) && (
                <Typography variant="h3" color={theme.palette.warning.main} style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ autoComplete: 'chrome-off' }}
                  id="email-subject"
                  variant="outlined"
                  label="Subject"
                  placeholder={t('subject')}
                  fullWidth
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField id="email-message" variant="outlined" label="Message" placeholder={t('message')} multiline fullWidth value={message} onChange={(e) => setMessage(e.target.value)} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack style={{ marginTop: 20 }} spacing={1} direction="row" justifyContent="flex-end">
                <Button variant="outlined" color="error" onClick={() => setActive(false)}>
                  {t('cancel')}
                </Button>
                <Button variant="outlined" color="primary" onClick={() => setEditMailState(!editMailState)}>
                  {t('edit-recipients')}
                  <FontAwesomeIcon icon={faUsers} style={{ marginLeft: 10 }} />
                </Button>
                <LoadingPromiseButton disabled={recipients.length === 0 || subject.length === 0 || message.length === 0} variant="contained" color="primary" onClick={send}>
                  {t('send-mails')}
                  <FontAwesomeIcon icon={faShareAll} style={{ marginLeft: 10 }} />
                </LoadingPromiseButton>
              </Stack>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
    </StandardPopup>
  );
};
