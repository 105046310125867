import { API, Campaign, FormSubmission } from "@base/core";
import { combineLatest, from, Observable, of, OperatorFunction } from "rxjs";
import { map, switchMap, tap, filter } from "rxjs/operators";



export function getCampaignsFromCampaignFolder(): OperatorFunction<Campaign, Campaign[]> {
  return (campaign$: Observable<Campaign>) => campaign$.pipe(switchMap((campaign) => from(API.campaigns.getChildren<Campaign>(campaign.id))))
}

export function getFormSubmissionsFromCampaign(): OperatorFunction<Campaign, FormSubmission[]> {
  return (campaign$: Observable<Campaign>) => campaign$.pipe(switchMap((campaign) => from(API.campaigns.getFormViewerContents(campaign.id))))
}

export function getNotificationCountFromFormSubmissions(userId: string): OperatorFunction<FormSubmission[], number> {
  return (submissions$: Observable<FormSubmission[]>) => submissions$.pipe(
    switchMap((submissions) => {
      return combineLatest(
        submissions.map((s) => {
          const chatId = 'formsubmissions:' + s.id;
          return API.chats.getChatObservable(chatId);
        }),
      );
    }),
    map((chatItemArrayArrays) => chatItemArrayArrays.flatMap(c => c)),
    map((chats) => chats.reduce((acc, chat) => {
      let alreadyRead = Object.keys(chat.read).includes(userId)
      return acc + (alreadyRead ? 0 : 1)
    }, 0)),
  )
}

export function getNotificationCountFromCampaign(userId: string): OperatorFunction<Campaign, number> {
  return (campaign$: Observable<Campaign>) => campaign$.pipe(
    getFormSubmissionsFromCampaign(),
    getNotificationCountFromFormSubmissions(userId)
  )
}

export function getNotificationCountFromCampaigns(userId: string): OperatorFunction<Campaign[], number> {
  return (campaigns$: Observable<Campaign[]>) => campaigns$.pipe(
    switchMap(campaigns => {
      return combineLatest(campaigns.map(campaign =>
        getFormSubmissionsFromCampaign()(of(campaign)),
      ))
    }
    ),
    map(formsubmissions => {
      return formsubmissions.flatMap(c => c)
    }),
    map(formsubmissions => {
      return formsubmissions
    }),
    getNotificationCountFromFormSubmissions(userId)
  )
}

export function getNotificationCountFromCampaignParent(userId: string): OperatorFunction<Campaign, number> {
  return (campaign$: Observable<Campaign>) => campaign$.pipe(

    getCampaignsFromCampaignFolder(),
    getNotificationCountFromCampaigns(userId),
  )
}
