import { InviteUserPopup } from '@access-request';
import { API, archivedEmail } from '@base/core';
import { faMailBulk } from '@fortawesome/pro-regular-svg-icons';
import { faEnvelope, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, XGrid } from '@material-ui/x-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../assets/universal/new-empty-state.svg';
import { ConditionalRender, ContentHeader, LoadingPopup, usePopup } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { ConditionalRenderWithRights } from '../../helpers/ConditionalRenderWithRights';
import { useDeleteArchiveEmailMutation, useGetArchivedEmails } from './hooks/useGetArchiveTemplates';
import ShowArchivedEmailPopup from './popups/ShowArchivedEmailPopup';
import { GridNoResultsOverlay } from '../../components/content/GridContent';

const useStyles = makeStyles({
  root: (theme: any) => ({
    flex: 1,
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
  lastCell: {
    minWidth: '300px !important',
    position: 'absolute',
    right: 14,
    zIndex: 10,
  },
});

export type EmailArchiveProps = {
  emailFilter: 'system' | 'send';
};

export const EmailArchive = ({ emailFilter }: EmailArchiveProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const notify = usePopup();
  const [invitationEmails, setInvitationEmails] = useState(false);
  const [selection, setSelection] = useState([]);
  const [isSystemView, setIsSystemView] = useState(emailFilter === 'system' ?? false);
  const { isLoading: emailsLoading = false, data: archivedEmails = [] } = useGetArchivedEmails(isSystemView);
  const deleteEmailMutation = useDeleteArchiveEmailMutation(isSystemView);

  const columns: (lastCellClassName: string) => GridColDef[] = (lastCellClassName: string) => [
    {
      field: 'subject',
      width: 350,
      headerName: t('subject'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'timestamp',
      width: 180,
      headerName: t('date'),
      renderCell: ({ formattedValue, value = 'federation', row }) => {
        const date = (value as FirebaseFirestore.Timestamp)?.toDate();
        return <Typography variant="subtitle1">{`${date.toLocaleDateString('de')} - ${date.toLocaleTimeString('de', { timeStyle: 'short' })}`}</Typography>;
      },
    },
    {
      field: 'sendTo',
      width: 130,
      headerName: t('recipients'),
      renderCell: ({ formattedValue, value, row }) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body1" fontWeight="600">
              {(value as []).length}
            </Typography>
            <FontAwesomeIcon icon={faMailBulk} style={{ marginLeft: 4, marginRight: 10 }} />
          </Stack>
        );
      },
    },
    {
      flex: 1,
      align: 'right',
      cellClassName: lastCellClassName,
      renderCell: ({ formattedValue, value, row }: { formattedValue: any; value: any; row: archivedEmail }) => {
        const [showEmailOpen, setShowEmailOpen] = useState(undefined);

        return (
          <>
            <Button
              sx={{ borderRadius: 999, color: 'info.main', backgroundColor: 'info.lighter', ':hover': { bgcolor: 'info.main', color: 'white' } }}
              onClick={(e) => {
                e.stopPropagation();
                setShowEmailOpen(true);
              }}
              variant="contained"
              endIcon={<FontAwesomeIcon icon={faEnvelope} />}
            >
              {t('open')}
            </Button>
            <ShowArchivedEmailPopup
              open={showEmailOpen}
              setOpen={setShowEmailOpen}
              archivedEmail={row}
              onDelete={async () => {
                try {
                  await deleteEmailMutation.mutateAsync(row.id);
                  setShowEmailOpen(false);
                  notify({ title: t('deleted-successful'), type: 'success' });
                } catch (error) {
                  notify({ title: t('error'), type: 'error', text: error });
                }
              }}
            />
          </>
        );
      },
      field: '1',
      filterable: false,
      disableColumnMenu: true,
    },
  ];
  const [showEmail, setShowEmail] = useState<archivedEmail | undefined>(undefined);
  const [openDeletePopup, setOpenDeletePopup] = useState(undefined);

  return (
    <Box flex={1}>
      <ContentHeader noBack title={isSystemView ? t('scope-notifications') : t('custom-send-emails')} subtitle={t('emails-counted', { count: archivedEmails?.length })}>
        <InviteUserPopup
          active={invitationEmails}
          setActive={setInvitationEmails}
          sendEmail={async (to: string[], subject: string, message: string) => {
            try {
              await API.email.sendNotificationEmail({ emails: to }, subject, { emailTitle: subject, emailText: message, buttonLink: 'https://ibu-scope.com/', autosend: false });
              setInvitationEmails(false);
              notify({ title: t('successfully-sent'), type: 'success', text: t('invitation-emails-send-successfully') });
            } catch (error) {
              notify({ title: t('error'), type: 'error', text: error });
            }
          }}
        />
        {showEmail && (
          <ShowArchivedEmailPopup
            open={!!showEmail}
            setOpen={(open) => setShowEmail(undefined)}
            archivedEmail={showEmail}
            onDelete={async () => {
              try {
                showEmail && deleteEmailMutation.mutateAsync(showEmail?.id);
                setShowEmail(undefined);
                notify({ title: t('deleted-successful'), type: 'success' });
              } catch (error) {
                notify({ title: t('error'), type: 'error', text: error });
              }
            }}
          />
        )}
        <MainDialog
          open={openDeletePopup}
          onCloseClick={() => setOpenDeletePopup(false)}
          onSaveClick={async () => {
            const promises = selection.map(async (id) => await deleteEmailMutation.mutateAsync(id));
            await Promise.all(promises);
            setSelection([]);
            setOpenDeletePopup(false);
          }}
          saveButtonColor="error"
          secondButtonColor="primary"
          modalTitle={t('do-you-really-want-to-delete-count-archived-mails', { count: selection.length })}
          description={t('this-cannot-be-undone') /* TODO: Implement i18n key */}
          buttonText={t('delete')}
        />
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1}></Box>
          {!isSystemView && (
            <Button variant="contained" sx={{ borderRadius: 999 }} color="primary" endIcon={<FontAwesomeIcon icon={faMailBulk} />} onClick={() => setInvitationEmails(true)}>
              {t('send-custom-mail')}
            </Button>
          )}
          <ConditionalRender render={selection.length > 0}>
            <Button
              sx={{ borderRadius: 999 }}
              onClick={() => {
                setOpenDeletePopup(true);
              }}
              variant="contained"
              color="error"
              endIcon={<FontAwesomeIcon icon={faTrash} />}
            >
              {t('delete-selected')}
            </Button>
          </ConditionalRender>
        </Stack>
      </ContentHeader>
      {emailsLoading ? (
        <LoadingPopup />
      ) : (
        <ConditionalRenderWithRights>
          <XGrid
            className={classes.root}
            headerHeight={40}
            disableColumnSelector
            disableSelectionOnClick
            sortModel={[{ field: 'timestamp', sort: 'desc' }]}
            components={{
              NoRowsOverlay: () => {
                return (
                  <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                      <img src={EmptyState} style={{ width: 175 }} />
                      <Typography variant="h2" style={{ marginTop: 20 }}>
                        {t('no-emails-here')}
                      </Typography>
                      <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
                        {t('no-emails-send-by-scope')}
                      </Typography>
                    </Box>
                  </div>
                );
              },
              NoResultsOverlay: GridNoResultsOverlay,
            }}
            disableColumnMenu
            checkboxSelection
            onSelectionModelChange={({ selectionModel }) => {
              setSelection(selectionModel);
            }}
            columns={columns(classes.lastCell).map((c) => ({
              ...c,
              renderHeader: (params) => {
                return (
                  <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                    {params.colDef?.headerName}
                  </Typography>
                );
              },
            }))}
            onRowClick={(row) => setShowEmail(row?.row as archivedEmail)}
            rows={archivedEmails ?? []}
          />
        </ConditionalRenderWithRights>
      )}
    </Box>
  );
};
