import { addDays } from 'date-fns';
import { ApplicationForm } from '@base/core';

export function getDeadlineDateFromForm(deadline: ApplicationForm['deadline'], parentForm?: ApplicationForm) {
  if (!deadline) return 'No deadline';
  if (typeof deadline === 'number') {
    return new Date(deadline);
  }
  if (deadline.type === 'absolute') {
    return new Date(deadline.value);
  }
  if (deadline.type === 'relative') {
    if (!parentForm) return new Date();
    return new Date(addDays(getDeadlineDateFromForm(parentForm.deadline), deadline.value));
  }
}
