import { faMobile } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, TextField, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Actions } from '@base/core';
import TwoFactorIcon from '../../../assets/universal/fingerprint.svg';
import { StandardPopup } from '../../../components/common';
import { useHistory } from 'react-router';

export function TwoFAVerifyFactorCodePopup() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { twoFA, fetching } = useSelector((state: Core.StateType) => state.auth);
  const history = useHistory();
  const validationSchema = yup.object().shape({
    code: yup.string().required('No code provided.').min(5, 'Code is too short - should be 6 digits'),
  });

  function submitCode(code: string) {
    dispatch(Actions().auth.verifiy2FAFactorEnrollment(code));
  }

  return (
    <StandardPopup visible={twoFA.status?.getVerificationCode} onBackdropClick={() => null} width={425} hideClose>
      <Formik
        initialValues={{ code: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await submitCode(values.code);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ isSubmitting, errors, touched, submitForm }) => (
          <Form>
            <Card>
              <CardContent>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <img src={TwoFactorIcon} style={{ width: 72, height: 72, alignSelf: 'center' }} />
                </Box>
                <Typography variant="h2" sx={{ marginTop: 3, marginBottom: 3, textAlign: 'center' }}>
                  {t('two-factor-authentication')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon style={{ width: 44, height: 44, marginRight: 8 }} icon={faMobile} />
                    <Typography fontWeight="500" variant="subtitle1">
                      {t('two-factor-auth-decription', { hint: twoFA.phoneNumber })}
                    </Typography>
                  </Box>
                  <Field
                    style={{ marginTop: 24 }}
                    as={TextField}
                    label={t('enter-code')}
                    required
                    autoFocus
                    variant="outlined"
                    autoComplete="off"
                    name="code"
                    error={(touched.code && Boolean(errors.code)) || fetching.verify2FAFactorEnrollment.error}
                    helperText={(touched.code && errors.code) || fetching.verify2FAFactorEnrollment.error}
                  />
                  {fetching.verify2FAFactorEnrollment.error && (
                    <LoadingButton style={{ marginTop: 16 }} variant="contained" color="error" onClick={() => history.push('/login')}>
                      {t('retry-login')}
                    </LoadingButton>
                  )}
                  <LoadingButton style={{ marginTop: 16 }} variant="contained" color="success" loading={fetching?.verify2FAFactorEnrollment?.fetching} onClick={submitForm} type="submit">
                    {t('verify')}
                  </LoadingButton>
                  <Typography style={{ marginTop: 10, textAlign: 'center' }} variant="subtitle2">
                    {t('after-code-is-verified-redirected-to-login-page')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </StandardPopup>
  );
}
