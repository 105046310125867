import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import type {ReactChildrenProp} from '../../../../lib';
import {faFolders} from '@fortawesome/pro-regular-svg-icons';
import {Chip, Stack, Typography, useTheme} from '@material-ui/core';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

interface NavbarSmallProps {
  children?: ReactChildrenProp;
  title: string;
  subTitle?: string;
  icon?: IconDefinition;
  onParentClick?: () => void;
}

export const FloatingNavbar = ({children, title, subTitle, icon, onParentClick}: NavbarSmallProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row" sx={{
      justifyContent: "space-between",
      boxShadow: theme.shadows[2],
      backgroundColor: "white",
      alignItems: "center",
      position: "fixed",
      maxWidth: "100%",
      zIndex: 100,
      right: 0,
      left: 0,
      top: 0,
      py: 1,
      px: 2
    }}>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
        <FontAwesomeIcon icon={icon} style={{width: 40, height: 40}}/>
        <Stack alignItems="flex-start" maxWidth="100%" spacing={0.5}>
          <Typography sx={{overflow: "hidden", height: 24}}>
            {title}
          </Typography>
          <Chip onClick={() => onParentClick?.()} clickable sx={{pl: 1}}
                icon={<FontAwesomeIcon icon={faFolders} fixedWidth/>} label={subTitle}/>
        </Stack>
      </Stack>
      <Stack flexShrink={0} direction="row">{children}</Stack>
    </Stack>
  );
};
