import { Typography, Stack, Box } from '@material-ui/core';
import React from 'react';
import { MinimalReaderItem } from './components/MinimalReaderItem';
import { format } from 'date-fns';

export function ChangeMessage({ chat }: { chat: any }) {
  return (
    <Stack sx={{ borderLeft: '2px solid', borderColor: 'success', px: 1 }}>
      <Typography variant="subtitle2" fontWeight={600}>
        Field has been changed:
      </Typography>
      <Typography variant="subtitle2">From:</Typography>
      <Box>
        <MinimalReaderItem schemaValue={chat.schema} value={chat.from} />
      </Box>
      <Typography variant="subtitle2">To:</Typography>
      <Box>
        <MinimalReaderItem schemaValue={chat.schema} value={chat.to} />
      </Box>
      <Typography
        variant="subtitle2"
        style={{
          fontSize: '12px',
        }}
        color="grey.600"
      >
        {format(chat.dateSent, 'hh:mm')}
      </Typography>
    </Stack>
  );
}
