import * as ACTION_TYPES from './types';
import type * as ACTIONS from './actions';
import { FetchingType, createDefault, createFetching, createSuccess, createError, removeKey, addKeys, removeKeys, resettable } from '../common';
import { Query } from '../../entities/Query';

type ValueOf<T> = T[keyof T];
export type ActionsType = ValueOf<{ [k in keyof typeof ACTIONS]: ReturnType<typeof ACTIONS[k]> }>;

export type initialStateType = {
  fetching: {
    queryUsers: FetchingType;
    fetchNextPage: FetchingType;
    createUser: FetchingType & { result?: Core.User };
    deleteUser: FetchingType;
    fetchSingleUser: FetchingType;
    updateUser: FetchingType;
    updateUserGroups: FetchingType;
    setUserRoles: FetchingType;
    setUserRights: FetchingType;
  };
  query?: Query<Core.User>;
  usersList?: Core.User[];
  usersMap: { [uid: string]: Core.User };
  users: { [uid: string]: Core.User };
};

const initialState: initialStateType = {
  fetching: {
    queryUsers: createDefault(),
    fetchNextPage: createDefault(),
    createUser: createDefault(),
    deleteUser: createDefault(),
    fetchSingleUser: createDefault(),
    updateUser: createDefault(),
    updateUserGroups: createDefault(),
    setUserRights: createDefault(),
    setUserRoles: createDefault(),
  },
  users: {},
  usersMap: {},
};

export const usersReducer = (state: initialStateType = initialState, action: ActionsType): initialStateType => {
  const { usersList, query, ...rest } = state;
  let newUsersList: Core.User[];
  switch (action.type) {
    case ACTION_TYPES.UPDATE_USER_CREATE_DEFAULT_STATE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUser: createDefault(),
        },
      };
    case ACTION_TYPES.CREATE_USER_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createUser: createFetching(),
        },
      };
    case ACTION_TYPES.CREATE_USER_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createUser: { ...createSuccess(), result: action.user },
        },
      };
    case ACTION_TYPES.CREATE_USER_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          createUser: createError(action.error),
        },
      };
    case ACTION_TYPES.DELETE_USER_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          deleteUser: createFetching(),
        },
      };
    case ACTION_TYPES.DELETE_USER_SUCCESS:
      newUsersList = (state.usersList ?? []).filter((u) => u.id !== action.uid);
      return {
        ...state,
        fetching: {
          ...state.fetching,
          deleteUser: createSuccess(),
        },
        usersList: newUsersList,
        users: removeKey(state.users, action.uid),
        usersMap: newUsersList.reduce((map, u) => ({ ...map, [u.id]: u }), {}),
      };
    case ACTION_TYPES.DELETE_USER_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          deleteUser: createError(action.error),
        },
      };
    case ACTION_TYPES.FETCH_SINGLE_USER_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchSingleUser: createFetching(),
        },
      };
    case ACTION_TYPES.FETCH_SINGLE_USER_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchSingleUser: createSuccess(),
        },
        users: { ...state.users, [action.user.id]: action.user },
      };
    case ACTION_TYPES.FETCH_SINGLE_USER_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchSingleUser: createError(action.error),
        },
      };
    case ACTION_TYPES.UPDATE_USER_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUser: createFetching(),
        },
      };
    case ACTION_TYPES.UPDATE_USER_SUCCESS:
      newUsersList = (usersList ?? []).map((u) => {
        if (u.id === action.uid) return { ...u, ...action.userData };
        else return u;
      });
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUser: createSuccess(),
        },
        users: {
          ...state.users,
          [action.uid]: { ...state.users[action.uid], ...action.userData },
        },
        usersList: newUsersList,
        usersMap: newUsersList.reduce((map, u) => ({ ...map, [u.id]: u }), {}),
      };
    case ACTION_TYPES.UPDATE_USER_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUser: createError(action.error),
        },
      };
    case ACTION_TYPES.SET_USER_RIGHTS_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          setUserRights: createFetching(),
        },
      };
    case ACTION_TYPES.SET_USER_RIGHTS_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          setUserRights: createSuccess(),
        },
      };
    case ACTION_TYPES.SET_USER_RIGHTS_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          setUserRights: createError(action.error),
        },
      };
    case ACTION_TYPES.SET_USER_ROLES_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          setUserRoles: createFetching(),
        },
      };
    case ACTION_TYPES.SET_USER_ROLES_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          setUserRoles: createSuccess(),
        },
      };
    case ACTION_TYPES.SET_USER_ROLES_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          setUserRoles: createError(action.error),
        },
      };
    case ACTION_TYPES.UPDATE_USER_GROUPS_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUserGroups: createFetching(),
        },
      };
    case ACTION_TYPES.UPDATE_USER_GROUPS_SUCCESS:
      newUsersList = (usersList ?? []).map((u) => {
        if (u.id === action.uid) return { ...u, groups: removeKeys(addKeys(u.groups, action.groups.add, true), action.groups.remove) };
        else return u;
      });
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUserGroups: createSuccess(),
        },
        users: {
          ...state.users,
          [action.uid]: { ...state.users[action.uid], groups: removeKeys(addKeys(state.users[action.uid].groups, action.groups.add, true), action.groups.remove) },
        },
        usersList: newUsersList,
        usersMap: newUsersList.reduce((map, u) => ({ ...map, [u.id]: u }), {}),
      };
    case ACTION_TYPES.UPDATE_USER_GROUPS_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateUserGroups: createError(action.error),
        },
      };
    case ACTION_TYPES.QUERY_USERS_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          queryUsers: createFetching(),
        },
      };
    case ACTION_TYPES.QUERY_USERS_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          queryUsers: createSuccess(),
        },
        usersList: action.users,
        usersMap: action.users.reduce((map, u) => ({ ...map, [u.id]: u }), {}),
        query: action.query,
      };
    case ACTION_TYPES.QUERY_USERS_FAILURE:
      return {
        ...rest,
        fetching: {
          ...state.fetching,
          queryUsers: createError(action.error),
        },
      };
    case ACTION_TYPES.FETCH_NEXT_PAGE_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchNextPage: createFetching(),
        },
      };
    case ACTION_TYPES.FETCH_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchNextPage: createSuccess(),
        },
        usersList: [...(state.usersList ?? []), ...action.users],
      };
    case ACTION_TYPES.FETCH_NEXT_PAGE_FAILURE:
      return {
        ...rest,
        fetching: {
          ...state.fetching,
          fetchNextPage: createError(action.error),
        },
      };
    default:
      return state;
  }
};
