import { SearchResult, searchService } from '@base/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Stack, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ContentHeader } from '../../components/common';
import { AccessLinkPopup } from '../../components/common/popups/AccessLinkPopup';
import { downloadFile } from '../../components/content';
import { ContentElementSearch } from '../../components/content/ContentElementSearch';
import { NoSearchResultContentView } from '../../components/content/EmptyContentView';
import { SearchInput } from '../../components/inputs';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/Views/content/FileManager.scss';
import { ConditionalRenderWithRights } from '../../helpers/ConditionalRenderWithRights';
import { useURLQuery } from '../../lib/hooks/useQuery';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { Loading } from '../auth';
import * as mime from 'mime-types';

export interface Folder extends Core.VirtualFile {
  description?: string;
}

let timer;

export function useSearch({
  mimetype,
  page = 0,
  pageSize = 10,
  query,
  parent,
  searchOnEmptyQuery = true,
}: {
  query?: string;
  mimetype?: string;
  page?: number;
  pageSize?: number;
  parent?: string;
  searchOnEmptyQuery?: boolean;
}) {
  const [results, setResults] = useState<SearchResult | null>(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const limit = (page + 1) * pageSize,
    offset = page * pageSize;
  useEffect(() => {
    if (!searchOnEmptyQuery && query.length === 0) {
      setResults(null);
      return;
    }
    if (!fetching) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(async function executeQuery() {
        setFetching(true);
        timer = null;
        setError(null);
        try {
          const res = await searchService.search({ query: query + '*', limit, mimetype, offset, parent });
          if (res) setResults(res);
          else setResults(null);
        } catch (error) {
          setError(error.message);
        } finally {
          setFetching(false);
        }
      }, 200);
    }
  }, [query, limit, mimetype, offset]);
  return {
    results,
    fetching,
    error,
  };
}

function tryParseIntElseDefault(val: string) {
  try {
    return parseInt(val);
  } catch {
    return 0;
  }
}

const PAGE_SIZE = 10;

export const Search = () => {
  const [linkPopupActive, setLinkPopupActive] = useState(false);

  const q = useURLQuery();

  const searchValue = q.get('q'),
    setSearchValue = (query: string) => {
      q.set('q', query);
      history.replace('/search?' + q.toString());
    };

  const page = tryParseIntElseDefault(q.get('page') ?? '0'),
    setPage = (val: number) => {
      q.set('page', val.toString());
      history.replace('/search?' + q.toString());
    };

  const { error, fetching, results } = useSearch({ query: q.get('q'), page, pageSize: PAGE_SIZE });
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user } = useSelector((state: Core.StateType) => state.auth);
  if (results)
    var {
      hits: { hits = [], total },
      took,
    } = results;

  const [relativeLink, setRelativeLink] = useState('');

  if (!user) return null;

  const openFolder = (id: string) => history.push(`/files/${id}`);
  const pageNums = Math.ceil((total?.value ?? 0) / PAGE_SIZE);

  const changePageNum = (changeAmmount: number) => {
    const newPage = page + changeAmmount;

    if (newPage < 0 || newPage >= pageNums) return;
    setPage(newPage);
  };

  console.log(results);

  const nextPAgeVal = (page + 1) * PAGE_SIZE;
  const showingPagesVal = total?.value ? `${page * PAGE_SIZE + 1} - ${nextPAgeVal > total?.value ? total.value : nextPAgeVal} out of ` : '';

  return (
    <ContentContainer maxHeight="100vh">
      <AccessLinkPopup setActive={setLinkPopupActive} active={linkPopupActive} relativeLinkUrl={'/files/' + relativeLink} />
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.search]} />
      <ContentView noPadding>
        <ContentHeader title="Smart Search" subtitle={showingPagesVal + (total?.value ?? '0')} href="/">
          <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
            <Box flex={1}>
              <SearchInput
                size="small"
                InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
                placeholder={t('search')}
                fullWidth
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Box>
            <ConditionalRenderWithRights campaign_admin>
              <Button sx={{ borderRadius: 999 }} onClick={() => changePageNum(-1)} variant="contained" color="primary" startIcon={<FontAwesomeIcon style={{ fontSize: 16 }} icon={faChevronLeft} />}>
                Previous
              </Button>
              <Typography sx={{ marginX: 2 }}>
                Page {page + 1} / {pageNums}
              </Typography>
              <Button sx={{ borderRadius: 999 }} onClick={() => changePageNum(1)} variant="contained" color="primary" endIcon={<FontAwesomeIcon style={{ fontSize: 16 }} icon={faChevronRight} />}>
                Next
              </Button>
            </ConditionalRenderWithRights>
          </Stack>
        </ContentHeader>
        {fetching ? (
          <Loading small />
        ) : (
          <Box sx={{ padding: 4 }}>
            <div className="file-grid" style={{ gridTemplateColumns: '1fr', paddingBottom: 50, marginTop: 0 }}>
              {hits?.length > 0 ? (
                hits.map((hit, i) => (
                  <ContentElementSearch
                    key={hit._id}
                    id={hit._id}
                    name={hit.highlight?.['name']?.[0] || (hit.fields.name?.[0] ?? '')}
                    selected={i == selectedIndex}
                    //@ts-ignore
                    type={(hit.fields?.['documentData.content_type']?.[0] || hit.fields.type?.[0]) ?? 'unknown'}
                    highlights={hit.highlight?.['documentData.content'] ?? []}
                    onClick={() => setSelectedIndex(i)}
                    onDoubleClick={async () => {
                      if (hit.fields.downloadUrl) {
                        if (hit.fields.type[0] === 'application/pdf') {
                          history.push(`/files/${hit._id}/pdf-reader`);
                        } else if (hit.fields.type[0] === 'si/document') {
                          history.push(`/files/${hit._id}?read`);
                        } else {
                          const file = { ...hit.fields, type: hit.fields.type[0], name: hit.fields.name[0] };
                          //@ts-ignore
                          await downloadFile(file);
                        }
                      } else {
                        openFolder(hit._id);
                      }
                    }}
                  />
                ))
              ) : (
                <NoSearchResultContentView />
              )}
              {error && <h1>{error}</h1>}
            </div>
          </Box>
        )}
      </ContentView>
    </ContentContainer>
  );
};
