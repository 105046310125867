import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { SHORT_ANSWER } from './types';

export const QuestionRenderer: Renderer<string, string> = {
  type: SHORT_ANSWER,
  viewer,
  viewerMin,
  editor,
  contentType: 'string',
  defaultEditorValue: 'text',
  defaultReaderValue: '',
  getValidationSchema,
  getTextRepresentation,
};

function viewer({ onChange, schema, value, required, touched, error }: RendererViewerProps<string, string>): JSX.Element {
  return (
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        fullWidth
        label="Short Answer"
        type={schema}
        required={required}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        error={touched && Boolean(error)}
        helperText={touched && error}
      />
    </Grid>
  );
}

function viewerMin({ onChange, value = '', touched, required, error }: RendererViewerProps<undefined, string>): JSX.Element {
  return <div>{value}</div>;
}

async function getTextRepresentation({ onChange, value = '', touched, required, error }: RendererViewerProps<undefined, string>) {
  return `<div>${value}</div>`;
}

function editor({ onChange, value }: RendererEditorProps<string, string>): JSX.Element {
  return (
    <>
      <Grid item xs={12} md={8}>
        <TextField variant="outlined" fullWidth label="Short Answer" disabled />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <InputLabel id="question-type-label">Type</InputLabel>
          <Select
            labelId="question-type-label"
            label="Type"
            fullWidth
            variant="outlined"
            defaultValue={SHORT_ANSWER}
            SelectDisplayProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            value={value}
            onChange={(e) => {
              if (e.target.value) {
                onChange(e.target.value);
              }
            }}
          >
            <MenuItem value={'text'}>Text</MenuItem>
            <MenuItem value={'email'}>E-Mail</MenuItem>
            {/* <MenuItem value={"number"}>
              Phone-Number
            </MenuItem> */}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

function getValidationSchema(schema, t) {
  let validator = yup.string();
  if (schema.content == 'email') {
    validator = validator.email(t('enter-a-valid-email'));
    if (schema.required) {
      validator = validator.required(t('something-is-required', { something: t('email') }));
    }
  } else if (schema.required) {
    validator = validator.required(t('something-is-required', { something: t('question') }));
  }
  return validator;
}
