import { faArrowToBottom, faPlusCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Checkbox, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField, useTheme } from '@material-ui/core';
import cuid from 'cuid';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import * as yup from 'yup';
import { FileDownloadReaderMinimal } from '../components/FileDownload';
import { FileUpload } from '../components/FileUpload';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { useProvider } from '../provider/ProviderContext';
import { SHORT_ANSWER } from './types';

export interface FileUploadSpec {
  maxFiles: number;
  allowedTypes: string[];
  maxSize: number;
}

const mimtetypes = [
  { type: '*', title: 'All Types' },
  { type: 'application/pdf', title: 'PDF' },
  { type: 'image/*', title: 'Images' },
  { type: '.doc,.docx', title: 'Word' },
  { type: '.xlsx,.xls,.csv', title: 'Excel' },
  { type: '.ppt,.pptx', title: 'Powerpoint' },
  { type: '.zip', title: 'Zip Files' },
];

function checkTargets(lastTargets: string[], targets: string[]) {
  if (targets.length > 1 && targets.includes('*')) {
    if (!lastTargets.includes('*')) return ['*'];
    return targets.filter((t) => t !== '*');
  }
  if (targets.length === 0) {
    return ['*'];
  }
  return targets;
}

export const RequestFilesRenderer: Renderer<FileUploadSpec, FilePropsEditor[]> = {
  type: SHORT_ANSWER,
  contentType: 'files-requester',
  defaultEditorValue: { maxFiles: 1, allowedTypes: ['*'], maxSize: 4 },
  defaultReaderValue: [],
  editor,
  viewer,
  viewerMin,
  getValidationSchema,
  getTextRepresentation,
};

export interface FilePropsEditor {
  size: number;
  uploadedBytes: number;
  finished?: boolean;
  name: string;
  mimeType: string;
  id: string;
  cancel(): void;
  getDownloadUrl(): Promise<string>;
}

function editor({ onChange, value }: RendererEditorProps<FileUploadSpec, FilePropsEditor[]>): JSX.Element {
  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          label="Max Files"
          value={value.maxFiles}
          onChange={(e) => onChange({ ...value, maxFiles: parseInt(e.target.value) })}
          type="number"
          inputProps={{ min: '1', max: '10' }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          label="Max Size"
          InputProps={{
            endAdornment: <InputAdornment position="start">MB</InputAdornment>,
          }}
          value={value.maxSize}
          onChange={(e) => onChange({ ...value, maxSize: parseInt(e.target.value) })}
          type="number"
          inputProps={{ min: '1', max: '20' }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-mutiple-checkbox-label" style={{ left: 13, top: -7 }}>
            Allowed file types
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            label="Allowed file types"
            multiple
            value={value.allowedTypes}
            onChange={(e) => onChange({ ...value, allowedTypes: checkTargets(value.allowedTypes, e.target.value as string[]) })}
            variant="outlined"
            // input={<Input />}
            MenuProps={{
              // getContentAnchorEl: null,
              anchorEl: null,
              anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
              // transformOrigin: {horizontal: "left", vertical: "bottom"}
            }}
            // MenuProps={MenuProps}
            renderValue={(selected) => (
              <div>
                {(selected as string[]).map((value) => (
                  <Chip key={value} label={mimtetypes.find((m) => m.type == value)?.title} />
                ))}
              </div>
            )}
          >
            {mimtetypes.map(({ title, type }) => (
              <MenuItem key={type} value={type}>
                <Checkbox checked={value.allowedTypes.indexOf(type) > -1} />
                <ListItemText primary={title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

function viewerMin({ onChange, value, schema }: RendererViewerProps<FileUploadSpec, FilePropsEditor[]>): JSX.Element {
  return (
    <Grid item container xs={12} spacing={2}>
      {value?.map((file) => (
        <Grid key={file.id} item xs={12}>
          <FileDownloadReaderMinimal {...file} />
        </Grid>
      ))}
    </Grid>
  );
}
async function getTextRepresentation({ onChange, value = [], schema }: RendererViewerProps<FileUploadSpec, FilePropsEditor[]>) {
  return value.map((t) => t.name).join(', ');
}

function viewer({ onChange, value, schema, error, touched, setTouched }: RendererViewerProps<FileUploadSpec, FilePropsEditor[]>): JSX.Element {
  const theme = useTheme();
  const { uploadFileProvider } = useProvider();

  const uploadFile = async (file: File) => {
    try {
      const id = await uploadFileProvider.createFileId();
      const task = uploadFileProvider.uploadFile(id, file);
      onChange((val) => [
        ...(val ?? []),
        ({
          id,
          name: file.name,
          mimeType: file.type,
          size: file.size,
          uploadedBytes: 0,
          finished: false,
          cancel: task.cancel,
        } as any) as FilePropsEditor,
      ]);

      task.on({
        change: (uploadedBytes) => {
          console.log('change', uploadedBytes);
          onChange((val) => val.map((v) => (v.id == id ? { ...v, uploadedBytes } : v)));
        },
        success: () => {
          console.log('Success');
          onChange((val) =>
            val.map((v) => {
              if (v.id == id) {
                const { cancel, ...nv } = v;
                return { ...(nv as any), finished: true };
              }
              return v;
            })
          );
        },
        error: (error) => {
          console.log('Error', error);
          onChange((val) => val.filter((v) => v.id != id));
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const noMoreFiles = (value.length ?? 0) >= (schema.maxFiles || 1);

  const onDrop = (acceptedFiles: File[]) => {
    setTouched(true);
    if (noMoreFiles) return;
    for (const file of acceptedFiles) {
      if (file.size > 1024 * 1024 * schema.maxSize) {
        const id = cuid();
        onChange((val) => [
          ...(val ?? []),
          ({
            id,
            name: file.name,
            mimeType: file.type,
            size: file.size,
            uploadedBytes: 0,
            finished: false,
            cancel: () => onChange((val) => val.filter((v) => v.id != id)),
          } as any) as FilePropsEditor,
        ]);
      } else uploadFile(file);
    }
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });
  const { onClick, ...rootProps } = getRootProps();
  console.warn(error);
  return (
    // <div {...getRootProps()}>
    <Grid item container xs={12} {...rootProps} spacing={2}>
      {value.map((file, i) => (
        <Grid key={file.id} item xs={12}>
          <FormControl error={touched && Boolean(error?.[i]) && typeof error != 'string'} fullWidth>
            <FileUpload
              {...file}
              onCancel={file.cancel}
              onDelete={async () => {
                await uploadFileProvider.deleteFile(file.id);
                onChange((v) => v.filter((x) => x.id != file.id));
              }}
            />
            {touched && error?.[i] && typeof error != 'string' && <FormHelperText>{Object.values(error?.[i])[0]}</FormHelperText>}
          </FormControl>
        </Grid>
      ))}

      <Grid item xs={12}>
        <FormControl error={touched && Boolean(error)}>
          <input {...getInputProps()} accept={schema.allowedTypes.join(',')} />
          {isDragActive || (
            <Button disabled={noMoreFiles} onClick={onClick} startIcon={<FontAwesomeIcon icon={faPlusCircle} size="sm" />} variant="contained" fullWidth color="secondary">
              Upload
            </Button>
          )}
          {isDragActive && (
            <Box padding={5} bgcolor={alpha(theme.palette.text.primary, 0.1)} borderRadius={theme.shape.borderRadius} alignItems="center" justifyContent="center" display="flex">
              <FontAwesomeIcon icon={noMoreFiles ? faTimes : faArrowToBottom} size="3x" />
            </Box>
          )}
          {touched && typeof error == 'string' && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Grid>
    // </div>
  );
}

function getValidationSchema(schema, t) {
  let validator = yup
    .array()
    .of(
      yup.object({
        size: yup.number().max(1024 * 1024 * (schema.content.maxSize || 4), t('cannot-exceed-max-size-of', { size: `${schema.content.maxSize} MB` })),
        finished: yup.boolean().isTrue(t('wait-for-file-upload')),
        name: yup.string().typeError(t('something-is-required', { something: t('name') })),
      })
    )
    .max(schema.content.maxFiles, t('only-max-of-files-are-allowed', { entity: t('file-counted', { count: schema.content.maxFiles }) }));
  if (schema.required) {
    validator = validator.min(1, t('something-is-required', { something: t('question') })).required(t('something-is-required', { something: t('question') }));
  } else {
    validator = validator.nullable();
  }
  return validator.typeError(t('something-is-required', { something: t('question') }));
}
