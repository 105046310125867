import * as React from 'react';

function SvgPenRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ref={svgRef} {...props}>
      <path d="M21.195 2.417L19.583.806a2.75 2.75 0 00-3.889 0l-3.2 3.2L11.001 5.5.552 15.947l-.546 4.907A1.032 1.032 0 001.029 22a1.048 1.048 0 00.116-.006l4.9-.542L16.501 11l1.493-1.493 3.2-3.2a2.75 2.75 0 000-3.889zM5.101 19.482l-2.9.318.324-2.909 9.936-9.936 1.33-1.33 2.584 2.584-1.332 1.333-9.942 9.94zM19.736 4.848l-1.9 1.9-2.586-2.582 1.9-1.9a.687.687 0 01.972 0l1.612 1.612a.688.688 0 010 .972z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPenRegular);
export default ForwardRef;
