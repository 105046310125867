import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Paper, PaperProps, Stack, Switch, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainDialog } from '../../../components/common/popups/MainDialog';

export function LabeledSwitchBoxRounded({
  label,
  sx,
  onChange,
  checked,
  infoText,
  ...props
}: { label: string; checked?: boolean; infoText?: string; onChange?: (checked: boolean) => any } & Omit<PaperProps, 'onChange'>) {
  const [infoVisible, setInfoVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <Paper
      variant="outlined"
      sx={{
        mb: 1,
        ...(sx ?? {}),
      }}
      {...props}
    >
      <Grid container spacing={2} paddingX={2} paddingY={1}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>
              {label}
              {infoText && <FontAwesomeIcon onClick={() => setInfoVisible(true)} style={{ cursor: 'pointer', marginLeft: 8 }} icon={faInfoCircle} />}
            </Typography>
            <Switch onChange={onChange && ((e) => onChange(e.target.checked))} />
          </Stack>
        </Grid>
      </Grid>
      <MainDialog
        hideCancel
        width={500}
        buttonText="Close"
        open={infoVisible}
        modalTitle={t('information')}
        onCloseClick={() => setInfoVisible(false)}
        onSaveClick={() => setInfoVisible(false)}
        description={infoText}
      />
    </Paper>
  );
}
