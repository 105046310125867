import * as React from 'react';

function SvgH4(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.543 20" ref={svgRef} {...props}>
      <path d="M.629 20a.592.592 0 01-.457-.186.668.668 0 01-.171-.471V.686a.708.708 0 01.171-.5A.592.592 0 01.629 0H2a.676.676 0 01.5.186.676.676 0 01.186.5v7.943h9.943V.686a.676.676 0 01.186-.5.639.639 0 01.471-.186h1.374a.676.676 0 01.5.186.676.676 0 01.186.5v18.657a.615.615 0 01-.2.471.688.688 0 01-.486.186h-1.374a.639.639 0 01-.471-.186.639.639 0 01-.186-.471v-8.229H2.686v8.229a.615.615 0 01-.2.471A.688.688 0 012 20zm28.685 0a.639.639 0 01-.471-.186.639.639 0 01-.186-.471v-4.172h-9.286a.639.639 0 01-.471-.186.639.639 0 01-.186-.471v-1.057a1.536 1.536 0 01.314-.857l8.6-12.114a.944.944 0 01.429-.357A1.514 1.514 0 0128.686 0h1.943a.657.657 0 01.486.171.657.657 0 01.171.486v12.114h2.6a.639.639 0 01.474.186.639.639 0 01.186.471v1.086a.639.639 0 01-.186.472.639.639 0 01-.471.186h-2.6v4.171a.639.639 0 01-.186.471.639.639 0 01-.474.186zm-7.628-7.171h7V2.886z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgH4);
export default ForwardRef;
