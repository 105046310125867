import { Box, Stack, FormControl, FormHelperText, Grid, TextField, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { DateRangePicker } from '@material-ui/lab';
import DatePicker from '@material-ui/lab/DatePicker';
import { format } from 'date-fns';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormContent } from '../entities/FormContent';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { DATE_RANGE } from './types';

export const DateRangeRenderer: Renderer<boolean, [number | null, number | null]> = {
  type: DATE_RANGE,
  contentType: 'date-range',
  defaultEditorValue: '' as any,
  defaultReaderValue: [null, null],
  viewer,
  viewerMin,
  editor,
  getValidationSchema,
  getTextRepresentation,
};

function viewer({ onChange, value, required, schema, error, touched }: RendererViewerProps<undefined, [number | null, number | null]>) {
  const { t } = useTranslation();

  //@ts-ignore
  if (value?.[0]?.toDate) value[0] = value[0].toDate();
  //@ts-ignore
  if (value?.[1]?.toDate) value[1] = value[1].toDate();

  return (
    <Grid item xs={12} md={8} display="flex" alignItems="center">
      <FormControl error={touched && Boolean(error)}>
        <Grid container>
          <Grid item xs={5}>
            <DatePicker
              allowSameDateSelection
              mask={t('full-date-mask')}
              label="Start"
              renderInput={(params) => <TextField margin="normal" {...params} variant="outlined" />}
              onChange={(d) => onChange([d.valueOf(), value?.[1] ?? null])}
              value={value?.[0] ?? null}
            />
            {touched && error && Array.isArray(error) && <FormHelperText>{error[0]}</FormHelperText>}
          </Grid>
          <Grid item xs display="flex" justifyContent="center">
            <Typography sx={{ alignSelf: 'center', justifySelf: 'center' }}>to</Typography>
          </Grid>
          <Grid item xs={5}>
            <DatePicker
              allowSameDateSelection
              mask={t('full-date-mask')}
              label="End"
              renderInput={(params) => <TextField margin="normal" {...params} variant="outlined" />}
              onChange={(d) => onChange([value?.[0] ?? null, d.valueOf()])}
              value={value?.[1] ?? null}
            />
            {touched && error && Array.isArray(error) && <FormHelperText>{error[1]}</FormHelperText>}
          </Grid>
        </Grid>
        {touched && error && !Array.isArray(error) && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

function viewerMin({ onChange, value = [] as any, required, schema, error, touched }: RendererViewerProps<undefined, [number | null, number | null]>) {
  const { t } = useTranslation();
  //@ts-ignore
  if (value[0]?.toDate) value[0] = value[0].toDate();
  //@ts-ignore
  if (value[1]?.toDate) value[1] = value[1].toDate();
  return (
    <div>
      {value[0] ? format(new Date(value[0]), t('full-date-mask')) : null} - {value[1] ? format(new Date(value[1]), t('full-date-mask')) : null}
    </div>
  );
}

async function getTextRepresentation({ onChange, value = [] as any, required, schema, error, touched }: RendererViewerProps<undefined, [number | null, number | null]>) {
  //@ts-ignore
  if (value[0]?.toDate) value[0] = value[0].toDate();
  //@ts-ignore
  if (value[1]?.toDate) value[1] = value[1].toDate();
  return `${value[0] ? format(new Date(value[0]), 'MM/dd/yyyy') : ''} - ${value[1] ? format(new Date(value[1]), 'MM/dd/yyyy') : ''}`;
}

function editor({ onChange, value, setAsProjectDurationField, projectDurationField }: RendererEditorProps<boolean, [number | null, number | null]>) {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} md={8} display="flex" alignItems="center" container spacing={2}>
      <Grid xs={12} md={6} item>
        <DateRangePicker
          orientation="landscape"
          allowSameDateSelection
          mask={t('full-date-mask')}
          label="Choose Date"
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} variant="outlined" />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} variant="outlined" />
            </>
          )}
          disabled
          onChange={() => {}}
          value={[null, null]}
        />
      </Grid>
      {setAsProjectDurationField && (
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch />}
            label="Use field as Project duration"
            checked={projectDurationField}
            onChange={(e) => {
              setAsProjectDurationField((e.target as any).checked);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

function getValidationSchema(schema: FormContent<undefined>, t: TFunction<string>) {
  let validator = yup.array().of(yup.number().nullable()).min(2).max(2);
  if (schema.required) {
    validator = yup
      .array()
      .of(yup.number().typeError('Entry must be a date '))
      .min(2)
      .max(2)
      .required(t('something-is-required', { something: t('question') }));
  } else {
    validator = validator.nullable();
  }
  return validator.typeError(t('something-is-required', { something: t('question') }));
}
