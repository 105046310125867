import cuid from 'cuid';
import { Form } from '../entities/Form';

export function createEmptyForm(): Form {
  return {
    content: [],
    id: cuid(),
    //@ts-ignore
    published: false,
    name: '',
  };
}
