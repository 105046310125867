import { Box, Typography, useTheme } from '@material-ui/core';
import SearchIcon from '../../../assets/universal/search-icon.svg';
import React from 'react';

export function GridNoResultsOverlay() {
  const theme = useTheme();
  return (
    <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <img src={SearchIcon} style={{ width: 200 }} />
        <Typography variant="h2" style={{ marginTop: 20 }}>
          No results found!
        </Typography>
        <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
          We couldn't find anything related to your search or filter. <br />
          Please try a different search term or filter setting.
        </Typography>
      </Box>
    </div>
  );
}
