import { Box, Tab, useTheme } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Groups } from '../Groups';
import { GroupTags } from '../GroupTags';
import {
  useCreateGroupMutation,
  useCreateGroupTagMutation,
  useDeleteGroupFileGroupMutation,
  useDeleteGroupsMutation,
  useDeleteGroupTagsMutation,
  useUpdateGroupMutation,
  useUpdateGroupTagMutation,
} from '../hooks/mutations';
import { useGetGroups } from '../hooks/useGetGroups';
import { useGetGroupTags } from '../hooks/useGetGroupTags';
import { useGetUsers } from '../hooks/useGetUsers';

export function GroupsWrapper() {
  const { groupType } = useParams<{ groupType: string }>();
  const { data: groups = [] } = useGetGroups();
  const { data: groupTags = [] } = useGetGroupTags();
  const { data: users = [] } = useGetUsers();

  const createMutation = useCreateGroupMutation();
  const deleteMutation = useDeleteGroupsMutation();
  const updateMutation = useUpdateGroupMutation();
  const createTagMutation = useCreateGroupTagMutation();
  const deleteTagMutation = useDeleteGroupTagsMutation();
  const updateTagMutation = useUpdateGroupTagMutation();
  const deleteGrouptFileMutation = useDeleteGroupFileGroupMutation();
  const theme = useTheme();

  const finalGroups = groups.filter((g) => g.type == groupType);
  const finalGroupTags = groupTags.filter((g) => g.type == groupType);
  const [tabIndex, setTabIndex] = useState('groups');

  return (
    <TabContext value={tabIndex}>
      <Box paddingX={4} paddingBottom={2} paddingTop={1} bgcolor={theme.palette.background.grayDark}>
        <TabList style={{ height: 40, minHeight: 40 }} onChange={(_, v) => setTabIndex(v)} TabIndicatorProps={{ style: { background: theme.palette.primary.main } }} textColor={'#000000' as any}>
          <Tab label="Groups" value="groups" />
          <Tab label="Group Folder" value="group-folder" />
        </TabList>
      </Box>
      {tabIndex === 'groups' && (
        <Groups
          groups={finalGroups}
          onCreate={async (name, users) => {
            await createMutation.mutateAsync({ name, users, type: groupType ?? null });
          }}
          onDelete={async (id) => {
            await deleteMutation.mutateAsync(id);
          }}
          onUpdate={async (group, users) => {
            await updateMutation.mutateAsync({ ...group, users });
          }}
          onDeleteFiles={async (group, filesToDelete) => {
            await deleteGrouptFileMutation.mutateAsync({ ...group, filesToDelete });
          }}
          userList={users}
        />
      )}
      {tabIndex === 'group-folder' && (
        <GroupTags
          groups={finalGroupTags}
          onCreate={async (name, groups) => {
            await createTagMutation.mutateAsync({ name, groups, type: groupType ?? null });
          }}
          onDelete={async (id) => {
            await deleteTagMutation.mutateAsync(id);
          }}
          onUpdate={async (group, groups) => {
            await updateTagMutation.mutateAsync({ ...group, groups });
          }}
          groupList={finalGroups}
        />
      )}
    </TabContext>
  );
}
