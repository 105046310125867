import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, IconButton, IconButtonProps, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from '@material-ui/core';
import React from 'react';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useTheme } from '../../../theme/ThemeProvider';

export interface MainMenuItemProps {
  id: string;
  className?: string;
  title: string;
  color?: any;
  icon: IconDefinition;
  handleClose: () => void;
  disabled?: boolean;
  action: (id: string) => void;
}

export function MainMenutItem({ title, className, icon, action, handleClose, id, color, disabled }: MainMenuItemProps) {
  const theme = useTheme();

  const _onClick = useCallback(
    async (e: React.MouseEvent) => {
      await action(id);
      handleClose();
    },
    [action, handleClose, id],
  );

  const onClick = useMutation(_onClick);

  return (
    <MenuItem className={className} disabled={disabled} key={title} onClick={!onClick.isLoading ? onClick.mutate : undefined}>
      {onClick.isLoading && <CircularProgress style={{ width: 20, height: 20 }} />}
      {!onClick.isLoading && (
        <>
          <ListItemIcon>
            <FontAwesomeIcon color={color ?? 'black'} fixedWidth style={{ width: 16, height: 16, marginRight: -8 }} icon={icon} />
          </ListItemIcon>
          <ListItemText primary={title} primaryTypographyProps={{ variant: 'body1', fontWeight: '500' }} />
        </>
      )}
    </MenuItem>
  );
}

interface MainMenuProps extends IconButtonProps {
  id: string;
  actions: { title: string; icon: IconDefinition; action: (id: string) => void }[];
  IconButtonColor?: any;
  iconColor?: string;
  menuProps?: Omit<MenuProps, 'open'>;
  sx?: any;
}

export function MainMenu({ id, actions, iconColor, IconButtonColor, menuProps, sx }: MainMenuProps) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(
    (e?: any) => {
      e?.preventDefault();
      e?.stopPropagation();
      setAnchorEl(null);
    },
    [setAnchorEl],
  );

  return (
    <>
      <IconButton
        sx={{ color: 'info.main', backgroundColor: 'info.lighter', ':hover': { bgcolor: 'info.main', color: 'white' }, width: 40, height: 40, zIndex: 5, position: 'absolute', ...sx }}
        onClick={handleClick}
      >
        <FontAwesomeIcon fixedWidth fontSize={20} width={20} height={20} size="sm" icon={faEllipsisV} />
      </IconButton>
      <Menu
        sx={{ marginTop: 1 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...menuProps}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {actions?.map((action, index) => (
          <MainMenutItem key={index} id={id} handleClose={handleClose} {...action} color={theme.colors['main-color']} />
        ))}
      </Menu>
    </>
  );
}
