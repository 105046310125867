import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, LinearProgress, Table, TableCell, TableHead } from '@material-ui/core';
import React from 'react';
import { useAutoUpdateState } from "../../../views/fileManager/useAutoUpdateState";
import { getUserDataFromHeaders } from './helpers';
import { UserUploadMutaion } from './UserUploadMutaion';

export function MultiUserAdd({ headers, onClose, rows }: { headers: string[]; rows: string[][]; onClose: () => void }) {
  const [usersToAdd, setUsersToAdd] = useAutoUpdateState(() => getUserDataFromHeaders(headers, rows), [rows, headers]);
  const [finishedUsers, setFinishedUsers] = useAutoUpdateState<{ finished: boolean }[]>(
    usersToAdd.map(() => ({ finished: false })),
    [rows, headers],
  );
  const finishedAbs = finishedUsers.reduce((sum, u) => (sum += u.finished ? 1 : 0), 0);
  const progress = (finishedAbs / usersToAdd.length) * 100;

  return (
    <div style={{ maxHeight: '70vh', display: 'flex', flexDirection: 'column' }}>
      <LinearProgress value={progress} variant="determinate" style={{ marginBottom: 20 }} />
      <Table>
        <TableHead>
          <TableCell>Satus</TableCell>
          <TableCell>Username</TableCell>
          <TableCell>Error</TableCell>
        </TableHead>
        {usersToAdd.map((u, i) => (
          <UserUploadMutaion key={u.email} userData={u} onSuccess={() => setFinishedUsers((f) => f.map((user, j) => (i == j ? { finished: true } : user)))} />
        ))}
      </Table>

      <Button variant="contained" startIcon={<FontAwesomeIcon icon={faCheck} />} onClick={onClose} style={{ alignSelf: 'flex-end' }}>
        Beenden
      </Button>
    </div>
  );
}
