import React from 'react';
import { NothingHere } from '../../assets/images';
import '../../css/components/common/content/EmptyContent.scss';
import { useTranslation } from 'react-i18next';

export const EmptyContentView = ({ id = 'randomId' }) => {
  const { t } = useTranslation();
  return (
    <div className="empty-placeholder-container">
      <div className="id-overlay" id={id} />
      <div className="grey-bg">
        <NothingHere />
        <div className="heading">{t('messages.this-folder-is-empty')}</div>
      </div>
    </div>
  );
};

export const NoSearchResultContentView = ({ id = 'randomId' }) => {
  const { t } = useTranslation();
  return (
    <div className="empty-placeholder-container">
      <div className="id-overlay" id={id} />
      <div className="grey-bg">
        <NothingHere />
        <div className="heading">{t('keine-treffer-gefunden')}</div>
        <div className="text">{t('try-another-search-term')}</div>
      </div>
    </div>
  );
};
