import * as React from 'react';

function SvgLinkRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.912 20.912" ref={svgRef} {...props}>
      <path d="M12.834 8.078a5.726 5.726 0 01.4 7.644c-.255.316-.114.158-3.469 3.513a5.718 5.718 0 01-8.087-8.087l2.59-2.592a.49.49 0 01.837.335 7.079 7.079 0 00.195 1.546.487.487 0 01-.131.457l-1.876 1.872a3.431 3.431 0 004.852 4.852l3.071-3.072a3.432 3.432 0 00-.932-5.515.491.491 0 01-.268-.5 2.112 2.112 0 01.605-1.242l.179-.179a.487.487 0 01.562-.094 5.686 5.686 0 011.471 1.064zm6.4-6.4a5.715 5.715 0 00-8.087 0C7.794 5.032 7.935 4.874 7.68 5.189A5.719 5.719 0 009.55 13.9a.487.487 0 00.562-.094l.179-.179a2.112 2.112 0 00.605-1.242.491.491 0 00-.268-.5A3.432 3.432 0 019.7 6.364l3.071-3.072a3.431 3.431 0 014.852 4.852l-1.873 1.872a.487.487 0 00-.131.457 7.079 7.079 0 01.194 1.545.49.49 0 00.837.335l2.59-2.592a5.715 5.715 0 000-8.087z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLinkRegular);
export default ForwardRef;
