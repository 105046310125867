import { useSelector } from "react-redux";
import { UserHasRight } from "../helpers/ConditionalRenderWithRights";
import { Dashboard } from "./DashboardHelpers";

export function cx(...classes: (string | any)[]) {
  return classes.filter((s) => s).join(' ');
}

export function joinCssClasses(conditionalNames: { [className: string]: boolean }, ...otherClassnames: string[]) {
  const classNames = [
    ...otherClassnames,
    ...Object.entries(conditionalNames)
      .filter(([className, isClassNameActive]) => isClassNameActive)
      .map(([className, isClassNameActive]) => className),
  ];
  return classNames.join(' ');
}

export function cssClasses(conditionalNames: { [className: string]: boolean }, ...otherClassnames: string[]) {
  const classNames = [
    ...otherClassnames,
    ...Object.entries(conditionalNames)
      .filter(([className, isClassNameActive]) => isClassNameActive)
      .map(([className, isClassNameActive]) => className),
  ];
  return classNames.join(' ');
}


export function useExternalUserFilterForDashboardItems() {
  const user = useSelector((state: Core.StateType) => state.auth.user)
  const isExternal = Boolean(user.rights.is_group_only_user);

  const userIsSuperAdmin = user.roles?.superAdmin ?? false;

  return <T extends Dashboard.DashBoardItemBase>(items: T[]): T[] => {
    return items.filter(item => {
      if (isExternal) {
        if ((item.visibility == "both" || item.visibility == "external")) return true;
      }
      else {
        if (item.visibility != 'external') {
          if (item.rights) {
            return userIsSuperAdmin || UserHasRight(user, item.rights)
          }
          return true;
        }
      }
      return false;
    })
  }
}