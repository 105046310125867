import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type iconButtonProps = {
    icon: any;
    bgColor?: string;
    iconColor?: string;
    padding?: number;
    iconSize?: number;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
};

export const FAIconButton = ({ bgColor = '#F0F0F0', icon, iconColor = '#858585', onClick, iconSize = 26, className = 'icon-button', style }: iconButtonProps) => (
    <div onClick={onClick} className={className} style={{ backgroundColor: bgColor, ...style }}>
        <FontAwesomeIcon color={iconColor} icon={icon} style={{ fontSize: iconSize }} />
    </div>
);
