import './environments/environment';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, initialize } from '@base/web';
import { initializeDatabase, DataBase } from '@base/firebase';
import ThemeOverrides from './config/ThemeOverrides';
import DashboardConfig from './config/DashboardConfig';
import './lang/lang-overrides';
// import { createMockDB } from './mocks/Database';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';

initializeDatabase(
  {
    apiKey: 'AIzaSyC4zNLGI049PueFEwUOGZOfAkrTWOcaPVA',
    authDomain: 'ibu-knowledgebase.firebaseapp.com',
    databaseURL: 'https://ibu-knowledgebase.firebaseio.com',
    projectId: 'ibu-knowledgebase',
    storageBucket: 'ibu-knowledgebase.appspot.com',
    messagingSenderId: '645342901665',
    appId: '1:645342901665:web:9b9064e89d4fe9ea3607e0',
    measurementId: 'G-SH85T55SMB',
  },
  'europe-west3',
  firebase as any
);

// initialize(createMockDB({ user_create: true }));
initialize(DataBase);
console.log(DataBase);

ReactDOM.render(
  // <React.StrictMode>
  <Router
    themeOverrides={ThemeOverrides}
    dashboardConfig={DashboardConfig}
    muiOverrides={{ palette: { primary: { main: '#023850', dark: '#255a74', darker: '#16475e', light: '#009CD9', lighter: '#88d6e3' } } }}
  />,
  // </React.StrictMode>,
  document.getElementById('root')
);
