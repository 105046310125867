import { IUserService, Query, UserCreationData } from '@base/core';
import {
  getModuleQuestionsCollection,
  getModuleUserDataCollection,
  getModuleUserExamDataCollection,
  GROUP_COLLECTION,
  USER_COLLECTION,
} from '../config';

import {
  Exam,
  IExamService,
  IQuestionService,
  Question,
  QuestionUpdate,
  UserModuleDataFromDB,
} from '@learning/core';

import {firebase} from '../config'
import { createCallableApi } from './createCallableApi';

export class ExamService implements IExamService {
  async saveExam(uid: string, moduleId: string, exam: Exam): Promise<void> {
    await getModuleUserExamDataCollection(moduleId, uid).add(exam);
    
    createCallableApi(
      '/events/exam_event',
      'POST'
    )({
      uid,
      moduleId,
      percentageToPass: exam.percentageToPass,
      questionCount: exam.questions,
      questionsWrong: exam.questionsWrong,
      timeElapsed: exam.timeElapsed,
      timeTotal: exam.timeTotal
    });
  }

  async getAllExams(uid: string, moduleId: string): Promise<Exam[]> {
    let sn = await getModuleUserExamDataCollection(moduleId, uid).get();

    return sn.docs.map(d=> ({...d.data() as any, id: d.id}))
  }
}
