//@ts-nocheck

import { faFrown, faSmile, faSmileBeam } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardMedia, CardContent, Typography, CardActions, Button, Box, Divider, Container, Grid, ButtonGroup, ToggleButtonGroup, ToggleButton, Icon, SvgIcon } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import React, { useState } from 'react';

const colorsMinimal = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
const colorsFull = [
  'primary.lighter',
  'secondary.lighter',
  'info.lighter',
  'success.lighter',
  'warning.lighter',
  'error.lighter',
  'primary.light',
  'secondary.light',
  'info.light',
  'success.light',
  'warning.light',
  'error.light',
  'primary.main',
  'secondary.main',
  'info.main',
  'success.main',
  'warning.main',
  'error.main',
  'primary.dark',
  'secondary.dark',
  'info.dark',
  'success.dark',
  'warning.dark',
  'error.dark',
  'primary.darker',
  'secondary.darker',
  'info.darker',
  'success.darker',
  'warning.darker',
  'error.darker',
];

function Space() {
  return <div style={{ height: 30 }} />;
}
export function ThemingView() {
  const [toggleButtonState, setToggleButtonState] = useState("nice")
  return (
    <div style={{ padding: 100 }}>
      <Container maxWidth="lg">
        <Card>
          <CardMedia image="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg" title="Contemplative Reptile" />
          <CardContent>
            <Typography gutterBottom variant="h4" component="h4">
              Cards
            </Typography>
            <Typography component="p">Card with Card Actions</Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Default</Button>
            <Button size="small" color="primary">
              primary
            </Button>
            <Button size="small" color="secondary">
              secondary
            </Button>
            <Button size="small" disabled>
              disabled
            </Button>
          </CardActions>
        </Card>
        <Space />
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h4" component="h4">
              Buttons - Normal
            </Typography>
            <Button size="small">Default</Button>
            <Button size="small" color="primary">
              primary
            </Button>
            <Button size="small" color="secondary">
              secondary
            </Button>
            <Button size="small" disabled>
              disabled
            </Button>
            <Space />
            <Space />
            <Typography gutterBottom variant="h4" component="h4">
              Buttons - contained
            </Typography>

            <Grid container spacing={3}>
              {colorsMinimal.map((color) => (
                <Grid key={color} item>
                  <Button variant="contained" size="small" color={color}>
                    {color}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Space />
            <Space />
            <Typography gutterBottom variant="h4" component="h4">
              Buttons - Outlined
            </Typography>

            <Grid container spacing={3}>
              {colorsMinimal.map((color) => (
                <Grid key={color} item>
                  <Button variant="outlined" size="small" color={color}>
                    {color}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Space />
            <Space />
            <Typography gutterBottom variant="h4" component="h4">
              Buttons - Crazy
            </Typography>
            <Grid container spacing={3}>
              <Grid item>
                <Button variant="contained" size="small" color="secondary" startIcon={<FontAwesomeIcon icon={faSmileBeam} />}>
                  Icon Left
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" size="small" color="secondary" endIcon={<FontAwesomeIcon icon={faSmileBeam} />}>
                  Icon Right
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton variant="contained" size="small" color="secondary" endIcon={<FontAwesomeIcon icon={faSmileBeam} />}>
                  LoadingButton Right (loaded)
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton variant="contained" pendingPosition="end" pending size="small" color="secondary" endIcon={<FontAwesomeIcon icon={faSmileBeam} />}>
                  LoadingButton Right (loaded)
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton variant="contained" pendingPosition="start" pending size="small" color="secondary" startIcon={<FontAwesomeIcon icon={faSmileBeam} />}>
                  LoadingButton Left (loaded)
                </LoadingButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h4" component="h4">
              Button Groups
            </Typography>

            <Grid container spacing={3}>
              {colorsMinimal.map((color) => (
                <Grid key={color} item>
                  <ButtonGroup>
                    <Button variant="contained" size="small" color={color}>
                      1 {color}
                    </Button>
                    <Button variant="contained" size="small" color={color}>
                      2 {color}
                    </Button>
                    <Button variant="contained" size="small" color={color}>
                      3 {color}
                    </Button>
                  </ButtonGroup>
                </Grid>
              ))}
            </Grid>
            <Space />
            <Space />
            <Typography gutterBottom variant="h4" component="h4">
              Toggle Buttons
            </Typography>

            <ToggleButtonGroup exclusive value={toggleButtonState } onChange={(_, val)=> setToggleButtonState(val)}>
              <ToggleButton value="bad">
                <SvgIcon component={<FontAwesomeIcon icon={faSmile} size="7x" />} />
              </ToggleButton>
              <ToggleButton value="meh">
                <FontAwesomeIcon icon={faSmile} size="7x" />
              </ToggleButton>
              <ToggleButton value="nice">
                <FontAwesomeIcon icon={faSmileBeam} size="7x" />
              </ToggleButton>
            </ToggleButtonGroup>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
