import React from 'react';
import { BrowserRouter, Route, Switch, useParams } from 'react-router-dom';
import { PrivateRoute } from '../../../router/PrivateRoute';
import { useGetFile } from '../../campaign/hooks';
import { CampaignEditorWrapper } from './CampaignEditorWrapper';
import { CampaignFormEditorWrapper } from './CampaignFormEditorWrapper';
import { CampaignManagerWrapper } from './CampaignManagerWrapper';
// import { useGetFile } from '../hooks/useGetFile';
// import { CampaignEditorWrapper } from './CampaignEditorWrapper';
// import { CampaignFormEditorWrapper } from './CampaignFormEditorWrapper';
// import { CampaignManagerWrapper } from './CampaignManagerWrapper';
import { CampaignOverviewWrapper } from './CampaignOverviewExternalWrapper';

export function CampaignExternalRouter({ match }) {
  return (
    <Switch>
      <PrivateRoute path={`${match.url}/:parentId/forms/:formId`} component={CampaignFormEditorWrapper} exact />
      <PrivateRoute path={`${match.url}/:parentId`} component={MimeTypeSwitch} />
      <PrivateRoute path={`${match.url}/`} component={CampaignOverviewWrapper} />
    </Switch>
  );
}

function MimeTypeSwitch({ match }) {
  const { parentId } = useParams<{ parentId: string }>();
  const { data: parent } = useGetFile(parentId);
  switch (parent?.type) {
    case 'campaign':
      return <CampaignEditorWrapper match={match}/>;
    default:
      return <CampaignManagerWrapper match={match} />;
  }
}
