import { Button } from '@material-ui/core';
import { contentType } from 'mime-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function UploadFileComponent({ handleFile }: { handleFile: (e: any) => void; }) {
  const { t } = useTranslation();
  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Button variant="contained" onClick={() => fileRef.current.click()} color="primary">
        {t('upload-file')}
      </Button>
      <input ref={fileRef} type="file" style={{ display: 'none' }} onChange={handleFile} accept={contentType('.xlsx') || ''} />
    </>
  );
}
