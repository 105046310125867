import { ConditionalRender } from '@base/web';
import { FormControlLabel, Grid, Stack, Switch, Typography } from '@material-ui/core';
import { Field } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCheckboxElement } from '../../../components/content/GridContent/GridCheckboxElement';

function allEqual<T>(...params: T[]) {
  for (let i = 1; i < params.length; i++) {
    if (params[i - 1] !== params[i]) return false;
  }
  return true;
}

export function RightsEditControl({
  setRights,
  rights: { user_create, user_delete, user_edit, user_groups_create, user_groups_delete, user_groups_edit, user_rights_edit },
}: {
  setRights: (rights: Partial<Core.Rights>) => void;
  rights: Core.Rights;
}) {
  const { t } = useTranslation();
  const [advancedMode, setAdvancedMode] = useState(false);

  return (
    <>
      <Grid item xs={12} marginTop={2}>
        <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: -5 }}>
          <Typography variant="overline">{t('rights')}</Typography>
          <FormControlLabel label={t('advanced-mode')} control={<Switch checked={advancedMode} onChange={(e) => setAdvancedMode(e.target.checked)} />} labelPlacement="start" />
        </Stack>
      </Grid>
      <ConditionalRender render={advancedMode}>
        <Field as={GridCheckboxElement} type="checkbox" label={t('can-create-users')} name="rights.user_create" />
        <Field as={GridCheckboxElement} type="checkbox" label={t('can-edit-users')} name="rights.user_edit" />
        <Field as={GridCheckboxElement} type="checkbox" label={t('can-delete-users')} name="rights.user_delete" />
        <Field as={GridCheckboxElement} type="checkbox" label={t('can-edit-user-rights')} name="rights.user_rights_edit" />
        <Field as={GridCheckboxElement} type="checkbox" label={t('can-create-user-groups')} name="rights.user_groups_create" />
        <Field as={GridCheckboxElement} type="checkbox" label={t('can-edit-user-groups')} name="rights.user_groups_edit" />
        <Field as={GridCheckboxElement} type="checkbox" label={t('can-delete-user-groups')} name="rights.user_groups_delete" />
        <Field as={GridCheckboxElement} type="checkbox" label="Application Admin" name="rights.campaign_admin" />
        <Field as={GridCheckboxElement} type="checkbox" label={t('external-user')} name="rights.is_group_only_user" />
        <Field as={GridCheckboxElement} type="checkbox" label={t('file-admin-handbook-files')} name="rights.file_admin" />
        <Field as={GridCheckboxElement} type="checkbox" label={t('super-admin')} name="roles.superAdmin" />
      </ConditionalRender>
      <ConditionalRender render={!advancedMode}>
        <Field
          as={GridCheckboxElement}
          type="checkbox"
          label="User Admin"
          description={t('user-admin-description')}
          checked={user_create && user_edit && user_delete && user_groups_create && user_groups_delete && user_groups_edit && user_rights_edit}
          indeterminate={!allEqual(user_create, user_edit, user_delete, user_groups_create, user_groups_delete, user_groups_edit, user_rights_edit)}
          onChange={(e) => {
            setRights({
              user_create: e.target.checked,
              user_edit: e.target.checked,
              user_delete: e.target.checked,
              user_groups_create: e.target.checked,
              user_groups_delete: e.target.checked,
              user_groups_edit: e.target.checked,
              user_rights_edit: e.target.checked,
            });
          }}
        />
        <Field as={GridCheckboxElement} type="checkbox" label="Application Admin" name="rights.campaign_admin" description={t('application-admin-description')} />
        <Field as={GridCheckboxElement} type="checkbox" label="File Admin" name="rights.file_admin" description={t('file-admin-description')} />
        <Field as={GridCheckboxElement} type="checkbox" label="Super Admin" name="roles.superAdmin" description={t('super-admin-description')} />
      </ConditionalRender>
    </>
  );
}
