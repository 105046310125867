import { ApplicationForm, Campaign, Group, GroupTag } from '@base/core';
import { Form } from '@editors/form-editor';
import { faEdit, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Stack, Tab, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@material-ui/core';
import { DatePicker, LoadingButton, TabContext, TabList, TabPanel } from '@material-ui/lab';
import cuid from 'cuid';
import { Field, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ContentHeader, usePopup } from '../../../components/common';
import { LoadingPromiseButton, LoadingPromiseToggleButton } from '../../../components/LoadingPromiseButton';
import { useURLQuery } from '../../../lib/hooks/useQuery';
import { Loading } from '../../auth';
import { FormSubmissions } from '../FormSubmissions';
import { FormSubmissionsByNF } from '../FormSubmissionsByNF';
import { useCreateFormMutation, useDeleteFormMutation, useUpdateFormMutation, useUpdateFormsMutation } from '../hooks';
import { ReportsContent } from './ReportsContent';

export interface CampaignSetttingsProps {
  campaign: Campaign;
  forms: ApplicationForm[];
  groups: Group[];
  groupTags: GroupTag[];
  setGroups(update: { added: string[]; removed: string[]; value: string[] }): void | Promise<void>;
}

export function FormSettings({ campaign, groups, setGroups, groupTags, forms }: CampaignSetttingsProps) {
  const { formId } = useParams<{ formId: string }>();
  const updateFormMutation = useUpdateFormMutation<ApplicationForm>();
  const updateFormsMutation = useUpdateFormsMutation<ApplicationForm>();
  const createFormMutation = useCreateFormMutation<ApplicationForm>();
  const currentForm = forms.find((f) => f.id === formId);
  const theme = useTheme();
  // const [tabIndex, setTabIndex] = useState('overview');
  const tabIndex = useURLQuery().get('tab') || 'overview';

  const { t } = useTranslation();
  const notify = usePopup();
  const history = useHistory();
  const deleteFormMutation = useDeleteFormMutation();
  function setTabIndex(index: string) {
    history.replace('?tab=' + index);
  }
  const handleSort = (sortedReports: ApplicationForm[]) => {
    let count = 0;
    updateFormsMutation.mutate(sortedReports.map((r) => ({ formId: r.id, update: { position: count++ }, campaignId: campaign.id })));
  };

  const sortedReports = useMemo(() => forms.filter((f) => f.parent === currentForm?.id).sort((a, b) => (a.position ?? 0) - (b.position ?? 0)), [forms, currentForm?.id]);

  if (!currentForm) return <Loading small />;
  const status = currentForm.published ?? false;

  async function toggleFormPublishState(publish: boolean) {
    try {
      await updateFormMutation.mutateAsync({ formId: currentForm.id, update: { published: publish }, campaignId: campaign.id });
      notify({
        title: t('saved'),
        type: 'success',
      });
    } catch (error) {
      notify({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    }
  }

  return (
    <TabContext value={tabIndex}>
      <ContentHeader noBack dark title="Current Application" subtitle="Settings and reports">
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1} />
          {tabIndex !== 'groups' ? (
            <LoadingPromiseButton
              sx={{ borderRadius: 999 }}
              endIcon={<FontAwesomeIcon fixedWidth icon={faEdit} />}
              variant="contained"
              onClick={async () => {
                history.push(currentForm.id + '/edit');
              }}
            >
              {t('edit-form')}
            </LoadingPromiseButton>
          ) : (
            <LoadingPromiseButton
              sx={{ borderRadius: 999 }}
              variant="contained"
              endIcon={<FontAwesomeIcon fixedWidth icon={faPlusCircle} />}
              onClick={async () => {
                await createFormMutation.mutateAsync({
                  campaignId: campaign.id,
                  form: {
                    content: [],
                    id: cuid(),
                    name: 'Report ' + (sortedReports.length + 1),
                    parent: currentForm.id,
                    published: false,
                    position: 999999,
                    status: 'not-started',
                  },
                });
              }}
            >
              {t('create-report')}
            </LoadingPromiseButton>
          )}
        </Stack>
      </ContentHeader>
      <Box paddingX={4} paddingTop={1} paddingBottom={2} bgcolor={theme.palette.background.grayDark}>
        <TabList style={{ height: 40, minHeight: 40 }} onChange={(_, v) => setTabIndex(v)} TabIndicatorProps={{ style: { background: theme.palette.primary.main } }} textColor={'#000000' as any}>
          <Tab label="Submissions" value="overview" />
          <Tab label="Submissions by NF" value="submissions-by-nf" />
          <Tab label="Form Settings" value="settings" />
          <Tab label="Reports" value="groups" />
        </TabList>
      </Box>
      <TabPanel value="settings">
        <Formik
          initialValues={currentForm}
          enableReinitialize
          onSubmit={async (values) => {
            try {
              await updateFormMutation.mutateAsync({ formId, update: values, campaignId: campaign.id });
              notify({
                title: t('saved'),
                type: 'info',
              });
            } catch (error) {
              notify({
                title: t('error'),
                type: 'error',
                text: error.message,
              });
            }
          }}
        >
          {({ values, setFieldValue, isSubmitting, handleReset, handleSubmit }) => {
            return (
              <Grid container margin={0} spacing={4}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    <Typography variant="overline">{t('main-information')}</Typography>
                    <Field as={TextField} label={t('name')} name="name" variant="outlined" required />
                    <Field as={TextField} label={t('description')} name="description" variant="outlined" multiline rows={5} />
                    <Field
                      as={DatePicker}
                      value={values.deadline}
                      onChange={(v) => setFieldValue('deadline', v.valueOf())}
                      mask={t('full-date-mask')}
                      renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" label={t('deadline')} />}
                    />
                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                      <Button variant="outlined" color="error" onClick={handleReset}>
                        {t('cancel')}
                      </Button>
                      <LoadingButton variant="contained" color="primary" loading={isSubmitting} onClick={handleSubmit as any}>
                        {t('save-changes')}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack spacing={2}>
                    <Typography variant="h2">{t('status')}</Typography>
                    <ToggleButtonGroup value={status} exclusive>
                      <ToggleButton
                        value={false}
                        //@ts-ignore
                        color="error"
                        onClick={async () => {
                          if (status) await toggleFormPublishState(false);
                        }}
                      >
                        {!status ? t('unpublished') : t('unpublish')}
                      </ToggleButton>
                      <LoadingPromiseToggleButton
                        variant="contained"
                        // loadingPosition="start"
                        // loading
                        //@ts-ignore
                        value={true}
                        //@ts-ignore
                        color="success"
                        onClick={async () => {
                          if (!status) await toggleFormPublishState(true);
                        }}
                      >
                        {status ? t('published') : t('publish')}
                      </LoadingPromiseToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </TabPanel>
      <TabPanel value="groups" sx={{ display: 'flex', flexDirection: 'column', flex: tabIndex === 'groups' ? 1 : undefined }}>
        <ReportsContent
          reports={sortedReports}
          onChange={handleSort}
          campaignId={campaign.id}
          onDelete={async (id) => await deleteFormMutation.mutateAsync({ campaignId: campaign.id, formId: id })}
          onCreate={async () => {
            await createFormMutation.mutateAsync({
              campaignId: campaign.id,
              form: {
                content: [],
                id: cuid(),
                name: 'Report ' + (sortedReports.length + 1),
                parent: currentForm.id,
                published: false,
                position: 999999,
                status: 'not-started',
              },
            });
          }}
          onDuplicate={async (form, destinationCampaign) => {
            await createFormMutation.mutateAsync({
              campaignId: destinationCampaign || campaign.id,
              form: {
                ...form,
                id: cuid(),
              },
            });
          }}
          onChangePublish={async (published, reportId) => {
            try {
              await updateFormMutation.mutateAsync({ formId: reportId, update: { published: !!published }, campaignId: campaign.id });
              notify({
                title: t(published ? 'published' : 'unpublished'),
                type: 'info',
              });
            } catch (error) {
              notify({
                title: t('error'),
                type: 'error',
                text: error.message,
              });
            }
          }}
          onChangeForm={async (values, formId) => {
            try {
              await updateFormMutation.mutateAsync({ formId, update: values, campaignId: campaign.id });
              notify({
                title: t('saved'),
                type: 'info',
              });
            } catch (error) {
              notify({
                title: t('error'),
                type: 'error',
                text: error.message,
              });
            }
          }}
        />
      </TabPanel>
      <TabPanel value="overview" sx={{ display: 'flex', flexDirection: 'column', flex: tabIndex === 'overview' ? 1 : undefined }}>
        <FormSubmissions forms={[currentForm, ...sortedReports]} campaign={campaign} groups={groups} />
      </TabPanel>
      <TabPanel value="submissions-by-nf" sx={{ display: 'flex', flexDirection: 'column', flex: tabIndex === 'overview' ? 1 : undefined }}>
        <FormSubmissionsByNF forms={[currentForm, ...sortedReports]} campaign={campaign} groups={groups} />
      </TabPanel>
    </TabContext>
  );
}
