import * as ACTIONS from './actions';
import type {ThunkAction} from 'redux-thunk';
import type {StateType} from '../store';
import type {ActionsType} from './reducer';
import {AuthInteractor} from '../../useCases';
import {Observer, TwoFALoginError} from '../../entities';
import { AuthRepo } from '../../repos';

export class AuthThunk {
  constructor(private auth: AuthInteractor) {}

  login = (email: string, password: string, reCaptchaTriggerElementId?: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.loginRequestAction());
      const user: Core.User = await this.auth.login(email, password, reCaptchaTriggerElementId,{
        next: (user) => {
          const {rights, groups, roles, ...data} = user;
          dispatch(ACTIONS.updateDataSuccessAction(data));
        },
      });
      dispatch(ACTIONS.loginSuccessAction(user));
    } catch (error) {
      if (error instanceof TwoFALoginError) {
        dispatch(ACTIONS.set2FAResolverAction(error.resolver, error.phoneNumber));
      } else if (error.message === 'Error: Enrollment of second factor is required') {
        dispatch(ACTIONS.provide2FAFactorCallAction());
      } else {
        dispatch(ACTIONS.loginFailureAction(error));
      }
    }
  };

  create2FAcall = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    dispatch(ACTIONS.enforce2FACreationAction());
  };

  close2FAcall = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    dispatch(ACTIONS.close2FACallAction());
  };

  close2FACreationNotification = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    dispatch(ACTIONS.close2FACreationNotificationAction());
  };

  provide2FAVerificationCode = (verificationCode: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.provide2FAVerificationCodeRequestAction());
      const { twoFA } = getState().auth;

      if (!twoFA.resolver) throw new Error('you have to sign in first!');

      const user = await this.auth.verify2FACode(twoFA.resolver, verificationCode,{
        next: (user) => {
          const {rights, groups, roles, ...data} = user;
          dispatch(ACTIONS.updateDataSuccessAction(data));
        }
      });
      dispatch(ACTIONS.provide2FAVerificationCodeSuccessAction());
      dispatch(ACTIONS.loginSuccessAction(user));
      setLCDATA(user);
    } catch (error) {
      dispatch(ACTIONS.provide2FAVerificationCodeFailureAction(error));
    }
  };

  provide2FAPhoneNumber = (phoneNumber: string, reCaptchaTriggerElementId?: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.provide2FAPhoneNumberRequestAction());
      const verificationId = await this.auth.provide2faFactor(phoneNumber, reCaptchaTriggerElementId);
      dispatch(ACTIONS.provide2FAPhoneNumberSuccessAction(verificationId, phoneNumber));
      dispatch(ACTIONS.verify2FAFactorEnrollmentCallAction());
    } catch (error) {
      dispatch(ACTIONS.provide2FAPhoneNumberFailureAction(error));
    }
  };

  verifiy2FAFactorEnrollment = (verificationCode: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.verify2FAFactorEnrollmentRequestAction());
      const { twoFA } = getState().auth;
      await this.auth.verify2faFactor(twoFA.enrollmentVerificationId, verificationCode);
      dispatch(ACTIONS.verify2FAFactorEnrollmentSuccessAction());
      dispatch(ACTIONS.notify2FACreationfinishedAction());
    } catch (error) {
      dispatch(ACTIONS.verify2FAFactorEnrollmentFailureAction(error));
    }
  };

  logout = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.logoutRequestAction());
      await this.auth.logout();
      dispatch(ACTIONS.logoutSuccessAction());
      dispatch(ACTIONS.resetAction());
    } catch (error) {
      dispatch(ACTIONS.logoutFailureAction(error));
    }
  };

  resetPassword = (email: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.resetPasswordRequestAction());
      await this.auth.resetPassword(email);
      dispatch(ACTIONS.resetPasswordSuccessAction());
    } catch (error) {
      dispatch(ACTIONS.resetPasswordFailureAction(error));
    }
  };

  resetPasswordCreateDefault = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.resetPasswordResetAction());
    } catch (error) {
      dispatch(ACTIONS.resetPasswordFailureAction(error));
    }
  };

  updateData = (update: Partial<Omit<Core.User, 'groups' | 'rights' | 'roles' | 'id'>>): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.updateDataRequestAction());
      await this.auth.updateUserData(update);
      dispatch(ACTIONS.updateDataSuccessAction(update));
    } catch (error) {
      dispatch(ACTIONS.updateDataFailureAction(error));
    }
  };

  tryAutoLogin = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.tryAutoLoginRequestAction());

      this.auth.onAuthStateChanged(async (user) => {
        if (user) {
          dispatch(ACTIONS.tryAutoLoginSuccessAction(user));
        } else {
          dispatch(ACTIONS.logoutSuccessAction());
          dispatch(ACTIONS.resetAction());
        }
      });

      const user = await this.auth.tryAutoLogin({
        next: (user) => {
          const { rights, groups, roles, ...data } = user;
          dispatch(ACTIONS.updateDataSuccessAction(data));
        },
      });
      // const user = await this.auth.tryAutoLogin();
      // const { rights, groups, roles, ...data } = user;
      // dispatch(ACTIONS.updateDataSuccessAction(data));

      // if (user.isTwoFAEnforced && !user.is2FAEnrolled) {
      //   dispatch(ACTIONS.provide2FAFactorCallAction());
      // }

      dispatch(ACTIONS.tryAutoLoginSuccessAction(user));
      setLCDATA(user);
    } catch (error) {
      dispatch(ACTIONS.tryAutoLoginFailureAction(error));
    }
  };
}

function setLCDATA(user: Core.User) {
  //@ts-ignore
  if (typeof LC_API !== 'undefined') {
    //@ts-ignore
    LC_API.set_visitor_email?.(user.email);
    //@ts-ignore
    LC_API.set_visitor_name?.(user.displayName);
    //@ts-ignore
    LC_API.set_custom_variables?.(Object.entries(user).map(([key, value]) => ({
      name: key,
      value: JSON.stringify(value)
    })));
  }
}
