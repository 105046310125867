import { faArrowDown, faArrowUp, faCheck, faFolder, faTimes, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getFileExtension, getFileIconDefinition } from '..';
import { Actions } from '../../../../../core/src';
import '../../../css/components/common/content/Upload/UploadPopup.scss';
import { useTheme } from '../../../theme/ThemeProvider';
import { cutExtensionfromName } from '../ContentElementHelper';

type UploadTaskProps = {
  fileName: string;
  mimeType: Core.FileType;
  progress: number;
  canceled?: boolean;
  onAbortClick: () => void;
  onCanceledClick: () => void;
  onTaskClick: () => void;
  onGoToFolderClick: () => void;
};

const UploadTask = ({ fileName, mimeType, progress, onAbortClick, onTaskClick, onGoToFolderClick, onCanceledClick, canceled = false }: UploadTaskProps) => {
  const theme = useTheme();
  const extension = getFileExtension(mimeType);
  return (
    <div className="upload-task" onDoubleClick={progress === 100 && onTaskClick}>
      <div className="icon-name-con">
        <FontAwesomeIcon icon={getFileIconDefinition(mimeType).icon} className="file-icon" style={{ color: canceled && theme.colors.error }} />
        <div className="text" style={{ color: canceled && theme.colors.error }}>
          {cutExtensionfromName(fileName, extension)}
          {extension}
        </div>
      </div>
      {progress < 100 ? (
        canceled ? (
          <div onClick={onCanceledClick} className="btn trash">
            <FontAwesomeIcon icon={faTrash} color="#fff" />
          </div>
        ) : (
          <>
            <div onClick={onAbortClick} className="btn abort">
              <FontAwesomeIcon icon={faTimes} color="#fff" />
            </div>
            <div className="progress">
              <CircularProgress variant="determinate" value={progress} size={28} thickness={4.2} color={'primary'} />
            </div>
          </>
        )
      ) : (
        <>
          <div className="btn check">
            <FontAwesomeIcon icon={faCheck} color="#fff" />
          </div>
          <div onClick={onGoToFolderClick} className="btn folder">
            <FontAwesomeIcon icon={faFolder} />
          </div>
        </>
      )}
    </div>
  );
};

export const UploadPopup = () => {
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { t } = useTranslation();

  const { uploadTasks } = useSelector((state: Core.StateType) => state.files);

  useEffect(() => {
    for (const task of Object.values(uploadTasks)) {
      task.task?.then((t) => queryClient.invalidateQueries(['files', t.parent, 'children']));
    }
  }, [Object.values(uploadTasks).filter((t) => t.state === 'running').length]);

  useEffect(() => {
    if (Object.values(uploadTasks).length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [Object.values(uploadTasks).length]);

  const getUploadValue = () => {
    let allTotalBytes = 0;
    let allTransferBytes = 0;
    for (const task of Object.values(uploadTasks)) {
      allTotalBytes += task.totalBytes;
      allTransferBytes += task.bytesTransferred;
    }
    return (allTransferBytes / allTotalBytes) * 100;
  };

  if (!open && active) {
    return (
      <div className="upload-tasks-bubble" onClick={() => setOpen(!open)}>
        <CircularProgress variant="determinate" value={getUploadValue()} size={60} thickness={4.2} style={getUploadValue() === 100 ? { color: theme.colors.right } : {}} className="bubble-progress" />
        <div className="bubble-text">{Object.values(uploadTasks).length}</div>
        <FontAwesomeIcon icon={faArrowUp} className="bubble-icon" />
      </div>
    );
  } else if (active) {
    return (
      <div className="upload-tasks-popup">
        <div className="header">
          <div className="header-text">{t('upload-counted', { count: Object.values(uploadTasks).length })}</div>
          <div className="btn-group">
            <div className="btn header-icon-btn" onClick={() => setOpen(!open)}>
              <FontAwesomeIcon icon={faArrowDown} />
            </div>
            <div
              className="btn header-icon-btn"
              style={{ marginLeft: 10 }}
              onClick={() => {
                dispatch(Actions().files.clearCompletedTasks);
                setActive(!open);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
        <div className="task-list">
          {Object.values(uploadTasks).map((task) => {
            return (
              <UploadTask
                key={task.fileId}
                fileName={task.name}
                mimeType={task.mimeType}
                progress={(task.bytesTransferred / task.totalBytes) * 100}
                canceled={task.state === 'error' || task.state === 'cancelled'}
                onCanceledClick={() => dispatch(Actions().files.deleteCanceledTask(task))}
                onAbortClick={async () => (await task.task?.cancel()) && dispatch(Actions().files.deleteCanceledTask(task))}
                onGoToFolderClick={async () => {
                  history.push(`/files/${(await task.task)?.parent}`);
                }}
                onTaskClick={() => {
                  task.state === 'success' && history.push(`/files/${task.fileId}`);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
