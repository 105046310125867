import { FormSubmission } from '@base/core';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, CardContent, IconButton, Stack, TableCell, TableRow, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useMemo, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { Comments } from './Comments';
import { createEmptyForm } from './components';
import { MinimalReaderItem } from './components/MinimalReaderItem';
import { Renderers } from './content-renderer';
import './EditorsFormEditor.module.scss';
import { Form } from './entities/Form';
import { FormContent } from './entities/FormContent';
import { getChangeSets } from './Helper';
import { FileProvider } from './provider/FileProvider';
import { MockFileProvider } from './provider/mocks/MockFileProvider';
import { ProvidersProvider } from './provider/ProviderContext';
import { QuestionSpecificChatItem } from './QuestionSpecificChatItem';
import {useParams} from "react-router-dom";
import {useCallback} from "react";

export interface FormViewerMinimalProps<T extends { data: any; id: string }> {
  document: Form;
  fileProvider?: FileProvider;
  formSubmissionHistory: (FormSubmission & { timestamp: Date })[];
  uploadFileProvider?: FileProvider;
  value: T;
  review: FormSubmission['review'];
  chats?: QuestionSpecificChatItem[];
  onSendChat?: (message: string, questionId: string) => void;
  readChatItems?: (chatItemIds: string[]) => void;
  action?: (questionId: string, action: 'rejected' | 'accepted') => void;
  context?: any;
}

const useStyles = makeStyles({
  parent: {
    '&:hover': {
      '& $actions': {
        opacity: 1,
      },
    },
  },
  actions: {
    opacity: 0,
    transition: 'opacity 300ms ease',
  },
});

export function FormViewerMinimal<T extends { data: any; id: string }>({
  document = createEmptyForm(),
  fileProvider = new MockFileProvider(),
  uploadFileProvider = new MockFileProvider(),
  value,
  review = {},
  onSendChat,
  action,
  formSubmissionHistory,
  readChatItems,
  chats = [],
  context,
}: FormViewerMinimalProps<T>) {
  const items = document.content;
  const theme = useTheme();
  const classes = useStyles();

  const [selectedQuestion, setSelectedQuestion] = useState<string>(null);
  const me = useSelector((state: Core.StateType) => state.auth.user);

  return (
    <ProvidersProvider value={{ fileProvider: fileProvider, uploadFileProvider }}>
      <Stack spacing={2} paddingX={2}>
        {items.map((i, idx) => {
          switch (i.type) {
            case 'info':
              return null;
            case 'provide-file':
              return null;
            case 'section':
              return (
                <TableRow key={i.id}>
                  <TableCell component="th" sx={{ paddingLeft: 2 }} scope="row" colSpan={1000}>
                    <Typography variant="sub3">{i.title}</Typography>
                  </TableCell>
                </TableRow>
              );
            default: {
              const questionChats = chats.filter((c) => c.questionId === i.id);
              const unreadCount = questionChats.reduce((sum, c) => sum + (c.read[me.id] ? 0 : 1), 0);
              const changeSets = getChangeSets(formSubmissionHistory, i.id).map((c) => ({ ...c, schema: i }));
              const accepted = review[i.id]?.type === 'accepted';
              const rejected = review[i.id]?.type === 'rejected';
              return (
                <Badge key={i.id} color="error" badgeContent={unreadCount} sx={{ display: 'block' }}>
                  <Card
                    variant="outlined"
                    sx={{ cursor: i.id !== selectedQuestion ? 'pointer' : undefined, borderColor: accepted ? 'success.main' : questionChats.length > 0 || rejected ? 'error.main' : undefined }}
                    onClick={() => setSelectedQuestion(i.id)}
                    className={classes.parent}
                  >
                    <CardContent sx={{ padding: 2, paddingBottom: theme.spacing(2) + ' !important' }}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="sub3" sx={{ wordBreak: 'break-word', marginBottom: 1, color: 'grey.700', display: 'block' }}>
                          {i.title}
                        </Typography>
                        <Stack direction="row" spacing={1} className={classes.actions}>
                          <IconButton
                            size="small"
                            sx={{ color: 'white', bgcolor: 'error.main', ':hover': { bgcolor: 'error.light', color: 'white' }, width: 24, height: 24 }}
                            onClick={(e) => {
                              // e.stopPropagation();
                              action(i.id, 'rejected');
                            }}
                          >
                            <FontAwesomeIcon fixedWidth icon={faTimes} />
                          </IconButton>
                          <IconButton
                            size="small"
                            sx={{ color: 'white', bgcolor: 'success.main', ':hover': { bgcolor: 'success.light', color: 'white' }, width: 24, height: 24 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              action(i.id, 'accepted');
                            }}
                          >
                            <FontAwesomeIcon fixedWidth icon={faCheck} />
                          </IconButton>
                        </Stack>
                      </Stack>
                      <Typography variant="sub3" sx={{ wordBreak: 'break-word', marginBottom: 1, display: 'block' }} fontWeight="600">
                        <MinimalReaderItem schemaValue={i} value={value.data[i.id]} context={context} />
                      </Typography>
                      {onSendChat && selectedQuestion === i.id && (
                        <Comments
                          readChatItems={readChatItems}
                          chats={[...questionChats, ...(changeSets as any)]}
                          onSend={(message) => onSendChat(message, i.id)}
                          containerProps={{ maxHeight: 500 }}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Badge>
              );
            }
          }
        })}
      </Stack>
    </ProvidersProvider>
  );
}
