import { Typography, useTheme } from '@material-ui/core';
import React from 'react';

export function Budget({ budget, budgetOverride }: { budget: number; budgetOverride?: number }) {
  const theme = useTheme();
  const budgetString = `${(budget || 0).toFixed(2)} €`;
  return (
    <div>
      {/* Budget:{' '} */}
      {budgetOverride ? (
        <>
          <del style={{ color: theme.palette.error.main }}>{budgetString}</del> <span style={{ color: theme.palette.success.main }}>{budgetOverride.toFixed(2)} €</span>
        </>
      ) : (
        budgetString
      )}
    </div>
  );
}
