
import { createStore, combineReducers, compose, applyMiddleware, Action } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { usersReducer } from './users'
import { groupsReducer } from './groups'
import { authReducer } from './auth'
import { resettable } from './common';
import { filesReducer } from './files'
import { isJSDocAllType } from 'typescript';

//@redux-helper/rootReducer
const rootReducer = combineReducers({
	users: usersReducer,
	groups: groupsReducer,
	auth: authReducer,
	files: filesReducer,
});

const reducers = {
	users: usersReducer,
	groups: groupsReducer,
	auth: authReducer,
	files: filesReducer,
}

declare global {
	namespace Core {
		export interface StateType extends ReturnType<typeof rootReducer> { }
		export type DispatchType = ThunkDispatch<StateType, void, Action>
	}
}

export type StateType = Core.StateType;

export function configureStore(initialState?: Partial<Core.StateType>, otherReducers?: any, sagas?: any[]): any {
	const middleware = [];
	middleware.push(thunk);
	if (sagas) {
		var sagaMiddleWare = createSagaMiddleware();
		middleware.push(sagaMiddleWare)
	}

	//@ts-ignore
	if (process.env.NODE_ENV !== 'production' && createLogger) {
	  middleware.push(createLogger());
	}

	const store = createStore(resettable(combineReducers({...reducers, ...otherReducers})), initialState as any, compose(applyMiddleware(...middleware)));

	function* mainSaga() {
		yield all(sagas)
	}
	
	if (sagas) {
		sagaMiddleWare.run(mainSaga)
	}
	
	return store;
}
