import { Box, Tab, useTheme } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { AccessRequest } from '../AccessRequest';
import { RejectedAccessRequests } from '../RejectedAccessRequests';

export function AccessRequestWrapper() {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState('current');

  return (
    <TabContext value={tabIndex}>
      <Box paddingX={4} paddingBottom={2} paddingTop={1} bgcolor={theme.palette.background.grayDark}>
        <TabList style={{ height: 40, minHeight: 40 }} onChange={(_, v) => setTabIndex(v)} TabIndicatorProps={{ style: { background: theme.palette.primary.main } }} textColor={'#000000' as any}>
          <Tab label="Current" value="current" />
          <Tab label="Rejected" value="rejected" />
        </TabList>
      </Box>
      {tabIndex === 'current' && <AccessRequest />}
      {tabIndex === 'rejected' && <RejectedAccessRequests />}
    </TabContext>
  );
}
