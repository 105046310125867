import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import type { ReactChildrenProp, SVGImage } from '../../lib';
import { Typography } from '@material-ui/core';
import { useTheme } from '../../theme/ThemeProvider';

type leftConProps = {
  children?: ReactChildrenProp;
  customerLogo: SVGImage | string;
  productName: string;
  icon: IconDefinition;
  title: string;
};

export const LeftContainer = ({ children, customerLogo: CustomerLogo, productName, icon, title }: leftConProps) => {
  const theme = useTheme();
  return (
    <div className="auth-inside-left-con">
      {typeof CustomerLogo === 'string' ? <img src={CustomerLogo} className="customer-logo" /> : <CustomerLogo className="customer-logo" fill={theme.colors['main-color']} />}
      <div className="left-content-con">
        <div className="row mb20">
          <FontAwesomeIcon icon={icon} fixedWidth />
          <Typography variant="h4" sx={{ ml: 1 }}>
            {title}
          </Typography>
        </div>
        {children}
      </div>
    </div>
  );
};
