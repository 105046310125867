import { faAnalytics, faBooks, faBuilding, faPassport, faPhoneLaptop, faUnlock, faUsers, faUsersMedical } from '@fortawesome/pro-light-svg-icons';
import '@base/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import '../../css/components/common/layout/userGroupGrid.scss';
import { ConditionalRenderWithRights } from '../../helpers/ConditionalRenderWithRights';
import { UserGroupElement } from './UserGroupElement';

interface UserGridProps {
  title?: string;
  userId: string;
}

export const UserGroupGrid = ({ title = 'Für diesen Nutzer freigeschaltene Inhalte', userId }: UserGridProps) => {
  console.log(useSelector((s) => s));
  const { users } = useSelector((state: Core.StateType) => state.users);
  const { groups } = useSelector((state: Core.StateType) => state.groups);
  
  const userGroups = useMemo(
    () =>
      Object.entries(users[userId].groups)
        .filter(([groupId, hasGroup]) => hasGroup)
        .map(([groupId, hasGroup]) => groups.find((g) => g.id == groupId)),
    [groups, users],
  );

  return (
    <div className="grid-con users">
      <div className="inner-grid-con" style={{ paddingBottom: 4 }}>
        <ConditionalRenderWithRights user_edit>
          <div className="grid-con-title">{title}</div>
        </ConditionalRenderWithRights>
        <ConditionalRenderWithRights user_edit invert>
          <div className="grid-con-title">Deine freigeschaltenen Inhalte</div>
        </ConditionalRenderWithRights>
        <div className="flexbox-row" style={{ paddingBottom: 0 }}>
          <ConditionalRenderWithRights user_edit>
            <div className="separation-line-con">
              <UserGroupElement button style={{ marginRight: 18 }} name="Inhalte freischalten" icon={faUnlock} />
              <UserGroupElement button name="Neue Zugangs-Gruppe erstellen" icon={faUsersMedical} />
              <div className="flex-separation-line" />
            </div>
          </ConditionalRenderWithRights>
          <div className="w-layout-grid user-group-grid">
            {userGroups.filter(g=> g).map((g) => (
              <UserGroupElement name={g!.name} icon={g!.icon} key={g!.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
