import React, { useState } from 'react';
import { DefaultImagePlaceholder } from '../../assets/images';
import { usePromiseResolve } from '../../views/fileManager/usePromiseResolve';

export default function AsyncImage({ src, ...rest }: Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'> & { src: Promise<string> | string }) {
  const [imageLoadingError, setImageLoadingError] = useState<boolean>(false);
  const data = usePromiseResolve(src);
  const imageSrc = imageLoadingError ? DefaultImagePlaceholder : data;

  return (
    <img
      alt={rest.alt ?? 'Image error'}
      src={imageSrc ?? DefaultImagePlaceholder}
      {...rest}
      onError={() => {
        setImageLoadingError(true);
      }}
      placeholder={DefaultImagePlaceholder}
    />
  );
}
