import { FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@material-ui/core';
import cuid from 'cuid';
import React from 'react';
import * as yup from 'yup';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { SelectableEditor } from './SelectableEditor';
import { SHORT_ANSWER } from './types';

export const MultipleChoiceQuestionRenderer: Renderer<MCItem[], string> = {
  type: SHORT_ANSWER,
  contentType: 'selectable-item',
  defaultEditorValue: [{ id: cuid(), title: 'Option 1' }],
  viewer,
  viewerMin,
  editor,
  getValidationSchema,
  getTextRepresentation,
};

function viewer({ onChange, value, required, schema, error, touched }: RendererViewerProps<MCItem[], string>): JSX.Element {
  return (
    <Grid item xs={12}>
      <FormControl error={touched && Boolean(error)}>
        <RadioGroup onChange={(e) => onChange(e.target.value)} value={value}>
          {schema?.map((v) => (
            <FormControlLabel key={v.id} value={v.id} control={<Radio />} label={v.title} />
          ))}
        </RadioGroup>
        {touched && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

function viewerMin({ onChange, value, schema }: RendererViewerProps<MCItem[], string>): JSX.Element {
  return (
    <Grid item xs={12}>
      <RadioGroup onChange={(e) => onChange(e.target.value)} value={value}>
        {schema?.map((v) => (
          <FormControlLabel key={v.id} value={v.id} control={<Radio size="small" />} label={v.title} />
        ))}
      </RadioGroup>
    </Grid>
  );
}
async function getTextRepresentation({ onChange, value, schema }: RendererViewerProps<MCItem[], string>) {
  return schema.find((item) => item.id == value)?.title || 'None selected';
}

function editor({ onChange, value }: RendererEditorProps<MCItem[], string>): JSX.Element {
  return <SelectableEditor value={value} onChange={onChange} renderItem={(v) => <Radio disabled></Radio>} />;
}

function getValidationSchema(schema, t) {
  let validator = yup.string();
  if (schema.required) {
    validator = validator.nullable(false).required(t('something-is-required', { something: t('question') }));
  } else {
    validator = validator.nullable();
  }
  return validator.typeError(t('something-is-required', { something: t('question') }));
}
export type MCItem = {
  id: string;
  title: string;
  checked?: boolean;
};
