import { FormControlLabel, Grid, InputAdornment, Switch, TextField, Typography } from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';
import { FormContent } from '../entities';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { NUMBER } from './types';

interface EditorSchema {
  min: number | null;
  max: number | null;
  unit: string | null;
}

export const NumberRenderer: Renderer<EditorSchema, number> = {
  type: NUMBER,
  viewer,
  editor,
  viewerMin,
  contentType: 'number',
  defaultEditorValue: { min: null, max: null, unit: '€' },
  defaultReaderValue: 0,
  getValidationSchema,
  getTextRepresentation,
};

function viewer({ onChange, schema, value, required, touched, error }: RendererViewerProps<EditorSchema, number>): JSX.Element {
  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        label="Number"
        type="number"
        variant="outlined"
        required={required}
        value={value.toString()}
        onChange={(e) => onChange?.(parseFloat(e.target.value))}
        error={touched && Boolean(error)}
        helperText={touched && error}
        InputProps={{
          endAdornment: <InputAdornment position="start">{schema.unit}</InputAdornment>,
        }}
      />
    </Grid>
  );
}

function viewerMin({ onChange, value = 0, touched, required, error }: RendererViewerProps<EditorSchema, number>): JSX.Element {
  return <div>{value}</div>;
}
async function getTextRepresentation({ onChange, value, touched, required, error }: RendererViewerProps<undefined, number>) {
  return `<div>${value}</div>`;
}

function editor({ onChange, value, budgetField, setAsBudgetField }: RendererEditorProps<EditorSchema, number>): JSX.Element {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField label="Minimum" value={value.min} onChange={(e) => onChange({ ...value, min: e.target.value ? parseInt(e.target.value) : null })} type="number" fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Maximum" value={value.max} onChange={(e) => onChange({ ...value, max: e.target.value ? parseInt(e.target.value) : null })} type="number" fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Unit" value={value.unit} onChange={(e) => onChange({ ...value, unit: e.target.value })} fullWidth variant="outlined" />
        </Grid>
        {setAsBudgetField && (
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch />}
              label="Use field as Budget field"
              checked={budgetField}
              onChange={(e) => {
                setAsBudgetField((e.target as any).checked);
                // if ((e.target as any).checked) onChange({ ...value, unit: '€' });
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

function getValidationSchema(schema: FormContent<EditorSchema>, t) {
  let validator = yup.number();
  if (schema.content.max !== null) {
    validator = validator.max(schema.content.max, t('value-must-be-less-than', { value: schema.content.max }));
  }
  if (schema.content.min !== null) {
    validator = validator.min(schema.content.min, t('value-must-be-greater-than', { value: schema.content.min }));
  }
  if (schema.required) {
    validator = validator.required(t('something-is-required', { something: t('field') }));
  }
  return validator.typeError(t('something-is-required', { something: t('field') }));
}
