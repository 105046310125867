import { RequestPopup } from '@access-request';
import { AccessRequestDoc, AccessRequestFileProps, Group } from '@base/core';
import { faEdit, faEye, faLayerPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, AvatarGroup, Box, Button, Stack, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, XGrid } from '@material-ui/x-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalRender, MainMenu, UserAvatarHandler } from '../../components/common';
import { ContentHeader } from '../../components/common/ContentHeader';
import { MainDialog } from '../../components/common/popups/MainDialog';
import { SearchInput } from '../../components/inputs';
import { LoadingPromiseButton } from '../../components/LoadingPromiseButton';
import { useSettings } from '../../theme/ThemeProvider';
import { GroupPopup } from './popups';
import { GridNoResultsOverlay, GridNoRowsOverlay } from '../../components/content/GridContent';

const useStyles = makeStyles({
  root: (theme: any) => ({
    borderRadius: 0,
    height: 'auto',
    flex: 1,
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
});

interface GroupsProps {
  groups: Group[];
  loading?: boolean;
  onDelete: (groupIds: string[]) => Promise<any> | any;
  onCreate: (name: string, users: string[]) => Promise<any> | any;
  onUpdate: (group: Group, users: { added: string[]; removed: string[] }) => Promise<any> | any;
  onDeleteFiles: (group: Group, filesToDelete: AccessRequestFileProps[]) => Promise<any> | any;
  userList: Core.User[];
}

export function Groups({ groups, loading, onDelete, onCreate, userList, onUpdate, onDeleteFiles }: GroupsProps) {
  const [gridSelection, setGridSelection] = useState<(string | number)[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [open, setOpen] = useState(null);
  const [dataOpen, setDataOpen] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(undefined);

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const settings = useSettings();

  const columns = (
    userList: Core.User[],
    onSaveGroup: (group: Group, users: { added: string[]; removed: string[] }) => Promise<void> | void,
    onDeleteFiles: (group: Group, filesToDelete: AccessRequestFileProps[]) => Promise<void>,
  ): GridColDef[] => {
    return [
      {
        field: 'country',
        width: 55,
        renderCell: ({ formattedValue, value, row }) => {
          const namedGroup = settings.namedGroups.find((namedGroup) => namedGroup.name === row.type);
          return namedGroup?.getIcon && namedGroup.getIcon(row as Group) ? (
            <img src={namedGroup.getIcon(row as Group)} alt="flag" style={{ borderRadius: '50%', height: 28 }} />
          ) : (
            <div style={{ borderRadius: '50%', height: 20, width: 20, backgroundColor: 'grey' }} />
          );
        },
      },
      {
        field: 'name',
        width: 275,
        headerName: 'Groupname',
        renderCell: ({ formattedValue, value, row }) => {
          return <Typography variant="subtitle1">{value}</Typography>;
        },
      },
      {
        field: 'users',
        width: 300,
        headerName: 'Involved users',
        type: 'date',
        disableColumnMenu: true,
        renderCell: ({ formattedValue, value }) => (
          <AvatarGroup max={8} spacing="medium">
            {((value as any) ?? []).map((user: Core.User) => (
              <UserAvatarHandler key={user.id} userName={user.displayName} userPhotoUrl={user.photoURL} />
            ))}
          </AvatarGroup>
        ),
      },
      {
        field: 'edit',
        flex: 1,
        align: 'right',
        type: 'date',
        disableColumnMenu: true,
        renderCell: ({ formattedValue, value, row }) => {
          const [open, setOpen] = useState(false);
          const [dataOpen, setDataOpen] = useState(false);

          const group = settings.namedGroups.find((g) => g.name === (row as Group).type);
          const menuActions = [
            {
              title: t('Edit group'),
              icon: faEdit,
              action: () => {
                setOpen(true);
              },
            },
          ];

          if (group.accessRequest) {
            menuActions.push({
              title: t('view-data'),
              icon: faEye,
              action: () => {
                setDataOpen(true);
              },
            });
          }

          return (
            <>
              <MainMenu
                id={row.id}
                menuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                }}
                actions={menuActions}
              />
              <RequestPopup
                active={dataOpen}
                setActive={setDataOpen}
                config={{ changeDataMode: true, readOnly: false }}
                topic={t('verification')}
                onSave={async (data) => await onSaveGroup({ ...(row as any), requestData: data } as any, { added: [], removed: [] })}
                name={row?.organizationName}
                description="Federation Data"
                formData={row?.requestData as AccessRequestDoc}
                onDeleteFiles={async (filesToDelete: AccessRequestFileProps[]) => onDeleteFiles(row as Group, filesToDelete)}
                FormContent={group?.accessRequest?.FormContent}
                getInitialValues={group?.accessRequest?.getInitialValues}
                getSchema={group?.accessRequest?.getSchema}
              />
              <GroupPopup
                onClose={() => setOpen(false)}
                onSave={(name, added, removed) => onSaveGroup({ ...(row as Group), name }, { added, removed })}
                group={row as any}
                userList={userList}
                open={open}
              />
            </>
          );
        },
      },
    ];
  };

  return (
    <>
      <ContentHeader noBack title={t('groups')} subtitle={t('groups-counted', { count: groups?.length })}>
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1}>
            <SearchInput
              variant="outlined"
              size="small"
              InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
              placeholder={t('search-groups')}
              fullWidth
              value={filterModel?.items?.find((i) => i.columnField == 'name')?.value ?? ''}
              onChange={(e) => {
                try {
                  new RegExp(e.target.value);
                  setFilterModel({ items: [{ columnField: 'name', operatorValue: 'contains', value: e.target.value }] });
                } catch {}
              }}
            />
          </Box>
          <ConditionalRender render={gridSelection.length > 0}>
            <LoadingPromiseButton
              variant="contained"
              color="error"
              sx={{ borderRadius: 999 }}
              endIcon={<FontAwesomeIcon icon={faTrash} />}
              loadingPosition="end"
              onClick={() => {
                setOpenDeletePopup(true);
              }}
            >
              Delete selected Groups
            </LoadingPromiseButton>
            <MainDialog
              open={openDeletePopup}
              onCloseClick={() => setOpenDeletePopup(false)}
              onSaveClick={async () => {
                onDelete(gridSelection as string[]);
                setGridSelection([]);
                setOpenDeletePopup(false);
              }}
              saveButtonColor="error"
              secondButtonColor="primary"
              modalTitle={t('do-you-really-want-to-delete-count-groups', { count: gridSelection.length })}
              description={'This action cannot be undone.' /* TODO: Implement i18n key */}
              buttonText={t('delete')}
            />
          </ConditionalRender>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: 999 }}
            endIcon={<FontAwesomeIcon icon={faLayerPlus} />}
            onClick={(e) => {
              e.stopPropagation();
              setCreateGroupOpen(true);
            }}
          >
            Add Group
          </Button>
        </Stack>
      </ContentHeader>
      <XGrid
        className={classes.root}
        headerHeight={40}
        onSelectionModelChange={(newSelection) => {
          setGridSelection(newSelection.selectionModel);
        }}
        selectionModel={gridSelection}
        sortModel={[{ field: 'name', sort: 'asc' }]}
        filterModel={filterModel}
        onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
        checkboxSelection
        disableSelectionOnClick
        onRowClick={(params) => setOpen(params.row)}
        columns={columns(userList, onUpdate, onDeleteFiles).map((c) => ({
          ...c,
          renderHeader: (params) => {
            return (
              <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                {params.colDef.headerName}
              </Typography>
            );
          },
        }))}
        rows={groups}
        loading={loading}
        components={{
          NoRowsOverlay: GridNoRowsOverlay,
          NoResultsOverlay: GridNoResultsOverlay,
        }}
      />
      <GroupPopup onClose={() => setOpen(false)} onSave={(name, added, removed) => onUpdate({ ...(open as Group), name }, { added, removed })} group={open} userList={userList} open={open} />
      <GroupPopup onClose={() => setCreateGroupOpen(false)} onCreate={onCreate} userList={userList} open={createGroupOpen} />
    </>
  );
}
