import * as React from 'react';

function SvgReader(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.596 51.311" ref={svgRef} {...props}>
      <g fill="#747474">
        <path d="M65.486 5.55a1.11 1.11 0 00-1.11 1.11v39.957h-6.027a94.416 94.416 0 00-21.213 2.415 2.211 2.211 0 01-2.339-.924A25.231 25.231 0 0147.961 44.4h13.085a1.11 1.11 0 001.11-1.11v-7.772a1.11 1.11 0 00-2.22 0v6.66H47.961a27.446 27.446 0 00-13.553 3.565v-30.2a1.11 1.11 0 00-2.22 0v30.2a27.442 27.442 0 00-13.553-3.564H6.66V2.22h17.092a8.441 8.441 0 018.435 8.441 1.11 1.11 0 002.22 0 8.441 8.441 0 018.435-8.441h17.094v28.858a1.11 1.11 0 002.22 0V1.11A1.11 1.11 0 0061.046 0h-18.2A10.67 10.67 0 0033.3 5.918 10.67 10.67 0 0023.752 0H5.55a1.11 1.11 0 00-1.11 1.11v42.177A1.11 1.11 0 005.55 44.4h13.085A25.226 25.226 0 0131.8 48.106a2.22 2.22 0 01-2.326.929A94.421 94.421 0 008.25 46.618H2.22V6.66a1.11 1.11 0 00-2.22 0v41.067a1.11 1.11 0 001.11 1.11h7.137a92.223 92.223 0 0120.708 2.358 4.418 4.418 0 004.343-1.4 4.415 4.415 0 004.329 1.405 92.285 92.285 0 0120.722-2.361h7.137a1.11 1.11 0 001.11-1.11V6.66a1.11 1.11 0 00-1.11-1.11z" />
        <path d="M29.66 9.559a10.632 10.632 0 00-5.908-1.79H11.099a1.11 1.11 0 100 2.22h12.653a8.409 8.409 0 014.682 1.417 1.11 1.11 0 001.232-1.847zM55.497 7.769H42.844a10.634 10.634 0 00-5.914 1.79 1.11 1.11 0 001.232 1.847 8.414 8.414 0 014.682-1.417h12.653a1.11 1.11 0 000-2.22zM29.66 15.109a10.632 10.632 0 00-5.908-1.79H11.099a1.11 1.11 0 100 2.22h12.653a8.409 8.409 0 014.682 1.417 1.11 1.11 0 001.232-1.847zM55.497 13.319H42.844a10.634 10.634 0 00-5.914 1.79 1.11 1.11 0 101.232 1.847 8.414 8.414 0 014.682-1.417h12.653a1.11 1.11 0 000-2.22zM29.66 20.659a10.632 10.632 0 00-5.908-1.79H11.099a1.11 1.11 0 100 2.22h12.653a8.409 8.409 0 014.682 1.417 1.11 1.11 0 101.232-1.847zM55.497 18.869H42.844a10.634 10.634 0 00-5.914 1.79 1.11 1.11 0 001.232 1.847 8.414 8.414 0 014.682-1.417h12.653a1.11 1.11 0 000-2.22zM29.66 26.208a10.632 10.632 0 00-5.908-1.79H11.099a1.11 1.11 0 000 2.22h12.653a8.409 8.409 0 014.682 1.417 1.11 1.11 0 001.232-1.847zM55.497 24.418H42.844a10.634 10.634 0 00-5.914 1.79 1.11 1.11 0 001.232 1.847 8.414 8.414 0 014.682-1.417h12.653a1.11 1.11 0 000-2.22zM29.66 31.758a10.632 10.632 0 00-5.908-1.79H11.099a1.11 1.11 0 100 2.22h12.653a8.409 8.409 0 014.682 1.417 1.11 1.11 0 001.232-1.847zM55.497 29.968H42.844a10.634 10.634 0 00-5.914 1.79 1.11 1.11 0 001.232 1.847 8.414 8.414 0 014.682-1.417h12.653a1.11 1.11 0 000-2.22zM23.752 35.518H11.099a1.11 1.11 0 100 2.22h12.653a8.409 8.409 0 014.682 1.417 1.11 1.11 0 101.232-1.847 10.632 10.632 0 00-5.914-1.79zM55.497 35.518H42.844a10.634 10.634 0 00-5.914 1.79 1.11 1.11 0 001.232 1.847 8.414 8.414 0 014.682-1.417h12.653a1.11 1.11 0 000-2.22z" />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgReader);
export default ForwardRef;
