import React from 'react';
import { Tooltip } from '../../../../components/common';
import { SVGImage } from '../../../../lib';

export function cx(...classes: (string | boolean)[]) {
  return classes.filter((s) => s).join(' ');
}

type toolbarElementProps = {
  tooltipText?: string;
  icon: SVGImage;
  disabled?: boolean;
  active?: boolean;
  onClick: () => void;
  color?: string;
  bgActiveColor?: string;
  size?: 'md' | 'sm' | 'xs';
  tooltipStyle?: React.CSSProperties;
};

export const ToolbarElement = ({ onClick, color, tooltipText, icon: Icon, disabled = false, active = false, size, bgActiveColor, tooltipStyle }: toolbarElementProps) => {
  return (
    <Tooltip conStyle={{ height: '80%' }} text={tooltipText} hidden={tooltipText ? false : true} tooltipStyle={tooltipStyle}>
      <button
        onMouseDown={(e) => {
          e.preventDefault();
          onClick();
        }}
        disabled={disabled}
        className={cx('toolbar-icon-con', active && 'active')}
        style={bgActiveColor && active ? { backgroundColor: bgActiveColor } : {}}
      >
        <Icon className={disabled ? 'toolbar-icon toolbar-icon-con-disabled' + ' ' + size : 'toolbar-icon' + ' ' + size} style={{ fill: color }} />
      </button>
    </Tooltip>
  );
};
