import {API, ApplicationForm, Campaign, FormSubmission, Group} from '@base/core';
import {Form, getBudgetFromDocument} from '@editors/form-editor';
import {faCommentLines} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Drawer,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme
} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {combineLatest} from 'rxjs';
import {useObservable} from 'rxjs-hooks';
import {map, switchMap} from 'rxjs/operators';
import {useURLQuery} from '../../lib/hooks/useQuery';
import {useSettings} from '../../theme/ThemeProvider';
import {Loading} from '../auth';
import {useUpdateFormContentMutation} from '../externalUsers/hooks/mutations';
import {Kanban} from '../kanban/Kanban';
import {FormSubmissionDrawer} from './FormSubmissionDrawer';
import {useGetFormSubmissions} from './hooks/useGetFormSubmissions';
import {sendAcceptedNotification} from './sendAcceptedNotification';

export function FormSubmissions({campaign, forms, groups}: { campaign: Campaign; forms: Form[]; groups: Group[] }) {
  const {data: submissions = []} = useGetFormSubmissions(campaign.id);
  const history = useHistory();
  const theme = useTheme();
  const {t} = useTranslation();

  const updateSubmissionMutation = useUpdateFormContentMutation(true);

  const settings = useSettings();
  const [selections, setSelections] = useState([]);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<string>(null);
  const query = useURLQuery();
  const selectedFormId = query.get('form-id') || (forms[0]?.id ?? null);

  const setSelectedFormId = useCallback(
    (formId: string) => {
      query.set('form-id', formId);
      history.replace('?' + query.toString());
    },
    [history, query],
  );
  const me = useSelector((state: Core.StateType) => state.auth.user);

  const unreadCount = useObservable<{ [formId: string]: number }, [FormSubmission[]]>(
    (state$, input$) =>
      input$.pipe(
        switchMap(([submissions]) => {
          return combineLatest(
            submissions.map((s) => {
              const chatId = 'formsubmissions:' + s.id;
              return API.chats.getChatObservable(chatId).pipe(map((chatItems) => ({
                formId: s.formId,
                chats: chatItems
              })));
            }),
          );
        }),
        map((chats) => {
          return chats.reduce((map, chatInfo) => {
            const unreadCount = chatInfo.chats.reduce((acc, chat) => acc + (chat.read[me.id] ? 0 : 1), 0);
            if (map[chatInfo.formId]) map[chatInfo.formId] += unreadCount;
            else map[chatInfo.formId] = unreadCount;
            return map;
          }, {});
        }),
      ),
    {},
    [submissions],
  );
  const selectedForm = forms.find((f) => f.id === selectedFormId);
  if (!selectedForm) return <Loading small/>;
  return (
    <>
      <Drawer PaperProps={{sx: {width: 550}}} anchor="right" open={Boolean(selectedSubmissionId)}
              onClose={() => setSelectedSubmissionId(null)}>
        <FormSubmissionDrawer groups={groups} content={submissions.find((s) => s.id === selectedSubmissionId)}
                              form={selectedForm as ApplicationForm} onClose={() => setSelectedSubmissionId(null)}/>
      </Drawer>
      <Stack display="flex" flex={1} paddingTop={4} paddingRight={4} paddingLeft={1} spacing={2} overflow={"hidden"}>
        <Stack direction="row" spacing={2} marginBottom={6} alignItems="center">
          <Select value={selectedFormId} onChange={(e) => setSelectedFormId(e.target.value)} variant={'outlined'}>
            {forms.map((f) => (
              <MenuItem key={f.id} value={f.id}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1} maxHeight={"52vw"} overflow={"auto"}>
                  <ListItemText>{f.name}</ListItemText>
                  <ListItemIcon>
                    <Box
                      sx={{
                        minWidth: 16,
                        height: 16,
                        backgroundColor: theme.palette.error.main,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 50,
                        padding: 0.4,
                        paddingX: 1,
                        marginRight: -2,
                        marginLeft: 1,
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '600',
                        visibility: unreadCount[f.id] ? undefined : 'hidden',
                      }}
                    >
                      <Typography variant="subtitle2">
                        <FontAwesomeIcon style={{marginRight: 6}} icon={faCommentLines}/>
                        {unreadCount[f.id]}
                      </Typography>
                    </Box>
                  </ListItemIcon>
                </Stack>
              </MenuItem>
            ))}
          </Select>
          <Box sx={{flex: 1}}/>
          <Button
            variant="contained"
            disabled={selections.length === 0}
            onClick={() => {
              history.push(`/campaigns/${campaign.id}/forms/${selectedFormId}/compare?` + selections.map((s) => 'id=' + s).join('&'));
            }}
          >
            {t('compare')}
          </Button>
        </Stack>
        <div style={{display: "flex", maxHeight: `calc(100vh - 400px)`, width: "100%", overflow: "auto", paddingLeft:16, paddingRight:16}}>
          <Kanban
            submissions={submissions
              .filter((s) => s.formId === selectedFormId && s)
              .map((submission) => {
                const group = groups.find((g) => g.id === submission.entityId);
                if (!group) return null;
                return {
                  name: group.name,
                  icon: settings.namedGroups.find((n) => n.name === group.type).getIcon?.(group),
                  id: submission.id,
                  groupId: submission.entityId,
                  budget: getBudgetFromDocument(selectedForm, submission.data),
                  ...submission,
                };
              })
              .filter((g) => g)}
            onItemClick={(item) => {
              console.log('clicked:', item);
              setSelectedSubmissionId(item.id);
            }}
            onSelectionChange={setSelections}
            selection={selections}
            onStatusChange={async (status, item) => {
              await updateSubmissionMutation.mutateAsync({
                campaignId: campaign.id,
                formId: selectedFormId,
                groupId: item.groupId,
                data: {state: status as any}
              });

              if (status === 'success' || status === 'rejected') {
                await sendAcceptedNotification(item.entityId, status === 'success' ? 'accept' : 'reject', undefined, item.campaignId, item.formId, item.name);
              }
            }}
          />
        </div>
      </Stack>
    </>
  );
}
