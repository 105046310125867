import { RESET } from "./auth/types";

export type FetchingType = {
  fetching: boolean;
  success: boolean;
  error: any;
};

export const createFetching = (): FetchingType => ({
  error: null,
  fetching: true,
  success: false,
});
export const createDefault = (): FetchingType => ({
  error: null,
  fetching: false,
  success: false,
});
export const createSuccess = (): FetchingType => ({
  error: null,
  fetching: false,
  success: true,
});
export const createError = (error: any): FetchingType => ({
  error: error.message||error,
  fetching: false,
  success: false,
});

export function removeKey<T extends { [key: string]: R }, R>(
  value: T,
  key: string
) {
  const { [key]: discard, ...rest } = value;
  return rest;
}

export function removeKeys<T extends { [key: string]: R }, R>(
  value: T,
  keys: string[]
) {
  return keys.reduce((val, key) => removeKey(val, key) as any, value);
}

export function addKeys<T extends { [key: string]: R }, R>(
  object: T,
  keys: string[],
  value: any
) {
  return keys.reduce((val, key) => ({ ...val, [key]: value }), object);
}

export function resettable<T, A, R>(
  reducer: (state: T, action: A) => R
) {
  return (state: T, action: A): R => {
    if ((action as any).type == RESET) {
      return reducer(undefined as any, {} as A);
    } else {
      return reducer(state, action);
    }
  };
}
