
import { Renderer } from '../entities/Renderer'
import { CheckboxQuestionRenderer } from './CheckboxQuestionRenderer'
import { DateRangeRenderer } from './DateRangeRenderer'
import { DateRenderer } from './DateRenderer'
import { DropdownQuestionRenderer } from './DropdownQuestionRenderer'
import { LinearScaleRenderer } from './LinearScaleRenderer'
import { MultilineQuestionRenderer } from './MultilineQuestionRenderer'
import { MultipleChoiceQuestionRenderer } from './MultipleChoiceQuestionRenderer'
import { NumberRenderer } from './NumberRenderer'
import { PhoneNumberRenderer } from './PhoneNumberRenderer'
import { ProvideFilesRenderer } from './ProvideFilesRenderer'
import { QuestionRenderer } from './QuestionRenderer'
import { RequestFilesRenderer } from './RequestFilesRenderer'
import { TableRenderer } from './TableRenderer'
import * as TYPES from './types'

export type ValueOf<T> = T[keyof T]
export const Renderers: { [key: string]: Renderer<any, any> } = {

  // ----- Text ------
  [TYPES.SHORT_ANSWER]: QuestionRenderer,
  [TYPES.LONG_PARAGRAPH]: MultilineQuestionRenderer,
  [TYPES.NUMBER]: NumberRenderer,
  [TYPES.PHONE_NUMBER]: PhoneNumberRenderer,


  // ----- Selction ------
  [TYPES.MULTIPLE_CHOICE_QUESIONS]: MultipleChoiceQuestionRenderer,
  [TYPES.CHECKBOXES]: CheckboxQuestionRenderer,
  [TYPES.DROP_DOWN_MENU]: DropdownQuestionRenderer,


  // ----- Special ------
  [TYPES.LINEAR_SCALE]: LinearScaleRenderer,
  [TYPES.DATE]: DateRenderer,
  [TYPES.DATE_RANGE]: DateRangeRenderer,
  [TYPES.PROVIDE_FILES]: ProvideFilesRenderer,
  [TYPES.FILE_UPLOAD]: RequestFilesRenderer,
  [TYPES.TABLE]: TableRenderer,
}

