import { ChatItem, IChatService } from '@base/core';
import { collectionData } from 'rxfire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { firebase } from '../config';



export class ChatService implements IChatService {
  timeStamp() {
    return firebase.firestore.FieldValue.serverTimestamp()
  }

  private getChatCollection(chatId: string) {
    return firebase.firestore().collection(`chats/${chatId}/chat`)
  }

  getChatObservable(chatId: string): Observable<ChatItem[]> {
    const chatcol = this.getChatCollection(chatId);
    return collectionData<ChatItem>(chatcol, "id").pipe(map((chatItems) => chatItems.map(m => this.convertItemToChatItem(m))));
  }

  async readChatItems(chatId: string, chatIds: string[], uid: string): Promise<void> {
    const batch = firebase.firestore().batch();
    const col = this.getChatCollection(chatId)
    for (const chatId of chatIds) {
      batch.update(col.doc(chatId), { [`read.${uid}`]: this.timeStamp() })
    }
    await batch.commit();
  }

  async createChatItem(chatId: string, chatItem: Omit<ChatItem, 'read'>): Promise<void> {
    const col = this.getChatCollection(chatId)
    const newItem: ChatItem = { ...chatItem, dateSent: this.timeStamp() as any, read: { [chatItem.creator]: this.timeStamp() as any } }
    await col.add(newItem)
  }

  private convertObjectOfTimeStampsToDates(o: { [key: string]: firebase.default.firestore.Timestamp }) {
    const ret: { [key: string]: Date } = {};
    for (const [key, v] of Object.entries(o ?? {})) {
      ret[key] = v?.toDate() ?? new Date();
    }
    return ret
  }

  private convertItemToChatItem(item: any): ChatItem {
    const chatitem = { ...item };

    chatitem.read = this.convertObjectOfTimeStampsToDates(chatitem.read);
    chatitem.dateSent = chatitem.dateSent?.toDate() ?? new Date();

    return chatitem;
  }
}
