import { FileProvider, Form, FormEditor, FormViewer, FormViewerPDF, MockFileProvider } from '@editors/form-editor';
import { faBallotCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faClock, faCopy, faEdit, faEye, faPrint, faSave, faSpinnerThird, faTimes, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faFolders } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, Button, Chip, Dialog, Skeleton, Stack, TextField, Theme, Toolbar, Tooltip, Typography, useTheme } from '@material-ui/core';
import { LoadingButton, StaticDatePicker } from '@material-ui/lab';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import cuid from 'cuid';
import { format } from 'date-fns';
import React, { MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import { ConditionalRender, usePopup } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import { styled } from '@material-ui/styles';
import { useAutoUpdateState } from '../fileManager/useAutoUpdateState';
import { ApplicationForm } from '../../../../core/src';
import { useTheme as useOldTheme } from 'libs/base/web/src/theme';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarRoot: {
      background: theme.palette.background.paper,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[6],
    },
  }),
);

const IconButtonRect = withStyles({
  root: {
    padding: '8px !important',
    minWidth: '46px !important',
    marginLeft: '12px !important',
  },
  startIcon: {
    marginRight: '-4px !important',
    '& :nth-of-type(1)': {
      fontSize: '28px !important',
    },
  },
})(Button);

interface FormEditorViewProps {
  onSave(form: ApplicationForm, create?: boolean): void | Promise<void>;
  onClose(): void;
  campaignName: string;
  isLoading?: boolean;
  form?: ApplicationForm;
  onDelete(formId: string): void | Promise<void>;
  fileProvider: FileProvider;
}

const Offset = styled('div')(({ theme }: any) => theme.mixins.toolbar);

export function FormEditorView({ campaignName, form, onClose, onSave, fileProvider, isLoading, onDelete }: FormEditorViewProps) {
  const theme = useTheme();
  const oldTheme = useOldTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [saveHandlePressed, setSaveHandlePressed] = useState(false);
  const [document, setDocument] = useAutoUpdateState<ApplicationForm>(
    form ??
      ({
        name: t('untitled-form'),
        description: t('provide-a-description'),
        content: [{ content: 'text', contentType: 'text', id: cuid(), required: false, title: 'Untitled Question', type: 'short-answer' }],
        published: false,
        id: cuid(),
      } as any),
    [form],
  );
  const [preview, setPreview] = useState(false);
  const [savePending, setSavePending] = useState(false);
  const [openDeletePopup, setopenDeletePopup] = useState(false);
  const [deadlineOpen, setDeadlineOpen] = useState(false);
  const [deadlineDate, setDeadlineDate] = useState(form?.deadline);

  const history = useHistory();
  const notify = usePopup();
  const showSubmitMessage = () => {
    notify({
      title: t('submit-placeholder'),
      text: t('a-preview-form-can-not-be-submitted'),
      type: 'info',
    });
  };
  const deadlineRef = useRef(null);

  const handleSave = async () => {
    try {
      setSavePending(true);
      setSaveHandlePressed(true);
      await onSave(document);
      notify({
        title: t('saved'),
        type: 'success',
      });
    } catch (error) {
      notify({
        title: t('error'),
        type: 'error',
        text: error.message,
      });
    } finally {
      setSavePending(false);
    }
  };

  const editorRef = useRef() as MutableRefObject<HTMLDivElement>;
  const formViewerRef = useRef<{ print: () => void }>(null);

  const mockFileProvider = new MockFileProvider();

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Prompt when={form !== document && (form !== undefined || !saveHandlePressed)} message={() => t('are-you-sure-you-want-to-leave-without-saving') as string} />
      <AppBar>
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <Stack margin={2} direction="row" spacing={2} flex={1} alignItems="center">
            {/*<FontAwesomeIcon icon={faBallotCheck} size="4x" color={theme.palette.primary.light} />*/}
            <oldTheme.images.logo style={{height: 56, width: 'auto', marginRight: 8}} fill={theme.palette.primary.main}/>
            <Box>
              <Typography variant="h5">{isLoading ? <Skeleton width={200} /> : document.name || 'Untitled'}</Typography>
              <Chip label={campaignName} icon={<FontAwesomeIcon icon={faFolders} />} style={{ paddingLeft: 4 }} onClick={onClose} />
            </Box>
            <Stack direction="row" spacing={2} flex={1} alignItems="center" justifyContent="flex-end">
              {form && (typeof form.deadline === 'number' || form.deadline === undefined) && (
                <Tooltip title={t('deadline')}>
                  <Button
                    variant="contained"
                    size="medium"
                    startIcon={<FontAwesomeIcon icon={faClock} fixedWidth style={{ height: 24 }} />}
                    style={{ color: theme.palette.grey[700], backgroundColor: theme.palette.grey[200], height: 46 }}
                    sx={{ paddingX: 2 }}
                    onClick={() => {
                      setDeadlineOpen(true);
                    }}
                  >
                    {form?.deadline ? format(new Date(form?.deadline as number), t('full-date-mask')) : 'select deadline'}
                  </Button>
                </Tooltip>
              )}
              <ConditionalRender render={Boolean(form)}>
                <Dialog
                  open={deadlineOpen}
                  onClose={() => {
                    setDeadlineOpen(false);
                  }}
                  PaperComponent={Box}
                  PaperProps={{ sx: { borderRadius: 1 } }}
                >
                  <StaticDatePicker
                    value={(form?.deadline as any) ?? null}
                    mask={t('full-date-mask')}
                    open={deadlineOpen}
                    onChange={(newValue: Date) => {
                      setDeadlineDate(newValue.valueOf());
                    }}
                    renderInput={(params) => {
                      return <TextField {...params} />;
                    }}
                  />
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      await onSave({ ...document, deadline: deadlineDate });
                      setDeadlineOpen(false);
                    }}
                  >
                    {t('save')}
                  </LoadingButton>
                  <Button variant="contained" color="error" onClick={() => setDeadlineOpen(false)}>
                    {t('cancel')}
                  </Button>
                </Dialog>
              </ConditionalRender>
              <ConditionalRender render={Boolean(form)}>
                <Tooltip title={t('delete')}>
                  <IconButtonRect
                    size="medium"
                    startIcon={<FontAwesomeIcon icon={faTrash} fixedWidth style={{ height: 24 }} />}
                    style={{ color: theme.palette.grey[700], backgroundColor: theme.palette.grey[200], height: 46, width: 46 }}
                    variant="contained"
                    onClick={() => {
                      setopenDeletePopup(true);
                    }}
                  />
                </Tooltip>
              </ConditionalRender>
              <Tooltip title={t('print')}>
                <IconButtonRect
                  size="medium"
                  startIcon={<FontAwesomeIcon icon={faPrint} fixedWidth style={{ height: 24 }} />}
                  style={{ color: theme.palette.grey[700], backgroundColor: theme.palette.grey[200], height: 46, width: 46 }}
                  variant="contained"
                  onClick={() => {
                    formViewerRef.current.print();
                  }}
                />
              </Tooltip>
              <Tooltip title={preview ? t('edit') : t('preview')}>
                <IconButtonRect
                  size="medium"
                  startIcon={<FontAwesomeIcon icon={preview ? faEdit : faEye} style={{ width: 28 }} fixedWidth />}
                  style={{ color: theme.palette.grey[700], backgroundColor: theme.palette.grey[200], height: 46, width: 46 }}
                  variant="contained"
                  onClick={() => setPreview((p) => !p)}
                />
              </Tooltip>
              <Tooltip title={t('copy')}>
                <IconButtonRect
                  size="medium"
                  startIcon={<FontAwesomeIcon icon={faCopy} fixedWidth style={{ height: 24 }} />}
                  style={{ color: theme.palette.info.lighter, backgroundColor: theme.palette.info.main, height: 46, width: 46 }}
                  variant="contained"
                  onClick={async () => {
                    const id = cuid();
                    await onSave({ ...document, id, name: document.name + ' copy' }, true);
                    history.replace(id);
                  }}
                />
              </Tooltip>
              <Tooltip title={t('close')}>
                <IconButtonRect
                  size="medium"
                  startIcon={<FontAwesomeIcon icon={faTimes} />}
                  style={{ color: theme.palette.error.main, backgroundColor: theme.palette.error.lighter, height: 46, width: 46 }}
                  variant="contained"
                  onClick={async () => {
                    onClose();
                  }}
                />
              </Tooltip>
              <Tooltip title={t('save')}>
                <IconButtonRect
                  size="medium"
                  disabled={savePending}
                  startIcon={<FontAwesomeIcon icon={savePending ? faSpinnerThird : faSave} spin={savePending} />}
                  style={{ color: theme.palette.success.main, backgroundColor: theme.palette.success.lighter, height: 46, width: 46 }}
                  variant="contained"
                  onClick={handleSave}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
      <MainDialog
        open={openDeletePopup}
        onCloseClick={() => setopenDeletePopup(false)}
        onSaveClick={async () => {
          await onDelete(document.id);
        }}
        modalTitle={t('delete-form-title')}
        description={t('sure-to-delete-form')}
        buttonText={t('delete')}
      />
      {preview ? (
        <FormViewer
          document={{ ...document, content: document.content.map((c) => ({ ...c, required: false })) }}
          fileProvider={fileProvider}
          uploadFileProvider={mockFileProvider}
          onSubmit={() => Promise.resolve(showSubmitMessage())}
          onSave={async () => null}
        />
      ) : (
        <FormEditor fileProvider={fileProvider} document={document} onChange={setDocument as any} isLoading={isLoading} />
      )}
      <FormViewerPDF document={document} uploadFileProvider={fileProvider} value={{ data: {} }} ref={formViewerRef} showInformationFields={false} />
    </Box>
  );
}
