import { FormSubmission, FormWithReports, Group } from '@base/core';
import { Form } from '@editors/form-editor';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Chip, Stack, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, GridLinkOperator, XGrid } from '@material-ui/x-grid';
import React, { useCallback, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ContentHeader } from '../../components/common';
import { SearchInput } from '../../components/inputs';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { useSettings } from '../../theme/ThemeProvider';
import { useDebouncedState } from '../users';
import { useGetGroups } from '../users/hooks/useGetGroups';
import { GridNoResultsOverlay, GridNoRowsOverlay } from '../../components/content/GridContent';

interface ExternalFormProps {
  forms: FormWithReports[];
  parents: Core.VirtualFile[];
  baseUrl: string;
  loading?: boolean;
  onFormDoubleClick(forms: Form): void;
}

const useStyles = makeStyles({
  root: (theme: any) => ({
    flex: 1,
    height: 'auto',
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
  lastCell: {
    minWidth: '300px !important',
    position: 'absolute',
    left: 0,
    zIndex: 10,
    transform: 'translateX(calc(100vw - 100% - 24px))',
    justifyContent: 'flex-end',
  },
});

function getColor(status: FormSubmission['state'] | 'not-started') {
  switch (status) {
    case 'draft':
      return 'warningAction';
    case 'rejected':
      return 'errorAction';
    case 'submitted':
      return 'infoAction';
    case 'success':
      return 'successAction';
    case 'review':
      return 'warningAction';
    default:
      return 'default';
  }
}

function getName(status: FormSubmission['state'] | 'not-started') {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'rejected':
      return 'Rejected';
    case 'submitted':
      return 'Submitted';
    case 'success':
      return 'Accepted';
    case 'review':
      return 'In Review';
    default:
      return 'default';
  }
}
function columns(openGroup: (groupId: string) => void, t: TFunction<'translation'>): GridColDef[] {
  return [
    {
      field: 'country',
      width: 55,
      renderCell: ({ formattedValue, value, row }) => {
        const settings = useSettings();
        const namedGroup = settings.namedGroups.find((namedGroup) => namedGroup.name === row.type);
        return namedGroup?.getIcon && namedGroup.getIcon(row as Group) ? (
          <img src={namedGroup.getIcon(row as Group)} alt="flag" style={{ borderRadius: '50%', height: 28 }} />
        ) : (
          <div style={{ borderRadius: '50%', height: 20, width: 20, backgroundColor: 'grey' }} />
        );
      },
    },
    {
      field: 'name',
      width: 275,
      headerName: 'Name of Group',
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    {
      field: 'type',
      width: 100,
      headerName: 'Type',
      renderCell: ({ formattedValue, value = 'federation', row }) => {
        return <Typography variant="subtitle1">{formattedValue}</Typography>;
      },
      valueGetter: ({ value }) => (value ? t((value as string) + '-short') : 'other'),
    } as GridColDef,
    {
      field: 'formSubmissions',
      width: 300,
      headerName: 'Submissions',
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        return <Chip label={t('application-counted', { count: Object.keys(value ?? {}).length })} />;
      },
    },
    {
      field: 'edit',
      flex: 1,
      align: 'right',
      type: 'date',
      disableColumnMenu: true,
      renderCell: ({ formattedValue, value, row }) => {
        return (
          <Button
            sx={{ borderRadius: 999, color: 'info.main', backgroundColor: 'info.lighter', ':hover': { bgcolor: 'info.hover' } }}
            onClick={(e) => {
              e.stopPropagation();
              openGroup(row.id);
            }}
            style={{ marginRight: 14 }}
            variant="contained"
            endIcon={<FontAwesomeIcon fixedWidth icon={faEdit} />}
          >
            {t('view-activity')}
          </Button>
        );
      },
    },
  ].map(
    (def) =>
      ({
        ...def,
        renderHeader: (params) => {
          return (
            <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
              {params.colDef.headerName}
            </Typography>
          );
        },
      } as any),
  );
}
export function CampaignsByFederationOverview() {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles(theme);

  const [filterModel, setFilterModel, fastFilterModel] = useDebouncedState<GridFilterModel>();

  const { data: _groups = [], isLoading: loading } = useGetGroups();
  const openGroupSubmissions = useCallback((groupId: string) => history.push(`/nf-activity/${groupId}`), [history]);

  const groups = useMemo(() => _groups.filter((group) => group.formSubmissions), [_groups]);
  const cols = useMemo(() => columns(openGroupSubmissions, t), [openGroupSubmissions]);

  return (
    <ContentContainer>
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.nfActivity]}></NavbarSmall>
      <ContentView noPadding>
        <ContentHeader noBack title={t('groups')} subtitle={t('groups-counted', { count: groups?.length })}>
          <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
            <Box flex={1}>
              <SearchInput
                variant="outlined"
                size="small"
                InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
                placeholder={t('search-groups')}
                fullWidth
                value={fastFilterModel?.items?.find((i) => i.columnField == 'name')?.value ?? ''}
                onChange={(e) => {
                  try {
                    new RegExp(e.target.value);
                    setFilterModel({
                      items: [
                        { columnField: 'name', operatorValue: 'contains', value: e.target.value },
                        { columnField: 'type', operatorValue: 'contains', value: e.target.value },
                      ],
                      linkOperator: GridLinkOperator.Or,
                    });
                  } catch {}
                }}
              />
            </Box>
          </Stack>
        </ContentHeader>
        <XGrid
          className={classes.root}
          sortModel={[{ field: 'name', sort: 'asc' }]}
          headerHeight={40}
          filterModel={filterModel}
          onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
          disableSelectionOnClick
          onRowClick={(params) => openGroupSubmissions(params.row.id)}
          columns={cols}
          rows={groups}
          loading={loading}
          components={{
            NoRowsOverlay: GridNoRowsOverlay,
            NoResultsOverlay: GridNoResultsOverlay,
          }}
        />
      </ContentView>
    </ContentContainer>
  );
}
function getCurrentReportFromForm(form: FormWithReports): FormWithReports | undefined {
  for (const r of form.reports ?? []) {
    const report = r as FormWithReports;
    if (report.status !== 'rejected' && report.status !== 'success') return report;
    if (report.status === 'rejected') return undefined;
  }
  return undefined;
}
