import React, { useRef, useState } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface pictogramChooserProps {
  gridColumnSpan?: number;
  gridRowSpan?: number;
}

export const ColorChooser = ({ gridColumnSpan = 1, gridRowSpan = 4 }: pictogramChooserProps) => {
  const containerREf = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const Colors = [
    'code',
    'books',
    'book',
    'analytics',
    'alarm-clock',
    'book-user',
    'calendar',
    'phone',
    'award',
    'user',
    'user-check',
    'user-cog',
    'user-alt',
    'ballot',
    'check',
    'barcode',
    'book-open',
    'briefcase',
    'bookmark',
  ];

  return (
    <div id="w-node-175bae809898-c87a1d61" className="grid-con">
      <div className="inner-grid-con">
        <div className="grid-con-title">Farbe</div>
        <div className="piktogram-con" ref={containerREf}>
          {Colors.map((color, i) => (
            <div onClick={() => setSelectedIndex(i)} className="grid-circle-button color-btn" style={{ backgroundColor: color }} />
          ))}
        </div>
        <div className="slider-arrow-con" style={{ alignItems: 'center' }}>
          <FontAwesomeIcon icon={faChevronLeft} className="scroll-arrow-icon no-user-select" style={{ marginRight: 28 }} onClick={() => containerREf.current?.scrollBy({ left: -48 })} />
          <FontAwesomeIcon icon={faChevronRight} className="scroll-arrow-icon no-user-select" onClick={() => containerREf.current?.scrollBy({ left: 48 })} />
        </div>
      </div>
    </div>
  );
};
