import { AccessRequestDoc, AccessRequestFileProps, AccessRequestFormData, API, StandardPopup, usePopup } from '@base/web';
import { Card, CardContent, Grid, Stack, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useTheme } from '../../../../base/web/src/theme/ThemeProvider';
import { RequestFormContentProps } from '../form-contents/RequestFormContentProps';
import { AppealRequest } from './AppealRequest';
import { RequestForm } from './RequestForm';
import { VerifyRequest } from './VerifyRequest';

export type RequestPopupProps = {
  active: boolean;
  setActive;
  topic: string;
  name: string;
  description: string;
  loading?: boolean;
  config: {
    readOnly?: boolean;
    changeDataMode?: boolean;
    rejectAcceptMode?: boolean;
    requestMode?: boolean;
  };
  formData?: AccessRequestDoc;
  appeal?: (message: string) => void;
  verify?: () => Promise<any>;
  onSave?: (data: AccessRequestDoc) => Promise<any>;
  onDeleteFiles?: (files: AccessRequestFileProps[]) => Promise<void>;

  getSchema: (t: TFunction<'translation'>) => any;
  getInitialValues: (formData?: any) => any;
  FormContent: (props: RequestFormContentProps) => JSX.Element;
};

/**
 * The customer logo is set via the si theme provider
 * @param active open state
 * @param setActive set open state
 * @param readOnly specify if the form is only readable and the file button a downloadlink (true) or file picker (false)
 * @param topic the first bold text - should explain in one word what can be done here
 * @param name the normal text beside the topic - should tell which data is accessed with that topic
 * @param description the text below topic and name - should give a more detailed description
 * @param appeal the function call for the appeal button from the readonly mode - otherwise a submit button is shown
 * @param verify the function call for the verify button from the readonly mode - otherwise a submit button is shown
 */
export const RequestPopup = ({
  active,
  setActive,
  config = { readOnly: false, changeDataMode: false, rejectAcceptMode: false, requestMode: false },
  topic,
  name,
  description,
  appeal,
  verify,
  formData = undefined,
  loading = false,
  onSave,
  onDeleteFiles,
  FormContent,
  getInitialValues,
  getSchema,
}: RequestPopupProps) => {
  const [appealConfirmOpen, setAppealConfirmOpen] = useState(false);
  const [verifyConfirm, setVerifyConfirm] = useState(false);

  const notification = usePopup();
  const { t } = useTranslation();
  const theme = useTheme();
  // const updateGroupMutation = useUpdateGroupMutation();
  const submitFormData = async (data: AccessRequestFormData) => {
    try {
      if (config.changeDataMode) {
        const newFilePromisses =
          data?.files?.map(async (file) => {
            return API.accessRequest.uploadAccessRequestFile(file);
          }) ?? [];
        const addFileArray = (formData?.files ?? []).concat(await Promise.all(newFilePromisses)) as AccessRequestFileProps[];
        const { files, ...rest } = data;
        //the id and timestand dont need to be given because they should not be changed
        //@ts-ignore
        await onSave?.({ files: addFileArray ?? [], ...rest });
        notification({ title: t('saved'), type: 'success' });
      } else {
        await API.accessRequest.sendAccessRequest(data);
        notification({ title: t('successfully-sent'), text: t('you-have-successfully-applied-for-registration'), type: 'success' });
      }
    } catch (error) {
      notification({ title: t('error'), text: error, type: 'error' });
    }
    setActive(false);
  };

  return (
    <StandardPopup onBackdropClick={() => (config.requestMode ? {} : setActive(false))} onCloseClick={() => setActive(false)} visible={active}>
      <AppealRequest
        // type={formData?.type ?? 'federation'}
        setActive={() => setAppealConfirmOpen(false)}
        active={appealConfirmOpen}
        loading={loading}
        appeal={(message) => {
          appeal(message);
          setActive(false);
        }}
      />
      <VerifyRequest
        setActive={() => setVerifyConfirm(false)}
        type={formData?.type ?? 'federation'}
        active={verifyConfirm}
        loading={loading}
        verify={async () => {
          await verify();
          setActive(false);
        }}
      />
      <Card css={{ maxWidth: 900 }}>
        <CardContent style={{ maxWidth: 900 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                {topic && !config.changeDataMode && (
                  <Typography variant="h3" fontWeight={500} fontSize="2rem">
                    {topic}
                  </Typography>
                )}
                {name && !config.changeDataMode && (
                  <Typography variant="h3" fontWeight={400} fontSize="2rem">
                    {name}
                  </Typography>
                )}
                {config.changeDataMode && (
                  <Typography sx={{ marginBottom: 2 }} variant="h2" fontWeight={600} fontSize="2rem">
                    {formData?.organizationName}
                  </Typography>
                )}
              </Stack>
            </Grid>
            {description && !config.changeDataMode && (
              <Grid item xs={12} marginBottom={2}>
                <Typography variant="body1">{description}</Typography>
              </Grid>
            )}
          </Grid>
          <RequestForm
            onSubmit={async (data) => submitFormData(data)}
            appeal={() => setAppealConfirmOpen(true)}
            verify={() => setVerifyConfirm(true)}
            formData={formData}
            config={config}
            onDeleteFiles={onDeleteFiles}
            FormContent={FormContent}
            getSchema={getSchema}
            getInitialValues={getInitialValues}
          />
        </CardContent>
      </Card>
    </StandardPopup>
  );
};
