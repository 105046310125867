import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import EmptyState from '../../../assets/universal/new-empty-state.svg';

export function GridNoRowsOverlay() {
  const theme = useTheme();
  return (
    <div style={{ position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 5, padding: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <img src={EmptyState} style={{ width: 175 }} />
        <Typography variant="h2" style={{ marginTop: 20 }}>
          Nothing here.
        </Typography>
        <Typography variant="subtitle1" textAlign="center" style={{ marginTop: 4 }}>
          Currently there is nothing to show.
        </Typography>
      </Box>
    </div>
  );
}
