import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';
import { useTheme } from '../../../theme/ThemeProvider';
import { useAutoUpdateState } from "../../../views/fileManager/useAutoUpdateState";
import { DefaultInputBase } from '../../common';
import { extractJaggedArrayFromSheetCols, PERMISSIVE_MAIL_REGEX } from './helpers';

export function ColumnSelector({ sheet, onSelect }: { sheet: XLSX.Sheet; onSelect: (headers: string[], rows: string[][]) => void }) {
  const theme = useTheme();
  const { t } = useTranslation();
  let headerOptions = [
    { value: 'email', text: t('email') },
    { value: 'abteilung', text: t('department') },
    { value: 'address', text: t('address') },
    { value: 'firstname', text: t('firstname') },
    { value: 'lastname', text: t('lastname') },
    { value: 'jobTitle', text: t('job-description') },
    { value: 'mobile', text: t('mobile-number') },
    { value: 'phoneNumber', text: t('phone-number') },
    ...(theme.productSettings.learningmodules ? [{ value: 'currentModules', text: t('current-module') }] : []),
  ];

  const [rows, setRows] = useAutoUpdateState(() => extractJaggedArrayFromSheetCols(sheet), [sheet]);
  const [headers, setHeaders] = useAutoUpdateState(() => createInitialHeaders(rows, headerOptions), [sheet]);

  console.log('🚀 ~ file: ColumnSelector.tsx ~ line 56 ~ ColumnSelector ~ headers', headers);
  let continueEnabled = headers.includes('email') && headers.includes('firstname');

  return (
    <div>
      <div style={{ flex: 1, overflow: 'auto', maxHeight: '70vh' }}>
        <Table>
          <TableHead>
            <TableCell />
            {headers.map((val, i) => {
              headerOptions = headerOptions.filter((h) => h.value != headers[i - 1]);
              return (
                <TableCell key={i}>
                  <Select variant="filled" value={val} input={<DefaultInputBase />} defaultValue={0} onChange={(e) => setHeaders((headers) => headers.map((h, j) => (j == i ? e.target.value : h)))}>
                    <MenuItem value={0}>{t('assign-column')}</MenuItem>
                    {headerOptions.map((h) => (
                      <MenuItem key={h.value} value={h.value}>
                        {h.text}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            {rows.map((column, rowIndex) => (
              <TableRow key={column[0] + rowIndex}>
                <TableCell onClick={() => setRows((r) => r.filter((_, index) => index != rowIndex))}>
                  <FontAwesomeIcon icon={faTrash} color={theme.colors.error} />
                </TableCell>
                {column.map((value, columnIndex) => {
                  if (headers[columnIndex] == 'email') {
                    if (!PERMISSIVE_MAIL_REGEX.test(value)) {
                      var mailNotValid = true;
                      continueEnabled = false;
                    }
                  }
                  return (
                    <TableCell key={columnIndex}>
                      <Input
                        value={value}
                        error={mailNotValid}
                        onChange={(e) => setRows(rows.map((col, _rowIndex) => (_rowIndex == rowIndex ? col.map((_item, _colIndex) => (_colIndex == columnIndex ? e.target.value : _item)) : col)))}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Button variant="contained" disabled={!continueEnabled} style={{ marginTop: 20 }} onClick={() => onSelect(headers as string[], rows)}>
        {t('continue')}
      </Button>
    </div>
  );
}

function createInitialHeaders(rows: string[][], headerOptions: { value: string; text: string }[]) {
  const col = rows[0] ?? [];
  const emailRegex = /e?-?mail/i;

  const headersToTest = headerOptions.slice(1);

  return col.map((h) => {
    if (emailRegex.test(h)) return 'email';
    return headersToTest.find((header) => header.text == h)?.value??0 as number;
  });
}
