import { PermissionsObject, VirtualFileCreationBase } from '../entities';
import { FileUpdate } from '../redux/files';
import { FileRepo } from '../repos';
import { AuthInteractor } from './AuthInteractor';

export class FilesInteractor {

  constructor(private files: FileRepo, private auth: AuthInteractor) {}

  private getOptionalRightsAndGroupsFromUser(): [string | undefined, string[] | undefined] {
    let user = this.auth.getCurrentUser();
    if (user.roles?.superAdmin) return [undefined, undefined];
    const { id, groups } = user;
    return [id, Object.keys(groups || {})];
  }

  async getRootFiles(): Promise<Core.VirtualFile[]> {
    return this.files.getRootFiles(...this.getOptionalRightsAndGroupsFromUser());
  }

  async getParents(fileId: string): Promise<Core.VirtualFile[]> {
    return this.files.getParents(fileId);
  }

  async getFavoriteFiles(): Promise<Core.VirtualFile[]> {
    let { id, groups } = this.auth.getCurrentUser();
    return this.files.getFavoriteFiles(id, Object.keys(groups || {}));
  }

  async getChildren(parent: string): Promise<Core.VirtualFile[]> {
    return this.files.getChildren(parent, ...this.getOptionalRightsAndGroupsFromUser());
  }

  async getFile(fileId: string, cacheFirst?: boolean) {
    return this.files.getFile(fileId, cacheFirst);
  }

  async deleteFile(fileId: string): Promise<void> {
    return this.files.deleteFile(fileId);
  }

  async updateFile<T extends FileUpdate>(fileId: string, update: T) {
    return this.files.updateFile(fileId, update);
  }

  async batchUpdateFiles<T extends FileUpdate>(updates: {fileId: string, update: T}[]): Promise<void> {
    return await this.files.batchUpdateFiles(updates);
  }

  async toggleFavorite(fileId: string) {
    const file = await this.files.getFile(fileId, true);
    const user = this.auth.getCurrentUser();

    return this.files.updateFile(fileId, {
      setFavorite: {
        isFavorite: !file.favoriteOf.some((uid) => uid == user.id),
        uid: user.id,
      },
    });
  }

  updateNormalFile(fileId: string, data: Blob | Uint8Array | ArrayBuffer) {
    return this.files.updateNormalFile(fileId, data);
  }

  createNormalFile<T extends Partial<Omit<Core.VirtualFile, 'id' | 'trashed' | 'type' | 'permissons' | 'parent'>>>(
    parent: string | null,
    data: Blob | Uint8Array | ArrayBuffer,
    accessProperties: PermissionsObject,
    mimeType: Core.FileType,
    other: T
  ) {
    return this.files.createNormalFile(parent, data, accessProperties, mimeType, other);
  }

  async createSIDocument<T extends VirtualFileCreationBase>(parent: string | null, other: T): Promise<Core.VirtualFile> {
    const user = this.auth.getCurrentUser();
    return await this.createVirtualFile(
      parent,
      {
        users: {
          move: [user.id],
          write: [user.id],
          read: [user.id],
          permission: [user.id],
        },
        groups: {},
        visibility: 'public',
      },
      'si/document',
      other
    );
  }

  async createFolder<T extends VirtualFileCreationBase>(parent: string | null, other: T): Promise<Core.VirtualFile> {
    const user = this.auth.getCurrentUser();
    return await this.createVirtualFile(
      parent,
      {
        users: {
          move: [user.id],
          write: [user.id],
          read: [user.id],
          permission: [user.id],
        },
        groups: {},
        visibility: 'public',
      },
      'folder',
      other
    );
  }
  
  async createVirtualFile<T extends VirtualFileCreationBase>(parent: string | null, accessProperties: PermissionsObject, mimeType: Core.FileType, other: T): Promise<Core.VirtualFile> {
    return await this.files.createVirtualFile(parent, accessProperties, mimeType, other);
  }
}
