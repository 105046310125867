import { Box, Typography } from '@material-ui/core';
import React from 'react';
import '../../css/components/common/content/ContentElement.scss';
import { useTheme } from '../../theme/ThemeProvider';
import { cssClasses } from '../HelperFunctions';
import { useFileIcon } from './ContentElementHelper';

type ContentElementProps = {
  id: string;
  name: string;
  selected: boolean;
  type: Core.FileType | 'unknown';
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDoubleClick: () => void;
  highlights?: string[];
  timeStamp?: number;
  favorite?: boolean;
  setFavorite?: () => void;
};

export const ContentElementSearch = ({ id, name, type, selected, onClick, onDoubleClick, highlights, timeStamp, setFavorite, favorite }: ContentElementProps) => {
  const theme = useTheme();
  const onClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.detail === 1) {
      onClick(event);
    } else if (event.detail === 2) {
      onDoubleClick();
    }
  };

  const FileIcon = useFileIcon(type, id);

  return (
    <Box className={cssClasses({ selected: selected, folder: type === 'folder', file: type !== 'folder' }, 'content-element no-user-select')} onClick={onClickHandler} onDoubleClick={onDoubleClick}>
      {/* <div id={id} className="context-menu-overlay" onClick={onClickHandler} onDoubleClick={onDoubleClick} /> */}
      <FileIcon />
      <div>
        <Box sx={{ display: 'flex' }}>
          <Typography
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: name
                .replace('\n', ' ')
                .replace(/\s+/g, ' ')
                .replace(/>\s(\w)/g, '>&nbsp;$1')
                .replace(/(\w)\s</g, '$1&nbsp;<'),
            }}
          />
          {/* <Typography sx={{ marginLeft: 1 }}>
            <ConditionalRender render={theme.productSettings.favourites}>
              <FontAwesomeIcon icon={favorite ? faStarLight : faStarRegular} onClick={setFavorite} className={cx('favorite-icon-search', favorite && 'is-favorite')} />
            </ConditionalRender>
          </Typography> */}
        </Box>
        {highlights && (
          <Typography
            variant="subtitle2"
            style={{ ...(type === 'folder' ? { color: 'white' } : {}) }}
            sx={{ '& em': { fontWeight: '600' } }}
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: highlights.join(' ').replace('\n', ' ').replaceAll(/\s+/g, ' '),
            }}
          />
        )}
      </div>
    </Box>
  );
};
