import * as ACTION_TYPES from './types';

export const loginRequestAction = () => {
  return {
    type: ACTION_TYPES.LOGIN_REQUEST as typeof ACTION_TYPES.LOGIN_REQUEST,
  };
};

export const loginSuccessAction = (user: Core.User) => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS as typeof ACTION_TYPES.LOGIN_SUCCESS,
    user,
  };
};

export const loginFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.LOGIN_FAILURE as typeof ACTION_TYPES.LOGIN_FAILURE,
    error,
  };
};

export const logoutRequestAction = () => {
  return {
    type: ACTION_TYPES.LOGOUT_REQUEST as typeof ACTION_TYPES.LOGOUT_REQUEST,
  };
};

export const logoutSuccessAction = () => {
  return {
    type: ACTION_TYPES.LOGOUT_SUCCESS as typeof ACTION_TYPES.LOGOUT_SUCCESS,
  };
};

export const logoutFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.LOGOUT_FAILURE as typeof ACTION_TYPES.LOGOUT_FAILURE,
    error,
  };
};

export const resetPasswordRequestAction = () => {
  return {
    type: ACTION_TYPES.RESET_PASSWORD_REQUEST as typeof ACTION_TYPES.RESET_PASSWORD_REQUEST,
  };
};

export const resetPasswordSuccessAction = () => {
  return {
    type: ACTION_TYPES.RESET_PASSWORD_SUCCESS as typeof ACTION_TYPES.RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.RESET_PASSWORD_FAILURE as typeof ACTION_TYPES.RESET_PASSWORD_FAILURE,
    error,
  };
};

export const resetAction = () => {
  return {
    type: ACTION_TYPES.RESET as typeof ACTION_TYPES.RESET,
  };
};

export const updateDataRequestAction = () => {
  return {
    type: ACTION_TYPES.UPDATE_DATA_REQUEST as typeof ACTION_TYPES.UPDATE_DATA_REQUEST,
  };
};

export const updateDataSuccessAction = (update: Partial<Omit<Core.User, 'groups' | 'rights' | 'roles' | 'id'>>) => {
  return {
    type: ACTION_TYPES.UPDATE_DATA_SUCCESS as typeof ACTION_TYPES.UPDATE_DATA_SUCCESS,
    update,
  };
};

export const updateDataFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.UPDATE_DATA_FAILURE as typeof ACTION_TYPES.UPDATE_DATA_FAILURE,
    error,
  };
};

export const tryAutoLoginRequestAction = () => {
  return {
    type: ACTION_TYPES.TRY_AUTO_LOGIN_REQUEST as typeof ACTION_TYPES.TRY_AUTO_LOGIN_REQUEST,
  };
};

export const tryAutoLoginSuccessAction = (user: Core.User) => {
  return {
    type: ACTION_TYPES.TRY_AUTO_LOGIN_SUCCESS as typeof ACTION_TYPES.TRY_AUTO_LOGIN_SUCCESS,
    user,
  };
};

export const tryAutoLoginFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.TRY_AUTO_LOGIN_FAILURE as typeof ACTION_TYPES.TRY_AUTO_LOGIN_FAILURE,
    error,
  };
};

export const resetPasswordResetAction = () => {
  return {
    type: ACTION_TYPES.RESET_PASSWORD_RESET as typeof ACTION_TYPES.RESET_PASSWORD_RESET,
  };
};

export const provide2FAVerificationCodeRequestAction = () => {
  return {
    type: ACTION_TYPES.PROVIDE2FAVERIFICATION_CODE_REQUEST as typeof ACTION_TYPES.PROVIDE2FAVERIFICATION_CODE_REQUEST,
  };
};

export const provide2FAVerificationCodeSuccessAction = () => {
  return {
    type: ACTION_TYPES.PROVIDE2FAVERIFICATION_CODE_SUCCESS as typeof ACTION_TYPES.PROVIDE2FAVERIFICATION_CODE_SUCCESS,
  };
};

export const provide2FAVerificationCodeFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.PROVIDE2FAVERIFICATION_CODE_FAILURE as typeof ACTION_TYPES.PROVIDE2FAVERIFICATION_CODE_FAILURE,
    error,
  };
};

export const set2FAResolverAction = (resolver: any, phoneNumber: string) => {
  return {
    type: ACTION_TYPES.SET2FARESOLVER as typeof ACTION_TYPES.SET2FARESOLVER,
    resolver,
    phoneNumber,
  };
};

export const provide2FAPhoneNumberRequestAction = () => {
  return {
    type: ACTION_TYPES.PROVIDE2FAPHONE_NUMBER_REQUEST as typeof ACTION_TYPES.PROVIDE2FAPHONE_NUMBER_REQUEST,
  };
};

export const provide2FAPhoneNumberSuccessAction = (verificationId: string, phoneNumber: string) => {
  return {
    type: ACTION_TYPES.PROVIDE2FAPHONE_NUMBER_SUCCESS as typeof ACTION_TYPES.PROVIDE2FAPHONE_NUMBER_SUCCESS,
    verificationId,
    phoneNumber,
  };
};

export const provide2FAPhoneNumberFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.PROVIDE2FAPHONE_NUMBER_FAILURE as typeof ACTION_TYPES.PROVIDE2FAPHONE_NUMBER_FAILURE,
    error,
  };
};

export const verify2FAFactorEnrollmentRequestAction = () => {
  return {
    type: ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_REQUEST as typeof ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_REQUEST,
  };
};

export const verify2FAFactorEnrollmentSuccessAction = () => {
  return {
    type: ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_SUCCESS as typeof ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_SUCCESS,
  };
};

export const verify2FAFactorEnrollmentFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_FAILURE as typeof ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_FAILURE,
    error,
  };
};

export const provide2FAFactorCallAction = () => {
  return {
    type: ACTION_TYPES.PROVIDE2FAFACTOR_CALL as typeof ACTION_TYPES.PROVIDE2FAFACTOR_CALL,
  };
};

export const verify2FAFactorEnrollmentCallAction = () => {
  return {
    type: ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_CALL as typeof ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_CALL,
  };
};

export const enforce2FACreationAction = () => {
  return {
    type: ACTION_TYPES.ENFORCE2FACREATION as typeof ACTION_TYPES.ENFORCE2FACREATION,
  };
};

export const close2FACallAction = () => {
  return {
    type: ACTION_TYPES.CLOSE2FACALL as typeof ACTION_TYPES.CLOSE2FACALL,
  };
};

export const notify2FACreationfinishedAction = () => {
  return {
    type: ACTION_TYPES.NOTIFY2FACREATIONFINISHED as typeof ACTION_TYPES.NOTIFY2FACREATIONFINISHED,
  };
};

export const close2FACreationNotificationAction = () => {
  return {
    type: ACTION_TYPES.CLOSE2FACREATION_NOTIFICATION as typeof ACTION_TYPES.CLOSE2FACREATION_NOTIFICATION,
  };
};
