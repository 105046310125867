import React, { useContext } from 'react'
import { FileProvider } from './FileProvider';

interface ProviderContextType {
  fileProvider: FileProvider;
  uploadFileProvider: FileProvider;
}

const ProviderContext = React.createContext<ProviderContextType>({} as ProviderContextType)


const { Consumer, Provider } = ProviderContext;

export {
  Provider as ProvidersProvider,
}

export function useProvider() {
  return useContext(ProviderContext)
}