import { API } from '@base/core';
import { Form } from '@editors/form-editor';
import { useQuery, useQueries } from 'react-query';

export function useGetMultipleForms<T extends Form>(data: { campaignId: string; formId: string }[]) {
  return useQueries(
    data.map(({ campaignId, formId }) => ({
      queryKey: ['campaigns', campaignId, 'forms', formId],
      async queryFn() {
        return (await API.campaigns.getForm(campaignId, formId)) as T;
      },
    })),
  );
}
