import { Campaign, getKeysWithTruthyValues } from '@base/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useDeepCopyCampaignMutation } from '../../campaign/hooks/mutations';
import { useGetChildren } from '../../campaign/hooks/useFolderChildren';
import { useGetFile } from '../../campaign/hooks/useGetFile';
import { ApplicationsExternal } from '../ApplicationsExternal';

export function CampaignManagerWrapper({ match }) {
  const { parentId } = useParams<{ parentId: string }>();
  const user = useSelector((state: Core.StateType) => state.auth.user);
  const { data, isLoading } = useGetChildren<Campaign[]>(parentId, [{ field: 'publishedState', value: 'published' }]);
  const { data: parent } = useGetFile(parentId);
  const history = useHistory();

  const copyMutation = useDeepCopyCampaignMutation();
  const children = useMemo(() => (data?.children ?? []).filter((c) => getKeysWithTruthyValues(user.groups).some((gid) => c.selectedGroups?.[gid])), [data]);

  return (
    <ApplicationsExternal
      onDuplicateCampaign={async (campaign, parent) => {
        await copyMutation.mutateAsync({ campaign, newParent: parent });
      }}
      campaigns={children}
      loading={isLoading}
      baseUrl={'/campaigns-external/'}
      parents={parent ? [parent] : []}
      onCampaignDoubleClick={(c) => {
        history.push(c.id);
      }}
    />
  );
}
