import * as React from 'react';

function SvgAlignCenterRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.514 20.746" ref={svgRef} {...props}>
      <path d="M5.191 2.305h11.132a.581.581 0 00.581-.581V.581A.581.581 0 0016.323 0H5.191a.581.581 0 00-.581.581v1.143a.581.581 0 00.581.581zM20.746 18.44H.768a.768.768 0 00-.768.769v.768a.768.768 0 00.768.768h19.978a.768.768 0 00.768-.768v-.768a.768.768 0 00-.768-.769zm0-12.294H.768A.768.768 0 000 6.915v.768a.768.768 0 00.768.768h19.978a.768.768 0 00.768-.768v-.768a.768.768 0 00-.768-.768zM16.323 14.6a.581.581 0 00.581-.581v-1.145a.581.581 0 00-.581-.581H5.191a.581.581 0 00-.581.581v1.143a.581.581 0 00.581.581z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAlignCenterRegular);
export default ForwardRef;
