import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { emailNotificationChannels } from '../../index';

const NOTIFICATION_CHANNELS: emailNotificationChannels[] = ['new_access_request'];

export function NotificationSelectDropdown(props) {
  const [notChannel, setNotChannel] = React.useState<string[]>(props.value ?? []);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    props.onChange(event);
    setNotChannel(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl fullWidth>
      <Select
        // {...props}
        name={props.name}
        variant="outlined"
        id="notificationChannels"
        multiple
        value={notChannel}
        onChange={handleChange}
        renderValue={(selected) => selected.map((channel) => (channel = t(`notificationChannels.${channel}`))).join(', ')}
      >
        {NOTIFICATION_CHANNELS.map((channel) => (
          <MenuItem key={channel} value={channel}>
            <Checkbox checked={notChannel.indexOf(channel) > -1} />
            <ListItemText primary={t(`notificationChannels.${channel}`)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
