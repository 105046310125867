import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, IconButton, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {LoginHomeBg} from '../assets/images';
import {TwoFactorEnforcedPopup} from '../components/auth/2fa/TwoFactorEnforcedPopup';
import {ConditionalRender, CurrentUserAvatar} from '../components/common';
import {LogoutButton} from '../components/common/buttons/LogoutButton';
import Dashboard from '../components/Dashboard';
import '../css/Views/home.scss';
import {ConditionalRenderWithRights} from '../helpers/ConditionalRenderWithRights';
import {useTheme} from '../theme/ThemeProvider';

export const Home = () => {
  const [searchInput, setSearchInput] = useState('');
  const {user} = useSelector((state: Core.StateType) => state.auth);
  const theme = useTheme();
  const {t} = useTranslation();

  const history = useHistory();

  return (
    <div
      className="home-con"
      style={{
        backgroundImage: `url('${LoginHomeBg}')`,
      }}
    >
      <TwoFactorEnforcedPopup/>
      <div className="main-content-container">
        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', flex: 1, padding: 30}}>
          <theme.images.logo style={{color: theme.colors.logoLight, zIndex: 2, height: 120, width: 'auto'}}/>
        </div>
        <div className="user-area-wrapper-container">
          <div className="row-space-beetween">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box>
                <Typography variant="h1" style={{marginBottom: 4}}>
                  {t('Hallo') + ', '} <b style={{fontWeight: 500}}>{user?.displayName.split(' ')[0]}</b>
                </Typography>
                <div className="title-underline"/>
              </Box>
            </Box>
            <div className="row">
              <LogoutButton style={{marginRight: 20}} size={55}/>
              <CurrentUserAvatar style={{height: 90, width: 90}} fontSize={48}/>
            </div>
          </div>
        </div>
        <ConditionalRenderWithRights>
          <ConditionalRender render={theme.productSettings.search}>
            <div className="home-search-bar" style={{color: '#6D6D6D'}}>
              <IconButton onClick={() => history.push('/search?q=' + searchInput)}>
                <FontAwesomeIcon color="white" fixedWidth icon={faSearch}/>
              </IconButton>
              <input
                type="Text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder={t('search') + ' ' + t('document-plural')}
                className="search-bar-input"
                onKeyDown={(e) => {
                  if (e.keyCode == 13)
                    //enter
                    history.push('/search?q=' + searchInput);
                }}
              />
            </div>
          </ConditionalRender>
        </ConditionalRenderWithRights>
        <div className="content-area">
          <Dashboard/>
        </div>
      </div>
    </div>
  );
};
