import { API, UserCreationData } from '@base/core';
import { useMutation } from 'react-query';
import XLSX from 'xlsx';

function getDefinitionFromRange(range: string) {
  const [from, to] = range.split(':');
  const fromLetter = from.slice(0, 1);
  const fromNumber = from.slice(1);
  const toLetter = to.slice(0, 1);
  const toNumber = to.slice(1);

  const cols = toLetter.toUpperCase().charCodeAt(0) - fromLetter.toUpperCase().charCodeAt(0) + 1;
  const rows = parseInt(toNumber) - parseInt(fromNumber) + 1;
  return {
    cols,
    rows,
  };
}

export const PERMISSIVE_MAIL_REGEX = /.+@.+\..+/;

export function extractJaggedArrayFromSheetCols(sheet: XLSX.Sheet) {
  const rows: string[][] = [];
  if (!sheet) return [];
  const ACharcode = 'A'.charCodeAt(0);
  Object.entries(sheet).forEach(([key, value]) => {
    if (!key.match(/[A-Z]\d+/)) return;
    const rowNum = parseInt(key.slice(1)) - 1;

    const colIndex = key.charCodeAt(0) - ACharcode;
    if (!rows[rowNum]) rows[rowNum] = [];
    rows[rowNum][colIndex] = value.v;
  });
  return rows;
}

type headerKeys = 'abteilung' | 'address' | 'firstname' | 'lastname' | 'email' | 'jobTitle' | 'mobile' | 'phoneNumber' | 'currentModules';

export function getUserDataFromHeaders(headers: string[], rows: string[][]): UserCreationData[] {
  const map = headers.reduce((map, header, i) => ({ ...map, [header]: i }), {}) as {
    [header in headerKeys]: number;
  };
  const users: UserCreationData[] = [];
  for (const row of rows) {
    const newUser = {
      displayName: [row[map.firstname], row[map.lastname]].filter((u) => u != undefined).join(' '),
      firstname: row[map.firstname],
      email: row[map.email],
      emailVerified: true,
      password: 'a09j3arp',
      ...(row[map.lastname ?? ''] && { lastname: row[map.lastname ?? ''] }),
      ...(row[map.abteilung ?? ''] && { abteilung: row[map.abteilung ?? ''] }),
      ...(row[map.address ?? ''] && { address: row[map.address ?? ''] }),
      ...(row[map.currentModules ?? ''] && { currentModules: [row[map.currentModules ?? '']] }),
      ...(row[map.mobile ?? ''] && { mobile: row[map.mobile ?? ''] }),
      ...(row[map.phoneNumber ?? ''] && { phoneNumber: row[map.phoneNumber ?? ''] }),
    } as UserCreationData;
    users.push(newUser);
  }
  return users;
}

export function useUserCreate() {
  return useMutation(async (userData: UserCreationData) => {
    // await new Promise<void>((resolve, reject) => {
    //   setTimeout(() => {
    //     if (Math.random() > 0.3) resolve();
    //     else reject('SOME ERROR');
    //   }, Math.random() * 1000 + 100);
    // });
    await API.users.createUser(userData);
  });
}
