export * from './dataproviders/UserService';
export * from './dataproviders/AuthService';
export * from './dataproviders/GroupService';
export * from './dataproviders/EmailService';
export * from './dataproviders/AdobeSignService';
export * from './dataproviders/AccessRequestService';
export * from './init';

import { DataBase as IDB } from '@base/core';
import { LearningDatabase as LDB } from '@learning/core';

import { AuthService } from './dataproviders/AuthService';
import { FileService } from './dataproviders/FileService';
import { GroupService } from './dataproviders/GroupService';
import { UserService } from './dataproviders/UserService';
import { ModuleService } from './dataproviders/ModuleService';
import { QuestionService } from './dataproviders/LearningService';
import { ExamService } from './dataproviders/ExamService';
import { SearchService } from './dataproviders/SearchService';
import { EmailService } from './dataproviders/EmailService';
import { AccessRequestService } from './dataproviders/AccessRequestService';
import { FormService } from './dataproviders/FormService';
import { ChatService } from './dataproviders/ChatService';
import {AdobeSignService} from "./dataproviders/AdobeSignService";
import { ACCESS_REQUEST_COLLECTION, ACCESS_REQUEST_STORAGE, HANDBOOK_FILES_COLLECTION, HANDBOOK_FILES_STORAGE } from './config';

export const DataBase: IDB = {
  chatService: new ChatService(),
  authService: new AuthService(),
  groupService: new GroupService(),
  userService: new UserService(),
  fileService: new FileService(HANDBOOK_FILES_COLLECTION, HANDBOOK_FILES_STORAGE),
  campaignFileService: new FileService('campaign_files', 'campaign_files'),
  search: new SearchService(),
  emailService: new EmailService(),
  accessRequestService: new AccessRequestService(ACCESS_REQUEST_COLLECTION, ACCESS_REQUEST_STORAGE),
  formService: new FormService(),
  adobeSignService: new AdobeSignService(),
};

export * from './dataproviders/SimpleFileProvider';

export const LearningDB: LDB = {
  questionService: new QuestionService(),
  moduleService: new ModuleService(),
  examService: new ExamService(),
};

export * from './dataproviders/createCallableApi';
