import { QueryObserverResult, useQueries, useQuery, UseQueryOptions } from 'react-query';
import { API, Campaign, FormSubmission, getKeysWithTruthyValues } from '@base/core';
import { Form } from '@editors/form-editor';
import { useSelector } from 'react-redux';
import { useGetFile } from '../../campaign/hooks';

export function useGetFormContents(campaignId: string, formIds: string[], queryOptions?: UseQueryOptions<Form, unknown, Form>) {
  const user = useSelector((state: Core.StateType) => state.auth.user)

  const { data: campaign } = useGetFile<Campaign>(campaignId);
  const groupid = getKeysWithTruthyValues(user.groups).find(gid => campaign?.selectedGroups?.[gid])

  return useQueries(formIds.map(formId => ({
    queryKey: ['campaigns', campaignId, 'formssubmissions', formId],
    async queryFn() {
      return await API.campaigns.getFormViewerContent(campaignId, formId, groupid);
    },
    ...queryOptions
  })
  )
  ) as QueryObserverResult<FormSubmission, unknown>[]
}
// return useQuery(['campaigns', campaignId, 'formssubmissions', formId], async () => {
//   let groupid = getKeysWithTruthyValues(user.groups).find(gid=> campaign.selectedGroups?.[gid])

//   return await API.campaigns.getFormViewerContent(campaignId, formId, groupid);
// }, { enabled: Boolean(campaign) });