import { Campaign } from '@base/core';
import { faLayerPlus, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Box, Button, Stack } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FileUpdate } from 'libs/base/core/src/redux/files';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import { ContentHeader } from '../../components/common/ContentHeader';
import { AccessLinkPopup } from '../../components/common/popups/AccessLinkPopup';
import { Module } from '../../components/content';
import { compareSearch, ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/Views/ModulView.scss';
import { ConditionalRenderWithRights } from '../../helpers/ConditionalRenderWithRights';
import { getNotificationCountFromCampaignParent } from '../../lib/notificationCountHelpers';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { CampaignNotificationsPopup } from './CampaignNotificationsPopup';
import { ModuleSettingsPopup } from './ModuleSettingsPopup';

export interface Modules {
  id: string;
  faIcon: IconDefinition;
  title: string;
  count: number;
  notifications: number;
  bgImage: any;
  navigateTo: string;
}

export interface FileModule extends Core.NormalFile {
  icon: string;
  description: string;
  isParentModule?: boolean;
  parentModule?: string;
  groupTypeAccess?: string[];
}

interface CampaignOverviewProps {
  folders: FileModule[];
  isLoading?: boolean;
  onUpdate: (id: string, value: FileUpdate<FileModule> & { imageFile?: File }) => Promise<void>;
  onCreate: (value: FileUpdate<FileModule> & { imageFile?: File }) => Promise<void>;
  onDelete: (file: FileModule) => Promise<void>;
  onOpenModule(m: FileModule): void;
}

export const CampaignOverview = ({ isLoading = false, folders, onCreate, onDelete, onUpdate, onOpenModule }: CampaignOverviewProps) => {
  const [linkPopupActive, setLinkPopupActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showCreateModule, setShowCreateModule] = useState(false);
  const [realtiveLink, setRelativeLink] = useState('');
  // const [settingsPopupVisibility, setsettingsPopupVisibility] = useState<string>(null);
  // const [notificationsPopupVisibility, setNotificationsPopupVisibility] = useState<string>(null);

  // const { data: usersList = [] } = useGetUsers();

  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <ContentContainer>
      <AccessLinkPopup relativeLinkUrl={location.pathname + '/' + realtiveLink} setActive={setLinkPopupActive} active={linkPopupActive} />
      <ModuleSettingsPopup create visible={showCreateModule} onClose={() => setShowCreateModule(false)} onUpdate={onCreate} />
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.campaignOverview]} />
      <ContentView loading={isLoading} noPadding>
        <ContentHeader title={t('form-folders')} subtitle={t('folder-counted', { count: folders.length })} href="/">
          <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
            <Box flex={1} />
            <ConditionalRenderWithRights campaign_admin>
              <Button
                sx={{ color: 'primary.main', bgcolor: 'background.paper', ':hover': { bgcolor: 'action.hover' }, borderRadius: 999 }}
                onClick={() => setShowCreateModule(true)}
                variant="contained"
                color="primary"
                endIcon={<FontAwesomeIcon icon={faLayerPlus} />}
              >
                {t('create-application-type')}
              </Button>
            </ConditionalRenderWithRights>
          </Stack>
        </ContentHeader>
        <div style={{ padding: theme.spacing(4) }}>
          <div className="module-grid-module-view">
            {folders.map((file: FileModule, idx) => (
              <ModuleComponent
                key={file.id}
                searchValue={searchValue}
                file={file}
                // setsettingsPopupVisibility={setsettingsPopupVisibility}
                // setNotificationsPopupVisibility={setNotificationsPopupVisibility}
                onOpenModule={onOpenModule}
                idx={idx}
                // settingsPopupVisibility={settingsPopupVisibility}
                onUpdate={onUpdate}
                onDelete={onDelete}
                // notificationsPopupVisibility={notificationsPopupVisibility}
              />
            ))}
          </div>
        </div>
      </ContentView>
    </ContentContainer>
  );
};
function ModuleComponent({
  file,
  idx,
  onDelete,
  onOpenModule,
  onUpdate,
  searchValue,
}: {
  searchValue: string;
  file: FileModule;
  onOpenModule: (m: FileModule) => void;
  idx: number;
  onUpdate: (id: string, value: FileUpdate<FileModule> & { imageFile?: File }) => Promise<void>;
  onDelete: (file: FileModule) => Promise<void>;
}): JSX.Element {
  const me = useSelector((state: Core.StateType) => state.auth.user);

  const unreadCount = useObservable<number, [Campaign]>(
    (state$, input$) =>
      input$.pipe(
        map(([Campaign]) => Campaign),
        getNotificationCountFromCampaignParent(me.id),
      ),
    0,
    [(file as any) as Campaign],
  );

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  return (
    compareSearch(searchValue, file.name) && (
      <div>
        <Badge color="error" badgeContent={unreadCount} sx={{ display: 'block' }}>
          <Module
            campaign
            onSettingsClick={() => setSettingsOpen(true)}
            onNotificationClick={() => setNotificationsOpen(true)}
            currentModule={file}
            key={file.id}
            onClick={() => onOpenModule(file)}
            count={idx + 1}
          />
        </Badge>
        <ModuleSettingsPopup visible={settingsOpen} onClose={() => setSettingsOpen(false)} onUpdate={(u) => onUpdate(file.id, u)} currentModule={file} onDelete={() => onDelete(file)} isCampaign />
        <CampaignNotificationsPopup currentModule={file} visible={notificationsOpen} onClose={() => setNotificationsOpen(false)} />
      </div>
    )
  );
}
