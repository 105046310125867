/* eslint-disable @typescript-eslint/no-namespace */
/**
 * @schema https://schema.org/Person
 */

import { emailNotificationChannels } from '.';
import { NotificationSubscriptions } from './IEmailService';

declare global {
  namespace Core {
    export interface User extends NotificationSubscriptions {
      id: string;
      email: string;
      displayName: string;
      groups: Groups;
      rights: Rights;
      disabled: boolean;
      abteilung?: string;
      mobile?: string;
      emailVerified: boolean;
      phoneNumber?: string;
      photoURL?: Promise<string>;
      roles: Roles;
      notes?: string;
      address?: string;
      birthDate?: string;
      jobTitle?: string;
      supervisor: null | string;
      language: string;
      passwordChangeRequired?: boolean;
      isTwoFAEnforced?: boolean;
      is2FAEnrolled?: boolean;
      notificationChannels?: emailNotificationChannels[];
    }

    export type Rights = { [right in UserRights]?: true } & { groupRights?: GroupRights };
    export type GroupRights = { [right in GroupRightsKeys]: string[] };
    export type Roles = { superAdmin?: true };
    export type Groups = { [groupId: string]: true };

    export type UserRights =
      | 'user_create'
      | 'user_edit'
      | 'user_delete'
      | 'file_admin'
      | 'campaign_admin'
      | 'user_rights_edit'
      | 'user_groups_create'
      | 'user_groups_edit'
      | 'user_groups_delete'
      | 'is_group_only_user';
    export type GroupRightsKeys = 'user_create' | 'user_edit' | 'user_delete' | 'user_right_edit' | 'group_admin' | 'can_submit_applications';
  }
}

export {};
