import { AccessRequestFormData, API, StandardPopup, usePopup } from '@base/web';
import { Card, CardContent, Grid, Stack, Typography } from '@material-ui/core';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { RequestFormContentProps } from '../form-contents/RequestFormContentProps';
import { RegisterRequestForm } from './RegisterRequestForm';

export type LoginRequestPopupProps = {
  active: boolean;
  setActive;
  topic: string;
  name: string;
  description: string;
  getSchema: (t: TFunction<'translation'>) => any;
  getInitialValues: (formData?: any) => any;
  FormContent: (props: RequestFormContentProps) => JSX.Element;
  type: string;
};

/**
 * The customer logo is set via the si theme provider
 * @param active open state
 * @param setActive set open state
 * @param readOnly specify if the form is only readable and the file button a downloadlink (true) or file picker (false)
 * @param topic the first bold text - should explain in one word what can be done here
 * @param name the normal text beside the topic - should tell which data is accessed with that topic
 * @param description the text below topic and name - should give a more detailed description
 * @param appeal the function call for the appeal button from the readonly mode - otherwise a submit button is shown
 * @param verify the function call for the verify button from the readonly mode - otherwise a submit button is shown
 */
export const RegisterRequestPopup = ({ active, setActive, topic, name, description, FormContent, getSchema, getInitialValues, type }: LoginRequestPopupProps) => {
  const notification = usePopup();
  const { t } = useTranslation();
  const submitFormData = async (data: AccessRequestFormData) => {
    try {
      await API.accessRequest.sendAccessRequest({ ...data, type } as any);
      notification({ title: t('successfully-sent'), text: t('you-have-successfully-applied-for-registration'), type: 'success' });
    } catch (error) {
      notification({ title: t('error'), text: error, type: 'error' });
    }
    setActive(false);
  };

  return (
    <StandardPopup onBackdropClick={() => {}} onCloseClick={() => setActive(false)} visible={active}>
      <Card css={{ maxWidth: 900 }}>
        <CardContent style={{ maxWidth: 900 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                {topic && (
                  <Typography variant="h3" fontWeight={500} fontSize="2rem">
                    {topic}
                  </Typography>
                )}
                {name && (
                  <Typography variant="h3" fontWeight={400} fontSize="2rem">
                    {name}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} marginBottom={2}>
              <Typography variant="body1">{description}</Typography>
            </Grid>
          </Grid>
          <RegisterRequestForm onSubmit={async (data) => submitFormData(data)} FormContent={FormContent} getSchema={getSchema} getInitialValues={getInitialValues} />
        </CardContent>
      </Card>
    </StandardPopup>
  );
};
