import { SentDocumentStatusType } from '@base/core';
import { Chip, Stack, Typography, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getColorFromAdobeStatus, getIconFromAdobeStatus } from './helpers';

interface AgreementStatusProps {
  status: SentDocumentStatusType;
}

export function AgreementStatus({ status }: AgreementStatusProps) {
  const theme = useTheme();
  const color = getColorFromAdobeStatus(status);

  return (
    <Chip
      icon={<FontAwesomeIcon icon={getIconFromAdobeStatus(status)} style={{ width: 14, height: 14 }} />}
      color={color}
      label={status}
      sx={{ color: theme.palette[color].main, backgroundColor: theme.palette[color].lighter, pl: 1, fontWeight: 500 }}
    />
  );
}
