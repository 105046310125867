import * as React from 'react';

function SvgComment(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.19 21.19" ref={svgRef} {...props}>
      <path
        d="M18.542 0H2.649A2.651 2.651 0 000 2.649v11.92a2.651 2.651 0 002.649 2.649h3.973v3.477a.5.5 0 00.5.5.475.475 0 00.294-.1l5.169-3.874h5.96a2.651 2.651 0 002.649-2.649V2.649A2.651 2.651 0 0018.542 0zm.658 14.568a.664.664 0 01-.662.662H11.92l-.53.4-2.781 2.084v-2.483h-5.96a.664.664 0 01-.662-.662V2.649a.664.664 0 01.662-.662h15.893a.664.664 0 01.662.662zm-3.973-8.94H5.96a.664.664 0 00-.662.662v.662a.664.664 0 00.662.662h9.271a.664.664 0 00.662-.662v-.661a.664.664 0 00-.662-.662zM11.257 9.6H5.96a.664.664 0 00-.662.662v.662a.664.664 0 00.662.662h5.3a.664.664 0 00.662-.662v-.662a.664.664 0 00-.665-.662z"
        fill="#858585"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgComment);
export default ForwardRef;
