import * as React from 'react';

function SvgListOlRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.709 20.746" ref={svgRef} {...props}>
      <path d="M2.86 17.087l.81-.933a.922.922 0 00.235-.657v-.153a.47.47 0 00-.525-.526H.741a.37.37 0 00-.37.37v.741a.37.37 0 00.37.37H1.8a7.17 7.17 0 00-.509.57l-.26.324a.615.615 0 00-.13.689l.049.089a.564.564 0 00.567.365h.219c.478 0 .738.113.738.421 0 .219-.194.381-.665.381a1.924 1.924 0 01-.716-.144c-.3-.18-.544-.162-.722.144l-.261.432a.5.5 0 00.121.738 3.326 3.326 0 001.713.437A2.03 2.03 0 004.191 18.7a1.674 1.674 0 00-1.331-1.613zm20.108-.417H8.15a.741.741 0 00-.741.741v.741a.741.741 0 00.741.741h14.818a.741.741 0 00.741-.741v-.741a.741.741 0 00-.741-.74zm0-14.818H8.15a.741.741 0 00-.741.741v.741a.741.741 0 00.741.741h14.818a.741.741 0 00.741-.741v-.741a.741.741 0 00-.741-.741zm0 7.409H8.15a.741.741 0 00-.741.739v.741a.741.741 0 00.741.741h14.818a.741.741 0 00.741-.741V10a.741.741 0 00-.741-.739zM.741 5.927H3.7a.37.37 0 00.37-.37v-.741a.37.37 0 00-.37-.37h-.736V.37a.37.37 0 00-.37-.37H1.482a.37.37 0 00-.331.2l-.37.741a.37.37 0 00.331.536h.37v2.964H.741a.37.37 0 00-.37.37v.741a.37.37 0 00.37.375zM.56 13.336H3.7a.37.37 0 00.37-.37v-.741a.37.37 0 00-.37-.37H1.914c.152-.477 2.238-.865 2.238-2.614a1.875 1.875 0 00-2.059-1.832 2.244 2.244 0 00-1.873.868.458.458 0 00.129.712l.4.319c.26.211.509.114.747-.113a.621.621 0 01.438-.178.4.4 0 01.43.405c0 .589-2.36 1.011-2.36 3.2v.185a.507.507 0 00.556.529z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgListOlRegular);
export default ForwardRef;
