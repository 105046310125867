import * as React from 'react';

function SvgFileEditLight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.801 62.413" ref={svgRef} {...props}>
      <path d="M45.082 11.944L34.857 1.718A5.85 5.85 0 0030.725 0H5.85A5.87 5.87 0 000 5.862v50.7a5.852 5.852 0 005.85 5.85h35.1a5.852 5.852 0 005.85-5.85V16.088a5.879 5.879 0 00-1.718-4.144zm-2.754 2.767a1.917 1.917 0 01.512.9H31.2V3.973a1.917 1.917 0 01.9.512zm-1.377 43.8H5.85a1.956 1.956 0 01-1.95-1.95V5.862a1.956 1.956 0 011.95-1.95H27.3v12.675a2.918 2.918 0 002.925 2.925H42.9v37.051a1.956 1.956 0 01-1.949 1.95zM26.715 30.14l3.608 3.608a.544.544 0 010 .78l-13.7 13.7-3.668.524a1.1 1.1 0 01-1.243-1.243l.524-3.668 13.7-13.7a.544.544 0 01.78 0zm7.849.146l-2 2a.544.544 0 01-.78 0l-3.608-3.608a.544.544 0 010-.78l2-2a1.817 1.817 0 012.584 0l1.8 1.8a1.817 1.817 0 01.004 2.588z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileEditLight);
export default ForwardRef;
