import { faMobile } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, TextField, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Actions } from '@base/core';
import TwoFactorIcon from '../../../assets/universal/fingerprint.svg';
import { StandardPopup } from '../../../components/common';
//@ts-ignore
import PhoneNumberInput from 'react-phone-input-material-ui';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

export function TwoFASetPhoneNumberPopup() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { twoFA, fetching } = useSelector((state: Core.StateType) => state.auth);
  const validationSchema = yup.object().shape({
    phoneNumber: yup.string().required('No phone number provided.'),
  });

  return (
    <StandardPopup visible={twoFA?.status?.getPhoneNumber} onBackdropClick={() => null} width={425} hideClose>
      <Formik
        initialValues={{ phoneNumber: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          //TODO: can lead to potential error if ux fails
          await dispatch(Actions().auth.provide2FAPhoneNumber('+' + values.phoneNumber));
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ isSubmitting, errors, touched, handleSubmit, values, submitForm, setFieldValue }) => (
          <Form>
            <Card>
              <CardContent>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {/* <img src={TwoFactorIcon} style={{ width: 72, height: 72, alignSelf: 'center' }} /> */}
                  <FontAwesomeIcon icon={faMobileAlt} style={{ width: 72, height: 72, alignSelf: 'center' }} />
                </Box>
                <Typography variant="h2" sx={{ marginTop: 3, marginBottom: 3, textAlign: 'center' }}>
                  {t('two-factor-authentication')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon style={{ width: 44, height: 44, marginRight: 8 }} icon={faMobile} />
                    <Typography fontWeight="500" variant="subtitle1">
                      {t('enter-phone-number-to-register-second-login-factor')}
                    </Typography>
                  </Box>
                  <PhoneNumberInput
                    inputProps={{
                      variant: 'outlined',
                      label: t('phone-number'),
                      disabled: isSubmitting,
                      autoFocus: true,
                      required: true,
                      name: 'phoneNumber',
                      error: (touched.phoneNumber && Boolean(errors.phoneNumber)) || fetching.provide2FAPhoneNumber.error,
                      helperText: (touched.phoneNumber && errors.phoneNumber) || fetching.provide2FAPhoneNumber.error,
                    }}
                    style={{ marginTop: 24 }}
                    specialLabel={false}
                    component={TextField}
                    value={values.phoneNumber}
                    onChange={(value) => setFieldValue('phoneNumber', value)}
                  />
                  {fetching.provide2FAPhoneNumber.error && (
                    <LoadingButton style={{ marginTop: 16 }} variant="contained" color="error" onClick={() => window.location.reload()}>
                      {t('retry-login')}
                    </LoadingButton>
                  )}
                  <LoadingButton style={{ marginTop: 16 }} variant="contained" color="success" loading={fetching?.provide2FAPhoneNumber?.fetching} onClick={submitForm} type="submit">
                    {t('send-verification-code')}
                  </LoadingButton>
                  <Typography style={{ marginTop: 10, textAlign: 'center' }} variant="subtitle2">
                    {t('you-will-receive-a-sms-with-the-verification-code')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </StandardPopup>
  );
}
