import { faEnvelope, faExclamationCircle, faInfoCircle, faShareAll, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Card, Checkbox, Chip, Grid, ListItem, Stack, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import { StandardPopup } from 'libs/base/web/src/components/common';
import { LoadingPromiseButton } from 'libs/base/web/src/components/LoadingPromiseButton';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';

const defaultSubject = 'Invitation to IBU Scope';
const defaultInviteText = `Dear ladies and gentlemen,

You are invited to sign into our new web tool to send applications or sign documents in a modern digital way.`;

export type InviteUserPopupProps = {
  active: boolean;
  setActive: (active: boolean) => void;
  sendEmail: (to: string[], subject: string, message: string) => Promise<void>;
  subject?: string;
  message?: string;
};

function unique(value: string[]) {
  const s = value.reduce((map, v) => ({ ...map, [v]: true }), {});
  return Object.keys(s);
}

const validator = string().email();

export const InviteUserPopup = ({ active, setActive, sendEmail, subject: s = defaultSubject, message: m = defaultInviteText }: InviteUserPopupProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [editMailState, setEditMailState] = useState<boolean>(false);
  const [recipientEmails, setRecipientEmails] = useState([]);

  const [subject, setSubject] = useState(s);
  const [message, setMessage] = useState(m);

  const send = useCallback(async () => {
    await sendEmail(
      recipientEmails.filter((email) => validateEmail(email)),
      subject,
      message.replace(/\n/g, '<br/>')
    );
  }, [message, recipientEmails, sendEmail, subject]);

  function validateEmail(email): boolean {
    try {
      validator.validateSync(email);
      return true;
    } catch {
      return false;
    }
  }

  return (
    <StandardPopup PaperComponent={Box} visible={active} onBackdropClick={() => setActive(false)} width={700}>
      <Card>
        <Accordion sx={{ padding: 1 }} expanded={!editMailState} onChange={() => setEditMailState(false)}>
          <AccordionSummary>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography variant="h3" color="primary">
                <FontAwesomeIcon icon={faUsers} style={{ marginRight: 10 }} />
                {t('recipients')}
              </Typography>
              {recipientEmails.length === 0 && (
                <Typography variant="h3" color={theme.palette.warning.main} style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} paddingTop={0}>
                <Box sx={{ borderRadius: 2, display: 'flex', alignItems: 'center', backgroundColor: theme.palette.info.lighter, padding: 2 }}>
                  <FontAwesomeIcon icon={faInfoCircle} fixedWidth style={{ width: 32, height: 32, marginRight: 12 }} color={theme.palette.primary.main} />
                  <Typography variant="subtitle1" color={theme.palette.primary.main}>
                    {t('invitation-mail-description')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="email-list-input"
                  options={[]}
                  value={recipientEmails}
                  freeSolo
                  disableCloseOnSelect
                  onChange={(e, v, d) => {
                    setRecipientEmails(v);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      return (
                        <Tooltip placement="top" title={option} key={option}>
                          <Chip variant="outlined" color={(validateEmail(option) ? 'primary' : 'error') as any} label={option} {...getTagProps({ index })} />
                        </Tooltip>
                      );
                    })
                  }
                  renderInput={(params) => {
                    //@ts-ignore
                    const parts = params.inputProps.value.split(/[;,\s\n]/).map((s) => s.trim());
                    //@ts-ignore
                    let value = params.inputProps.value;
                    if (parts.length > 1) {
                      setRecipientEmails(unique([...recipientEmails, ...parts.filter((p) => p.length > 0)]));
                      value = '';
                    }
                    return (
                      <TextField
                        {...params}
                        autoComplete="chrome-off"
                        inputProps={{ ...params.inputProps, value, autoComplete: 'chrome-off' }}
                        variant="outlined"
                        label="E-Mails"
                        placeholder="E-Mails"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack style={{ marginTop: 20 }} spacing={1} direction="row" justifyContent="flex-end">
                <Button variant="outlined" color="error" onClick={() => setActive(false)}>
                  {t('cancel')}
                </Button>
                <Button variant="outlined" color="primary" onClick={() => setEditMailState(!editMailState)}>
                  {t('edit-mail')}
                  <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: 10 }} />
                </Button>
                <LoadingPromiseButton disabled={recipientEmails.length === 0 || subject.length === 0 || message.length === 0} variant="contained" color="primary" onClick={send}>
                  {t('send-mails')}
                  <FontAwesomeIcon icon={faShareAll} style={{ marginLeft: 10 }} />
                </LoadingPromiseButton>
              </Stack>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <Accordion sx={{ padding: 1 }} expanded={editMailState} onChange={() => setEditMailState(true)}>
          <AccordionSummary>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography variant="h3" color="primary">
                <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 10 }} />
                {t('email-editor')}
              </Typography>
              {(subject.length === 0 || message.length === 0) && (
                <Typography variant="h3" color={theme.palette.warning.main} style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ autoComplete: 'chrome-off' }}
                  id="email-subject"
                  variant="outlined"
                  label="Subject"
                  placeholder={t('subject')}
                  fullWidth
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField id="email-message" variant="outlined" label="Message" placeholder={t('message')} multiline fullWidth value={message} onChange={(e) => setMessage(e.target.value)} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack style={{ marginTop: 20 }} spacing={1} direction="row" justifyContent="flex-end">
                <Button variant="outlined" color="error" onClick={() => setActive(false)}>
                  {t('cancel')}
                </Button>
                <Button variant="outlined" color="primary" onClick={() => setEditMailState(!editMailState)}>
                  {t('edit-recipients')}
                  <FontAwesomeIcon icon={faUsers} style={{ marginLeft: 10 }} />
                </Button>
                <LoadingPromiseButton disabled={recipientEmails.length === 0 || subject.length === 0 || message.length === 0} variant="contained" color="primary" onClick={send}>
                  {t('send-mails')}
                  <FontAwesomeIcon icon={faShareAll} style={{ marginLeft: 10 }} />
                </LoadingPromiseButton>
              </Stack>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
    </StandardPopup>
  );
};
