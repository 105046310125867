import React from 'react';

type gridMultilineProps = {
  placeholder: string;
  charMax?: number;
  value: string;
  onText?: (text: string) => void;
  onChange?: (event: any) => void;
  gridRowSpan?: number;
  gridColumnSpan?: number;
  placeUnderneathInput?: boolean;
};

export const GridMultilineInput = ({ value, placeholder, onText, onChange, charMax = 200, gridRowSpan = 3, gridColumnSpan = 1, placeUnderneathInput = true, ...props }: gridMultilineProps) => {
  return (
    <div
      id={placeUnderneathInput ? 'w-node-175bae809891-c87a1d61' : ''}
      style={!placeUnderneathInput ? { gridRow: 'span ' + gridRowSpan, gridColumn: 'span ' + gridColumnSpan } : {}}
      className="grid-con"
    >
      <div className="inner-grid-con">
        <div className="grid-multiline-con">
          {charMax > 0 && (
            <div className="multiline-char-count">
              {value.length} / {charMax}
            </div>
          )}
          <textarea
            maxLength={charMax > 0 ? charMax : undefined}
            className="multiline-text"
            style={{ backgroundColor: 'transparent', color: '#6D6D6D', width: '100%', height: '100%', border: 'none', resize: 'none', fontSize: 18, fontFamily: 'Rubik', fontWeight: 400 }}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onText?.(e.target.value)
              onChange?.(e)
            }}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};
