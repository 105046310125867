import * as React from 'react';

function SvgExclamationTriangleRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.344 20.75" ref={svgRef} {...props}>
      <path d="M10.081 8.3l.267 4.539a.486.486 0 00.485.458h1.677a.486.486 0 00.49-.462l.262-4.535a.486.486 0 00-.485-.515h-2.211a.486.486 0 00-.485.515zm3.293 7.266a1.7 1.7 0 11-1.7-1.7 1.7 1.7 0 011.7 1.696zM13.357.972a1.946 1.946 0 00-3.37 0L.262 17.833a1.946 1.946 0 001.685 2.917H21.4a1.946 1.946 0 001.685-2.917L13.357.972zM2.156 18.44l9.305-16.13a.243.243 0 01.421 0l9.306 16.13a.243.243 0 01-.211.365H2.366a.243.243 0 01-.211-.365z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgExclamationTriangleRegular);
export default ForwardRef;
