import * as React from 'react';

function SvgInfoSquareRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" ref={svgRef} {...props}>
      <path d="M21 2.25v16.5A2.25 2.25 0 0118.75 21H2.25A2.25 2.25 0 010 18.75V2.25A2.25 2.25 0 012.25 0h16.5A2.25 2.25 0 0121 2.25zm-2.25 16.219V2.531a.281.281 0 00-.281-.281H2.531a.281.281 0 00-.281.281v15.938a.281.281 0 00.281.281h15.938a.281.281 0 00.281-.281zM10.5 4.031A1.969 1.969 0 118.531 6 1.969 1.969 0 0110.5 4.031zm2.625 11.906a.563.563 0 01-.562.563H8.438a.563.563 0 01-.562-.562v-1.125a.563.563 0 01.563-.562H9v-3h-.562a.563.563 0 01-.562-.562V9.563A.563.563 0 018.438 9h3a.563.563 0 01.563.563v4.687h.563a.563.563 0 01.563.563z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgInfoSquareRegular);
export default ForwardRef;
