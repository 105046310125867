import { useSortable } from '@dnd-kit/sortable';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Radio, TextField } from '@material-ui/core';
import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { MCItem } from './MultipleChoiceQuestionRenderer';

export function SortableQuestionItem({ id, title, onChange, remove, add, children }: { id: string; title: string; onChange: (value: MCItem) => void; remove: () => void; add: () => void; children: React.ReactNode }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 3,
  };

  return (
    <div style={style} {...attributes} ref={setNodeRef}>
      <div {...listeners} style={{ cursor: 'move' }}>
        {children}
      </div>
      <TextField
        autoFocus
        onFocus={e=> e.target.select()}
        fullWidth
        variant="standard"
        placeholder="Short Answer"
        value={title}
        onChange={(e) => onChange?.({ id, title: e.target.value })}
        onKeyDown={(e) => {
          if (e.key == 'Enter') {
            add();
          }
        }} />
      <IconButton onClick={remove}>
        <FontAwesomeIcon icon={faTrash} size="xs" />
      </IconButton>
    </div>
  );
}
