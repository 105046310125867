import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';
import { Field } from 'formik';
import { TextFieldWithLabel } from '../../../components/users/TextFieldWithLabel';
import { ConditionalRender } from '@base/web';
import { useWithRights } from '../../../helpers/ConditionalRenderWithRights';
import React from 'react';
//@ts-ignore
import PhoneNumberInput from 'react-phone-input-material-ui';

export function BasicInformationEditControl({
  touched,
  errors,
  inCreation,
  isOwn,
  values,
  setFieldValue,
}: {
  errors: any;
  touched: any;
  inCreation: boolean;
  isOwn: boolean;
  values: any;
  setFieldValue: any;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} md={6}>
        <Field
          as={TextFieldWithLabel}
          placeholder="Name"
          required
          name="displayName"
          error={touched.displayName && Boolean(errors.displayName)}
          helperText={touched.displayName && errors.displayName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {/* <TextFieldWithLabel value={secondName} placeholder="Nachname" onChange={setSecondName} /> */}
        <Field as={TextFieldWithLabel} inputType="email" required placeholder="E-Mail" name="email" error={touched.email && Boolean(errors.email)} helperText={touched.email && errors.email} />
      </Grid>
      <Grid item xs={12} md={6}>
        <PhoneNumberInput
          inputProps={{
            variant: 'outlined',
            label: t('phone-number'),
            autoComplete: 'off',
            required: true,
            error: touched.phoneNumber && Boolean(errors.phoneNumber),
            helperText: touched.phoneNumber && errors.phoneNumber,
          }}
          value={values.phoneNumber}
          onChange={(v) => setFieldValue('phoneNumber', v)}
          specialLabel={false}
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ConditionalRender render={useWithRights('user_edit', 'superadmin') || (useWithRights('user_create') && inCreation) || isOwn}>
          <Field as={TextFieldWithLabel} inputType="text" placeholder={t('set-new-password')} name="newPassword" />
        </ConditionalRender>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field as={TextFieldWithLabel} placeholder={t('department')} name="abteilung" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field as={TextFieldWithLabel} inputType="tel" placeholder={t('mobile-number')} name="mobile" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field as={TextFieldWithLabel} inputType="text" placeholder={t('address')} name="address" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field as={TextFieldWithLabel} inputType="text" placeholder={t('job-description')} name="jobTitle" />
      </Grid>
    </>
  );
}
