import * as React from 'react';

function SvgUndoRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.08 20.746" ref={svgRef} {...props}>
      <path d="M.5 0h1.161a.5.5 0 01.5.514l-.1 4.123a10.373 10.373 0 111.696 13.435.5.5 0 01-.02-.729l.826-.826a.5.5 0 01.69-.023A8.2 8.2 0 103.234 7l5.286-.126a.5.5 0 01.514.5v1.159a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5V.5A.5.5 0 01.5 0z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUndoRegular);
export default ForwardRef;
