import { API, FormSubmission } from '@base/core';
import { useDroppable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Badge, Box, Card, Checkbox, Chip, Skeleton, Stack, Typography, useTheme } from '@material-ui/core';
import { format } from 'date-fns';
import type firebasetype from 'firebase/app';
import React, { useMemo } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useObservable } from 'rxjs-hooks';
import { Budget } from '../campaign/Budget';

export interface KanbanItemType {
  id: string;
  name: string;
  icon: string;
  state: string;
  budget?: number;
  budgetOverride?: number;
}

interface InternalKanbanItemType extends KanbanItemType {
  checked?: boolean;
}

function DraggableKanbanItem({ id, index, onClick, onChecked, ...itemData }: InternalKanbanItemType & { onClick?: () => void; onChecked: (checked: boolean) => void; index: number }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'pointer',
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} onClick={onClick}>
          <KanbanItem id={id} {...itemData} isDragging={isDragging} onChecked={onChecked} />
        </div>
      )}
    </Draggable>
  );
}

function getAgreementStatusFromAgreement(agreement: FormSubmission['agreement']) {
  if (agreement.approvedFiles?.length > 0) return <Chip color={'successAction' as any} size="small" label="Approved by IBU" />;
  if (agreement.nfFiles?.length > 0) return <Chip color={'warningAction' as any} size="small" label="Agreement received" />;
  if (agreement.files?.length > 0) return <Chip color={'infoAction' as any} size="small" label="Sent to NF" />;
}

function KanbanItem({
  id,
  name,
  budget,
  state,
  icon,
  isDragging,
  checked,
  onChecked,
  budgetOverride,
  submissionDate,
  lastUpdatedTimestamp,
  ...rest
}: InternalKanbanItemType & { isDragging?: boolean; onChecked?: (checked: boolean) => void; submissionDate?: number; lastUpdatedTimestamp?: firebasetype.firestore.Timestamp }) {
  const me = useSelector((state: Core.StateType) => state.auth.user);
  const chatId = 'formsubmissions:' + id;
  const chats = useObservable(() => API.chats.getChatObservable(chatId)) ?? [];

  const unreadCount = chats.map((c) => !c.read[me.id]).reduce((sum, unread) => sum + (unread ? 1 : 0), 0);
  const { t } = useTranslation();
  return (
    <Badge color="error" badgeContent={unreadCount} sx={{ display: 'block' }}>
      <Card className="no-user-select">
        <Stack padding={1} alignItems="center" direction="row" spacing={1.5}>
          <Box height={28}>{isDragging ? <Skeleton width={28} height={28} variant="circular" /> : <img src={icon} alt="flag" style={{ borderRadius: '50%', height: 28 }} />}</Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" fontWeight="500">
              {isDragging ? <Skeleton width="100%" /> : name}
            </Typography>
            <Typography variant="subtitle2">{isDragging ? <Skeleton width="100%" /> : budget !== undefined ? <Budget budget={budget} budgetOverride={budgetOverride} /> : ''}</Typography>
            {submissionDate && (
              <Typography variant="caption" fontWeight="500" mt={1}>
                {isDragging ? <Skeleton width="100%" /> : 'Date: ' + format(new Date(submissionDate), t('full-date-mask'))}
              </Typography>
            )}
            {!submissionDate && lastUpdatedTimestamp && (
              <Typography variant="caption" fontWeight="500" mt={1}>
                {isDragging ? <Skeleton width="100%" /> : 'Date: ' + format(new Date(lastUpdatedTimestamp.toDate().valueOf()), t('full-date-mask'))}
              </Typography>
            )}
            {(rest as any).agreement && <Box mt={0.5}>{isDragging ? <Skeleton width="100%" /> : getAgreementStatusFromAgreement((rest as any).agreement)}</Box>}
          </Box>
          {onChecked && (
            <Checkbox
              checked={checked}
              onChange={(e) => {
                onChecked(e.target.checked);
              }}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </Stack>
      </Card>
    </Badge>
  );
}

function KanbanList<T extends InternalKanbanItemType>({
  title,
  status,
  onItemChecked,
  onItemClick,
  items,
  color,
}: {
  title: string;
  status: string;
  items: T[];
  color: string;
  onItemClick?: (item: T) => void;
  onItemChecked?: (item: T, checked: boolean) => void;
}) {
  const { setNodeRef } = useDroppable({ id: status });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Droppable droppableId={status}>
      {(provided) => (
        <Card
          {...provided.droppableProps}
          variant="outlined"
          ref={setNodeRef}
          style={{ overflowY: "auto" }}
          sx={{ bgcolor: 'background.grayLight', minWidth: 300, width: 300, overflow: 'visible', position: 'static !important' as any, zIndex: 'unset !important' }}
        >
          <Stack sx={{ padding: 2 }}>
            <Stack flexDirection="row" justifyContent="space-between">
              <Box sx={{ borderRadius: 50, border: 'solid 2px', borderColor: color, paddingY: 0.25, paddingX: 1.5 }}>
                <Typography variant="subtitle1" fontWeight="600">
                  {title}
                </Typography>
              </Box>
            </Stack>
            <Stack ref={provided.innerRef} mt={2} spacing={1.5}>
              {items.map((item, index) => (
                <DraggableKanbanItem index={index} key={item.id} {...item} onClick={() => onItemClick(item)} onChecked={onItemChecked && ((c) => onItemChecked(item, c))} />
              ))}
              {provided.placeholder}
            </Stack>
          </Stack>
        </Card>
      )}
    </Droppable>
  );
}

export function Kanban<T extends KanbanItemType>({
  submissions,
  onStatusChange,
  onSelectionChange,
  selection = [],
  onItemClick,
}: {
  submissions: T[];
  onStatusChange: (newStatus: string, item: T) => void;
  onSelectionChange?: (selection: string[]) => void;
  selection?: string[];
  onItemClick?: (item: T) => void;
}) {
  const map = useMemo(() => {
    const keys = ['draft', 'submitted', 'rejected', 'success', 'review', 'on-hold'];
    const map: { [key: string]: T[] } = keys.reduce((m, k) => ({ ...m, [k]: [] }), {});
    for (const s of submissions) {
      if (keys.includes(s.state)) {
        map[s.state].push(s);
      }
    }
    return map;
  }, [submissions]);

  const theme = useTheme();

  return (
    <Stack direction="row" spacing={2} position="relative">
      <DragDropContext onDragEnd={handleDragEnd}>
        <KanbanList
          title="Draft"
          onItemChecked={onSelectionChange && ((i, c) => onSelectionChange(c ? [...selection, i.id] : selection.filter((s) => i.id !== s)))}
          onItemClick={onItemClick}
          color={theme.palette.grey[500]}
          status={'draft'}
          items={map.draft.map((i) => ({ ...i, checked: selection.includes(i.id) }))}
        />
        <KanbanList
          title="Submissions"
          onItemChecked={onSelectionChange && ((i, c) => onSelectionChange(c ? [...selection, i.id] : selection.filter((s) => i.id !== s)))}
          onItemClick={onItemClick}
          color={theme.palette.grey[500]}
          status={'submitted'}
          items={map.submitted.map((i) => ({ ...i, checked: selection.includes(i.id) }))}
        />
        <KanbanList
          title="In Review"
          onItemChecked={onSelectionChange && ((i, c) => onSelectionChange(c ? [...selection, i.id] : selection.filter((s) => i.id !== s)))}
          onItemClick={onItemClick}
          color={theme.palette.info.main}
          status={'review'}
          items={map.review.map((i) => ({ ...i, checked: selection.includes(i.id) }))}
        />
        <KanbanList
          title="On hold"
          onItemChecked={onSelectionChange && ((i, c) => onSelectionChange(c ? [...selection, i.id] : selection.filter((s) => i.id !== s)))}
          onItemClick={onItemClick}
          color={theme.palette.warning.main}
          status={'on-hold'}
          items={map['on-hold'].map((i) => ({ ...i, checked: selection.includes(i.id) }))}
        />
        <KanbanList
          title="Accepted"
          onItemChecked={onSelectionChange && ((i, c) => onSelectionChange(c ? [...selection, i.id] : selection.filter((s) => i.id !== s)))}
          onItemClick={onItemClick}
          color={theme.palette.success.main}
          status={'success'}
          items={map.success.map((i) => ({ ...i, checked: selection.includes(i.id) }))}
        />
        <KanbanList
          title="Rejected"
          onItemChecked={onSelectionChange && ((i, c) => onSelectionChange(c ? [...selection, i.id] : selection.filter((s) => i.id !== s)))}
          onItemClick={onItemClick}
          color={theme.palette.error.main}
          status={'rejected'}
          items={map.rejected.map((i) => ({ ...i, checked: selection.includes(i.id) }))}
        />
        {/* <DragOverlay>{activeItem ? <KanbanItem {...activeItem} /> : null}</DragOverlay> */}
      </DragDropContext>
    </Stack>
  );

  function handleDragEnd(result: DropResult, provided: ResponderProvided) {
    const { source, destination } = result;

    if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) return;

    // const overContainer = findContainer(overId);

    // if (!activeContainer || !overContainer || activeContainer !== overContainer) {
    //   return;
    // }

    // const activeIndex = map[activeContainer].map((i) => i.id).indexOf(active.id);
    // const overIndex = map[overContainer].map((i) => i.id).indexOf(overId);

    // if (activeIndex !== overIndex) {
    //   setMap((items) => ({
    //     ...items,
    //     [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
    //   }));
    // }
    onStatusChange(destination.droppableId, map[source.droppableId][source.index]);
  }
}
