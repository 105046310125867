import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid } from '@material-ui/core';
import cuid from 'cuid';
import React from 'react';
import * as yup from 'yup';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { SelectableEditor } from './SelectableEditor';
import { SHORT_ANSWER } from './types';

export const CheckboxQuestionRenderer: Renderer<MCItem[], MCResponse> = {
  type: SHORT_ANSWER,
  contentType: 'selectable-item',
  defaultEditorValue: [{ id: cuid(), title: 'Option 1' }],
  defaultReaderValue: {},
  viewer,
  viewerMin,
  editor,
  getValidationSchema,
  getTextRepresentation,
};

type MCItem = {
  id: string;
  title: string;
};

type MCResponse = { [key: string]: boolean };

function viewer({ onChange, value = {}, schema, required, error, touched }: RendererViewerProps<MCItem[], MCResponse>) {
  return (
    <Grid item xs={8}>
      <FormControl error={touched && Boolean(error)} component="fieldset">
        <FormGroup>
          {schema?.map((v) => (
            <Box key={v.id} display="flex" alignItems="center">
              <FormControlLabel checked={value[v.id]} onChange={(e) => onChange({ ...value, [v.id]: (e.target as any).checked })} control={<Checkbox></Checkbox>} label={v.title} />
            </Box>
          ))}
        </FormGroup>
        {touched && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

function viewerMin({ onChange, value = {}, schema }: RendererViewerProps<MCItem[], MCResponse>) {
  return (
    <Grid item xs={8}>
      <FormGroup>
        {schema?.map((v) => (
          <Box key={v.id} display="flex" alignItems="center">
            <FormControlLabel sx={{ fontSize: 15 }} checked={value[v.id] ?? false} onChange={() => {}} control={<Checkbox size="small" />} label={v.title} />
          </Box>
        ))}
      </FormGroup>
    </Grid>
  );
}
async function getTextRepresentation({ onChange, value = {}, schema }: RendererViewerProps<MCItem[], MCResponse>) {
  return schema?.map((v) => `<div>[${value[v.id] ? 'X' : '&nbsp;&nbsp;'}] ${v.title}</div>`).join('');
}

function editor({ onChange, value }: RendererEditorProps<MCItem[], MCResponse>) {
  return <SelectableEditor value={value} onChange={onChange} renderItem={() => <Checkbox disabled></Checkbox>} />;
}

function getValidationSchema(schema, t) {
  const validator = yup.object({});
  return validator;
}
