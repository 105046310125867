import { string } from 'yup';
import React from 'react';
import { Autocomplete, Chip, TextField, Tooltip } from '@material-ui/core';

const validator = string().email();

function unique(value: string[]) {
  const s = value.reduce((map, v) => ({ ...map, [v]: true }), {});
  return Object.keys(s);
}

function validateEmail(email): boolean {
  try {
    validator.validateSync(email);
    return true;
  } catch {
    return false;
  }
}

export function EmailAutocompleteInput({ emails, setEmails }: { emails: string[]; setEmails: (value: string[]) => void }) {
  return (
    <Autocomplete
      multiple
      id="email-list-input"
      options={[]}
      value={emails}
      freeSolo
      disableCloseOnSelect
      onChange={(e, v, d) => {
        setEmails(v as any);
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Tooltip placement="top" title={option} key={index}>
              <Chip key={index} variant="outlined" color={(validateEmail(option) ? 'primary' : 'error') as any} label={option} {...getTagProps({ index })} />
            </Tooltip>
          );
        })
      }
      renderInput={(params) => {
        //@ts-ignore
        const parts = params.inputProps.value.split(/[;,\s\n]/).map((s) => s.trim());
        //@ts-ignore
        let value = params.inputProps.value;
        if (parts.length > 1) {
          setEmails(unique([...emails, ...parts.filter((p) => p.length > 0)]));
          value = '';
        }
        return <TextField {...params} autoComplete="chrome-off" inputProps={{ ...params.inputProps, value, autoComplete: 'chrome-off' }} variant="outlined" label="E-Mails" placeholder="E-Mails" />;
      }}
    />
  );
}
