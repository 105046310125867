import { Observable } from 'rxjs';
import { ChatItem, IChatService, Observer } from '../entities';
import { AuthRepo, UsersRepo } from '../repos';

export class ChatInteractor {
  constructor(private chatService: IChatService) { }

  getChatObservable(chatId: string): Observable<ChatItem[]> {
    return this.chatService.getChatObservable(chatId);
  }

  async readChatItems(chatId: string, chatIds: string[], uid: string): Promise<void> {
    return this.chatService.readChatItems(chatId, chatIds, uid);
  }

  async createChatItem<T extends Omit<ChatItem, "read" | "dateSent" | "id">>(chatId: string, chatItem: T): Promise<void> {
    await this.chatService.createChatItem(chatId, chatItem);
  }
}

