import { AccessRequestFileProps, API, Group, GroupTag, UserCreationData, VirtualFileCreationBase } from '@base/core';
import { Form } from '@editors/form-editor';
import { promises } from 'dns';
import { FileUpdate } from 'libs/base/core/src/redux/files';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export function useDeleteGroupMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (groupId: string) => {
      return await API.groups.deleteGroup(groupId);
    },
    {
      onSuccess: (data, groupId, context) => {
        queryClient.invalidateQueries(['groups', groupId]);
        queryClient.setQueryData(['groups'], (old: Group[]) => {
          if (!old) return old;
          return old.filter((g) => g.id != groupId);
        });
      },
    },
  );
}

export function useDeleteGroupsMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (groupIds: string[]) => {
      return await Promise.all(groupIds.map((id) => API.groups.deleteGroup(id)));
    },
    {
      onSuccess: (data, groupIds, context) => {
        for (const id of groupIds) {
          queryClient.invalidateQueries(['groups', id]);
        }
        queryClient.setQueryData(['groups'], (old: Group[]) => {
          if (!old) return old;
          return old.filter((g) => !groupIds.some((id) => g.id == id));
        });
      },
    },
  );
}

export function useCreateGroupMutation<T extends Omit<Group, 'id'>>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ users, ...rest }: { users: string[] } & T) => {
      const group = await API.groups.createGroup(rest);
      await API.groups.updateUsersForGroup(group.id, { add: users, remove: [] });
      return group;
    },
    {
      onSuccess: (group, req, context) => {
        queryClient.setQueryData(['groups'], (old: Group[]) => [...(old ?? []), group]);
        queryClient.setQueryData(['users'], (old: Core.User[]) => (old ?? []).map((u) => (req.users.some((id) => id == u.id) ? { ...u, groups: { ...u.groups, [group.id]: true } } : u)));
      },
    },
  );
}

export function useUpdateGroupMutation<T extends Group>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ users, id, ...rest }: { users: { added: string[]; removed: string[] } } & T) => {
      console.log('updating:', id);
      await API.groups.updateGroup(id, rest);
      if (users.added.length > 0 || users.removed.length > 0) {
        await API.groups.updateUsersForGroup(id, { add: users.added, remove: users.removed });
        await queryClient.invalidateQueries(['users']);
      }
      await queryClient.invalidateQueries(['groups']);
    },
    {
      onSuccess: (_, req, context) => {
        const { users, ...group } = req;
        queryClient.setQueryData(['groups'], (old: Group[]) => (old ?? []).map((g) => (g.id == req.id ? group : g)));
        queryClient.setQueryData(['users'], (old: Core.User[]) => {
          const userMap: { [key: string]: boolean } = {};
          for (const removed of users.removed) {
            userMap[removed] = false;
          }
          for (const added of users.added) {
            userMap[added] = true;
          }
          return (old ?? []).map((u) => {
            if (userMap[u.id] === undefined) return u;
            return { ...u, groups: { ...u.groups, [group.id]: userMap[u.id] } };
          });
        });
      },
    },
  );
}

export function useDeleteGroupFileGroupMutation<T extends Group>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, filesToDelete, requestData, ...rest }: { filesToDelete: AccessRequestFileProps[] } & T) => {
      console.log('updating:', id);
      const deletePromises = filesToDelete.map((file) => {
        requestData.files = requestData.files.filter((currentFile) => currentFile.firebasePath !== file.firebasePath);
        return API.accessRequest.deleteAccessRequestFile(file);
      });
      await Promise.all(deletePromises);
      await API.groups.updateGroup(id, { requestData, ...rest });
      await queryClient.invalidateQueries(['groups']);
      console.log('finish delete');
    },
    {
      onSuccess: (_, req, context) => {
        const { ...group } = req;
        queryClient.setQueryData(['groups'], (old: Group[]) => (old ?? []).map((g) => (g.id === req.id ? group : g)));
      },
    },
  );
}

export function useDeleteGroupTagMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (groupId: string) => {
      return await API.groups.deleteGroupTag(groupId);
    },
    {
      onSuccess: (data, groupId, context) => {
        queryClient.setQueryData(['grouptags'], (old: Group[]) => {
          if (!old) return old;
          return old.filter((g) => g.id != groupId);
        });
      },
    },
  );
}

export function useDeleteGroupTagsMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (groupIds: string[]) => {
      return await Promise.all(groupIds.map((id) => API.groups.deleteGroupTag(id)));
    },
    {
      onSuccess: (data, groupIds, context) => {
        for (const id of groupIds) {
          queryClient.invalidateQueries(['groups', id]);
        }
        queryClient.setQueryData(['grouptags'], (old: GroupTag[]) => {
          if (!old) return old;
          return old.filter((g) => !groupIds.some((id) => g.id == id));
        });
      },
    },
  );
}

export function useCreateGroupTagMutation<T extends Omit<GroupTag, 'id'>>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ groups, ...rest }: { groups: string[] } & T) => {
      const grouptag = await API.groups.createGroupTag(rest);
      await API.groups.updateGroupsForGroupTag(grouptag.id, { add: groups, remove: [] });
      return grouptag;
    },
    {
      onSuccess: (group, req, context) => {
        queryClient.setQueryData(['grouptags'], (old: GroupTag[]) => [...(old ?? []), group]);
        queryClient.setQueryData(['groups'], (old: Group[]) => (old ?? []).map((g) => (req.groups.some((id) => id == g.id) ? { ...g, groupTag: group.id } : g)));
      },
    },
  );
}

export function useUpdateGroupTagMutation<T extends GroupTag>() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ groups, id, ...rest }: { groups: { added: string[]; removed: string[] } } & T) => {
      await API.groups.updateGroupTag(id, rest);
      await API.groups.updateGroupsForGroupTag(id, { add: groups.added, remove: groups.removed });
    },
    {
      onSuccess: (_, req, context) => {
        const { groups, ...group } = req;
        queryClient.setQueryData(['grouptags'], (old: GroupTag[]) => (old ?? []).map((g) => (g.id == req.id ? group : g)));
        queryClient.setQueryData(['groups'], (old: Group[]) => {
          const groupMap: { [key: string]: boolean } = {};
          for (const removed of groups.removed) {
            groupMap[removed] = false;
          }
          for (const added of groups.added) {
            groupMap[added] = true;
          }
          return (old ?? []).map((u) => {
            if (groupMap[u.id] === undefined) return u;
            return { ...u, groupTag: groupMap[u.id] ? group.id : null };
          });
        });
      },
    },
  );
}

export function useUpdateGroupsMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { added: string[]; removed: string[]; uid: string }>(
    async ({ uid, added, removed }) => {
      await API.users.updateUserGroups(uid, { add: added, remove: removed });
    },
    {
      onSuccess: (group, req, context) => {
        const { uid, added, removed } = req;
        queryClient.setQueryData(['users'], (old: Core.User[]) =>
          (old ?? []).map((u) => {
            if (u.id == uid) {
              const newGroups = { ...u.groups };
              for (const group of removed) {
                delete newGroups[group];
              }
              for (const group of added) {
                newGroups[group] = true;
              }
              return { ...u, groups: newGroups };
            }
            return u;
          }),
        );
      },
    },
  );
}

export function useCreateUserMutation<T extends UserCreationData & Core.User>() {
  const queryClient = useQueryClient();

  return useMutation<Core.User, Error, T & { userImage?: Blob }>(
    async ({ userImage, rights, roles, groups, photoURL, ...rest }) => {
      const user = await API.users.createUser(rest);
      const promises = [];
      if (userImage) {
        promises.push(API.users.setProfileImage(user.id, userImage));
      }
      if (rights) {
        if (Object.keys(rights).length > 0) await API.users.setUserRights(user.id, rights);
      }
      if (roles) {
        await API.users.setUserRoles(user.id, roles);
      }
      if (groups) {
        await API.users.updateUserGroups(user.id, {
          add: Object.entries(groups)
            .filter(([_, v]) => v)
            .map(([k]) => k),
          remove: [],
        });
      }

      await Promise.all(promises);
      return { ...user, ...rest } as Core.User;
    },
    {
      onSuccess: (user, req, context) => {
        queryClient.setQueryData(['users'], (old: Core.User[]) => [...(old ?? []), user]);
      },
    },
  );
}

export function useUpdateUserMutation<T extends Partial<Omit<Core.User & { password: string }, 'groups' | 'rights' | 'roles'>>>() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, T & { userImage?: Blob }>(
    async ({ id, userImage, photoURL, emailVerified, ...update }) => {
      await API.users.updateUser(id, update);
      if (userImage) {
        await API.users.setProfileImage(id, userImage);
      }
    },
    {
      onSuccess: (group, req, context) => {
        const { id, userImage, ...update } = req;
        queryClient.setQueryData(['users'], (old: Core.User[]) => (old ?? []).map((u) => (u.id == id ? { ...u, ...update } : u)));
      },
    },
  );
}

export function useDeleteUserMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(
    async (userId) => {
      await API.users.deleteUser(userId);
    },
    {
      onSuccess: (group, id, context) => {
        queryClient.setQueryData(['users'], (old: Core.User[]) => (old ?? []).filter((u) => u.id !== id));
      },
    },
  );
}

export function useDeleteUsersMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, any, string[]>(
    async (userIds) => {
      await Promise.all(userIds.map((id) => API.users.deleteUser(id)));
    },
    {
      onSuccess: (group, ids, context) => {
        queryClient.setQueryData(['users'], (old: Core.User[]) => (old ?? []).filter((u) => !ids.some((id) => id == u.id)));
      },
    },
  );
}

export type EMail = string;

export function usePasswordResetMutation() {
  return useMutation<void, Error, EMail>(async (email) => {
    await API.auth.resetPassword(email);
  });
}
