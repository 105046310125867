import { useQuery, UseQueryOptions } from 'react-query';
import { API, Campaign, FormSubmission, getKeysWithTruthyValues } from '@base/core';
import { Form } from '@editors/form-editor';
import { useSelector } from 'react-redux';
import { useGetFile } from '../../campaign/hooks';

export function useGetFormContent(campaignId: string, formId: string, queryOptions?: UseQueryOptions<FormSubmission, unknown, FormSubmission>) {
  const user = useSelector((state: Core.StateType) => state.auth.user)

  const { data: campaign } = useGetFile<Campaign>(campaignId);


  const groupid = getKeysWithTruthyValues(user.groups).find(gid => campaign?.selectedGroups?.[gid])

  return useQuery(['campaigns', campaignId, 'formssubmissions', formId, groupid], async () => {

    return await API.campaigns.getFormViewerContent(campaignId, formId, groupid);
  }, { enabled: Boolean(campaign), ...queryOptions });
}
