import * as React from 'react';

function SvgH3(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 20" ref={svgRef} {...props}>
      <path d="M.62 19.718a.584.584 0 01-.451-.183A.659.659 0 010 19.07V.676A.7.7 0 01.169.183.584.584 0 01.62 0h1.352a.666.666 0 01.493.183.666.666 0 01.183.493v7.831h9.8V.676a.666.666 0 01.183-.493.63.63 0 01.468-.183h1.352a.666.666 0 01.493.183.666.666 0 01.183.493V19.07a.607.607 0 01-.2.465.678.678 0 01-.479.183h-1.349a.63.63 0 01-.465-.183.63.63 0 01-.183-.465v-8.113h-9.8v8.113a.607.607 0 01-.2.465.678.678 0 01-.479.183zM25.915 20a9.253 9.253 0 01-3.8-.718 5.856 5.856 0 01-2.437-1.873 4.4 4.4 0 01-.887-2.394.528.528 0 01.169-.408.6.6 0 01.423-.155h1.268a.923.923 0 01.507.127.862.862 0 01.31.493A2.923 2.923 0 0023.06 17.1a6.576 6.576 0 002.887.592 4.968 4.968 0 003.225-.972 3.368 3.368 0 001.2-2.775q0-3.437-4.2-3.437h-2.651a.63.63 0 01-.465-.183.63.63 0 01-.183-.465v-.732a1 1 0 01.31-.732l5.493-6.085h-8.338a.659.659 0 01-.465-.169.584.584 0 01-.183-.451V.677a.666.666 0 01.183-.493.63.63 0 01.465-.184h11.155a.666.666 0 01.493.183.666.666 0 01.183.493v.93a1.032 1.032 0 01-.282.648l-5.493 6.169h.451a7.193 7.193 0 014.549 1.493 5.069 5.069 0 011.676 4.112 5.523 5.523 0 01-.915 3.2 5.86 5.86 0 01-2.549 2.056 9.079 9.079 0 01-3.691.716z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgH3);
export default ForwardRef;
