import { Actions } from '@base/core';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { StandardButton, useNotificationEffect } from '../../components/common';
import { ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/components/common/content/gridContent/flexWrapper.scss';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { TextFieldWithLabel } from '../../components/users/TextFieldWithLabel';

export const UsersCreation = () => {
  const { t } = useTranslation();
  // const { uid } = useParams<{ uid: string }>();
  const {
    // users,
    fetching: {
      createUser: { error, success },
    },
  } = useSelector((state: Core.StateType) => state.users);

  // const { user } = useSelector((state: Core.StateType) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  useNotificationEffect({ title: t('error'), type: 'error', text: error?.error?.message }, error && !success, [error, success]);

  const schema = yup.object({
    email: yup
      .string()
      .email(t('enter-a-valid-email'))
      .required(t('something-is-required', { something: t('email') })),
    password: yup
      .string()
      .min(8, t('password-should-be-minimum-of-8-characters-in-length'))
      .required(t('something-is-required', { something: t('password') })),
    displayName: yup.string().required(t('something-is-required', { something: t('name') })),
  });

  return (
    <Formik
      initialValues={{
        displayName: '',
        email: '',
        mobile: '',
        phoneNumber: '',
        newPassword: '',
        abteilung: '',
        password: '',
      }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        const res: boolean = (await dispatch(
          Actions().users.createUser({
            ...values,
            emailVerified: true,
          }),
        )) as any;

        if (res) history.goBack();
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, values, setValues, errors, touched, ...formik }) => {
        console.log({ isSubmitting, submitForm, values, setValues, errors, touched, ...formik });

        return (
          <Form className="content-section" autoComplete="off">
            <NavbarSmall title={t('neuer-nutzer')} breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.users, BREADCRUMBS.userCreation]}>
              <div style={{ display: 'flex' }}>
                <StandardButton contentColor="#ffffff" onClick={() => history.goBack()} text={t('cancel')} bgColor="#ffffff30" />
                <StandardButton contentColor="white" onClick={submitForm} text={t('add-user')} bgColor="#ffffff30" faIcon={faPlusCircle} loading={isSubmitting} />
              </div>
            </NavbarSmall>
            <ContentView>
              <div className="content-title">{t('personal-data')}</div>
              <div className="flex-wrapper" style={{ marginTop: 40, marginBottom: 60 }}>
                <div className="input-grid" style={{ marginTop: 0, rowGap: 24 }}>
                  <Field
                    as={TextFieldWithLabel}
                    placeholder={t('name')}
                    name="displayName"
                    required
                    error={touched.displayName && Boolean(errors.displayName)}
                    helperText={touched.displayName && errors.displayName}
                  />
                  <Field as={TextFieldWithLabel} placeholder={t('email')} name="email" required error={touched.email && Boolean(errors.email)} helperText={touched.email && errors.email} />
                  <Field
                    as={TextFieldWithLabel}
                    placeholder={t('password')}
                    name="password"
                    required
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <Field
                    as={TextFieldWithLabel}
                    placeholder={t('department')}
                    name="abteilung"
                    error={touched.abteilung && Boolean(errors.abteilung)}
                    helperText={touched.abteilung && errors.abteilung}
                  />
                  <Field
                    as={TextFieldWithLabel}
                    placeholder={t('phone-number')}
                    name="phoneNumber"
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                  <Field as={TextFieldWithLabel} placeholder={t('mobile-number')} name="mobile" error={touched.mobile && Boolean(errors.mobile)} helperText={touched.mobile && errors.mobile} />
                </div>
              </div>
            </ContentView>
          </Form>
        );
      }}
    </Formik>
  );
};
