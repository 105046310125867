import { API, ApplicationForm, FormFile, FormSubmission, Group } from '@base/core';
import { SimpleFileProvider } from '@base/firebase';
import { FormViewerMinimal, FormViewerPDF, getBudgetFromDocument } from '@editors/form-editor';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Stack, TextField, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useObservable } from 'rxjs-hooks';
import { usePopup } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import { useUpdateFormContentMutation } from '../externalUsers/hooks/mutations';
import { useDeleteFormContentMutation } from './hooks';
import { useGetFormSubmissionHistory } from './hooks/useGetFormSubmissionHistory';
import { sendAcceptedNotification } from './sendAcceptedNotification';
import { ESignAgreementAccordion } from './eSign';
import { FileUploadPopup } from '../../components/agreements';
import { AgreementsAccordion } from './Agreements';
import {useParams} from "react-router-dom";

export function FormSubmissionDrawer({ content, groups, form, onClose }: { groups: Group[]; content: FormSubmission; form: ApplicationForm; onClose: () => void }) {
  const group = groups.find((g) => g.id === content?.entityId);
  const writeFileProvider = useMemo(() => new SimpleFileProvider('/campaigns/forms/response_files'), []);
  const chatId = 'formsubmissions:' + content?.id;
  const chats = useObservable(() => API.chats.getChatObservable(chatId));
  const theme = useTheme();
  const notify = usePopup();
  const user = useSelector((state: Core.StateType) => state.auth.user);
  const { t } = useTranslation();
  const { data: formSubmissionHistory = [] } = useGetFormSubmissionHistory(content?.campaignId, content?.id);
  const nfFileProvider = useMemo(() => new SimpleFileProvider('/forms/agreements-nf'), []);
  const ibuFileProvider = useMemo(() => new SimpleFileProvider('/forms/agreements'), []);
  const [approvedAgreementPopupOpen, setApprovedAgreementPopupOpen] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [NFAgreementPopupOpen, setNFAgreementPopupOpen] = useState(false);
  const originalBudget = getBudgetFromDocument(form, content?.data ?? {});

  const mutation = useUpdateFormContentMutation();

  const context = useMemo(() => {
    return {
      group,
      form
    }
  }, [group, form])

  const handleSendChat = async (message: string, questionId: string) => {
    try {
      await API.chats.createChatItem(chatId, {
        creator: user.id,
        text: message,
        questionId,
      });
      await API.email.sendChatNotificationEmail(
        { groupIds: [content.entityId] },
        message,
        form.content.find((c) => c.id == questionId)?.title ?? '',
        `${window.location.origin}/campaigns-external/${content.campaignId}/forms/${form.id}`,
        form.name,
      );
    } catch (error) {
      notify({
        title: t('error'),
        type: 'error',
        text: error.message || error,
      });
    }
  };
  const handleReadChatItems = (chatItems: string[]) => {
    void API.chats.readChatItems(chatId, chatItems, user.id);
  };
  const updateSubmissionMutation = useUpdateFormContentMutation();

  const formViewerRef = useRef<{ print: () => void }>(null);

  const changeBudget = async (budget: number) => {
    const update = {
      campaignId: content.campaignId,
      data: {
        state: 'success',
        budgetOverride: budget,
      },
      formId: form.id,
      groupId: content.entityId,
    } as any;
    await updateSubmissionMutation.mutateAsync(update);
    await sendAcceptedNotification(content.entityId, 'accept', budget, content.campaignId, content.formId, form.name);
    onClose();
  };

  const addAgreementFilesToSubmission = (files: FormFile[]) => {
    // eslint-disable-next-line no-restricted-globals
    const ok = confirm('Send "Agreement is ready to be signed" email?');
    if (ok) {
      void API.email.sendNotificationEmail({ groupIds: [content.entityId] }, 'Agreement is ready to be signed!', {
        autosend: true,
        buttonLink: window.location.origin + '/submissions-external/' + content.entityId,
        buttonText: 'Open my submissions',
        emailTitle: 'Agreement is ready to be signed!',
        paragraphTitle: 'Application: ' + form.name,
        emailText: `
      <p>Dear National Federation</p></br>
      <p>Your agreement is ready to be signed by respective representatives from your organisation. </p><p>Please download the agreement, check the data, sign it and upload to the IBU Scope. After that the IBU approves the agreement and transfers the approved budget.</p>
      <br>
      <p>You can download the agreement under “Sent Submissions” in the dashboard.</p>
      <br>`,
        belowButtonText: `
      <p>Best regards,</p>
      </br>
      <p>your IBU Scope Team</p>
      `,
      });
    }
    return updateSubmissionMutation.mutateAsync({
      campaignId: content.campaignId,
      data: {
        'agreement.files': files,
      } as any,
      formId: form.id,
      groupId: content.entityId,
    });
  };

  const addApprovedAgreementFilesToSubmission = (files: FormFile[]) => {
    // eslint-disable-next-line no-restricted-globals
    const ok = confirm('Send "Agreement has been signed by IBU" email?');
    if (ok)
      void API.email.sendNotificationEmail({ groupIds: [content.entityId] }, 'Agreement has been signed by IBU!', {
        autosend: true,
        buttonLink: window.location.origin + '/submissions-external/' + content.entityId,
        buttonText: 'Open my submissions',
        emailTitle: 'Agreement has been signed by IBU!',
        paragraphTitle: 'Application: ' + form.name,
        emailText: `<p>Your agreement has been signed by IBU. </p>
      <br>
      <br>
      <p>You can download the agreement under “Sent Applications” in the dashboard.</p>
      <br>`,
        belowButtonText: `
      <p>Best regards,</p>
      </br>
      <p>your IBU Scope Team</p>
      `,
      });
    return updateSubmissionMutation.mutateAsync({
      campaignId: content.campaignId,
      data: {
        'agreement.approvedFiles': files,
      } as any,
      formId: form.id,
      groupId: content.entityId,
    });
  };

  const addNFAgreementFilesToSubmission = (files: FormFile[]) => {
    const { formId, entityId, campaignId } = content;

    return updateSubmissionMutation.mutateAsync({
      campaignId: campaignId,
      data: {
        'agreement.nfFiles': files,
      } as any,
      formId,
      groupId: entityId,
    });
  };

  const formContentMutation = useDeleteFormContentMutation();

  const [agreementPopupOpen, setAgreementPopupOpen] = useState(false);
  const [openChangeBudgetPopup, setOpenChangeBudgetPopup] = useState(false);
  const [budget, setBudget] = useState(content?.budgetOverride || content?.data?.budget);
  const [submitterNameLoading, setSubmitterNameLoading] = useState(false);

  const [submitterName, setSubmitterName] = useState(undefined);
  const [submissionDate, setSubmissionDate] = useState(undefined);
  const [submissionTime, setSubmissionTime] = useState(undefined);

  const [lastSubmitterName, setLastSubmitterName] = useState(undefined);
  const [lastSubmissionDate, setLastSubmissionDate] = useState(undefined);
  const [lastSubmissionTime, setLastSubmissionTime] = useState(undefined);

  useEffect(() => {
    void getSubmitionDetails();
    // console.log(content);
  }, [content]);

  async function getSubmitionDetails() {
    try {
      const lastTimestampDate = content?.lastUpdatedTimestamp?.toDate();
      setLastSubmissionDate(lastTimestampDate.toLocaleDateString('de'));
      setLastSubmissionTime(lastTimestampDate.toLocaleTimeString('de', { timeStyle: 'short' }));
      setSubmitterNameLoading(true);
      const lastUser = await API.users.getUserById(content.lastUpdatedSubmitterId);
      setLastSubmitterName(lastUser.displayName);
    } catch (error) {
      setLastSubmitterName(undefined);
    }
    setSubmitterNameLoading(false);

    try {
      const timestampDate = content.timestamp ? content.timestamp.toDate() : new Date(content.submissionDate);
      setSubmissionDate(timestampDate.toLocaleDateString('de'));
      setSubmissionTime(timestampDate.toLocaleTimeString('de', { timeStyle: 'short' }));
      setSubmitterNameLoading(true);
      const user = await API.users.getUserById(content.submitterId);
      setSubmitterName(user.displayName);
    } catch (error) {
      setSubmitterName(undefined);
    }
    setSubmitterNameLoading(false);
  }

  if (!group) {
    return (
      <Stack spacing={4} sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={64} />
        <Typography variant="h4">Searching Submission...</Typography>
      </Stack>
    );
  }
  return (
    <Stack spacing={4}>
      <Box sx={{ padding: 2, backgroundColor: theme.palette.background.gray }}>
        <Typography variant="subtitle2">Submission</Typography>
        <Typography fontWeight="500" variant="h5">
          {group.name}
        </Typography>
        <Stack spacing={0} flexDirection="row" flexWrap="wrap">
          {form.budgetField && (
            <Button size="small" sx={{ mt: 1, mr: 1 }} variant="contained" onClick={() => setOpenChangeBudgetPopup(true)}>
              {t('change_budget')}
            </Button>
          )}
          <Button size="small" sx={{ mt: 1, mr: 1 }} variant="contained" onClick={() => formViewerRef.current.print()}>
            {t('print')}
          </Button>
          <Button size="small" sx={{ mt: 1, mr: 1 }} color="error" variant="contained" onClick={() => setOpenDeletePopup(true)}>
            {t('delete')}
          </Button>
        </Stack>
      </Box>
      <FileUploadPopup
        initialFiles={content.agreement?.files}
        title="Upload Agreements"
        fileProvider={ibuFileProvider}
        onClose={() => setAgreementPopupOpen(false)}
        open={agreementPopupOpen}
        onSave={addAgreementFilesToSubmission}
      />
      <FileUploadPopup
        initialFiles={content.agreement?.approvedFiles}
        title="Upload Approved Agreements"
        fileProvider={ibuFileProvider}
        onClose={() => setApprovedAgreementPopupOpen(false)}
        open={approvedAgreementPopupOpen}
        onSave={addApprovedAgreementFilesToSubmission}
      />
      <FileUploadPopup
        initialFiles={content.agreement?.nfFiles}
        title="Upload signed NF Agreements"
        fileProvider={nfFileProvider}
        onClose={() => setNFAgreementPopupOpen(false)}
        open={NFAgreementPopupOpen}
        onSave={addNFAgreementFilesToSubmission}
      />
      <ESignAgreementAccordion formSubmission={content} style={{ marginLeft: 16, marginRight: 16 }} />
      <AgreementsAccordion
        content={content}
        onSendToNFClick={() => setAgreementPopupOpen(true)}
        sentBackFromNFClick={() => setNFAgreementPopupOpen(true)}
        verifiedByIBUClick={() => setApprovedAgreementPopupOpen(true)}
        style={{ marginLeft: 16, marginRight: 16 }}
      />
      {(content.submissionDate || content.timestamp || content.submitterId || content.lastUpdatedSubmitterId || content.lastUpdatedTimestamp) && (
        <Accordion defaultExpanded style={{ margin: 0 }}>
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            <Typography variant="overline">Submission Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {(content.submissionDate || content.timestamp) && (
              <Typography style={{ display: 'flex' }} fontWeight={400}>
                Initially submitted at:
                <Typography fontWeight={500} style={{ marginLeft: 10 }}>
                  {submissionDate} - {submissionTime}
                </Typography>
              </Typography>
            )}
            {content.submitterId &&
              (submitterNameLoading ? (
                <Typography fontWeight={400}>
                  Initially submitted by: <CircularProgress size={14} style={{ marginLeft: 10 }} />
                </Typography>
              ) : (
                submitterName && (
                  <Typography style={{ display: 'flex', marginTop: 10 }} fontWeight={400}>
                    Initially submitted by:
                    <Typography fontWeight={500} style={{ marginLeft: 10 }}>
                      {submitterName}
                    </Typography>
                  </Typography>
                )
              ))}
            {content.lastUpdatedTimestamp && (
              <Typography style={{ display: 'flex', marginTop: 20 }} fontWeight={400}>
                Last updated at:
                <Typography fontWeight={500} style={{ marginLeft: 10 }}>
                  {lastSubmissionDate} - {lastSubmissionTime}
                </Typography>
              </Typography>
            )}
            {content.lastUpdatedSubmitterId &&
              (submitterNameLoading ? (
                <Typography fontWeight={400}>
                  Last updated by: <CircularProgress size={14} style={{ marginLeft: 10 }} />
                </Typography>
              ) : (
                lastSubmitterName && (
                  <Typography style={{ display: 'flex', marginTop: 10 }} fontWeight={400}>
                    Last updated by:
                    <Typography fontWeight={500} style={{ marginLeft: 10 }}>
                      {lastSubmitterName}
                    </Typography>
                  </Typography>
                )
              ))}
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
          <Typography variant="overline">Application Contents</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormViewerMinimal
            document={form}
            review={content.review}
            action={(questionId, action) => {
              mutation.mutate({
                campaignId: content.campaignId,
                formId: content.formId,
                groupId: content.entityId,
                data: {
                  [`review.${questionId}`]: { type: action },
                },
              });
            }}
            formSubmissionHistory={formSubmissionHistory}
            uploadFileProvider={writeFileProvider}
            value={{ ...content, entityName: group.name }}
            chats={(chats as any) || []}
            onSendChat={handleSendChat}
            readChatItems={handleReadChatItems}
            context={context}
          />
        </AccordionDetails>
      </Accordion>
      <MainDialog
        open={openDeletePopup}
        onCloseClick={() => setOpenDeletePopup(false)}
        onSaveClick={async () => {
          await formContentMutation
            .mutateAsync({
              campaignId: content.campaignId,
              formId: content.formId,
              storageId: content.entityId,
            })
            .then(() => onClose());
          setOpenDeletePopup(false);
        }}
        saveButtonColor="error"
        secondButtonColor="primary"
        modalTitle={t('confirm-delete')}
        description={t('delete-form-title')}
        buttonText={t('delete')}
      />
      <MainDialog
        modalTitle={t('change_budget')}
        description={`The requested Budget from ${group.name} is ${originalBudget} €`}
        open={openChangeBudgetPopup}
        onCloseClick={() => setOpenChangeBudgetPopup(false)}
        buttonText="Accept with new Budget"
        onSaveClick={async () => {
          await changeBudget(budget);
        }}
      >
        <TextField label={t('corrected_budget')} sx={{ marginTop: 2 }} fullWidth variant="outlined" value={budget} type="number" onChange={(e) => setBudget(parseFloat(e.target.value))} />
      </MainDialog>
      <FormViewerPDF
        document={form}
        uploadFileProvider={writeFileProvider}
        value={{
          ...content,
          entityName: group.name,
          submissionDate,
          submitterName,
          lastSubmissionDate,
          lastSubmitterName,
        }}
        ref={formViewerRef}
      />
    </Stack>
  );
}
