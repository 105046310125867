import * as React from 'react';

function SvgPaintRollerRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.745 20.745" ref={svgRef} {...props}>
      <path d="M18.476 2.917h-1.621v-.972A1.945 1.945 0 0014.911 0H1.945A1.945 1.945 0 000 1.945v3.89a1.945 1.945 0 001.945 1.944h12.966a1.945 1.945 0 001.945-1.945v-.972h1.621a.325.325 0 01.324.324v3.89a.325.325 0 01-.324.324h-8.1A2.271 2.271 0 008.1 11.669v1.3h-.321a1.3 1.3 0 00-1.3 1.3v5.186a1.3 1.3 0 001.3 1.3h2.593a1.3 1.3 0 001.3-1.3v-5.193a1.3 1.3 0 00-1.3-1.3h-.324v-1.3a.325.325 0 01.324-.324h8.1a2.271 2.271 0 002.269-2.269V5.186a2.271 2.271 0 00-2.265-2.269zm-3.565 2.917H1.945V1.945h12.966zM9.724 18.8h-1.3v-3.89h1.3z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPaintRollerRegular);
export default ForwardRef;
