import React from 'react';

interface ContentContainerProps {
    icon: string;
    title: string;
    fileQuantity: number;
    description: string;
}

export const ContentTitleArea = ({ icon, title, fileQuantity = 0, description }: ContentContainerProps) => {
    return (
        <div className="content-title-area">
            <img src={icon} className="icon-title-area" />
            <div>
                <div className="text-row">
                    <div className="title-area-title">{title}</div>
                    <div className="title-area-quantity">{fileQuantity} Dateien</div>
                </div>
                <div className="title-area-description">{description}</div>
            </div>
        </div>
    );
};
