import { API, Campaign, FormSubmission, getKeysWithTruthyValues } from '@base/core';
import { QueryObserverResult, useQueries, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

export function useGetMultipleFormSubmissions(data: { campaignId: string; formId: string }[], groupId?: string) {
  const user = useSelector((state: Core.StateType) => state.auth.user);
  const queryClient = useQueryClient();

  return useQueries(
    data.map(({ campaignId, formId }) => ({
      queryKey: ['campaigns', campaignId, 'formssubmissions', formId],
      async queryFn() {
        const campaign = await queryClient.fetchQuery({
          queryKey: ['campaigns', campaignId],
          queryFn: async () => (await API.campaigns.getFile(campaignId)) as Campaign,
        });
        if (groupId) return await API.campaigns.getFormViewerContent(campaignId, formId, groupId);
        const groupid = getKeysWithTruthyValues(user.groups).find((gid) => campaign?.selectedGroups?.[gid]);
        return await API.campaigns.getFormViewerContent(campaignId, formId, groupid);
      },
    })),
  ) as QueryObserverResult<FormSubmission, unknown>[];
}
