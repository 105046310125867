import '../../../css/components/common/popups/NavigationPopup.scss';

import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import type { ReactChildrenProp } from '../../../lib';

type NavigationItemProps = {
  disabled?: boolean;
  children?: ReactChildrenProp;
  className?: string;
};

export const NavigationItem = ({ disabled = false, children, className }: NavigationItemProps) => {
  return !disabled ? <div className={'navigation-menu-item ' + className}>{children}</div> : null;
};

type NavigationTextItemProps = {
  text?: string;
  onClick?: any;
  active?: boolean;
  disabled?: boolean;
  hoverAnimation?: boolean;
  className?: string;
};

export const NavigationTextItem = ({ text, onClick, active = false, disabled = false, hoverAnimation = false, className }: NavigationTextItemProps) => {
  return (
    <NavigationItem disabled={disabled}>
      <div className={'navigation-popup-item space-between' + (active ? ' active' : '') + (hoverAnimation ? ' hover' : '' + ' ' + className)} onClick={onClick}>
        <div>
          <div className="navigation-heading-text">{text}</div>
          <div className="navigation-popup-icon right" hidden={!active}>
            <FontAwesomeIcon icon={faCheck} style={{ height: '13px' }} />
          </div>
        </div>
      </div>
    </NavigationItem>
  );
};

type NavigationNavigatorProps = {
  setActiveMenu: React.Dispatch<React.SetStateAction<string>>;
  text: string;
  onClick?: any;
  leftIcon?: any;
  rightIcon?: any;
  goToMenu: string;
  children?: ReactChildrenProp;
  active?: boolean;
  disabled?: boolean;
};
export const NavigationNavigator = ({ leftIcon, goToMenu, text, setActiveMenu, rightIcon }: NavigationNavigatorProps) => {
  return (
    <div className={rightIcon ? 'navigation-menu-item navigation-popup-item space-between hover' : 'navigation-menu-item navigation-popup-item hover'} onClick={() => setActiveMenu(goToMenu)}>
      <div className="navigation-popup-icon left" hidden={!leftIcon}>
        <FontAwesomeIcon icon={leftIcon} style={{ fontSize: '13px' }} />
      </div>
      <div className="navigation-heading-text">{text}</div>
      <div className="navigation-popup-icon right" hidden={!rightIcon}>
        <FontAwesomeIcon icon={rightIcon} style={{ fontSize: '13px', marginRight: 2 }} />
      </div>
    </div>
  );
};

type NavigationMenuProps = {
  activeMenu: React.ComponentState;
  setMenuHeight: React.Dispatch<React.SetStateAction<number>>;
  name: string;
  children?: ReactChildrenProp;
  className?: string;
};
export const NavigationMenu = ({ name, children, setMenuHeight, activeMenu, className = '' }: NavigationMenuProps) => {
  function calc(el: { offsetHeight: number }) {
    const height: number = el.offsetHeight;
    setMenuHeight(height);
  }
  return (
    <CSSTransition
      in={activeMenu === name}
      timeout={500}
      classNames={name == 'main' ? 'menu-primary' : 'menu-secondary'}
      unmountOnExit
      //@ts-ignore
      onEnter={calc}
    >
      <div className={className}>{children}</div>
    </CSSTransition>
  );
};

type NavigationMenuContainerProps = {
  menuHeight: React.ComponentState;
  setMenuHeight?: React.Dispatch<React.SetStateAction<number>>;
  children?: ReactChildrenProp;
  navigationMenuRef: any;
  style?: React.CSSProperties;
  className?: string;
};
export function NavigationMenuContainer({ children, menuHeight, navigationMenuRef, style, className = 'navigation-menu-popup' }: NavigationMenuContainerProps) {
  return (
    <div className={'navigation-menu-container ' + className} style={{ height: menuHeight, ...style }} ref={navigationMenuRef} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  );
}
