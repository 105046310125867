import * as React from 'react';

function SvgNothingHere(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="195.58" height="140" viewBox="0 0 195.58 199.963" {...props}>
      <g id="Gruppe_12322" data-name="Gruppe 12322" transform="translate(-856 -553.814)">
        <g id="Gruppe_12287" data-name="Gruppe 12287" transform="translate(0 42)">
          <g id="Gruppe_12286" data-name="Gruppe 12286" transform="translate(4153.035 17636)">
            <path id="Pfad_14100" data-name="Pfad 14100" d="M2954.588,308.3l-85.448,39.605.533-96.913,85.144-27.243Z" transform="translate(-6077.491 -17272.129)" fill="#b78c58" />
            <path id="Pfad_14101" data-name="Pfad 14101" d="M2633.19,185.531l64.108,23.353,84.917-27.243-78.58-15.531Z" transform="translate(-5905.116 -17230.02)" fill="#99744b" />
            <path id="Pfad_14102" data-name="Pfad 14102" d="M2894.64,125.17l30.288-15.1,69.737,15.1L2973.446,140.7Z" transform="translate(-6096.12 -17189.078)" fill="#f2d08b" />
            <path id="Pfad_14103" data-name="Pfad 14103" d="M2894.64,166.11v41.343l16.934-6.035,61.872-19.777Z" transform="translate(-6096.12 -17230.02)" fill="#d3b875" />
            <path id="Pfad_14104" data-name="Pfad 14104" d="M2697.3,261.543l-.534,96.913-63.575-26.268v-94Z" transform="translate(-5905.116 -17282.678)" fill="#d3b875" />
            <path id="Pfad_14105" data-name="Pfad 14105" d="M2871.12,250.993l19.637,34.836,86.725-34.467-21.219-27.612Z" transform="translate(-6078.938 -17272.129)" fill="#f2d08b" />
            <path id="Pfad_14106" data-name="Pfad 14106" d="M2629.218,261.543l-19.971,32.373L2540,263.822l25.109-25.632Z" transform="translate(-5837.035 -17282.678)" fill="#f2d08b" />
            <path id="Pfad_14107" data-name="Pfad 14107" d="M2581.963,117.093,2563.07,86.269l72.342-13.839,17,25.241Z" transform="translate(-5853.89 -17161.58)" fill="#d3b875" />
          </g>
          <g id="Gruppe_12289" data-name="Gruppe 12289" transform="translate(-18 5)">
            <ellipse
              id="Ellipse_709"
              data-name="Ellipse 709"
              cx="3.237"
              cy="2.018"
              rx="3.237"
              ry="2.018"
              transform="matrix(0.934, 0.358, -0.358, 0.934, 956.663, 530.814)"
              fill="#464646"
              opacity="0.23"
            />
            <ellipse id="Ellipse_710" data-name="Ellipse 710" cx="3.237" cy="2.518" rx="3.237" ry="2.518" transform="matrix(0.899, -0.438, 0.438, 0.899, 956.848, 533.986)" fill="#464646" />
            <ellipse id="Ellipse_711" data-name="Ellipse 711" cx="3.237" cy="2.018" rx="3.237" ry="2.018" transform="translate(961.9 540.672) rotate(-121)" fill="#464646" opacity="0.23" />
          </g>
          <g id="Gruppe_12288" data-name="Gruppe 12288" transform="translate(985.217 511.814)">
            <ellipse id="Ellipse_712" data-name="Ellipse 712" cx="3.237" cy="2.018" rx="3.237" ry="2.018" transform="translate(10.142 3.768) rotate(159)" fill="#464646" opacity="0.23" />
            <ellipse id="Ellipse_713" data-name="Ellipse 713" cx="3.237" cy="2.518" rx="3.237" ry="2.518" transform="matrix(-0.899, -0.438, 0.438, -0.899, 6.304, 7.698)" fill="#464646" />
            <ellipse id="Ellipse_714" data-name="Ellipse 714" cx="3.237" cy="2.018" rx="3.237" ry="2.018" transform="translate(0 7.779) rotate(-59)" fill="#464646" opacity="0.23" />
          </g>
        </g>
        <path
          id="Pfad_14119"
          data-name="Pfad 14119"
          d="M-.967,2.361s-15.812,15.61-14.312,31.36,28.835,36.2,28.835,36.2"
          transform="translate(940.5 583.5)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
          strokeDasharray="7"
          opacity="0.16"
        />
        <path
          id="Pfad_14120"
          data-name="Pfad 14120"
          d="M-3.98-17.737S16.751-12.792,23.592-1.11s-1.9,26.351-2.315,30.729c-1.5,15.75-37.043,37.464-37.043,37.464"
          transform="translate(995.5 577.5)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
          strokeDasharray="7"
          opacity="0.16"
        />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgNothingHere);
export default ForwardRef;
