import React, { useEffect } from 'react';
import { Dashboard, DashboardProvider } from '../components/DashboardHelpers';
import { DeepPartial } from '../helpers/DeepPartial';
import { DefaultTheme, ThemeType } from '../theme/Theme';
import { ThemeProvider } from '../theme/ThemeProvider';
import { Router } from './Router';
import { createTheme } from '@atlaskit/theme';
import { ThemeOptions } from '@material-ui/core';

function deepOverrideObject<T extends { [key: string]: any }>(original: T, overrides: DeepPartial<T>): T {
  const copy = { ...original };
  for (const [key, value] of Object.entries(original)) {
    if (overrides[key] !== undefined) {
      if ((typeof overrides[key] === 'object' || typeof overrides[key] === 'symbol') && !Array.isArray(overrides[key])) {
        //@ts-ignore
        copy[key] = deepOverrideObject(value, overrides[key]);
      } else {
        //@ts-ignore
        copy[key] = overrides[key];
      }
    }
  }
  return copy;
}

function applyThemeOverrides(overrides: DeepPartial<ThemeType>, theme: ThemeType): ThemeType {
  const { images, ...themeToEdit } = theme;
  const { images: imagesOverrides, ...overridesToEdit } = overrides;
  theme = deepOverrideObject<ThemeType>(themeToEdit as ThemeType, overridesToEdit);
  theme.images = { ...images, ...(imagesOverrides ?? {}) };
  return theme;
}
type ThemeProps = any;
interface ThemeTokens {
  backgroundColor?: string;
  textColor?: string;
}
export type ThemeOverrides = DeepPartial<ThemeType>;
const Theme = createTheme<ThemeTokens, ThemeProps>(() => ({
  backgroundColor: '#333',
  textColor: '#eee',
}));
export default ({ dashboardConfig, themeOverrides, muiOverrides = {} }: { themeOverrides: DeepPartial<ThemeType>; dashboardConfig: Dashboard.DashboardConfig; muiOverrides?: ThemeOptions }) => {
  const theme = applyThemeOverrides(themeOverrides, DefaultTheme);
  useEffect(() => {
    createTheme(() => ({}));
  }, [theme]);
  return (
    <ThemeProvider value={theme}>
      <Theme.Provider value={(t) => ({ ...t({}) })}>
        <DashboardProvider value={dashboardConfig}>
          <Router muiOverrides={muiOverrides} />
        </DashboardProvider>
      </Theme.Provider>
    </ThemeProvider>
  );
};
