import { Box, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Actions } from '../../../../../core/src';
import TwoFactorIcon from '../../../assets/universal/fingerprint.svg';
import { StandardPopup } from '../../../components/common';
import { LoadingPromiseButton } from '../../LoadingPromiseButton';

export function TwoFactorEnforcedPopup() {
  const { t } = useTranslation();
  const { twoFA } = useSelector((state: Core.StateType) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  async function onClose() {
    await dispatch(Actions().auth.logout());
    await dispatch(Actions().auth.close2FAcall());
    queryClient.clear();
    history.push('/login');
  }

  return (
    <StandardPopup visible={twoFA?.status?.enforce2FACreation} onBackdropClick={() => null} width={425} hideClose>
      <Card>
        <CardContent>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={TwoFactorIcon} style={{ width: 72, height: 72, alignSelf: 'center' }} />
          </Box>
          <Typography fontWeight="500" variant="h3" sx={{ marginTop: 3, marginBottom: 2, textAlign: 'center' }}>
            {t('two-factor-enforced')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ alignItems: 'center', justifyContent: 'center', marginBottom: 1 }}>
              <Typography textAlign="center" fontWeight="500">
                {t('please-login-again')}
              </Typography>
              <Typography textAlign="center" fontWeight="500" variant="subtitle2">
                {t('two-factor-enforced-description')}
              </Typography>
            </Box>
            <LoadingPromiseButton
              style={{ marginTop: 16 }}
              variant="contained"
              color="success"
              onClick={async () => {
                await onClose();
              }}
            >
              {t('got-it')}
            </LoadingPromiseButton>
          </Box>
        </CardContent>
      </Card>
    </StandardPopup>
  );
}
