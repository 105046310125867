import '../../css/components/common/inputs/TextInput.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';

type InputProps = {
  icon: any;
  placeholder: string;
  value: string;
  backgroundColor?: string;
  password?: boolean;
  autoFoucs?: boolean;
  onChange: (text: string) => void;
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange">;

export const TextInputIcon = ({ icon, placeholder, value, password = false, autoFoucs = true, onChange, ...rest }: InputProps) => {
  const [passwordVisible, setPasswordVisible] = useState(password);
  return (
    <div className="login-form-input">
      <FontAwesomeIcon className="icon" icon={icon} />
      <div className={password ? 'password-container reverse' : 'password-container'}>
        {password ? <FontAwesomeIcon color="#858585" onClick={() => setPasswordVisible(!passwordVisible)} icon={passwordVisible ? faEye : faEyeSlash} className="eye-icon" /> : null}
        <input
          {...rest}
          className="login-input"
          style={{ paddingRight: password ? 4 : 18 }}
          type={passwordVisible ? 'password' : password ? 'text' : 'email'}
          autoFocus={autoFoucs}
          maxLength={256}
          placeholder={placeholder}
          id={password ? 'password' : 'name'}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
