import * as React from 'react';

function SvgArrowToTopRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.786 20.75" ref={svgRef} {...props}>
      <path d="M1.644 11.482L8.5 4.609a.556.556 0 01.787 0l6.855 6.873a.556.556 0 010 .787l-.908.908a.55.55 0 01-.792-.009l-4.345-4.5v11.526a.557.557 0 01-.556.556h-1.3a.557.557 0 01-.556-.556V8.671l-4.34 4.5a.558.558 0 01-.792.009l-.908-.908a.552.552 0 010-.787zM.556 2.408H17.23a.557.557 0 00.556-.556v-1.3A.557.557 0 0017.23 0H.556A.557.557 0 000 .556v1.3a.557.557 0 00.556.552z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowToTopRegular);
export default ForwardRef;
