import { faChevronDown, faSignature } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Stack, Typography, useTheme } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationForm, FormFile, FormStatus, FormSubmission, FormWithReports, InfoPopup, StandardPopup } from '../../..';
import { SimpleFileProvider } from '../../../../../firebase/src';
import { FileUploadPopup } from '../../../components/agreements';
import { ApplicationDeadlineDateChip, ApplicationStatusChip, ApplicationSubmissionDateChip } from '../../../components/campaign';
import { sendAgreementNotification, useUpdateFormContentMutation } from '../../externalUsers/hooks/mutations';
import { AgreementsElement } from '../Agreements';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ESignAgreementsElement } from '../eSign';

function getApplicationOpenButtonString(status: FormStatus) {
  switch (status) {
    case 'not-started':
      return 'Start';
    case 'draft':
      return 'Edit';
    default:
      return 'View';
  }
}

function getReportAccess(allReports: ApplicationForm[], currentIndex: number) {
  for (let index = 0; index < currentIndex; index++) {
    const report = allReports[index];
    if (report.status === 'rejected') return 'rejected' as const;
    if (report.status !== 'success') return 'locked' as const;
  }
  return 'open' as const;
}

interface ApplicationCenterProps {
  open: boolean;
  onClose: () => void;
  onOpenApplicationForm: (formId: string, campaignId?: string) => void;
  application: FormWithReports;
}

export function ApplicationCenterPopup({ open, onClose, application, onOpenApplicationForm }: ApplicationCenterProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!application) return null;
  return (
    <StandardPopup visible={open} onBackdropClick={onClose} width={900}>
      <Card sx={{}}>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 3 }}>
            <Box
              sx={{
                borderRadius: '50%',
                width: 42,
                height: 42,
                backgroundColor: theme.palette.primary.main,
                marginRight: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon style={{ height: 22, width: 22, color: 'white' }} icon={faSignature} />
            </Box>
            <Typography variant="h2" fontWeight="500">
              Application Center
            </Typography>
          </Box>
          <Typography sx={{ fontWeight: 400 }}>
            <strong style={{ fontWeight: 500 }}>{t('application')}:</strong> {application.name}
          </Typography>
        </CardContent>
        <Stack spacing={1}>
          <SectionDevider text={t('application')} />
          <CardContent sx={{ py: 2 }}>
            <ApplicationRow
              application={application}
              formId={application.id}
              index={1}
              onOpenApplicationForm={(formId: string) => onOpenApplicationForm(formId, application?.submission?.campaignId)}
              defaultOpen={application.reports.length === 0}
              isMainApplication
            />
          </CardContent>
          {application.reports.length > 0 && (
            <>
              <SectionDevider text={t('reports')} />
              <CardContent sx={{ pt: 2 }}>
                <Stack spacing={1}>
                  {application.reports.map((report, index) => (
                    <ApplicationRow
                      key={report.id}
                      index={index + 2}
                      application={report}
                      formId={application.id}
                      onOpenApplicationForm={(formId: string) => onOpenApplicationForm(formId, application.submission.campaignId)}
                      accessibility={getReportAccess([application, ...application.reports], index + 1)}
                    />
                  ))}
                </Stack>
              </CardContent>
            </>
          )}
        </Stack>
      </Card>
    </StandardPopup>
  );
}

interface ApplicationReportRowProps {
  application: ApplicationForm;
  formId: string;
  index: number;
  onOpenApplicationForm: (formId: string) => void;
  accessibility?: 'open' | 'locked' | 'rejected';
  defaultOpen?: boolean;
  isMainApplication?: boolean;
}

function ApplicationRow({ index, application, formId, onOpenApplicationForm, accessibility = 'open', defaultOpen = false, isMainApplication = false }: ApplicationReportRowProps) {
  const [expanded, setExpanded] = useState(defaultOpen);
  const { t } = useTranslation();
  const theme = useTheme();
  const disabled = accessibility !== 'open';

  const showInlineDeadline = !isMainApplication && accessibility === 'open' && (application?.status === 'not-started' || application?.status === 'draft');
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((p) => !p)}
      TransitionProps={{ unmountOnExit: true }}
      disabled={disabled}
      sx={{
        backgroundColor: '#f5f5f7',
        border: `2px solid ${theme.palette.grey[400]}`,
      }}
    >
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown} style={{ width: 16, height: 16 }} />}
        aria-controls="application-center-application-content"
        id="application-center-row"
        title={t('clickToExpand')}
        sx={{ pr: 2 }}
      >
        <Stack spacing={1} direction={'row'} sx={{ alignItems: 'center', justifyContent: 'space-between', paddingRight: 2, width: '100%' }}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                minWidth: 30,
                maxWidth: 30,
                minHeight: 30,
                maxHeight: 30,
              }}
            >
              <Typography fontWeight={600} color={'white'}>
                {index}
              </Typography>
            </Box>
            <Typography sx={{ width: '60%', flexGrow: 1 }}>{application.name}</Typography>
          </Stack>
          {!disabled && (
            <Stack direction={'row'} spacing={1} alignItems="center">
              {showInlineDeadline && <ApplicationDeadlineDateChip deadline={application?.deadline} />}
              <ApplicationStatusChip status={application?.status} />
              <Button variant={'contained'} size={'small'} sx={{ flexShrink: 0, fontSize: '14px !important' }} onClick={() => onOpenApplicationForm(application.id)}>
                {getApplicationOpenButtonString(application?.status)}
                <FontAwesomeIcon icon={faEdit} style={{ width: 14, height: 14, marginLeft: 8 }} />
              </Button>
            </Stack>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <Stack spacing={1} direction={'column'}>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderRadius: 1,
              backgroundColor: theme.palette.background.paper,
              border: '2px solid',
              borderColor: theme.palette.grey[400],
              py: 1,
              px: 2,
            }}
            style={{ alignItems: 'center' }}
          >
            <InfoPopup description={application.description} renderAsButton />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                {t('status')}:
              </Typography>
              <ApplicationStatusChip status={application?.status} />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                {t('deadline')}:
              </Typography>
              <ApplicationDeadlineDateChip deadline={application?.deadline} />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                {t('submissionDate')}:
              </Typography>
              <ApplicationSubmissionDateChip submission={application?.submission} dateValue={application?.submission?.submissionDate} deadline={application?.deadline} />
            </Stack>
          </Stack>
          {/*<AgreementsCard submission={application?.submission} formId={formId} />*/}
          <ESignAgreementsElement formSubmission={application?.submission} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export type FileUploadPopupProps = {
  submission: FormSubmission;
  formId: string;
};

export function AgreementsCard({ submission, formId }: FileUploadPopupProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const nfFileProvider = useMemo(() => new SimpleFileProvider('/forms/agreements-nf'), []);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const updateSubmissionMutation = useUpdateFormContentMutation();

  const addAgreementFilesToSubmission = async (files: FormFile[]) => {
    const { formId, entityId, campaignId } = submission;
    try {
      await sendAgreementNotification(campaignId, formId, entityId);
    } catch (e) {
      console.error(e);
    }

    return updateSubmissionMutation.mutateAsync({
      campaignId: submission.campaignId,
      data: {
        'agreement.nfFiles': files,
      } as any,
      formId: formId,
      groupId: submission.entityId,
    });
  };

  return (
    <>
      <AgreementsElement content={submission} sentBackFromNFClick={() => setFileUploadOpen(true)} />
      <FileUploadPopup
        title={t('uploadAgreementFiles')}
        fileProvider={nfFileProvider}
        initialFiles={submission?.agreement?.nfFiles}
        onSave={addAgreementFilesToSubmission}
        onClose={() => setFileUploadOpen(false)}
        open={fileUploadOpen}
      />
    </>
  );
}

function SectionDevider({ text }: { text: string }) {
  const theme = useTheme();
  return (
    <div style={{ width: '100%', backgroundColor: theme.palette.grey[200], padding: 8, paddingLeft: 24 }}>
      <Typography variant={'overline'}>{text}</Typography>
    </div>
  );
}
