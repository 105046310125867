import { useQuery, UseQueryOptions } from 'react-query';
import { API } from '@base/core';
import { Form } from '@editors/form-editor';

export function useGetForm<T extends Form>(campaignId: string, formId: string, queryOptions?: UseQueryOptions<T, unknown, T>) {
  return useQuery(
    ['campaigns', campaignId, 'forms', formId],
    async () => {
      return (await API.campaigns.getForm(campaignId, formId)) as T;
    },
    queryOptions,
  );
}
