import { FormFile } from '@base/core';
import { SimpleFileProvider } from '@base/firebase';
import { FileProvider } from '@editors/form-editor';
import React from 'react';

export function addFileUploadTask(fileProvider: FileProvider, setFiles: React.Dispatch<React.SetStateAction<FormFile[]>>) {
  return async (file: File) => {
    try {
      const id = await fileProvider.createFileId();
      const task = fileProvider.uploadFile(id, file);
      setFiles((val) => [
        ...val,
        {
          id,
          name: file.name,
          mimeType: file.type,
          size: file.size,
          uploadedBytes: 0,
          finished: false,
          cancel: task.cancel,
          read: {},
        } as any,
      ]);

      task.on({
        change: (uploadedBytes) => {
          console.log('change', uploadedBytes);
          setFiles((val) => val.map((v) => (v.id == id ? { ...v, uploadedBytes } : v)));
        },
        success: () => {
          console.log('Success');
          setFiles((val) =>
            val.map((v) => {
              if (v.id == id) {
                const { cancel, ...nv } = v;
                return { ...(nv as any), finished: true };
              }
              return v;
            }),
          );
        },
        error: (error) => {
          console.log('Error', error);
          setFiles((val) => val.filter((v) => v.id != id));
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
}
