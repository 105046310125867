import '../../../css/components/common/dropdowns/DropDownMenu.scss';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { cx } from '../../HelperFunctions';

type dropdownElementProps = {
  selected: boolean;
  text: string;
  icon: IconDefinition;
  hoverBgColor?: string;
  onItemClick: () => void;
};

export const DropdownElement = ({ selected, text, icon, onItemClick }: dropdownElementProps) => (
  <div
    className={cx('content', selected && 'active')}
    style={{ fontSize: 14 }}
    onMouseDown={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onItemClick();
    }}
  >
    {text}
    {selected && <FontAwesomeIcon icon={icon} size="sm" color={'#ffffff'} />}
  </div>
);
