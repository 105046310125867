import { IAuthService, Observer } from '../entities';

export class AuthRepo {
  constructor(private authService: IAuthService) {}

  private currentUser: Core.User | undefined | null;

  onAuthStateChanged(callback: (loggedIn: null | Core.User) => void) {
    this.authService.onAuthStateChanged(callback);
  }
  getCurrentUser() {
    return this.currentUser;
  }

  updateCachedUser(update: Partial<Omit<Core.User, 'groups' | 'rights' | 'roles' | 'id'>>) {
    this.currentUser = { ...this.currentUser, ...update };
  }

  async tryAutoLogin(observer?: Observer<Core.User>): Promise<Core.User> {
    const user = await this.authService.tryAutoLogin(observer);
    this.currentUser = user;
    return user;
  }

  resetPassword(email: string): Promise<void> {
    return this.authService.resetPassword(email);
  }

  async clear2Factor(uid: string): Promise<void> {
    return this.authService.clear2Factor(uid);
  }

  async logout(): Promise<void> {
    await this.authService.logout();
    this.currentUser = null;
  }

  async login(email: string, password: string, reCaptchaTriggerElementId: string, observer?: Observer<Core.User>): Promise<Core.User> {
    const user = await this.authService.login(email, password, reCaptchaTriggerElementId, observer);
    this.currentUser = user;
    return user;
  }

  async provide2faFactor(phoneNumber: string, reCaptchaTriggerElementId?: string): Promise<string> {
    return await this.authService.provide2faFactor(phoneNumber, reCaptchaTriggerElementId);
  }

  async verify2faFactor(verificationId: string, verificationCode: string): Promise<void> {
    return await this.authService.verify2faFactor(verificationId, verificationCode);
  }

  async verify2FACode(resolver: any, verificationCode: string, observer?: Observer<Core.User>): Promise<Core.User> {
    return await this.authService.verify2FACode(resolver, verificationCode, observer);
  }
}
