import { faCheck, faExclamationTriangle, faInfoCircle, faTimes, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import '../../../css/components/common/popups/TimedPopup.scss';
import { ReactProps } from '../../../lib';
import { ThemeType } from '../../../theme/Theme';
import { useTheme } from '../../../theme/ThemeProvider';
import { ConditionalRender } from '../ConditionalRender';

function getPopupColor(theme: ThemeType, popupType: 'error' | 'warning' | 'success' | 'info') {
  return theme.colors[popupType];
}

const PopupIconMapping: { [key in PopupType]: any } = {
  error: faTimes,
  info: faInfoCircle,
  success: faCheck,
  warning: faExclamationTriangle,
};

export type Action = {
  text: string;
  type?: PopupType | 'primary';
  action: () => void;
};

type TimedPopup = {
  type: PopupType;
  title?: string;
  text?: string;
  actions?: Action[];
  time?: number;
  active: boolean;
  onTimerFinished?: () => void;
  icon?: IconDefinition;
  zIndex: number;
  centered?: boolean;
};

export const TimedPopup = ({ title, active, text, actions, type, zIndex, time = 3000, onTimerFinished, icon, centered }: TimedPopup) => {
  const [timerId, setTimerId] = useState(0);
  const theme = useTheme();

  const close = () => {
    onTimerFinished();
  };

  React.useEffect(() => {
    const listener = (e) => {
      if (e.key === 'Escape' || e.key === 'Esc') onTimerFinished();
    };
    if (active) {
      document.body.addEventListener('keydown', listener);
      transitionOutTimer();
    }
    return () => document.body.removeEventListener('keydown', listener);
  }, [active]);

  const transitionOutTimer = () => {
    time === 0 || actions?.length > 0
      ? null
      : setTimerId(
          //@ts-ignore
          setTimeout(() => {
            close?.();
          }, time),
        );
  };

  const Icon = PopupIconMapping[type];
  const color = getPopupColor(theme, type);

  if (actions?.length) {
    return (
      <Dialog open={open} onClose={() => close?.()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ alignItems: 'center' }}>
          <FontAwesomeIcon icon={icon || Icon} style={{ color: color, marginRight: 16 }} />
          {title}
        </DialogTitle>
        {text && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {actions.map((a) => (
            <Button
              key={a.text}
              onClick={() => {
                a.action();
                close?.();
              }}
              color={a.type}
              variant="contained"
            >
              {a.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div className="timed-popup" style={{ zIndex }} onMouseEnter={() => clearTimeout(timerId)} onMouseLeave={() => transitionOutTimer()}>
      <div className="timed-pop-title-container">
        <FontAwesomeIcon icon={icon || Icon} className="timed-pop-icon" style={{ color: color }} />
        <div className="timed-pop-title" style={{ color: color }}>
          {title}
        </div>
      </div>
      {/* <div className="close-icon-btn" onClick={() => close()}>
        <FontAwesomeIcon icon={faTimesCircle} className="timed-pop-icon" style={{ color: theme.colors['dark-gray'] }} />
      </div> */}
      <ConditionalRender render={text != null}>
        <div className="timed-pop-text">{text}</div>
      </ConditionalRender>
      {actions && (
        <Stack spacing={1} pt={2} width="100%">
          {actions.map((button, index) => (
            <Button
              key={index}
              variant="contained"
              onClick={() => {
                button.action();
                close();
              }}
              color={button.type as any}
            >
              {button.text}
            </Button>
          ))}
        </Stack>
      )}
    </div>
  );
};

export function usePopup() {
  const { pushPopup } = React.useContext(PopupContext);
  return pushPopup;
}

export function useDialog() {
  const { pushPopup } = React.useContext(PopupContext);
  const { t } = useTranslation();

  return ({ text, title, actionText, onActionClick }: { text: string; title: string; actionText: string; onActionClick: () => void }) =>
    pushPopup({
      type: 'info',
      text,
      blocking: true,
      title,
      time: 0,
      actions: [
        { action: () => {}, text: t('cancel'), type: 'error' },
        { action: onActionClick, text: actionText, type: 'primary' },
      ],
    });
}

export function useNotificationEffect(data: PopupDefinition, show: boolean, change: any[]) {
  const notify = usePopup();

  useLayoutEffect(() => {
    if (show) notify(data);
  }, [...change]);
}

export const PopupContext = React.createContext<{ pushPopup: (popupDefinition: PopupDefinition) => void }>({ pushPopup: () => {} });

type PopupType = 'error' | 'info' | 'success' | 'warning';

export type PopupDefinition = {
  title?: string;
  type: PopupType;
  text?: string;
  blocking?: boolean;
  actions?: Action[];
  id?: number;
  time?: number;
  icon?: IconDefinition;
};

let counter = 0;

export const TimedPopupProvider = ({ children }: ReactProps) => {
  const [popups, setPopups] = useState<PopupDefinition[]>([]);

  const pushPopup = (popupDefinition: Omit<PopupDefinition, 'id'>) => {
    setPopups((current) => [...current, { ...popupDefinition, id: counter++ }]);
  };

  return (
    <div style={{ position: 'relative', display: 'flex', flex: 1, flexDirection: 'column', maxWidth: '100%' }}>
      <PopupContext.Provider value={{ pushPopup }}>
        <div className="timed-popup-container">
          {/* <TransitionGroup> */}
          {popups.map((p, i, arr) => {
            return (
              <CSSTransition key={p.id} timeout={300} classNames="timed-popup">
                <TimedPopup
                  key={p.id}
                  active={i == 0}
                  type={p.type}
                  actions={p.actions}
                  icon={p.icon}
                  title={p.title}
                  text={p.text?.toString()}
                  time={p.time}
                  onTimerFinished={() => setPopups((pps) => [...pps.filter((_, idx) => i != idx)])}
                  zIndex={999 - i}
                />
              </CSSTransition>
            );
          })}
          {/* </TransitionGroup> */}
        </div>
        {children}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            background: '#0006',
            zIndex: 800,
            display: popups.some((p) => p.blocking) ? 'block' : 'none',
          }}
        ></div>
      </PopupContext.Provider>
    </div>
  );
};
