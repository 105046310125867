import { SimpleFileProvider } from '@base/firebase';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationForm } from '../../..';
import { useGetGroups } from '../../users/hooks/useGetGroups';
import { useGetForm } from '../hooks';
import { useGetFormSubmissions } from '../hooks/useGetFormSubmissions';
import SubmissionCompareView from '../SubmissionCompareView';

export default function SubmissionCompareViewWrapper() {
  const { parentId, formId } = useParams<{ parentId: string; formId: string }>();
  const writeFileProvider = useMemo(() => new SimpleFileProvider('/campaigns/forms/response_files'), []);

  const { data: form, isLoading } = useGetForm<ApplicationForm>(parentId, formId);
  const { data: formsubmissions = [], isLoading: isFormsubmissionsLoading } = useGetFormSubmissions(parentId);
  const { data: groups = [] } = useGetGroups();
  const submissions = formsubmissions.filter((s) => s.formId == form?.id && s.state != 'draft').map((s) => ({ submission: s, group: groups.find((g) => g.id == s.entityId) }));
  console.log('🚀 ~ file: SubmissionCompareViewWrapper.tsx ~ line 17 ~ SubmissionCompareViewWrapper ~ submissions', submissions);
  return <SubmissionCompareView document={form} submissions={submissions} uploadFileProvider={writeFileProvider} />;
}
