import { Avatar, ListItemIcon, ListItemText, MenuItem, Select, Stack, Typography } from '@material-ui/core';
import { LANGUAGES } from '../../../lib';
import React from 'react';

export function LanguageSelect(props) {
  return (
    <Select
      {...props}
      fullWidth
      variant="outlined"
      renderValue={(languageShort) => (
        <Stack display="flex" alignItems="center" direction="row" spacing={2}>
          <Avatar src={LANGUAGES.find((l) => l.short == languageShort).image} sx={{ width: 24, height: 24 }} />
          <Typography>{LANGUAGES.find((l) => l.short == languageShort).language}</Typography>
        </Stack>
      )}
    >
      {LANGUAGES.map((language, i) => {
        return (
          <MenuItem key={language.short} value={language.short}>
            <ListItemIcon>
              <Avatar src={language.image} sx={{ width: 24, height: 24 }} />
            </ListItemIcon>
            <ListItemText>{language.language}</ListItemText>
          </MenuItem>
        );
      })}
    </Select>
  );
}
