import '../../css/Views/auth/loading.scss';
import React from 'react';
import { AuthContainer } from '../../components/auth';
import { useTheme } from '../../theme/ThemeProvider';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@material-ui/core';

export const Loading = ({ small }: { small?: boolean }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  if (small)
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={60} />
      </Box>
    );
  return (
    <div className="login">
      <AuthContainer loading>
        <div className="loading-container">
          <theme.images.logo className="loading-customer-logo" style={{ color: theme.colors.logoLight }} />
          <div className="loading-text-con">
            <div className="spinner">
              <div className="bounce1" style={{ marginRight: 6 }}></div>
              <div className="bounce2" style={{ marginRight: 6 }}></div>
              <div className="bounce3"></div>
            </div>
            <p className="loading-text">{t('gleich fertig')}</p>
          </div>
        </div>
      </AuthContainer>
    </div>
  );
};
