import * as React from 'react';

function SvgInfoCircleLight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={svgRef} {...props}>
      <path d="M10 1.29A8.71 8.71 0 111.29 10 8.711 8.711 0 0110 1.29M10 0a10 10 0 1010 10A10 10 0 0010 0zM8.548 13.871h.484V9.032h-.484a.484.484 0 01-.484-.484v-.323a.484.484 0 01.484-.484h1.935a.484.484 0 01.484.484v5.645h.484a.484.484 0 01.484.484v.323a.484.484 0 01-.484.484h-2.9a.484.484 0 01-.484-.484v-.323a.484.484 0 01.481-.483zM10 4.194a1.29 1.29 0 101.29 1.29A1.29 1.29 0 0010 4.194z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgInfoCircleLight);
export default ForwardRef;
