import { Group, GroupTag } from '@base/core';
import { faPlusCircle, faSave } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { StandardPopup, usePopup } from '../../../components/common';
import { diffArrays } from 'diff';

interface GroupPopupPropsBase {
  open?: boolean;
  onClose(): void;
  groupList: Group[];
}
interface GroupPopupCreateProps {
  onCreate(name: string, groups: string[]): Promise<void> | void;
}

export interface GroupPopupSaveProps {
  onSave(name: string, addedGroups: string[], removedGroups: string[]): Promise<void> | void;
  group?: GroupTag & { groups: Group[] };
}

type GroupPopupProps = Partial<GroupPopupCreateProps & GroupPopupSaveProps> & GroupPopupPropsBase;
export function GroupTagPopup(props: GroupPopupSaveProps & GroupPopupPropsBase);
export function GroupTagPopup(props: GroupPopupCreateProps & GroupPopupPropsBase);
export function GroupTagPopup({ onClose, open = false, onCreate, onSave, groupList, group }: GroupPopupProps) {
  const theme = useTheme();
  const notify = usePopup();
  const { t, i18n } = useTranslation();

  const schema = yup.object({
    name: yup.string().required(t('something-is-required', { something: t('name') })),
  });

  return (
    <StandardPopup visible={open} onBackdropClick={onClose} width={750}>
      <Formik
        initialValues={group ?? { name: '', groups: [] }}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (group) {
              const diff = diffArrays(
                group.groups.map((g) => g.id),
                values.groups.map((g) => g.id),
              );
              const added = diff.filter((d) => d.added).flatMap((d) => d.value);
              const removed = diff.filter((d) => d.removed).flatMap((d) => d.value);
              await onSave(values.name, added, removed);
            } else {
              await onCreate(
                values.name,
                values.groups.map((g) => g.id),
              );
            }
            onClose();
          } catch (error) {
            notify({
              title: t('error'),
              type: 'error',
              text: error.message,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, errors, touched, isSubmitting, setFieldValue, values }) => (
          <Card>
            <CardHeader
              title={group ? t('update-group') : t('create-new-group')}
              titleTypographyProps={{ variant: 'h2' }}
              style={{ background: theme.palette.background.gray, paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Field as={TextField} name="name" label={t('name')} fullWidth variant="outlined" required error={touched.name && Boolean(errors.name)} helperText={touched.name && errors.name} />
                </Grid>
                <Grid xs={12} item>
                  <Autocomplete
                    options={groupList}
                    value={values.groups}
                    onChange={(_, v) => setFieldValue('groups', v)}
                    multiple
                    autoSelect
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Select groups" placeholder="add group" />}
                    getOptionLabel={(option) => option.name}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ paddingX: 3, paddingBottom: 3, justifyContent: 'flex-end' }}>
              <Button variant="outlined" color="error" onClick={onClose}>
                {t('cancel')}
              </Button>
              {!group && (
                <LoadingButton startIcon={<FontAwesomeIcon icon={faPlusCircle} />} variant="contained" onClick={handleSubmit as any} loading={isSubmitting}>
                  {t('create')}
                </LoadingButton>
              )}
              {group && (
                <LoadingButton startIcon={<FontAwesomeIcon icon={faSave} />} variant="contained" onClick={handleSubmit as any} loading={isSubmitting}>
                  {t('save')}
                </LoadingButton>
              )}
            </CardActions>
          </Card>
        )}
      </Formik>
    </StandardPopup>
  );
}
