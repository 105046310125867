import { Observer } from './Observer';

export interface IAuthService {
  onAuthStateChanged(callback: (loggedIn: null | Core.User) => void);
  tryAutoLogin(observer?: Observer<Core.User>): Promise<Core.User>;
  resetPassword(email: string): Promise<void>;
  logout(): Promise<void>;
  login(email: string, password: string, reCaptchaTriggerElementId: string, observer?: Observer<Core.User>): Promise<Core.User>;
  provide2faFactor(phoneNumber: string, reCaptchaTriggerElementId?: string): Promise<string>;
  verify2faFactor(verificationId: string, verificationCode: string): Promise<void>;
  verify2FACode(resolver: any, verificationCode: string, observer?: Observer<Core.User>): Promise<Core.User>;
  clear2Factor(uid: string): Promise<void>;
}

export class TwoFALoginError extends Error {
  constructor(public message: string, public phoneNumber: string, public resolver: any) {
    super(message);
  }
  type: 'TwoFALoginError';
}

export class TwoFARequireEnrollmentError extends Error {
  constructor(public message: string) {
    super(message);
  }
  type: 'TwoFARequireEnrollmentError';
}
