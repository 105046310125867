import { QueryObserverResult, useQueries, useQuery, UseQueryOptions } from 'react-query';
import { API } from '@base/core';
import { useGetUsers } from './useGetUsers';
import { useMemo } from 'react';
import { useGetGroups } from './useGetGroups';

export function useGetGroupTags() {
  const { data: groups = [] } = useGetGroups();

  const res = useQuery(['grouptags'], async () => {
    const groupTags = await API.groups.getGroupTags();

    return groupTags;
  });

  const finalGroupTags = useMemo(
    () =>
      (res.data ?? []).map((g) => {
        return { ...g, groups: groups.filter((group) => group.groupTag == g.id) };
      }),
    [res, groups],
  );

  return { ...res, data: finalGroupTags }
}
