// import './atlaskit-overrides';
import './fonts/fonts.scss';
import i18n from './lib/i18n';
import './lib/IconLibrary';

import { LicenseInfo } from '@material-ui/x-grid';

LicenseInfo.setLicenseKey('f7a78f07b0035b1c1aefb9be54321186T1JERVI6MjQ1MzMsRVhQSVJZPTE2NTE4NDgyODEwMDAsS0VZVkVSU0lPTj0x');

export * from '@base/core';
export * from './components';
export * from './components/common';
export * from './components/content/Main';
export * from './components/Dashboard';
export * from './components/DashboardHelpers';
export * from './views/campaign';
export * from './theme';
export * from '@modules/country-icons';
export { default as Router } from './router';
export * from './router';
export { i18n };
