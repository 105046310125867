import * as React from 'react';

function SvgText(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.029 20" ref={svgRef} {...props}>
      <path d="M6.829 20a.639.639 0 01-.471-.186.639.639 0 01-.186-.471V2.457H.657a.639.639 0 01-.471-.186A.639.639 0 010 1.8V.686a.676.676 0 01.186-.5A.639.639 0 01.657 0h13.714a.628.628 0 01.486.186.708.708 0 01.171.5V1.8a.639.639 0 01-.186.471.639.639 0 01-.471.186H8.886v16.886a.615.615 0 01-.2.471A.688.688 0 018.2 20z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgText);
export default ForwardRef;
