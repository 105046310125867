import * as ACTION_TYPES from './types';
import type * as ACTIONS from './actions';
import { FetchingType, createDefault, createFetching, createSuccess, createError } from '../common';

type ValueOf<T> = T[keyof T];
export type ActionsType = ValueOf<{ [k in keyof typeof ACTIONS]: ReturnType<typeof ACTIONS[k]> }>;

export type initialStateType = {
  fetching: {
    login: FetchingType;
    logout: FetchingType;
    resetPassword: FetchingType;
    tryAutoLogin: FetchingType;
    updateData: FetchingType;
    verify2FALoginCode: FetchingType;
    provide2FAPhoneNumber: FetchingType;
    verify2FAFactorEnrollment: FetchingType;
  };
  twoFA: {
    multiFactorVerificationId?: string;
    resolver?: any;
    phoneNumber?: string;
    enrollmentVerificationId?: string;
    status?: {
      getLoginCode?: boolean;
      getPhoneNumber?: boolean;
      getVerificationCode?: boolean;
      enforce2FACreation?: boolean;
      start2FACreation?: boolean;
      finished2FACreation?: boolean;
    };
  };
  user: Core.User | null;
};

const initialState: initialStateType = {
  fetching: {
    login: createDefault(),
    logout: createDefault(),
    resetPassword: createDefault(),
    tryAutoLogin: createDefault(),
    updateData: createDefault(),
    verify2FALoginCode: createDefault(),
    provide2FAPhoneNumber: createDefault(),
    verify2FAFactorEnrollment: createDefault(),
  },
  user: null,
  twoFA: {
    status: {
      getLoginCode: false,
      getPhoneNumber: false,
      getVerificationCode: false,
      enforce2FACreation: false,
      start2FACreation: false,
      finished2FACreation: false,
    },
  },
};

export const authReducer = (state: initialStateType = initialState, action: ActionsType): initialStateType => {
  switch (action.type) {
    case ACTION_TYPES.CLOSE2FACREATION_NOTIFICATION:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: false,
            enforce2FACreation: false,
            finished2FACreation: false,
          },
        },
      };
    case ACTION_TYPES.NOTIFY2FACREATIONFINISHED:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          login: createDefault(),
          tryAutoLogin: createDefault(),
        },
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: false,
            enforce2FACreation: false,
            finished2FACreation: true,
          },
        },
      };
    case ACTION_TYPES.CLOSE2FACALL:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: false,
            enforce2FACreation: false,
          },
        },
      };
    case ACTION_TYPES.ENFORCE2FACREATION:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: false,
            enforce2FACreation: true,
          },
        },
      };
    case ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_CALL:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: true,
            enforce2FACreation: false,
          },
        },
      };
    case ACTION_TYPES.PROVIDE2FAFACTOR_CALL:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: true,
            getVerificationCode: false,
            enforce2FACreation: false,
          },
        },
      };

    case ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          verify2FAFactorEnrollment: createFetching(),
        },
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: true,
          },
        },
      };

    case ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          verify2FAFactorEnrollment: createSuccess(),
        },
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: false,
          },
        },
      };

    case ACTION_TYPES.VERIFY2FAFACTOR_ENROLLMENT_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          verify2FAFactorEnrollment: createError(action.error),
        },
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: true,
          },
        },
      };

    case ACTION_TYPES.PROVIDE2FAPHONE_NUMBER_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          provide2FAPhoneNumber: createFetching(),
        },
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: true,
            getVerificationCode: false,
          },
        },
      };

    case ACTION_TYPES.PROVIDE2FAPHONE_NUMBER_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          provide2FAPhoneNumber: createSuccess(),
        },
        twoFA: {
          enrollmentVerificationId: action.verificationId,
          phoneNumber: action.phoneNumber,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: true,
          },
        },
      };

    case ACTION_TYPES.PROVIDE2FAPHONE_NUMBER_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          provide2FAPhoneNumber: createError(action.error),
        },
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: true,
            getVerificationCode: false,
          },
        },
      };

    case ACTION_TYPES.SET2FARESOLVER:
      return { ...state, twoFA: { resolver: action.resolver, phoneNumber: action.phoneNumber, status: { getLoginCode: true, getPhoneNumber: false, getVerificationCode: false } } };

    case ACTION_TYPES.PROVIDE2FAVERIFICATION_CODE_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          verify2FALoginCode: createFetching(),
        },
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: true,
            getPhoneNumber: false,
            getVerificationCode: false,
          },
        },
      };
    case ACTION_TYPES.PROVIDE2FAVERIFICATION_CODE_SUCCESS: {
      const { twoFA, ...rest } = state;
      return {
        ...rest,
        fetching: {
          ...state.fetching,
          verify2FALoginCode: createSuccess(),
        },
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: false,
            getPhoneNumber: false,
            getVerificationCode: false,
          },
        },
      };
    }
    case ACTION_TYPES.PROVIDE2FAVERIFICATION_CODE_FAILURE: {
      const { twoFA, ...rest } = state;
      return {
        ...rest,
        fetching: {
          ...state.fetching,
          verify2FALoginCode: createError(action.error),
        },
        twoFA: {
          ...state.twoFA,
          status: {
            getLoginCode: true,
            getPhoneNumber: false,
            getVerificationCode: false,
          },
        },
      };
    }
    case ACTION_TYPES.TRY_AUTO_LOGIN_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          tryAutoLogin: createFetching(),
        },
      };
    case ACTION_TYPES.TRY_AUTO_LOGIN_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          tryAutoLogin: createSuccess(),
        },
        user: action.user,
      };
    case ACTION_TYPES.TRY_AUTO_LOGIN_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          tryAutoLogin: createError(action.error),
        },
      };
    case ACTION_TYPES.UPDATE_DATA_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateData: createFetching(),
        },
      };
    case ACTION_TYPES.UPDATE_DATA_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateData: createSuccess(),
        },
        user: state.user ? { ...state.user, ...action.update } : null,
      };
    case ACTION_TYPES.UPDATE_DATA_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updateData: createError(action.error),
        },
      };
    case ACTION_TYPES.LOGIN_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          login: createFetching(),
        },
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          login: createSuccess(),
        },
        user: action.user,
      };
    case ACTION_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          login: createError(action.error),
        },
        user: null,
      };
    case ACTION_TYPES.LOGOUT_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          logout: createFetching(),
        },
      };
    case ACTION_TYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          logout: createSuccess(),
        },
      };
    case ACTION_TYPES.LOGOUT_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          logout: createError(action.error),
        },
      };
    case ACTION_TYPES.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          resetPassword: createFetching(),
        },
      };
    case ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          resetPassword: createSuccess(),
        },
      };
    case ACTION_TYPES.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          resetPassword: createError(action.error),
        },
      };
    case ACTION_TYPES.RESET_PASSWORD_RESET:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          resetPassword: createDefault(),
        },
      };
    default:
      return state;
  }
};
