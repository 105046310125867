import { faFolderOpen, faSave } from '@fortawesome/pro-regular-svg-icons';
import { Slider } from '@material-ui/core';
import React, { MutableRefObject, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import '../../../css/components/common/popups/ChangeProfilePicturePopup.scss';
import '../../../css/Views/admin/users/UsersOverride.scss';
import { useTheme } from '../../../theme/ThemeProvider';
import { useAutoUpdateState } from '../../../views/fileManager/useAutoUpdateState';
import { usePromiseResolve } from '../../../views/fileManager/usePromiseResolve';
import { StandardButton } from '../buttons/StandardButton';
import { StandardPopup } from './StandardPopup';

export const ChangeProfilePicturePopup = ({ active, setActive, currentUser, setImage }) => {
  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const editorRef = useRef<AvatarEditor>();
  const { t } = useTranslation();

  const theme = useTheme();

  const [avatarZoom, setAvatarZoom] = useState(1);
  const [userImage, setUserImage] = useAutoUpdateState(usePromiseResolve(currentUser?.photoURL ?? ''));

  const saveImage = () => {
    editorRef.current.getImageScaledToCanvas().toBlob(async (pic) => {
      setImage(pic);
    });
    setActive(false);
  };

  return (
    <StandardPopup visible={active} onBackdropClick={() => setActive(false)}>
      <div className="change-profile-picture-container">
        <div className="user-image-con">
          <AvatarEditor
            className="user-image-flex"
            ref={(editor) => (editorRef.current = editor)}
            style={{ boxShadow: theme.styles.shadow.shadowS, borderWidth: 3, borderColor: 'white', borderStyle: 'double', width: 'unset !important' }}
            image={userImage ? userImage : 'https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg'}
            width={150}
            crossOrigin="anonymous"
            height={150}
            border={0}
            borderRadius={50}
            scale={avatarZoom}
          />
          <input
            style={{ display: 'none' }}
            ref={fileInputRef}
            type="file"
            accept="image/*"
            name="user-avatar"
            id="avatar"
            onChange={(e) => {
              const currentFile = e.target.files?.[0];
              if (!currentFile) return;
              const reader = new FileReader();
              reader.onload = (e) => {
                setUserImage(e.target!.result as string);
              };
              reader.readAsDataURL(currentFile); // convert to base64 string
            }}
          />
        </div>
        <div className="property-label">{t('zoom')}</div>
        <Slider
          defaultValue={1}
          aria-labelledby="discrete-slider"
          aria-label="zoom"
          valueLabelDisplay="off"
          step={0.1}
          min={0.5}
          max={5.5}
          onChange={(e, val) => setAvatarZoom(val as number)}
          style={{ color: theme.colors['main-color'], marginBottom: 15 }}
        />
        <StandardButton
          onClick={() => fileInputRef.current.click()}
          faIcon={faFolderOpen}
          bgColor={theme.colors['main-color']}
          contentColor={'#fff'}
          text={t('choose-photo')}
          style={{ margin: '0px 0px 15px 0px' }}
        />
        <StandardButton onClick={() => saveImage()} faIcon={faSave} bgColor={theme.colors['main-color']} contentColor={'#fff'} text={t('save')} style={{ marginLeft: 0 }} />
      </div>
    </StandardPopup>
  );
};
