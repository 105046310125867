import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, AvatarProps, Tooltip, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../css/components/common/UserImageHandler.scss';
import { usePromiseResolve } from '../../views/fileManager/usePromiseResolve';
import { UserEdit } from '../../views/users';
import AsyncImage from './AsyncImage';
//TODO: Add CSS or webflow will intruduce a bug (perhaps)

interface UserImageHandlerProps {
  userName?: string;
  userPhotoUrl?: Promise<string> | string;
  id?: string;
  fontSize?: number;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const UserImageHandler = ({ userName, userPhotoUrl, id, fontSize, style, onClick }: UserImageHandlerProps) => {
  const theme = useTheme();
  const regex = /^([A-Z]).*?(\s([A-Z])[\w\-]*)?$/;
  const [_, firstChar, __, secondChar] = regex.exec(userName ? userName : '') ?? [];
  const history = useHistory();

  return (
    <div onClick={onClick} className="user-image-handler-container" style={{ ...style }}>
      {userPhotoUrl && <AsyncImage src={userPhotoUrl} style={{ height: '100%' }} onDragStart={(e) => e.preventDefault()} />}
      {!userPhotoUrl &&
        (userName ? (
          <div className="user-initials-container" style={{ backgroundColor: theme.palette.grey[200] }}>
            <p className="user-initials" style={{ color: theme.palette.primary.main, fontSize: fontSize }}>
              {firstChar}
              {secondChar}
            </p>
          </div>
        ) : (
          <div className="user-initials-container" style={{ backgroundColor: theme.palette.grey[200] }}>
            <FontAwesomeIcon icon={faImage} color={theme.palette.grey[700]} fixedWidth style={{ width: 26, height: 26 }} />
          </div>
        ))}
    </div>
  );
};

export function CurrentUserAvatar(props: { fontSize?: number; style?: React.CSSProperties }) {
  const [userEditPopup, setUserEditPopup] = useState(false);
  const { user } = useSelector((state: Core.StateType) => state.auth);

  return (
    <>
      <UserImageHandler {...props} id={user?.id} userName={user?.displayName} userPhotoUrl={user?.photoURL} onClick={() => setUserEditPopup(true)} />
      <UserEdit open={userEditPopup} onClose={() => setUserEditPopup(false)} currentUser={user} />
    </>
  );
}

export interface UserAvatarHandlerProps {
  userName?: string;
  userPhotoUrl?: Promise<string> | string;
  disableTooltip?: boolean;
}

export const UserAvatarHandler = ({ userName, userPhotoUrl, disableTooltip, ...props }: UserAvatarHandlerProps & AvatarProps) => {
  const regex = /^([A-Z]).*?(\s([A-Z])[\w\-]*)?$/;
  const [_, firstChar, __, secondChar] = regex.exec(userName ? userName : '') ?? [];
  const url = usePromiseResolve(userPhotoUrl);
  return (
    <Tooltip title={disableTooltip ? '' : userName}>
      <Avatar sx={{ width: 34, height: 34 }} alt={userName} src={url} imgProps={{ onDragStart: (e) => e.preventDefault() }} {...props}>
        {firstChar}
        {secondChar}
      </Avatar>
    </Tooltip>
  );
};
