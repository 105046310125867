import { Button, Card, CardActions, CardContent, CardHeader, Grid, Paper, Stack, Switch, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../../../core/src';
import { LoadingPromiseButton } from '../../../components';
import { StandardPopup, usePopup } from '../../../components/common';
import { usePasswordResetMutation, useUpdateUserMutation } from '../hooks/mutations';
import { LabeledSwitchBoxRounded } from './LabeledSwitchBoxRounded';

interface ResetPopupBaseProps {
  open?: boolean;
  onClose(): void;
  externalUser?: boolean;
  user: Core.User;
}

export function ResetPasswordPopup({ onClose, open = false, user, externalUser }: ResetPopupBaseProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const resetPasswordMutation = usePasswordResetMutation();
  const notify = usePopup();
  const updateUserMutation = useUpdateUserMutation();

  async function toggle2FA(activated: boolean) {
    try {
      await updateUserMutation.mutateAsync({ id: user?.id, isTwoFAEnforced: activated });
      if (activated) {
        await API.email.sendNotificationEmailToUsers([user?.id], 'Two Factor Authentication required', {
          autosend: true,
          emailTitle: 'Two Factor Authentication is now required',
          emailText: `
          Dear ${user?.displayName ?? 'IBU Scope User'}</br>
          Two factor authentication is now required for increased security. The next time you login, you can register a new second factor.</br></br>
          `,
          buttonLink: 'https://ibu-scope.com/',
          buttonText: 'Login to IBU Scope',
          belowButtonText: `
          <p>Best regards,</p>
          </br>
          <p>your IBU Scope Team</p>
          `,
        });
      } else {
        await API.auth.clear2Factor(user?.id);
      }
      notify({ type: 'success', title: t('saved') });
    } catch (error) {
      notify({ type: 'error', title: t('two-factor-auth-toggle-failed'), text: typeof error === 'string' ? error : JSON.stringify(error) });
    }
  }

  return (
    <StandardPopup visible={open} onBackdropClick={onClose} width={550}>
      <Card>
        <CardHeader
          title={t('change-login-data') + ' of ' + user?.displayName}
          titleTypographyProps={{ variant: 'h3' }}
          style={{ background: theme.palette.background.gray, paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <LabeledSwitchBoxRounded
            label={'Force' + ' ' + t('password-reset')}
            onChange={async (checked) => {
              try {
                await updateUserMutation.mutateAsync({ id: user.id, passwordChangeRequired: checked });
                notify({ type: 'success', title: t('saved') });
              } catch (error) {
                notify({ type: 'error', title: t('something-failed') });
              }
            }}
            checked={user?.passwordChangeRequired}
          />

          <Typography variant="subtitle2">{t('force-user-reset-password-description')}</Typography>
          {!externalUser && (
            <>
              <LabeledSwitchBoxRounded
                sx={{ mt: 1 }}
                label={t('2fa-reset') + ' (2FA)'}
                onChange={(checked) => {
                  void toggle2FA(checked);
                }}
                checked={user?.isTwoFAEnforced}
              />
              <Typography variant="subtitle2">{t('reset-2fa-description')}</Typography>
            </>
          )}
        </CardContent>
        <CardActions sx={{ paddingX: 3, paddingBottom: 3, justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="error" onClick={onClose}>
            {t('cancel')}
          </Button>
          {user?.isTwoFAEnforced && (
            <LoadingPromiseButton
              variant="outlined"
              color="primary"
              loading={updateUserMutation.isLoading}
              onClick={async () => {
                try {
                  await API.auth.clear2Factor(user?.id);
                  notify({ type: 'success', title: t('two-factor-auth-reset-successfull') });
                  onClose();
                } catch (error) {
                  notify({ type: 'error', title: t('two-factor-auth-reset-failed'), text: typeof error === 'string' ? error : JSON.stringify(error) });
                }
              }}
            >
              {t('reset-2fa')}
            </LoadingPromiseButton>
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            loading={resetPasswordMutation.isLoading || updateUserMutation.isLoading}
            onClick={async () => {
              await resetPasswordMutation.mutateAsync(user?.email);
              onClose();
            }}
          >
            {t('reset-password')}
          </LoadingButton>
        </CardActions>
      </Card>
    </StandardPopup>
  );
}
