import {Editor as CFEditor} from "@atlaskit/editor-core";
import React from "react";
import {FormContent} from "@editors/form-editor";

interface ConfluenceEditorProps {
    onChange: (value: FormContent<any>) => void;
    value:  FormContent<any>;
    focused: boolean;
}
export function ConfluenceEditor({value, focused, onChange}:ConfluenceEditorProps) {
  return (
    <CFEditor
      defaultValue={value.content}
      key={'EDITORKEY'}
      appearance={focused ? 'comment' : 'chromeless'}
      allowFindReplace
      allowHelpDialog
      onChange={(editorView) => {
        onChange({ ...value, content: editorView.state.toJSON().doc });
      }}
      allowDate
      disabled={!focused}
      allowTextAlignment
      codeBlock={{ allowCopyToClipboard: true, useLongPressSelection: true }}
      allowExpand={{ allowInsertion: true, allowInteractiveExpand: true }}
      allowLayouts={{ UNSAFE_addSidebarLayouts: true, allowBreakout: true, useLongPressSelection: true }}
      allowPanel
      allowNewInsertionBehaviour
      waitForMediaUpload
      allowNestedTasks
      allowTables={{ advanced: true, allowColumnSorting: true, stickyHeaders: true }}
      allowStatus
      placeholderHints={focused ? ['Type / to open the insert menu...', 'Type /table to get a table...', 'Type // to insert a Data...', 'Press Ctrl+/ to get a Help...'] : []}
      allowTasksAndDecisions
      quickInsert
      allowTextColor={{ EXPERIMENTAL_allowMoreTextColors: true }}
      allowIndentation
      allowDynamicTextSizing
      allowKeyboardAccessibleDatepicker
      allowRule
      mentionInsertDisplayName
      placeholder={focused ? 'Type something...' : ''}
      shouldFocus
      // media={{
      //   provider: mediaProvider,
      //   allowMediaGroup: true,
      //   allowMediaSingle: true,
      //   allowResizing: true,
      //   allowAnnotation: true,
      //   allowResizingInTables: true,
      //   allowAltTextOnImages: true,
      //   altTextValidator: (value: string) => {
      //     const errors = [];
      //     if (!/^[A-Z]/g.test(value)) {
      //       errors.push('Please start with capital letter.');
      //     }
      //     if (!/^[^"<>&\\]*$/g.test(value)) {
      //       errors.push('Please remove special characters.');
      //     }
      //     if (!/(\w.+\s).+/g.test(value)) {
      //       errors.push('Please use at least two words.');
      //     }
      //     return errors;
      //   },
      //   useMediaPickerPopup: false,
      //   allowDropzoneDropLine: true,
      //   allowLazyLoading: true,
      //   allowAdvancedToolBarOptions: true,
      //   allowBreakoutSnapPoints: false,
      //   isCopyPasteEnabled: true,
      //   waitForMediaUpload: false,
      //   fullWidthEnabled: false,
      //   allowMediaSingleEditable: true,
      //   customDropzoneContainer: containerRef.current,
      //   useForgePlugins: true,
      //   featureFlags: {
      //     // captions: true,
      //     newCardExperience: true,
      //     // folderUploads: true,
      //     // zipPreviews: true,
      //     codeViewer: true,
      //   },
      // }}
    />
  )
}
