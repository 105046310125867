import * as React from 'react';

function SvgUnderline(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.711 20.546" ref={svgRef} {...props}>
      <path d="M9.064 16.332a6.905 6.905 0 01-4.854-1.587 6.22 6.22 0 01-1.725-4.808V.575a.584.584 0 01.15-.414.523.523 0 01.4-.161h2.122a.56.56 0 01.414.161.56.56 0 01.161.414v9.362a3.869 3.869 0 00.863 2.737 3.194 3.194 0 002.45.92 3.194 3.194 0 002.45-.92 3.869 3.869 0 00.862-2.737V.575a.56.56 0 01.161-.414.56.56 0 01.414-.161h2.116a.523.523 0 01.4.161.584.584 0 01.15.414v9.362a6.244 6.244 0 01-1.714 4.807 6.827 6.827 0 01-4.82 1.588zM17.078 18.6H.633a.641.641 0 00-.633.649v.651a.641.641 0 00.633.648h16.445a.641.641 0 00.633-.648v-.648a.641.641 0 00-.633-.652z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUnderline);
export default ForwardRef;
