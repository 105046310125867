import * as React from 'react';

function SvgPeople(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 227.289 292.79" ref={svgRef} {...props}>
      <g data-name="Gruppe 11349">
        <path
          data-name="Pfad 1324"
          d="M56.474 291c-1.46-1.51-6.48-7.94-6.57-11.17-.13-4.57 0-10.85.17-17.69h-7.19c.29 7.46.31 16.76.21 19.57-.16.48-.68 3.6-.94 4.06-.39.68-.06 5.87-.06 5.87l14.55-.58-.13-.05z"
          fill="#ef6e4b"
        />
        <path
          data-name="Pfad 1325"
          d="M49.884 270c.31-19.31 4.16-48.53 4.7-53.38.34-3.12 3-11.29 6.62-23.23 8.34-27.7 8.89-47.69 6-65.73l-18.63.43c-3.23 14.33-21.51 29.79-9.07 46.35.5 6.19 4 27.17 2.53 36.41-1.77 10.91-2.82 16.24-2.16 28.91.28 5.27 3.14 21.79 3.12 30.24z"
          fill="#ef6e4b"
        />
        <path data-name="Pfad 1326" d="M38.054 263.54h-8.51c1 8.79 1.67 16.25 1.67 16.25l-.93 13h9.26c-.61-3.88-2.35-15.51-2-20.42.21-2.92.37-5.86.51-8.83z" fill="#ff8b66" />
        <path
          data-name="Pfad 1327"
          d="M24.114 191.18c.74 6.17 2.13 10.3 2.68 15.49.81 7.55-3.81 18.5-.82 30.67 1.42 5.76 3.48 24.77 4.55 35.29h7v-.3c1.84-25.6 1.43-55.12 2.79-66.72.57-4.89 4-12.83 5.3-19.15 0 0 4-18.36 6.8-19.89 11.31-6.3 14.8-38.9 14.8-38.9L39.434 129a43.69 43.69 0 00-14.59 37.41 3.65 3.65 0 01-.09.67c-1.48 11.24-1.34 18.51-.64 24.1z"
          fill="#ff8b66"
        />
        <path
          data-name="Pfad 1328"
          d="M29.674 282.73s.35-3.19 4.74-2.82 3.84 3.58 3.84 3.58a1 1 0 011.24 0c.55.52-.26 4.95.79 5.88s.52 3.38.52 3.38h-12.89s-.47-3.14.89-4.31-1.59-5.36.87-5.71z"
          fill="#0e3944"
        />
        <path data-name="Pfad 1329" d="M41.114 292.75s-.71-9.58 1.17-12.08c0 0 2.07 3.29 4.9 3.29 1.85 0 2.59-4.24 4.17-3.29 1.38.84 2.31 3.23 6 5.68s6.74 1.76 7 6.4z" fill="#04191c" />
        <path
          data-name="Pfad 1330"
          d="M79.974 217.67s-1.33.38-3.7.92c-.81.19-1.73.39-2.76.61-1.47.31-3.16.64-5 1-.86.16-1.77.31-2.71.45-2.33.37-4.89.72-7.63 1l-2.63.26c-2.42.22-5 .4-7.59.5-.91 0-1.83.07-2.75.09a123.435 123.435 0 01-13.61-.44c-.92-.07-1.84-.16-2.77-.28-2.94-.33-5.91-.79-8.86-1.41-.91-.18-1.83-.39-2.74-.61a78.759 78.759 0 01-8.45-2.53s2.75-57.65 27.75-97.43l26.29-6.49s17.16 40.75 17.16 104.36z"
          fill="#105e99"
        />
        <path
          data-name="Pfad 1331"
          d="M76.274 218.59c-.81.19-1.73.39-2.76.61 0-2.28 0-5-.07-8.09-.06-4.62-.2-10-.43-15.77-.14-2.88-.24-5.86-.44-8.89s-.35-6.1-.6-9.16l-.36-4.6c-.15-1.53-.29-3.05-.44-4.55-.26-3-.65-6-1-8.85s-.8-5.61-1.18-8.23c-.1-.66-.2-1.3-.29-1.94l-.36-1.86c-.23-1.22-.46-2.41-.67-3.54-.39-2.28-1-4.31-1.4-6.14-.22-.92-.43-1.77-.62-2.56s-.46-1.49-.67-2.13c-.66-2.09-1.09-3.45-1.22-3.89.15.43.63 1.78 1.35 3.85.22.64.46 1.35.73 2.11l.7 2.54c.47 1.82 1.13 3.85 1.58 6.12.26 1.13.52 2.31.79 3.53.13.61.27 1.23.41 1.86s.24 1.27.36 1.93c.45 2.61 1 5.35 1.43 8.22s.91 5.82 1.26 8.84c.19 1.5.39 3 .58 4.55s.33 3.07.5 4.6c.35 3.07.63 6.15.89 9.18s.48 6 .71 8.9c.41 5.77.7 11.17.91 15.8.15 2.84.24 5.39.31 7.56z"
          fill="#0d527c"
        />
        <path
          data-name="Pfad 1332"
          d="M68.464 220.16c-.86.16-1.77.31-2.71.45v-5.81c0-4.51-.11-9.76-.23-15.4-.07-2.81-.15-5.72-.23-8.68s-.19-6-.34-9-.27-6-.45-9c-.09-1.48-.17-2.94-.26-4.39s-.21-2.87-.31-4.28c-.18-2.81-.43-5.52-.68-8.09s-.51-5-.78-7.23c-.14-1.12-.25-2.2-.4-3.22s-.3-2-.44-2.88c-.28-1.81-.51-3.39-.81-4.67-.4-1.94-.67-3.25-.77-3.78.13.53.44 1.83.9 3.75.34 1.28.61 2.85.95 4.66l.54 2.87c.17 1 .32 2.08.49 3.2.34 2.23.72 4.65 1 7.22s.66 5.28.94 8.09c.14 1.4.29 2.83.44 4.28s.26 2.91.4 4.38c.27 3 .5 6 .72 9s.42 6 .62 9 .35 5.87.51 8.69c.3 5.63.54 10.9.71 15.41.06 1.97.13 3.78.19 5.43z"
          fill="#0d527c"
        />
        <path
          data-name="Pfad 1333"
          d="M56.434 168.14c0-5.61 0-10.84-.1-15.32-.05-2.24-.07-4.3-.16-6.12s-.1-3.41-.19-4.71c-.12-2-.21-3.29-.24-3.83.06.54.18 1.86.37 3.82.13 1.3.21 2.89.34 4.71s.22 3.87.34 6.11c.23 4.49.42 9.72.59 15.33s.29 11.59.4 17.57c.23 13.37.31 26.73.34 35.93l-2.63.26c.26-9.19.6-22.69.79-36.19.08-5.98.14-11.96.15-17.56z"
          fill="#0d527c"
        />
        <path
          data-name="Pfad 1334"
          d="M47.114 183.83c.1 15 .5 29.95.76 38.56-.91 0-1.83.07-2.75.09 0-8.62.11-23.65.49-38.66.14-5.75.33-11.5.55-16.89.09-2.69.24-5.3.35-7.77s.28-4.8.4-7 .29-4.12.42-5.87.26-3.27.39-4.52c.25-2.5.4-3.93.4-3.93s-.1 1.43-.27 3.94c-.09 1.25-.17 2.78-.24 4.53s-.18 3.72-.23 5.87-.15 4.49-.17 7-.1 5.07-.1 7.76v16.89z"
          fill="#0d527c"
        />
        <path
          data-name="Pfad 1335"
          d="M41.324 136.08s-.3 1.43-.83 3.92c-.28 1.25-.59 2.76-.89 4.51s-.7 3.72-1 5.88-.72 4.5-1.06 7-.71 5.09-1 7.81-.68 5.51-1 8.36-.62 5.75-.89 8.65c-.57 5.8-1 11.61-1.49 17.06s-.79 10.53-1.08 14.89c-.2 3-.38 5.69-.52 7.9-.92-.07-1.84-.16-2.77-.28.22-2.19.47-4.84.77-7.83.43-4.36 1-9.44 1.56-14.88s1.28-11.24 2-17c.36-2.89.78-5.78 1.17-8.63s.84-5.64 1.24-8.34.88-5.31 1.28-7.79.89-4.8 1.29-6.95.85-4.11 1.21-5.85.72-3.25 1-4.49z"
          fill="#0d527c"
        />
        <path
          data-name="Pfad 1336"
          d="M36.414 129l-1.51 3.88-.79 2.12-.47 1.2-.43 1.33-2 6.12c-.65 2.27-1.3 4.74-2 7.34s-1.27 5.41-1.95 8.27-1.16 5.88-1.77 8.91-1.05 6.15-1.53 9.25-.92 6.21-1.26 9.29-.74 6.09-1 9-.54 5.77-.76 8.46-.37 5.23-.54 7.58c-.2 3.28-.34 6.18-.44 8.57-.91-.18-1.83-.39-2.74-.61.16-2.31.38-5.06.65-8.15.25-2.36.46-4.91.78-7.61s.62-5.54 1-8.47.81-6 1.28-9 1-6.18 1.54-9.3 1.2-6.19 1.81-9.24 1.33-6 2.05-8.88 1.43-5.64 2.2-8.24 1.53-5.05 2.25-7.3 1.52-4.27 2.17-6.08l.47-1.31.5-1.19.89-2.1c.85-2.06 1.41-3.41 1.6-3.84z"
          fill="#0d527c"
        />
        <path data-name="Pfad 1337" d="M88.364 100.41c.79-1.47 5.36 4.34 3.53 9.15s-4.53-3-4.53-3-1.25-1.89 1-6.15z" fill="#a1b1b5" />
        <path data-name="Pfad 1338" d="M91.894 97.44l-5.45 7.25 1.32 8.62s4.89-8.57 6.52-11.25-2.39-4.62-2.39-4.62z" fill="#ff8b66" />
        <path
          data-name="Pfad 1339"
          d="M50.004 75.21s12 4.36 15.6 7.7l9.76 30.22s.33-2.11 1-1.25l12-11.47s-1.72 9.15 3.53 9.15l-1.67 3a40.122 40.122 0 01-1.58 4.66c-3.9 6.23-8.52 15.55-12.61 16.69-6 1.66-16.34-24.51-16.34-24.51z"
          fill="#bcc7c9"
        />
        <path
          data-name="Pfad 1340"
          d="M34.184 120.56c4.71 6.66 28.63 7.1 32.63 0 2.54-4.56-2.7-7.56-2.7-7.56 12.78-21.82-9.55-37.1-9.55-37.1s-20.83-7.64-32.26 2.17 12.81 40.04 12.81 40.04c-.28.67-1.51 1.63-.93 2.45z"
          fill="#cfd8dc"
        />
        <path
          data-name="Pfad 1341"
          d="M29.114 42.92s-4.9-5-9.62-.68.24 13.23 0 14.86-4.06 2-3.14 4.09 3.37 2.38 3.14 3.38-2.56 2.61-1.56 3.64 3.19 0 3 1.75-1.34 4.11.82 4.28c0 0-1.66-2-.16-2.67s2.65.07 3.32-2-2.43-3-1-4.11 4.44-1.17 3-3-2-3.51.73-5 1.47-14.54 1.47-14.54z"
          fill="#8b383e"
        />
        <path data-name="Pfad 1342" d="M50.264 40.48c5.51-.15 5.87 11.92-.73 18.34s-8.25-18.11.73-18.34z" fill="#8b383e" />
        <path data-name="Pfad 1343" d="M31.484 39.31s16.42-5.5 19.73 6.06.3 23.76-7.4 22.94c-10.85-1.15-25.08-22.5-12.33-29z" fill="#ff8b66" />
        <path data-name="Pfad 1344" d="M39.754 55.55a1.62 1.62 0 101.7-1.09 1.45 1.45 0 00-1.7 1.09z" fill="#db614d" />
        <path data-name="Pfad 1345" d="M41.034 48.46a.37.37 0 00.27-.16 2.26 2.26 0 012-.94.342.342 0 00.07-.68 2.93 2.93 0 00-2.67 1.25.35.35 0 00.1.47.42.42 0 00.23.06z" fill="#8b383e" />
        <path data-name="Pfad 1346" d="M48.604 46.7a.32.32 0 00.19-.07 2.22 2.22 0 012.22-.28.343.343 0 00.27-.63 2.92 2.92 0 00-2.92.38.34.34 0 00.24.6z" fill="#8b383e" />
        <path data-name="Pfad 1347" d="M50.264 58.82s-2.47 1.6-6.81.2c0 0 1.31 5.13 4.06 4.48s2.75-4.68 2.75-4.68z" fill="#1a4850" />
        <path data-name="Pfad 1348" d="M38.024 45.25a18.68 18.68 0 00-3.56 11s-2.76-3.38-4.2-.45 4.33 7.22 4.33 7.22-9.29.53-10.38-11.49c-.46-5.06 6.11-9.68 13.81-6.28z" fill="#8b383e" />
        <path
          data-name="Pfad 1349"
          d="M46.644 74.72c-1.82-2-.64-6.69-.64-6.69-5.11 1.68-9.95-4.2-11.21-7.59a1.11 1.11 0 01-1.82.63s3.52 7.08 1.32 13.14c3.58 1.48 7 3.35 10.51 5.06l.49-.13a10.72 10.72 0 011.35-4.42z"
          fill="#ef6e4b"
        />
        <path
          data-name="Pfad 1350"
          d="M49.454 57.1l-.31-.68a1.29 1.29 0 00.78-1.64 1 1 0 00-.55-.61 1.17 1.17 0 00-.86.11l-.36-.66a2 2 0 011.47-.16 1.71 1.71 0 011 1 2 2 0 01-1.17 2.64z"
          fill="#db614d"
        />
        <path data-name="Pfad 1351" d="M42.174 52.59c0 .74.46 1.31.93 1.28s.81-.65.76-1.39-.47-1.31-.93-1.28-.82.65-.76 1.39z" fill="#1a4850" />
        <path data-name="Pfad 1352" d="M41.724 52.4s.39-1.7 1-1.74 1.62 1.09 1.62 1.09l1.1-.81-1.06.42s-.92-1.29-1.76-1-.9 2.04-.9 2.04z" fill="#1a4850" />
        <path data-name="Pfad 1353" d="M49.014 51.53c0 .74.46 1.31.93 1.28s.81-.65.76-1.39-.47-1.31-.93-1.28-.81.65-.76 1.39z" fill="#1a4850" />
        <path data-name="Pfad 1354" d="M48.564 51.34s.39-1.7 1-1.74 1.62 1.09 1.62 1.09l1.1-.8-1.06.41s-.92-1.29-1.76-1-.9 2.04-.9 2.04z" fill="#1a4850" />
        <path
          data-name="Pfad 1355"
          d="M39.504 42.43s.55 12.13-5 13.83c0 0-3.65-6.2-8.46-1.86 0 0-5.15-14.14 7-16.4 0 0 2.35-5.41 11.78-3.69 13.17 2.4 11.22 14.15 8.34 17.17-.05 0-2.25-12.15-13.66-9.05z"
          fill="#8b383e"
        />
        <path data-name="Pfad 1356" d="M34.844 72.11s1.88 3.17 10 6.18a7.85 7.85 0 00.82-6.18s4.6 1.7 6.88 5.26h-4.08l-.62 4.32-10.62-4.32-6.12.92s-1.14-5.37 3.74-6.18z" fill="#bcc7c9" />
        <path data-name="Pfad 1357" d="M46.644 80.12a38.28 38.28 0 019.2 15.44c2.88 9.5 2.14 15.42 2.14 15.42s.25-18.79-12.57-30.86z" fill="#bcc7c9" />
        <path
          data-name="Pfad 1358"
          d="M97.594 89.19l-3.57 4.25c-3.58 4.25-2.68 4.59-2 7.51.63 2.73 5.74 2.13 7.78 1.3 1.72-.68 6.32.38 7.59-.58.51-.38 0-.59-1.48-.73a11.08 11.08 0 01-3.28-.93c1.1-1.61 7.33-.36 8.25-2.26.43-.92-7 .72-7.73-.23 0 0 .35-.62 3.94-1.73 2.06-.63 4.18-1.27 4.3-2 .21-1.26-3.87.82-9 1.28 0 0 2.09-1.07 4.09-1.93 1.84-.78 3.63-1.32 3.21-2.11-.35-.64-3.18.66-5 1.42a22.259 22.259 0 01-7.68 1.73 8.372 8.372 0 01-1.09 0c3.19-4.61 1.67-4.99 1.67-4.99z"
          fill="#ff8b66"
        />
        <path data-name="Pfad 1359" d="M95.854 94.21s.34 2.42-1 3.76a3 3 0 002.05-3.75 8.375 8.375 0 01-1.05-.01z" fill="#ef6e4b" />
        <path
          data-name="Pfad 1360"
          d="M59.944 142.36c.17 1.58-31.94 5.15-35.27 5.16a.761.761 0 01-.26 0c-1-.31-4.79-26.22-3.3-26.62 7.34-1.91 33.17-5.95 34.32-5.09l.15.21c1.24 2.53 4.19 24.77 4.36 26.34z"
          fill="#0e3944"
        />
        <path
          data-name="Pfad 1361"
          d="M59.944 142.36c.17 1.58-31.94 5.15-35.27 5.16l.24-.67s32.66-3.55 33.06-5-3.73-24.53-3.73-24.53a1.28 1.28 0 011.19-1.5l.15.21c1.24 2.52 4.19 24.76 4.36 26.33z"
          fill="#0a2f33"
        />
        <path
          data-name="Pfad 1362"
          d="M40.824 130.5a2.5 2.5 0 01-1.94 3.23 3.21 3.21 0 01-3.24-2.64 2.5 2.5 0 011.94-3.24 2.1 2.1 0 01.56 0 2 2 0 002 2.68 1.22 1.22 0 00.66-.27 1.987 1.987 0 01.02.24z"
          fill="#cfd8dc"
        />
        <path data-name="Pfad 1363" d="M44.394 137.63a6.37 6.37 0 012.69 1.31c.83.9 2.23 3.7 3.59 4s1.27 2-2.16.15-6.17-2.99-4.12-5.46z" fill="#ef6e4b" />
        <path data-name="Pfad 1364" d="M43.944 139.68l4.57 3.45s3.15 2.38 2.71 3-3.3-.37-3.3-.37-1.72 1.25-2.28 1.11-2.36-1-3-.29-6.81-2.57-6.81-2.57 6.08-4.44 8.11-4.33z" fill="#ff8b66" />
        <path data-name="Pfad 1365" d="M46.564 141.65a6.17 6.17 0 01-2.17-4s-4-1.17-5.75-.76-7.36-2.61-7.36-2.61l-1.55 5.6a58.785 58.785 0 006.1 4.15c2.02.97 10.73-2.38 10.73-2.38z" fill="#ff8b66" />
        <path
          data-name="Pfad 1366"
          d="M21.004 79.47s7.3-.68 7.77 10.42c.66 15.74-11.6 26.73-11.6 26.73s4.81 6.86 6.43 7.69 5.17 7.18 5.17 7.18l3.51 2.49c.61 2.51-3.51 7.2-3.51 7.2l-4.8-2.56a18.81 18.81 0 01-6.19-.46c-2.5-.82-14.81-12.22-17.48-13.75s12.91-37.36 20.7-44.94z"
          fill="#bcc7c9"
        />
        <path data-name="Pfad 1367" d="M153.514 45.29c-7.24 3.48-19.72 47.65-19.72 47.65l-.15-.15-1.37 17.6a5.49 5.49 0 004.46 2.5c7.66 1.5 19.67-37.27 19.67-37.27z" fill="#0a2f33" />
        <path
          data-name="Pfad 1368"
          d="M113.864 290.9a14.287 14.287 0 00.11 1.85h31.45v-1.85a57.609 57.609 0 00-2.95-15.37l-7.86-3.37a12.55 12.55 0 01-4.61 8.32 11 11 0 01-1.8 1.16c-.29.15-.58.3-.88.43a14.748 14.748 0 01-2 .75 49.39 49.39 0 01-7.55 1.32c-3.4.52-3.91 4.39-3.91 6.76z"
          fill="#04191c"
        />
        <path data-name="Pfad 1369" d="M113.864 290.9a14.287 14.287 0 00.11 1.85h31.45v-1.85z" fill="#04191c" />
        <path
          data-name="Pfad 1370"
          d="M169.694 290.9a7.9 7.9 0 00.36 1.85h20.95a9.7 9.7 0 00.59-1.85c2.06-10-11.86-22.29-11.86-22.29l-4.42 5.89s.51 9.44-3.23 11.4a4.81 4.81 0 00-2.39 5z"
          fill="#04191c"
        />
        <path data-name="Pfad 1371" d="M191.594 290.9h-21.9a7.9 7.9 0 00.36 1.85h20.95a9.7 9.7 0 00.59-1.85z" fill="#04191c" />
        <path
          data-name="Pfad 1372"
          d="M204.684 128.84c0 19.93-2 40.23-2.46 45.38s-10.63 110.11-10.63 110.11-10.86-8.78-17-2.26l1.34-15.89 2.73-111-4.85-.31-23.27 111.3s-5.06 14.41-5.11 19.56l-15.46-5.26 2.52-7.3 3.94-24s16.08-106.38 14.77-118.32z"
          fill="#0a2f33"
        />
        <path
          data-name="Pfad 1373"
          d="M149.544 72.37c-.07 5.54 4.26 35.69-.06 69.19 0 0 7.42 4.45 19.78 7l2.3-6.81 3.17 7.47c19.27-2.66 31.07-8.16 31.07-8.16s1.5-35.06 2.48-47.7c1-12.2 2.22-24.6 1.89-36.85a35.436 35.436 0 00-.26-4.29 7.061 7.061 0 00-.7-2.8c-2.39-3.51-7.68-5.14-18-8a38 38 0 00-8.41-1.35 103.027 103.027 0 00-11.26 0c-9 .48-15.88 2.38-19.55 6.78l-.3.37a10.091 10.091 0 00-1.21 4c-1 5.9-.81 12.07-.91 18.06 0 .96-.02 2.02-.03 3.09z"
          fill="#0e3944"
        />
        <path
          data-name="Pfad 1374"
          d="M184.974 38.18s5.53 1.08 7.83 3.66 1 13.72 1 13.72h-9.59l4.28 5.37s-21.52 18.44-25.38 35.16l5.08-17-8.58-16.8 5.38-5.5h-7s4.78-18.63 16.76-18.13z"
          fill="#0a2f33"
        />
        <path data-name="Pfad 1375" d="M172.174 32.73l2.22 13.1s3.55 2 11.79-2.59c0 0-3.36-10.1.88-16.75h-2.42c0 .04-2.1 7.51-12.47 6.24z" fill="#ef6e4b" />
        <path
          data-name="Pfad 1376"
          d="M189.684 20.24c.43 12.42-17.28 17.76-20.57 11.84-1.06-1.89-2.12-7.16-2.51-10.79-.09-.82-.16-1.66-.19-2.49-.25-5.66.75-11.29 4-12.53 11.27-4.35 18.7-1.27 19.27 13.97z"
          fill="#ff8b66"
        />
        <path data-name="Pfad 1377" d="M177.684 16.52c0 .84-.36 1.53-.83 1.54s-.86-.68-.87-1.52.36-1.54.83-1.55.86.68.87 1.53z" fill="#1a4850" />
        <path data-name="Pfad 1378" d="M169.944 17.25c0 .85-.36 1.54-.83 1.55s-.87-.68-.88-1.53.37-1.54.84-1.54.86.67.87 1.52z" fill="#1a4850" />
        <path data-name="Pfad 1379" d="M181.004 19.12a1.62 1.62 0 11-1.89-.7 1.45 1.45 0 011.89.7z" fill="#db614d" />
        <path data-name="Pfad 1380" d="M168.324 20.47a1.4 1.4 0 01-1.76.82c-.09-.82-.16-1.66-.19-2.49a2 2 0 01.76 0 1.45 1.45 0 011.19 1.67z" fill="#db614d" />
        <path data-name="Pfad 1381" d="M178.534 12.24a.54.54 0 00.44-.19.51.51 0 00-.09-.71 4.7 4.7 0 00-3.4-.72.508.508 0 10.18 1 3.83 3.83 0 012.61.53.54.54 0 00.26.09z" fill="#0e3944" />
        <path data-name="Pfad 1382" d="M167.204 13.72a.51.51 0 00.53-.48c0-.38.91-.57 1.39-.59a.5.5 0 000-1c-.85 0-2.3.38-2.35 1.53a.51.51 0 00.43.54z" fill="#0e3944" />
        <path
          data-name="Pfad 1383"
          d="M170.314 23.32a6.1 6.1 0 001.26 0l-.09-.74a2.72 2.72 0 01-2.16-.34.69.69 0 01-.2-.42 13.65 13.65 0 002.62-6.91l-.75-.05a13.19 13.19 0 01-2.53 6.6l-.07.08v.1a1.41 1.41 0 00.41 1.13 2.33 2.33 0 001.51.55z"
          fill="#ef6e4b"
        />
        <path data-name="Pfad 1384" d="M169.654 25s.3.51 3 .18a18 18 0 004.36-1.06s-.38 5.37-4.4 5.39c-3.36 0-2.96-4.51-2.96-4.51z" fill="#1a4850" />
        <path data-name="Pfad 1385" d="M186.114 20.44s.26-4.5-2.46-7.11-.36-6.43-.36-6.43-10.91-1.85-15.77 2.57c0 0-3.63-6.4 6.75-9s27.25 5.46 13.08 27.36l2.31-7.58z" fill="#0e3944" />
        <path data-name="Pfad 1386" d="M186.484 19.87s2.44-2.92 4.76-1.17-1.49 7.13-4 7-.76-5.83-.76-5.83z" fill="#ff8b66" />
        <path data-name="Pfad 1387" d="M190.954 99.26c-2.65 0-4.15 12.14 1 13.43.03 0 6.24-13.31-1-13.43z" fill="#04191c" />
        <path
          data-name="Pfad 1388"
          d="M191.114 110.25c-1.13.46-9.08 3.28-10.65 3.07h-.18c-1.53-.3-3.25-1.8-4.32-2.19s-1.5-1.47-2.48-2.19-1.58-2.92-1.58-2.92-3.32-3-1.93-3.36c1.73-.49 3.69 1.24 3.69 1.24l4.28.74s5.15-2 4.93-3.07-2.34-.18-4.21-.34c-1.23-.09-1.32-1.44-1.32-1.44l3.17-.8s4.63-.75 5.63-.34 4.65 2.72 4.65 2.72l6.86-.15 1.32 9s-6.77-.43-7.86.03z"
          fill="#ff8b66"
        />
        <path
          data-name="Pfad 1389"
          d="M180.234 113.29c-1.53-.3-3.25-1.8-4.32-2.19s-1.5-1.47-2.48-2.19-1.58-2.92-1.58-2.92c.84.7 4.89 1.44 5.2 1.77s-.18.68 0 1.36 1.58.7 1.49 1.44a1.41 1.41 0 001 1.77 1.21 1.21 0 01.69.96z"
          fill="#ef6e4b"
        />
        <path
          data-name="Pfad 1390"
          d="M209.214 49.42c-7.06 3.71-9.56 19.17-8.81 25.44l7.76 20.63s-1.52 2.79-.27 3.29l-16.94.48s4.33 4.51 1 13.43c0 0 22.15 6.63 31.64 0 2.74-1.92 4.21-7.4 3.53-11.4-4.84-28.29-17.91-51.87-17.91-51.87z"
          fill="#0a2f33"
        />
        <path
          data-name="Pfad 1391"
          d="M190.394 41.56c-7.09 7.23-13.08 17.79-16.69 24.95-2.32 4.6-3.65 7.8-3.65 7.8a67.12 67.12 0 01-1.55-6.08c-3.33-16.4 1.14-27.23 1.14-27.23l1.92-1 1.73 2.89 1.42 2.37 3.69-2 7.51-4z"
          fill="#cfd8dc"
        />
        <path data-name="Pfad 1392" d="M184.964 36.38l-9.89 7.7-1.96-6.08s-5.72 4.43-3.12 11.42l3.37-5.34 1.49 1.63 3.23-1.63 2.2 5.34s5.29-5.28 7.32-8.45z" fill="#bcc7c9" />
        <path
          data-name="Pfad 1393"
          d="M180.974 44.89l-6 4.54c-.92 4.47-1.23 11-1.27 17.08-2.32 4.6-3.65 7.8-3.65 7.8a67.12 67.12 0 01-1.55-6.08 130.662 130.662 0 014.81-18.8l-2.09-4.54 2.07-2 1.67 1.81 3.44-1.41z"
          fill="#105e99"
        />
        <path data-name="Pfad 1394" d="M120.574 74.05c-1.84-2.77-11.87 6.49-8.88 10.65s10.62-2.46 10.62-2.46.19-5.24-1.74-8.19z" fill="#04191c" />
        <path
          data-name="Pfad 1395"
          d="M104.264 71.71a23.793 23.793 0 014.26 2.49c2.13 2.15 3.32 3.33 5 3.87s3.51 4.79 3.51 4.79a14.75 14.75 0 004.89-6.52s-1.63-1.78-1.72-2.54c-.18-1.51 1.27-3.37.37-6.06a21.342 21.342 0 01-.72-6.52s-1.34-.8-1.85.59.09 3.64-.33 4.19a2 2 0 01-.28.3 1.62 1.62 0 01-1.29.46h-.1c-1.63-.44-4-3.4-4.85-4.82-1.26-2-3.14-4.58-4-3.33s.94 1.6 2.91 6.47l-.4.35c.12.36-2.64-2-4.68-4.55-1.4-1.77-2.15-.26-2 .51s1.8 2.35 2.8 3.65l1.79 2.24-.32.64c-4-2-4.33-3.75-5.46-3.08s1.12 3.13 2.41 3.78a12.47 12.47 0 012.24 1.84c-3.39-.9-4-2.19-4.74-1.46s1.41 2.4 2.56 2.71z"
          fill="#ff8b66"
        />
        <path data-name="Pfad 1396" d="M115.914 71.19c-.56-2.21 1.19-4.58 1.41-4.86a1.62 1.62 0 01-1.29.46 3.9 3.9 0 00-.12 4.4z" fill="#ef6e4b" />
        <path data-name="Pfad 1397" d="M139.274 91.45s-.76-2.59-2.11-1.76-16.56-15.66-16.56-15.66.06 8.91-8.88 10.65c0 0 14.57 20.7 20.58 25.69 1.35 1.13 3.89.55 7-1z" fill="#0a2f33" />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPeople);
export default ForwardRef;
