import * as React from 'react';

function SvgExclamationCircleRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={svgRef} {...props}>
      <path d="M10 0a10 10 0 1010 10A10 10 0 0010 0zm0 18.065A8.065 8.065 0 1118.065 10 8.06 8.06 0 0110 18.065zm1.694-4.194A1.694 1.694 0 1110 12.177a1.7 1.7 0 011.694 1.694zM8.413 5.347l.274 5.484a.484.484 0 00.483.46h1.66a.484.484 0 00.483-.46l.274-5.484a.484.484 0 00-.483-.508H8.9a.484.484 0 00-.487.508z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgExclamationCircleRegular);
export default ForwardRef;
