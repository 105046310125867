import { AccessObjectUpdate, IFileService, PermissionsObject, TaskState, UploadTask, UploadTaskSnapshot, VirtualFileCreationBase } from '@base/core';
import { firebase } from '../config';
interface StorageFile extends Core.VirtualFile {
  storageUrl: string;
}

function union_arrays<T>(x: T[], y: T[]) {
  const obj: any = {};
  for (let i = x.length - 1; i >= 0; --i) obj[x[i]] = x[i];
  for (let i = y.length - 1; i >= 0; --i) obj[y[i]] = y[i];
  const res = [];
  for (const k in obj) {
    res.push(obj[k]);
  }
  return res as T[];
}

function mergeAccessProperties(
  a: PermissionsObject,
  b: PermissionsObject
): {
  users: Core.AccessObject;
  groups: Core.AccessObject;
  visibility: 'public' | 'private';
} {
  const merged = { ...a, ...b };

  if (!merged.groups) merged.groups = {};
  if (b.groups && a.groups) {
    merged.groups.move = union_arrays(a.groups.move ?? [], b.groups.move ?? []);
    merged.groups.read = union_arrays(a.groups.read ?? [], b.groups.read ?? []);
    merged.groups.write = union_arrays(a.groups.write ?? [], b.groups.write ?? []);
    merged.groups.permission = union_arrays(a.groups.permission ?? [], b.groups.permission ?? []);
  }
  if (!merged.users) merged.users = {};
  if (b.users && a.users) {
    merged.users.move = union_arrays(a.users.move ?? [], b.users.move ?? []);
    merged.users.read = union_arrays(a.users.read ?? [], b.users.read ?? []);
    merged.users.write = union_arrays(a.users.write ?? [], b.users.write ?? []);
    merged.users.permission = union_arrays(a.users.permission ?? [], b.users.permission ?? []);
  }
  return merged as any;
}

function substractProperties(
  a: PermissionsObject,
  b: PermissionsObject
): {
  users: Core.AccessObject;
  groups: Core.AccessObject;
  visibility: 'public' | 'private';
} {
  const merged = { ...a };
  if (!merged.groups) merged.groups = {};
  merged.groups.move = (a.groups.move ?? []).filter((x) => !(b.groups.move ?? []).some((y) => y == x));
  merged.groups.read = (a.groups.read ?? []).filter((x) => !(b.groups.read ?? []).some((y) => y == x));
  merged.groups.write = (a.groups.write ?? []).filter((x) => !(b.groups.write ?? []).some((y) => y == x));
  merged.groups.permission = (a.groups.permission ?? []).filter((x) => !(b.groups.permission ?? []).some((y) => y == x));
  if (!merged.users) merged.users = {};
  merged.users.move = (a.users.move ?? []).filter((x) => !(b.users.move ?? []).some((y) => y == x));
  merged.users.read = (a.users.read ?? []).filter((x) => !(b.users.read ?? []).some((y) => y == x));
  merged.users.write = (a.users.write ?? []).filter((x) => !(b.users.write ?? []).some((y) => y == x));
  merged.users.permission = (a.users.permission ?? []).filter((x) => !(b.users.permission ?? []).some((y) => y == x));
  return merged as any;
}

function isNotEmptyPermissionObject(val: PermissionsObject) {
  return !!val.visibility || Object.values(val.groups).some((p) => p.length > 0) || Object.values(val.users).some((p) => p.length > 0);
}

function getPermissionObjectFromUpdate(val: { users: AccessObjectUpdate; groups: AccessObjectUpdate }): { add: PermissionsObject; remove: PermissionsObject; set: PermissionsObject } {
  const add: PermissionsObject = { users: {}, groups: {} },
    remove: PermissionsObject = { users: {}, groups: {} },
    set: PermissionsObject = { users: {}, groups: {} };
  if (val.users) {
    let key: keyof AccessObjectUpdate;
    for (key in val.users) {
      add.users[key] = val.users[key]?.add ?? [];
      remove.users[key] = val.users[key]?.remove ?? [];
      set.users[key] = val.users[key]?.set ?? [];
    }
  }
  if (val.groups) {
    let key: keyof AccessObjectUpdate;
    for (key in val.groups) {
      add.groups[key] = val.groups[key]?.add ?? [];
      remove.groups[key] = val.groups[key]?.remove ?? [];
      set.groups[key] = val.groups[key]?.set ?? [];
    }
  }
  return { add, remove, set };
}

function storageStateToTaskStateMapping(state: string): TaskState {
  switch (state) {
    case firebase.storage.TaskState.CANCELED:
      return 'cancelled';
    case firebase.storage.TaskState.ERROR:
      return 'error';
    case firebase.storage.TaskState.PAUSED:
      return 'paused';
    case firebase.storage.TaskState.RUNNING:
      return 'running';
    case firebase.storage.TaskState.SUCCESS:
      return 'success';

    default:
      return 'error';
  }
}

export class FileService implements IFileService {
  async setFavoriteForFile(fileId: string, uid: string, isFavorite: boolean): Promise<void> {
    console.log({ fileId, uid, isFavorite });
    if (isFavorite)
      await this.getCurrentCollection()
        .doc(fileId)
        .update({ [`favoriteOf.${uid}`]: true });
    else
      await this.getCurrentCollection()
        .doc(fileId)
        .update({ [`favoriteOf.${uid}`]: firebase.firestore.FieldValue.delete() });
  }

  async getFavoriteFiles(uid: string, groups?: string[]): Promise<Core.VirtualFile[]> {
    return await this.queryFiles(uid, groups, [{ value: true, field: `favoriteOf.${uid}` }]);
  }

  constructor(private collectionRoot: string, storageRoot: string) {}

  async batchUpdateFile(updates: { fileId: string; update: any }[]) {
    const batch = firebase.firestore().batch();
    for (const { fileId: id, update: change } of updates) {
      batch.update(this.getCurrentCollection().doc(id), change);
    }
    await batch.commit();
  }

  async getFiles(fileIds: string[]): Promise<Core.VirtualFile[]> {
    let files: Core.VirtualFile[];
    await firebase.firestore().runTransaction(async (t) => {
      files = await Promise.all(
        fileIds.map(async (fileId) => {
          const sn = await t.get(this.getCurrentCollection().doc(fileId));
          return transformItem({ ...sn.data(), id: sn.id });
        })
      );
    });
    return files;
  }

  private getCurrentCollection() {
    return firebase.firestore().collection(this.collectionRoot);
  }

  private getCurrentStorageRef() {
    return firebase.storage().ref(this.collectionRoot);
  }

  getDocsWithIdFromSN(sn: firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>) {
    return sn.docs.map((d) => ({ ...d.data(), id: d.id }));
  }

  getRootFiles(): Promise<Core.VirtualFile[]>;
  getRootFiles(uid: string, groups: string[]): Promise<Core.VirtualFile[]>;
  async getRootFiles(uid?: string, groups?: string[]) {
    return this.getChildren(null, uid as string, groups as string[]);
  }

  getChildren(parent: string | null): Promise<Core.VirtualFile[]>;
  getChildren(parent: string | null, uid: string, groups: string[]): Promise<Core.VirtualFile[]>;
  getChildren(parent: string | null, uid: string, groups: string[], equalQueries: { field: string; value: any }[]): Promise<Core.VirtualFile[]>;
  async getChildren(parent: string | null, uid?: string, groups?: string[], equalQueries?: { field: string; value: any }[]) {
    return await this.queryFiles(uid, groups, [{ value: parent, field: 'parent' }, ...(equalQueries ?? [])]);
  }

  async queryFiles(uid?: string, groups?: string[], equalQueries?: { field: string; value: any }[]) {
    const items: { [key: string]: any } = {};
    let query: firebase.default.firestore.CollectionReference | firebase.default.firestore.Query = this.getCurrentCollection();

    for (const { field, value } of equalQueries ?? []) {
      query = query.where(field, '==', value);
    }

    function addAll(elements: ({ id: string } & unknown)[]) {
      elements.forEach((e) => (items[e.id] = e));
    }

    try {
      const sn = await query.get();
      addAll(this.getDocsWithIdFromSN(sn));
      console.log('getAll Succeded!!!');
    } catch (error) {
      if (uid != undefined && groups != undefined) {
        const byUserSN = await query.where('permissions.users.read', 'array-contains', uid).get();
        addAll(this.getDocsWithIdFromSN(byUserSN));
        try {
          if (groups.length > 0) {
            const byGroupsSN = await query.where('permissions.groups.read', 'array-contains-any', groups).get();
            addAll(this.getDocsWithIdFromSN(byGroupsSN));
          }
        } catch {}
        const byPublic = await query.where('permissions.visibility', '==', 'public').get();

        addAll(this.getDocsWithIdFromSN(byPublic));
      } else {
        const byPublic = await query.where('permissions.visibility', '==', 'public').get();

        // const sn = await query.get();
        // addAll(this.getDocsWithIdFromSN(sn));
        addAll(this.getDocsWithIdFromSN(byPublic));
      }
    }

    const returnableItems = Object.values(items) as Core.VirtualFile[];

    for (const item of returnableItems) {
      await transformItem(item);
    }
    return returnableItems;
  }

  renameFile(fileId: string, newName: string): Promise<void> {
    return this.getCurrentCollection().doc(fileId).update({ name: newName });
  }

  async fileExists(fileId: string) {
    try {
      return (await this.getCurrentCollection().doc(fileId).get()).exists;
    } catch (error) {
      return false;
    }
  }

  async getFile(fileId: string): Promise<Core.VirtualFile> {
    const sn = await this.getCurrentCollection().doc(fileId).get();
    const data = sn.data() as Core.VirtualFile;
    if (data === undefined) throw new Error('Could not get File with id: ' + fileId);
    data.id = fileId;
    await transformItem(data);
    return data;
  }

  async moveFile(fileId: string, newParent: string | null): Promise<void> {
    if (!newParent || (await this.fileExists(newParent))) {
      if (!newParent) newParent = null; //Make sure newParent is null and not undefined
      return await this.getCurrentCollection().doc(fileId).update({ parent: newParent });
    }
  }

  async deleteFile(fileId: string): Promise<void> {
    let storageUrl;

    await firebase.firestore().runTransaction(async (t) => {
      const fileRef = await t.get(this.getCurrentCollection().doc(fileId));
      if (!fileRef.exists) throw new Error('File does not exist');
      const file = fileRef.data();
      storageUrl = (file as StorageFile).storageUrl ?? undefined;
      t.delete(this.getCurrentCollection().doc(fileId));
    });

    if (storageUrl) {
      const storageFileRef = firebase.storage().ref(storageUrl);
      try {
        await storageFileRef.delete();
      } catch (error) {
        console.error('Could not delete file from storage: ',error);
      }
    }
  }

  async updateAccessProperties(id: string, update: { users: AccessObjectUpdate; groups: AccessObjectUpdate; visibility?: 'public' | 'private' }): Promise<void> {
    await firebase.firestore().runTransaction(async (t) => {
      const file = (await t.get(this.getCurrentCollection().doc(id))).data() as Core.VirtualFile;

      const { add, remove, set } = getPermissionObjectFromUpdate(update);
      const origin = isNotEmptyPermissionObject(set) ? set : file.permissions;
      let newAccess = substractProperties(origin, remove);
      newAccess = mergeAccessProperties(newAccess, add);
      console.log('🚀 ~ file: FileService.ts ~ line 287 ~ FileService ~ awaitfirebase.firestore ~ isNotEmptyPermissionObject(set)', isNotEmptyPermissionObject(set));

      file.permissions = newAccess;
      console.log('🚀 ~ file: FileService.ts ~ line 277 ~ FileService ~ awaitfirebase.firestore ~ update', update);
      if (update.visibility) newAccess.visibility = update.visibility;
      console.log('🚀 ~ file: FileService.ts ~ line 275 ~ FileService ~ awaitfirebase.firestore ~ newAccess', newAccess);
      t.update(this.getCurrentCollection().doc(id), {
        'permissions.users': newAccess.users,
        'permissions.groups': newAccess.groups,
        ...(newAccess.visibility ? { 'permissions.visibility': newAccess.visibility } : {}),
      });
    });
  }

  createFile<T extends VirtualFileCreationBase>(
    parent: string | null,
    data: Blob | Uint8Array | ArrayBuffer,
    accessProperties: PermissionsObject,
    mimeType: Core.FileType,
    other: T
  ): UploadTask<Core.NormalFile> {
    const fileProm = this.createVirtualFile(parent, accessProperties, mimeType, other, true);

    let _next: (sn: UploadTaskSnapshot) => void = () => {},
      _error: (error: Error, fileId: string) => void = () => {},
      _completed: (file: Core.NormalFile) => void = () => {},
      _cancel: () => Promise<boolean> = async () => {
        try {
          const f = await fileProm;
          await this.deleteFile(f.id);
          return true;
        } catch {}
        return false;
      },
      _resume = () => false,
      _pause = () => false,
      _task: firebase.default.storage.UploadTask | undefined;

    const promise = new Promise(async (resolve, reject) => {
      try {
        const file = await fileProm;
        const task = this.getCurrentStorageRef().child(file.id).put(data);
        _task = task;
        _cancel = async () => {
          try {
            if (task.cancel()) {
              await this.deleteFile(file.id);
              return true;
            }
          } catch {
            return false;
          }
        };
        _resume = () => task.resume();
        _pause = () => task.pause();
        this.listenOnTask(task, _next, _error, _completed, fileProm);
        const sn = await task;
        resolve({ ...file, downloadUrl: await sn.ref.getDownloadURL() });
      } catch (error) {
        fileProm.then((file) => {
          this.deleteFile(file.id);
        });
        reject(error);
      }
    });

    return {
      on: (next, error, completed) => {
        if (_task) {
          this.listenOnTask(_task, next, error, completed, fileProm);
        }
        _next = next;
        _error = error;
        _completed = completed;
      },
      cancel: async () => {
        return await _cancel();
      },
      resume: () => _resume(),
      pause: () => _pause(),
      //@ts-ignore
      then: (cb) => promise.then((...args) => cb(...args)),
      //@ts-ignore
      finally: (cb) => promise.finally((...args) => cb(...args)),
      //@ts-ignore
      catch: (cb) => promise.catch((...args) => cb(...args)),
    } as UploadTask<Core.NormalFile>;
  }

  private listenOnTask(
    task: firebase.default.storage.UploadTask,
    _next: (sn: UploadTaskSnapshot) => void,
    _error: (error: Error, fileId: string) => void,
    _completed: (file: Core.NormalFile) => void,
    fileProm: Promise<Core.VirtualFile>
  ) {
    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (sn) =>
        fileProm.then((file) =>
          _next({
            fileId: file.id,
            bytesTransferred: sn.bytesTransferred,
            state: storageStateToTaskStateMapping(sn.state),
            totalBytes: sn.totalBytes,
            mimeType: file.type,
            name: file.name,
          })
        ),
      (error) => fileProm.then((file) => _error(error, file.id)),
      async () => _completed({ ...(await fileProm), downloadUrl: await (await task).ref.getDownloadURL() })
    );
  }

  async createVirtualFile<T extends Partial<VirtualFileCreationBase>>(
    parent: string | null,
    accessProperties: PermissionsObject,
    mimeType: Core.FileType,
    other: T,
    withStorageUrl?: boolean
  ): Promise<Core.VirtualFile> {
    accessProperties = await this.mergeWithParentPermissionsIfExisting<T>(parent, accessProperties);
    const doc = this.getCurrentCollection().doc();

    const r = await doc.set({
      parent: parent ?? null,
      permissions: accessProperties,
      type: mimeType,
      lastUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
      createTime: firebase.firestore.FieldValue.serverTimestamp(),
      ...(withStorageUrl && { storageUrl: `handbook_files/${doc.id}` }),
      ...(other as any),
    } as Core.VirtualFile);
    const res = await doc.get();
    return transformItem({ ...res.data(), id: res.id } as Core.VirtualFile);
  }

  private async mergeWithParentPermissionsIfExisting<T extends Partial<VirtualFileCreationBase>>(parent: string | null, accessProperties: PermissionsObject) {
    if (parent != null && (await this.fileExists(parent))) {
      const parentFile = await this.getFile(parent);

      accessProperties = mergeAccessProperties(parentFile.permissions ?? ({} as any), accessProperties);
    }
    return accessProperties;
  }

  updateNormalFile(fileId: string, data: Blob | Uint8Array | ArrayBuffer): UploadTask<Core.NormalFile> {
    const fileProm = this.getFile(fileId);

    let _next: (sn: UploadTaskSnapshot) => void = () => {},
      _error: (error: Error, fileId: string) => void = () => {},
      _completed: (file: Core.NormalFile) => void = () => {},
      _cancel: () => Promise<boolean> = async () => {
        return false;
      },
      _resume = () => false,
      _pause = () => false,
      _task: firebase.default.storage.UploadTask | undefined;

    const promise = new Promise(async (resolve, reject) => {
      try {
        const file = (await fileProm) as StorageFile;
        const task = firebase.storage().ref(file.storageUrl).put(data);
        _task = task;
        _cancel = async () => {
          try {
            if (task.cancel()) {
              await this.deleteFile(file.id);
              return true;
            }
          } catch {
          } finally {
            return false;
          }
        };
        _resume = () => task.resume();
        _pause = () => task.pause();
        this.listenOnTask(task, _next, _error, _completed, fileProm);
        const sn = await task;
        resolve({ ...file, downloadUrl: await sn.ref.getDownloadURL() });
      } catch (error) {
        fileProm.then((file) => {
          this.deleteFile(file.id);
        });
        reject(error);
      }
    });

    return {
      on: (next, error, completed) => {
        if (_task) {
          this.listenOnTask(_task, next, error, completed, fileProm);
        }
        _next = next;
        _error = error;
        _completed = completed;
      },
      cancel: async () => {
        return await _cancel();
      },
      resume: () => _resume(),
      pause: () => _pause(),
      //@ts-ignore
      then: (cb) => promise.then((...args) => cb(...args)),
      //@ts-ignore
      finally: (cb) => promise.finally((...args) => cb(...args)),
      //@ts-ignore
      catch: (cb) => promise.catch((...args) => cb(...args)),
    } as UploadTask<Core.NormalFile>;
  }

  updateFile<T extends Partial<Pick<Core.VirtualFile, 'permissions' | 'trashed' | 'type' | 'name'>>>(id: string, change: T): Promise<void> {
    return this.getCurrentCollection().doc(id).update(change);
  }
}
async function transformItem(item: any): Promise<Core.NormalFile> {
  const storageItem = item as StorageFile;
  if (storageItem.storageUrl) {
    (item as Core.NormalFile).downloadUrl = firebase.storage().ref(storageItem.storageUrl).getDownloadURL();
    item.downloadUrl.catch((e) => console.error(e));
  }
  item.favoriteOf = Object.keys(item.favoriteOf ?? {});

  //@ts-ignore
  if (item.lastUpdateTime?.toDate) item.lastUpdateTime = item.lastUpdateTime.toDate();

  //@ts-ignore
  if (item.createTime?.toDate) item.createTime = item.createTime.toDate();

  //@ts-ignore
  if (item.deadline?.toDate) item.deadline = item.deadline.toDate();
  //@ts-ignore
  if (item.releaseDate?.toDate) item.releaseDate = item.releaseDate.toDate();

  return item;
}
