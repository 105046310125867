import { Card, CardContent, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Renderers } from '../content-renderer';
import { FormContent } from '../entities/FormContent';
import { cx } from '../helpers';

export function ReaderItem({
  schemaValue,
  value,
  onChange,
  focused,
  setFocus,
  error,
  touched,
  setTouched,
}: {
  schemaValue: FormContent;
  value: any;
  onChange: (val: any) => void;
  focused: boolean;
  setTouched: (touched: boolean) => void;
  setFocus: (ref: HTMLDivElement) => void;
  touched?: boolean;
  error?: any;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const rootref = useRef<HTMLDivElement>(null);

  return (
    <Grid item xs={12} ref={rootref}>
      <div
        className={cx('question__wrapper', focused && 'focused')}
        onFocus={() => {
          setFocus(rootref.current);
        }}
        // onBlur={() => !draggingRef.current && setFocused(false)}
        tabIndex={0}
      >
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography style={{ wordBreak: 'break-word' }} variant="h6" fontWeight="500">
                  {schemaValue.title}
                </Typography>
              </Grid>
              {renderViewer(schemaValue.type, schemaValue.required, schemaValue.content, value, onChange, touched, error, setTouched)}
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </div>
    </Grid>
  );

  function renderViewer(type: string, required: boolean, schema: any, value: any, onChange: (val: any) => void, touched?: boolean, error?: any, setTouched?: (touched: boolean) => void) {
    const Viewer = Renderers[type].viewer;
    return <Viewer value={value ?? Renderers[type].defaultReaderValue} required={required} schema={schema} onChange={onChange} touched={touched} error={error} setTouched={setTouched} />;
  }
}
