import { FormFile, FormSubmission } from '@base/core';
import { SimpleFileProvider } from '@base/firebase';
import { StandardPopup } from '@base/web';
import { Button, Card, CardContent, Stack, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Agreements } from '../../views/campaign/Agreements';
import { FileUploadPopup } from './FileUploadPopup';

export type FileUploadPopupProps = {
  onSave: (files: FormFile[]) => Promise<any>;
  open: boolean;
  onClose: () => void;
  submission: FormSubmission;
};

export function AgreementsNFPopup({ onSave, onClose, open, submission }: FileUploadPopupProps) {
  const { t } = useTranslation();
  const nfFileProvider = useMemo(() => new SimpleFileProvider('/forms/agreements-nf'), []);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  return (
    <StandardPopup onBackdropClick={onClose} visible={open} width={500}>
      <Card>
        <CardContent>
          <Typography variant="h1" mb={2}>
            {t('agreements')}
          </Typography>
          <FileUploadPopup
            title={t('uploadAgreementFiles')}
            fileProvider={nfFileProvider}
            initialFiles={submission.agreement?.nfFiles}
            onSave={onSave}
            onClose={() => setFileUploadOpen(false)}
            open={fileUploadOpen}
          />
          <Agreements content={submission} sentBackFromNFClick={() => setFileUploadOpen(true)} />
          <Stack direction="row" spacing={1} justifyContent="flex-end" mt={2}>
            <Button variant="contained" onClick={onClose}>
              {t('close')}
            </Button>
            {/* <Button variant="contained" endIcon={<FontAwesomeIcon icon={faCheckCircle} />} onClick={() => setFileUploadOpen(true)} color="success">Upload your own files</Button> */}
          </Stack>
        </CardContent>
      </Card>
    </StandardPopup>
  );
}
