import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ReactProps } from '../lib/ReactProps';

export function PrivateRoute({ component, children, ...rest }: ReactProps<RouteProps>) {
  const user = useSelector((state: Core.StateType) => state.auth.user);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return user ? <Route {...{ ...rest, children, component }} /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />;
      }}
    />
  );
}
