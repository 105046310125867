import { FormControl, FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import DatePicker from '@material-ui/lab/DatePicker';
import { format } from 'date-fns';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { date, number } from 'yup';
import { FormContent } from '../entities/FormContent';
import { Renderer, RendererEditorProps, RendererViewerProps } from '../entities/Renderer';
import { SHORT_ANSWER } from './types';

export const DateRenderer: Renderer<undefined, number> = {
  type: SHORT_ANSWER,
  contentType: 'string',
  defaultEditorValue: '' as any,
  viewer,
  viewerMin,
  editor,
  getValidationSchema,
  getTextRepresentation,
};

function viewer({ onChange, value, required, schema, error, touched }: RendererViewerProps<undefined, number>) {
  const { t } = useTranslation();
  //@ts-ignore
  if (value?.toDate) value = value.toDate();
  return (
    <Grid item xs={12} md={8} display="flex" alignItems="center">
      <FormControl error={touched && Boolean(error)}>
        <DatePicker
          allowSameDateSelection
          mask={t('full-date-mask')}
          label="Choose Date"
          renderInput={(params) => <TextField margin="normal" {...params} variant="outlined" />}
          onChange={(d) => onChange(d.valueOf())}
          value={value ?? null}
        />
        {touched && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}

function viewerMin({ onChange, value = null, required, schema, error, touched }: RendererViewerProps<undefined, number>) {
  const { t } = useTranslation();
  //@ts-ignore
  if (value?.toDate) value = value.toDate();
  return <div>{value ? format(new Date(value), t('full-date-mask')) : null}</div>;
}

async function getTextRepresentation({ onChange, value = null, required, schema, error, touched }: RendererViewerProps<undefined, number>) {
  //@ts-ignore
  if (value?.toDate) value = value.toDate();
  return value ? format(new Date(value), 'MM/dd/yyyy') : '';
}

function editor({ onChange, value }: RendererEditorProps<undefined, number>) {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} md={8} display="flex" alignItems="center">
      <DatePicker
        orientation="landscape"
        allowSameDateSelection
        mask={t('full-date-mask')}
        label="Choose Date"
        renderInput={(params) => <TextField margin="normal" {...params} variant="outlined" />}
        disabled
        onChange={() => null}
        value={null}
      />
    </Grid>
  );
}

function getValidationSchema(schema: FormContent<undefined>, t: TFunction<string>) {
  let validator = number();
  if (schema.required) {
    validator = validator.required(t('something-is-required', { something: t('question') }));
  } else {
    validator = validator.nullable();
  }
  return validator.typeError(t('something-is-required', { something: t('question') }));
}
