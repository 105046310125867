import { faLayerPlus, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, useTheme } from '@material-ui/core';
import { FileUpdate } from 'libs/base/core/src/redux/files';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ContentHeader, usePopup } from '../../components/common';
import { AccessManagementPopup } from '../../components/common/popups/AccessManagementPopup';
import { EmptyContentView, Module } from '../../components/content';
import { SortDropdown } from '../../components/content/search';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/Views/ModulView.scss';
import { ConditionalRenderWithRights } from '../../helpers/ConditionalRenderWithRights';
import { getPlainPermissionsFromPermissionUpdate } from '../../helpers/getPlainPermissionsFromPermissionUpdate';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { FileModule } from '../campaign/CampaignOverview';
import { ModuleSettingsPopup } from '../campaign/ModuleSettingsPopup';
import { useCreatePhysicalFileMutation, useDeleteFileMutation, useGetChildren, useUpdateFileMutation, useUpdatePhysicalFileMutation } from './hooks';

export interface Modules {
  id: string;
  faIcon: IconDefinition;
  title: string;
  count: number;
  notifications: number;
  bgImage: any;
  navigateTo: string;
}

export const ModuleOverview = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const notify = usePopup();
  const { parentModuleId } = useParams<{ parentModuleId: string }>();
  const { user } = useSelector((state: Core.StateType) => state.auth);

  // const [searchValue, setSearchValue] = useState('');
  const [realtiveLink, setRelativeLink] = useState('');
  const [accessPopup, setAccessPopup] = useState<Core.VirtualFile>(null);
  const [linkPopupActive, setLinkPopupActive] = useState(false);
  const [settingsPopupVisibility, setsettingsPopupVisibility] = useState<string>(null);
  const [filter, setFilter] = useState<'a-z' | 'z-a'>('a-z');

  const containerID = 'ModuleContainer38';

  const { data: rootFileObj, isLoading } = useGetChildren(null);
  const rootFiles = (rootFileObj?.children ?? []).filter((f: FileModule) => (parentModuleId ? f.parentModule === parentModuleId : !f.parentModule));

  const createMutation = useCreatePhysicalFileMutation();
  const updateImageMutation = useUpdatePhysicalFileMutation();
  const updateFolderMutation = useUpdateFileMutation();
  const deleteFolderMutation = useDeleteFileMutation();
  const [showCreateModule, setShowCreateModule] = useState(false);

  const onUpdate = async (id: string, value: FileUpdate<FileModule> & { imageFile?: File }) => {
    try {
      const { imageFile, ...update } = value;
      if (imageFile) {
        await updateImageMutation.mutateAsync({ data: imageFile, fileId: id });
      }
      await updateFolderMutation.mutateAsync({ fileId: id, update });
      notify({ type: 'success', title: t('successfully-updated') });
    } catch (error) {
      notify({ type: 'error', title: t('update-failed'), text: error });
    }
  };

  const onCreate = async (value: FileUpdate<FileModule> & { imageFile?: File }) => {
    try {
      await createMutation.mutateAsync({
        data: value.imageFile,
        description: value.description,
        name: value.name,
        //@ts-ignore
        icon: value.icon,
        parent: null,
        type: 'folder',
        isParentModule: value.isParentModule,
        parentModule: parentModuleId ? parentModuleId : value.parentModule,
        permissions: getPlainPermissionsFromPermissionUpdate(value),
      });
      notify({ type: 'success', title: t('successfully-created') });
    } catch (error) {
      notify({ type: 'error', title: t('creation-failed'), text: error });
    }
  };

  const onDelete = async (file: FileModule) => {
    try {
      await deleteFolderMutation.mutateAsync(file);
      notify({ type: 'success', title: t('deleted-successfully_named', { name: file.name }) });
    } catch (error) {
      notify({ type: 'error', title: t('delete-failed'), text: error });
    }
  };

  const sortedRootFiles = useMemo(() => {
    const res = rootFiles.sort((a, b) => (a.name ?? '').localeCompare(b.name));
    if (filter === 'z-a') return res.reverse();
    return res;
  }, [rootFiles, filter]);

  return (
    <ContentContainer>
      <ModuleSettingsPopup create visible={showCreateModule} onClose={() => setShowCreateModule(false)} onUpdate={onCreate} />
      <AccessManagementPopup
        subject="Access Verification"
        file={rootFiles.find((f) => f.id === accessPopup?.id)}
        message=""
        relativeLinkUrl={'/files/' + realtiveLink}
        setActive={() => setAccessPopup(null)}
        openLinkFirst={linkPopupActive}
        open={Boolean(accessPopup)}
      />
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.moduleOverview]} />
      <ContentView id={containerID} loading={isLoading} noPadding>
        <ContentHeader title={t('form-folders')} subtitle={t('folder-counted', { count: rootFiles.length })}>
          <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
            <Box flex={1} />
            <ConditionalRenderWithRights campaign_admin file_admin>
              <Button
                sx={{ color: 'primary.main', bgcolor: 'background.paper', ':hover': { bgcolor: 'action.hover' }, borderRadius: 999 }}
                onClick={() => setShowCreateModule(true)}
                variant="contained"
                color="primary"
                endIcon={<FontAwesomeIcon icon={faLayerPlus} />}
              >
                {t('create-main-folder')}
              </Button>
            </ConditionalRenderWithRights>
            <SortDropdown onChange={setFilter} />
          </Stack>
        </ContentHeader>
        <div style={{ padding: theme.spacing(4) }}>
          <div className="module-grid-module-view" id={containerID}>
            {sortedRootFiles.map((file: FileModule, idx) => {
              return (
                <React.Fragment key={file.id}>
                  <Module
                    external={!(file.permissions.users.write.includes(user.id) || user.rights.file_admin || user.roles.superAdmin)}
                    onLinkClick={() => {
                      setRelativeLink(file.id);
                      setLinkPopupActive(true);
                      setAccessPopup(file);
                    }}
                    onAccessClick={() => {
                      setRelativeLink(file.id);
                      setLinkPopupActive(false);
                      setAccessPopup(file);
                    }}
                    onSettingsClick={() => setsettingsPopupVisibility(file.id)}
                    navigateTo={file.isParentModule ? `/file-modules/${file.id}` : `/files/${file.id}`}
                    currentModule={file}
                    count={idx + 1}
                  />
                  <ModuleSettingsPopup
                    possibleParentModules={(rootFileObj.children ?? []).filter((f: FileModule) => f.isParentModule) as FileModule[]}
                    visible={settingsPopupVisibility === file.id}
                    onClose={() => setsettingsPopupVisibility(null)}
                    onUpdate={(u) => onUpdate(file.id, u)}
                    onDelete={() => onDelete(file)}
                    currentModule={file}
                  />
                </React.Fragment>
              );
            })}
            {rootFiles.length === 0 && <EmptyContentView />}
          </div>
        </div>
      </ContentView>
    </ContentContainer>
  );
};
