import React, { useEffect, useState } from 'react';
import type { ReactChildrenProp } from '../../lib';
import { cx } from '../HelperFunctions';

type sliderProps = {
  children?: ReactChildrenProp;
};

export const AuthSlider = ({ children }: sliderProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const slideCount = children ? React.Children.count(children) : 0;

  const changeSlide = () => {
    const interval = setInterval(() => {
      if (selectedIndex >= slideCount - 1) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
    }, 5000)
    return () => clearInterval(interval)
  };

  useEffect(changeSlide, []);

  if (!children) return null;

  return (
    <div className="auth-inside-right-con">
      {React.Children.toArray(children).filter((_, i) => i === selectedIndex)}
      <div className="slider-circle-con">
        {React.Children.map(children, (_, i) => (
          <div onClick={() => setSelectedIndex(i)} className={cx('slider-circle', i === selectedIndex && 'active')} />
        ))}
      </div>
    </div>
  );
};
