import { IFileService, PermissionsObject, VirtualFileCreationBase } from '../entities';
import { FileUpdate } from '../redux/files/thunk';

export class FileRepo {

  constructor(private fileService: IFileService) { }

  fileMap: Map<string, Core.VirtualFile> = new Map();

  async getRootFiles(uid?: string, groups?: string[]): Promise<Core.VirtualFile[]> {
    const files = await this.fileService.getRootFiles(uid, groups);
    this.cacheFilesInMap(files);
    return files;
  }

  async getParents(fileId: string): Promise<Core.VirtualFile[]>  {
    let file = await this.getFile(fileId, true);
    const parents = await this.fileService.getFiles(file.path);
    this.cacheFilesInMap(parents)
    
    return file.path.map(p=> this.fileMap.get(p));
  }

  async getFavoriteFiles(uid: string, groups?: string[]): Promise<Core.VirtualFile[]> {
    const files = await this.fileService.getFavoriteFiles(uid, groups);
    this.cacheFilesInMap(files);
    return files;
  }

  async getChildren(parent: string, uid?: string, groups?: string[], equalQueries?: { field: string; value: any }[]): Promise<Core.VirtualFile[]> {
    const files = await this.fileService.getChildren(parent, uid, groups, equalQueries);
    this.cacheFilesInMap(files);
    return files;
  }

  async getFile(fileId: string, cacheFirst?: boolean) {
    if (cacheFirst) {
      if (this.fileMap.has(fileId)) {
        return this.fileMap.get(fileId);
      }
    }
    const file = await this.fileService.getFile(fileId);
    this.cacheFilesInMap([file]);
    return file;
  }

  async cacheFile(file: Core.VirtualFile) {
    this.cacheFilesInMap([file]);
  }

  async deleteFile(fileId: string): Promise<void> {
    await this.fileService.deleteFile(fileId);
    if (this.fileMap.has(fileId))
      this.fileMap.delete(fileId);
  }

  async updateFile<T extends FileUpdate>(fileId: string, update: T) {
    const { permissions, parent, setFavorite: setFavorite, ...rest } = update;
    if (permissions) {
      await this.fileService.updateAccessProperties(fileId, permissions);
    }
    if (setFavorite) {
      await this.fileService.setFavoriteForFile(fileId, setFavorite.uid, setFavorite.isFavorite);
    }
    if (rest && Object.keys(rest).length > 0) {
      await this.fileService.updateFile(fileId, rest);
    }
    if (parent) {
      await this.fileService.moveFile(fileId, parent);
    }

    return await this.getFile(fileId);
  }

  async batchUpdateFiles<T extends FileUpdate>(updates: { fileId: string; update: T; }[]): Promise<void> {
    return await this.fileService.batchUpdateFile(updates);
  }

  updateNormalFile(fileId: string, data: Blob | Uint8Array | ArrayBuffer) {
    const task = this.fileService.updateNormalFile(fileId, data);
    task.then(() => this.getFile(fileId));
    return task;
  }

  createNormalFile<T extends Partial<Omit<Core.VirtualFile, 'id' | 'trashed' | 'type' | 'permissons' | 'parent'>>>(
    parent: string | null,
    data: Blob | Uint8Array | ArrayBuffer,
    accessProperties: PermissionsObject,
    mimeType: Core.FileType,
    other: T
  ) {
    const task = this.fileService.createFile(parent, data, accessProperties, mimeType, other);
    task.then((f) => this.getFile(f.id));
    return task;
  }

  getFileMap(): { [fileId: string]: Core.VirtualFile; } {
    const map: { [fileId: string]: Core.VirtualFile; } = {};

    this.fileMap.forEach((value, key) => (map[key] = value));
    return map;
  }

  async createVirtualFile<T extends VirtualFileCreationBase>(parent: string | null, accessProperties: PermissionsObject, mimeType: Core.FileType, other: T): Promise<Core.VirtualFile> {
    const file = await this.fileService.createVirtualFile(parent, accessProperties, mimeType, other);
    this.cacheFilesInMap([file]);
    return file;
  }

  private cacheFilesInMap(files: Core.VirtualFile[]) {
    for (const file of files) {
      this.fileMap.set(file.id, file);
    }
  }
}
