import { Austria, England } from './images';

export var LANGUAGES: { language: string; image: string; short: string }[] = [
  {
    language: 'Deutsch',
    short: 'de',
    image: Austria,
  },
  {
    language: 'English',
    short: 'en',
    image: England,
  }
];
