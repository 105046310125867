import { UploadTaskSnapshot } from '../../entities';
import * as ACTION_TYPES from './types';

export const fetchRootFilesRequestAction = () => {
  return {
    type: ACTION_TYPES.FETCH_ROOT_FILES_REQUEST as typeof ACTION_TYPES.FETCH_ROOT_FILES_REQUEST,
  };
};

export const fetchRootFilesSuccessAction = (files: Core.VirtualFile[]) => {
  return {
    type: ACTION_TYPES.FETCH_ROOT_FILES_SUCCESS as typeof ACTION_TYPES.FETCH_ROOT_FILES_SUCCESS,
    files,
  };
};

export const fetchRootFilesFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.FETCH_ROOT_FILES_FAILURE as typeof ACTION_TYPES.FETCH_ROOT_FILES_FAILURE,
    error,
  };
};

export const fetchChildrenRequestAction = () => {
  return {
    type: ACTION_TYPES.FETCH_CHILDREN_REQUEST as typeof ACTION_TYPES.FETCH_CHILDREN_REQUEST,
  };
};

export const fetchChildrenSuccessAction = (files: Core.VirtualFile[], parent: string) => {
  return {
    type: ACTION_TYPES.FETCH_CHILDREN_SUCCESS as typeof ACTION_TYPES.FETCH_CHILDREN_SUCCESS,
    files,
    parentId: parent,
  };
};

export const fetchChildrenFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.FETCH_CHILDREN_FAILURE as typeof ACTION_TYPES.FETCH_CHILDREN_FAILURE,
    error,
  };
};

export const updateFileRequestAction = () => {
  return {
    type: ACTION_TYPES.UPDATE_FILE_REQUEST as typeof ACTION_TYPES.UPDATE_FILE_REQUEST,
  };
};

export const updateFileSuccessAction = (file: Core.VirtualFile, uid?: string) => {
  return {
    type: ACTION_TYPES.UPDATE_FILE_SUCCESS as typeof ACTION_TYPES.UPDATE_FILE_SUCCESS,
    file,
    uid,
  };
};

export const updateFileFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.UPDATE_FILE_FAILURE as typeof ACTION_TYPES.UPDATE_FILE_FAILURE,
    error,
  };
};

export const createFileRequestAction = () => {
  return {
    type: ACTION_TYPES.CREATE_FILE_REQUEST as typeof ACTION_TYPES.CREATE_FILE_REQUEST,
  };
};

export const createFileSuccessAction = (file: Core.VirtualFile) => {
  return {
    type: ACTION_TYPES.CREATE_FILE_SUCCESS as typeof ACTION_TYPES.CREATE_FILE_SUCCESS,
    file,
  };
};

export const createFileFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.CREATE_FILE_FAILURE as typeof ACTION_TYPES.CREATE_FILE_FAILURE,
    error,
  };
};

export const createFolderRequestAction = () => {
  return {
    type: ACTION_TYPES.CREATE_FOLDER_REQUEST as typeof ACTION_TYPES.CREATE_FOLDER_REQUEST,
  };
};

export const createFolderSuccessAction = (folder: Core.VirtualFile) => {
  return {
    type: ACTION_TYPES.CREATE_FOLDER_SUCCESS as typeof ACTION_TYPES.CREATE_FOLDER_SUCCESS,
    folder,
  };
};

export const createFolderFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.CREATE_FOLDER_FAILURE as typeof ACTION_TYPES.CREATE_FOLDER_FAILURE,
    error,
  };
};

export const updateFolderRequestAction = () => {
  return {
    type: ACTION_TYPES.UPDATE_FOLDER_REQUEST as typeof ACTION_TYPES.UPDATE_FOLDER_REQUEST,
  };
};

export const updateFolderSuccessAction = (folder: Core.VirtualFile) => {
  return {
    type: ACTION_TYPES.UPDATE_FOLDER_SUCCESS as typeof ACTION_TYPES.UPDATE_FOLDER_SUCCESS,
    folder,
  };
};

export const updateFolderFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.UPDATE_FOLDER_FAILURE as typeof ACTION_TYPES.UPDATE_FOLDER_FAILURE,
    error,
  };
};

export const updateFileMapAction = (fileMap: { [key: string]: Core.VirtualFile }) => {
  return {
    type: ACTION_TYPES.UPDATE_FILE_MAP as typeof ACTION_TYPES.UPDATE_FILE_MAP,
    fileMap,
  };
};

export const fetchFileRequestAction = () => {
  return {
    type: ACTION_TYPES.FETCH_FILE_REQUEST as typeof ACTION_TYPES.FETCH_FILE_REQUEST,
  };
};

export const fetchFileSuccessAction = (file: Core.VirtualFile) => {
  return {
    type: ACTION_TYPES.FETCH_FILE_SUCCESS as typeof ACTION_TYPES.FETCH_FILE_SUCCESS,
    file,
  };
};

export const fetchFileFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.FETCH_FILE_FAILURE as typeof ACTION_TYPES.FETCH_FILE_FAILURE,
    error,
  };
};

export const updatePhysicalFileRequestAction = () => {
  return {
    type: ACTION_TYPES.UPDATE_PHYSICAL_FILE_REQUEST as typeof ACTION_TYPES.UPDATE_PHYSICAL_FILE_REQUEST,
  };
};

export const updatePhysicalFileSuccessAction = (file: Core.NormalFile) => {
  return {
    type: ACTION_TYPES.UPDATE_PHYSICAL_FILE_SUCCESS as typeof ACTION_TYPES.UPDATE_PHYSICAL_FILE_SUCCESS,
    file,
  };
};

export const updatePhysicalFileFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.UPDATE_PHYSICAL_FILE_FAILURE as typeof ACTION_TYPES.UPDATE_PHYSICAL_FILE_FAILURE,
    error,
  };
};

export const createPhysicalFileRequestAction = () => {
  return {
    type: ACTION_TYPES.CREATE_PHYSICAL_FILE_REQUEST as typeof ACTION_TYPES.CREATE_PHYSICAL_FILE_REQUEST,
  };
};

export const createPhysicalFileSuccessAction = (file: Core.NormalFile) => {
  return {
    type: ACTION_TYPES.CREATE_PHYSICAL_FILE_SUCCESS as typeof ACTION_TYPES.CREATE_PHYSICAL_FILE_SUCCESS,
    file,
  };
};

export const createPhysicalFileFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.CREATE_PHYSICAL_FILE_FAILURE as typeof ACTION_TYPES.CREATE_PHYSICAL_FILE_FAILURE,
    error,
  };
};

export const uploadTaskChangedAction = (update: Partial<UploadTaskSnapshot> & { fileId: string } & { name: string; mimeType: Core.FileType }) => {
  return {
    type: ACTION_TYPES.UPLOAD_TASK_CHANGED as typeof ACTION_TYPES.UPLOAD_TASK_CHANGED,
    update,
  };
};

export const deleteFileRequestAction = () => {
  return {
    type: ACTION_TYPES.DELETE_FILE_REQUEST as typeof ACTION_TYPES.DELETE_FILE_REQUEST,
  };
};

export const deleteFileSuccessAction = (fileId: string) => {
  return {
    type: ACTION_TYPES.DELETE_FILE_SUCCESS as typeof ACTION_TYPES.DELETE_FILE_SUCCESS,
    fileId,
  };
};

export const deleteFileFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.DELETE_FILE_FAILURE as typeof ACTION_TYPES.DELETE_FILE_FAILURE,
    error,
  };
};

export const fetchFavoritesRequestAction = () => {
  return {
    type: ACTION_TYPES.FETCH_FAVORITES_REQUEST as typeof ACTION_TYPES.FETCH_FAVORITES_REQUEST,
  };
};

export const fetchFavoritesSuccessAction = (files: Core.VirtualFile[]) => {
  return {
    type: ACTION_TYPES.FETCH_FAVORITES_SUCCESS as typeof ACTION_TYPES.FETCH_FAVORITES_SUCCESS,
    files,
  };
};

export const fetchFavoritesFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.FETCH_FAVORITES_FAILURE as typeof ACTION_TYPES.FETCH_FAVORITES_FAILURE,
    error,
  };
};

export const clearCompletedUploadTasksAction = () => {
  return {
    type: ACTION_TYPES.CLEAR_COMPLETED_UPLOAD_TASKS as typeof ACTION_TYPES.CLEAR_COMPLETED_UPLOAD_TASKS,
  };
};

export const deleteCancledTaskAction = (task: UploadTaskSnapshot) => {
  return {
    type: ACTION_TYPES.DELETE_CANCLED_TASK as typeof ACTION_TYPES.DELETE_CANCLED_TASK,
    task,
  };
};

export const uploadFilesWithinFoldersRequestAction = () => {
  return {
    type: ACTION_TYPES.UPLOAD_FILES_WITHIN_FOLDERS_REQUEST as typeof ACTION_TYPES.UPLOAD_FILES_WITHIN_FOLDERS_REQUEST,
  };
};

export const uploadFilesWithinFoldersSuccessAction = (fileTree: any) => {
  return {
    type: ACTION_TYPES.UPLOAD_FILES_WITHIN_FOLDERS_SUCCESS as typeof ACTION_TYPES.UPLOAD_FILES_WITHIN_FOLDERS_SUCCESS,
    fileTree,
  };
};

export const uploadFilesWithinFoldersFailureAction = (error: any) => {
  return {
    type: ACTION_TYPES.UPLOAD_FILES_WITHIN_FOLDERS_FAILURE as typeof ACTION_TYPES.UPLOAD_FILES_WITHIN_FOLDERS_FAILURE,
    error,
  };
};
