import { faEnvelopeOpenText } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Button, Card, Chip, Grid, Stack, TextField, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { archivedEmail, StandardPopup } from '../../..';

export type ShowArchivedEmailPopupProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  archivedEmail: archivedEmail;
  onDelete?: () => Promise<void>;
};
export default function ShowArchivedEmailPopup({ open, setOpen, archivedEmail, onDelete }: ShowArchivedEmailPopupProps) {
  const { t } = useTranslation();
  const date = archivedEmail.timestamp?.toDate();
  const dateString = `${date.toLocaleDateString('de')} - ${date.toLocaleTimeString('de', { timeStyle: 'short' })}`;

  return (
    <StandardPopup visible={open} onCloseClick={() => setOpen(false)} onBackdropClick={() => setOpen(false)} width={800}>
      <Card sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" color="primary">
              <FontAwesomeIcon icon={faEnvelopeOpenText} style={{ marginRight: 10 }} />
              {t('email')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography variant="caption" sx={{}}>
                {t('send-date')}:
              </Typography>
              <Typography fontWeight={500}>{dateString}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography variant="caption" sx={{}}>
                {t('subject')}:
              </Typography>
              <Typography fontWeight={500}>{archivedEmail.subject}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={archivedEmail.sendTo}
              getOptionLabel={(option) => option}
              defaultValue={archivedEmail.sendTo}
              contentEditable={false}
              disableClearable
              disabled
              renderInput={(params) => <TextField {...params} label={t('sentTo')} variant="outlined" contentEditable={false} />}
              renderTags={(value) =>
                value.map((option, index) => {
                  return (
                    <Tooltip placement="top" title={option} key={option} style={{ margin: 2 }}>
                      <Chip variant="outlined" color={'primary'} label={option} contentEditable={false} />
                    </Tooltip>
                  );
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            {archivedEmail?.email && <iframe srcDoc={archivedEmail.email} title={archivedEmail.id} width="100%" style={{ borderRadius: 10, borderStyle: 'solid', borderWidth: 2 }} height={500} />}
          </Grid>
          <Grid item xs={12}>
            <Stack style={{ marginTop: 20 }} spacing={1} direction="row" justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                {t('close')}
              </Button>
              {/* <LoadingPromiseButton variant="outlined" color="error" onClick={onDelete}>
                {t('delete')}
                <FontAwesomeIcon icon={faTrash} style={{ marginLeft: 10 }} />
              </LoadingPromiseButton> */}
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </StandardPopup>
  );
}
