export const INFORMATION_DOC = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        { type: 'text', marks: [{ type: 'strong' }], text: 'Health System and Insurance' },
        { type: 'hardBreak' },
        {
          type: 'text',
          text:
            'In order to be eligible for free health care, you will need the European Health Insurance Card (e-Card). A visit to the doctor here on campus is also free of charge. It is also possible to purchase supplemental insurance through the Salzburger Gebietskrankenkasse. A tick vaccination is highly recommended.  ',
        },
        { type: 'text', marks: [{ type: 'link', attrs: { href: 'http://www.sgkk.at/', __confluenceMetadata: null } }], text: 'www.sgkk.at' },
      ],
    },
    {
      type: 'paragraph',
      content: [
        { type: 'text', marks: [{ type: 'strong' }], text: 'Visas and Residence Permits' },
        { type: 'hardBreak' },
        {
          type: 'text',
          text:
            'No visa is required for students from other EU countries. Students from non-EU countries must apply for and receive a validated visa and residence permit from the Austrian diplomatic representatives in their home countries. For your stay in Austria, it is necessary to arrive with a valid passport / identity card and present this at border control as well as evidence of sufficient financial resources and health insurance plan.',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        { type: 'text', marks: [{ type: 'strong' }], text: 'Accommodation and Living Expenses' },
        { type: 'hardBreak' },
        {
          type: 'text',
          text:
            'Your accommodation expenses will cost you roughly around 300 to 400 Euro a month. Your living expenses will come to roughly 250 Euro a month. These prices are for simple accomodation usually consisting of a private room and light housekeeping. If you’re looking for a short-notice, simple and cheap accommodation, please contact the Jugendgästehaus Salzburg, open all year.',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        { type: 'text', text: 'Josef-Preis-Allee 18,' },
        { type: 'hardBreak' },
        { type: 'text', text: 'Tel. +43 662 / 84 26 70' },
        { type: 'hardBreak' },
        { type: 'text', text: 'Fax +43 662 / 84 11 01' },
        { type: 'hardBreak' },
        { type: 'text', marks: [{ type: 'link', attrs: { href: 'http://www.jufa.at/salzburg-e.php', __confluenceMetadata: null } }], text: 'www.jufa.at/salzburg-e.php' },
      ],
    },
    {
      type: 'paragraph',
      content: [
        { type: 'text', marks: [{ type: 'strong' }], text: 'Shops' },
        { type: 'hardBreak' },
        {
          type: 'text',
          text:
            'Supermarkets and shopping malls often open until 7 or 7:30 pm on weekdays; EUROPARK stores are open Monday to Thuesday 9 am to 7:30 pm, Friday until 9 pm and Saturday 9 am till 6 pm. ',
        },
        {
          type: 'text',
          marks: [{ type: 'strong' }],
          text: 'On Sundays and holidays shops at stations and petrol stations are open (but usually more expensive than ordinary supermarkets!).',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        { type: 'text', marks: [{ type: 'strong' }], text: 'Banks' },
        { type: 'hardBreak' },
        {
          type: 'text',
          text:
            'The opening hours vary from bank to bank, but in general they are open Mon - Fri from 9:00 am - 12:00 pm and then from 2:00 pm - 4:00 pm. They are closed on Saturday and Sunday.',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        { type: 'text', marks: [{ type: 'strong' }], text: 'Tourist Information' },
        { type: 'hardBreak' },
        { type: 'text', text: 'Salzburg Tourism, Mozartplatz 5, 5020 Salzburg ' },
        { type: 'hardBreak' },
        { type: 'text', marks: [{ type: 'link', attrs: { href: 'http://www.salzburg.info/', __confluenceMetadata: null } }], text: 'www.salzburg.info' },
      ],
    },
    { type: 'paragraph' },
  ],
};