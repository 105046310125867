import React from 'react';
import type { ReactChildrenProp } from '../../lib';
import '../../css/components/common/shadowBox.scss';

type ShadowBoxProps = {
    style?: React.CSSProperties;
    hidden?: boolean;
    children?: ReactChildrenProp;
    classNames?: string;
} & React.HTMLAttributes<HTMLDivElement>;
export const ShadowBox = ({ style, classNames, hidden = false, children, ...props }: ShadowBoxProps) => (
    <div {...props} className={'shadow-box' + ' ' + (classNames ?? '')} style={{ ...style }} hidden={hidden}>
        {children}
    </div>
);
