import React from 'react';
import { DefaultTheme, ThemeType } from './Theme';

const ThemeContext = React.createContext<ThemeType>(DefaultTheme);
const { Consumer: ThemeConsumer, Provider: ThemeProvider } = ThemeContext;

export function useTheme() {
    return React.useContext<ThemeType>(ThemeContext);
}

export { ThemeConsumer, ThemeProvider, DefaultTheme, useTheme as useSettings };