import { Button, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShowFileProps } from './ShowFileProps';

export function ShowFile({ files, disabled }: ShowFileProps): JSX.Element {
  const { t } = useTranslation();

  console.log(files);
  if (files?.length > 0) {
    return (
      <>
        {files.map((file) => (
          <Paper key={file?.name} variant={'outlined'} className="upload-con">
            {file ? (
              <Typography variant="body1">{file.name}</Typography>
            ) : (
              <div>
                <Typography variant="subtitle1">{t('verification-document-for-nf-status')}</Typography>
                <Typography variant="body2">{t('no-document-was-uploaded')}</Typography>
              </div>
            )}
            <div className="flex-con">
              <a href={file?.downloadUrl} target="_blank">
                <Button variant="contained" color="secondary" disabled={disabled}>
                  {t('download')}
                </Button>
              </a>
            </div>
          </Paper>
        ))}
      </>
    );
  } else {
    return (
      <Paper variant={'outlined'} className="upload-con">
        <Typography variant="body2">{t('no-document-was-uploaded')}</Typography>
      </Paper>
    );
  }
}
