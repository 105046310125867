import * as ACTIONS from './actions';
import * as AUTH_ACTIONS from '../auth/actions';
import type { ThunkAction } from 'redux-thunk';
import type { StateType } from '../store';
import type { ActionsType } from './reducer';
import { IUserService, UserCreationData } from '../../entities';

type QueryParams = {
  /**
   * ATTENTION!! Max Groups are 10
   */
  groups?: string[];
};

export class UsersThunk {
  constructor(private userService: IUserService) {}

  queryUsers = (query: QueryParams = {}): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.queryUsersRequestAction());
      let q = await this.userService.createUserQuery();
      let users = await q.getNextPage(1000);
      dispatch(ACTIONS.queryUsersSuccessAction(users, q));
    } catch (error) {
      dispatch(ACTIONS.queryUsersFailureAction(error));
    }
  };

  fetchNextPage = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      let { users } = getState();
      if (!users.query) throw new Error('Must fetch before trying to update');
      if (users.query.end) return;
      dispatch(ACTIONS.fetchNextPageRequestAction());

      dispatch(ACTIONS.fetchNextPageSuccessAction(await users.query.getNextPage(10)));
    } catch (error) {
      dispatch(ACTIONS.fetchNextPageFailureAction(error));
    }
  };

  createUser = (userData: UserCreationData): ThunkAction<void, StateType, boolean, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.createUserRequestAction());
      let user = await this.userService.createUser(userData);
      dispatch(ACTIONS.createUserSuccessAction(user));
      return true;
    } catch (error) {
      dispatch(ACTIONS.createUserFailureAction(error));
      return false;
    }
  };

  deleteUser = (uid: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.deleteUserRequestAction());
      await this.userService.deleteUser(uid);
      dispatch(ACTIONS.deleteUserSuccessAction(uid));
      return true;
    } catch (error) {
      dispatch(ACTIONS.deleteUserFailureAction(error));
      return false;
    }
  };

  fetchSingleUser = (uid: string): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.fetchSingleUserRequestAction());
      let user = await this.userService.getUserById(uid);
      dispatch(ACTIONS.fetchSingleUserSuccessAction(user));
    } catch (error) {
      dispatch(ACTIONS.fetchSingleUserFailureAction(error));
    }
  };

  updateUser = (uid: string, { photoURL, ...userData }: Partial<Omit<Core.User, 'groups' | 'rights' | 'roles' | 'id'>>): ThunkAction<void, StateType, never, ActionsType> => async (
    dispatch,
    getState
  ) => {
    try {
      dispatch(ACTIONS.updateUserRequestAction());
      await this.userService.updateUser(uid, userData);
      dispatch(ACTIONS.updateUserSuccessAction(uid, userData));
      if (getState().auth.user?.id === uid) {
        dispatch(AUTH_ACTIONS.updateDataSuccessAction(userData) as any);
      }
      return true;
    } catch (error) {
      dispatch(ACTIONS.updateUserFailureAction(error));
      return false;
    }
  };

  updateUserImage = (uid: string, userData: Blob | Uint8Array | ArrayBuffer): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.updateUserRequestAction());
      const downloadUrl = await this.userService.setProfileImage(uid, userData);
      dispatch(ACTIONS.updateUserSuccessAction(uid, { photoURL: Promise.resolve(downloadUrl) }));
      if (getState().auth.user?.id === uid) {
        dispatch(AUTH_ACTIONS.updateDataSuccessAction({ photoURL: Promise.resolve(downloadUrl) }) as any);
      }
      return true;
    } catch (error) {
      dispatch(ACTIONS.updateUserFailureAction(error));
      return false;
    }
  };

  setUserRights = (uid: string, rights: Core.Rights): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.setUserRightsRequestAction());
      await this.userService.setUserRights(uid, rights);
      dispatch(ACTIONS.updateUserSuccessAction(uid, { rights }));
    } catch (error) {
      dispatch(ACTIONS.setUserRightsFailureAction(error));
    }
  };

  setUserRoles = (uid: string, roles: Core.Roles): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.setUserRolesRequestAction());
      await this.userService.setUserRoles(uid, roles);
      dispatch(ACTIONS.updateUserSuccessAction(uid, { roles }));
      dispatch(ACTIONS.setUserRolesSuccessAction());
    } catch (error) {
      dispatch(ACTIONS.setUserRolesFailureAction(error));
    }
  };

  updateUserGroups = (uid: string, groups: { add: string[]; remove: string[] }): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.updateUserGroupsRequestAction());
      await this.userService.updateUserGroups(uid, groups);

      dispatch(ACTIONS.updateUserGroupsSuccessAction(uid, groups));
    } catch (error) {
      dispatch(ACTIONS.updateUserGroupsFailureAction(error));
    }
  };

  updateUserCreateDefaultState = (): ThunkAction<void, StateType, never, ActionsType> => async (dispatch, getState) => {
    try {
      dispatch(ACTIONS.updateUserCreateDefaultStateAction());
    } catch (error) {
      dispatch(ACTIONS.updateUserFailureAction(error));
    }
  };
}
