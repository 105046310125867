import { faBooks, faCog, faFileSignature, faInboxIn, faInboxOut, faInfoCircle, faStar, faUsers, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faChartNetwork } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSettings } from '../theme/ThemeProvider';
import { useDeleteGroupFileGroupMutation, useUpdateGroupMutation } from '../views/users/hooks/mutations';
import { useGetGroup } from '../views/users/hooks/useGetGroup';
import { MainModule, MainModuleProps } from './content/Main/MainModule';
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons';
import { Dialog, Theme } from '@material-ui/core';
import styled from '@material-ui/styles/styled';
import ReactPlayer from 'react-player';
import { RequestPopup } from '@access-request';

export declare namespace Dashboard {
  export interface DashBoardItemBase {
    column?: number;
    row?: number;
    columnSpan?: number;
    rowSpan?: number;
    backgroundColor?: string;
    visibility?: 'external' | 'internal' | 'both';
    color?: string;
    rights?: Core.Rights;
  }

  export interface ReactDashboardItem extends DashBoardItemBase {
    render: () => JSX.Element;
  }

  export interface DashBoardItem extends DashBoardItemBase {
    title: string | { [language: string]: string };
    icon: IconDefinition;
    useTitleAsI18NKey?: boolean;
    actions: {
      navigateTo?: string;
      external?: boolean;
      onClick?: () => void;
    };
  }

  export interface DashboardConfig {
    items?: (DashBoardItem | ReactDashboardItem)[];
    groups?: { title: string | { [language: string]: string }; items: (DashBoardItem | ReactDashboardItem)[] }[];
  }
}

function getFirstMatchingGroup(groupRights: Core.GroupRights) {
  for (const arr of Object.values(groupRights ?? {})) {
    if (arr && arr.length > 0) return arr[0];
  }
}

//Not an ideal solution... but it works
function getFirstGroup(groups: Core.Groups) {
  return Object.keys(groups)?.[0];
}

export const defaultDashboardItems = {
  documents: {
    actions: {
      navigateTo: '/files',
    },
    icon: faBooks,
    title: 'documents',
    useTitleAsI18NKey: true,
  },
  campaign: {
    actions: {
      navigateTo: '/campaigns',
    },
    icon: faInboxIn,
    rights: {
      campaign_admin: true,
    },
    title: 'campaigns',
    useTitleAsI18NKey: true,
  },
  nfActivity: {
    actions: {
      navigateTo: '/nf-activity',
    },
    rights: {
      campaign_admin: true,
    },
    icon: faChartNetwork,
    title: 'nf-activity',
    useTitleAsI18NKey: true,
  } as Dashboard.DashBoardItemBase,
  users: {
    actions: {
      navigateTo: '/admin',
    },
    icon: faUsers,
    title: 'users-and-groups',
    useTitleAsI18NKey: true,
  },
  favorites: {
    actions: {
      navigateTo: '/favorites',
    },
    icon: faStar,
    title: 'favorites',
    useTitleAsI18NKey: true,
    visibility: 'internal',
  },
  guidelines: {
    actions: {
      navigateTo: '/file-modules/QvE263uE1RKKJQgt4r7r',
    },
    icon: faInfoCircle,
    title: 'guidelines',
    visibility: 'both',
    useTitleAsI18NKey: true,
  },
  campaignExternal: {
    actions: {
      navigateTo: '/campaigns-external',
    },
    icon: faFileSignature,
    visibility: 'external',
    title: 'campaigns',
    useTitleAsI18NKey: true,
    /* ToDo: Add external tag */
  },
  submissionsExternal: {
    visibility: 'external',
    render: () => {
      const { user } = useSelector((state: Core.StateType) => state.auth);
      if (!getFirstGroup(user.groups)) {
        return null;
      }
      const config = {
        actions: {
          navigateTo: '/submissions-external/' + getFirstGroup(user.groups),
        },
        icon: faInboxOut,
        title: 'Your Applications',
        useTitleAsI18NKey: false,
      };
      return <MainModule {...(config as any)} />;
    },
  },
  usersExternal: {
    visibility: 'external',
    render: () => {
      const { user } = useSelector((state: Core.StateType) => state.auth);
      if (!getFirstGroup(user.groups)) {
        return null;
      }
      const config = {
        actions: {
          navigateTo: '/user-external/' + getFirstGroup(user.groups),
        },
        icon: faUsers,
        title: 'external-user-management',
        useTitleAsI18NKey: true,
      };
      return <MainModule {...(config as any)} />;
    },
  },
  nfInformation: {
    useTitleAsI18NKey: true,
    visibility: 'external',
    render: () => {
      const [popupOpen, setPopupOpen] = useState(false);
      const { user } = useSelector((state: Core.StateType) => state.auth);
      const groupID = getFirstGroup(user.groups);
      const { data: group, isLoading } = useGetGroup(groupID);
      const settings = useSettings();
      const namedGroup = settings.namedGroups.find((g) => group?.type === g.name);

      const { t } = useTranslation();
      const updateGrouptMutation = useUpdateGroupMutation();
      const deleteGrouptFileMutation = useDeleteGroupFileGroupMutation();
      if (!groupID || !namedGroup?.accessRequest) {
        return null;
      }
      const config: MainModuleProps = {
        actions: {
          onClick: () => setPopupOpen(true),
        },
        icon: faCog,
        title: t(group.type) + ' ' + t('settings'),
        visibility: 'external',
        useTitleAsI18NKey: false,
      };

      return (
        <>
          <RequestPopup
            active={popupOpen}
            setActive={setPopupOpen}
            config={{ changeDataMode: user.rights?.groupRights?.group_admin?.some((gId) => gId === groupID), readOnly: !user.rights?.groupRights?.group_admin?.some((gId) => gId === groupID) }}
            description={''}
            name={''}
            topic={t('federation-data')}
            onSave={(data) => updateGrouptMutation.mutateAsync({ ...group, users: { added: [], removed: [] }, requestData: data })}
            loading={isLoading || updateGrouptMutation.isLoading}
            formData={group.requestData}
            onDeleteFiles={async (filesToDelete) => {
              await deleteGrouptFileMutation.mutateAsync({ ...group, filesToDelete });
            }}
            FormContent={namedGroup.accessRequest.FormContent}
            getInitialValues={namedGroup.accessRequest.getInitialValues}
            getSchema={namedGroup.accessRequest.getSchema}
          />
          <MainModule {...config} />
        </>
      );
    },
    /* ToDo: Add external tag */
  },
  videoTutorials: {
    useTitleAsI18NKey: true,
    visibility: 'external',
    render: () => {
      const { t } = useTranslation();
      const [popupOpen, setPopupOpen] = useState(false);
      const { user } = useSelector((state: Core.StateType) => state.auth);
      const videoUrl = 'https://youtu.be/LxRkKhxxgPQ';

      const config: MainModuleProps = {
        actions: {
          onClick: () => setPopupOpen(true),
        },
        icon: faPlayCircle,
        title: t('videoTutorials'),
        visibility: 'external',
        useTitleAsI18NKey: false,
      };

      return (
        <>
          <Dialog open={popupOpen} maxWidth={'lg'} fullWidth onClose={() => setPopupOpen(false)} sx={{ borderRadius: 2 }}>
            <div
              style={{
                // padding: 8,
                backgroundColor: '#000000',
                aspectRatio: '16/9',
                width: '100%',
              }}
            >
              <StyledVideoPlayer width="100%" height="100%" url={videoUrl} controls />
            </div>
          </Dialog>
          <MainModule {...config} />
        </>
      );
    },
  },
  // visibility: 'internal',
};

/**
 *
 * @param items list of items to include (order matters) or leave blank to include all
 */

export function generateDefaultDashboardItems(...items: (keyof typeof defaultDashboardItems)[]) {
  if (items.length === 0) {
    return Object.values(defaultDashboardItems);
  }
  const retItems = [];
  for (const item of items) {
    retItems.push(defaultDashboardItems[item]);
  }
  return retItems;
}

const DashboardContext = React.createContext<Dashboard.DashboardConfig>({ items: generateDefaultDashboardItems() });
const { Consumer: DashboardConsumer, Provider: DashboardProvider } = DashboardContext;

export { DashboardConsumer, DashboardProvider };

export function useDashboardConfig() {
  return React.useContext(DashboardContext);
}

export function getTitleFromI18NObject(val: string | { [language: string]: string }, language: string) {
  if (typeof val == 'string') return val;

  if (val[language] !== undefined) return val[language];
  return val['en'] ?? '';
}

const StyledVideoPlayer = styled(ReactPlayer)(({ theme }: { theme: Theme }) => ({
  '& iframe': {
    display: 'flex',
    borderRadius: 8,
  },
}));
