import * as React from 'react';

function SvgEyeRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.118 20.746" ref={svgRef} {...props}>
      <path d="M15.559 4.322a5.993 5.993 0 00-1.688.27 2.993 2.993 0 01.391 1.459 3.025 3.025 0 01-3.025 3.025 2.993 2.993 0 01-1.459-.391 6.035 6.035 0 105.781-4.363zM30.93 9.584A17.328 17.328 0 0015.559 0 17.33 17.33 0 00.188 9.585a1.748 1.748 0 000 1.577 17.328 17.328 0 0015.371 9.584 17.33 17.33 0 0015.371-9.585 1.748 1.748 0 000-1.577zm-15.371 8.568a14.7 14.7 0 01-12.854-7.78 14.7 14.7 0 0112.854-7.78 14.7 14.7 0 0112.854 7.78 14.7 14.7 0 01-12.854 7.78z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEyeRegular);
export default ForwardRef;
