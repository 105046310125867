import React from 'react';
import type { ReactChildrenProp } from '../../lib/ReactProps';
import { LoadingPopup } from '../common';
import '../../css/Views/contentView.scss';
import { useTheme } from '@material-ui/core/styles';

export interface ContentViewProps {
  children: ReactChildrenProp;
  id?: string;
  onClick?: (event) => void;
  loading?: boolean;
  contentViewCss?: React.CSSProperties;
  presenterContainerCss?: React.CSSProperties;
  noPadding?: boolean;
  onContextMenu?: (event) => void;
}

export const ContentView = React.forwardRef(({ children, id, onClick, loading = false, contentViewCss, presenterContainerCss, noPadding = false, onContextMenu }: ContentViewProps, ref) => {
  const theme = useTheme();
  return (
    <div id={id} className="content-view" onClick={onClick} style={{ ...contentViewCss, padding: noPadding && 0, background: theme.palette.background.paper }} onContextMenu={onContextMenu}>
      {loading ? (
        <LoadingPopup />
      ) : (
        <div
          className="content-presenter-container"
          id={id}
          ref={ref as any}
          style={{ width: noPadding && '100%', height: noPadding && '100%', display: 'flex', flexDirection: 'column', ...presenterContainerCss }}
        >
          {children}
        </div>
      )}
    </div>
  );
});
