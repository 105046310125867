import type { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { SVGImage } from '../../../lib';
import '../../../css/components/common/buttons/button.scss';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

type buttonProps = {
  text?: string;
  bgColor: string;
  contentColor: string;
  onClick?: () => void;
  icon?: SVGImage;
  faIcon?: IconDefinition;
  iconSize?: number;
  borderColor?: string;
  borderWidth?: number;
  disabled?: boolean;
  id?: string;
  style?: React.CSSProperties;
  loading?: boolean;
};

export const StandardButton = ({
  text,
  bgColor,
  onClick,
  borderColor,
  borderWidth = 0,
  disabled = false,
  icon: Icon,
  style = {},
  contentColor = 'white',
  faIcon,
  iconSize = 24,
  id,
  loading = false,
}: buttonProps) => (
  <div
    onClick={() => disabled||loading || onClick?.()}
    className="standard-icon-button"
    style={{
      backgroundColor: bgColor,
      borderColor: borderColor,
      borderWidth: borderWidth,
      color: contentColor,
      opacity: disabled||loading ? 0.7 : 1,
      cursor: disabled||loading ? 'progress' : 'pointer',
      ...style,
    }}
    id={id}
  >
    {Icon && <Icon className="button-icon" style={{ fill: contentColor }} />}
    {(faIcon || loading) && <FontAwesomeIcon className="button-icon" icon={loading ? faSpinnerThird : faIcon} style={{ fontSize: iconSize, marginRight: text ? 12 : 0 }} spin={loading}/>}
    <div>{text}</div>
  </div>
);
