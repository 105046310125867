import { ApplicationForm, Campaign } from '@base/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { usePopup } from '../../../components/common';
import { useGetGroups } from '../../users/hooks/useGetGroups';
import { useGetGroupTags } from '../../users/hooks/useGetGroupTags';
import { CampaignEditor } from '../CampaignEditor';
import { usePublishCampaignMutation, useUpdateFileMutation } from '../hooks/mutations';
import { useGetFile } from '../hooks/useGetFile';
import { useGetForms } from '../hooks/useGetForms';

export function CampaignEditorWrapper({ match }) {
  const { parentId } = useParams<{ parentId: string }>();

  // const { data, isLoading } = useGetChildren(parentId);
  const { data: campaign } = useGetFile<Campaign>(parentId);
  const { data: parent } = useGetFile<Campaign>(campaign.parent, { enabled: Boolean(campaign) });
  const { data: groups } = useGetGroups();
  const { data: groupTags } = useGetGroupTags();
  const { data: forms = [] } = useGetForms<ApplicationForm>(parentId);
  const history = useHistory();
  const notification = usePopup();
  const { t } = useTranslation();

  const updateMutation = useUpdateFileMutation<Campaign>();

  const publishMutation = usePublishCampaignMutation();

  const updateCampaign = async (campaign: Campaign) => {
    const { permissions, ...update } = campaign;
    try {
      await updateMutation.mutateAsync({ fileId: parentId, update });
      notification({ type: 'success', title: t('saved') });
    } catch (error) {
      notification({ type: 'error', title: t('error'), text: error.message });
    }
  };

  return (
    <CampaignEditor
      publishCampaign={async (id) => {
        await publishMutation.mutateAsync({ campaignId: id });
      }}
      groups={groups}
      parent={parent}
      groupTags={groupTags}
      campaign={campaign}
      onUpdateCampaign={updateCampaign}
      forms={forms}
      baseUrl={match.url}
      onCreateForm={() => {
        history.push(match.url + '/forms/new/edit');
      }}
      onEditForm={(id) => {
        history.push(match.url + '/forms/' + id + '/edit');
      }}
    />
  );
}
