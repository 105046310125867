import {Observer} from '../entities';
import {AuthRepo, UsersRepo} from '../repos';

export class AuthInteractor {
  constructor(private authRepo: AuthRepo, private userRepo: UsersRepo) {
  }

  onAuthStateChanged(callback: (loggedIn: null | Core.User) => void) {
    this.authRepo.onAuthStateChanged(callback);
  }

  async updateUserData(update: Partial<Omit<Core.User & { password: string }, 'groups' | 'rights' | 'roles' | 'id'>>) {
    await this.userRepo.updateUser(this.getCurrentUser().id, update);
    const {password, ...rest} = update;
    this.authRepo.updateCachedUser(rest);
  }

  getCurrentUser(): Core.User {
    const user = this.authRepo.getCurrentUser();
    if (user) return user;
    else throw new Error('User is not logged in!');
  }

  async tryAutoLogin(observer?: Observer<Core.User>): Promise<Core.User> {
    return this.authRepo.tryAutoLogin(observer);
  }

  resetPassword(email: string): Promise<void> {
    return this.authRepo.resetPassword(email);
  }

  async clear2Factor(uid: string): Promise<void> {
    return this.authRepo.clear2Factor(uid);
  }

  async logout(): Promise<void> {
    return this.authRepo.logout();
  }

  async provide2faFactor(phoneNumber: string, reCaptchaTriggerElementId?: string): Promise<string> {
    return await this.authRepo.provide2faFactor(phoneNumber, reCaptchaTriggerElementId);
  }

  async verify2faFactor(verificationId: string, verificationCode: string): Promise<void> {
    return await this.authRepo.verify2faFactor(verificationId, verificationCode);
  }

  async verify2FACode(resolver: any, verificationCode: string, observer?: Observer<Core.User>): Promise<Core.User> {
    return await this.authRepo.verify2FACode(resolver, verificationCode, observer);
  }

  async login(email: string, password: string, reCaptchaTriggerElementId: string, observer?: Observer<Core.User>): Promise<Core.User> {
    return this.authRepo.login(email, password, reCaptchaTriggerElementId, observer);
  }
}
