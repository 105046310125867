import React from 'react';
import { SVGImage } from '../../lib';

type slideProps = {
  illustration: SVGImage | string;
  title: string;
  description: string;
};

export const AuthSlide = ({ illustration: Illustration, title, description }: slideProps) => (
  <>
    <div className="right-image-con">
      <div className="bg-circle-auth-right" />
      <div className="image-position-container">{typeof Illustration === 'string' ? <img src={Illustration} className="illustration" /> : <Illustration className="illustration" />}</div>
    </div>
    <div className="right-auth-text-con">
      <div className="right-auth-con-title">
        {title}
        <div className="underline-full-width" />
      </div>
      <div className="right-auth-description">{description}</div>
    </div>
  </>
);
