import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faCopy, faGripHorizontal, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Card, CardActions, CardContent, Divider, FormControlLabel, Grid, IconButton, Switch, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Renderers } from '../content-renderer';
import { FormContent } from '../entities/FormContent';
import { cx } from '../helpers';
import { getValue, SetAction } from './getValue';
import { QuestionSelectDropDown } from './QuestionSelectDropDown';

interface ItemProps {
  value: FormContent;
  onChange: (val: SetAction<FormContent>) => void;
  changeType: (newType: string) => void;
  onRemove: () => void;
  onDownload: () => void;
  duplicate: () => void;
  focused: boolean;
  setFocus: (ref: HTMLDivElement) => void;
  setAsBudgetField?: (set: boolean) => void;
  budgetField?: boolean;
  setAsProjectDurationField?: (set: boolean) => void;
  projectDurationField?: boolean;
}

function Item({ value, onChange, changeType, onRemove, onDownload, duplicate, focused, setFocus, budgetField, setAsBudgetField, projectDurationField, setAsProjectDurationField }: ItemProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const rootref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focused) {
      setFocus(rootref.current);
    }
  }, [focused]);

  return (
    <Grid item xs={12} ref={rootref}>
      <div
        style={style}
        className={cx('question__wrapper', focused && 'focused')}
        onFocus={() => {
          console.log(value.id, ':focus');
          setFocus(rootref.current);
        }}
        // onBlur={() => !draggingRef.current && setFocused(false)}
        tabIndex={0}
        ref={setNodeRef}
        {...attributes}
      >
        <Card sx={{ boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.08)' }}>
          <div className="drag-handle__wrapper" onMouseDown={(e) => e.preventDefault()} onMouseUp={(e) => e.preventDefault()} onClick={() => setFocus(rootref.current)} {...listeners}>
            <Box padding={2} flex={1} justifyContent="center" alignItems="center" flexDirection="column" display="flex">
              <FontAwesomeIcon icon={faGripHorizontal} color={alpha(theme.palette.text.primary, 0.2)} size="1x" />
            </Box>
          </div>
          <CardContent style={{ paddingTop: focused ? undefined : 0, marginTop: focused ? undefined : -8 }}>
            {focused && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    onFocus={(e) => e.target.select()}
                    fullWidth
                    multiline
                    label="Question"
                    onChange={(e) => {
                      const val = e.target.value;
                      onChange({ ...value, title: val });
                    }}
                    value={value.title}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <QuestionSelectDropDown value={value.type} onChange={(v) => changeType(v)} />
                </Grid>
                {renderEditor(
                  value.content,
                  (change) => onChange((val) => ({ ...val, content: getValue(change, val.content) })),
                  value.type,
                  value.required,
                  setAsBudgetField,
                  budgetField,
                  setAsProjectDurationField,
                  projectDurationField
                )}
              </Grid>
            )}
            {focused || (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">{value.title}</Typography>
                </Grid>
                {renderViewer(value.type, value.required, value.content)}
              </Grid>
            )}
          </CardContent>
          <Divider />
          <CardActions style={{ flexDirection: 'row-reverse' }} className="card-action__wrapper">
            <IconButton onClick={onRemove}>
              <FontAwesomeIcon icon={faTrash} color={theme.palette.error.main} size="xs" />
            </IconButton>
            <Tooltip title="Duplicate item">
              <IconButton onClick={duplicate}>
                <FontAwesomeIcon icon={faCopy} size="xs" />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem style={{ marginLeft: 16 }} />
            <FormControlLabel
              control={<Switch color="secondary" checked={value.required} onChange={(e) => onChange({ ...value, required: e.target.checked })} />}
              label={t('required')}
              labelPlacement="start"
            />
          </CardActions>
        </Card>
      </div>
    </Grid>
  );

  function renderViewer(type: string, required: boolean, schema: any) {
    const Viewer = Renderers[type].viewer;
    return <Viewer value={Renderers[type].defaultReaderValue} required={required} schema={schema} />;
  }
  function renderEditor(
    value: any,
    onChange: (val: SetAction<any>) => void,
    type: string,
    required: boolean,
    setAsBudgetField?: (set: boolean) => void,
    budgetField?: boolean,
    setAsProjectDurationField?: (set: boolean) => void,
    projectDurationField?: boolean
  ) {
    const Editor = Renderers[type].editor;

    return (
      <Editor
        value={value}
        onChange={onChange}
        required={required}
        setAsBudgetField={setAsBudgetField}
        budgetField={budgetField}
        setAsProjectDurationField={setAsProjectDurationField}
        projectDurationField={projectDurationField}
      />
    );
  }
}
function areEqual(a: ItemProps, b: ItemProps) {
  return a.value === b.value && a.focused === b.focused && a.budgetField === b.budgetField && a.projectDurationField === b.projectDurationField;
}

const MemoizedItem = React.memo(Item, areEqual);

export { MemoizedItem as Item };
