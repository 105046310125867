import * as React from 'react';

function SvgQuoteRightSolid(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.709 20.745" ref={svgRef} {...props}>
      <path d="M21.486 0h-5.927a2.223 2.223 0 00-2.223 2.223V8.15a2.223 2.223 0 002.223 2.223h3.7v2.964A2.966 2.966 0 0116.3 16.3h-.37a1.109 1.109 0 00-1.111 1.111v2.223a1.109 1.109 0 001.111 1.111h.37a7.407 7.407 0 007.409-7.409V2.223A2.223 2.223 0 0021.486 0zM8.15 0H2.223A2.223 2.223 0 000 2.223V8.15a2.223 2.223 0 002.223 2.223h3.7v2.964A2.966 2.966 0 012.964 16.3h-.37a1.109 1.109 0 00-1.111 1.111v2.223a1.109 1.109 0 001.111 1.111h.37a7.407 7.407 0 007.409-7.409V2.223A2.223 2.223 0 008.15 0z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgQuoteRightSolid);
export default ForwardRef;
