import { IUserService, Query, UserCreationData } from '@base/core';
import { getModuleQuestionsCollection, getModuleUserDataCollection, GROUP_COLLECTION, USER_COLLECTION } from '../config';

import { IQuestionService, Question, QuestionUpdate, UserModuleDataFromDB } from '@learning/core';

import { firebase } from '../config';
import { createCallableApi } from './createCallableApi';

export class QuestionService implements IQuestionService {
  async updateQuestion({
    areaId,
    experiencePointsToAddToArea,
    moduleId,
    nextValue,
    questionId,
    setAreaId = false,
    userId,
    currentQuestion,
    scoreUpdate,
    oldScore,
    timeDiff,
  }: QuestionUpdate): Promise<void> {
    await getModuleUserDataCollection(moduleId)
      .doc(userId)
      .set(
        {
          questionScores: { [questionId]: nextValue },
          ...((setAreaId && { currentAreaId: areaId }) || {}),
          areaStats: {
            [areaId]: {
              experiencePoints: (firebase.firestore.FieldValue.increment(experiencePointsToAddToArea) as any) as number,
            },
          },
        } as UserModuleDataFromDB,
        { merge: true }
      );
    const combinedAnswerLength = currentQuestion.answer ? currentQuestion.answer.length : currentQuestion.answers.reduce((sum, cv) => sum + cv.text.length, 0);
    createCallableApi(
      '/events/question_event',
      'POST'
    )({
      elapsedTime: timeDiff,
      moduleId: moduleId,
      areaId: areaId,
      questionId: questionId,
      scoreUpdate,
      oldScore: oldScore,
      newScore: nextValue,
      uid: userId,
      questionCharCount: currentQuestion.text.length,
      answerCount: currentQuestion.answers?.length ?? 1,
      combinedAnswerLength,
      meanAnswerLength: combinedAnswerLength / currentQuestion.answers?.length ?? 1,
    });
  }

  async getQuestion(moduleId: string, questionId: string): Promise<Question> {
    let sn = await getModuleQuestionsCollection(moduleId).doc(questionId).get();

    return { ...(sn.data() as any), moduleId };
  }
}
