import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { de, en } from '@base/translations';

const resources = { en: { common: en }, de: { common: de } };
// const resources = { en: {translation: en}, de: {translation: de} };

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    ns: ['core', 'common'],
    defaultNS: 'common',
    // lng: 'de',
    // nsSeparator: '.',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

export default i18n;

const t = i18n.t.bind(i18n);

export { t };
