import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCode,
  faBooks,
  faBook,
  faAnalytics,
  faAlarmClock,
  faBookUser,
  faCalendar,
  faPhone,
  faAward,
  faUser,
  faUserCog,
  faUserCheck,
  faUserAlt,
  faCheck,
  faBallot,
  faBarcode,
  faBookOpen,
  faBookmark,
  faBriefcase,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faAddressBook,
  faAddressCard,
  faArchive,
  faBalanceScale,
  faBook as faBookLight,
  faBriefcase as faBriefcaseLight,
  faBuilding,
  faBullhorn,
  faBusinessTime,
  faCalendar as faCalendarLight,
  faCalendarAlt,
  faCalculator,
  faCabinetFiling,
  faChartLine,
  faChartNetwork,
  faChartPie,
  faCompass,
  faEdit,
  faEnvelope,
  faFileChartLine,
  faFileChartPie,
  faFileSpreadsheet,
  faFileUser,
  faGlobe,
  faIndustry,
  faFolders,
  faLandmark,
  faKeynote,
  faShieldCheck,
  faMarker,
  faMindShare,
  faPhone as faPhoneLight,
  faUsers,
  faPassport,
  faPhoneOffice,
  faPresentation,
  faPrint,
  faProjector,
  faStickyNote,
  faTasks,
  faUserChart,
  faChartBar,
  faAnalytics as faAnalyticsLight,
  faSkiingNordic,
  faSkiing,
  faDatabase,
  faDesktop,
  faDownload,
  faLaptop,
  faPhoneLaptop,
  faFilm,
  faImage,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  faCode,
  faBooks,
  faBook,
  faAnalytics,
  faCalendarLight,
  faAlarmClock,
  faPhoneLight,
  faBookUser,
  faCalendar,
  faPhone,
  faAward,
  faUser,
  faBriefcaseLight,
  faUserCheck,
  faUserCog,
  faUserAlt,
  faBallot,
  faCheck,
  faBarcode,
  faBookLight,
  faBookOpen,
  faBriefcase,
  faBookmark,
  faBuilding,
  faUsers,
  faPhoneLaptop,
  faPassport,
  faAnalyticsLight,
  faCalendarAlt,
  faCalculator,
  faShieldCheck,
  faCabinetFiling,
  faChartLine,
  faChartNetwork,
  faChartPie,
  faCompass,
  faEdit,
  faEnvelope,
  faFileChartLine,
  faFileChartPie,
  faFileSpreadsheet,
  faFileUser,
  faGlobe,
  faIndustry,
  faFolders,
  faAddressBook,
  faAddressCard,
  faArchive,
  faBalanceScale,
  faLandmark,
  faKeynote,
  faMarker,
  faMindShare,
  faPhoneOffice,
  faPresentation,
  faPrint,
  faProjector,
  faStickyNote,
  faTasks,
  faUserChart,
  faChartBar,
  faSkiingNordic,
  faSkiing,
  faDatabase,
  faDesktop,
  faDownload,
  faLaptop,
  faFilm,
  faBullhorn,
  faBusinessTime,
  faImage,
);
