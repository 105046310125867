import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Theme,
  Typography,
  useTheme as useNewTheme
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useTheme as useOldTheme} from 'libs/base/web/src/theme';

import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles({
  circle: (theme: Theme) => ({
    borderRadius: '50%',
    border: '2px solid ' + theme.palette.text.primary,
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'margin-right': theme.spacing(2),
  }),
});

export function Section({
                          id,
                          description,
                          title,
                          disableCount,
                          onRemove = () => null,
                          isRemovable = false,
                          numOfSections,
                          sectionNum,
                          focused = false,
                          onChange,
                          isEmpty = false,
                          setFocus,
                        }: {
  id: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
  // onChange: (val: FormContent) => void;
  isRemovable?: boolean;
  isEmpty?: boolean;
  numOfSections: number;
  disableCount?: boolean;
  sectionNum: number;
  onChange: (val: { description: string; title: string }) => void;
  onRemove?: () => void;
  focused?: boolean;
  setFocus: (ref: HTMLDivElement) => void;
}) {
  const newTheme = useNewTheme();
  const oldTheme = useOldTheme();
  const {t} = useTranslation();

  const classes = useStyles(newTheme);
  const rootref = useRef<HTMLDivElement>(null);

  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid item xs={12} className="section__wrapper" ref={rootref}>
      <div
        {...attributes}
        ref={setNodeRef}
        style={style}
        onFocus={() => {
          setFocus(rootref.current);
        }}
      >
        <Card sx={{boxShadow: '0px 8px 10px 4px rgba(0,0,0,0.08)'}}>
          <Box bgcolor={oldTheme.colors["main-color"]} color={"white"}>
            {!disableCount && (
              <CardContent sx={{paddingY: 2}}>
                <Box display="flex" alignItems="center">
                  <div className={classes.circle} style={{borderColor: "white"}}>
                    <Typography variant="h6" fontWeight="500">
                      {sectionNum}
                    </Typography>
                  </div>
                  <Typography variant="h5" fontWeight="500" style={{flex: 1}}>
                    Section {sectionNum} of {numOfSections}
                  </Typography>
                  {isEmpty && <Alert severity="warning">{t('warnings.empty-section')}</Alert>}
                  {isRemovable && (
                    <IconButton onClick={onRemove} className="trash-button">
                      <FontAwesomeIcon icon={faTrash} color={newTheme.palette.error.main} size="xs"/>
                    </IconButton>
                  )}
                  <oldTheme.images.logo style={{color: "white", zIndex: 2, height: 34, width: 'auto'}}/>
                </Box>
              </CardContent>
            )}
            <Card sx={{boxShadow: 'none'}}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {focused ? (
                      <TextField
                        fullWidth
                        label="Section title"
                        autoFocus
                        onFocus={(e) => e.target.select()}
                        variant="outlined"
                        onChange={(e) => {
                          const val = e.target.value;
                          onChange({title: val, description: description as string});
                        }}
                        value={title}
                      />
                    ) : (
                      <Typography variant="h6" fontWeight="500"
                                  style={{whiteSpace: 'pre-line', wordBreak: 'break-word'}}>
                        {title}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {focused ? (
                      <TextField
                        fullWidth
                        multiline
                        label="Section description"
                        variant="outlined"
                        onChange={(e) => {
                          const val = e.target.value;
                          onChange({title: title as string, description: val});
                        }}
                        value={description}
                      />
                    ) : (
                      <Typography style={{whiteSpace: 'pre-line'}}>{description}</Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Card>
      </div>
    </Grid>
  );
}
