import { Chip } from '@material-ui/core';
import React from 'react';
import { FormStatus, FormSubmission } from '../../../../core/src/entities/IFormService';

function getStatusColor(status: FormSubmission['state'] | 'not-started') {
  switch (status) {
    case 'draft':
      return 'warningAction';
    case 'rejected':
      return 'errorAction';
    case 'submitted':
      return 'infoAction';
    case 'success':
      return 'successAction';
    case 'review':
      return 'warningAction';
    default:
      return 'default';
  }
}

function getStatusName(status: FormStatus) {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'rejected':
      return 'Rejected';
    case 'submitted':
      return 'Submitted';
    case 'success':
      return 'Accepted';
    case 'review':
      return 'In Review';
    default:
      return 'Not started';
  }
}

export function getColorFromDate(date: Date) {
  if (!date) return 'default';
  const difference = Date.now() - date.valueOf();
  if (difference > 0) return 'errorAction';
  if (difference > -1000 * 3600 * 24 * 7) return 'warningAction';

  return 'default';
}

export function getColorForSubmissionDate(submissionDate: Date, deadline: Date) {
  if (!submissionDate) return 'default';
  const difference = deadline.valueOf() - submissionDate.valueOf();
  if (difference < 0) return 'errorAction';
  if (difference < -1000 * 3600 * 24 * 7) return 'warningAction';

  return 'default';
}

interface ApplicationDateChipProps {
  submission: FormSubmission;
  dateValue: Date;
  deadline?: Date;
}

export function ApplicationSubmissionDateChip({ submission, dateValue, deadline = new Date() }: ApplicationDateChipProps) {
  let date = '';
  if (submission?.submissionDate) {
    date = new Date(submission?.submissionDate).toLocaleDateString();
  } else if (submission?.lastUpdatedTimestamp) {
    date = submission?.lastUpdatedTimestamp.toDate().toLocaleDateString();
  } else if (submission?.timestamp) {
    date = submission?.timestamp.toDate().toLocaleDateString();
  }
  return <Chip color={getColorForSubmissionDate(dateValue, deadline) as any} label={date ? date : '-'} />;
}

export function ApplicationStatusChip({ status }: { status: FormStatus }) {
  return <Chip color={getStatusColor(status) as any} label={getStatusName(status)} />;
}

export function ApplicationDeadlineDateChip({ deadline }: { deadline: Date }) {
  return <Chip color={getColorFromDate(deadline) as any} label={deadline?.toLocaleDateString?.() ?? '-'} />;
}
