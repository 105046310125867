import { FileProvider, RemoteFile, UploadTask } from '@editors/form-editor'
import { firebase } from '../config'
import cuid from 'cuid'

export class SimpleFileProvider implements FileProvider {
  constructor(private collectionPath: string) { }

  async createFileId(): Promise<string> {
    return cuid()
  }

  private getCollection() {
    return firebase.storage().ref(this.collectionPath);
  }

  uploadFile(id: string, file: File): UploadTask {
    let fbTask = this.getCollection().child(id).put(file, { contentType: file.type, customMetadata: { name: file.name } });

    let myResolve: (remoteFile: RemoteFile) => void, myReject: (error: any) => void;

    let task: UploadTask = new Promise(function (res, rej) {
      myResolve = res;
      myReject = rej;
    }) as any

    task.on = ({ change, error, success }) => {
      change(0, file.size, file.name);
      fbTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
        complete: () => success({ id, mimeType: file.type, name: file.name, getDownloadUrl: async () => await (await task).getDownloadUrl() }),
        error,
        next: (v) => change(v.bytesTransferred, v.totalBytes, file.name)
      })
    }
    task.cancel = async () => fbTask.cancel();

    fbTask.then(async sn => myResolve({ getDownloadUrl: async () => await (await task).getDownloadUrl(), id, mimeType: file.type, name: file.name }))
    fbTask.catch(myReject);

    return task;
  }

  async deleteFile(id: string): Promise<void> {
    await this.getCollection().child(id).delete()
  }


  async getRemoteFile(id: string): Promise<RemoteFile> {
    let meta = await this.getCollection().child(id).getMetadata();

    return {
      id,
      mimeType: meta.contentType,
      name: meta.customMetadata.name,
      getDownloadUrl: () => this.getCollection().child(id).getDownloadURL()
    }
  }

}