import { FormFile, FormSubmission } from '@base/core';
import { SimpleFileProvider } from '@base/firebase';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faDownload, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Button, Card, Stack, Typography, useTheme } from '@material-ui/core';
import { downloadFile } from 'libs/editors/form-editor/src/lib/components/FileDownload';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface AgreementFileProps {
  files: FormFile[];
  downloadFormFile: (file: FormFile) => void;
}
function AgreementFileElement({ files, downloadFormFile }: AgreementFileProps) {
  const theme = useTheme();
  return (
    <>
      {files.map((f) => (
        <Button
          size="small"
          sx={{ bgcolor: theme.palette.background.grayDark, color: theme.palette.grey[800], justifyContent: 'left', textAlign: 'left', overflowWrap: 'anywhere' }}
          fullWidth
          key={f.id}
          startIcon={<FontAwesomeIcon style={{ fontSize: 15, marginLeft: 8 }} icon={faFile} />}
          onClick={() => downloadFormFile(f)}
        >
          {f.name}
        </Button>
      ))}
    </>
  );
}

export function Agreements({
  content,
  onSendToNFClick,
  sentBackFromNFClick,
  verifiedByIBUClick,
  ...props
}: { content: FormSubmission; onSendToNFClick?: () => void; sentBackFromNFClick?: () => void; verifiedByIBUClick?: () => void } & Omit<AccordionProps, 'children'>) {
  const ibuFileProvider = useMemo(() => new SimpleFileProvider('/forms/agreements'), []);
  const nfFileProvider = useMemo(() => new SimpleFileProvider('/forms/agreements-nf'), []);

  const downloadIBUSideFile = async (file: any) => {
    await ibuFileProvider.getRemoteFile(file.id).then((file) => downloadFile(file));
  };
  const downloadNFSideFile = async (file: any) => {
    await nfFileProvider.getRemoteFile(file.id).then((file) => downloadFile(file));
  };

  const theme = useTheme();
  const { t } = useTranslation();

  const showUploadButtonForNf = content?.agreement?.files?.length > 0 && sentBackFromNFClick;

  return (
    <Stack sx={{ paddingTop: 0 }}>
      <Stack spacing={2}>
        <Stack spacing={1} sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 1, padding: 2 }}>
          <Typography variant="overline">Sent to NF</Typography>
          <AgreementFileElement files={content?.agreement?.files ?? []} downloadFormFile={downloadIBUSideFile} />
          {onSendToNFClick && (
            <Button
              size="small"
              sx={{ color: 'white', bgcolor: 'primary.main', ':hover': { bgcolor: 'primary.lighter' } }}
              fullWidth
              endIcon={<FontAwesomeIcon style={{ fontSize: 15 }} icon={faUpload} />}
              onClick={onSendToNFClick}
            >
              Upload Agreement Template
            </Button>
          )}
        </Stack>
        <Stack spacing={1} sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 1, padding: 2 }}>
          <Typography variant="overline">Sent back from NF</Typography>
          <AgreementFileElement files={content?.agreement?.nfFiles ?? []} downloadFormFile={downloadNFSideFile} />
          {showUploadButtonForNf && (
            <Button
              size="small"
              sx={{ color: 'white', bgcolor: 'primary.main', ':hover': { bgcolor: 'primary.lighter' } }}
              fullWidth
              endIcon={<FontAwesomeIcon style={{ fontSize: 15 }} icon={faUpload} />}
              onClick={sentBackFromNFClick}
            >
              Upload signed Agreement
            </Button>
          )}
        </Stack>
        <Stack spacing={1} sx={{ backgroundColor: theme.palette.background.gray, borderRadius: 1, padding: 2 }}>
          <Typography variant="overline">Verified by IBU</Typography>
          <AgreementFileElement files={content?.agreement?.approvedFiles ?? []} downloadFormFile={downloadIBUSideFile} />
          {verifiedByIBUClick && (
            <Button
              size="small"
              sx={{ color: 'white', bgcolor: 'primary.main', ':hover': { bgcolor: 'primary.lighter' } }}
              fullWidth
              endIcon={<FontAwesomeIcon style={{ fontSize: 15 }} icon={faUpload} />}
              onClick={verifiedByIBUClick}
            >
              Upload verified Agreement
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export function AgreementsAccordion({
  content,
  onSendToNFClick,
  sentBackFromNFClick,
  verifiedByIBUClick,
  ...props
}: { content: FormSubmission; onSendToNFClick?: () => void; sentBackFromNFClick?: () => void; verifiedByIBUClick?: () => void } & Omit<AccordionProps, 'children'>) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Accordion disableGutters sx={{ border: `2px ${theme.palette.background.grayDark} solid`, marginY: 0, borderRadius: 2 }} {...props}>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
        <Typography variant="overline">{t('legacy-agreements')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        <Agreements content={content} onSendToNFClick={onSendToNFClick} sentBackFromNFClick={sentBackFromNFClick} verifiedByIBUClick={verifiedByIBUClick} />
      </AccordionDetails>
    </Accordion>
  );
}

export function AgreementsElement({
  content,
  onSendToNFClick,
  sentBackFromNFClick,
  verifiedByIBUClick,
  ...props
}: { content: FormSubmission; onSendToNFClick?: () => void; sentBackFromNFClick?: () => void; verifiedByIBUClick?: () => void } & Omit<AccordionProps, 'children'>) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card sx={{ boxShadow: 'unset', p: 2, border: `2px solid ${theme.palette.grey[400]}`, borderRadius: 1 }}>
      <Stack spacing={2}>
        <Typography variant="overline">{t('agreements')}</Typography>
        <Agreements content={content} onSendToNFClick={onSendToNFClick} sentBackFromNFClick={sentBackFromNFClick} verifiedByIBUClick={verifiedByIBUClick} />
      </Stack>
    </Card>
  );
}
