import * as React from 'react';

function SvgTimesCircleRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={svgRef} {...props}>
      <path d="M10 0a10 10 0 1010 10A10 10 0 0010 0zm0 18.065A8.065 8.065 0 1118.065 10 8.062 8.062 0 0110 18.065zm4.1-10.573L11.6 10l2.5 2.508a.484.484 0 010 .685l-.911.911a.484.484 0 01-.685 0L10 11.6l-2.508 2.5a.484.484 0 01-.685 0l-.911-.911a.484.484 0 010-.685L8.4 10 5.9 7.492a.484.484 0 010-.685l.911-.911a.484.484 0 01.685 0L10 8.4l2.508-2.5a.484.484 0 01.685 0l.911.911a.484.484 0 010 .685z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTimesCircleRegular);
export default ForwardRef;
