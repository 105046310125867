import {arrayMove} from '@dnd-kit/sortable';
import {faCog, faMinusCircle, faPlusCircle, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack,
  Switch,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import {makeStyles} from '@material-ui/styles';
import {GridColDef, GridColumns, GridRowData, useGridApiRef, XGrid} from '@material-ui/x-grid';
import cuid from 'cuid';
import {format} from 'date-fns';
import {Field, Formik} from 'formik';
import React, {useState} from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {ApplicationForm, StandardPopup} from '../../../../../base/web/src';
import {SetAction} from '../components/getValue';
import {FormContent} from '../entities/FormContent';
import {Renderer, RendererEditorProps, RendererViewerProps} from '../entities/Renderer';
import {TABLE} from './types';
import {renderCellExpand} from "../helpers";
import {DownloadXLSXButton} from "../components/DownloadXLSXButton";

import { useParams } from 'react-router-dom';
import { useGetForm } from '../../../../../base/web/src/views/campaign/hooks/useGetForm';
import { useGetFile } from '../../../../../base/web/src/views/campaign/hooks/useGetFile';

export const TableRenderer: Renderer<TableSchema, TableValue> = {
  type: TABLE,
  contentType: 'table',
  defaultEditorValue: {columns: [{field: 'ColumnOne'}], rows: [{id: 'firstRow'}]},
  defaultReaderValue: {rowData: {}, userAddedRows: []},
  viewer: Viewer,
  viewerMin: ViewerMin,
  editor: Editor,
  getValidationSchema,
  getTextRepresentation: getTextRepresentation,
};

const useStyles = makeStyles({
  xGridRoot: {
    '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: '#D9E2E6',
      overflow: 'visible',
      fontSize: 14,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      overflow: 'visible',
    },
    '& .MuiDataGrid-columnsContainer': {
      overflow: 'visible',
    },
    '& .MuiDataGrid-cell': {
      borderColor: 'darkgray',
    },
    '& .MuiDataGrid-columnHeader': {
      borderBottomColor: 'darkgray',
    },
    '& .MuiDataGrid-columnSeparator': {
      color: 'darkgray',
    },
    borderTopColor: 'darkgray',
    borderLeftColor: 'darkgray',
    borderRightColor: 'darkgray',
    borderRadius: 0,
  },
  parent: {
    '&:hover': {
      '& $actions': {
        opacity: 1,
      },
    },
  },
  actions: {
    opacity: 0,
    transition: 'opacity 300ms ease',
  },
});

const useStylesViewer = makeStyles((theme: Theme) => {
  return {
    root: {
      fontSize: 14,
      '& .MuiDataGrid-row .MuiDataGrid-cell': {
        backgroundColor: theme.palette.background.gray,
      },
      '& .MuiDataGrid-cell.MuiDataGrid-cellEditable': {
        backgroundColor: 'inherit !important',
      },
      '& .MuiDataGrid-columnHeaderWrapper': {
        backgroundColor: '#D9E2E6',
        overflow: 'visible',
      },
      '& .MuiDataGrid-columnSeparator': {
        color: 'darkgray',
      },
      borderTopColor: 'darkgray',
      borderLeftColor: 'darkgray',
      borderRightColor: 'darkgray',
      borderRadius: 0,
      '& .MuiDataGrid-cell': {
        // backgroundColor: 'white',
        borderColor: 'darkgray',
      },
    },
  };
});

const useStylesPrintedTable = makeStyles((theme: Theme) => {
  return {
    root: {
      fontSize: 14,
      '& table, td, tr, th': {
        border: 'none',
        borderRadius: 0,
        borderWidth: 0,
        borderSpacing: 0,
        borderCollapse: 'collapse'
      },
      '& .odd-row>td': {
        backgroundColor: theme.palette.grey[200],
      },
      '& .even-row>td': {
        backgroundColor: theme.palette.grey[300],
      },
      '& .header-row>th': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      '& .calc-row>td': {
        backgroundColor: theme.palette.grey[500],
      },
    },
  };
});

function getCalculationRow(columns: GridColumns, rows: GridRowData[]) {
  const fields = columns.filter((c) => c.type === 'number').map((c) => c.field);

  const calcRow = {
    id: 'calcRow', index: 'sum', ...fields.reduce((map, field) => ({
      ...map,
      [field]: {sum: 0, percentage: ''}
    }), {})
  };

  for (const row of rows) {
    for (const key of fields) {
      calcRow[key].sum += parseFloat(row[key]) || 0;
    }
  }
  let grossSum = 0;
  for (const key of fields) {
    grossSum += calcRow[key].sum;
  }
  for (const key of fields) {
    const {sum} = calcRow[key];
    const percentage = grossSum === 0 ? '0%' : Math.round((sum / grossSum) * 100) + '%';
    calcRow[key] = `sum: ${sum} (${percentage})`;
  }

  return calcRow;
}

function getRowSum(columns: GridColumns, row: GridRowData, calRow: GridRowData) {
  const fields = columns.filter((c) => c.type === 'number').map((c) => c.field);
  let sum = 0;
  let calcRowSum = 0;
  let returnValue = '';

  for (const key of fields) {
    const value = !isNaN(row[key]) ? row[key] : row[key]?.split(': ')[1].split(' (')[0] ?? 0;
    sum += parseFloat(value);
  }
  for (const key of fields) {
    const value = calRow[key].split(': ')[1].split(' (')[0];
    calcRowSum += parseFloat(value);
  }
  for (const key of fields) {
    const percentage = calcRowSum === 0 ? '0%' : Math.round((sum / calcRowSum) * 100) + '%';
    returnValue = `sum: ${sum}`;
    if (row.id !== 'calcRow') {
      returnValue += `(${percentage})`;
    }
  }

  return returnValue;
}

function Viewer({onChange, value, required, schema, error, touched}: RendererViewerProps<TableSchema, TableValue>) {
  const apiRef = useGridApiRef();
  const theme = useTheme();
  const classes = useStylesViewer(theme);
  const handleEditCellChangeCommitted = React.useCallback(
    ({id, field, props}) => {
      onChange((val) => ({
        ...val,
        rowData: {...val.rowData, [id]: {...(val.rowData[id] ?? {}), [field]: props.value}},
      }));
    },
    [onChange]
  );
  const addRow = () => {
    onChange((val) => ({...val, userAddedRows: [...val.userAddedRows, {id: cuid()}]}));
  };
  const removeLastRow = () => {
    onChange((val) => ({...val, userAddedRows: val.userAddedRows.slice(0, val.userAddedRows.length - 1)}));
  };
  const {rows, columns} = alterRows(schema, value);

  return (
    <>
      {schema.userCanAddRows && (
        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={addRow} endIcon={<FontAwesomeIcon icon={faPlusCircle}/>}>
              row
            </Button>
            <Button variant="outlined" disabled={value.userAddedRows.length === 0} onClick={removeLastRow}
                    endIcon={<FontAwesomeIcon icon={faMinusCircle}/>}>
              remove last row
            </Button>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} display="flex" alignItems="center">
        <XGrid
          density="compact"
          apiRef={apiRef}
          className={classes.root}
          columns={columns}
          rows={rows}
          disableSelectionOnClick
          disableColumnFilter
          hideFooter
          onEditCellChangeCommitted={handleEditCellChangeCommitted}
          disableColumnMenu
          disableMultipleColumnsSorting
          autoHeight
          onCellClick={({field, id, isEditable}) => isEditable && apiRef.current.setCellMode(id, field, 'edit')}
        />
      </Grid>
    </>
  );
}

function getDateFromTimestampSeconds(seconds:number):string {
  const date = new Date(seconds * 1000)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${lpad(day)}.${lpad(month)}.${year}`
}


function lpad(value: number, count = 2){
  let padded = value.toString();
  while(padded.length<count){
    padded = "0" + padded;
  }
  return padded
}

function sanitizeRow(row:{[p: string]: {[p: string]: any}}){
  const keys = Object.keys(row)
  keys.forEach(key=>{
    if(row[key]?.seconds) {
      row[key] = getDateFromTimestampSeconds(row[key]?.seconds ?? 0) ?? "No date"
    }
    if(row[key] instanceof Date){
      row[key] = getDateFromTimestampSeconds((row[key] as Date).valueOf()/1000)
    }
  })
  return row
}

function mergeRow(row:{[p: string]: {[p: string]: any}}, rowData){
  return (rowData[row.id] ? {...row, ...rowData[row.id]} : row)
}

function alterRows(schema: TableSchema, value: TableValue) {
  const userAddedRows = Array.isArray(value.userAddedRows) ? value.userAddedRows : [];
  const rowData = value.rowData ?? {};
  const rows = [...schema.rows, ...userAddedRows].map((r) => mergeRow(r, rowData)).map(sanitizeRow);
  const calculationRow = getCalculationRow(schema.columns, rows);
  if (schema.calculationRow) rows.push(calculationRow);

  const indexCol = {
    field: 'index',
    width: 70,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    headerName: 'No.',
    align: 'center'
  } as GridColDef;

  const sumCol = {
    field: 'total',
    width: 200,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    headerName: 'Total',
    type: "number"
  } as GridColDef;

  if (schema.numberedColumns && schema.calculationColumn) {
    return {
      columns: [indexCol, ...schema.columns, sumCol],
      rows: rows.map((r, i) => ({index: i + 1, total: getRowSum(schema.columns, r, calculationRow), ...r})),
    };
  } else if (!schema.numberedColumns && schema.calculationColumn) {
    return {
      columns: [...schema.columns, sumCol],
      rows: rows.map((r) => ({total: getRowSum(schema.columns, r, calculationRow), ...r})),
    };
  } else if (schema.numberedColumns && !schema.calculationColumn) {
    return {
      columns: [indexCol ,...schema.columns],
      rows: rows.map((r, i) => ({index: i + 1, ...r})),
    };
  }
  const expandableColumns = Array.isArray(schema.columns) ? schema.columns.map(column => {
    return {...column, renderCell: renderCellExpand}
  }) : schema.columns

  return {rows, columns: expandableColumns};
}

function ViewerMin({onChange, value, required, schema, error, touched, context}: RendererViewerProps<TableSchema, TableValue>) {

  const {rows, columns} = alterRows(schema, value);
  const theme = useTheme();
  const classes = useStylesPrintedTable(theme);
  const headers = [];
  const dataRows = [];

  let xlsxName = 'Untitled.xlsx';
  let xlsxSheetName = 'Untitled';
  let printVersion = false;

  if (context && context.form && context.group) {
    const appName = context.form.name;
    const groupName = context.group.name;

    xlsxName = `${appName}_${groupName}_${new Date().toLocaleDateString()}.xlsx`
    // excel sheetnames only can have 31 characters
    xlsxSheetName = `${appName.slice(0, 31)}`
  } else if (context && context.type === 'print') {
    printVersion = true;
    columns.map((c) => headers.push(< th>{c.headerName}</th>));
    let index = 0;
    rows.map((row) => {
      const dataCols = []

      let rowClassName;

      if (index === rows.length-1 && schema.calculationRow) {
        rowClassName = 'calc-row';
      } else {
        rowClassName = index % 2 === 0 ? 'even-row' : 'odd-row';
      }

      columns.map((c) => {
        dataCols.push(<td>{getFormattedValue(row[c.field], c.type)}</td>);
      })
      dataRows.push(<tr className={rowClassName}>{dataCols}</tr>);
      index++;
    })
  }

  return (
    <Stack spacing={3}>
      { !printVersion
        ? (
          <>
            <XGrid
              columns={columns}
              rows={rows}
              isCellEditable={() => false}
              disableSelectionOnClick
              disableColumnFilter
              density="compact"
              disableColumnMenu
              hideFooter
              autoHeight
              disableMultipleColumnsSorting
              showCellRightBorder
            />
            <Stack justifyContent="flex-end">
              <DownloadXLSXButton
                buttonText="Download Excel"
                rows={rows}
                columns={columns}
                filename={xlsxName}
                sheetname={xlsxSheetName}
              />
            </Stack>
          </>
        )
        : (
          <>
            <table cellSpacing={0} className={classes.root}>
              <thead>
                <tr className={"header-row"}>
                  { headers }
                </tr>
              </thead>
              <tbody>
                { dataRows }
              </tbody>
            </table>
          </>
        )
      }
    </Stack>
  );
}

async function getTextRepresentation({
                                       onChange,
                                       value,
                                       required,
                                       schema,
                                       error,
                                       touched
                                     }: RendererViewerProps<TableSchema, TableValue>) {
  const {rows, columns} = alterRows(schema, value);
  return `<table>
        <thead>
        <tr>
        ${columns.map((c) => `<td>${c.headerName}</td>`).join('')}
      }
      </tr>
  </thead>
  <tbody>
  ${rows.map((row) => `<tr>${columns.map((c) => `<td>${getFormattedValue(row[c.field], c.type)}</td>`).join('')}</tr>`).join('')}
  </tbody>
</table>`;
}

function getFormattedValue(value: any, type: string) {
  switch (type) {
    case 'date':
      return value > 0 ? format(value, 'MM/dd/yyyy') : (value ?? '').toString();
    default:
      return (value ?? '').toString();
  }
}

function Editor({onChange, value}: RendererEditorProps<TableSchema, TableValue>) {
  const classes = useStyles();

  const [editColumn, setEditColumn] = useState<GridColDef>(null);

  const addRow = () => {
    onChange((val) => ({...val, rows: [...val.rows, {id: cuid()}]}));
  };
  const addColumn = () => {
    onChange((val) => ({
      ...val,
      columns: [...val.columns, {field: cuid(), headerName: 'Untitled', editable: true, width: 300}]
    }));
  };
  const deleteSelectedRows = (rows) => {
    onChange((val) => ({...val, rows: val.rows.filter((r) => !rows.includes(r.id))}));
  };

  const handleEditCellChangeCommitted = React.useCallback(
    ({id, field, props}) => {
      onChange((val) => ({
        ...val,
        rows: val.rows.map((row) => {
          if (row.id === id) {
            return {...row, [field]: props.value};
          }
          return row;
        }),
      }));
    },
    [onChange]
  );
  const apiRef = useGridApiRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={addRow} endIcon={<FontAwesomeIcon icon={faPlusCircle}/>}>
            {value.rows.length} rows
          </Button>
          <Button variant="outlined" onClick={addColumn} endIcon={<FontAwesomeIcon icon={faPlusCircle}/>}>
            {value.columns.length} columns
          </Button>
        </Stack>
        <Button variant="contained" onClick={handleClick} endIcon={<FontAwesomeIcon icon={faCog}/>}>
          Table Settings
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem>
            <FormControlLabel
              checked={value.numberedColumns}
              onChange={(e, c) => onChange((v) => ({...v, numberedColumns: (e.target as any).checked}))}
              control={<Switch/>}
              label="Numbered Columns"
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              checked={value.userCanAddRows}
              onChange={(e, c) => onChange((v) => ({...v, userCanAddRows: (e.target as any).checked}))}
              control={<Switch/>}
              label="User is able to add rows"
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel checked={value.calculationRow}
                              onChange={(e, c) => onChange((v) => ({...v, calculationRow: (e.target as any).checked}))}
                              control={<Switch/>} label="Calculation row"/>
          </MenuItem>
          <MenuItem>
            <FormControlLabel checked={value.calculationColumn}
                              onChange={(e, c) => onChange((v) => ({...v, calculationColumn: (e.target as any).checked}))}
                              control={<Switch/>} label="Calculation column"/>
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" sx={{marginTop: 3}}>
        <XGrid
          className={classes.xGridRoot}
          apiRef={apiRef}
          columns={[
            {
              field: 'delete',
              headerName: '',
              width: 50,
              resizable: false,
              sortable: false,
              renderHeader() {
                return <></>;
              },
              renderCell({id}) {
                return (
                  <IconButton
                    size="small"
                    sx={{
                      color: 'error.main',
                      bgcolor: 'error.lighter',
                      ':hover': {bgcolor: 'error.light', color: 'error.lighter'},
                      width: 28,
                      height: 28
                    }}
                    onClick={() => {
                      deleteSelectedRows([id]);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash}/>
                  </IconButton>
                );
              },
            },
            ...value.columns.map((c) => ({
              ...c,
              sortable: false,
              renderHeader: (params) => (
                <Stack
                  direction="row"
                  className={classes.parent}
                  justifyContent="space-between"
                  width="100%"
                  position="relative"
                  height="100%"
                  flex={1}
                  alignItems="center"
                  spacing={1}
                  style={{justifyContent: 'center'}}
                >
                  <InputBase
                    defaultValue={params.colDef.headerName ?? 'Untitled'}
                    sx={{flex: 1, height: 46}}
                    onBlur={(change) => {
                      onChange((v) => ({
                        ...v,
                        columns: v.columns.map((c) => (c.field === params.colDef.field ? {
                          ...c,
                          headerName: change.target.value
                        } : c))
                      }));
                    }}
                  />
                  <Stack className={classes.actions} direction="row" position="absolute" spacing={1} top={-30}>
                    <IconButton
                      size="small"
                      sx={{
                        color: 'white',
                        bgcolor: 'primary.main',
                        ':hover': {bgcolor: 'primary.light', color: 'white'},
                        width: 24,
                        height: 24,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8,
                      }}
                      onClick={() => setEditColumn(c)}
                    >
                      <FontAwesomeIcon icon={faCog} fixedWidth style={{fontSize: 15}}/>
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{
                        color: 'white',
                        bgcolor: 'error.main',
                        ':hover': {bgcolor: 'error.light', color: 'white'},
                        width: 24,
                        height: 24,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 8,
                        borderTopLeftRadius: 8,
                      }}
                      onClick={() => onChange((v) => ({
                        ...v,
                        columns: v.columns.filter((col) => col.field !== c.field)
                      }))}
                    >
                      <FontAwesomeIcon icon={faTrash} fixedWidth style={{fontSize: 15}}/>
                    </IconButton>
                  </Stack>
                </Stack>
              ),
              editable: true,
            })),
          ]}
          rows={value.rows}
          onCellClick={({field, id}) => apiRef.current.setCellMode(id, field, 'edit')}
          disableColumnFilter
          disableColumnMenu
          hideFooter
          disableSelectionOnClick
          onColumnOrderChange={(param) => {
            onChange((v) => ({...v, columns: arrayMove(v.columns, param.oldIndex, param.targetIndex)}));
          }}
          onColumnResizeCommitted={(param) => {
            onChange((v) => ({
              ...v,
              columns: v.columns.map((c) => (c.field === param.colDef.field ? {...c, width: param.width} : c))
            }));
          }}
          disableMultipleColumnsSorting
          onEditCellChangeCommitted={handleEditCellChangeCommitted}
          autoHeight
          showCellRightBorder
          // showColumnRightBorder
        />
      </Grid>
      <ChangeHeaderPopup editColumn={editColumn ?? ({} as GridColDef)} onChange={onChange}
                         onClose={() => setEditColumn(null)} open={Boolean(editColumn)}/>
    </>
  );
}

export interface TableSchema {
  columns: GridColumns;
  rows: GridRowData[];
  numberedColumns?: boolean;
  calculationRow?: boolean;
  calculationColumn?: boolean;
  userCanAddRows?: boolean;
}

export interface TableValue {
  rowData: { [id: string]: { [field: string]: any } };
  userAddedRows: GridRowData[];
}

function ChangeHeaderPopup({
                             editColumn,
                             onChange,
                             onClose,
                             open
                           }: { editColumn: GridColDef; onChange: (val: SetAction<TableSchema>) => void; onClose: () => void; open: boolean }) {
  const {t} = useTranslation();
  return (
    <StandardPopup visible={open} onCloseClick={onClose} onBackdropClick={onClose} width={500}>
      <Formik
        enableReinitialize
        initialValues={editColumn}
        onSubmit={(values) => {
          onChange((v) => ({
            ...v,
            columns: v.columns.map((c) => (values.field === c.field ? values : c)),
          }));
          onClose();
        }}
      >
        {({values, submitForm, setFieldValue}) => (
          <>
            <DialogContent sx={{paddingBottom: 1}}>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Typography variant="overline">Input Type</Typography>
                </Grid>
                <Grid xs={12} item>
                  <Select value={values.type} onChange={(e) => setFieldValue('type', e.target.value)}
                          defaultValue="string" fullWidth variant="outlined">
                    <MenuItem value="string">Text</MenuItem>
                    <MenuItem value="number">Number</MenuItem>
                    <MenuItem value="date">Date only</MenuItem>
                    <MenuItem value="dateTime">Date and Time</MenuItem>
                    <MenuItem value="boolean">Checkbox</MenuItem>
                  </Select>
                </Grid>
                <Grid xs={12} item>
                  <Field as={FormControlLabel} name="editable" fullWidth type="checkbox" label="Viewer can edit"
                         control={<Switch/>} labelPlacement="start"/>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button variant="contained" color="primary" onClick={submitForm}>
                {t('save')}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </StandardPopup>
  );
}

function getValidationSchema(schema: FormContent<TableSchema>, t: TFunction<string>) {
  // let validator = date();
  // if (schema.required) {
  //   validator = validator.required(t('something-is-required', { something: t('question') }));
  // } else {
  //   validator = validator.nullable();
  // }
  // return validator.typeError(t('something-is-required', { something: t('question') }));
}
