import { ApplicationForm, Campaign } from '@base/core';
import { Form } from '@editors/form-editor';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetFile } from '../../campaign/hooks/useGetFile';
import { useGetForms } from '../../campaign/hooks/useGetForms';
import { FormsExternal } from '../FormsExternal';
import { useGetFormContents } from '../hooks/useGetFormContents';
import { getDeadlineDateFromForm } from './getDeadlineDateFromForm';

export function CampaignEditorWrapper({ match }) {
  const { parentId } = useParams<{ parentId: string }>();

  const { data: forms = [] as Form[] } = useGetForms(parentId);
  const querydata = useGetFormContents(
    parentId,
    forms.map((f) => f.id),
  );

  const history = useHistory();

  const { data: parent } = useGetFile<Campaign>(parentId);
  const { data: campaignParent } = useGetFile(parent?.parent, { enabled: !!parent });

  const finalForms = useMemo(() => {
    const filteredForms: ApplicationForm[] = forms
      .map((f: ApplicationForm) => {
        const queryData = querydata.find((q) => q.data?.formId == f.id);
        return {
          ...f,
          status: queryData?.data?.state ?? 'not-started',
          deadline: f.deadline ? getDeadlineDateFromForm(f.deadline) : parent?.deadline,
          submission: queryData?.data,
        } as any;
      })
      .filter((f) => f.published !== false);

    return filteredForms.filter((f) => !f.parent).map((f) => ({ ...f, reports: filteredForms.filter((report) => report.parent === f.id).sort((a, b) => (a.position ?? 0) - (b.position ?? 0)) }));
  }, [forms, querydata, parent?.deadline]);

  const parents = [];
  if (campaignParent) parents.push(campaignParent);
  if (parent) parents.push(parent);

  return (
    <FormsExternal
      parents={parents}
      forms={finalForms as any}
      baseUrl={match.url}
      onFormDoubleClick={(formId) => {
        history.push(match.url + '/forms/' + formId);
      }}
    />
  );
}
