import { useQuery, UseQueryOptions } from 'react-query';
import { API } from '@base/core';

export function useGetFile<T extends Core.VirtualFile>(fileId: string, queryOptions?: UseQueryOptions<T, unknown, T>) {
  return useQuery(
    ['files', fileId],
    async () => {
      return (await API.files.getFile(fileId)) as T;
    },
    queryOptions,
  );
}
