import React, { useMemo, useState } from 'react';
import { useKeyPress } from './useKeyPress';

export function useSelection<T extends { id: string }>(actualCurrentFiles: T[]) {
  const [selectedMap, setSelectedMap] = useState<{ [fileId: string]: boolean }>(() => ({}));
  const multiselectPressMac = useKeyPress('Meta');
  const multiselectPressOther = useKeyPress('ctrl');
  const multiselectPressShift = useKeyPress('Shift');

  const onContentClick = (element: T, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (multiselectPressMac || multiselectPressOther || multiselectPressShift || e.ctrlKey) {
      //select one elemen more
      setSelectedMap({ ...selectedMap, [element.id]: !selectedMap[element.id] });
    } else {
      //select given element and deselect all others
      const newState = { [element.id]: true };
      setSelectedMap(() => newState);
    }
    setSelectedMap((state) => {
      return state;
    });
  };

  const deSelectAll = () => {
    setSelectedMap({});
  };

  const selectAll = () => {
    setSelectedMap(
      actualCurrentFiles.reduce((pv, file) => {
        return { ...pv, [file.id]: true };
      }, {}),
    );
  };

  const selectedItemCount = useMemo(() => Object.values(selectedMap).reduce((sum, e) => (e ? sum + 1 : sum), 0), [selectedMap]);

  const selectedIds = useMemo(
    () =>
      Object.entries(selectedMap)
        .filter(([_, value]) => value)
        .map(([key]) => key),
    [selectedMap],
  );

  return { selectAll, deSelectAll, onContentClick, selectedMap, selectedItemCount, setSelectedMap, selectedIds };
}
