import { faSearch, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import '../../../css/components/common/popups/AddUserPopup.scss';
import { SelectableUser } from '../../users/SelectableUser';
import { usePopup } from './TimedPopup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Actions } from '@base/core';
import { StandardPopup } from './StandardPopup';

interface SetSupervisorPopup {
  active: React.ComponentState;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSupervisor?: string;
}

export function SetSupervisorPopup({ active, setActive, selectedSupervisor }: SetSupervisorPopup) {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string>(selectedSupervisor);
  const { t } = useTranslation();
  useEffect(() => {
    setSelectedUser(selectedSupervisor);
  }, [selectedSupervisor]);

  const {
    usersList,
    fetching: { queryUsers },
  } = useSelector((state: Core.StateType) => state.users);
  const dispatch = useDispatch();
  const { uid } = useParams<{ uid: string }>();

  useEffect(() => {
    if (!queryUsers.fetching && !usersList) dispatch(Actions().users.queryUsers());
  }, []);

  const notification = usePopup();

  const changeUserSelect = (user: Core.User) => {
    if (selectedUser === null) {
      setSelectedUser(user.id);
    } else if (selectedUser === user.id) {
      setSelectedUser(null);
    } else {
      setSelectedUser(user.id);
    }
  };

  const nameSearch = (userName: string) => {
    try {
      const searchRe = new RegExp(searchInput, 'gi');
      return searchRe.test(userName);
    } catch (error) {
      return true;
    }
  };

  const updateUser = async (newSupervisor: string) => {
    const res = ((await dispatch(Actions().users.updateUser(uid, { supervisor: newSupervisor }))) as any) as boolean;
    if (res) notification({ title: t('changed-supervisor'), type: 'info' });
  };

  const setSupervisor = async () => {
    await updateUser(selectedUser);
    setActive(false);
    // }else{
    //   notification({title: t('no-user-selected'), type:'error', text:t('no-user-selected-text')})
    // }
  };

  return (
    <StandardPopup
      visible={active}
      onBackdropClick={async () => {
        await setSupervisor();
        // setActive(false);
      }}
    >
      <div className="add-user-popup-provider">
        <div className="add-user-menu">
          <div className="split--container space--between">
            <div className="add-user-input-container">
              <FontAwesomeIcon style={{ fontSize: 24 }} icon={faSearch} className="add-user-input-icon" />
              <input style={{ fontSize: 20 }} type="Text" placeholder={t('search-person')} value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className="add-user-input" />
            </div>
            <button className="add-user-top-button" onClick={() => setSupervisor()}>
              <div style={{ fontSize: 20 }}>{t('set-supervisor')}</div>
              <FontAwesomeIcon style={{ fontSize: 20, marginLeft: 10 }} icon={faUser} />
            </button>
          </div>
          <div className="user-grid-mod">
            <div className="user-select-grid">
              {(usersList ?? []).map((user) => {
                return nameSearch(user.displayName) ? (
                  <SelectableUser
                    key={user.id}
                    selected={user.id === selectedUser}
                    userName={user.displayName}
                    userArea={user.abteilung}
                    userImage={user.photoURL}
                    onReSelectClick={() => changeUserSelect(user)}
                  />
                ) : null;
              })}
            </div>
          </div>
        </div>
      </div>
    </StandardPopup>
  );
}
