import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../../../css/components/common/content/main/mainModule.scss';
import { useHistory } from 'react-router-dom';
import { Dashboard, getTitleFromI18NObject } from '../../DashboardHelpers';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useTheme } from '../../../theme/ThemeProvider';

export type MainModuleProps = Dashboard.DashBoardItem | Dashboard.ReactDashboardItem;

function compileObjectIfValueIsNotUndefined<T extends object>(val: T): T {
  const ret: any = {};

  for (const [key, value] of Object.entries(val)) {
    if (value !== undefined) ret[key] = value;
  }
  return ret;
}

function isReactDashBoardItem(item: Dashboard.DashBoardItem | Dashboard.ReactDashboardItem): item is Dashboard.ReactDashboardItem {
  return !!(item as Dashboard.ReactDashboardItem).render;
}

function getGridSpan(start?: number, span?: number) {
  if (start !== undefined && span !== undefined) return `${start} / span ${span}`;
  if (span !== undefined) return `span ${span}`;
  if (start !== undefined) return `${start}`;
  return undefined;
}

/**
 *
 * @param navigateTo set path to router -  default is "/home"
 */

export const MainModule = (props: MainModuleProps) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const { backgroundColor = theme.colors['main-color'], color, column, columnSpan, row, rowSpan } = props;
  if (isReactDashBoardItem(props)) {
    if (props.render())
      return (
        <div
          className="module"
          style={compileObjectIfValueIsNotUndefined({
            backgroundColor,
            color,
            gridColumn: getGridSpan(column, columnSpan),
            gridRow: getGridSpan(row, rowSpan),
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            overflow: 'hidden',
          })}
        >
          <props.render />
        </div>
      );
    return null;
  } else {
    const { useTitleAsI18NKey, title } = props;
    const onClickHandler = () => {
      const { external, navigateTo, onClick } = props.actions;
      if (navigateTo) {
        if (external) window.open(navigateTo, '_newtab');
        else history.push(navigateTo);
      }
      onClick?.();
    };

    const desc = useTitleAsI18NKey ? t(title as string) : getTitleFromI18NObject(title, i18n.language);
    return (
      <div
        className="module"
        style={compileObjectIfValueIsNotUndefined({
          backgroundColor,
          color,
          gridColumn: getGridSpan(column, columnSpan),
          gridRow: getGridSpan(row, rowSpan),
        })}
        onClick={onClickHandler}
      >
        <FontAwesomeIcon icon={props.icon} style={{ fontSize: 58 }} className="module-icon" />
        <Typography fontWeight="500" sx={{ mt: 2, textAlign: 'center' }} variant="h5">
          {desc}
        </Typography>
      </div>
    );
  }
};
