import { Box, Tab, useTheme } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailArchive } from '..';

export function EmailArchiveWrapper() {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState('send');
  const { t } = useTranslation();

  return (
    <TabContext value={tabIndex}>
      <Box paddingX={4} paddingBottom={2} paddingTop={1} bgcolor={theme.palette.background.grayDark}>
        <TabList style={{ height: 40, minHeight: 40 }} onChange={(_, v) => setTabIndex(v)} TabIndicatorProps={{ style: { background: theme.palette.primary.main } }} textColor={'#000000' as any}>
          <Tab label={t('custom-send')} value="send" />
          <Tab label={t('system-notifications')} value="system" />
        </TabList>
      </Box>
      {tabIndex === 'send' && <EmailArchive emailFilter="send" />}
      {tabIndex === 'system' && <EmailArchive emailFilter="system" />}
    </TabContext>
  );
}
