import { Box, Grid, Stack } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConditionalRender, UserImageHandler } from '../../components/common';
import { useTheme } from '../../theme/ThemeProvider';
import { CheckField, TextField } from '../../components/users/TextField';

interface UserEditUserContentProps {
  currentUser: Core.User;
}

export function UserEditUserContent({ currentUser }: UserEditUserContentProps) {
  const theme = useTheme();
  const { usersMap } = useSelector((state: Core.StateType) => state.users);
  const { t } = useTranslation();

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box sx={{ height: 64, width: 64, borderRadius: '50%' }}>
            <UserImageHandler userName={currentUser.displayName} userPhotoUrl={currentUser.photoURL} style={{ width: '100%', height: '100%' }} />
          </Box>
          <div className="content-title">{currentUser.displayName}</div>
        </Stack>
        <Grid container spacing={2} style={{ marginLeft: '-16px' }}>
          <Grid xs={4} item>
            <TextField describtion="Name" text={currentUser.displayName} />
          </Grid>
          <Grid xs={4} item>
            <TextField describtion="E-Mail" text={currentUser.email} />
          </Grid>
          <Grid xs={4} item>
            <TextField describtion={t('phone-number')} text={currentUser.phoneNumber} />
          </Grid>
          <Grid xs={4} item>
            <TextField describtion={t('department')} text={currentUser.abteilung} />
          </Grid>
          <Grid xs={4} item>
            <TextField describtion={t('mobile-number')} text={currentUser.mobile} />
          </Grid>
          <Grid xs={4} item>
            <TextField describtion={t('address')} text={currentUser.address} />
          </Grid>
          <Grid xs={4} item>
            <TextField describtion={t('job-description')} text={currentUser.jobTitle} />
          </Grid>
          <Grid xs={4} item>
            <TextField describtion="Supervisor" text={currentUser.supervisor ? usersMap[currentUser.supervisor]?.displayName : t('no-supervisor-selected')} />
          </Grid>
        </Grid>
      </Stack>
      <ConditionalRender render={theme.productSettings.learningmodules}>
        <div className="content-title mg-top">{t('unlocked-modules')}</div>
        <div className="input-grid" style={{ marginTop: 20, gridTemplateRows: 'auto' }}>
          {currentUser.currentModules?.map((module) => {
            return <CheckField key={module} describtion={module === 'VA' ? 'Versicherungsagent' : 'Vermögensberater'} checked />;
          })}
        </div>
      </ConditionalRender>
      {/* <div className="content-title" style={{ marginTop: 40, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ flex: '1' }}>{t('rights')}</div>
      </div>
      <UserRightsCheckFieldArray currentUser={currentUser} /> */}
    </>
  );
}
