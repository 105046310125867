import { QueryObserverResult, useQueries, UseQueryOptions } from 'react-query';
import { API } from '@base/core';


export function useGetParents(parents: string[]) {
  return useQueries(parents.map(parent => ({
    queryKey: ["files", parent],
    async queryFn() {
      return await API.files.getFile(parent);
    }
  } as UseQueryOptions<unknown, unknown, Core.VirtualFile>)
  )) as QueryObserverResult<Core.VirtualFile, unknown>[];
}
