import { faCloudUpload, faImages } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import AsyncImage from '../../common/AsyncImage';

interface uploadImageProps {
  title: string;
  subTitle?: string;
  gridRowSpan?: number;
  gridColumnSpan?: number;
  image?: string | Promise<string>;
  setImage: (url: string, imageFile: File) => void;
}

export const UploadImage = ({ title, subTitle, image, setImage }: uploadImageProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const onDrop = (acceptedFiles: File[]) => {
    const currentFile = acceptedFiles[0];
    if (!currentFile) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target!.result as string, currentFile);
    };
    reader.readAsDataURL(currentFile); // convert to base64 string
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });
  const { onClick, ...rootProps } = getRootProps();

  return (
    <Box {...rootProps} sx={{ display: 'flex', borderRadius: 2, border: `2px solid ${theme.palette.background.gray}`, padding: 2, marginBottom: 1, marginTop: 1, alignItems: 'center' }}>
      {image ? (
        <AsyncImage alt="module image" src={image} style={{ borderRadius: 8, height: 110, width: 170, marginRight: 16, objectFit: 'cover' }} />
      ) : (
        <Box
          sx={{
            height: 110,
            width: 170,
            borderRadius: 2,
            mr: 2,
            backgroundColor: theme.palette.background.gray,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <FontAwesomeIcon style={{ fontSize: 32 }} icon={faCloudUpload} fixedWidth />
          <Typography sx={{ mt: 1 }} fontWeight="500" variant="subtitle1">
            {t('drop-image-here')}
          </Typography>
        </Box>
      )}
      <Box>
        <input {...getInputProps()} accept="image/*" />
        <Typography fontWeight="500">Thumbnail</Typography>
        <Typography variant="subtitle1">Upload a Thumbnail Picture</Typography>
        <Box sx={{ mt: 1 }}>
          <Button variant="contained" color="primary" onClick={onClick}>
            {t('upload-image')}
          </Button>
          <Button target="_blank" href="https://www.pexels.com/" variant="outlined" startIcon={<FontAwesomeIcon icon={faImages} />} color="primary" sx={{ ml: 1 }}>
            {t('browse-free-imagers')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
