import React from 'react';
import { Theme, useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles, WithStyles } from '@material-ui/styles';

import { createStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { LoadingButton } from '@material-ui/lab';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      height: 40,
      width: 40,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

type ActionDialogProps = {
  open: boolean;
  onSaveClick?: any;
  onCloseClick: any;
  children: any;
  modalTitle: string;
  savePending?: boolean;
  saveOnEnter?: boolean;
};

export const ActionDialog = ({ open, onSaveClick, onCloseClick, children, modalTitle, savePending, saveOnEnter }: ActionDialogProps) => {
  return (
    <Dialog
      onClose={onCloseClick}
      aria-labelledby="customized-dialog-title"
      open={open}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          saveOnEnter && onSaveClick();
        }
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onCloseClick}>
        {modalTitle}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <MuiDialogActions style={{ padding: 16 }}>
        <Button
          //@ts-ignore
          color="error"
          onClick={onCloseClick}
          variant="outlined"
          disabled={savePending}
        >
          Discard changes
        </Button>
        <LoadingButton variant="contained" onClick={onSaveClick} loading={savePending} type="submit">
          Save changes
        </LoadingButton>
      </MuiDialogActions>
    </Dialog>
  );
};
