import { ApplicationForm, FormSubmission } from '@base/core';
import { StandardPopup } from '@base/web';
import { Box, Card, CardContent, Stack, Typography } from '@material-ui/core';
import { FormSubmissionDrawer } from './FormSubmissionDrawer';
import React from 'react';
import { QueryObserverResult } from 'react-query';

export function ComparePopup({
  finalForms,
  group,
  onClose,
  open,
  selectedSubmissions,
  submissionData,
}: {
  open: boolean;
  onClose: () => void;
  selectedSubmissions: string[];
  submissionData: FormSubmission[];
  finalForms: ApplicationForm[];
  group: any;
}) {
  const selectedForms = selectedSubmissions.map((submissionId) => finalForms.find((form) => form.id === submissionId)).filter(Boolean);
  return (
    <StandardPopup visible={open} onBackdropClick={onClose}>
      <Card>
        <CardContent sx={{ width: '100%', overflow: 'visible', boxSizing: 'border-box' }}>
          <Typography variant="h1" sx={{ marginBottom: 3, display: 'block' }} fontWeight="500">
            Compare Applications
          </Typography>
          <Stack direction="row" sx={{ overflow: 'auto', width: '100%', height: '75vh' }}>
            {selectedForms.map((form) => (
              <Box key={form.id} minWidth={500}>
                <FormSubmissionDrawer groups={[{ ...group, name: form.name }]} content={submissionData.find((s) => s.formId === form.id)} form={form} onClose={() => {}} />
              </Box>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </StandardPopup>
  );
}
