import * as React from 'react';

function SvgUnlinkRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ref={svgRef} {...props}>
      <path d="M13.066 16.712a.516.516 0 010 .729l-2.8 2.8a6.015 6.015 0 01-8.503-8.511l2.8-2.8a.516.516 0 01.729 0l.972.972a.516.516 0 010 .729L3.5 13.4a3.61 3.61 0 105.072 5.137l2.8-2.8a.516.516 0 01.729 0l.972.972zM10.635 6.26L13.4 3.5a3.61 3.61 0 115.137 5.072l-2.8 2.8a.516.516 0 000 .729l.972.972a.516.516 0 00.729 0l2.8-2.8a6.016 6.016 0 00-8.507-8.507l-2.8 2.8a.516.516 0 000 .729l.972.972a.516.516 0 00.729 0zm10.241 15.589l.972-.972a.516.516 0 000-.729l-20-20a.516.516 0 00-.729 0l-.972.972a.516.516 0 000 .729l20 20a.516.516 0 00.729 0z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUnlinkRegular);
export default ForwardRef;
