import { Group } from '@base/core';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faBuilding, faSitemap, faUniversity } from '@fortawesome/pro-regular-svg-icons';
import { LoginBg, IbuLogo, Search, Woman, FirstSlide, LoginHomeBg } from '../assets/images';
import * as countries from 'i18n-iso-countries';
import { IconMap } from '../../../../modules/country-icons/src/lib/country-icons';
import { RequestFormContentProps } from 'libs/access-request/src/lib/form-contents/RequestFormContentProps';
import * as NF from 'libs/access-request/src/lib/form-contents/nf';
import * as RG from 'libs/access-request/src/lib/form-contents/rg';
import { TFunction } from 'react-i18next';

export const DefaultTheme = {
  colors: {
    //Customer Colors
    'main-100': '#EAF0F4',
    'main-200': '#CDDDE4',
    'main-300': '#B0C9D5',
    'main-400': '#93B5C5',
    'main-500': '#75A1B5',
    'main-600': '#588DA6',
    'main-700': '#3B7A97',
    'main-color': '#023850',
    'main-accent-color': '#ffffff',

    secondary: '#A0BE00',

    //Editor Colors
    'dark-gray': '#6D6D6D',
    'light-gray': '#C9C9C9',
    'editor-bg-gray': '#D6D6D6',
    'editor-light-gray': '#F3F3F3',
    'editor-accent': '#009CD9',
    'grey-bg': '#EFEFEF',

    //Standard Colors
    information: '#015B80',
    error: '#D55555',
    warning: '#D59955',
    success: '#06713F',
    favorite: '#E1D533',

    //Question Colors
    right: '#2EB174',
    wrong: '#7B1111',

    //File Colors
    powerpoint: '#C85E21',
    word: '#3455BF',
    excel: '#4DA533',
    pdf: '#C92C2C',
    'si-file': '#006E9B',

    //Shadow Colors
    'shadow-main': 'rgba(0, 0, 0, 0.15)',

    logoLight: 'white',
    logoDark: '#023850',
  },
  styles: {
    shadow: {
      noShadow: 'none',
      shadowXS: '0px 0px 5px 2px rgba(0, 0, 0, .12)',
      shadowS: '0px 0px 3px 3px rgba(0, 0, 0, .12)',
      shadowM: '0px 0px 4px 1px rgba(0, 0, 0, .2)',
      shadow3D: '0px 4px 4px 1px rgba(0, 0, 0, .2)',
    },
    borderRadius: {
      borderRadiusXS: 4,
      borderRadiusS: 6,
      borderRadiusM: 10,
      borderRadiusL: 15,
      borderRadiusXL: 20,
    },
  },
  slides: {
    loginSlides: [
      {
        title: 'Slide 1',
        description: 'Slide 1 theme fehlt!',
        illustration: FirstSlide,
      },
      {
        title: 'Slide 2',
        description: 'Slide 2 theme fehlt!',
        illustration: Woman,
      },
      {
        title: 'Slide 3n',
        description: 'Slide 3 theme fehlt!',
        illustration: Search,
      },
    ],
  },
  images: {
    loginBG: LoginBg,
    logo: IbuLogo,
    loginHomeBgSvg: LoginHomeBg,
  },
  productSettings: {
    editor: true,
    organigram: true,
    favourites: true,
    learningmodules: false,
    search: false,
    accessRequest: false,
    furtherGroups: true,
  },
  namedGroups: [
    {
      external: false,
      icon: faSitemap,
      name: 'department',
    },
    {
      external: true,
      icon: faBuilding,
      name: 'federation',
      getIcon: (group: Group) => {
        if (group.requestData?.country) {
          const key = countries.getAlpha2Code(group.requestData?.country as string, 'en');
          return IconMap[key];
        }
      },
      accessRequest: {
        FormContent: NF.NFRequestFormContent,
        getInitialValues: NF.getInitialValues,
        getSchema: NF.getSchema,
        displayName: 'National Federation',
      },
    },
    {
      external: true,
      icon: faUniversity,
      name: 'research-grant',
      getIcon: (group: Group) => {
        if (group.requestData?.country) {
          const key = countries.getAlpha2Code(group.requestData?.country as string, 'en');
          return IconMap[key];
        }
      },
      accessRequest: {
        FormContent: RG.NFRequestFormContent,
        getInitialValues: RG.getInitialValues,
        getSchema: RG.getSchema,
        displayName: 'Research Grant Programme',
      },
    },
  ] as NamedGroup[],
};

export type TranslationKey = string;

export interface NamedGroup {
  name: TranslationKey;
  external: boolean;
  getIcon?: (group: Group) => string;
  icon: IconDefinition;
  accessRequest?: {
    getSchema: (t: TFunction<'translation'>) => any;
    getInitialValues: (formData?: any) => any;
    FormContent: (props: RequestFormContentProps) => JSX.Element;
    displayName: string;
  };
}

export type ThemeType = typeof DefaultTheme;
