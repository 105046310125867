import * as React from 'react';

function SvgLayout(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.933 20.077" ref={svgRef} {...props}>
      <g fill="none" stroke="#858585" strokeWidth={2.2}>
        <g>
          <rect width={11.712} height={20.077} rx={4} stroke="none" />
          <rect x={1.1} y={1.1} width={9.512} height={17.877} rx={2.9} />
        </g>
        <g transform="translate(14.221)">
          <rect width={11.712} height={20.077} rx={4} stroke="none" />
          <rect x={1.1} y={1.1} width={9.512} height={17.877} rx={2.9} />
        </g>
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLayout);
export default ForwardRef;
