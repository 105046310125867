import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React from 'react';

const SearchTextField = styled(TextField)({
  Input: {
    '&:hover &:before': {
      border: 'none',
      borderColor: 'transparent',
      outline: 'none',
    },
  },
});

export function SearchInput(props: TextFieldProps) {
  return (
    <SearchTextField
      variant="outlined"
      {...props}
      InputProps={{
        ...(props.InputProps ?? {}),
        startAdornment: (
          <InputAdornment position="start" style={{ marginTop: 0 }}>
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        ),
        sx: { borderRadius: 999, alignItems: 'center', ...(props.InputProps?.sx ?? {}) },
      }}
    />
  );
}
