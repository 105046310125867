import * as React from 'react';

function SvgArrowToLeftRegular(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.208 20.75" ref={svgRef} {...props}>
      <path d="M13.4 18.832l-8.019-8a.649.649 0 010-.919l8.019-8a.649.649 0 01.919 0l1.059 1.059a.641.641 0 01-.011.924L10.116 8.97H23.56a.65.65 0 01.648.648v1.513a.65.65 0 01-.648.648H10.116l5.247 5.063a.651.651 0 01.011.924l-1.059 1.059a.644.644 0 01-.915.007zM2.81 20.1V.648A.65.65 0 002.161 0H.648A.65.65 0 000 .648V20.1a.65.65 0 00.648.648h1.513a.65.65 0 00.649-.648z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowToLeftRegular);
export default ForwardRef;
