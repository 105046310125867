import { QueryObserverResult, useQueries, useQuery, UseQueryOptions } from 'react-query';
import { API } from '@base/core';
import { useGetUsers } from './useGetUsers';
import { useMemo } from 'react';

export function useGetGroups() {
  const { data: users = [] } = useGetUsers();

  const res = useQuery(['groups'], async () => {
    const groups = await API.groups.getGroups();

    return groups;
  });

  const finalGroups = useMemo(
    () =>
      (res.data ?? []).map((g) => {
        return { ...g, users: users.filter((u) => u.groups[g.id]) };
      }),
    [res, users],
  );

  return {...res, data: finalGroups}
}
