import * as React from 'react';

function SvgWoman(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.894 264.208" ref={svgRef} {...props}>
      <g transform="translate(182.706 20069.324)">
        <path
          d="M-111.426-19922.204a342.293 342.293 0 00-3.894-41.522c-2.8-16.215-5.355-25.541-5.355-25.639-.37-1.426-5.705-7.2-17.568-7.02s-10.738 7.745-10.738 7.745a2.431 2.431 0 00.088.672c.112.39 11 39.877 10.529 90.858 0 .715-.088 1.947-.034 2.775 0 0 27.493.711 27.5 0 .937-4.455.47-16.799-.528-27.869z"
          fill="#b7d3f3"
        />
        <path
          d="M-107.971-19897.363c0 .711-.38 1.28-.837 1.28-.458 0-.818-.579-.823-1.28h-23.58c0 .711-.38 1.28-.837 1.28-.487 0-.832-.6-.828-1.314a356.894 356.894 0 00-1.947-40.252c-3.135-30-8.923-50.041-9.005-50.319a2.434 2.434 0 01-.088-.672s-1.124-7.565 10.738-7.745 17.2 5.593 17.573 7.02c0 .073 1.46 5.355 3.349 14.852a421.385 421.385 0 012.006 10.787 336.448 336.448 0 013.894 41.522c.994 11.07 1.344 20.387.385 24.841z"
          fill="#d2e3fc"
        />
        <path d="M-136.632-19896.083h92.371s-2.151-8.684-55.658-7.409c-37.842.92-36.713 7.409-36.713 7.409z" fill="#c5dcf8" />
        <path
          d="M-144.148-19805.155c10.709-54.218 7.954-87.489 7.944-87.908-.029-.837 1.509-1.592 2.336-1.621s4.537-.248 4.96.487c.565.935 2.721 41.926-13.241 89.081zM-57.908-19805.155c-10.709-54.218-7.954-87.489-7.939-87.908 0-.837-1.514-1.592-2.341-1.621s-4.532-.248-4.96.487c-.56.935-2.721 41.926 13.245 89.081zM-40.809-19805.155c-10.709-54.218-7.959-87.489-7.944-87.908.025-.837-1.514-1.592-2.341-1.621s-4.532-.248-4.96.487c-.56.935-2.716 41.926 13.245 89.081zM-126.94-19805.155c10.709-54.218 7.954-87.489 7.939-87.908 0-.837 1.514-1.592 2.341-1.621s4.532-.248 4.96.487c.56.935 2.716 41.926-13.245 89.081z"
          fill="#b7d3f3"
        />
        <rect width={96.154} height={5.028} rx={2.514} transform="translate(-138.467 -19898.332)" fill="#b7d3f3" />
        <path
          d="M-47.449-19976.721l30.706-6.65 1.806 4.381s-33.87 20.192-37.054 19.218 4.542-16.949 4.542-16.949zM-75.365-19837.854a2.726 2.726 0 00-.487 2.921c.7 1.947 3.028 4.118 5.729 6.284.346.277.652.535.92.769.068.059.141.122.2.185l.161.141c2.517 2.322 1.572 2.853 6.094 7.565 5.16 5.355 8.713 5.467 9.736 4.98.852-.419-.886-3.266-2.809-6.464a17.412 17.412 0 01-.282-.487l-.764-1.285c-2.254-3.85-1.674-5.661-1.144-11.391.438-4.659 7.248-26.023 7.248-26.023l-9.887-1.7s-2.979 17.013-6.26 19.632c-1.752 1.4-3.719 1.416-5.4 2.434-.117.073-.239.146-.35.224a12.894 12.894 0 00-2.705 2.215z"
          fill="#ffb27d"
        />
        <path
          d="M-75.365-19837.853a2.725 2.725 0 00-.487 2.921c.862 1.947 3.13 4.1 5.739 6.265.341.282.643.545.91.789.068.058.141.122.2.185l.161.141c2.517 2.322 1.572 2.853 6.094 7.565 5.16 5.354 8.713 5.467 9.736 4.98.852-.419-.886-3.266-2.809-6.464a21.987 21.987 0 01-9.2-6.781c-3.111-3.962-5.179-9.059-7.3-12.009-.117.073-.239.146-.35.224a12.882 12.882 0 00-2.694 2.184z"
          fill="#1c468a"
        />
        <path
          d="M4.364-19844.031c.609 3.052 4.96 2.541 9.823 1.353s3.7.224 10.622-.54 9.015-3.344 9.249-4.381c.19-.915-3.227-1.236-6.9-1.66-.458-.049-.92-.107-1.387-.166-4.138-.521-7.17-3.188-10.928-5.384-3.563-2.078-8.879-10.544-8.879-10.544l-9.487.75s4.98 7.564 6.907 10.85c1.129 1.947 1.071 4.493.93 6.465a12.3 12.3 0 00.05 3.257z"
          fill="#ffb27d"
        />
        <path
          d="M14.188-19842.676c4.868-1.188 3.7.224 10.622-.54s9.015-3.344 9.249-4.381c.19-.915-3.227-1.236-6.9-1.66l-.034.073a19.471 19.471 0 01-9.906 3.077c-4.693.117-9.663-1.231-12.9-1.178a12.3 12.3 0 00.073 3.247c.581 3.042 4.933 2.551 9.796 1.362z"
          fill="#1c468a"
        />
        <path d="M-16.743-19983.369s2.259-5.54 4.746-5.763 10.4-1.46 10.962-.341 0 5.2-1.7 6.669-12.2 3.841-12.2 3.841z" fill="#ffb27d" />
        <path d="M-31.994-19904.694l-20.445 55.006a18.783 18.783 0 01-6.153-.565 26.156 26.156 0 01-5.948-2.921s5.072-52.835 14.375-63.822 18.171 12.302 18.171 12.302z" fill="#163560" />
        <path
          d="M-112.862-19926.953s58.75-19.866 71.854-15.64 52.441 82.5 52.441 82.5a7.607 7.607 0 01-3.31 2.088c-2.254.847-7.711.793-7.711.793s-35.506-30.433-39.731-39.307-5.914-17.329-10.145-16.487-54.519 20.713-65.934 8.879-8.426-27.211-8.426-27.211z"
          fill="#1c468a"
        />
        <path
          d="M-79.367-20067.034a13.939 13.939 0 00-7.711-1.46 3.618 3.618 0 011.622-.776c-1.324-.195-4.269 1.115-4.269 1.115-18.361-7.073-21.248 19.958-20.859 26.242s.428 13.508-8.762 19.145c-18.01 11.086-2.81 30.435-2.81 30.435.141.083 8.392 14.073 8.392 14.073-.487-5.077 22.957-31.86 35.175-39.882 2.974-4.381 4.031-12.116 4.108-12.724-.487 6.133-1.562 9.575-2.5 11.512 10.368-9.541 4.346-43.854-2.386-47.68z"
          fill="#0c3f54"
        />
        <path
          d="M-79.294-20017.757c.063 0-5.9 3.12-10.383 1.894-4.216-1.149-6.971-6.615-6.912-6.63 1.786-.355 2.3-2.809 2.264-13.352l.8.156 13.576 2.687s-.307 4.274-.316 8.275c-.007 3.402.197 6.609.971 6.97z"
          fill="#ffb27d"
        />
        <path d="M-79.961-20033.005s-.307 4.274-.316 8.275c-7.6-.214-11.615-7.146-13.26-10.987z" fill="#ed975d" />
        <path d="M-94.925-20037.172s-3.977-6.294-4.936-11.683c-.682-3.836 1.275-18.074 15.577-16.02a19.423 19.423 0 018.061 3.047c3.106 2.093 7.589 6.625 2.575 19.043l-1.3 4.2z" fill="#0c3f54" />
        <path d="M-77.41-20029.193s-17.972.3-18.011-10.9-2.337-18.7 9.137-19.359 13.713 3.607 14.6 7.341-1.053 22.553-5.726 22.918z" fill="#ffb27d" />
        <path d="M-78.072-20059.722s-4.921 13.143-12.476 15.09-10.826-.083-10.826-.083a21.379 21.379 0 006.995-12.656s12.595-8.606 16.307-2.351z" fill="#0c3f54" />
        <path d="M-79.294-20058.658s3.675 2.921 4.571 5.783 1.3 8.718 3.013 8.519c0 0 3.7-8.928-.487-13.976-4.39-5.359-7.097-.326-7.097-.326z" fill="#0c3f54" />
        <path
          d="M-119.317-19969.408c-1.772-7.433 4.259-21.856 10.553-33.8 5.71-10.826 11.639-19.608 12.17-19.291 15.061 8.942 16.424 1.509 16.424 1.509s13.683 1.816 17.558 8.485c4.809 8.275 10 31.4 15.158 35.778-.793 2.434-.453 3.714.385 6.718.18.642.38 1.256.6 1.835a35.01 35.01 0 002.59 5.316c-2.624 1.6-7.686 4.625-11.415 2.434-5.686-3.33-10.125-13.46-12.622-17.495v-.044c-5.428 30.721 1.947 37.716-1.786 40.953-3.943 3.407-9.58-1.626-21.978 3.748-8.134 3.529-19.418 8.626-27.016 9.516-3.982.487-6.956-.229-7.925-2.921-2.841-7.794 10.687-28.57 7.304-42.741z"
          fill="#1c468a"
        />
        <path d="M-81.421-20035.98s.331 1.422 3.894 1.2c0 0 2.974-.18 2.882-1.747a9.63 9.63 0 00-6.776.547z" fill="#fff" />
        <path
          d="M-126.643-19926.643c.974 2.687 3.943 3.407 7.925 2.921 5.257-2.293 27.975-11.094 27.746-17.037-.38-8.825-4.381-9.108-1.553-20.007 2.263-8.762-18.727-25.016-22.071-28.555-6.3 11.941-6.469 12.505-4.7 19.958 3.363 14.131-10.166 34.902-7.347 42.72z"
          fill="#163560"
        />
        <path
          d="M-129.213-19958.488a33.319 33.319 0 0110.987-10.149c-.633-1.641 16.945-23.19 16.945-26.529s4.693-27.328 4.693-27.328-14.652.906-19.719 7.978c-6.344 8.844-19.518 42.885-12.906 56.028z"
          fill="#1c468a"
        />
        <path d="M-118.227-19968.636l29.27 7.638-2.745 4.931s-35.623-.248-37.511-2.434 10.986-10.135 10.986-10.135z" fill="#ffb27d" />
        <path
          d="M-67.913-19977.902c2.5 4.036 6.937 14.165 12.622 17.495 3.729 2.176 8.791-.852 11.415-2.434a35.027 35.027 0 01-2.59-5.316c-2.093.487-4.644 1.09-6.294 1.587-3.203.954-15.153-11.332-15.153-11.332z"
          fill="#163560"
        />
        <path d="M-88.957-19960.998s9.2-2.6 11.386-2 9.687 5.578 8.635 6.927-7.667-.808-10.709-.4-5.393 1.908-7.584 1.582a25.151 25.151 0 01-4.478-1.183z" fill="#ffb27d" />
        <path d="M-88.956-19961s4.5-2.312 5.778-3.627a.36.36 0 01.56.063c.341.54.662 1.587-.915 2.624a8.486 8.486 0 01-5.423.94z" fill="#ffb27d" />
        <path d="M-46.476-19948.065h-42.885a3.407 3.407 0 013.407-3.407h39.468z" fill="#d5e000" />
        <path d="M-46.597-19994.773h66.3l-15.9 46.707h-66.736z" fill="#8a9100" />
        <path d="M20.686-19994.773l-15.908 46.7h-66.733l16.336-46.7z" fill="#d5e000" />
        <path d="M-15.063-19971.855a4.308 4.308 0 01-3.977 3.232 2.485 2.485 0 01-2.488-3.232 4.318 4.318 0 013.977-3.232 2.49 2.49 0 012.488 3.232z" fill="#f9ddbf" />
        <path d="M20.686-19994.775l-15.908 46.682h-44.195c29.275-9.989 37.068-40.456 38.378-46.7z" fill="#c6d100" />
        <path d="M-171.806-19805.158a1.718 1.718 0 001.7-1.4l8.329-47.218 11.975-67.891 3.349-18.985-8.528-.243-1.46 11.021-10.393 77.442-6.163 45.9a1.2 1.2 0 001.193 1.368z" fill="#95c0f4" />
        <path d="M-156.463-19929.845a12.224 12.224 0 016.659 8.2l3.349-18.984-8.528-.243z" fill="#78acea" />
        <path d="M40.401-19805.158a1.718 1.718 0 01-1.7-1.4l-8.324-47.218-11.979-67.891-3.349-18.985 8.528-.243 1.46 11.021 10.4 77.442 6.158 45.9a1.2 1.2 0 01-1.193 1.368z" fill="#95c0f4" />
        <path d="M25.057-19929.845a12.224 12.224 0 00-6.659 8.2l-3.349-18.984 8.529-.243z" fill="#78acea" />
        <rect width={228.894} height={14.905} rx={7.453} transform="rotate(180 23.094 -9966.587)" fill="#78acea" />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWoman);
export default ForwardRef;
