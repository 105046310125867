import React from 'react';
import { Renderers } from '../content-renderer';
import { FormContent } from '../entities/FormContent';

export function MinimalReaderItem({ schemaValue, value, context }: { schemaValue: FormContent; value: any; context?: any}) {

  return renderViewer(schemaValue.type, schemaValue.required, schemaValue.content, value, context);

  function renderViewer(type: string, required: boolean, schema: any, value: any, context?: any) {
    const Viewer = Renderers[type].viewerMin || Renderers[type].viewer;
    return <Viewer value={value ?? Renderers[type].defaultReaderValue} required={required} schema={schema} context={context} />;
  }
}
